import React, { useEffect, useState } from 'react';
import NewContact from './NewContact';
import api from 'api';
import joinName from 'utils/joinName';
import processError from 'utils/processError';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { UserName } from 'components/UserVerification';
import {
  Row,
  Col,
  Space,
  Button,
  Card,
  Typography,
  Skeleton,
  Divider,
  message,
  notification,
} from 'antd';

import User from 'types/User';

const { Text } = Typography;

type Props = {
  companyId: string;
};

const Contacts: React.FC<Props> = ({ companyId }) => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<User[]>([]);
  const [selectedContact, selectContact] = useState('');
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  useEffect(() => {
    api.company
      .getUsers(companyId)
      .then(({ userResource }) => {
        setContacts(userResource.map((item) => item.user));
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message: 'Error fetching contacts',
          description: message,
        });
      })
      .finally(() => setLoading(false));
  }, [companyId]);

  const appendToContacts = (contact: User) => {
    setContacts([...contacts, contact]);
    message.success('Contact added');
  };

  return (
    <>
      <Row>
        <Col xs={24} md={16} lg={12}>
          <Card
            title="Contacts"
            extra={
              <Button
                onClick={() => setShowNewContactModal(true)}
                type="primary"
              >
                Add New Contact
              </Button>
            }
          >
            <Skeleton loading={loading}>
              {contacts.map((contact) => {
                const {
                  id,
                  firstName,
                  middleName,
                  lastName,
                  username,
                  phoneNumber,
                } = contact;
                return (
                  <>
                    <UserName
                      key={id}
                      name={joinName({ firstName, middleName, lastName })}
                      isSelected={selectedContact === id}
                      onClick={() => {
                        if (selectedContact === id) {
                          selectContact('');
                        } else {
                          selectContact(id);
                        }
                      }}
                      clickable
                    />
                    {selectedContact === id && (
                      <>
                        <Space direction="vertical">
                          <Space>
                            <MailOutlined style={{ marginRight: 10 }} />
                            {username ? (
                              username
                            ) : (
                              <Text type="secondary">Not available</Text>
                            )}
                          </Space>
                          <Space>
                            <PhoneOutlined style={{ marginRight: 10 }} />
                            {phoneNumber ? (
                              phoneNumber
                            ) : (
                              <Text type="secondary">Not available</Text>
                            )}
                          </Space>
                        </Space>
                        <Divider />
                      </>
                    )}
                  </>
                );
              })}
            </Skeleton>
          </Card>
        </Col>
      </Row>
      {showNewContactModal && (
        <NewContact
          companyId={companyId}
          appendToContacts={appendToContacts}
          hide={() => {
            setShowNewContactModal(false);
          }}
        />
      )}
    </>
  );
};

export default Contacts;
