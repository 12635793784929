import { store, persistor } from './store';
const BASE_URL = window.env?.API_URL;
const DEFAULT_COUNTRY_CODE = 'NZ';
const MAX_CASHBACK_PERCENTAGE = window.env?.MAX_CASHBACK_PERCENTAGE || 10;
const MAX_FEE_PERCENTAGE = window.env?.MAX_FEE_PERCENTAGE || 10;
const MIN_PURCHASER_OFFER_FEE = window.env?.MIN_PURCHASER_OFFER_FEE || 0;
const MIN_SUPPLIER_OFFER_FEE = window.env?.MIN_SUPPLIER_OFFER_FEE || 0;
const RELAY_FEE_PERCENTAGE = window.env?.RELAY_FEE_PERCENTAGE
  ? window.env?.RELAY_FEE_PERCENTAGE
  : 20;
const SUCCESS_FEE_PERCENTAGE = window.env?.SUCCESS_FEE_PERCENTAGE || 40;
const reCaptchaKey = window.env?.RE_CAPTCHA_KEY || '';

export {
  store,
  persistor,
  reCaptchaKey,
  BASE_URL,
  DEFAULT_COUNTRY_CODE,
  MAX_CASHBACK_PERCENTAGE,
  MAX_FEE_PERCENTAGE,
  MIN_PURCHASER_OFFER_FEE,
  MIN_SUPPLIER_OFFER_FEE,
  RELAY_FEE_PERCENTAGE,
  SUCCESS_FEE_PERCENTAGE,
};
