function knapsack(array, capacity) {
  // Filter out invoices whose amount is greater than cashpool,
  // and sort the remaining invoices on descending order of amount.
  const items = array
    .filter((a) => a.invoice.discountedTotal <= capacity)
    .sort((a, b) => b - a);

  let idxItem = 0,
    idxWeight = 0,
    oldMax = 0,
    newMax = 0,
    numItems = items.length,
    weightMatrix = new Array(numItems + 1),
    keepMatrix = new Array(numItems + 1),
    solutionSet = [],
    formattedCapacity = parseInt(capacity + 0.5);
  // Setup matrices
  for (idxItem = 0; idxItem < numItems + 1; idxItem++) {
    weightMatrix[idxItem] = new Array(formattedCapacity + 1);
    keepMatrix[idxItem] = new Array(formattedCapacity + 1);
  }
  // Build weightMatrix from [0][0] -> [numItems-1][formattedCapacity-1]
  for (idxItem = 0; idxItem <= numItems; idxItem++) {
    for (idxWeight = 0; idxWeight <= formattedCapacity; idxWeight++) {
      // Fill top row and left column with zeros
      if (idxItem === 0 || idxWeight === 0) {
        weightMatrix[idxItem][idxWeight] = 0;
      }

      // If item will fit, decide if there's greater value in keeping it,
      // or leaving it
      else if (items[idxItem - 1].invoice.discountedTotal <= idxWeight) {
        newMax =
          items[idxItem - 1].invoice.discountedTotal +
          weightMatrix[idxItem - 1][
            idxWeight - parseInt(items[idxItem - 1].invoice.discountedTotal)
          ];
        oldMax = weightMatrix[idxItem - 1][idxWeight];

        // Update the matrices
        if (newMax > oldMax && newMax <= capacity) {
          weightMatrix[idxItem][idxWeight] = newMax;
          keepMatrix[idxItem][idxWeight] = 1;
        } else {
          weightMatrix[idxItem][idxWeight] = oldMax;
          keepMatrix[idxItem][idxWeight] = 0;
        }
      }

      // Else, item can't fit; value and weight are the same as before
      else {
        weightMatrix[idxItem][idxWeight] = weightMatrix[idxItem - 1][idxWeight];
      }
    }
  }

  // Traverse through keepMatrix ([numItems][formattedCapacity] -> [1][?])
  // to create solutionSet
  idxWeight = formattedCapacity;
  idxItem = numItems;
  for (idxItem; idxItem > 0; idxItem--) {
    if (keepMatrix[idxItem][idxWeight] === 1) {
      solutionSet.push(items[idxItem - 1]);
      idxWeight =
        idxWeight - parseInt(items[idxItem - 1].invoice.discountedTotal);
    }
  }

  return {
    total: weightMatrix[numItems][formattedCapacity],
    selectedInvoices: solutionSet,
  };
}

export default knapsack;
