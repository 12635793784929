import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Card, Progress, Statistic, StatisticProps } from 'antd';

const StyledCard = styled(Card)`
  height: 100%;
`;

type Props = {
  value: number;
  totalValue: number;
  showFraction?: boolean;
};

const KpiBar: React.FC<Props & StatisticProps> = ({
  title,
  value,
  totalValue,
  className,
  showFraction,
}) => {
  const progressPercent = Math.round((value / totalValue) * 100);
  return (
    <StyledCard className={className} title={title}>
      <Statistic
        value={value.toLocaleString()}
        valueStyle={valueStyles}
        suffix={showFraction ? `/${totalValue.toLocaleString()}` : undefined}
      />
      <Progress percent={progressPercent} strokeColor={colors.primary} />
    </StyledCard>
  );
};

const valueStyles: React.CSSProperties = {
  fontSize: '24px',
  fontWeight: 'bold',
};
export default KpiBar;
