import React from 'react';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const notEnabledMessage = 'Period lock is not enabled.';

export const PeriodLockMessage: React.FC<{ lastLockedMonth: string | null }> =
  ({ lastLockedMonth }) => {
    if (lastLockedMonth) {
      const messageFirstLine = `Invoices dated before ${lastLockedMonth} will no longer be processed automatically.`;
      const messageSecondLine = 'These invoices can be found in the ';
      return (
        <Space direction="vertical">
          <Text>{messageFirstLine}</Text>
          <Space>
            <Text>{messageSecondLine}</Text>
            <Link to="/bills/blocked">Blocked Tab</Link>
          </Space>
        </Space>
      );
    }

    return <Text>{notEnabledMessage}</Text>;
  };
