import axios from 'axios';
import BankAccount from 'types/BankAccount';

const activateSystemBankAccount = ({
  entityId,
}: {
  entityId: string;
}): Promise<BankAccount> =>
  new Promise((resolve, reject) => {
    axios
      .post(`/entities/${entityId}/system-account/activate`)
      .then(({ data }: { data: BankAccount }) => resolve(data))
      .catch((error) => reject(error));
  });

export default { activateSystemBankAccount };
