import { Settings } from 'types/Company';

const GET = 'relay/company/settings/GET';
const LOGOUT = 'LOGOUT';

type Action = {
  type: string;
  payload: Settings;
};

const initialState: Settings | null = null;

export default (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case GET:
      return payload;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const getCompanySettings = (companySettings: Settings) => ({
  type: GET,
  payload: companySettings,
});
