import React from 'react';
import styled from 'styled-components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { SortBy, SortDirection } from 'types/RipeCompanyInvoice';
import { useNavigate } from 'hooks';

type IconProps = {
  columnKey: SortBy;
  sortBy: SortBy;
};

type Props = IconProps & {
  title: string;
  sortDirection: SortDirection;
  setSortBy: (param: SortBy) => void;
  setSortDirection: (param: SortDirection) => void;
};

const IconContainer = styled.div<{ isHidden: boolean }>`
  margin-left: 6px;
  ${(props) => props.isHidden && 'visibility: hidden'}
`;

const SortingLabel = styled.span`
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 10px;
  width: 100%;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Container = styled.div`
  display: flex;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TitleWithSort: React.FC<Props> = ({
  title,
  sortBy,
  sortDirection,
  setSortBy,
  setSortDirection,
  columnKey,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const params = new URLSearchParams();
    if (columnKey !== sortBy) {
      setSortBy(columnKey);
      setSortDirection('asc');
      params.set('sort', `${columnKey},asc`);
    } else if (sortDirection === 'asc') {
      params.set('sort', `${sortBy},desc`);
      setSortDirection('desc');
    } else {
      params.set('sort', `${sortBy},asc`);
      setSortDirection('asc');
    }
    navigate({ params });
  };

  return (
    <Container onClick={handleClick}>
      <SortingLabel>{title}</SortingLabel>
      <IconContainer isHidden={sortBy !== columnKey}>
        {sortDirection === 'asc' ? <CaretDownOutlined /> : <CaretUpOutlined />}
      </IconContainer>
    </Container>
  );
};

export default TitleWithSort;
