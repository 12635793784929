import React, { useState, useEffect, useRef, createRef } from 'react';
import { Button, Input } from 'antd';
import PageTracker from 'components/PageTracker';
import { InputContainer } from 'components';
import queryString from 'query-string';
import styled from 'styled-components';
import { TitleSection } from 'components';

import api from 'api';
import processError from 'utils/processError';
import { StyledButton } from './ActivateAccount';

const Row = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  text-align: center;
  width: 60px;
  margin-right: ${(props) => (props.last ? '0' : '10px')};
`;

const StyledInput = styled(Input)`
  text-align: center;
`;

const CODE_INVALID_EXCEED_LIMIT =
  'You have made 3 failed attempts. Please request for a new code.';
const CODE_INVALID_ERROR = 'Invalid code. Please check the code you received.';
const CODE_MISSING_ERROR =
  'Please enter the code recieved on your phone number';
const INVALID_LINK_ERROR = 'The link might be broken or expired';

const CODE_LENGTH = 6;
const initialCodeArray = Array(CODE_LENGTH).fill('');

const ActivateAccount = ({ history, location, setAlert, user }) => {
  // Token from URL
  const { token } = queryString.parse(location.search);

  // Phone number passed from previos screen
  const { phoneNumber } = location.state ? location.state : {};
  const [codeArray, setCodeArray] = useState(initialCodeArray.slice());
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [invalidCodes, setInvalidCodes] = useState(0);
  const [pasting, setPasting] = useState(false);

  let inputRefs = useRef([]);

  if (inputRefs.current.length !== CODE_LENGTH) {
    inputRefs.current = Array(CODE_LENGTH)
      .fill()
      .map((_, i) => inputRefs.current[i] || createRef());
  }

  useEffect(() => {
    if (!phoneNumber) {
      history.replace(`/activate?set_phone_number=true&token=${token}`);
    }
  }, [history, phoneNumber, token]);

  const onCodeInput = (e, i) => {
    let value = e.target.value;
    if (value === ' ') {
      // Don't let user tap the space key or paste a single space char.
      // If they paste multiple space chars, that is handled in later code block.
      setPasting(false);
      return;
    }
    if (!isNaN(value)) {
      if (pasting) {
        setCodeArray([...value.split('')]);
        if (value.length < 6) {
          inputRefs.current[value.length].focus();
        }
        setPasting(false);
      } else {
        if (value.length > 1) {
          // A digit already exists in the input field.
          // We need to replace it with the newly entered digit.
          const quotient = Math.floor(value / 10);
          const remainder = value % 10;
          value =
            codeArray[i] === String(remainder)
              ? String(quotient)
              : String(remainder);
        }
        let updatedCodeArray = codeArray;
        updatedCodeArray[i] = value;
        setCodeArray([...updatedCodeArray]);
        if (value !== '' && i < 5) {
          // Shift focus to next cell
          inputRefs.current[i + 1].focus();
        }
        if (value === '' && i > 0) {
          // Shift focus to previous cell
          inputRefs.current[i - 1].focus();
        }
        setPasting(false);
      }
    }
  };

  const onSubmit = () => {
    const code = codeArray.join('');
    if (!code || code.length < CODE_LENGTH) {
      // User has either not entered the whole code or any digit at all.
      setError(CODE_MISSING_ERROR);
      return;
    }
    setLoading(true);
    api.user.code
      .verify({ token, code, userId: user.id })
      .then(() => {
        setLoading(false);
        setInvalidCodes(0);
        history.push(`/verify?token=${token}`);
      })
      .catch((error) => {
        setLoading(false);
        const { status, message, errors } = processError(error);
        if (status === 401) {
          setAlert({
            message: 'Error',
            description: INVALID_LINK_ERROR,
            type: 'error',
          });
        } else if (status === 404 && errors && errors.length) {
          setError(CODE_MISSING_ERROR);
        } else if (status === 400 && !errors) {
          setInvalidCodes(invalidCodes + 1);
          if (invalidCodes >= 2) {
            // User has made 3 consecutive failed attempts.
            setError(CODE_INVALID_EXCEED_LIMIT);
          } else {
            setError(CODE_INVALID_ERROR);
          }
        } else {
          setError(message);
        }
      });
  };

  const sendNewCode = () => {
    api.user.code
      .send({ token, userId: user.id })
      .then(() => {
        setAlert({
          message: 'New code sent',
          type: 'success',
          showIcon: false,
        });
        setInvalidCodes(0);
        setCodeArray(initialCodeArray.slice());
        setError('');
      })
      .catch((error) => {
        const { status, message } = processError(error);
        if (status === 401) {
          setAlert({
            message: 'Error',
            description: INVALID_LINK_ERROR,
            type: 'error',
          });
        } else {
          setError(message);
        }
      });
  };

  return (
    <>
      <PageTracker />
      <TitleSection
        title="We just sent you an SMS"
        subTitle={`Enter the six digit code we sent to phone number ending in ${phoneNumber.slice(
          -4
        )} below`}
      />
      <InputContainer error={error}>
        <Row>
          {[...Array(CODE_LENGTH).keys()].map((i) => (
            <InputWrapper key={String(i)} last={i === CODE_LENGTH - 1}>
              <StyledInput
                onChange={(e) => {
                  onCodeInput(e, i);
                }}
                onPaste={() => {
                  setPasting(true);
                  return;
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                ref={(el) => (inputRefs.current[i] = el)}
                value={codeArray[i]}
                disabled={invalidCodes === 3}
                size="large"
              />
            </InputWrapper>
          ))}
        </Row>
      </InputContainer>
      <StyledButton
        disabled={invalidCodes === 3}
        loading={loading}
        onClick={onSubmit}
        type="primary"
        size="large"
      >
        Continue
      </StyledButton>
      <Button
        onClick={sendNewCode}
        type="ghost"
        style={styles.newCodeButton}
        size="large"
      >
        Send New Code
      </Button>
    </>
  );
};

const styles = {
  newCodeButton: {
    border: 'none',
    boxShadow: 'none',
  },
};

export default ActivateAccount;
