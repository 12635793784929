import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, Menu, Row, Tooltip, Skeleton } from 'antd';
import PageTracker from 'components/PageTracker';

import api from 'api';
import { setToolbarTitle } from 'store/ui';
import Directory from './Directory';
import KYC from './KYC';
import Summary from './Summary';
import Offers from './Offers';
import Contacts from './Contacts';
import Transactions from './Transactions';
import Settings from './Settings';

import { Breadcrumbs } from './Breadcrumbs';
import { CompanyInfo } from './CompanyInfo';
import { menuItems } from './menuItems';

import Company from 'types/Company';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

const defaultMenuSelection = 'summary';

const CompanyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { params, url } = useRouteMatch<{ id: string; menu?: string }>();

  const [company, setCompany] = useState<Company>(
    location.state ? location.state.company : {}
  );

  const [isLoading, setLoading] = useState(true);

  const [selectedMenu, setSelectedMenu] = useState(defaultMenuSelection);

  const {
    id,
    companyName,
    industryCodeDescription,
    registrationNumber,
    website,
    fundsAvailable,
  } = company;

  const getCompanyData = useCallback(() => {
    api.company
      .get({ companyId: params.id })
      .then((response) => {
        setCompany(response.company);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.id]);

  useEffect(() => {
    if (!company.id) {
      getCompanyData();
    } else {
      setLoading(false);
    }
  }, [company, getCompanyData]);

  useEffect(() => {
    if (companyName) {
      dispatch(setToolbarTitle(companyName));
    }
  }, [companyName, dispatch]);

  useEffect(() => {
    if (params.menu) {
      setSelectedMenu(params.menu);
    } else {
      history.push(`${url}/${defaultMenuSelection}`);
    }
  }, [history, params.menu, url]);

  const handleChangeMenu = (menu: string) => {
    const newUrl = `/companies/${id}/${menu}`;
    history.push(newUrl);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <Helmet>
        <title>{companyName} | Relay</title>
      </Helmet>
      <PageTracker />

      <Breadcrumbs />

      <CompanyInfo
        companyName={companyName}
        industryCodeDescription={industryCodeDescription}
        registrationNumber={registrationNumber}
        website={website}
      />

      <Row gutter={32} wrap={false}>
        <Col flex="none">
          <Menu
            selectedKeys={[selectedMenu]}
            onSelect={({ key }) => {
              handleChangeMenu(key);
            }}
          >
            {menuItems.map(({ key, name, Icon }) => (
              <Menu.Item key={key}>
                <Tooltip placement="right" title={name} className="tooltip">
                  <Icon />
                </Tooltip>
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col flex="auto">
          {selectedMenu === 'summary' && (
            <Summary
              companyId={id}
              balance={fundsAvailable}
              refreshCompanyData={getCompanyData}
            />
          )}
          {selectedMenu === 'directory' && <Directory entityId={id} />}
          {selectedMenu === 'kyc' && <KYC company={company} />}
          {selectedMenu === 'offers' && <Offers companyId={id} />}
          {selectedMenu === 'contacts' && <Contacts companyId={id} />}
          {selectedMenu === 'transactions' && <Transactions companyId={id} />}
          {selectedMenu === 'settings' && <Settings />}
        </Col>
      </Row>
    </>
  );
};

export default CompanyDetails;
