import React, { useState } from 'react';
import styled from 'styled-components';
import FilterDialog from './FilterDialog';
import useFilter from './useFilter';
import { Dropdown, Space, Typography } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { InvoiceFilters } from 'types/InvoiceFilters';

const { Text, Link } = Typography;

const Container = styled(Space)`
  cursor: pointer;
  user-select: none;
`;

const Arrow = styled(CaretDownOutlined)<{ $open: boolean }>`
  position: relative;
  top: 0.5px;
  transition: 400ms transform;
  transform: ${(props) => props.$open && 'rotate(180deg);'};
`;

const ResetLink = styled(Link)`
  font-size: 15px;
  margin-left: 12px;
`;

type Props = {
  filters: InvoiceFilters;
  type: 'BILL' | 'INVOICE';
};

const Filter: React.FC<Props> = ({ filters, type }) => {
  const [open, setOpen] = useState(false);

  const onVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const hideDropdown = () => {
    setOpen(false);
  };

  const {
    activeFilterCount,
    amountProps,
    dateProps,
    invoiceNumberProps,
    matchesXeroProps,
    handleDone,
    handleReset,
  } = useFilter({ filters, hideDropdown });

  const text = activeFilterCount ? `Filter (${activeFilterCount})` : 'Filter';

  return (
    <>
      <Dropdown
        overlay={() => (
          <FilterDialog
            amountProps={amountProps}
            dateProps={dateProps}
            invoiceNumberProps={invoiceNumberProps}
            matchesXeroProps={matchesXeroProps}
            filters={filters}
            invoiceType={type}
            handleDone={handleDone}
            handleReset={handleReset}
            hideDropdown={hideDropdown}
          />
        )}
        trigger={['click']}
        onVisibleChange={onVisibleChange}
        placement="bottomRight"
        visible={open}
      >
        <Container align="center" onClick={(e) => e.preventDefault()}>
          <Text>{text}</Text>
          <Arrow $open={open} />
        </Container>
      </Dropdown>
      {activeFilterCount > 0 && (
        <ResetLink onClick={handleReset}>Reset all</ResetLink>
      )}
    </>
  );
};

export default Filter;
