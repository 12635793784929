import React, { useState } from 'react';
import api from 'api';
import { useDispatch } from 'react-redux';
import { TableRow } from '../InvoiceTable/types';
import { BulkActionButton } from 'components';
import { processError } from 'utils';
import { setRefreshData } from 'store/ui';
import { notification } from 'antd';

type Props = {
  action: 'include' | 'exclude';
  onSuccess: () => void;
  selectedRows?: TableRow[];
  invoiceIds?: string[];
  isSupplierBills?: boolean;
};

const BulkIncludeExcludeButton: React.FC<Props> = ({
  action,
  onSuccess,
  selectedRows,
  invoiceIds,
  isSupplierBills = false,
}) => {
  const [isPending, setIsPending] = useState(false);

  const dispatch = useDispatch();

  const bulkIncludeExclude = (action: 'include' | 'exclude') => {
    let invoiceIdsToInclude: string[] = [];
    if (isSupplierBills && invoiceIds) {
      invoiceIdsToInclude = invoiceIds;
    } else if (selectedRows) {
      const invoicesToInclude = selectedRows.filter((row) =>
        action === 'include'
          ? row.includeInPurchaserOffer === false
          : row.includeInPurchaserOffer === true
      );
      if (invoicesToInclude.length === 0) {
        notification.info({ message: `No bills to ${action}` });
        return;
      }
      invoiceIdsToInclude = invoicesToInclude.map((invoice) => invoice.key);
    }
    setIsPending(true);
    const payload = invoiceIdsToInclude.map((id) => ({
      path: '/include_in_purchaser_offer' as const,
      id,
      operation: 'replace' as const,
      value: action === 'include' ? true : false,
    }));
    api.invoices
      .patch(payload)
      .then((response) => {
        const totalItemsCount = response.length;
        const successItemsCount = response.reduce((count, item) => {
          if (item.updated) {
            return count + 1;
          } else {
            return count;
          }
        }, 0);
        const failedItemsCount = totalItemsCount - successItemsCount;
        if (successItemsCount) {
          const noun = successItemsCount === 1 ? 'bill' : 'bills';
          const message =
            action === 'include'
              ? `Included ${successItemsCount} ${noun} in your offer`
              : `Excluded ${successItemsCount} ${noun} from your offer`;
          notification.success({ message });
        }
        if (failedItemsCount) {
          const noun = failedItemsCount === 1 ? 'bill' : 'bills';
          const failedItemMessage = response.find(
            (item) => item.updated === false
          )?.message;
          const message =
            action === 'include'
              ? `Could not include ${failedItemsCount} ${noun} in your offer. ${failedItemMessage}`
              : `Could not exclude ${failedItemsCount} ${noun} from your offer. ${failedItemMessage}`;
          notification.error({ message });
        }
        dispatch(setRefreshData(true));
        onSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setIsPending(false));
  };

  const bulkInclude = () => bulkIncludeExclude('include');

  const bulkExclude = () => bulkIncludeExclude('exclude');

  const onClick = action === 'include' ? bulkInclude : bulkExclude;

  const label = action.toUpperCase();

  return (
    <BulkActionButton loading={isPending} onClick={onClick}>
      {label}
    </BulkActionButton>
  );
};

export default BulkIncludeExcludeButton;
