import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';

import { colors } from 'themes';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  background: #f6f7f8;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 2rem;
  font-size: 10px;
  padding-right: 10px;
  color: ${colors.greys600};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Select = styled.select`
  color: ${colors.greys600};
  background: white;
  height: 20px;
  width: 60px;
  margin-left: 3%;
  margin-right: 0.5%;
  font-size: 10px;
`;

const ItemsPerPageLabel = styled.span`
  margin-right: 5%;
`;

const PaginationComponent = ({
  rows,
  pageSize,
  pageNumber,
  handlePageChange,
  handleSizeChange,
  startRow,
  endRow,
}) => {
  return (
    <Container>
      <span>
        {startRow}-{endRow <= rows ? endRow : rows} of {rows}
      </span>
      <Select
        defaultValue={pageSize}
        onChange={(event) => handleSizeChange(event)}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Select>
      <ItemsPerPageLabel>items per page</ItemsPerPageLabel>
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideFirstLastPages
        activePage={pageNumber}
        itemsCountPerPage={pageSize}
        totalItemsCount={rows}
        onChange={handlePageChange}
        pageRangeDisplayed={4}
      />
    </Container>
  );
};

export default PaginationComponent;
