import React, { useEffect, useState } from 'react';
import api from 'api';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import SupplierOffer, { OfferExecutePayload } from 'types/SupplierOffer';
import { getCompany, showTopUpWindow } from 'store/auth';
import { getSupplierOffersAvailable } from 'store/offers';
import { setRefreshData } from 'store/ui';
import { getAnalytics } from 'store/analytics';
import { formatCurrency, formatDateForDisplay, processError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, notification } from 'antd';
import { AmountStrip, InvoiceInfo, ModalHeader } from 'components';

const PayInvoice: React.FC<{
  invoiceId: string;
  amountDue: number;
  cashReward: number;
  companyName: string;
  dueDate: number;
  invoiceNumber: string;
  hideModal: () => void;
  onSuccessPayInvoice: () => void;
}> = ({
  invoiceId,
  amountDue,
  cashReward,
  companyName,
  dueDate,
  invoiceNumber,
  hideModal,
  onSuccessPayInvoice,
}) => {
  // const [isLoading, setLoading] = useState(true);
  const [isPending, setPending] = useState(false);

  const dispatch = useDispatch();

  const companyId = useSelector((store: any) => store.auth.user?.companyId);
  const fundsAvailable = useSelector(
    (store: any) => store.auth?.user?.company?.company?.fundsAvailable
  );
  const supplierOffersAvailable: SupplierOffer[] = useSelector(
    (store: any) => store.offers.supplierOffers.available
  );
  const supplierOffer = supplierOffersAvailable.find(
    (offer) =>
      offer.offerStatus === 'CLEARING' && offer.invoiceIds.includes(invoiceId)
  );

  useEffect(() => {
    if (companyId) {
      dispatch(getCompany(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(getSupplierOffersAvailable());
  }, [dispatch]);

  const payInvoice = () => {
    if (supplierOffer && amountDue) {
      const showTopup = amountDue > parseFloat(fundsAvailable);
      if (showTopup && amountDue && invoiceId) {
        hideModal();
        dispatch(
          showTopUpWindow({
            trigger: 'SUPPLIER_OFFER',
            triggerAmount: amountDue,
            crr: 0,
            selectedInvoice: { id: invoiceId },
          })
        );
      } else {
        const payload: OfferExecutePayload = {
          invoiceIds: supplierOffer.invoiceIds,
          state: 'EXECUTE',
        };
        setPending(true);

        api.supplierOffers
          .execute({ params: payload, uriParam: supplierOffer.id })
          .then((res) => {
            if (res.status === 'FAILED') {
              notification.error({
                message: res?.invoicesExecutionResult[0]?.reason,
              });
            } else {
              dispatch(setRefreshData(true));
              dispatch(getAnalytics('bill'));
              onSuccessPayInvoice();
            }
          })
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          })
          .finally(() => setPending(false));
      }
    } else {
      notification.warning({ message: 'Cannot pay now' });
    }
  };

  return (
    <Modal
      closable={false}
      title={<ModalHeader onClose={hideModal} title="Pay Invoice" />}
      visible={true}
      onCancel={hideModal}
      onOk={payInvoice}
      okText="Pay Now"
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ type: 'link' }}
    >
      <InvoiceInfo
        amountDue={formatCurrency(amountDue)}
        companyName={companyName}
        dueDate={formatDateForDisplay(dueDate)}
        invoiceNumber={invoiceNumber}
        invoiceType="BILL"
      />

      <AmountStrip
        label="Cashback Available"
        value={formatCurrency(cashReward)}
        css={`
          position: relative;
          left: -2%;
          width: 104%;
        `}
        greyScale
      />
    </Modal>
  );
};

export default PayInvoice;
