import React, { useState, useEffect } from 'react';
import BusinessVerification from './BusinessVerification';
import BusinessOwners from './BusinessOwners';
import Header from './Header';
import PageLoading from 'components/PageLoading';
import queryString from 'query-string';
import styled from 'styled-components';
import Upload from './UserVerification/Upload';
import UserStateCheck from 'components/UserStateCheck';
import UserVerification from './UserVerification';
import Verified from './Verified';
import { Divider } from 'antd';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import api from 'api';
import User, { UserState } from 'types/User';
import Company from 'types/Company';
import { reCaptchaKey } from 'config';

const STEP_USER_DETAILS = 'user_details';
const STEP_UPLOAD = 'upload_document';
const STEP_BUSINESS_DETAILS = 'business_details';
const STEP_BENEFICIAL_OWNERS = 'beneficial_owners';

type Props = {
  isLoggedIn?: true;
  token: string;
} & RouteComponentProps;

const Verification: React.FC<Props> = (props) => {
  const { history, isLoggedIn, location, token } = props;
  const { step, upload_document } =
    location && queryString.parse(location.search);

  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userState, setUserState] = useState<UserState | ''>('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    api.user.get(token).then(async (user) => {
      setUser(user);
      api.company
        .get({ token, companyId: user.companyId })
        .then(({ company }) => {
          setCompany(company);
          setLoading(false);
        });
    });
  }, [history, token]);

  let stepperValue = 0;
  switch (step) {
    case STEP_USER_DETAILS:
      stepperValue = 0;
      break;
    case STEP_UPLOAD:
      stepperValue = 0;
      break;
    case STEP_BUSINESS_DETAILS:
      stepperValue = 1;
      break;
    default:
      break;
  }

  const isBusinessDetailsConfirmed = userState === 'BUSINESS_DETAILS_CONFIRMED';

  const acknowledgementText = company
    ? `By clicking confirm, you warrant that you are an authorised representative of ${company.companyName} and have authorisation to make payments on behalf of ${company.companyName}.`
    : '';

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <VerificationContainer className="verification-screen">
      <UserStateCheck
        token={token}
        setUserStateCallback={setUserState}
        user={user}
        company={company}
      />
      <Helmet>
        <title>Verify your account | Relay</title>
      </Helmet>
      <Header
        current={stepperValue}
        history={history}
        isBusinessDetailsConfirmed={!!isBusinessDetailsConfirmed}
        isLoggedIn={!!isLoggedIn}
        token={token}
      />
      <Divider />
      {step === STEP_USER_DETAILS && !upload_document && user && (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <UserVerification
            countryCode={company?.address?.country || null}
            primaryUser={user}
            setUser={setUser}
            token={token}
          />
        </GoogleReCaptchaProvider>
      )}
      {company && company.verificationStatus === 'VERIFIED' ? (
        <Verified history={history} />
      ) : (
        <>
          {step === STEP_USER_DETAILS && upload_document === 'true' && user && (
            <Upload
              primaryUser={user}
              history={history}
              setUser={setUser}
              token={token}
              isLoggedIn={!!isLoggedIn}
            />
          )}
          {step === STEP_BUSINESS_DETAILS && company && (
            <BusinessVerification
              company={company}
              setCompany={setCompany}
              token={token}
              acknowledgementText={acknowledgementText}
              user={user}
            />
          )}
          {step === STEP_BENEFICIAL_OWNERS && user && (
            <BusinessOwners
              countryCode={company?.address?.country || null}
              primaryOwner={user}
              history={history}
              location={location}
              token={token}
              isLoggedIn={!!isLoggedIn}
            />
          )}
        </>
      )}
    </VerificationContainer>
  );
};

const VerificationContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 0px;
  padding: 10px;
  @media (min-width: 480px) {
    padding: 20px 0;
  }
`;

export default Verification;
