import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AmountOperator,
  OrderNumberOperator,
  InvoiceNumberOperator,
  IssueDateOperator,
} from 'types/RipeCompanyInvoice';
import { useNavigate } from 'hooks';
import moment from 'moment';

const buildBrowserParams = ({
  isAmountChecked,
  amount,
  higherAmount,
  amountOperator,

  isOrderNumberChecked,
  orderNumber,
  orderNumberOperator,

  isInvoiceNumberChecked,
  invoiceNumber,
  invoiceNumberOperator,

  isIssueDateChecked,
  issueDate,
  issueDateEnd,
  issueDateOperator,

  pageNumber,
  pageSize,
  sort,
  supplier,
  tab,
}: {
  isAmountChecked?: boolean;
  amount?: string;
  higherAmount?: string;
  amountOperator?: AmountOperator;

  isOrderNumberChecked?: boolean;
  orderNumber?: string;
  orderNumberOperator?: OrderNumberOperator;

  isInvoiceNumberChecked?: boolean;
  invoiceNumber?: string;
  invoiceNumberOperator?: InvoiceNumberOperator;

  isIssueDateChecked?: boolean;
  issueDate?: string;
  issueDateEnd?: string;
  issueDateOperator?: IssueDateOperator;

  pageNumber?: string | null;
  pageSize?: string | null;
  sort?: string | null;
  supplier?: string | null;
  tab?: string | null;
}) => {
  const params = new URLSearchParams();

  if (isAmountChecked && amount) {
    if (higherAmount && amountOperator === 'between') {
      params.set('amount', `${amount}-${higherAmount}:${amountOperator}`);
    } else if (amountOperator) {
      params.set('amount', `${amount}:${amountOperator}`);
    } else {
      params.set('amount', amount);
    }
  }

  if (isOrderNumberChecked && orderNumber && orderNumberOperator) {
    const isOperatorValid =
      validOrderNumberOperators.includes(orderNumberOperator);
    if (isOperatorValid) {
      params.set('orderNumber', `${orderNumber}:${orderNumberOperator}`);
    }
  }

  if (isInvoiceNumberChecked && invoiceNumber && invoiceNumberOperator) {
    const isOperatorValid = validInvoiceNumberOperators.includes(
      invoiceNumberOperator
    );
    if (isOperatorValid) {
      params.set('invoiceNumber', `${invoiceNumber}:${invoiceNumberOperator}`);
    }
  }

  if (isIssueDateChecked && issueDate) {
    if (issueDateEnd && issueDateOperator === 'between') {
      params.set(
        'issueDate',
        `${issueDate}/${issueDateEnd}:${issueDateOperator}`
      );
    } else {
      params.set('issueDate', `${issueDate}:${issueDateOperator}`);
    }
  }

  if (pageNumber) {
    params.set('page', pageNumber);
  }
  if (pageSize) {
    params.set('size', pageSize);
  }
  if (sort) {
    params.set('sort', sort);
  }
  if (supplier) {
    params.set('supplier', supplier);
  }
  if (tab) {
    params.set('tab', tab);
  }

  return params;
};

const validAmountOperators = ['eq', 'between', 'lt', 'gt'];

const validOrderNumberOperators = ['eq', 'contains'];

const validInvoiceNumberOperators = ['eq', 'contains'];

const validIssueDateOperators = ['eq', 'between', 'gt', 'ge', 'lt', 'le'];

const useFilters = ({ hideFilterDialog }: { hideFilterDialog: () => void }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const locationParams = new URLSearchParams(location.search);

  const tabParam = locationParams.get('tab');
  const pageSizeParam = locationParams.get('size');
  const supplierParam = locationParams.get('supplier');
  const sortParam = locationParams.get('sort');

  const amountParam = locationParams.get('amount');
  const orderNumberParam = locationParams.get('orderNumber');
  const invoiceNumberParam = locationParams.get('invoiceNumber');
  const issueDateParam = locationParams.get('issueDate');

  const [amount, setAmount] = useState('');
  const [amountOperator, setAmountOperator] = useState<AmountOperator>('eq');
  const [higherAmount, setHigherAmount] = useState('');
  const [isAmountActive, setAmountActive] = useState(false);
  const [isAmountChecked, setAmountChecked] = useState(false);

  const [orderNumber, setOrderNumber] = useState('');
  const [orderNumberOperator, setOrderNumberOperator] =
    useState<OrderNumberOperator>('eq');
  const [isOrderNumberActive, setOrderNumberActive] = useState(false);
  const [isOrderNumberChecked, setOrderNumberChecked] = useState(false);

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceNumberOperator, setInvoiceNumberOperator] =
    useState<InvoiceNumberOperator>('eq');
  const [isInvoiceNumberActive, setInvoiceNumberActive] = useState(false);
  const [isInvoiceNumberChecked, setInvoiceNumberChecked] = useState(false);

  const [issueDate, setIssueDate] = useState('');
  const [issueDateEnd, setIssueDateEnd] = useState('');
  const [issueDateOperator, setIssueDateOperator] =
    useState<IssueDateOperator>('eq');
  const [isIssueDateActive, setIssueDateActive] = useState(false);
  const [isIssueDateChecked, setIssueDateChecked] = useState(false);

  const activeFilterCount = [
    isAmountActive,
    isOrderNumberActive,
    isInvoiceNumberActive,
    isIssueDateActive,
  ].filter((item) => item).length;

  useEffect(() => {
    if (!amountParam) {
      setAmountActive(false);
      setAmountChecked(false);
      return;
    }
    const [amount, operator] = amountParam.split(':');
    if (!operator) {
      setAmountActive(true);
      setAmountChecked(true);
      setAmount(amount);
      setAmountOperator('eq');
      return;
    }

    const isOperatorValid = validAmountOperators.includes(operator);

    if (!isOperatorValid) {
      return;
    }

    setAmountActive(true);
    setAmountChecked(true);

    // @ts-expect-error
    setAmountOperator(operator);

    if (operator === 'between') {
      const [lowerAmount, higherAmount] = amount.split('-');
      setAmount(lowerAmount);
      higherAmount && setHigherAmount(higherAmount);
    } else {
      setAmount(amount);
    }
  }, [amountParam]);

  useEffect(() => {
    if (!orderNumberParam) {
      setOrderNumberActive(false);
      setOrderNumberChecked(false);
      return;
    }
    const [orderNumber, operator] = orderNumberParam.split(':');

    const isOperatorValid = validOrderNumberOperators.includes(operator);

    if (!isOperatorValid) {
      return;
    }

    setOrderNumberActive(true);
    setOrderNumberChecked(true);

    // @ts-expect-error
    setOrderNumberOperator(operator);

    setOrderNumber(orderNumber);
  }, [orderNumberParam]);

  useEffect(() => {
    if (!invoiceNumberParam) {
      setInvoiceNumberActive(false);
      setInvoiceNumberChecked(false);
      return;
    }
    const [invoiceNumber, operator] = invoiceNumberParam.split(':');

    const isOperatorValid = validInvoiceNumberOperators.includes(operator);

    if (!isOperatorValid) {
      return;
    }

    setInvoiceNumberActive(true);
    setInvoiceNumberChecked(true);

    // @ts-expect-error
    setInvoiceNumberOperator(operator);

    setInvoiceNumber(invoiceNumber);
  }, [invoiceNumberParam]);

  useEffect(() => {
    if (!issueDateParam) {
      setIssueDateChecked(false);
      setIssueDateActive(false);
      return;
    }
    const [issueDate, operator] = issueDateParam.split(':');
    const isOperatorValid = validIssueDateOperators.includes(operator);
    if (!isOperatorValid) {
      return;
    }

    const [date, endDate] = issueDate.split('/');
    if (date && moment(date, 'YYYY-MM-DD').isValid()) {
      setIssueDate(date);
    }
    if (
      operator === 'between' &&
      endDate &&
      moment(endDate, 'YYYY-MM-DD').isValid()
    ) {
      setIssueDateEnd(endDate);
    }

    setIssueDateChecked(true);
    setIssueDateActive(true);

    // @ts-expect-error
    setIssueDateOperator(operator);
  }, [issueDateParam]);

  const handleDone = () => {
    const params = buildBrowserParams({
      isAmountChecked,
      amount,
      amountOperator,
      higherAmount,

      isOrderNumberChecked,
      orderNumber,
      orderNumberOperator,

      isInvoiceNumberChecked,
      invoiceNumber,
      invoiceNumberOperator,

      isIssueDateChecked,
      issueDate,
      issueDateEnd,
      issueDateOperator,

      pageNumber: '1',
      pageSize: pageSizeParam,
      sort: sortParam,
      supplier: supplierParam,
      tab: tabParam,
    });
    navigate({ params, reset: true });
    hideFilterDialog();
  };

  const handleReset = () => {
    setAmountChecked(false);
    setOrderNumberChecked(false);
    setInvoiceNumberChecked(false);
    setIssueDateChecked(false);

    const params = buildBrowserParams({
      pageNumber: '1',
      pageSize: pageSizeParam,
      sort: sortParam,
      supplier: supplierParam,
      tab: tabParam,
    });
    navigate({ params, reset: true });
    hideFilterDialog();
  };

  const handleChangeAmount = (value: string) => setAmount(value);
  const handleChangeHigherAmount = (value: string) => setHigherAmount(value);
  const handleChangeAmountOperator = (value: AmountOperator) =>
    setAmountOperator(value);

  const handleChangeOrderNumber = (value: string) => setOrderNumber(value);
  const handleChangeOrderNumberOperator = (value: OrderNumberOperator) =>
    setOrderNumberOperator(value);

  const handleChangeInvoiceNumber = (value: string) => setInvoiceNumber(value);
  const handleChangeInvoiceNumberOperator = (value: InvoiceNumberOperator) =>
    setInvoiceNumberOperator(value);

  const amountFilters = {
    amount,
    amountOperator,
    higherAmount,
    isAmountChecked,
    handleChangeAmount,
    handleChangeHigherAmount,
    handleChangeAmountOperator,
    setAmountChecked,
  };

  const orderNumberFilters = {
    orderNumber,
    isChecked: isOrderNumberChecked,
    handleChangeOrderNumber,
    handleCheck: setOrderNumberChecked,
    handleChangeOperator: handleChangeOrderNumberOperator,
    operator: orderNumberOperator,
  };

  const invoiceNumberFilters = {
    invoiceNumber,
    isChecked: isInvoiceNumberChecked,
    handleChangeInvoiceNumber,
    handleCheck: setInvoiceNumberChecked,
    handleChangeOperator: handleChangeInvoiceNumberOperator,
    operator: invoiceNumberOperator,
  };

  const issueDateFilters = {
    date: issueDate,
    endDate: issueDateEnd,
    isChecked: isIssueDateChecked,
    handleChangeDate: setIssueDate,
    handleChangeEndDate: setIssueDateEnd,
    handleCheck: setIssueDateChecked,
    operator: issueDateOperator,
    handleChangeOperator: setIssueDateOperator,
  };

  return {
    activeFilterCount,
    amountFilters,
    orderNumberFilters,
    invoiceNumberFilters,
    issueDateFilters,
    handleDone,
    handleReset,
  };
};

export default useFilters;
