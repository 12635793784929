import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Space, Typography } from 'antd';
import { ButtonType } from 'antd/lib/button';

const { Text } = Typography;

const Label: React.FC<{ label: string }> = ({ label }) => (
  <Text>{label + ':'}</Text>
);

const ValueWrapper = styled.div`
  min-width: 200px;
`;

export const Value: React.FC<{
  isBold?: boolean;
  value?: string | null;
}> = ({ isBold = false, value }) => {
  return (
    <ValueWrapper>
      {value ? (
        <Text strong={isBold}>{value}</Text>
      ) : (
        <Text type="secondary">-</Text>
      )}
    </ValueWrapper>
  );
};

const ActionButton: React.FC<{
  label: string;
  onClick: () => void;
  actionType?: ButtonType;
}> = ({ label, onClick, actionType }) => (
  <Col offset={8}>
    <Button type={actionType} onClick={onClick}>
      {label}
    </Button>
  </Col>
);

export const DetailsItem: React.FC<{
  label: string;
  control?: ReactNode;
  value?: string | null;
  isBold?: boolean;
  action?: () => void;
  actionLabel?: string;
  actionType?: ButtonType;
}> = ({ label, control, value, isBold, action, actionLabel, actionType }) => {
  return (
    <Row align="middle">
      <Col span={9}>
        <Label label={label} />
      </Col>

      <Col span={15}>
        {control ? (
          control
        ) : (
          <Space wrap>
            <Value isBold={isBold} value={value} />
            {action && actionLabel ? (
              <ActionButton
                label={actionLabel}
                onClick={action}
                actionType={actionType}
              />
            ) : null}
          </Space>
        )}
      </Col>
    </Row>
  );
};
