import axios from 'axios';
import Company, { Settings } from 'types/Company';
import BankAccount from 'types/BankAccount';
import CounterParty, {
  CounterPartiesApiResponse,
  CounterPartyWithLinks,
} from 'types/CounterParty';
import Links from 'types/Links';
import User from 'types/User';

const URL = '/company';

type CompanyWithToken = {
  company: Company;
  token?: string;
};

type CompanyIdWithToken = {
  companyId: string;
  token?: string;
};

type CompanyStatusWithToken = {
  status?: string;
  token?: string;
  page: number;
  size: number;
  verificationStatus?: string;
  companyName?: string;
};

type CompanyUsersApiResponse = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  userResource: Array<{ user: User; _links: Links }>;
};

type CompaniesApiResponse = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  companies: Array<{ company: Company; _links: Links }>;
};

type CompanyFromNzbnDatabase = {
  nzbn: string;
  entityName: string;
  sourceRegisterUniqueId: string;
  classifications: any[];
};

const post = ({ company, token }: CompanyWithToken) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}`, company, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const put = ({ company, token }: CompanyWithToken) => {
  return new Promise<{ company: Company; _links: Links }>(function (
    resolve,
    reject
  ) {
    axios
      .put(`${URL}/${company.id}`, company, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const get = ({ companyId, token }: CompanyIdWithToken) => {
  return new Promise<{ company: Company; _links: Links }>(function (
    resolve,
    reject
  ) {
    axios
      .get(`${URL}/${companyId}`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then((response) => {
        const company = response.data;
        resolve(company);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAll = ({
  token,
  status,
  page,
  size,
  verificationStatus,
  companyName,
}: CompanyStatusWithToken) => {
  return new Promise<CompaniesApiResponse>(function (resolve, reject) {
    const params = {
      page,
      size,
      verificationStatus: verificationStatus || undefined,
      status: status || undefined,
      companyName: companyName
        ? `${encodeURIComponent(companyName)}:like`
        : undefined,
    };
    axios
      .get(URL, {
        params,
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUsers = (companyId: string) => {
  return new Promise<CompanyUsersApiResponse>(function (resolve, reject) {
    axios
      .get(`${URL}/${companyId}/users`)
      .then((response) => {
        const users = response.data;
        resolve(users);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getBankAccounts = ({ companyId, token }: CompanyIdWithToken) => {
  return new Promise<BankAccount[]>(function (resolve, reject) {
    axios
      .get(`${URL}/${companyId}/bankaccount`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getBankAccountDocumentLink = ({
  companyId,
  bankAccountId,
}: {
  companyId: string;
  bankAccountId: string;
}) => {
  return new Promise<string>((resolve, reject) => {
    const url = `/company/${companyId}/bankaccount/${bankAccountId}/document`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const postBankAccount = ({
  account,
  companyId,
  token,
}: {
  account: any;
  companyId: string;
  token?: string;
}): Promise<BankAccount> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}/${companyId}/bankaccount`, account, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

type UpdateBankAccountParams = {
  companyId: Company['id'];
  payload: BankAccount;
};

const updateBankAccount = ({
  companyId,
  payload,
}: UpdateBankAccountParams): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/company/${companyId}/bankaccount/${payload.id}`, payload)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const verifyBankAccount = ({
  formData,
  companyId,
  bankAccountId,
}: {
  formData: FormData;
  companyId: Company['id'];
  bankAccountId: BankAccount['id'];
}) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `${URL}/${companyId}/bankaccount/${bankAccountId}/verify`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

type DeleteBankAccountParams = {
  bankAccountId: BankAccount['id'];
  companyId: Company['id'];
};

const deleteBankAccount = ({
  bankAccountId,
  companyId,
}: DeleteBankAccountParams): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/company/${companyId}/bankaccount/${bankAccountId}`)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });
};

type WithdrawBalanceParams = {
  entityId: Company['id'];
  payload: {
    bankAccountId: BankAccount['id'];
    withdrawalAmount: number;
  };
};

const withdrawBalance = ({ entityId, payload }: WithdrawBalanceParams) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/company/${entityId}/withdrawal`, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

type SearchType = {
  searchTerm: string;
  countryCode?: 'AU' | 'NZ';
};

const searchCompanies = ({ searchTerm, countryCode }: SearchType) => {
  return new Promise<CompanyFromNzbnDatabase[]>(function (resolve, reject) {
    const params = {
      companyName: encodeURIComponent(searchTerm),
      countryCode,
    };
    axios
      .get(`${URL}/search`, { params })
      .then(({ data }) => {
        resolve(countryCode === 'AU' ? data.Companies.CompanyDTO : data.items);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getSettings = ({
  companyId,
  token,
}: CompanyIdWithToken): Promise<Settings> => {
  return new Promise<Settings>((resolve, reject) => {
    const url = `/company/${companyId}/settings`;
    axios
      .get(url, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const putSettings = ({
  companyId,
  settings,
  token,
}: CompanyIdWithToken & { settings: Settings }): Promise<Settings> => {
  return new Promise<Settings>((resolve, reject) => {
    if (!companyId) {
      reject('Something went wrong');
    }
    if (!settings) {
      reject('Something went wrong');
    }
    if (!settings.id) {
      reject('Something went wrong');
    }
    const url = `/company/${companyId}/settings/${settings.id}`;
    axios
      .put(url, settings, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const settings = {
  get: getSettings,
  put: putSettings,
};

const getCounterParties = ({
  companyId,
  counterPartyName,
  isPurchaserOffersEnabled,
  size,
}: {
  companyId: string;
  counterPartyName?: string;
  isPurchaserOffersEnabled?: boolean;
  size?: number;
}): Promise<CounterPartiesApiResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${URL}/${companyId}/counter-parties`;
    const params = {
      counterPartyName: counterPartyName
        ? encodeURIComponent(`${counterPartyName}:like`)
        : undefined,
      isPurchaserOffersEnabled,
      size,
    };
    axios
      .get(url, { params })
      .then(({ data }: { data: CounterPartiesApiResponse }) => resolve(data))
      .catch((error) => reject(error));
  });
};

type PatchCounterPartyParams = {
  entityId: Company['id'];
  counterPartyId: CounterParty['id'];
  payload: {
    id: CounterParty['id'];
    modificationTime: CounterParty['modificationTime'];
    value: CounterParty['periodLockDate'];
    path: '/periodLockDate';
    operation: 'replace';
  };
};

const patchCounterParty = ({
  entityId,
  counterPartyId,
  payload,
}: PatchCounterPartyParams): Promise<CounterPartyWithLinks> => {
  return new Promise((resolve, reject) => {
    const url = `/company/${entityId}/counter-parties/${counterPartyId}`;
    axios
      .patch(url, payload)
      .then(({ data }: { data: CounterPartyWithLinks }) => resolve(data))
      .catch((error) => reject(error));
  });
};

export default {
  post,
  put,
  get,
  getAll,
  getUsers,
  getBankAccounts,
  getBankAccountDocumentLink,
  searchCompanies,
  verifyBankAccount,
  updateBankAccount,
  deleteBankAccount,
  postBankAccount,
  withdrawBalance,
  settings,
  getSettings,
  getCounterParties,
  patchCounterParty,
};
