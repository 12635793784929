import React from 'react';
import styled from 'styled-components';
import { Alert, Typography } from 'antd';
import { RowContainer, RowLabel, RowValue } from 'components/row';
import InfoRow from './InfoRow';
import AmountSelection from './AmountSelection';
import colors from 'colors';

const Info = styled(Alert)`
  margin-left: 10px;
  margin-right: 10px;
`;

const QuestionContainer = styled.div`
  margin-top: 24px;
  padding-left: 10px;
  padding-right: 10px;
`;

const ErrorMessage = styled.p`
  color: ${colors.error};
  font-size: 12;
  text-align: right;
  margin-right: 12px;
  margin-top: 10px;
`;

const TopupEntry = ({
  fundsAvailable,
  triggerAmount,
  amountOptions,
  trigger,
  selectAmountOption,
  updateCustomInput,
  errorMessage,
}) => {
  return (
    <div>
      <InfoRow amount={fundsAvailable} />
      {trigger !== 'CUSTOM' && (
        <RowContainer highlighted>
          <RowLabel>
            {trigger === 'PURCHASER_OFFER' || trigger === 'PURCHASER_OFFER_EDIT'
              ? 'Cashpool Amount'
              : trigger === 'NO_OFFER'
              ? 'Amount Payable'
              : 'Total Amount Due'}
          </RowLabel>
          <RowValue>{triggerAmount}</RowValue>
        </RowContainer>
      )}

      {trigger === 'SUPPLIER_OFFER' && (
        <Info
          type="info"
          message="You’ll need to transfer funds to your Relay account to make this payment
        and earn your cash back"
        />
      )}
      <QuestionContainer>
        <Typography.Text>How much would you like to topup?</Typography.Text>
      </QuestionContainer>
      <AmountSelection
        fundsAvailable={fundsAvailable}
        triggerAmount={triggerAmount}
        amountOptions={amountOptions}
        selectAmountOption={selectAmountOption}
        updateCustomInput={updateCustomInput}
      />
      {errorMessage ? (
        <ErrorMessage>
          Topup amount cannot be 0. Please enter a higher amount.
        </ErrorMessage>
      ) : null}
    </div>
  );
};

export default TopupEntry;
