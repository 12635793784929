import React from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from 'themes';

type Props = {
  message: string;
  fromApprouter: boolean;
};
type StyleProps = {
  $fromApprouter: boolean;
};
const Container = styled.div<StyleProps>`
  display: flex;
  height: ${(props) => (props.$fromApprouter ? '100vh' : '10vh')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const animate = keyframes`
  0% {
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
`;

const Circle = styled.div<StyleProps>`
  width: ${(props) => (props.$fromApprouter ? '40px' : '20px')};
  height: ${(props) => (props.$fromApprouter ? '40px' : '20px')};
  border-radius: 10px;
  background-color: ${colors.greys500};
  animation: ${animate} 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    alternate-reverse;
`;

const Text = styled.span<StyleProps>`
  color: ${colors.greys500};
  font-size: ${(props) => props.$fromApprouter && '25px'};
`;

const LogInWait: React.FC<Props> = ({ message, fromApprouter }) => {
  return (
    <Container
      $fromApprouter={fromApprouter}
      data-testid="login-wait-container"
    >
      <Circle $fromApprouter={fromApprouter} data-testid="login-wait-circle" />
      <Text $fromApprouter={fromApprouter}>{message}</Text>
    </Container>
  );
};

export default LogInWait;
