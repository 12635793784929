import api from 'api';
import processError from 'utils/processError';
import { notification } from 'antd';
import Companies from 'types/Company';

const GET_COMPANIES = 'GET_COMPANIES';
const SET_LOADING = 'companies/SET_LOADING';
const UNSET_LOADING = 'companies/UNSET_LOADING';

const LOGOUT = 'LOGOUT';

type State = {
  companies: Companies[];
  isLoading: boolean;
  total: number;
};

type Action = {
  type: string;
  companies: Companies[];
  total: number;
};

const initialState: State = {
  companies: [],
  isLoading: false,
  total: 0,
};

export default (state = initialState, action: Action) => {
  const { type, companies, total } = action;
  switch (type) {
    case GET_COMPANIES:
      return {
        ...state,
        companies,
        total,
        isLoading: false,
      };
    case SET_LOADING:
      return { ...state, isLoading: true };
    case UNSET_LOADING:
      return { ...state, isLoading: false };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

type CompaniesFilter = {
  status?: string;
  page: number;
  size: number;
  token: string;
  verificationStatus?: string;
  companyName?: string;
};

export const getCompanies = ({
  status,
  page,
  size,
  token,
  verificationStatus,
  companyName,
}: CompaniesFilter) => {
  return (dispatch: any) => {
    dispatch({ type: SET_LOADING });
    api.company
      .getAll({ token, status, page, size, verificationStatus, companyName })
      .then((res) => {
        const { companies, totalElements } = res;
        dispatch({
          type: GET_COMPANIES,
          companies: companies.map((c) => c.company),
          total: totalElements,
        });
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message: 'Error',
          description: message,
        });
        dispatch({ type: GET_COMPANIES, companies: [], total: 0 });
      })
      .finally(() => {
        dispatch({ type: UNSET_LOADING });
      });
  };
};
