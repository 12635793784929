import React from 'react';
import formatter from 'utils/currencyFormatter';
import { Typography } from 'antd';

type Props = {
  amount: number | '-';
  style?: React.CSSProperties;
  className?: string;
};

const CurrencyAmount: React.FC<Props> = ({ amount, className, style }) => {
  const formattedAmount = amount === '-' ? amount : formatter.format(amount);
  return (
    <Typography.Text
      className={className}
      style={style}
      data-testid="currency-amount-text"
    >
      {formattedAmount}
    </Typography.Text>
  );
};

export default CurrencyAmount;
