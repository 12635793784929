import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getTodayTimestamp, toTitleCase } from 'utils';

import { hideCreateInvoiceWindow } from 'store/invoices';
import { getSupplierOffersAvailable } from 'store/offers';
import { setBackgroundCheckPurchaserOffer, setRefreshData } from 'store/ui';
import { colors } from 'themes';
import { RadioButtons } from 'ds';
import CompanyNameInput from 'components/CompanyNameInput';
import CompanyName from 'components/SelectCompany';
import PaymentDueEntry from 'components/PaymentDueEntry';
import AmountInput from 'components/AmountInput';

import InputItem from 'components/InputItem';
import { ModalHeader } from 'components';

import ConfirmationScreen from './ConfirmationScreen';
import processError from 'utils/processError';

import api from 'api';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  RadioChangeEvent,
  Row as AntRow,
  Select,
  Skeleton,
} from 'antd';
import getTimestamp from 'utils/getTimestamp';
import featureFlags from 'config/featureFlags';
import { CloseOutlined } from '@ant-design/icons';
import { useDueDate } from './useDueDate';
import { Settings } from 'types/Company';
import { GlAccount } from 'types/AccountCode';
import breakpoints from 'styles/layout/breakpoints';

const isFutureDate = (date: moment.MomentInput) =>
  moment(date).isAfter(getTodayTimestamp());

const isCreditNoteCreationEnabled = featureFlags.addCreditNote;

const RecordSelection = styled(RadioButtons)`
  margin-bottom: 1.5rem;
`;

const Row = styled.div<{ $error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-bottom: ${(props) => (props.$error ? '0px' : '30px')};
`;

const Label = styled.p<{ $company?: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${colors.greys600};
  text-align-last: left;
  margin-top: 10px;
  width: ${(props) => (props.$company ? '62px' : '180px')};
`;

const CompanyContainer = styled.div`
  height: 56px;
  background-color: ${colors.greys100};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: auto !important;
  padding-bottom: auto !important;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Line = styled.hr`
  height: 0;
  margin-top: 25px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
`;

const LabelContainer = styled.div``;

const Error = styled.p`
  text-align: right;
  color: red;
  flex: 1;
  position: relative;
  top: 5;
`;

const StyledInput = styled(Input)`
  width: 150px;
`;

const LIMIT_FOR_PO_NOT_REQUIRED = 5000;

const ERROR_AMOUNT_LIMIT = 'Amount should be less than $5000';

const recordTypes = [
  { label: 'Bill', value: 'BILL' },
  { label: 'Credit Note', value: 'CREDIT_NOTE' },
];

const AddInvoice = () => {
  const dispatch = useDispatch();

  const { isVisibleNewInvoiceWindow, companyNameToAddInvoice, type } =
    useSelector(
      (state: {
        invoices: {
          companyNameToAddInvoice: string | null;
          isVisibleNewInvoiceWindow: boolean;
          type: 'BILL' | 'INVOICE';
        };
      }) => state.invoices
    );

  const companyId: string = useSelector(
    (state: any) => state.auth.user.companyId
  );

  const country = useSelector(
    (state: any) => state.auth.user.company?.company?.address?.country
  );

  const settings = useSelector(
    (state: { settings: Settings | null }) => state.settings
  );

  const isInternalSearchOnlyEnabled = settings?.isInternalSearchOnlyEnabled;
  const isRipeEnabled = settings?.isRipeEnabled;
  const isCreateBillInXeroEnabled = settings?.isCreateBillInXeroEnabled;

  const { defaultDueDate, loadingDefaultDueDate } = useDueDate({
    invoiceType: type,
    supplierName: companyNameToAddInvoice,
  });

  const isCountryAU = country === 'AU';

  const defaultIssueDate = moment();

  const [isAddingCreditNote, setAddingCreditNote] = useState(false);

  const shouldShowCopyToXero =
    isCreateBillInXeroEnabled &&
    isRipeEnabled &&
    type === 'BILL' &&
    !isAddingCreditNote;

  const shouldShowAccountCodeSelection =
    isRipeEnabled && type === 'BILL' && !isAddingCreditNote;

  const location = useLocation();
  // allow user to add bill with copy xero option from supplier details page.
  // identify supplier details page by 'suppliers' and 'bills' in the url(quick fix)
  const pathNameSplit = location.pathname.split('/');
  const isBillsSummaryPage = pathNameSplit.includes('bills');
  const isSuppliersDetailsPage = pathNameSplit.includes('suppliers');
  const shouldCheckCopyToXero =
    shouldShowCopyToXero && (isBillsSummaryPage || isSuppliersDetailsPage);

  const [glCodes, setGlCodes] = useState<GlAccount[]>([]);
  const [selectedGlCode, setSelectedGlCode] = useState<
    GlAccount['accountCode'] | null
  >(null);
  const [isLoadingGlCodes, setLoadingGlCodes] = useState(false);

  const [company, setCompany] = useState(
    companyNameToAddInvoice ? { companyName: companyNameToAddInvoice } : null
  );
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [dueDate, setDueDate] = useState(defaultDueDate);
  const [issueDate, setIssueDate] = useState(defaultIssueDate);
  const [amount, setAmount] = useState<string | null>(null);
  const [copyToXero, setCopyToXero] = useState(shouldCheckCopyToXero);
  const [poNotRequired, setPoNotRequired] = useState(false);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');

  // errors
  const [companyError, setCompanyError] = useState('');
  const [invoiceError, setInvoiceError] = useState('');
  const [dateValidationError, setDateValidationError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [purchaseOrderNumberError, setPurchaseOrderNumberError] = useState('');
  const [issueDateError, setIssueDateError] = useState('');

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isNewCompanySelected, setNewCompanySelected] = useState(true);

  // for company input disable
  const [editingCompany, setEditingCompany] = useState(
    companyNameToAddInvoice ? false : true
  );

  useEffect(() => {
    if (companyId && shouldShowAccountCodeSelection) {
      setLoadingGlCodes(true);
      api.xero
        .getAccounts({ companyId, size: 1000, isVisibleInRelay: true })
        .then((glAccounts) => {
          setGlCodes(glAccounts.accounts.map((account) => account.account));
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.warning({
            message: 'Could not load GL codes',
            description: message,
          });
        })
        .finally(() => setLoadingGlCodes(false));
    }
  }, [companyId, shouldShowAccountCodeSelection]);

  useEffect(() => {
    if (defaultDueDate) {
      setDueDate(defaultDueDate);
    }
  }, [defaultDueDate]);

  const COMPANY_MISSING_ERROR = isCountryAU
    ? 'Please type company name'
    : 'Please select company';

  const handleHide = () => dispatch(hideCreateInvoiceWindow());

  // on select company
  const onSelectCompany = (company: { companyName: string }) => {
    setCompany(company);
    setCompanyError('');
    setNewCompanySelected(true);
    setEditingCompany(false);
  };

  const handleError = (error: any) => {
    const { message } = processError(error);
    notification.error({ message });
  };

  const onSuccess = () => {
    setDueDate(defaultDueDate);
    setIssueDate(defaultIssueDate);
    setAmount(null);
    setInvoiceNumber('');
    setCompany(null);

    dispatch(setRefreshData(true));
    handleHide();
  };

  const submitBill = () => {
    if (company?.companyName) {
      setLoading(true);
      const payload = {
        billGLCode:
          isRipeEnabled && selectedGlCode ? selectedGlCode : undefined,

        billNumber: invoiceNumber,
        dueDate: getTimestamp(dueDate),
        issueDate: getTimestamp(issueDate),
        issuedFor: companyId,
        issuedByCompanyName: company.companyName,
        total: Number(amount),

        createBillInXero: copyToXero || undefined,
        billOrderNumber:
          copyToXero && !poNotRequired ? purchaseOrderNumber.trim() : undefined,
      };
      api.invoices
        .postBill(payload)
        .then(() => {
          dispatch(getSupplierOffersAvailable());
          dispatch(
            setBackgroundCheckPurchaserOffer({
              shouldCheck: true,
              direction: 'OFFERED',
            })
          );
          onSuccess();
        })
        .catch((error) => handleError(error))
        .finally(() => setLoading(false));
    } else {
      notification.error({ message: 'Cannot create bill without company' });
    }
  };

  const submitInvoice = () => {
    if (company?.companyName) {
      setLoading(true);
      const payload = {
        dueDate: getTimestamp(dueDate),
        issuedBy: companyId,
        issuedForCompanyName: company.companyName,
        invoiceNumber: invoiceNumber.trim(),
        total: Number(amount),
      };

      api.invoices
        .postInvoice(payload)
        .then(() => {
          dispatch(
            setBackgroundCheckPurchaserOffer({
              shouldCheck: true,
              direction: 'AVAILABLE',
            })
          );
          onSuccess();
        })
        .catch((error) => handleError(error))
        .finally(() => setLoading(true));
    } else {
      notification.error({ message: 'Cannot create invoice without company' });
    }
  };

  const submitCreditNote = () => {
    if (company?.companyName && amount) {
      setLoading(true);
      const payload = {
        creditNumber: invoiceNumber.trim(),
        issuedFor: companyId,
        issuedByCompanyName: toTitleCase(company.companyName),
        total: amount,
      };
      api.creditNotes
        .createCreditNote(payload)
        .then(() => onSuccess())
        .catch((error) => handleError(error))
        .finally(() => setLoading(false));
    } else {
      notification.error({
        message:
          'Cannot create credit note without company and/or without amount',
      });
    }
  };

  const onSubmit = () => {
    if (type === 'BILL') {
      if (isAddingCreditNote) {
        submitCreditNote();
      } else {
        submitBill();
      }
    } else {
      submitInvoice();
    }
  };

  const cancel = () => {
    setIsEditing(true);
    handleHide();
    setDueDate(defaultDueDate);
    setIssueDate(defaultIssueDate);
    setAmount(null);
    setInvoiceNumber('');
    setCompany(null);
  };

  const handleChangeDueDate = (dueDate: Moment | null) => {
    if (!dueDate) {
      return;
    }
    if (moment(dueDate).isBefore(moment(), 'day')) {
      setDateValidationError('Cannot add invoice with past due date');
      setDueDate(dueDate);
    } else if (moment(dueDate).isSame(moment(), 'day')) {
      setDateValidationError('Cannot add invoice with current due date');
      setDueDate(dueDate);
    } else {
      setDateValidationError('');
      setDueDate(dueDate);
    }
  };

  const changeIssueDate = (issueDate: Moment | null) => {
    if (!issueDate) {
      return;
    }
    setIssueDate(issueDate);
    if (isFutureDate(issueDate)) {
      setIssueDateError('Cannot add invoice with future issue date');
    } else {
      setIssueDateError('');
    }
  };
  const validateAmount = (amount: any, currentAmount: any) => {
    if (amount < 1) {
      setAmountError('Amount cannot be less than $1');
      setAmount(amount);
    } else if (poNotRequired && amount > LIMIT_FOR_PO_NOT_REQUIRED) {
      setAmountError(ERROR_AMOUNT_LIMIT);
      setAmount(amount);
    } else {
      if (isNaN(amount)) {
        setAmountError('');
        if (amount === '.') {
          setAmount('0.');
        } else {
          setAmount(currentAmount);
        }
      } else {
        setAmount(amount);
        setAmountError('');
      }
    }
  };

  const handleChangeGlCode = (glCode: GlAccount['accountCode']) =>
    setSelectedGlCode(glCode);

  const validate = () => {
    let hasErrors = false;
    if (isCountryAU) {
      if (!company?.companyName) {
        setCompanyError(COMPANY_MISSING_ERROR);
        hasErrors = true;
      }
    } else {
      if (!isNewCompanySelected && !company) {
        setCompanyError(COMPANY_MISSING_ERROR);
        hasErrors = true;
      }
      if (isNewCompanySelected && !company) {
        setCompanyError(COMPANY_MISSING_ERROR);
        hasErrors = true;
      }
      if (!isNewCompanySelected && company) {
        setCompanyError(COMPANY_MISSING_ERROR);
        hasErrors = true;
      }
    }
    if (!invoiceNumber) {
      setInvoiceError('This field is required *');
      hasErrors = true;
    }
    if (invoiceNumber && !invoiceNumber.trim()) {
      setInvoiceError('This field is required *');
      hasErrors = true;
    }
    if (!dueDate) {
      setDateValidationError('This field is required *');
      hasErrors = true;
    }
    if (dateValidationError || issueDateError) {
      hasErrors = true;
    }
    if (type !== 'INVOICE' && !issueDate) {
      setIssueDateError('This field is required *');
      hasErrors = true;
    }

    if (!amount) {
      setAmountError('This field is required *');
      hasErrors = true;
    }
    if (poNotRequired && Number(amount) >= 5000) {
      setAmountError(ERROR_AMOUNT_LIMIT);
      hasErrors = true;
    }

    if (
      !isAddingCreditNote &&
      copyToXero &&
      !poNotRequired &&
      !purchaseOrderNumber.trim()
    ) {
      setPurchaseOrderNumberError('This field is required *');
      hasErrors = true;
    }
    if (!hasErrors) {
      // We don't have any validation errors
      // Let's move forward from the editing state to the confirmation state
      setIsEditing(false);
    }
  };

  const handleChangeRecordType = (e: RadioChangeEvent) => {
    if (e.target.value === 'CREDIT_NOTE') {
      setAddingCreditNote(true);
    } else {
      setAddingCreditNote(false);
    }
  };

  const companyNamePlaceholder =
    type === 'INVOICE' ? 'Enter Customer Name' : 'Enter Supplier Name';

  return (
    <Modal
      aria-label={type === 'BILL' ? 'Add new bill' : 'Add new invoice'}
      visible={isVisibleNewInvoiceWindow}
      closable={false}
      css={`
        .ant-modal-body {
          padding-bottom: 0;
        }
        .ant-modal-footer {
          padding-top: 0;
        }
      `}
      footer={
        <AntRow justify={isEditing ? 'end' : 'space-between'}>
          {!isEditing && (
            <Button
              css={`
                position: relative;
                left: 0;
              `}
              disabled={loading}
              onClick={() => setIsEditing(true)}
              type="ghost"
            >
              Back
            </Button>
          )}
          <Col>
            <Button disabled={loading} onClick={cancel} type="ghost">
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={isEditing ? validate : onSubmit}
              type="primary"
            >
              {isEditing ? 'Add' : 'Confirm'}
            </Button>
          </Col>
        </AntRow>
      }
      title={
        <ModalHeader
          title={
            isEditing
              ? type === 'INVOICE'
                ? 'Add Invoice'
                : isCreditNoteCreationEnabled && isSuppliersDetailsPage
                ? 'Add'
                : 'Add Bill'
              : type === 'INVOICE'
              ? 'Confirm Invoice Details'
              : 'Confirm Details'
          }
          onClose={cancel}
        />
      }
    >
      {loadingDefaultDueDate && isLoadingGlCodes ? (
        <Skeleton />
      ) : isEditing ? (
        <>
          {type === 'BILL' &&
            isCreditNoteCreationEnabled &&
            isSuppliersDetailsPage && (
              <RecordSelection
                options={recordTypes}
                onChange={handleChangeRecordType}
                value={isAddingCreditNote ? 'CREDIT_NOTE' : 'BILL'}
              />
            )}
          <CompanyContainer>
            <LabelContainer>
              <Label $company>{type === 'INVOICE' ? 'TO' : 'FROM'}</Label>
            </LabelContainer>
            <div
              style={{
                width: '100%',
                marginTop:
                  companyError ||
                  (!isNewCompanySelected && !company && COMPANY_MISSING_ERROR)
                    ? 57
                    : 27,
              }}
            >
              {isCountryAU ? (
                <CompanyNameInput
                  placeholder={companyNamePlaceholder}
                  companyName={company?.companyName || ''}
                  setCompanyName={(companyName) => {
                    setCompany({ companyName });
                  }}
                  error={companyError}
                />
              ) : (
                <InputItem
                  label=""
                  error={
                    companyError ||
                    (!isNewCompanySelected && !company && COMPANY_MISSING_ERROR)
                  }
                >
                  <CompanyName
                    isInternalSearchOnlyEnabled={isInternalSearchOnlyEnabled}
                    selectedCompanyName={company ? company.companyName : ''}
                    onSelectCompany={onSelectCompany}
                    placeholder={companyNamePlaceholder}
                    fromCreateInvoice
                    onInput={() => {
                      setNewCompanySelected(false);
                    }}
                    suffix={
                      company &&
                      !editingCompany && (
                        <CloseOutlined
                          onClick={() => {
                            setCompany(null);
                            setEditingCompany(true);
                          }}
                        />
                      )
                    }
                    disabled={!editingCompany}
                    hidePrefix={isSuppliersDetailsPage}
                    error=""
                  />
                </InputItem>
              )}
            </div>
          </CompanyContainer>
          <Line />

          <Row $error={!!invoiceError}>
            <LabelContainer>
              <Label>
                {type === 'INVOICE'
                  ? 'INVOICE'
                  : isAddingCreditNote
                  ? 'CREDIT NOTE'
                  : 'BILL'}
              </Label>
            </LabelContainer>
            <StyledInput
              prefix="#"
              value={invoiceNumber}
              onChange={(e) => {
                setInvoiceNumber(e.target.value);
                setInvoiceError('');
              }}
              placeholder={
                type === 'INVOICE'
                  ? 'Enter Invoice ID'
                  : isAddingCreditNote
                  ? 'ID Number'
                  : 'Enter Bill ID'
              }
            />
          </Row>
          <Error>{invoiceError}</Error>
          {type !== 'INVOICE' && (
            <>
              <Row $error={!!issueDateError}>
                <LabelContainer>
                  <Label>ISSUE DATE</Label>
                </LabelContainer>
                <PaymentDueEntry
                  date={issueDate}
                  onChange={(date) => changeIssueDate(date)}
                  placeholder="Enter Issue Date"
                  testId="issue-date-input"
                  isEditing
                />
              </Row>
              <Error>{issueDateError}</Error>
            </>
          )}

          {!isAddingCreditNote && (
            <>
              <Row $error={!!dateValidationError}>
                <LabelContainer>
                  <Label>DATE DUE</Label>
                </LabelContainer>
                <PaymentDueEntry
                  date={dueDate}
                  onChange={handleChangeDueDate}
                  placeholder="Enter Due Date"
                  testId="due-date-input"
                  isEditing
                />
              </Row>
              <Error>{dateValidationError}</Error>
            </>
          )}

          <Row $error={!!amountError}>
            <LabelContainer>
              <Label>
                {isAddingCreditNote ? 'CREDIT AMOUNT' : 'AMOUNT DUE (inc. GST)'}
              </Label>
            </LabelContainer>
            <AmountInput
              value={amount}
              onChange={(e: any) =>
                validateAmount(e.target.value.trim(), amount)
              }
              discountEntry
              onBlur={() =>
                setAmount(amount ? parseFloat(amount).toFixed(2) : null)
              }
            />
          </Row>

          <Error>{amountError}</Error>

          {shouldShowCopyToXero && (
            <>
              <Row>
                <LabelContainer>
                  <Label>CREATE IN XERO</Label>
                </LabelContainer>
                <Checkbox
                  checked={copyToXero}
                  data-testid="copy-to-xero"
                  onChange={(e) => {
                    setCopyToXero(e.target.checked);
                    setPurchaseOrderNumberError('');
                  }}
                  disabled={isBillsSummaryPage || isSuppliersDetailsPage}
                />
              </Row>

              {copyToXero && (
                <>
                  <Row>
                    <LabelContainer>
                      <Label>PO NOT REQUIRED</Label>
                    </LabelContainer>
                    <Checkbox
                      checked={poNotRequired}
                      data-testid="po-not-required"
                      onChange={(e) => {
                        const { checked } = e.target;
                        setPoNotRequired(checked);
                        if (checked) {
                          setPurchaseOrderNumberError('');
                        }
                      }}
                    />
                  </Row>

                  <Row $error={!!purchaseOrderNumberError}>
                    <LabelContainer>
                      <Label>PURCHASE ORDER NUMBER</Label>
                    </LabelContainer>
                    <StyledInput
                      disabled={poNotRequired}
                      prefix="#"
                      value={purchaseOrderNumber}
                      onChange={(e) => {
                        setPurchaseOrderNumber(e.target.value);
                        setPurchaseOrderNumberError('');
                      }}
                      placeholder="Enter PO Number"
                    />
                  </Row>
                  <Error>{purchaseOrderNumberError}</Error>
                </>
              )}
            </>
          )}

          {shouldShowAccountCodeSelection && (
            <Row>
              <LabelContainer>
                <Label>GL CODE</Label>
              </LabelContainer>
              <Select
                allowClear
                css={`
                  width: 100%;
                  @media screen and (min-width: ${breakpoints.tablet}) {
                    width: 260px;
                  }
                `}
                data-testid="gl-code-select"
                filterOption={(input, option) => {
                  const matchingAccount = glCodes.find(
                    (item) => item.accountCode === option?.value
                  );
                  return matchingAccount
                    ? matchingAccount.accountCode.includes(input) ||
                        matchingAccount?.accountName
                          .toString()
                          .toLowerCase()
                          .includes(input.toString().toLowerCase())
                    : false;
                }}
                filterSort={(optionA, optionB) =>
                  optionA
                    .toString()
                    .toLowerCase()
                    .localeCompare(optionB.toString().toLowerCase())
                }
                onChange={handleChangeGlCode}
                options={glCodes.map((glCode) => ({
                  label: `${glCode.accountName} - ${glCode.accountCode}`,
                  value: glCode.accountCode,
                }))}
                placeholder="Default"
                showSearch
                value={selectedGlCode || undefined}
              />
            </Row>
          )}

          <Line />
        </>
      ) : (
        <ConfirmationScreen
          isAddingCreditNote={isAddingCreditNote}
          dueDate={dueDate}
          issueDate={issueDate}
          companyName={company?.companyName || ''}
          amount={String(amount)}
          invoiceNumber={invoiceNumber || ''}
          invoiceType={type}
        />
      )}
    </Modal>
  );
};

export default AddInvoice;
