// We call an invoice a live invoice if the inovice is not past its due date.
// Given an array of invoices, this function returns an array of invoices that are live invoices.

import Invoice from 'types/Invoice';
import getTodayTimestamp from './getTodayTimestamp';

const today = getTodayTimestamp();

const getLiveInvoices = (invoices: Array<Invoice>): Array<Invoice> =>
  invoices.filter((invoice) => invoice.dueDate >= today);

export default getLiveInvoices;
