import React from 'react';
import styled from 'styled-components';
import { Alert, Divider } from 'antd';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import Myob from './Myob';
import Xero from './Xero';

const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const BusinessAccounts = ({
  company,
  loading,
  loadingMyob,
  loadingMyobAccountRight,
  handleClick,
  myobHandleClick,
  token,
  location,
}) => {
  const error = location && queryString.parse(location.search).error;
  const showError = () => {
    if (error) {
      return (
        <ErrorContainer>
          {error === 'xero' || error === 'myob' ? (
            <Alert
              message={`Error in connecting to ${
                error === 'myob' ? 'MYOB' : 'Xero'
              } account`}
              type="error"
            />
          ) : (
            <Alert message="Error!" type="error" />
          )}
          <Divider />
        </ErrorContainer>
      );
    } else return null;
  };
  if (
    !company.connectedToMyobEssentials &&
    !company.connectedToXero &&
    !company.connectedToMyobAccountRight
  ) {
    return (
      <>
        {showError()}
        <Row>
          {window.env.FEATURE_FLAGS && window.env.FEATURE_FLAGS.XERO_CONNECT ? (
            <Xero
              company={company}
              loading={loading}
              handleClick={handleClick}
            />
          ) : null}

          {window.env.FEATURE_FLAGS &&
          window.env.FEATURE_FLAGS.MYOB_CONNECT &&
          window.env.FEATURE_FLAGS.MYOB_ESSENTIALS ? (
            <Myob
              company={company}
              loadingMyob={loadingMyob}
              myobHandleClick={myobHandleClick}
              token={token}
              myobAccount="Essentials"
            />
          ) : null}
        </Row>
        {window.env.FEATURE_FLAGS &&
        window.env.FEATURE_FLAGS.MYOB_CONNECT &&
        window.env.FEATURE_FLAGS.MYOB_ACCOUNT_RIGHT ? (
          <Myob
            company={company}
            loadingMyob={loadingMyobAccountRight}
            myobHandleClick={myobHandleClick}
            token={token}
            myobAccount="Account Right"
          />
        ) : null}
      </>
    );
  } else {
    return (
      <>
        {showError()}
        <Row>
          {window.env.FEATURE_FLAGS &&
          window.env.FEATURE_FLAGS.XERO_CONNECT &&
          company.connectedToXero ? (
            <Xero
              company={company}
              loading={loading}
              handleClick={handleClick}
            />
          ) : null}

          {window.env.FEATURE_FLAGS &&
          window.env.FEATURE_FLAGS.MYOB_CONNECT &&
          window.env.FEATURE_FLAGS.MYOB_ESSENTIALS &&
          company.connectedToMyobEssentials ? (
            <Myob
              company={company}
              loadingMyob={loadingMyob}
              myobHandleClick={myobHandleClick}
              token={token}
              myobAccount="Essentials"
            />
          ) : null}
        </Row>
        {window.env.FEATURE_FLAGS &&
        window.env.FEATURE_FLAGS.MYOB_CONNECT &&
        window.env.FEATURE_FLAGS.MYOB_ACCOUNT_RIGHT &&
        company.connectedToMyobAccountRight ? (
          <Myob
            company={company}
            loadingMyob={loadingMyobAccountRight}
            myobHandleClick={myobHandleClick}
            token={token}
            myobAccount="Account Right"
          />
        ) : null}
      </>
    );
  }
};

export default withRouter(BusinessAccounts);
