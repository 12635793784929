import React, { FC, useEffect, useState } from 'react';
import api from 'api';
import colors from 'colors';
import styled from 'styled-components/macro';
import AmountStrip from 'components/AmountStrip';
import CreditNoteList from './CreditNoteList';
import { formatCurrency } from 'utils';
import Invoice, { CreditNoteAllocation } from 'types/Invoice';
import { AllocationType } from 'types/CreditNote';
import { Skeleton } from 'antd';

const WiderAmountStrip = styled(AmountStrip)`
  padding: 9px 40px;
  margin-top: 20px;
`;

const StyledAmountStrip = styled(WiderAmountStrip)`
  .amount-strip-label {
    color: ${colors.greys900};
  }
  .amount-strip-value {
    color: ${colors.greys900};
  }
`;

// type definitions
export type InvoiceAllocation = AllocationType & {
  amountDue: number;
  invoiceNumber: string;
};

type CreditAllocationType = {
  invoiceNumber: Invoice['billNumber'];
  creditNoteAllocations: Invoice['creditNoteAllocations'];
  creditNoteValue: Invoice['creditNoteValue'];
};

type Allocation = CreditNoteAllocation & { creditNumber: string };

type Props = {
  allocations: Invoice['creditNoteAllocations'];
  allocatedAmount: Invoice['creditNoteValue'];
  onClickCreditNote: (value: string) => void;
};

const CreditAllocations: FC<Props> = ({
  allocations,
  allocatedAmount,
  onClickCreditNote,
}) => {
  const [creditNotes, setCreditNotes] = useState<Allocation[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let creditNotes: Allocation[] = [];
    if (allocations && allocations.length) {
      setLoading(true);
      const creditNoteIds = allocations.map(
        (allocation) => allocation.creditNoteId
      );
      api.creditNotes
        .getAll({ ids: creditNoteIds.toString() })
        .then((result) => {
          result.creditNotes.forEach((cn) => {
            const allocatedAmount = allocations
              .filter((item) => item.creditNoteId === cn.creditNote.id)
              ?.reduce(
                (total, item) => total + parseFloat(`${item.allocatedAmount}`),
                0.0
              );
            allocatedAmount &&
              creditNotes.push({
                creditNoteId: cn.creditNote.id,
                creditNumber: cn.creditNote.creditNumber,
                allocatedAmount: Number(allocatedAmount.toFixed(2)),
              });
          });
          const sortedCreditNotes = creditNotes.sort(
            (a, b) => Number(b.allocatedAmount) - Number(a.allocatedAmount)
          );
          setLoading(false);
          setCreditNotes(sortedCreditNotes);
        });
    }
  }, [allocations]);

  return loading ? (
    <Skeleton />
  ) : (
    <>
      <CreditNoteList creditNotes={creditNotes} onClick={onClickCreditNote} />
      <StyledAmountStrip
        label="Total Credit Applied"
        value={allocatedAmount ? `-${formatCurrency(allocatedAmount)}` : '-'}
        background="#F6F2FF"
      />
    </>
  );
};

export default CreditAllocations;
