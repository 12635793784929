import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { RadioChangeEvent } from 'antd';
import { Chips } from 'ds';
import { useMediaQuery } from 'hooks';

const FilterChips: React.FC<{
  className?: string;
  isTableFilters?: boolean;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  selected?: string;
}> = ({ className, isTableFilters, onChange, options, selected }) => {
  const isLargerThan1366 = useMediaQuery('(min-width: 1366px)');

  const handleChange = (e: RadioChangeEvent) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Chips
      className={className}
      onChange={handleChange}
      options={options}
      css={
        isTableFilters
          ? `
        position: absolute;
        ${isLargerThan1366 ? 'left: 370px;' : 'right: 0;'}
        z-index: 10;
        top: 7px;
      `
          : ''
      }
      value={selected}
    />
  );
};

export default FilterChips;
