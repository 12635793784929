import moment from 'moment';
import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { InvoiceFilters } from 'types/InvoiceFilters';

const buildBrowserUrl = ({
  path,
  entity,
  page,
  size,
  tab,
  status,
  isAmountChecked,
  amount,
  higherAmount,
  amountOperator,
  isDateChecked,
  date,
  endDate,
  dateOperator,
  inTheNext,
  inTheNextSelection,
  isInvoiceNumberChecked,
  invoiceNumber,
  invoiceNumberOperator,
  isMatchesXeroChecked,
  matchesXero,
}: {
  path: string;
  entity?: string | null;
  page?: string | null;
  size?: string | null;
  tab?: string | null;
  status?: string | null;
  isAmountChecked?: boolean;
  amount?: string | null;
  higherAmount?: string | null;
  amountOperator?: string | null;
  isDateChecked?: boolean;
  date?: string | null;
  endDate?: string | null;
  dateOperator?: string | null;
  inTheNext?: number | null;
  inTheNextSelection?: 'days' | 'months' | null;
  isInvoiceNumberChecked?: boolean;
  invoiceNumber?: string | null;
  invoiceNumberOperator?: string | null;
  isMatchesXeroChecked?: boolean;
  matchesXero?: string | null;
}) => {
  let params = new URLSearchParams();

  if (entity) {
    params.set('entity', entity);
  }

  if (page) {
    params.set('page', page);
  }

  if (size) {
    params.set('size', size);
  }

  if (tab) {
    params.set('tab', tab);
  }

  if (status) {
    params.set('status', status);
  }

  if (isAmountChecked && amount) {
    if (higherAmount && amountOperator === 'range') {
      params.set('amount', `${amount}-${higherAmount}:${amountOperator}`);
    } else if (amountOperator) {
      params.set('amount', `${amount}:${amountOperator}`);
    } else {
      params.set('amount', amount);
    }
  }

  if (isDateChecked) {
    if (dateOperator === 'in' && inTheNext && inTheNextSelection) {
      params.set('in', `${inTheNext}:${inTheNextSelection}`);
    } else if (date) {
      if (endDate && dateOperator === 'range') {
        params.set('date', `${date}-${endDate}:${dateOperator}`);
      } else if (dateOperator) {
        params.set('date', `${date}:${dateOperator}`);
      } else {
        params.set('date', date);
      }
    }
  }

  if (isInvoiceNumberChecked && invoiceNumber && invoiceNumberOperator) {
    params.set('number', `${invoiceNumber}:${invoiceNumberOperator}`);
  }

  if (isMatchesXeroChecked && matchesXero) {
    params.set('isMatchedPayablesValue', matchesXero);
  }

  return `${path}?${params}`;
};

const validAmountOperators = ['gt', 'lt', 'range'];

const validDateOperators = ['eq', 'range', 'gt', 'gte', 'lt', 'lte'];

const validInvoiceNumberOperators = ['in', 'eq', 'like'];

const useFilter = ({
  filters,
  hideDropdown,
}: {
  filters: InvoiceFilters;
  hideDropdown: () => void;
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const entity = queryParams.get('entity');
  const size = queryParams.get('size');
  const status = queryParams.get('status');
  const tab = queryParams.get('tab');
  const { pathname } = location;

  const [isAmountActive, setAmountActive] = useState(false);
  const [isAmountChecked, setAmountChecked] = useState(false);
  const [amount, setAmount] = useState<string | null>(null);
  const [amountOperator, setAmountOperator] = useState<string>('eq');
  const [higherAmount, setHigherAmount] = useState<string | null>(null);

  const [isDateActive, setDateActive] = useState(false);
  const [isDateChecked, setDateChecked] = useState(false);
  const [dateDue, setDateDue] = useState<string | null>(filters.dateDue);
  const [dateOperator, setDateOperator] = useState<string>('eq');
  const [inTheNext, setInTheNext] = useState<number | null>(null);
  const [inTheNextSelection, setInTheNextSelection] = useState<
    'days' | 'months'
  >('days');
  const [endDate, setEndDate] = useState<string | null>(null);

  const [isInvoiceNumberActive, setInvoiceNumberActive] = useState(false);
  const [isInvoiceNumberChecked, setInvoiceNumberChecked] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(filters.invoiceNumber);
  const [invoiceNumberOperator, setInvoiceNumberOperator] = useState('in');

  const [isMatchesXeroActive, setMatchesXeroActive] = useState(false);
  const [isMatchesXeroChecked, setMatchesXeroChecked] = useState(false);
  const [matchesXero, setMatchesXero] = useState(filters.matchesXero);

  const activeFilterCount = [
    isAmountActive,
    isDateActive,
    isInvoiceNumberActive,
    isMatchesXeroActive,
  ].filter((item) => item).length;

  useEffect(() => {
    if (!filters.amount) {
      setAmountActive(false);
      setAmountChecked(false);
      return;
    }
    setAmountActive(true);
    setAmountChecked(true);
    const [amount, operator] = filters.amount.split(':');
    if (operator) {
      if (validAmountOperators.includes(operator)) {
        setAmountOperator(operator);
      }
      if (amount) {
        const splittedAmount = amount.split('-');
        if (splittedAmount.length === 2) {
          setAmount(splittedAmount[0]);
          setHigherAmount(splittedAmount[1]);
        } else {
          setAmount(amount);
        }
      }
    }
  }, [filters.amount]);

  useEffect(() => {
    if (!filters.dateDue) {
      return;
    }
    setDateActive(true);
    setDateChecked(true);
    const [dateProps, operator] = filters.dateDue.split(':');
    if (operator) {
      if (validDateOperators.includes(operator)) {
        const [date, endDate] = dateProps.split('-');
        if (date && moment(date, 'DDMMYYYY').isValid()) {
          setDateDue(date);
        }
        if (
          operator === 'range' &&
          endDate &&
          moment(endDate, 'DDMMYYYY').isValid()
        ) {
          setEndDate(endDate);
        }
        setDateOperator(operator);
      }
    }
  }, [filters.dateDue]);

  useEffect(() => {
    if (!filters.inTheNext) {
      return;
    }
    const [inTheNext, operator] = filters.inTheNext.split(':');
    if (inTheNext && operator) {
      if (operator === 'days' || operator === 'months') {
        setInTheNext(Number(inTheNext));
        setInTheNextSelection(operator);
        setDateOperator('in');
        setDateActive(true);
        setDateChecked(true);
      }
    }
  }, [filters.inTheNext]);

  useEffect(() => {
    if (filters.invoiceNumber) {
      const [invoiceNumber, operator] = filters.invoiceNumber.split(':');
      if (operator) {
        if (validInvoiceNumberOperators.includes(operator)) {
          setInvoiceNumberOperator(operator);
          if (invoiceNumber) {
            setInvoiceNumber(invoiceNumber);
          }
          setInvoiceNumberActive(true);
          setInvoiceNumberChecked(true);
        }
      }
    } else {
      setInvoiceNumberActive(false);
      setInvoiceNumberChecked(false);
    }
  }, [filters.invoiceNumber]);

  useEffect(() => {
    if (filters.matchesXero) {
      setMatchesXeroActive(true);
      setMatchesXeroChecked(true);
      setMatchesXero(filters.matchesXero);
    } else {
      setMatchesXeroActive(false);
      setMatchesXeroChecked(false);
    }
  }, [filters.matchesXero]);

  useEffect(() => {
    if (!filters.dateDue && !filters.inTheNext) {
      setDateActive(false);
      setDateChecked(false);
    }
  }, [filters.dateDue, filters.inTheNext]);

  const handleChangeDateDue = (value: string) => {
    setDateDue(value);
  };

  const handleChangeInvoiceNumber = (value: string) => {
    setInvoiceNumber(value);
  };

  const handleChangeMatchesXero = (value: string) => {
    setMatchesXero(value);
  };

  const handleDone = () => {
    const url = buildBrowserUrl({
      path: pathname,
      entity,
      page: '1',
      size,
      status,
      tab,
      isAmountChecked,
      amount,
      higherAmount,
      amountOperator: amountOperator === 'eq' ? undefined : amountOperator,
      isDateChecked,
      date: dateDue,
      endDate,
      dateOperator: dateOperator === 'eq' ? undefined : dateOperator,
      inTheNext,
      inTheNextSelection,
      isInvoiceNumberChecked,
      invoiceNumber,
      invoiceNumberOperator,
      isMatchesXeroChecked,
      matchesXero,
    });
    history.push(url);
    hideDropdown();
  };

  const handleReset = () => {
    const url = buildBrowserUrl({
      path: pathname,
      entity,
      page: '1',
      size,
      status,
      tab,
    });
    setAmountChecked(false);
    setDateChecked(false);
    setInvoiceNumberChecked(false);
    setMatchesXeroChecked(false);
    history.push(url);
    hideDropdown();
  };

  const amountProps = {
    amount,
    amountOperator,
    handleChangeAmount: setAmount,
    handleChangeHigherAmount: setHigherAmount,
    handleChangeOperator: setAmountOperator,
    handleCheck: setAmountChecked,
    higherAmount: higherAmount,
    isChecked: isAmountChecked,
    operator: amountOperator,
  };

  const dateProps = {
    date: dateDue,
    handleChangeDateDue: handleChangeDateDue,
    handleChangeEndDate: setEndDate,
    handleChangeOperator: setDateOperator,
    handleCheck: setDateChecked,
    endDate: endDate,
    inTheNext: inTheNext,
    handleChangeInTheNext: setInTheNext,
    inTheNextSelection: inTheNextSelection,
    handleChangeInTheNextSelection: setInTheNextSelection,
    isActive: isDateActive,
    isChecked: isDateChecked,
    operator: dateOperator,
  };

  const invoiceNumberProps = {
    invoiceNumber: invoiceNumber,
    isChecked: isInvoiceNumberChecked,
    handleChangeInvoiceNumber: handleChangeInvoiceNumber,
    handleCheck: setInvoiceNumberChecked,
    operator: invoiceNumberOperator,
    handleChangeOperator: setInvoiceNumberOperator,
  };

  const matchesXeroProps = {
    matchesXero: matchesXero,
    isChecked: isMatchesXeroChecked,
    handleChangeMatchesXero: handleChangeMatchesXero,
    handleCheck: setMatchesXeroChecked,
  };

  return {
    activeFilterCount,
    amountProps,
    dateProps,
    invoiceNumberProps,
    matchesXeroProps,
    handleDone,
    handleReset,
  };
};

export default useFilter;
