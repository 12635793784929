import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';

import queryString from 'query-string';

import { login } from 'store/auth';
import { setCompanyId, setAccountRightCompanyFiles } from 'store/signup';
import api from 'api';

import MyobError from './MyobError';
class MyobCallback extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      callbackError: false,
    };
  }

  componentDidMount() {
    const {
      token,
      myobAccount,
      setAccountRightCompanyFilesAction,
      history,
      user,
    } = this.props;

    const MYOB_SCOPE =
      myobAccount === 'Essentials'
        ? window.env.MYOB_ESSENTIALS_SCOPE
        : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
    const authorization_code = queryString.parse(window.location.search).code;

    api.myob
      .getMyobCallback({
        authorization_code,
        scope: MYOB_SCOPE,
        token,
      })
      .then(({ data }) => {
        if (myobAccount === 'Essentials') {
          history.replace(data.url);
        } else {
          setAccountRightCompanyFilesAction(data.companyFiles);
          if (user) {
            const company = user.company && user.company.company;
            const {
              myobEssentialsAccountsConfigured,
              myobAccountRightAccountsConfigured,
            } = company;
            if (
              myobEssentialsAccountsConfigured ||
              myobAccountRightAccountsConfigured
            ) {
              history.replace('/settings');
            } else {
              history.replace(
                '/settings/connect-business-account?config=myob-company-file'
              );
            }
          } else {
            history.replace('/signup?step=myob-company-file');
          }
        }
      })
      .catch((error) => {
        notification.error({
          message:
            error ??
            'Something went wrong. Please try again or contact Relay if the issue persists.',
        });
        this.setState({ loading: false, callbackError: true });
      });
  }

  render() {
    const { loading, callbackError } = this.state;
    const { history } = this.props;

    return (
      <MyobError
        history={history}
        callbackError={callbackError}
        loading={loading}
      />
    );
  }
}

const mapStateToProps = ({ signup, auth }) => ({
  user: auth.user,
  token: auth.user ? auth.user.token : signup.token,
  myobAccount: signup.myobAccount,
});

const mapDispatchToProps = {
  login,
  setCompanyId,
  setAccountRightCompanyFilesAction: setAccountRightCompanyFiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyobCallback));
