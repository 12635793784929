import React, { Key, useState } from 'react';
import { colors } from 'themes';
import { Table, TableProps } from 'antd';
import styled from 'styled-components';
import breakpoints from 'styles/layout/breakpoints';
import { ColumnsType } from 'antd/lib/table';

const StyledTable = styled(Table)<{ $hasScroll: boolean }>`
  .ant-table-thead {
    .ant-table-cell {
      background: none;
      font-weight: normal !important;
      color: ${colors.greys500};
      ::before {
        display: none;
      }
    }
  }
  .ant-table-body {
    ${({ $hasScroll }) => ($hasScroll ? 'overflow: auto !important' : '')}
  }
  .ant-table-cell {
    font-size: 14px;
    padding: 8px;
    @media screen and (max-width: ${breakpoints.mobile}) {
      font-size: 12px;
    }
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
  }
`;

type Props<T> = {
  allowSelection?: boolean;
  onRowSelection?: (selectedIds: Key[]) => void;
} & TableProps<T>;

type Identified = {
  id: string;
  [key: string]: any;
};

const SimpleTable = <T extends Identified>({
  columns,
  dataSource,
  pagination,
  allowSelection,
  scroll,
  onRowSelection,
}: Props<T>) => {
  const [selectedItemId, setSelectedItemId] = useState<Key[]>(
    dataSource?.map((item: T) => item.id) ?? []
  );

  const onChange = (selectedIds: Key[]) => {
    setSelectedItemId(selectedIds);
    if (onRowSelection) onRowSelection(selectedIds);
  };

  const rowSelection = {
    selectedRowKeys: selectedItemId,
    onChange,
  };

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns as ColumnsType<Object>}
      pagination={pagination}
      rowSelection={allowSelection ? rowSelection : undefined}
      rowKey="id"
      scroll={scroll?.x ? scroll : { ...scroll, x: '100%' }}
      $hasScroll={!!scroll}
    />
  );
};

export default SimpleTable;
