import React from 'react';
import styled from 'styled-components';
import { Button, Space } from 'antd';
import { ChevronLeft, ChevronRight } from 'assets/icons';

const Container = styled(Space)`
  position: absolute;
  left: 190px;
  top: 5px;
  z-index: 10;
`;

const StyledButton = styled(Button)`
  border: 1px solid #286eff !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MonthNavigation: React.FC<{
  handleClickPrevious: () => void;
  handleClickNext: () => void;
  isNextMonthDisabled: boolean;
}> = ({ handleClickPrevious, handleClickNext, isNextMonthDisabled }) => {
  return (
    <Container>
      <StyledButton
        onClick={handleClickPrevious}
        icon={<ChevronLeft />}
        shape="circle"
        type="ghost"
        data-testid="month-navigation-left"
      />
      <StyledButton
        disabled={isNextMonthDisabled}
        onClick={handleClickNext}
        icon={<ChevronRight />}
        shape="circle"
        type="ghost"
        data-testid="month-navigation-right"
      />
    </Container>
  );
};

export default MonthNavigation;
