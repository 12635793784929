import React from 'react';
import styled from 'styled-components';

import accountNumberFormatter from 'utils/accountNumberFormatter';

import InputComponent from 'components/Input';
import InfoRow from './InfoRow';
import NumberFormat from 'react-number-format';

import { colors } from 'themes';

const Heading = styled.h6`
  margin-bottom: 2px;
`;
const Error = styled.span`
  font-size: 13px;
  color: red;
  margin-top: -10px;
`;
const StyledInput = styled(InputComponent)`
  ::placeholder {
    color: ${colors.greys300};
  }
`;

const AccountDetails = (props) => {
  const {
    isConfirmAddBankAccount,
    accountName,
    setAccountName,
    unformattedNumber,
    setAccountNumberValidationError,
    accountNumberValidationError,
    setAccountNumber,
    accountNumber,
    isCountryAU,
  } = props;

  const accountNumberFormat = isCountryAU
    ? '##-####-#######-##'
    : '##-####-#######-###';

  return (
    <>
      {isConfirmAddBankAccount ? (
        <InfoRow label="Account name" value={accountName} />
      ) : (
        <div style={styles.marginBottom}>
          <Heading>Account name</Heading>
          <InputComponent
            type="search"
            value={accountName}
            onChange={(value) => setAccountName(value)}
            multiline={true}
            id={accountName}
          />
        </div>
      )}
      {isConfirmAddBankAccount ? (
        <InfoRow
          label="Account number"
          value={accountNumberFormatter(unformattedNumber)}
        />
      ) : (
        <>
          <Heading>Account number</Heading>
          <NumberFormat
            customInput={StyledInput}
            isNumericString
            format={accountNumberFormat}
            onValueChange={({ value }) => {
              value && setAccountNumberValidationError(false);
              setAccountNumber(value);
            }}
            placeholder={accountNumberFormat}
            value={accountNumber}
            shouldReturnEvent
          />
          {accountNumberValidationError && (
            <Error>Enter valid account number</Error>
          )}
        </>
      )}
    </>
  );
};

const styles = {
  marginBottom: { marginBottom: 10 },
};

export default AccountDetails;
