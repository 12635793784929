import axios from 'axios';
import queryString from 'query-string';
import CreditNote, {
  CreditNoteApiResponse,
  CreditNotesApiResponse,
  CreditNoteBulkDeleteResponse,
} from 'types/CreditNote';

const createCreditNote = (payload: {
  billGLCode?: string | null;
  issuedFor: string;
  issuedByCompanyName: string;
  total: string;
  creditNumber: string;
}): Promise<CreditNote> =>
  new Promise((resolve, reject) => {
    axios
      .post('/credit-note', payload)
      .then(({ data }: { data: { creditNote: CreditNote } }) =>
        resolve(data.creditNote)
      )
      .catch((error) => reject(error));
  });

type GetParams = {
  createdByCompanyId?: string;
  createdForCompanyId?: string;
  ids?: string;
};

const get = (id: string): Promise<CreditNote> =>
  new Promise<CreditNote>((resolve, reject) => {
    const url = `/credit-note/${id}`;
    axios
      .get(url)
      .then(({ data }: { data: CreditNoteApiResponse }) =>
        resolve(data.creditNote)
      )
      .catch((error) => reject(error));
  });

const getAll = ({
  createdByCompanyId,
  createdForCompanyId,
  ids,
}: GetParams): Promise<CreditNotesApiResponse> =>
  new Promise<CreditNotesApiResponse>((resolve, reject) => {
    const url = queryString.stringifyUrl({
      url: '/credit-note',
      query: { createdByCompanyId, createdForCompanyId, ids },
    });
    axios
      .get(url)
      .then(({ data }: { data: CreditNotesApiResponse }) => resolve(data))
      .catch((error) => reject(error));
  });

const put = (payload: CreditNote): Promise<CreditNote> =>
  new Promise<CreditNote>((resolve, reject) => {
    const url = `/credit-note/${payload.id}`;
    axios
      .put(url, payload)
      .then(({ data }: { data: CreditNote }) => resolve(data))
      .catch((error) => reject(error));
  });

const patch = (payload: {
  id: string;
  modificationTime: number;
  path: string;
  operation: string;
  value: string | number;
}): Promise<CreditNote> =>
  new Promise<CreditNote>((resolve, reject) => {
    const url = `/credit-note/${payload.id}`;
    axios
      .patch(url, payload)
      .then(({ data }: { data: { creditNote: CreditNote } }) =>
        resolve(data.creditNote)
      )
      .catch((error) => reject(error));
  });

const allocateCredit = ({
  id,
  payload,
}: {
  id: string;
  payload: Array<{ invoiceId: string; allocatedAmount: number }>;
}) =>
  new Promise((resolve, reject) => {
    const url = `/credit-note/${id}/invoice-allocations`;
    axios
      .put(url, payload)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });

const bulkDelete = (payload: { creditNoteIds: Array<string> }) =>
  new Promise<CreditNoteBulkDeleteResponse>((resolve, reject) => {
    const url = `/credit-note/batch`;
    axios
      .delete(url, { data: payload })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });

export default {
  bulkDelete,
  createCreditNote,
  get,
  getAll,
  patch,
  put,
  allocateCredit,
};
