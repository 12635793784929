import React, { Component } from 'react';
import { connect } from 'react-redux';
import formatter from 'utils/currencyFormatter';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import { clearCompanyNameToAddInvoice } from 'store/invoices';
import { getSupplierOffers, getPurchaserOffersAvailable } from 'store/offers';
import { setDataToRefresh, setToolbarTitle } from 'store/ui';
import { getCompany, getBankAccounts } from 'store/auth';
import { getCompanySettings } from 'store/settings';

import api from 'api';

import Tabs from 'components/Tabs';
import InvoiceLoadTrigger from 'components/InvoiceLoadTrigger';
import PageTracker from 'components/PageTracker';
import { ShowPayNowHelper, SummaryBlock } from 'components';
import spacing from 'styles/layout/spacing';
import styled from 'styled-components';

const SummaryBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: ${spacing.gutter.lg};
  row-gap: ${spacing.gutter.lg};
`;
class Invoices extends Component {
  UNSAFE_componentWillUnmount() {
    const { user } = this.props;
    if (user.roles && user.roles[0] !== 'ROLE_ADMIN') {
      this.props.getSupplierOffers();
    }
  }

  componentDidMount() {
    this.props.setToolbarTitle('Invoices');
    this.props.clearCompanyNameToAddInvoice();

    const { user } = this.props;
    //get company for update xero connection status
    const { companyId } = user;
    if (companyId) {
      this.props.getCompany(companyId);
      this.props.getBankAccounts(companyId);
      api.company.settings.get({ companyId }).then((data) => {
        this.props.getCompanySettings(data);
      });
    }

    const connectedToXero =
      user.company.company && user.company.company.connectedToXero;
    const connectedToMyobEssentials =
      user.company.company && user.company.company.connectedToMyobEssentials;
    const status = user.company.company && user.company.company.status;
    const connectedToMyobAccountRight =
      user.company.company && user.company.company.connectedToMyobAccountRight;

    if (
      connectedToXero ||
      connectedToMyobEssentials ||
      connectedToMyobAccountRight ||
      status === 'PENDING' ||
      status === 'ACTIVE' ||
      status === 'INVITED'
    ) {
      this.props.getSupplierOffers();
      this.props.getPurchaserOffersAvailable();
    }

    this.props.setDataToRefresh('INVOICE');
  }

  render() {
    const {
      invoices,
      amountPaid,
      amountRequested,
      maxServiceFeesReceived,
      maxServiceFeesRequested,
    } = this.props;

    return (
      <>
        <ShowPayNowHelper />
        <Helmet>
          <title>Invoices | Relay</title>
        </Helmet>
        <PageTracker />
        <InvoiceLoadTrigger />
        <SummaryBlockContainer>
          <SummaryBlock
            invoices={invoices}
            title="ACTIVE PROMPT PAYMENT REQUESTS"
            highlightedRowLabel="Maximum Service Fee"
            highlightedRowValue={formatter.format(
              maxServiceFeesRequested ? maxServiceFeesRequested : 0
            )}
            label="Amount Requested"
            value={formatter.format(amountRequested ? amountRequested : 0)}
          />
          <SummaryBlock
            invoices={invoices}
            title="PROMPT PAYMENTS RECEIVED"
            subTitle={moment().format('MMM YYYY')}
            highlightedRowLabel="Service Fees"
            highlightedRowValue={formatter.format(
              maxServiceFeesReceived ? maxServiceFeesReceived : 0
            )}
            label="Amount Paid Promptly"
            value={formatter.format(amountPaid ? amountPaid : 0)}
          />
        </SummaryBlockContainer>
        <Tabs data={invoices} type="INVOICE" />
      </>
    );
  }
}

const mapStateToProps = ({ invoices, auth, analytics }) => ({
  invoices: invoices.invoices,
  user: auth.user,
  maxServiceFeesReceived: analytics.maxServiceFeesReceived,
  maxServiceFeesRequested: analytics.maxServiceFeesRequested,
  amountRequested: analytics.totalAmountRequested,
  amountPaid: analytics.totalAmountPaid,
});

const mapDispatchToProps = {
  clearCompanyNameToAddInvoice,
  getSupplierOffers,
  setToolbarTitle,
  getPurchaserOffersAvailable,
  getCompany,
  getBankAccounts,
  getCompanySettings,
  setDataToRefresh,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
