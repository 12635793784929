import React, { useState, useEffect } from 'react';
import NavLink from './NavLink';
import styled from 'styled-components';
import { relayNewLogo } from 'assets/images';
import { Drawer, Menu } from 'antd';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from 'react-router';
import spacing from 'styles/layout/spacing';
import featureFlags from 'config/featureFlags';
import colors from 'colors';

const sideMenuColor = '#f0edea';
const Container = styled.div<{
  isDesktop: boolean;
  isDrawerOpen: boolean;
}>`
  width: ${spacing.sideBar.width};
  background-color: ${sideMenuColor};
  height: 100vh;
`;

const StyledMenu = styled(Menu)<{ $openSubMenu: string }>`
  background-color: ${sideMenuColor} !important;
  .ant-menu-submenu-title {
    background: ${(props) =>
      props.$openSubMenu === '/bills' ? '#f0f7ff !important' : ''};
    :hover {
      background: ${(props) =>
        props.$openSubMenu === '/bills' ? '#f0f7ff !important' : ''};
    }
    color: ${(props) =>
      props.$openSubMenu === '/bills' ? colors.primary + ' !important' : ''};
  }
  .ant-menu-sub {
    background: ${sideMenuColor} !important;
  }
  && .ant-menu-item {
    ::after {
      border-right: 3px solid #f6f7f8;
    }
  }
  && .ant-menu-item-selected {
    ::after {
      border-right: 3px solid #f6f7f8;
    }
  }
`;

const Logo = styled.img`
  width: 100px;
  margin: 13px 0px 15px 25px;
  transition: all 300ms;
`;

const SubmMenuItem = styled(Menu.Item)`
  padding-left: 24px !important;
  margin-top: 0px;
  margin-bottom: 0px;
`;

type NavItem = {
  label: string;
  to: string;
  icon?: string;
  isEnabled: boolean;
  isRipePage?: boolean;
  subMenuItems?: {
    label: string;
    to: string;
    icon?: string;
    isEnabled: boolean;
  }[];
};

const adminNavItems: NavItem[] = [
  {
    label: 'Topups',
    to: '/',
    icon: 'receipt',
    isEnabled: true,
  },
  {
    label: 'Bank Accounts',
    to: '/bank-accounts',
    icon: 'account_balance',
    isEnabled: true,
  },
  {
    label: 'Companies',
    to: '/companies',
    icon: '',
    isEnabled: true,
  },
  {
    label: 'System',
    to: '/system',
    icon: '',
    isEnabled: true,
  },
  {
    label: 'Live Offers',
    to: '/live-offers',
    icon: '',
    isEnabled: true,
  },
];

type Props = {
  isAdmin: boolean;
  isDesktop: boolean;
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  pathname: string;
  history: RouteComponentProps['history'];
};

const SideBar: React.FC<Props> = ({
  isAdmin,
  isDesktop,
  isDrawerOpen,
  closeDrawer,
  pathname,
  history,
}) => {
  // To control the open state of submenu
  // Update this variable with the key of the submenu that is to be opened
  const [openSubMenu, setOpenSubMenu] = useState<string>('');

  const settings = useSelector((state: any) => state.settings);

  const isRipeEnabled = settings?.isRipeEnabled;
  const isRipeLiteEnabled = settings?.isRipeLiteEnabled;

  useEffect(() => {
    const splittedPath = pathname.split('/');
    setOpenSubMenu(splittedPath[1]);
  }, [pathname]);

  const userNavItems: NavItem[] = [
    {
      label: 'Account',
      to: '/account',
      icon: 'account_circle',
      isEnabled: true,
    },
    {
      label: 'Dashboard',
      to: '/dashboard',
      isRipePage: true,
      isEnabled: featureFlags.dashboardEnabled,
    },
    {
      label: 'Invoices',
      to: '/invoices',
      icon: 'assignment',
      isEnabled: true,
    },
    {
      label: 'Bills',
      to: '/bills',
      icon: 'credit_card',
      isEnabled: true,
      subMenuItems: [
        // {
        //   label: 'Statements',
        //   to: '/bills/statements',
        // },
        {
          label: 'Pending Review',
          to: '/bills/pending-review',
          isEnabled: true,
        },
        {
          label: 'Pending Approval',
          to: '/bills/pending-approval',
          isEnabled: featureFlags.pendingApprovalEnabled && isRipeLiteEnabled,
        },
        {
          label: 'Variances',
          to: '/bills/variances',
          isEnabled: true,
        },
        {
          label: 'Processing',
          to: '/bills/processing',
          isEnabled: true,
        },
        // Not removing the next item, anticipating that it may be needed in the future
        // {
        //   label: 'Unmatched',
        //   to: '/bills/unmatched',
        // },
        {
          label: 'Blocked',
          to: '/bills/blocked',
          isEnabled: true,
        },
      ],
    },
  ];

  const navItems = isAdmin ? adminNavItems : userNavItems;

  const renderChildren = () => (
    <>
      <Logo src={relayNewLogo} alt="relay-logo" />
      <StyledMenu
        mode="inline"
        theme="light"
        defaultSelectedKeys={[pathname]}
        selectedKeys={[pathname]}
        expandIcon={<></>}
        openKeys={[openSubMenu]}
        $openSubMenu={pathname}
      >
        {navItems.map((item) => {
          if (isRipeEnabled && item.subMenuItems) {
            return (
              <Menu.SubMenu
                title={item.label}
                style={{
                  color: '#3B4048',
                  fontSize: 16,
                }}
                onTitleClick={() => {
                  history.push(`/bills`);
                }}
                key="bills"
              >
                {item.subMenuItems.map(
                  (item) =>
                    item.isEnabled && (
                      <SubmMenuItem key={item.to} style={styles.subMenuItem}>
                        <NavLink {...item} isSubMenu={true} />
                      </SubmMenuItem>
                    )
                )}
              </Menu.SubMenu>
            );
          } else {
            if (
              item.isEnabled &&
              ((isRipeEnabled && item.isRipePage) || !item.isRipePage)
            ) {
              return (
                <Menu.Item key={item.to}>
                  <NavLink {...item} isSubMenu={false} />
                </Menu.Item>
              );
            }
            return null;
          }
        })}
      </StyledMenu>
    </>
  );

  return isDesktop ? (
    <Container isDesktop={isDesktop} isDrawerOpen={isDrawerOpen}>
      {renderChildren()}
    </Container>
  ) : (
    <Drawer
      contentWrapperStyle={styles.drawer.contentWrapper}
      bodyStyle={styles.drawer.body}
      headerStyle={styles.drawer.header}
      width="90vw"
      closeIcon={null}
      onClose={closeDrawer}
      placement="left"
      visible={isDrawerOpen}
    >
      {renderChildren()}
    </Drawer>
  );
};

const styles = {
  subMenuItem: { marginBottom: 0, marginTop: 0 },
  drawer: {
    contentWrapper: {
      maxWidth: spacing.sideBar.width,
    },
    body: {
      backgroundColor: sideMenuColor,
      padding: 0,
    },
    header: {
      display: 'none',
    },
  },
};

export default SideBar;
