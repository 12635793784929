import React from 'react';

import PaginationComponent from 'components/Pagination';

type Props = {
  rows: number;
  pageSize: number;
  pageNumber: number;
  handleChangePageParams: ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }) => void;
  totalPages: number;
};

const PaginationComp: React.FC<Props> = ({
  rows,
  pageSize,
  pageNumber,
  handleChangePageParams,
  totalPages,
}) => {
  const startRow = Math.ceil((pageNumber - 1) * pageSize + 1);
  const endRow = Math.ceil(pageNumber * pageSize);
  const pagesArray = [];

  for (let i = 1; i <= totalPages; i++) {
    pagesArray.push(i);
  }

  const handlePageChange = (newPageNumber: number) => {
    handleChangePageParams({ pageNumber: newPageNumber, pageSize });
  };

  const handleSizeChange = (e: any) => {
    handleChangePageParams({ pageNumber: 1, pageSize: Number(e.target.value) });
  };

  const MIN_PAGE_SIZE = 10; //pagination control shows only if total elements is grater than 10

  if (rows > MIN_PAGE_SIZE) {
    return (
      <PaginationComponent
        rows={rows}
        startRow={startRow}
        endRow={endRow}
        pageSize={pageSize}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
      />
    );
  } else {
    return null;
  }
};

export default PaginationComp;
