import { unzip } from 'unzipit';

export const readImageFromZip = async (data: string | ArrayBuffer | Blob) => {
  const { entries } = await unzip(data);

  const imageEntry = Object.entries(entries).find(
    ([name]) =>
      name.includes('png') || name.includes('jpeg') || name.includes('jpg')
  );

  if (imageEntry) {
    const imageBlob = await imageEntry[1].blob('image/png');
    const imageUrl = URL.createObjectURL(imageBlob);
    return imageUrl;
  }

  return '';
};
