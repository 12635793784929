import React from 'react';
import 'styled-components/macro';
import spacing from 'styles/layout/spacing';
import { Space, Skeleton } from 'antd';

export const LoadingIndicator = () => (
  <Space
    direction="vertical"
    css={`
      width: 100%;
      height: 104px;
      padding-top: ${spacing.gutter.lg};
    `}
  >
    <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
    <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
  </Space>
);
