const accountNumberFormatter = (
  accountNumber: null | undefined | string
): string => {
  let formattedAccountNumber = null;

  if (
    accountNumber === null ||
    accountNumber === undefined ||
    accountNumber === ''
  ) {
    formattedAccountNumber = '';
  } else {
    if (accountNumber.search('-') !== -1) {
      // If the string already contains atleast a dash, do not format it.
      // Just return as is.
      return accountNumber;
    }

    if (accountNumber.length === 15) {
      formattedAccountNumber =
        accountNumber &&
        accountNumber.substring(0, 2) +
          '-' +
          accountNumber.substring(2, 6) +
          '-' +
          accountNumber.substring(6, 13) +
          '-' +
          accountNumber.substring(13, 15);
    } else if (accountNumber.length === 11) {
      formattedAccountNumber =
        accountNumber &&
        accountNumber.substring(0, 2) +
          '-' +
          accountNumber.substring(2, 6) +
          '-' +
          accountNumber.substring(6, 13);
    } else if (accountNumber.length <= 10) {
      formattedAccountNumber = '';
      for (let index = 0; index < accountNumber.length; index++) {
        if (index !== 0 && index % 3 === 0) {
          formattedAccountNumber = `${formattedAccountNumber}-${accountNumber[index]}`;
        } else {
          formattedAccountNumber = `${formattedAccountNumber}${accountNumber[index]}`;
        }
      }
    } else {
      formattedAccountNumber =
        accountNumber &&
        accountNumber.substring(0, 2) +
          '-' +
          accountNumber.substring(2, 6) +
          '-' +
          accountNumber.substring(6, 13) +
          '-' +
          accountNumber.substring(13, 16);
    }
  }

  return formattedAccountNumber;
};

export default accountNumberFormatter;
