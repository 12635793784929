import React, { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import colors from 'colors';
const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
`;
const Reset = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.warms.red};
  cursor: pointer;
`;
const Distribute = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.blues.dark};
  cursor: pointer;
  margin-left: 16px;
`;

type Props = {
  distributeCreditEqually: () => void;
  equalDistribute: boolean;
  resetDistribution: () => void;
};

const DistributeButton: FC<Props> = ({
  distributeCreditEqually,
  equalDistribute,
  resetDistribution,
}) => {
  return (
    <Container>
      {equalDistribute ? (
        <Reset onClick={resetDistribution}>Reset</Reset>
      ) : (
        <Distribute onClick={distributeCreditEqually}>Distribute</Distribute>
      )}
    </Container>
  );
};

export default DistributeButton;
