import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes';

const Beta = styled.div`
  width: 50px;
  height: 14px;
  background: #ff9300;
  border-radius: 2px;
  display: flex;
  align-items: center;
  text-align: center;
`;
const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${colors.greys100};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;

const BetaBadge = () => {
  return (
    <Beta>
      <Text>Beta</Text>
    </Beta>
  );
};

export default BetaBadge;
