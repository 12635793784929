import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components/macro';
import spacing from 'styles/layout/spacing';
import colors from 'colors';

const { Text } = Typography;

const Container = styled.div`
  position: relative;
  top: ${spacing.gutter.md};
  padding-bottom: ${spacing.gutter.lg};
`;

export const Note: React.FC = () => (
  <Container>
    <Text
      css={`
        color: ${colors.greys500};
        font-size: 12px;
      `}
    >
      If your prompt payment request is successful the Total Fee will be
      automatically deducted from your account balance. (Total Fee = Discount
      Amount + Success Fee).
    </Text>
  </Container>
);
