import React from 'react';
import { Row, Col, Skeleton } from 'antd';

const Loading: React.FC = () => (
  <Row gutter={64}>
    <Col span={12}>
      <Skeleton active />
    </Col>
    <Col span={12}>
      <Skeleton.Image />
    </Col>
  </Row>
);

export default Loading;
