import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Checkbox } from 'antd';

import ProgressBar from 'components/ProgressBar';
import colors from 'colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextBold = styled.p`
  font-weight: bold;
  margin-left: 2px;
  margin-right: 2px;
  color: ${(props) => (props.errorText ? colors.error : colors.greys700)};
`;
const Row = styled.p`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.errorText ? colors.error : colors.greys700)};
  cursor: ${(props) => props.clickable && 'pointer'};
`;

const Ruler = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
`;

const ConfirmationScreen = ({
  isFinish,
  invoiceType,
  totalInvoices,
  validInvoices,
  isChecked,
  setChecked,
  fileFormat,
  failedInvoicesCount,
  warningInvoices,
  warningInvoicesForDownload,
  progressView,
  importProgress,
  failedInvoicesForDownload,
}) => {
  const isValidOne = validInvoices === 1 ? true : false;
  const isFailedOne = failedInvoicesCount === 1 ? true : false;
  const isWarningOne = warningInvoices.length === 1 ? true : false;

  const convertArrayOfObjectsToCSV = ({ invoicesForDownload, fileHeader }) => {
    let result, columnIndex, headers, columnDelimiter, lineDelimiter, data;

    data = invoicesForDownload || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = ',';
    lineDelimiter = '\n';
    // TODO: fileFormat = xero
    headers = fileHeader;
    let arrayKeys = [
      `${invoiceType === 'INVOICE' ? 'invoiceNumber' : 'billNumber'}`,
      `${invoiceType === 'BILL' && 'billOrderNumber'}`,
      `${
        invoiceType === 'INVOICE'
          ? 'issuedForCompanyName'
          : 'issuedByCompanyName'
      }`,
      'dueDate',
      'total',
      'contactEmail',
      `${failedInvoicesForDownload.length && 'reason'}`,
    ];
    result = '';
    result += headers.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      columnIndex = 0;
      arrayKeys.forEach(function (key) {
        if (columnIndex > 0) result += columnDelimiter;
        if (key === 'dueDate') {
          result += moment(item[key]).format('DD/MM/YYYY').valueOf();
        } else {
          result += item[key] ? item[key] : '';
        }
        columnIndex++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  const downloadInvoices = () => {
    let data, filename, link;
    const fileHeader = [
      'Invoice Number',
      `${invoiceType === 'INVOICE' ? 'Customer Name' : 'Supplier Name'}`,
      'Due Date',
      'Amount Due (inc. GST)',
      'Contact Email',
    ];
    let csv = convertArrayOfObjectsToCSV({
      invoicesForDownload: warningInvoicesForDownload,
      fileHeader,
    });
    if (csv == null) return;

    filename =
      invoiceType === 'INVOICE'
        ? 'duplicate_invoices.csv'
        : 'duplicate_bills.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadFailedInvoices = () => {
    let data, filename, link;
    const fileHeader = [
      'Invoice Number',
      `${invoiceType === 'BILL' && 'Order Number'}`,
      `${invoiceType === 'INVOICE' ? 'Customer Name' : 'Supplier Name'}`,
      'Due Date',
      'Amount Due (inc. GST)',
      'Contact Email',
      'Reason for Failure',
    ];
    let csv = convertArrayOfObjectsToCSV({
      invoicesForDownload: failedInvoicesForDownload,
      fileHeader,
    });

    if (csv == null) return;

    filename =
      invoiceType === 'INVOICE'
        ? 'invoices_not_imported.csv'
        : 'bills_not_imported.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const progressPercent = importProgress.split('%').join('');

  const handleCheck = (e) => setChecked(e.target.checked);

  return (
    <Container>
      {progressView ? (
        <ProgressBar percent={progressPercent} />
      ) : (
        <>
          {!isFinish && (
            <Row>
              The file contains
              <TextBold>{totalInvoices}</TextBold>
              {invoiceType === 'BILL' ? 'bill' : 'invoice'}
              {totalInvoices !== 1 ? 's.' : '.'}
            </Row>
          )}
          {totalInvoices !== 0 && (
            <Row>
              <TextBold>{validInvoices}</TextBold>
              {invoiceType === 'BILL' ? 'bill' : 'invoice'}
              {isValidOne ? '' : 's'}
              {!isFinish
                ? validInvoices === 0
                  ? ` are valid. Nothing to upload.`
                  : `${isValidOne ? ' is' : ' are'} valid and will be uploaded.`
                : `${isValidOne ? ' was' : ' were'} imported successfully.`}
            </Row>
          )}
          {failedInvoicesCount > 0 && (
            <>
              <Row errorText={true}>
                <TextBold errorText={true}>{failedInvoicesCount}</TextBold>
                {invoiceType === 'BILL' ? 'bill' : 'invoice'}
                {isFailedOne ? '' : 's'}
                {!isFinish
                  ? `${
                      isFailedOne ? ' is' : ' are'
                    } invalid and will not be uploaded.`
                  : ' could not be imported.'}
                <TextBold
                  onClick={downloadFailedInvoices}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: 10,
                  }}
                >
                  View Details
                </TextBold>
              </Row>
              {warningInvoices.length > 0 && <Ruler />}
            </>
          )}
          {warningInvoices.length > 0 && (
            <>
              <Row>
                {`We found ${warningInvoices.length} ${
                  invoiceType === 'BILL' ? 'bill' : 'invoice'
                }${isWarningOne ? '' : 's'} that ${
                  isWarningOne ? 'is' : 'are'
                } similar to ${
                  isWarningOne ? (invoiceType === 'BILL' ? 'a' : 'an') : ''
                } ${invoiceType === 'BILL' ? 'bill' : 'invoice'}${
                  isWarningOne ? '' : 's'
                } recently paid on Relay.`}
              </Row>
              <TextBold
                onClick={downloadInvoices}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {`View ${invoiceType === 'BILL' ? 'Bill' : 'Invoice'}${
                  isWarningOne ? '' : 's'
                }`}
              </TextBold>
              <Row>
                {`Do you want to import ${isWarningOne ? 'this' : 'these'} ${
                  invoiceType === 'BILL' ? 'bill' : 'invoice'
                }${isWarningOne ? '' : 's'}?`}
              </Row>
            </>
          )}
          {!isFinish && fileFormat === 'RELAY' && validInvoices !== 0 && (
            <Checkbox
              checked={isChecked}
              onChange={handleCheck}
              css={`
                font-size: 14px;
              `}
            >
              {invoiceType === 'BILL'
                ? 'I confirm that the bills in this file are approved and ready for payment'
                : 'I confirm that the invoices in this file are approved and awaiting payment'}
            </Checkbox>
          )}
        </>
      )}
    </Container>
  );
};

export default ConfirmationScreen;
