import InputItem from 'components/InputItem';
import React from 'react';
import CSVReader from 'react-csv-reader';

interface CsvImportProps {
  label?: string;
  error: string;
  fileLoadedHandler: (data: any[]) => void;
}

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, '_'),
};

const CsvImport: React.FC<CsvImportProps> = ({
  label,
  error,
  fileLoadedHandler,
}) => {
  return (
    <InputItem label={label} error={error ? error : false}>
      <CSVReader
        label="csv-input"
        cssClass="csv-reader-container"
        onFileLoaded={fileLoadedHandler}
        parserOptions={papaparseOptions}
        inputId="ObiWan"
      />
    </InputItem>
  );
};

export default CsvImport;
