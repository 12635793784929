import React from 'react';
import { Space, Typography } from 'antd';
import styled from 'styled-components';

import BankAccount from 'types/BankAccount';
import { AustraliaOptions } from './types';

const { Text } = Typography;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

type AccountReviewProps = {
  bankAccountName?: BankAccount['bankAccountName'];
  bankAccountNumber?: BankAccount['bankAccountNumber'];
  bsb?: BankAccount['bsb'];
  payid?: BankAccount['payId'];
  isCountryAU: boolean;
  australiaOption: AustraliaOptions;
};

export const AccountReview: React.FC<AccountReviewProps> = ({
  bankAccountName,
  bankAccountNumber,
  bsb,
  payid,
  isCountryAU,
  australiaOption,
}) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row>
        <Text>Account name</Text>
        <Text>{bankAccountName}</Text>
      </Row>

      {isCountryAU && australiaOption === AustraliaOptions.ACCOUNT_NUMBER && (
        <Row>
          <Text>BSB</Text>
          <Text>{bsb}</Text>
        </Row>
      )}

      {(!isCountryAU ||
        (isCountryAU &&
          australiaOption === AustraliaOptions.ACCOUNT_NUMBER)) && (
        <Row>
          <Text>Account number</Text>
          <Text>{bankAccountNumber}</Text>
        </Row>
      )}

      {isCountryAU && australiaOption === AustraliaOptions.PAYID && (
        <Row>
          <Text>PayID</Text>
          <Text>{payid}</Text>
        </Row>
      )}
    </Space>
  );
};
