const SET_XERO_RELAY_ACCOUNTS = 'relay/signup/SET_XERO_RELAY_ACCOUNTS';
const LOGOUT = 'LOGOUT';

const initialState = {
  xeroRelayAccounts: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_XERO_RELAY_ACCOUNTS:
      return { ...state, xeroRelayAccounts: payload };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const setXeroRelayAccounts = (accounts) => ({
  type: SET_XERO_RELAY_ACCOUNTS,
  payload: accounts,
});
