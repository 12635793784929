import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Radio, RadioGroupProps } from 'antd';

const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 1em;
    border: none;
    border-radius: 16px;
    background: ${colors.greys100};
    color: ${colors.greys500};
    font-size: 12px;
    font-weight: 700;
    height: 24px;
    display: inline-flex;
    align-items: center;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: ${colors.blues.lighter};
    color: ${colors.blues.dark};
  }
  .ant-radio-button-wrapper:hover {
    background: ${colors.blues.lighter};
    color: ${colors.greys500};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
    background: ${colors.blues.lighter};
    color: ${colors.blues.dark};
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:active {
    background: ${colors.blues.lighter};
    color: ${colors.blues.dark};
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;

const Chips: React.FC<RadioGroupProps> = (props) => (
  <RadioGroup optionType="button" buttonStyle="solid" {...props} />
);

export default Chips;
