import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import colors from 'colors';

const { Text } = Typography;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 13px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Label = styled(Text)`
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: ${colors.greys600};
`;

const Value = styled(Text)`
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: ${colors.greys900};
  text-align: right;
`;

type Props = { label: string; value: string | null | undefined };

const InfoRow: React.FC<Props> = ({ label, value }) => {
  return (
    <Row>
      <Label> {label} </Label>
      <Value> {value} </Value>
    </Row>
  );
};

export default InfoRow;
