import React from 'react';
import styled from 'styled-components';

import { Button, Input } from 'antd';
import { InputContainer, TitleSection } from 'components';

const Row = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  text-align: center;
  width: 60px;
  margin-right: ${(props) => (props.last ? '0' : '10px')};
`;

const CodeInput = styled(Input)`
  text-align: center;
`;

const CODE_LENGTH = 6;

const VerificationCode = (props) => {
  const {
    onCodeInput,
    showCodeEnter,
    error,
    setPasting,
    codeArray,
    invalidCodes,
    inputRefs,
    phoneNumber,
    sendCode,
  } = props;

  return (
    <>
      {showCodeEnter && (
        <>
          <TitleSection
            title="We just sent you an SMS"
            subTitle={`Enter the six digit code we sent to phone number ending in ${phoneNumber.slice(
              phoneNumber.length - 4
            )}`}
          />
          <InputContainer error={error}>
            <Row>
              {[...Array(CODE_LENGTH).keys()].map((i) => (
                <InputWrapper key={String(i)} last={i === CODE_LENGTH - 1}>
                  <CodeInput
                    onChange={(e) => {
                      onCodeInput(e, i);
                    }}
                    onPaste={() => {
                      setPasting(true);
                      return;
                    }}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    ref={(el) => (inputRefs.current[i] = el)}
                    value={codeArray[i]}
                    disabled={invalidCodes === 3}
                  />
                </InputWrapper>
              ))}
            </Row>
          </InputContainer>
          <Button onClick={sendCode} type="ghost">
            Send New Code
          </Button>
        </>
      )}
    </>
  );
};

export default VerificationCode;
