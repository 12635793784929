import axios from 'axios';

const getMyobCallback = ({ authorization_code, scope, token }) => {
  return new Promise(function(resolve, reject) {
    axios
      .get(`/myob/callback?code=${authorization_code}&scope=${scope}`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
};
const getMyobRelayAccount = ({ token, companyId, myobAccount }) => {
  return new Promise(function(resolve, reject) {
    const url = `/company/${companyId}/${
      myobAccount === 'Essentials'
        ? 'myobessentialsrelayaccounts'
        : 'myobaccountrightrelayaccounts'
    }`;
    const options = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios
      .get(url, options)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const postMyobRelayAccount = ({ token, url, account }) => {
  return new Promise(function(resolve, reject) {
    const options = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .post(url, account, options)
      .then(({ data }) => resolve(data))
      .catch(error => {
        reject(error);
      });
  });
};

const triggerInvoiceLoad = ({ token, myobAccount }) => {
  const scope =
    myobAccount === 'Essentials'
      ? window.env.MYOB_ESSENTIALS_SCOPE
      : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
  return new Promise(function(resolve, reject) {
    axios
      .get(`/myob/invoices/load?scope=${scope}`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization']
        }
      })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error));
  });
};

const triggerAccountsLoad = ({ token, myobAccount }) => {
  const scope =
    myobAccount === 'Essentials'
      ? window.env.MYOB_ESSENTIALS_SCOPE
      : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
  return new Promise(function(resolve, reject) {
    axios
      .get(`myob/accounts/load?scope=${scope}`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization']
        }
      })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error));
  });
};

const loadCompanyFile = ({
  companyId,
  username,
  password,
  myobAccount,
  token
}) => {
  const scope =
    myobAccount === 'Essentials'
      ? window.env.MYOB_ESSENTIALS_SCOPE
      : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
  return new Promise(function(resolve, reject) {
    const url = `/myob/company/load?scope=${scope}`;
    const body = {
      companyId,
      username,
      password
    };
    const options = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios
      .post(url, body, options)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => reject(response));
  });
};

const validate = ({ companyId, username, password, myobAccount, token }) => {
  const scope =
    myobAccount === 'Essentials'
      ? window.env.MYOB_ESSENTIALS_SCOPE
      : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
  return new Promise(function(resolve, reject) {
    const url = `/myob/validate?scope=${scope}`;
    const body = {
      companyId,
      username,
      password
    };
    const options = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios
      .post(url, body, options)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => reject(response));
  });
};

export default {
  getMyobCallback,
  getMyobRelayAccount,
  postMyobRelayAccount,
  triggerInvoiceLoad,
  triggerAccountsLoad,
  loadCompanyFile,
  validate
};
