import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from 'antd';

const Container = styled.div`
  float: right;
`;

type Action = {
  action: () => void;
  label: string;
  buttonType?: ButtonProps['type'];
};

type ActionsProps = {
  primaryAction?: Action;
  secondaryAction?: Action;
};

export const Actions: React.FC<ActionsProps> = ({
  primaryAction,
  secondaryAction,
}) => (
  <Container>
    {secondaryAction ? (
      <Button onClick={secondaryAction.action} type="ghost">
        {secondaryAction.label}
      </Button>
    ) : null}

    {primaryAction ? (
      <Button
        onClick={primaryAction.action}
        type={primaryAction.buttonType || 'primary'}
      >
        {primaryAction.label}
      </Button>
    ) : null}
  </Container>
);
