import Coordinate from 'types/RipeDraw';

const SET_COORDINATES = 'relay/ripe/SET_COORDINATES';
const CLEAR = 'relay/ripe/CLEAR';
const LOGOUT = 'LOGOUT';

type State = {
  coordinates: Coordinate | null;
};

type Action = {
  type: string;
  payload?: any;
};

const initialState: State = {
  coordinates: null,
};

export default (state = initialState, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case SET_COORDINATES:
      return { ...state, coordinates: payload };
    case CLEAR:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const setCoordinates = (coordinates: Coordinate | null) => ({
  type: SET_COORDINATES,
  payload: coordinates,
});

export const clearCoordinates = () => ({ type: CLEAR });
