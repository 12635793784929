import React from 'react';
import colors from 'colors';
import styled from 'styled-components/macro';
import DropdownTrigger from './DropdownTrigger';
import { Button, Breadcrumb, Layout, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ChevronRight as ChevronIcon } from 'assets/icons';
import spacing from 'styles/layout/spacing';

const StyledHeader = styled(Layout.Header)<{ $isDesktop: boolean }>`
  height: ${spacing.topBar.height};
  border-bottom: 1px solid #bdc3cb;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => (props.$isDesktop ? spacing.gutter.lg : '0')};
`;

const MenuButton = styled(Button)`
  min-width: 50px !important;
  margin: 0 0.5em;
`;

const TitleContainer = styled(Breadcrumb)<{ $hasSubtitle?: boolean }>`
  font-weight: bold;
  font-size: 20px;
  //color: ${colors.greys700};
  margin-top: auto;
  margin-bottom: auto;
  a {
    cursor: ${({ $hasSubtitle }) => ($hasSubtitle ? `pointer` : `auto`)};
  }
  .ant-breadcrumb-separator {
    margin: 0 16px;
  }
`;

const Header: React.FC<{
  isDesktop: boolean;
  openDrawer: () => void;
  title: string;
}> = ({ isDesktop, openDrawer, title }) => {
  const subTitle = useSelector((store: any) => store.ui.toolbarSubtitle);
  return (
    <StyledHeader $isDesktop={isDesktop}>
      <Space size={0}>
        {!isDesktop && (
          <MenuButton onClick={openDrawer} type="ghost">
            <MenuOutlined />
          </MenuButton>
        )}
        <TitleContainer separator="" $hasSubtitle={!!subTitle}>
          <Breadcrumb.Item href={subTitle ? '/bills' : undefined}>
            {title}
          </Breadcrumb.Item>
          {subTitle && (
            <>
              <Breadcrumb.Separator>
                <ChevronIcon />
              </Breadcrumb.Separator>
              <Breadcrumb.Item>{subTitle}</Breadcrumb.Item>
            </>
          )}
        </TitleContainer>
      </Space>
      <DropdownTrigger isDesktop={isDesktop} />
    </StyledHeader>
  );
};

export default Header;
