import React from 'react';
import formatter from 'utils/currencyFormatter';
import styled from 'styled-components/macro';
import { Row, Tooltip } from 'antd';

import accountNumberFormatter from 'utils/accountNumberFormatter';
import { SetDefaultBankAccountButton } from './SetDefaultBankAccountButton';
import { CheckCircleFilledGreen, CheckCircleFilledOrange } from 'assets/icons';
import { InputItem, CurrencyInput } from 'components';
import Text from 'antd/lib/typography/Text';
import spacing from 'styles/layout/spacing';
import colors from 'colors';
import BankAccount from 'types/BankAccount';
import { Address } from 'types/Address';

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const renderAccountDetails = (
  account: BankAccount,
  countryCode: Address['countryCode']
) => {
  const { bankAccountNumber, bsb, payId } = account;
  const formattedAccountNumber = accountNumberFormatter(bankAccountNumber);
  if (countryCode === 'AU') {
    if (payId) {
      return <Text>{payId}</Text>;
    } else {
      return (
        <DetailsColumn>
          <Text>{`BSB: ${bsb}`}</Text>
          <Text>{`Account number: ${formattedAccountNumber}`}</Text>
        </DetailsColumn>
      );
    }
  } else {
    return <Text>{formattedAccountNumber}</Text>;
  }
};

const FundsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.gutter.xs} 0;
  margin-bottom: ${spacing.gutter.md};
  border-bottom: 1px solid ${colors.greys200};
  font-size: 14px;
  color: ${colors.greys600};
`;
const StatusImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: ${spacing.gutter.xs};
  cursor: pointer;
`;

const WithdrawFunds: React.FC<{
  countryCode: Address['countryCode'];
  fundsAvailable: number;
  toAccount: BankAccount;
  fromBankAccount: BankAccount;
  withDrawAmount: string;
  onChangeAmount: (values: {
    formattedValue: string;
    value: string;
    floatValue: number;
  }) => void;
  hasPendingWithdrawal: boolean;
}> = ({
  countryCode,
  fundsAvailable,
  toAccount,
  withDrawAmount,
  onChangeAmount,
  fromBankAccount,
  hasPendingWithdrawal,
}) => {
  console.log(toAccount);
  return (
    <div>
      <FundsRow>
        <Text>Funds Available</Text>
        <Text>{`${formatter.format(fundsAvailable)}`}</Text>
      </FundsRow>
      <InputItem
        label="From: Relay working capital account"
        error={
          fromBankAccount
            ? undefined
            : 'No system bank account, please contact Relay'
        }
      >
        {fromBankAccount
          ? renderAccountDetails(fromBankAccount, countryCode)
          : null}
      </InputItem>
      <InputItem label={toAccount ? `To: ${toAccount.bankAccountName}` : 'To'}>
        {toAccount ? (
          <Row
            align={
              countryCode === 'NZ' || toAccount.payId ? 'middle' : 'bottom'
            }
          >
            {renderAccountDetails(toAccount, countryCode)}
            {(toAccount.bankAccountStatus === 'VERIFIED' ||
              toAccount.bankAccountStatus === 'VERIFICATION_PENDING') && (
              <Tooltip
                title={
                  toAccount.bankAccountStatus === 'VERIFIED'
                    ? 'VERIFIED'
                    : 'Verification Pending'
                }
              >
                <StatusImg
                  css={
                    countryCode === 'AU' && !toAccount.payId
                      ? `position: relative; bottom: 3px;`
                      : undefined
                  }
                  src={
                    toAccount.bankAccountStatus === 'VERIFIED'
                      ? CheckCircleFilledGreen
                      : CheckCircleFilledOrange
                  }
                  alt="status-img"
                />
              </Tooltip>
            )}
          </Row>
        ) : (
          <SetDefaultBankAccountButton />
        )}
      </InputItem>
      {!hasPendingWithdrawal && (
        <InputItem label="Amount">
          <CurrencyInput
            value={withDrawAmount}
            onValueChange={onChangeAmount}
            size="default"
          />
        </InputItem>
      )}
    </div>
  );
};

export default WithdrawFunds;
