import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Autosuggest from 'react-autosuggest';

import processError from 'utils/processError';

import api from 'api';

import RipeCompanyInvoice from 'types/RipeCompanyInvoice';

import './autosuggest.css';
import Input from './Input';
import { notification } from 'antd';

const SupplierAutosuggest = Autosuggest as { new (): Autosuggest<string> };

const Container = styled.div`
  position: relative;
  width: 258px;
  margin-left: 20px;
`;

const renderInputComponent = (inputProps: any) => (
  <Input inputProps={inputProps} />
);

const renderSuggestion = (suggestion: any) => <span>{suggestion}</span>;

const getSuggestionValue = (suggestion: any) => suggestion;

type Props = {
  handleChangeSearchValue: (value: string) => void;
  handleClearSearchValue: () => void;
  filterParams: any;
  getParams: any;
  suggestedSearchValue: string;
};

const SearchBar: React.FC<Props> = ({
  handleChangeSearchValue,
  handleClearSearchValue,
  filterParams,
  getParams,
  suggestedSearchValue,
}) => {
  const [searchValue, setSearchValue] = useState(suggestedSearchValue);
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (suggestedSearchValue) {
      setSearchValue(suggestedSearchValue);
    }
  }, [suggestedSearchValue]);

  // What happens when the input value changes.
  // We need to set the value prop.
  const onChange = (event: any, { newValue }: { newValue: any }) => {
    setSearchValue(newValue);
  };

  const onBlur = () => {
    setSearchValue(searchValue);
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  // User has selected an entry from suggestions.
  // Let's now filter the list of documents.
  const onSuggestionSelected = (
    event: any,
    { suggestionValue }: { suggestionValue: string }
  ) => {
    handleChangeSearchValue(suggestionValue);
    setSuggestions([]);
  };

  // We need to show suggestions only if user has typed atleast 3 characters (excluding space characters).
  const shouldRenderSuggestions = (value: string) => value.trim().length > 2;

  const clearSearchValue = () => {
    handleClearSearchValue();
    setSearchValue('');
  };

  //we fetching suggestion on onSuggestionFetchRequested
  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    const params = getParams();
    const searchParam = `supplierName=${encodeURIComponent(value)}:contains&`;

    api.ripe
      .getDocuments({
        ...params,
        pageNumber: 0,
        pageSize: 25,
        searchParam,
        filterParams,
      })
      .then(({ docs }: { docs: RipeCompanyInvoice[] }) => {
        const supplierNames = docs.map((item) => item.supplierName || '');
        const validSupplierNames = supplierNames.filter(
          (supplierName) => !!supplierName
        );
        const uniqueSupplierNamesSet = new Set(validSupplierNames);
        const uniqueSupplierNamesArray = Array.from(uniqueSupplierNamesSet);
        setSuggestions(uniqueSupplierNamesArray);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      });
  };

  const inputProps = {
    placeholder: 'Search',
    value: searchValue,
    onChange,
    onBlur,
    clearSearchValue,
  };
  return (
    <Container>
      <SupplierAutosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={clearSuggestions}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={shouldRenderSuggestions}
        multiSection={false}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      />
    </Container>
  );
};

export default SearchBar;
