import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { login, getBankAccounts } from 'store/auth';
import LogInWait from 'pages/Login/LogInWait';

import api from 'api';

const AddBankAccountToXero = ({
  token,
  company,
  companyId,
  login,
  history,
  next,
  myobConnect,
  myobAccount,
  getBankAccounts,
}) => {
  useEffect(() => {
    if (company) {
      // We have mapped discount given and discount received account.
      // Relay bank account is also added to MYOB/Xero.
      // Now we need to PUT company with corresponding updated fields.
      let connectionKey = '';
      let configKey = '';
      if (myobConnect) {
        if (myobAccount === 'Essentials') {
          connectionKey = 'connectedToMyobEssentials';
          configKey = 'myobEssentialsAccountsConfigured';
        } else {
          connectionKey = 'connectedToMyobAccountRight';
          configKey = 'myobAccountRightAccountsConfigured';
        }
      } else {
        connectionKey = 'connectedToXero';
        configKey = 'xeroAccountsConfigured';
      }

      const updatedCompany = {
        ...company,
        [connectionKey]: true,
        [configKey]: true,
      };
      api.company.put({ company: updatedCompany, token });
    }

    if (next) {
      history.push(next);
    } else {
      loadUser();
    }
  });

  const loadUser = () => {
    api.auth.getUser(token).then((userDetails) => {
      // get company details
      api.company.get({ companyId, token }).then((company) => {
        const userData = {
          ...userDetails,
          token,
          company,
        };
        login({ user: userData, loggedIn: true });
        getBankAccounts(companyId);
        history.push('/invoices');
      });
      // get company details ends here
    });
    // get user details ends here
  };
  return <LogInWait message="Loading..." />;
};

const mapStateToProps = ({ auth, signup }) => ({
  company: auth.user ? auth.user.company.company : signup.company,
  myobAccount: signup.myobAccount,
});

export default connect(mapStateToProps, { login, getBankAccounts })(
  withRouter(AddBankAccountToXero)
);
