import React from 'react';
import InputItem from 'components/InputItem';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

type Props = {
  mfaCode: string;
  setMfaCode: (value: string) => void;
  mfaError: string;
  setMfaError: (error: string) => void;
  onKeyPress: () => void;
};

const Mfa: React.FC<Props> = ({
  mfaCode,
  setMfaCode,
  mfaError,
  setMfaError,
  onKeyPress,
}) => {
  return (
    <InputItem label="MFA Code" error={mfaError}>
      <NumberFormat
        customInput={Input}
        format="### ###"
        onValueChange={({ value }) => {
          setMfaCode(value);
          setMfaError('');
        }}
        placeholder="Enter the code shown on your authenticator app"
        value={mfaCode}
        onKeyPress={onKeyPress}
        data-testid="mfa-input"
      />
    </InputItem>
  );
};

export default Mfa;
