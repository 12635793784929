import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { Input, DatePicker, Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import InvoiceItems from './InvoiceItems';
import Amount from './Amount';
import getTimestamp from 'utils/getTimestamp';
import processError from 'utils/processError';

import api from 'api';

import CompanyName from 'components/SelectCompany';
import InputItem from 'components/InputItem';

const COMPANY_MISSING_ERROR = 'Please select your company';

const CreateNewInvoice = (props) => {
  const defaultDueDate = moment().add(1, 'months').format('DD/MM/YYYY');
  //const itemId = Math.floor(Math.random() * 10000) + 1;
  var invoiceItem = new Array(5)
    .fill({
      itemNumber: Math.floor(Math.random() * 10000) + 1,
      id: '',
      description: '',
      units: 0,
      unitType: 'HOURS',
      unitPrice: 0,
      amount: 0,
    })
    .map(() => ({
      itemNumber: Math.floor(Math.random() * 10000) + 1,
      id: '',
      description: '',
      units: 0,
      unitType: 'HOURS',
      unitPrice: 0,
      amount: 0,
    }));
  // const [customerId, setCustomerId] = useState(null);
  // const [contactId, setContactId] = useState(null);

  // Company object to be posted to /company API
  const [company, setCompany] = useState(null);
  const [jobReference, setJobReference] = useState('');
  const [invoiceItems, setItems] = useState(invoiceItem);
  const [dueDate, setDueDate] = useState(defaultDueDate);
  const [dateValidationError, setDateValidationError] = useState('');
  const [loading, setLoading] = useState(false);

  const [companyError, setCompanyError] = useState('');
  const [isNewCompanySelected, setNewCompanySelected] = useState(true);

  // for company input disable
  const [editingCompany, setEditingCompany] = useState(true);

  const { user, type, history } = props;
  // // remove logged customer from customers list
  // const filteredCustomers = customers.filter(
  //   customer => customer.id !== user.company.company.id
  // );
  // const menuItems = filteredCustomers.map(customer => {
  //   return {
  //     label: customer.companyName,
  //     value: customer.id,
  //     onClick: () => {
  //       setCustomerId(customer.id);
  //     }
  //   };
  // });

  // on select company
  const onSelectCompany = (company) => {
    setCompany(company);
    setCompanyError('');
    setNewCompanySelected(true);
    setEditingCompany(false);
  };

  // add invoice items
  const addNewInvoiceItem = (items) => {
    setItems([...invoiceItems, items]);
  };
  // update added invoice item
  const updateInvoiceItem = ({ field, value, id }) => {
    const updatedItems = invoiceItems.map((item) => {
      if (id === item.itemNumber) {
        let newItem = item;
        newItem[field] = value;
        newItem.amount = newItem.units * newItem.unitPrice;
        return newItem;
      } else {
        return item;
      }
    });
    setItems(updatedItems);
  };

  // delete invoice items
  const deleteInvoiceItem = (itemNumber) => {
    const filteredInvoiceItems = invoiceItems.filter(
      (item) => item.itemNumber !== itemNumber
    );
    setItems(filteredInvoiceItems);
  };

  // const filteredContacts = customers.filter(
  //   customer => customer.id === customerId
  // );
  // const customerContacts = filteredContacts.map(customer => {
  //   const contacts = customer.contacts.map(contact => {
  //     return {
  //       label: contact.name,
  //       value: contact.id,
  //       onClick: () => {
  //         setContactId(contact.id);
  //       }
  //     };
  //   });
  //   return contacts;
  // });

  const subTotal = invoiceItems.reduce(
    (accumulator, item) => accumulator + item.amount,
    0
  );
  const gst = 0.15 * subTotal;
  const total = subTotal + gst;

  const onSubmit = () => {
    if (!isNewCompanySelected && !company) {
      setCompanyError(COMPANY_MISSING_ERROR);
      return;
    }
    if (!isNewCompanySelected && company) {
      setCompanyError(COMPANY_MISSING_ERROR);
      return;
    }
    setLoading(true);
    setDateValidationError('');
    const formatDueDate = moment(dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    //const issuedFor = customers.filter(c => c.id === customerId)[0];
    // const contact = issuedFor.contacts.filter(con => con.id === contactId)[0];
    const issued = user.company.company.id;
    const date = getTimestamp(formatDueDate);
    let payload = {
      // jobReference: String(jobReference),
      dueDate: date,
      items: invoiceItems,
      subTotal: String(subTotal),
      gst: String(gst),
      total: String(total),
    };
    if (type === 'INVOICE') {
      payload = {
        ...payload,
        issuedBy: issued,
        issuedForCompanyName: company.companyName,
        invoiceNumber: String(jobReference),
      };
    } else {
      payload = {
        ...payload,
        issuedFor: issued,
        issuedByCompanyName: company.companyName,
        billNumber: String(jobReference),
      };
    }
    setDateValidationError('');
    api.invoices
      .post(payload)
      .then(() => {
        const message =
          type === 'INVOICE'
            ? 'Invoice created successfully'
            : 'Bill  created successfully';
        notification.success({ message });
        setLoading(false);
        setJobReference('');
        setDueDate(defaultDueDate);
        setItems(invoiceItem);
        setCompany(null);
        history.go(-1);
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({ message });
        setLoading(false);
      });
  };

  const cancel = () => {
    // setCustomerId(null);
    setJobReference('');
    setDueDate(defaultDueDate);
    setItems(invoiceItem);
    setCompany(null);
    history.go(-1);
  };

  const validateDate = (dueDate) => {
    if (moment(dueDate).isBefore(moment().format('DD MMM YYYY'))) {
      setDateValidationError('Cannot add invoice with past date.');
      setDueDate(dueDate);
    } else if (moment(dueDate).isSame(moment().format('DD MMM YYYY'))) {
      setDateValidationError('Cannot add invoice with current date.');
      setDueDate(dueDate);
    } else {
      setDateValidationError('');
      setDueDate(dueDate);
    }
  };
  return (
    <div style={styles.container}>
      {/* <SelectField
        menuItems={menuItems}
        placeholder="Select a customer"
        value={customerId}
      /> */}
      {/* {customerId && (
        <SelectField
          menuItems={customerContacts[0]}
          placeholder="Select a contact"
          value={contactId}
          style={styles.contact}
        />
      )} */}
      <div style={{ width: '100%' }}>
        <InputItem
          label={type === 'INVOICE' ? 'Select purchaser' : 'Select supplier'}
          error={
            companyError ||
            (!isNewCompanySelected && !company && COMPANY_MISSING_ERROR)
          }
        >
          <CompanyName
            selectedCompanyName={company ? company.companyName : ''}
            onSelectCompany={onSelectCompany}
            placeholder={
              type === 'INVOICE' ? 'Enter Customer Name' : 'Enter Supplier Name'
            }
            fromCreateInvoice
            onInput={() => {
              setNewCompanySelected(false);
            }}
            style={{ height: 25, padding: 0, paddingLeft: 5 }}
            suffix={
              company &&
              !editingCompany && (
                <CloseOutlined
                  onClick={() => {
                    setCompany(null);
                    setEditingCompany(true);
                  }}
                />
              )
            }
            disabled={!editingCompany}
            //error={dateValidationError}
          />
        </InputItem>
      </div>
      <div style={styles.inputField}>
        <InputItem label="Job reference">
          <Input
            placeholder="Job reference"
            lineDirection="center"
            value={jobReference}
            style={styles.jobReference}
            size="small"
            onChange={(e) => setJobReference(e.target.value)}
          />
        </InputItem>
        <InputItem
          label="Due date"
          error={dateValidationError}
          style={styles.dateContainer}
        >
          <DatePicker
            allowClear={false}
            value={moment(dueDate, 'DD/MM/YYYY')}
            format={['DD/MM/YYYY']}
            onChange={(dateString) => validateDate(dateString)}
            size="small"
          />
        </InputItem>
      </div>
      <InvoiceItems
        items={invoiceItems}
        updateInvoiceItem={updateInvoiceItem}
        deleteInvoiceItem={deleteInvoiceItem}
      />
      <Button
        type="primary"
        onClick={() =>
          addNewInvoiceItem({
            itemNumber: Math.floor(Math.random() * 10000) + 1,
            id: '',
            description: '',
            units: 0,
            unitType: 'HOURS',
            unitPrice: 0,
            amount: 0,
          })
        }
      >
        Add item
      </Button>
      <div style={styles.amountContainer}>
        <Amount label="Sub-total" amount={subTotal} />
        <Amount label="GST" amount={gst} />
        <Amount label="Total" amount={total} />
      </div>
      <div style={styles.buttonsContainer}>
        <Button onClick={cancel} style={styles.cancelButton} type="ghost">
          Cancel
        </Button>
        <Button
          onClick={loading === false && onSubmit}
          disabled={!company || !dueDate || dateValidationError}
          type="primary"
          loading={loading}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 20,
  },
  // content: {
  //   minHeight: 100
  // },
  contact: {
    marginLeft: 30,
  },
  dueDateText: {
    marginTop: 35,
    marginRight: 10,
  },
  amountContainer: {
    marginLeft: '80%',
  },
  inputField: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    //justifyContent: 'space-around',
    marginBottom: 15,
  },
  jobReference: {
    marginRight: 100,
  },
  dateContainer: {
    marginLeft: 50,
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: 16,
    paddingBottom: 20,
  },
  cancelButton: {
    marginRight: 15,
  },
};

const mapStateToProps = ({ auth, invoices }) => ({
  customers: invoices.customers,
  user: auth.user,
});

export default connect(mapStateToProps)(withRouter(CreateNewInvoice));
