import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Button, Col, Input, notification, Row } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { useLocation } from 'react-router';

import api from 'api';
import spacing from 'styles/layout/spacing';
import CounterParty from 'types/CounterParty';
import { PaymentTerm } from 'types/PaymentTerm';
import { Preferences } from 'types/Company';
import { GlAccount } from 'types/AccountCode';
import { processError } from 'utils';
import { Tabs } from 'ds';
import { CounterPartyDetails, DirectoryTable, Filter } from './components';
import { useNavigate } from 'hooks';
import ImportCounterpartiesModal from './components/ImportCounterpartiesModal';
import ConfigureCounterpartiesModal from './components/ConfigureCounterpartiesModal';
import { PaymentTermsContext } from './PaymentTermsContext';
import { CounterpartyFilters } from './components/Filter/CounterpartyFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.gutter.sm};
  width: 100%;
  background-color: #f6f7f8;
  padding: ${spacing.gutter.sm};
  .ant-input-search {
    width: fit-content;
  }
`;

const ConfigureButton = styled(Button)`
  margin-right: auto;
`;

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 10;
const GL_CODE_PAGE_SIZE = 1000;

const tabs = [
  { label: 'All', key: 'all' },
  { label: 'Members', key: 'members', status: 'ACTIVE' },
  { label: 'Non-Members', key: 'non_members', statuses: 'INACTIVE,INVITED' },
];

const Directory: React.FC<{ entityId: string }> = ({ entityId }) => {
  const location = useLocation();
  const locationParams = new URLSearchParams(location.search);

  const processingQueueParam = locationParams.get('queue');
  const glCodeParam = locationParams.get('glcode');
  const paymentTermsParam = locationParams.get('pids');
  const scheduledPaymentsParam = locationParams.get('sp');
  const pageNumberParam = Number(
    locationParams.get('page') || DEFAULT_PAGE_NUMBER
  );
  const pageSizeParam = Number(locationParams.get('size') || DEFAULT_PAGE_SIZE);
  const searchParam = locationParams.get('q') || '';
  const tabParam = locationParams.get('tab') || 'all';

  const filters: CounterpartyFilters = {
    scheduledPayments: scheduledPaymentsParam,
    paymentTerms: paymentTermsParam,
    processingQueue: processingQueueParam,
    glCode: glCodeParam,
  };

  const navigate = useNavigate();
  const { search } = useLocation();

  const [counterParties, setCounterParties] = useState<CounterParty[]>([]);
  const [availableCodes, setAvailableCodes] = useState<GlAccount[]>([]);
  const [preferences, setPreferences] = useState<Preferences>();

  // To control search input
  const [searchInput, setSearchInput] = useState('');

  // Value sent to the API
  const [searchTerm, setSearchTerm] = useState(searchParam);

  const [selectedTab, setSelectedTab] = useState(tabParam);

  const [isLoading, setLoading] = useState(true);

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [configureModalOpen, setConfigureModalOpen] = useState(false);

  const [pageNumber, setPageNumber] = useState(pageNumberParam);
  const [pageSize, setPageSize] = useState(pageSizeParam);
  const [totalElements, setTotalElements] = useState(0);

  const [activeCounterparty, setActiveCounterparty] =
    useState<CounterParty | null>(null);
  const [selectedCounterparties, setSelectedCounterparties] = useState<
    CounterParty[]
  >([]);

  const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([]);

  useEffect(() => {
    setSelectedCounterparties([]);
  }, [search]);

  useEffect(() => {
    setPageNumber(pageNumberParam);
  }, [pageNumberParam]);

  useEffect(() => {
    setPageSize(pageSizeParam);
  }, [pageSizeParam]);

  useEffect(() => {
    setSearchInput(searchParam);
    setSearchTerm(searchParam);
  }, [searchParam]);

  useEffect(() => {
    setSelectedTab(tabParam);
  }, [tabParam]);

  const handleChangeTab = (tabKey: string) => {
    const params = new URLSearchParams();
    params.set('tab', tabKey);
    params.set('page', String(DEFAULT_PAGE_NUMBER));
    navigate({ params });
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setSelectedTab(tabKey);
  };

  const selectCounterParty = (id: string) => {
    const selected = counterParties.find((item) => item.id === id);
    if (selected) {
      setActiveCounterparty(selected);
    }
  };

  const onUpdateComplete = () => {
    setActiveCounterparty(null);
    setSelectedCounterparties([]);
    getCounterParties();
  };

  const onConfigureComplete = () => {
    setConfigureModalOpen(false);
    setActiveCounterparty(null);
    setSelectedCounterparties([]);
    getCounterParties();
  };

  const updateCounterParty = (updatedCounterParty: CounterParty) => {
    const updatedList = counterParties.map((counterParty) =>
      counterParty.id === updatedCounterParty.id
        ? updatedCounterParty
        : counterParty
    );
    setCounterParties(updatedList);
    setActiveCounterparty(updatedCounterParty);
  };

  const getCounterParties = useCallback(() => {
    setLoading(true);
    const tab = tabs.find((tab) => tab.key === selectedTab);
    api.admin.counterParties
      .getAll({
        counterPartyName: searchTerm ? `${searchTerm}:like` : undefined,
        entityId,
        defaultProcessingQueue: processingQueueParam || undefined,
        glCodes: glCodeParam || undefined,
        paymentTermIds: paymentTermsParam || undefined,
        isScheduledPaymentsEnabled: scheduledPaymentsParam || undefined,
        page: pageNumber - 1,
        size: pageSize,
        status: tab?.status,
        statuses: tab?.statuses,
      })
      .then((data) => {
        if (data.counterParties) {
          setTotalElements(data.totalElements);
          setCounterParties(
            data.counterParties.map((item) => item.counterParty)
          );
        }
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [
    entityId,
    processingQueueParam,
    glCodeParam,
    paymentTermsParam,
    scheduledPaymentsParam,
    pageNumber,
    pageSize,
    searchTerm,
    selectedTab,
  ]);

  const getPaymentTerms = useCallback(() => {
    api.paymentTerms
      .getPaymentTerms({ entityId })
      .then((data) => setPaymentTerms(data));
  }, [entityId]);

  const getPreferences = useCallback(() => {
    api.admin.entities
      .getEntityPreference({ entityId })
      .then((data) => setPreferences(data));
  }, [entityId]);

  const getGlAccounts = useCallback(() => {
    api.admin.xeroAccounts
      .get({
        entityId,
        size: GL_CODE_PAGE_SIZE,
      })
      .then((data) => {
        const retrievedAccounts: GlAccount[] = data.accounts.map(
          ({ account }) => {
            const {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            } = account;
            return {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            };
          }
        );
        setAvailableCodes(
          retrievedAccounts.filter((account) => {
            return account.isVisibleInRelay;
          })
        );
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      });
  }, [entityId]);

  useEffect(() => {
    getCounterParties();
    getGlAccounts();
    getPaymentTerms();
    getPreferences();
  }, [getCounterParties, getPaymentTerms, getPreferences, getGlAccounts]);

  const handlePaginationChange = (page: number, size: number) => {
    const params = new URLSearchParams();
    params.set('page', String(page));
    params.set('size', String(size));
    navigate({ params });
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // When user types in search input box
    setSearchInput(e.target.value);
  };

  const handleSearch = (value: string) => {
    // When user clicks on Search button
    const params = new URLSearchParams();
    params.set('page', String(DEFAULT_PAGE_NUMBER));
    params.set('q', value.trim());
    navigate({ params });
  };

  const onChange = (_: Key[], selectedRows: CounterParty[]) => {
    setSelectedCounterparties(selectedRows);
  };

  const toggleImportModal = (isOpen: boolean) => {
    if (isOpen) {
      setIsImportModalOpen(true);
    } else {
      setIsImportModalOpen(false);
      getCounterParties();
    }
  };

  const toggleConfigureModal = (isOpen: boolean) => {
    setConfigureModalOpen(isOpen);
  };

  const shouldShowMergeButton =
    activeCounterparty?.counterParty.status !== 'ACTIVE';

  return (
    <PaymentTermsContext.Provider value={paymentTerms}>
      <Tabs activeKey={selectedTab} onChange={handleChangeTab} type="card">
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}></Tabs.TabPane>
        ))}
      </Tabs>
      <ActionContainer>
        {selectedCounterparties.length > 0 && (
          <ConfigureButton onClick={() => toggleConfigureModal(true)}>
            Configure
          </ConfigureButton>
        )}
        <Filter filters={filters} />
        <Input.Search
          placeholder="Search by company"
          value={searchInput}
          onChange={handleChangeSearchInput}
          onSearch={handleSearch}
          allowClear
        />
        <Button onClick={() => toggleImportModal(true)}>Import</Button>
      </ActionContainer>
      <Row
        css={`
          max-width: 1080px;
        `}
        gutter={32}
      >
        <Col span={10}>
          <DirectoryTable
            counterParties={counterParties}
            rowSelection={{
              selectedRowKeys: selectedCounterparties.map((item) => item.id),
              type: 'checkbox',
              onChange: onChange,
            }}
            handleChange={handlePaginationChange}
            loading={isLoading}
            pageNumber={pageNumber}
            selectCounterParty={selectCounterParty}
            activeCounterPartyId={activeCounterparty?.id}
            pageSize={pageSize}
            totalElements={totalElements}
          />
        </Col>
        <Col span={14}>
          {!!activeCounterparty && (
            <CounterPartyDetails
              preferences={preferences}
              counterParty={activeCounterparty}
              availableCodes={availableCodes}
              entityId={entityId}
              onMergeComplete={onUpdateComplete}
              paymentTerms={paymentTerms}
              shouldShowMergeButton={shouldShowMergeButton}
              updateCounterParty={updateCounterParty}
            />
          )}
        </Col>
      </Row>
      {isImportModalOpen && (
        <ImportCounterpartiesModal
          isOpen={isImportModalOpen}
          onClose={() => setIsImportModalOpen(false)}
          onImportComplete={onUpdateComplete}
        />
      )}
      {configureModalOpen && (
        <ConfigureCounterpartiesModal
          isOpen={configureModalOpen}
          entityId={entityId}
          counterParties={selectedCounterparties}
          onClose={() => setConfigureModalOpen(false)}
          onSuccess={onConfigureComplete}
        />
      )}
    </PaymentTermsContext.Provider>
  );
};

export default Directory;
