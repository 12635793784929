import React, { useState } from 'react';
import { Form, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import api from 'api';
import { hideInviteWindow } from 'store/invoices';
import { setRefreshData } from 'store/ui';
import { processError } from 'utils';
import { Modal } from 'ds';

type FormValues = { email: string; note: string };

const InviteWindow: React.FC = () => {
  const dispatch = useDispatch();

  const invoices = useSelector((state: any) => state.invoices);

  const { isVisibleInvite, invoiceType, selectedInvoice } = invoices;

  const handleHide = () => {
    form.resetFields();
    dispatch(hideInviteWindow());
  };

  const [form] = Form.useForm<FormValues>();

  const [loading, setLoading] = useState(false);

  const handleError = (error?: string) => {
    notification.error({
      message:
        error ??
        'Something went wrong. Please try again or contact Relay if the issue persists.',
    });
  };

  const handleSubmit = ({ email, note }: FormValues) => {
    setLoading(true);
    const payload = {
      email,
      note,
      type: invoiceType,
      companyId:
        invoiceType === 'INVOICE'
          ? selectedInvoice.owingCompany.id
          : selectedInvoice.companyOwed.id,
    };
    api.invite
      .sendInvite(payload)
      .then(() => {
        // Set store variable that triggers the refresh of table data
        dispatch(setRefreshData(true));
        setLoading(false);
        handleHide();
      })
      .catch((err) => {
        const { errors, status, message, debugMessage } = processError(err);
        if (status === 403 && debugMessage === 'company not verified') {
          handleHide();
        } else if (errors) {
          errors.map((message: string) => handleError(message));
        } else {
          handleError(message);
        }
        setLoading(false);
      });
  };

  return (
    <Modal
      title={invoiceType === 'INVOICE' ? 'Invite Customer' : 'Invite Supplier'}
      visible={isVisibleInvite && selectedInvoice}
      onCancel={handleHide}
      onOk={form.submit}
      okButtonProps={{ loading }}
      okText="Invite"
    >
      <Form
        form={form}
        initialValues={{ email: selectedInvoice?.contactEmail }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={invoiceType === 'INVOICE' ? 'Customer' : 'Supplier'}
          labelCol={{ span: 5 }}
        >
          <Input
            value={
              invoiceType === 'INVOICE'
                ? selectedInvoice?.owingCompany.companyName
                : selectedInvoice?.companyOwed.companyName
            }
            disabled
          />
        </Form.Item>

        <Form.Item
          label="Email"
          labelCol={{ span: 5 }}
          name="email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Email is not in valid format' },
          ]}
        >
          <Input
            placeholder={
              invoiceType === 'INVOICE' ? 'Customer email' : 'Supplier email'
            }
            required={true}
          />
        </Form.Item>

        <Form.Item label="Note" labelCol={{ span: 5 }} name="note">
          <Input.TextArea
            placeholder="You may include reference details here. e.g. Job Number, Claim Number etc."
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteWindow;
