import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  margin-top: 10px;
  background-color: #f8d7da;
  color: #721c24;
  font-size: 12px;
`;

const Error = ({ children }) => <Container>{children}</Container>;

export default Error;
