import React, { FC } from 'react';
import styled from 'styled-components/macro';
import colors from 'colors';
import CreditInput from './CreditInput';
import { Table, Typography } from 'antd';
import { Credit as CreditType } from '../AllocateCreditNote';
import { formatCurrency } from 'utils';

const { Text } = Typography;

const DataTable = styled(Table)`
  margin-top: 1.5rem;
  .ant-table-thead > tr > th {
    font-size: 10px;
    font-weight: bold !important;
    background-color: ${colors.greys100};
    border-bottom: none;
    color: ${colors.greys700};
    line-height: 14px;
    padding: 6px 16px;
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    color: ${colors.greys900};
    border-bottom: none;
    padding-bottom: 0px;
    padding-top: 12px;
    align-items: center;
  }
  .ant-table-tbody > tr:last-child > td {
    padding-bottom: 12px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }
  .row-with-background {
    background: ${colors.greys100};
    td {
      border-top: 8px solid #fff;
      padding-bottom: 9px !important;
      padding-top: 9px !important;
    }
  }
`;

type Props = {
  dataSource: CreditType[];
  onCreditChange: (invoiceId: string, credit: number | string) => void;
  onBlurFormat: (v: any) => void;
  loading: boolean;
  error: boolean;
};

const InvoiceTable: FC<Props> = ({
  dataSource,
  onCreditChange,
  onBlurFormat,
  loading,
  error,
}) => {
  const columns = [
    {
      title: 'INVOICE #',
      key: 'invoiceNumber',
      dataIndex: 'invoiceNumber',
    },
    {
      title: () => (
        <Text
          css={`
            position: relative;
            right: 0.65rem;
          `}
        >
          CREDIT
        </Text>
      ),
      key: 'allocatedAmount',
      align: 'right' as const,
      render: (record: CreditType) => {
        return (
          <CreditInput
            value={record.allocatedAmount}
            onChange={(value) => onCreditChange(record.invoiceId, value)}
            onBlurFormat={() => onBlurFormat(record.invoiceId)}
            error={
              record.amountDue < Number(record.allocatedAmount) ||
              Number(record.allocatedAmount) < 0 ||
              error
            }
          />
        );
      },
    },
    {
      title: 'AMOUNT DUE',
      key: 'amountDue',
      align: 'right' as 'right',
      render: (record: CreditType) => {
        if (record.allocatedAmount && record.allocatedAmount >= 0) {
          const updatedAmount =
            record.amountDue - parseFloat(`${record.allocatedAmount}`);
          const amountToShow = updatedAmount < 0 ? 0 : updatedAmount;

          return formatCurrency(amountToShow);
        } else {
          return formatCurrency(record.amountDue);
        }
      },
    },
  ];

  return (
    <DataTable
      rowKey="invoiceNumber"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      loading={loading}
      scroll={{ y: 180 }}
      rowClassName={(record) => {
        const isBackground = record.valueOf().hasOwnProperty('isAllocated');
        return isBackground ? 'row-with-background' : '';
      }}
    />
  );
};

export default InvoiceTable;
