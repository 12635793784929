import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from './Breadcrumbs';
import colors from 'colors';
import { withRouter, RouteComponentProps } from 'react-router';
import { Close } from 'assets/icons';
import spacing from 'styles/layout/spacing';
import breakpoints from 'styles/layout/breakpoints';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 40px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
`;

const Background = styled.div`
  background-color: ${colors.greys100};
  overflow: auto;
`;

const Container = styled.div`
  margin: 0 auto;
  width: ${breakpoints.desktop};
  padding: ${spacing.gutter.md} ${spacing.gutter.lg};
  min-height: 100vh;
`;

const LoadingContainer = styled(Container)`
  padding-top: 250px;
`;

type Props = {
  loading?: boolean;
  breadcrumbs?: {
    label: string;
    href?: string;
    onClick?: () => void;
  }[];
  history: {
    length: number;
    go: (numberOfPages: number) => void;
    push: (path: string) => void;
  };
  onClose?: () => void;
} & RouteComponentProps;

const AlternatePageLayout: React.FC<Props> = ({
  history,
  loading,
  children,
  breadcrumbs,
  onClose,
}) => {
  const goBack = (): void => {
    if (onClose) {
      onClose();
    } else {
      if (history.length && history.length > 2) history.go(-1);
      else history.push('/');
    }
  };
  if (loading) {
    return <LoadingContainer>{children}</LoadingContainer>;
  }
  return (
    <Background>
      <Container>
        <Header>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <CloseButton src={Close} alt="go back" onClick={goBack} />
        </Header>
        {children}
      </Container>
    </Background>
  );
};

export default withRouter(AlternatePageLayout);
