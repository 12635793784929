import React from 'react';
import colors from 'colors';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { InvoiceStatusDot, TableCellWithStatusDot } from 'components';
import { Typography } from 'antd';

const { Text } = Typography;

const Container = styled.div<{ $clickable: boolean }>`
  padding-left: 10px;
  position: relative;
  left: -10px;
  :hover {
    background: ${(props) => props.$clickable && colors.greys100};
    border-radius: 3px;
    cursor: ${(props) => props.$clickable && 'pointer'};

    text-decoration: ${(props) => props.$clickable && 'underline dotted'};
  }
`;

type Props = {
  id: string;
  number: string;
  includeInPurchaserOffer: boolean;
  ripeBillDocumentId: string | null;
};

const InvoiceNumber: React.FC<Props> = ({
  id,
  number,
  includeInPurchaserOffer,
  ripeBillDocumentId,
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (ripeBillDocumentId) {
      history.push(`/bills/r/${id}?rid=${ripeBillDocumentId}`);
    }
  };
  return (
    <Container $clickable={!!ripeBillDocumentId} onClick={handleClick}>
      <TableCellWithStatusDot>
        <InvoiceStatusDot includeInPurchaserOffer={includeInPurchaserOffer} />
        <Text>{number}</Text>
      </TableCellWithStatusDot>
    </Container>
  );
};

export default InvoiceNumber;
