import React, { useState, useEffect, useCallback } from 'react';
import api from 'api';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Alert, Button, notification, Typography } from 'antd';
import { GlAccount, AccountCodeApiResponse } from 'types/AccountCode';
import { AddAccountCodeModal } from './AddAccountCodeModal';
import { AccountCodeTable } from './AccountCodeTable';
import { processError } from 'utils';
import spacing from 'styles/layout/spacing';

const { Title } = Typography;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: ${spacing.gutter.sm};
`;

const Container = styled.div`
  max-width: 850px;
`;

export const AccountCodes: React.FC = () => {
  const entityId = useParams<{ id: string }>().id;

  // Array of account code records, as returned by the API
  // This is to PUT an account code record
  const [accountCodes, setAccountCodes] = useState<AccountCodeApiResponse[]>(
    []
  );

  const [activeAccountCodes, setActiveAccountCodes] = useState<GlAccount[]>([]);
  const [availableAccountCodes, setAvailableAccountCodes] = useState<
    GlAccount[]
  >([]);
  const [accountCodeToEdit, setAccountCodeToEdit] = useState<GlAccount | null>(
    null
  );
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const getAccountCodes = useCallback(() => {
    setLoading(true);
    api.admin.xeroAccounts
      .get({
        entityId,
        size: 1000,
      })
      .then((data) => {
        setAccountCodes(data.accounts.map((account) => account.account));
        const retrievedAccounts: GlAccount[] = data.accounts.map(
          ({ account }) => {
            const {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            } = account;
            return {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            };
          }
        );
        setAvailableAccountCodes(
          retrievedAccounts.filter((account) => !account.isVisibleInRelay)
        );
        setActiveAccountCodes(
          retrievedAccounts.filter((account) => account.isVisibleInRelay)
        );
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [entityId]);

  useEffect(() => {
    getAccountCodes();
  }, [getAccountCodes]);

  const handleClickEdit = (accountCodeToEdit: GlAccount) => {
    setModalOpen(true);
    setAccountCodeToEdit(accountCodeToEdit);
  };

  const handleClickAdd = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setAccountCodeToEdit(null);
    setModalOpen(false);
  };

  const onSuccess = () => {
    getAccountCodes();
    handleModalClose();
  };

  return (
    <Container>
      <Title level={5}>GL Codes</Title>
      {availableAccountCodes.length === 0 &&
      activeAccountCodes.length === 0 &&
      !isLoading ? (
        <Alert
          message="Company not configured to retrieve available GL codes from Xero"
          type="warning"
          closable
        />
      ) : (
        <>
          <AccountCodeTable
            accountCodes={activeAccountCodes}
            handleClickEdit={handleClickEdit}
            isLoading={isLoading}
          />
          <ButtonContainer>
            {availableAccountCodes.length > 0 ? (
              <Button type="primary" onClick={handleClickAdd}>
                Add
              </Button>
            ) : (
              <Alert
                message="All available GL codes have been added"
                type="info"
                closable
              />
            )}
          </ButtonContainer>
        </>
      )}
      {isModalOpen && (
        <AddAccountCodeModal
          accountCodeToEdit={accountCodeToEdit}
          entityId={entityId}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSuccess={onSuccess}
          accountCodes={accountCodes}
          availableAccountCodes={availableAccountCodes}
        />
      )}
    </Container>
  );
};
