import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from 'api';
import Company from 'types/Company';
import User, { UserState, VerificationStatus } from 'types/User';
import { login } from 'store/auth';

type Props = {
  setUserStateCallback?: (state: UserState) => void;
  token: string;
  user: User | null;
  company: Company | null;
} & RouteComponentProps;

const UserStateCheck: React.FC<Props> = ({
  history,
  location,
  setUserStateCallback,
  token,
  company,
}) => {
  const {
    step,
    set_name,
    set_phone_number,
    upload_document,
    verify_code,
  } = queryString.parse(location.search);
  const [verificationStatus, setVerificationStatus] = useState<
    VerificationStatus | ''
  >('');
  const [userState, setUserState] = useState<UserState | ''>('');

  const dispatch = useDispatch();

  useEffect(() => {
    api.user.get(token).then(async (user) => {
      setVerificationStatus(user.verificationStatus);
      const res = await api.user.getState({ token, userId: user.id });
      if (
        company?.address?.country === 'AU' &&
        res.state === 'BUSINESS_DETAILS_CONFIRMED'
      ) {
        dispatch(
          login({
            user: { ...user, company: { company }, token },
            loggedIn: true,
          })
        );
        history.push('/invoices?tab=all');
      } else {
        setUserState(res.state);
        setUserStateCallback && setUserStateCallback(res.state);
      }
    });
  }, [setUserStateCallback, token, company, dispatch, history]);

  switch (userState) {
    case 'SIGNED_UP':
      if (set_name !== 'true') {
        history.replace(`/activate?set_name=true&token=${token}`);
      }
      break;
    case 'EMAIL_VERIFIED':
      if (set_name !== 'true') {
        history.replace(`/activate?set_name=true&token=${token}`);
      }
      break;
    case 'PASSWORD_UPDATED':
      if (set_phone_number !== 'true' && verify_code !== 'true') {
        history.replace(`/activate?set_phone_number=true&token=${token}`);
      }
      break;
    case 'PHONE_NUMBER_VERIFIED':
      if (
        verificationStatus === 'VERIFICATION_FAILED' &&
        (step !== 'user_details' || upload_document !== 'true')
      ) {
        history.replace(
          `/verify?step=user_details&upload_document=true&token=${token}`
        );
      } else if (step !== 'user_details') {
        history.replace(`/verify?step=user_details&token=${token}`);
      }
      break;
    case 'OWNER_VERIFIED':
      if (step !== 'business_details') {
        history.replace(`/verify?step=business_details&token=${token}`);
      }
      break;
    case 'BUSINESS_DETAILS_CONFIRMED':
      break;
    case 'ONBOARDED':
      break;
    default:
      break;
  }
  return null;
};

export default withRouter(UserStateCheck);
