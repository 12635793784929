import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from 'api';
import styled from 'styled-components';
import currencyFormatter from 'utils/currencyFormatter';
import DeleteProgress from './DeleteProgress';
import { notification, Table, Tabs, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import {
  setPageNumber,
  setPageSize,
  setSortBy,
  setSortDirection,
} from 'store/ripe';
import { setToolbarTitle } from 'store/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps, useLocation } from 'react-router';
import { useNavigate } from 'hooks';

import RipeCompanyInvoice, {
  ProcessingStatus,
  RipePageType,
  SortDirection,
  SortBy,
  RipeType,
  RipeCompanyInvoiceAPIResolve,
  InvoiceStatistics,
} from 'types/RipeCompanyInvoice';

import Pagination from './Pagination';
import TableButton from 'components/TableButton';
import {
  getInvoiceVariance,
  getReasonFromMatchStatus,
  processError,
} from 'utils';
import { colors } from 'themes';

import DeleteButton from './DeleteButton';
import SearchBar from './SearchBar';
import Filter from './Filter';
import TitleWithSort from './TitleWithSort';
import InvoiceKpis from './Statistics/InvoiceKpis';

const { TabPane } = Tabs;

type AlignType = 'left' | 'right' | 'center';

type RenderButtonParams = {
  ripePageType: RipePageType;
  documentId: RipeCompanyInvoice['documentId'];
  lockStatus: RipeCompanyInvoice['lockStatus'];
  lockedBy: RipeCompanyInvoice['lockedBy'];
  username: string;
  documentIds: string[];
  nextPageHref: string;
  prevPageHref: string;
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  locationParams: URLSearchParams;
};

const renderButton = ({
  ripePageType,
  documentId,
  documentIds,
  lockStatus,
  lockedBy,
  username,
  nextPageHref,
  prevPageHref,
  pageNumber,
  pageSize,
  totalElements,
  locationParams,
}: RenderButtonParams) => {
  type ButtonLabel = 'Review' | 'View' | 'Locked';
  const urlPathFragment =
    ripePageType === RipeType.PENDING_REVIEW
      ? 'pending-review'
      : ripePageType === RipeType.PENDING_APPROVAL
      ? 'pending-approval'
      : ripePageType === RipeType.VARIANCE
      ? 'variances'
      : ripePageType === RipeType.PROCESSING
      ? 'processing'
      : ripePageType === RipeType.BLOCKED
      ? 'blocked'
      : ripePageType === RipeType.UNMATCHED
      ? 'unmatched'
      : '';
  const button = ({
    locked,
    label,
  }: {
    locked: boolean;
    label: ButtonLabel;
  }) => (
    <TableButton className="table-row-button" icon={locked && <LockOutlined />}>
      {label}
    </TableButton>
  );
  if (lockStatus === 'LOCKED' && lockedBy !== username) {
    const title = `Locked for editing${lockedBy && ` by ${lockedBy}`}`;
    return (
      <Tooltip title={title} placement="left">
        {button({ locked: true, label: 'Locked' })}
      </Tooltip>
    );
  } else {
    let label: ButtonLabel = 'Review';
    if (
      ripePageType === RipeType.PROCESSING ||
      ripePageType === RipeType.UNMATCHED
    ) {
      label = 'View';
    }
    return (
      <Link
        to={{
          pathname: `/bills/${urlPathFragment}/${documentId}`,
          state: {
            documentIds,
            nextPageHref,
            prevPageHref,
            pageNumber,
            pageSize,
            totalElements,
            direction: 'forward',
            previousParams: locationParams.toString(),
          },
        }}
      >
        {button({ locked: false, label })}
      </Link>
    );
  }
};

const ActionContainer = styled.div`
  height: 44px;
  background-color: #f6f7f8;
  display: flex;
  align-items: center;
  padding: 10px;
  .ant-btn {
    padding: 0px 20px;
    border-radius: 2px;
    border-color: ${colors.greys500};
    font-size: 10px;
    :hover {
      border-color: ${colors.greys500};
      color: initial;
      background: ${colors.greys200};
    }
    :focus {
      color: initial;
    }
    :active {
      color: initial;
    }
  }
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Container = styled.div`
  margin-top: 1rem;
`;

const StyledTable = styled(Table)`
  min-height: 400px;
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
  }
  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 0;
    text-align: center;
  }

  .ant-table-thead .ant-table-cell {
    background-color: rgb(103, 108, 116);
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    font-variant: tabular-nums;
    line-height: 1.5715;
    height: 24px;
    padding: 4px 10px;
    border-radius: 0;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 10px;
    font-size: 12px;
  }
  .ant-button > span {
    font-size: 12px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ant-table-expanded-row .ant-spin-nested-loading {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .ant-table-tbody > tr:hover > td {
    background-color: transparent;
    .table-row-button {
      background-color: ${colors.greys200};
    }
  }
  .ant-table-tbody > .ant-table-placeholder > .ant-table-cell {
    display: table-cell;
    border-bottom: none;
    padding: 20px 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 400;
  }
  .ant-table-tbody .custom-sorted-column {
    padding-right: 23px !important;
  }
`;

type Props = {
  ripePageType: RipePageType;
} & RouteComponentProps;

type StateProps = {
  username: string;
  pageNumber: number;
  pageSize: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
};

type DispatchProps = {
  setToolbarTitle: (title: string) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setSortBy: (sortBy: SortBy) => void;
  setSortDirection: (sortDirection: SortDirection) => void;
};

const BillsPendingReview: React.FC<Props & StateProps & DispatchProps> = ({
  setToolbarTitle,
  ripePageType,
  username,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  location,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const locationParams = new URLSearchParams(search);

  const tabParam = locationParams.get('tab') || 'ALL';
  const pageNumberParam = locationParams.get('page');
  const pageSizeParam = locationParams.get('size');
  const supplierParam = locationParams.get('supplier');
  const sortParam = locationParams.get('sort');

  const amountParam = locationParams.get('amount');
  const orderNumberParam = locationParams.get('orderNumber');
  const invoiceNumberParam = locationParams.get('invoiceNumber');
  const issueDateParam = locationParams.get('issueDate');

  const [documents, setDocuments] = useState<RipeCompanyInvoice[]>([]);
  const [invoiceStatistics, setInvoiceStatistics] =
    useState<InvoiceStatistics>();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [nextPageHref, setNextPageHref] = useState('');
  const [prevPageHref, setPrevPageHref] = useState('');
  const [activeTabKey, setActiveTabKey] = useState(tabParam);

  const [suggestedSearchValue, setSuggestedSearchValue] = useState(
    supplierParam || ''
  );

  const [filterParams, setFilterParams] = useState('');
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<
    (string | number)[]
  >([]);

  const [batchReviewId, setBatchReviewId] = useState<null | string>(null);

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    const params = new URLSearchParams();
    if (amountParam) {
      params.set('invoiceAmount', amountParam);
    }
    if (orderNumberParam) {
      params.set('orderNumber', orderNumberParam);
    }
    if (invoiceNumberParam) {
      params.set('invoiceNumber', invoiceNumberParam);
    }
    if (issueDateParam) {
      params.set('dateIssue', issueDateParam);
    }
    if (params.toString()) {
      setFilterParams(`${params}&`);
    } else {
      setFilterParams('');
    }
  }, [amountParam, orderNumberParam, invoiceNumberParam, issueDateParam]);

  useEffect(() => {
    if (location.state && location.state.previousParams) {
      navigate({ params: new URLSearchParams(location.state.previousParams) });
    }
  }, [location, navigate, setPageNumber, setPageSize]);

  useEffect(() => {
    if (pageNumberParam) {
      setPageNumber(Number(pageNumberParam));
    } else {
      setPageNumber(1);
    }
  }, [pageNumberParam, setPageNumber]);

  useEffect(() => {
    if (pageSizeParam) {
      setPageSize(Number(pageSizeParam));
    }
  }, [pageSizeParam, setPageSize]);

  useEffect(() => {
    if (sortParam) {
      const [sortBy, sortDirection] = sortParam.split(',');
      if (sortBy) {
        // @ts-ignore
        setSortBy(sortBy);
      }
      if (sortDirection) {
        // @ts-ignore
        setSortDirection(sortDirection);
      }
    }
  }, [sortParam, setSortBy, setSortDirection]);

  useEffect(() => {
    if (supplierParam) {
      setSuggestedSearchValue(supplierParam);
    }
  }, [supplierParam]);

  useEffect(() => {
    if (tabParam) {
      setActiveTabKey(tabParam);
    }
  }, [tabParam]);

  const handleChangePageParams = ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }) => {
    const params = new URLSearchParams();
    params.set('page', String(pageNumber));
    params.set('size', String(pageSize));
    navigate({ params });
    setPageNumber(pageNumber);
    setPageSize(pageSize);
  };

  useEffect(() => {
    let title = 'Bills';
    if (ripePageType === RipeType.PENDING_REVIEW) {
      title = 'Bills Pending Review';
    } else if (ripePageType === RipeType.PENDING_APPROVAL) {
      title = 'Bills Pending Approval';
    } else if (ripePageType === RipeType.PROCESSING) {
      title = 'Bills Processing';
    } else if (ripePageType === RipeType.VARIANCE) {
      title = 'Bills with Variances';
    } else if (ripePageType === RipeType.BLOCKED) {
      title = 'Bills Blocked';
    } else if (ripePageType === RipeType.UNMATCHED) {
      title = 'Unmatched Bills';
    }
    setToolbarTitle(title);
  }, [ripePageType, setToolbarTitle]);

  const getParams = useCallback(() => {
    let processingStatus: ProcessingStatus = 'RETRY';
    let reviewedInRelay: boolean | undefined = false;
    let authorisedAmount: number | undefined = undefined;
    if (ripePageType === RipeType.PENDING_REVIEW) {
      processingStatus = 'RETRY';
      reviewedInRelay = false;
    } else if (ripePageType === RipeType.PENDING_APPROVAL) {
      processingStatus = 'PENDING_APPROVAL';
      reviewedInRelay = true;
      authorisedAmount = 0;
    } else if (ripePageType === RipeType.PROCESSING) {
      processingStatus = 'RETRY';
      reviewedInRelay = true;
    } else if (ripePageType === RipeType.VARIANCE) {
      processingStatus = 'VARIANCE';
      reviewedInRelay = undefined;
      authorisedAmount = 0;
    } else if (ripePageType === RipeType.BLOCKED) {
      processingStatus = 'BLOCKED';
      reviewedInRelay = undefined;
    } else if (ripePageType === RipeType.UNMATCHED) {
      processingStatus = 'NO_MATCH';
      reviewedInRelay = undefined;
    }

    let matchStatus = '';
    let matchStatuses = '';
    if (activeTabKey === 'NO_PO_FOUND') {
      matchStatus = 'ORDER_NOT_FOUND_OCR';
    } else if (activeTabKey === 'NO_PO_MATCH') {
      matchStatus = 'ORDER_NOT_FOUND_GPOS';
    } else if (activeTabKey === 'STATUS_MISMATCH') {
      matchStatus = 'STATUS_MISMATCH';
    } else if (activeTabKey === 'VARIANCE') {
      matchStatuses = 'AMOUNT_UNDER,AMOUNT_OVER';
    } else if (activeTabKey === 'MISSING_DATA') {
      matchStatuses = 'INVOICE_DETAILS_MISSING';
    } else if (activeTabKey === 'CONTACT_NOT_FOUND') {
      matchStatuses = 'CONTACT_NOT_FOUND';
    } else if (activeTabKey === 'PERIOD_LOCK') {
      matchStatuses = 'EXPIRED';
    } else if (activeTabKey === 'DUPLICATE_BILL') {
      matchStatuses = 'POTENTIAL_DUPLICATE';
    } else if (activeTabKey === 'CLOSED_BILL') {
      matchStatuses = 'ALREADY_CLOSED';
    } else if (activeTabKey === 'BLOCKED') {
      processingStatus = 'BLOCKED';
      reviewedInRelay = undefined;
    }

    return {
      matchStatus,
      matchStatuses,
      processingStatus,
      reviewedInRelay,
      authorisedAmount,
    };
  }, [activeTabKey, ripePageType]);

  const getData = useCallback(() => {
    const params = getParams();
    let searchParam = '';
    if (suggestedSearchValue) {
      searchParam = `supplierName=${encodeURIComponent(suggestedSearchValue)}&`;
    }

    let sort = 'sort=updatedAt,asc&';
    if (sortBy && sortDirection) {
      sort = `sort=${sortBy},${sortDirection}&`;
    }

    setLoading(true);

    abortControllerRef?.current?.abort();
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    api.ripe
      .getDocuments({
        ...params,
        pageNumber: pageNumber - 1,
        pageSize,
        searchParam,
        filterParams,
        sort,
        abortController,
      })
      .then(
        ({
          docs,
          totalPages,
          totalElements,
          _links,
        }: RipeCompanyInvoiceAPIResolve) => {
          setDocuments(docs);
          setTotalElements(totalElements);
          setTotalPages(totalPages);
          if (_links && _links.next && _links.next.href) {
            setNextPageHref(_links.next.href);
          }
          if (_links && _links.prev && _links.prev.href) {
            setPrevPageHref(_links.prev.href);
          }
          setDocumentIds(docs.map((doc) => doc.documentId));
        }
      )
      .catch((error) => {
        const { message } = processError(error);
        if (message !== 'canceled') {
          notification.error({ message });
        }
        setDocuments([]);
      })
      .finally(() => setLoading(false));
  }, [
    pageNumber,
    pageSize,
    suggestedSearchValue,
    getParams,
    filterParams,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getStatistics = useCallback(() => {
    const { processingStatus, reviewedInRelay, authorisedAmount } = getParams();
    api.ripe
      .getStatistics({
        processingStatus,
        reviewedInRelay,
        authorisedAmount,
      })
      .then((invoiceStatistics) => setInvoiceStatistics(invoiceStatistics))
      .catch((error) => {
        const { message } = processError(error);
        if (message !== 'canceled') {
          notification.error({ message });
        }
      });
  }, [getParams]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  const selectedDocumentsCount = selectedDocumentIds.length;

  const handleDelete = () => {
    const documentsToReview = selectedDocumentIds.map((id) => ({
      documentId: id,
      state: 'DELETE' as const,
    }));
    const payload = { documentsToReview };
    setDeleting(true);
    api.ripe
      .batchReviewDocuments(payload)
      .then((response) => setBatchReviewId(response))
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      });
  };

  const hideProgressModal = ({ hasSuccess }: { hasSuccess: boolean }) => {
    setBatchReviewId(null);
    if (hasSuccess) {
      getData();
      getStatistics();
    }
    setSelectedDocumentIds([]);
    setDeleting(false);
  };

  const rowSelection = {
    selectedRowKeys: selectedDocumentIds,
    onChange: (selectedRowKeys: (string | number)[]) => {
      setSelectedDocumentIds(selectedRowKeys);
    },
  };

  const orderColumn = {
    title: (
      <TitleWithSort
        title="ORDER #"
        columnKey="orderNumber"
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSortBy={setSortBy}
        setSortDirection={setSortDirection}
      />
    ),
    render: (record: RipeCompanyInvoice) => record.orderNumber || '-',
    key: 'ORDER_NUMBER',
  };

  const columnsLeftSlice = [
    {
      title: (
        <TitleWithSort
          title="SUPPLIER"
          columnKey="supplierName"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
        />
      ),
      render: (record: RipeCompanyInvoice) => record.supplierName || '-',
      key: 'SUPPLIER',
    },
    {
      title: (
        <TitleWithSort
          title="INVOICE #"
          columnKey="invoiceNumber"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
        />
      ),
      render: (record: RipeCompanyInvoice) => record.invoiceNumber || '-',
      key: 'INVOICE_NUMBER',
    },
    {
      title: (
        <TitleWithSort
          title="ISSUE DATE"
          columnKey="dateIssue"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
        />
      ),
      render: (record: RipeCompanyInvoice) => record.dateIssue || '-',
      key: 'ISSUE_DATE',
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    render: (record: RipeCompanyInvoice) =>
      renderButton({
        ripePageType,
        documentId: record.documentId,
        lockStatus: record.lockStatus,
        lockedBy: record.lockedBy,
        username,
        documentIds,
        nextPageHref,
        prevPageHref,
        pageNumber,
        pageSize,
        totalElements,
        locationParams,
      }),
    key: 'ACTION',
    align: 'center' as AlignType,
  };

  const amountColumn = {
    title: (
      <TitleWithSort
        title="AMOUNT DUE"
        columnKey="invoiceAmount"
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSortBy={setSortBy}
        setSortDirection={setSortDirection}
      />
    ),
    key: 'AMOUNT_DUE',
    render: (record: RipeCompanyInvoice) =>
      record.invoiceAmount
        ? currencyFormatter.format(parseFloat(record.invoiceAmount))
        : '-',
    align: 'right' as const,
    className: 'custom-sorted-column',
  };

  const reasonColumn = {
    title: 'REASON',
    render: (record: RipeCompanyInvoice) =>
      getReasonFromMatchStatus(record.matchStatus),
    key: 'REASON',
  };

  const varianceColumns = [
    {
      title: (
        <TitleWithSort
          title="POS AMOUNT"
          columnKey="posAmount"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
        />
      ),
      render: (record: RipeCompanyInvoice) =>
        record.posAmount
          ? currencyFormatter.format(parseFloat(record.posAmount))
          : '-',
      key: 'POS_AMOUNT',
      align: 'right' as const,
      className: 'custom-sorted-column',
    },
    {
      title: 'INVOICE VARIANCE',
      render: (record: RipeCompanyInvoice) =>
        getInvoiceVariance({
          posAmount: record.posAmount,
          invoiceAmount: record.invoiceAmount,
        }),
      key: 'INVOICE_VARIANCE',
      align: 'right' as const,
    },
  ];

  let columns: object[];
  switch (ripePageType) {
    case RipeType.VARIANCE:
      columns = [
        orderColumn,
        ...columnsLeftSlice,
        amountColumn,
        ...varianceColumns,
        actionColumn,
      ];
      break;
    case RipeType.PENDING_APPROVAL:
      columns = [...columnsLeftSlice, amountColumn, actionColumn];
      break;
    default:
      columns = [
        orderColumn,
        ...columnsLeftSlice,
        reasonColumn,
        amountColumn,
        actionColumn,
      ];
      break;
  }

  const tabs =
    ripePageType === RipeType.PENDING_APPROVAL
      ? [
          { tab: 'All', key: 'ALL' },
          { tab: 'Variance', key: 'VARIANCE' },
        ]
      : ripePageType === RipeType.BLOCKED
      ? [
          { tab: 'All', key: 'ALL' },
          { tab: 'Missing Data', key: 'MISSING_DATA' },
          { tab: 'Period Lock', key: 'PERIOD_LOCK' },
          { tab: 'Duplicate Bill', key: 'DUPLICATE_BILL' },
          { tab: 'Closed Bill', key: 'CLOSED_BILL' },
          { tab: 'Contact Not Found', key: 'CONTACT_NOT_FOUND' },
        ]
      : [
          { tab: 'All', key: 'ALL' },
          { tab: 'No PO Found', key: 'NO_PO_FOUND' },
          { tab: 'No PO Match', key: 'NO_PO_MATCH' },
          { tab: 'Status Mismatch', key: 'STATUS_MISMATCH' },
          { tab: 'Variance', key: 'VARIANCE' },
        ];

  const onChangeTab = (tabKey: string) => {
    const params = new URLSearchParams();
    params.set('tab', tabKey);
    params.set('page', '1');
    navigate({ params });
    setActiveTabKey(tabKey);
    setPageNumber(1);
  };

  const handleChangeSearchValue = (value: string) => {
    const params = new URLSearchParams();
    params.set('supplier', value);
    params.set('page', '1');
    navigate({ params });
    setSuggestedSearchValue(value);
    setPageNumber(1);
  };

  const handleClearSearchValue = () => {
    const params = locationParams;
    params.delete('supplier');
    params.set('page', '1');
    navigate({ params, reset: true });
    setSuggestedSearchValue('');
    setPageNumber(1);
  };

  return (
    <Container>
      <Helmet>
        <title>Relay | Bills pending for review</title>
      </Helmet>
      {(ripePageType === RipeType.VARIANCE ||
        ripePageType === RipeType.PENDING_REVIEW) && (
        <InvoiceKpis
          ripeType={ripePageType}
          invoiceStatistics={invoiceStatistics}
          tab={activeTabKey}
        />
      )}
      {ripePageType === RipeType.VARIANCE ? (
        <>
          <ActionContainer>
            {selectedDocumentsCount !== 0 && (
              <DeleteButton
                selectedDocumentsCount={selectedDocumentsCount}
                handleDelete={handleDelete}
                deleting={deleting}
              />
            )}
            <FilterContainer>
              <Filter />
              <SearchBar
                getParams={getParams}
                filterParams={filterParams}
                handleChangeSearchValue={handleChangeSearchValue}
                handleClearSearchValue={handleClearSearchValue}
                suggestedSearchValue={suggestedSearchValue}
              />
            </FilterContainer>
          </ActionContainer>
          <StyledTable
            columns={columns}
            dataSource={documents}
            loading={loading}
            pagination={false}
            locale={{ emptyText: 'No items to show' }}
            rowKey="documentId"
            rowSelection={rowSelection}
            size="small"
          />
          <Pagination
            rows={totalElements}
            totalPages={totalPages}
            pageNumber={pageNumber}
            pageSize={pageSize}
            handleChangePageParams={handleChangePageParams}
          />
        </>
      ) : (
        <Tabs activeKey={activeTabKey} onChange={onChangeTab} animated={false}>
          {tabs.map((tab) => (
            <TabPane tab={tab.tab} key={tab.key}>
              <ActionContainer>
                {(ripePageType === RipeType.PENDING_REVIEW ||
                  ripePageType === RipeType.PENDING_APPROVAL ||
                  ripePageType === RipeType.BLOCKED) &&
                  selectedDocumentsCount !== 0 && (
                    <DeleteButton
                      selectedDocumentsCount={selectedDocumentsCount}
                      handleDelete={handleDelete}
                      deleting={deleting}
                    />
                  )}
                <FilterContainer>
                  <Filter />
                  <SearchBar
                    getParams={getParams}
                    filterParams={filterParams}
                    handleChangeSearchValue={handleChangeSearchValue}
                    handleClearSearchValue={handleClearSearchValue}
                    suggestedSearchValue={suggestedSearchValue}
                  />
                </FilterContainer>
              </ActionContainer>
              <StyledTable
                columns={columns}
                dataSource={documents}
                loading={loading}
                pagination={false}
                locale={{ emptyText: 'No items to show' }}
                rowKey="documentId"
                rowSelection={
                  ripePageType === RipeType.PENDING_REVIEW ||
                  ripePageType === RipeType.PENDING_APPROVAL ||
                  ripePageType === RipeType.BLOCKED
                    ? rowSelection
                    : undefined
                }
                size="small"
              />
              <Pagination
                rows={totalElements}
                totalPages={totalPages}
                pageNumber={pageNumber}
                pageSize={pageSize}
                handleChangePageParams={handleChangePageParams}
              />
            </TabPane>
          ))}
        </Tabs>
      )}
      {batchReviewId && (
        <DeleteProgress onHide={hideProgressModal} reviewId={batchReviewId} />
      )}
    </Container>
  );
};

const mapStateToProps = ({
  auth,
  ripe,
}: {
  auth: any;
  ripe: {
    pageNumber: number;
    pageSize: number;
    sortBy: SortBy;
    sortDirection: SortDirection;
  };
}): StateProps => ({
  username: auth.user?.username,
  pageNumber: ripe.pageNumber,
  pageSize: ripe.pageSize,
  sortBy: ripe.sortBy,
  sortDirection: ripe.sortDirection,
});

const mapDispatchToProps: DispatchProps = {
  setPageNumber,
  setPageSize,
  setToolbarTitle,
  setSortBy,
  setSortDirection,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsPendingReview);
