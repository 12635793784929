import React, { useEffect, useState } from 'react';
import {
  Action,
  Amount,
  CashBack,
  CompanyName,
  Email,
  InvoiceNumber,
  Header,
  Status,
} from '../components';
import useTableActions from './useTableActions';
import SupplierOffer from 'types/SupplierOffer';
import Invoice, { InvoiceItem } from 'types/Invoice';
import { PurchaserOfferResource } from 'types/PurchaserOffer';
import { findBillStatus, findOtherCompanyStatus } from '../utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { formatCurrency, formatDateForDisplay, validateEmail } from 'utils';
import { TableAction, TableRow } from '../types';
import { showInviteWindow } from 'store/invoices';
import { setDataToRefresh } from 'store/ui';
import { setShowPayNow } from 'store/offerLanding';
import { MailOutlined } from '@ant-design/icons';
import api from 'api';

type AlignType = 'left' | 'center' | 'right';

const NUMBER_OF_PO_DISABLED_COUNTERPARTIES_TO_GET = 2000;

const useInvoiceTable = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((store: any) => store.invoices.loadingInvoices);

  const [rows, setRows] = useState<TableRow[]>([]);

  const [
    purchaserOfferDisabledCounterPartyIDs,
    setPurchaserOfferDisabledCounterPartyIDs,
  ] = useState<string[]>([]);

  const {
    cancelPayment,
    includeExcludeBill,
    invite,
    isPending,
    isPaidInvoiceModalVisible,
    sendNotification,
    selectedInvoiceId,
    selectedInvoice,
    selectedRow,
    selectedBillForDetails,
    setPaidInvoiceModalVisible,
    setSelectedInvoiceId,
    setSelectedInvoice,
    setSelectedRow,
    setSelectedBillForDetails,
    setSupplierOfferToExecute,
    setVisibleEditContactEmail,
    supplierOfferToExecute,
    visibleEditContactEmail,
  } = useTableActions();

  const showPayNow = useSelector((state: any) => state.offerLanding.showPayNow);

  const bills = useSelector(
    (store: any) =>
      store.invoices.bills.map((bill: InvoiceItem) => bill.invoice),
    shallowEqual
  );

  const user = useSelector((store: any) => store.auth.user);
  const companyId = user?.company?.company?.id;

  const purchaserOffers: PurchaserOfferResource = useSelector(
    (store: any) => store.offers.purchaserOffers.offered
  );

  const activePurchaserOffer = purchaserOffers.find(
    ({ purchaserOffer }) =>
      purchaserOffer.offerStatus === 'ACTIVE' ||
      purchaserOffer.offerStatus === 'VIRTUAL'
  );

  const supplierOfffers: SupplierOffer[] = useSelector(
    (store: any) => store.offers.supplierOffers.available
  );

  useEffect(() => {
    api.company
      .getCounterParties({
        companyId,
        isPurchaserOffersEnabled: false,
        size: NUMBER_OF_PO_DISABLED_COUNTERPARTIES_TO_GET,
      })
      .then((response) => {
        const ids = response.counterParties.map(
          (counterParty) => counterParty.counterParty.counterParty.id
        );
        setPurchaserOfferDisabledCounterPartyIDs(ids);
      });
  }, [companyId]);

  useEffect(() => {
    dispatch(setDataToRefresh('INVOICE'));
  }, [dispatch]);

  useEffect(() => {
    const rows = bills.map((bill: Invoice) => {
      const {
        id,
        billNumber,
        billStatus,
        cashReward,
        includeInPurchaserOffer,
        issuedByCompanyName,
        dueDate,
        discountedTotal,
        contactEmail,
        sentToSupplier,
        companyOwed,
        invoiceStatus,
        issuedBy,
        total,
        ripeBillDocumentId,
      } = bill;

      const otherCompanyId = issuedBy;

      let row = {
        key: id,
        billStatus,
        invoiceNumber: '',
        supplier: '',
        dueDate: '',
        amountDue: '',
        includeInPurchaserOffer: false,
        invoiceStatus,
        isPurchaserOffersDisabled: false,
        status: '',
        cashback: '',
        cashbackPercentage: '',
        isNotEligible: false,
        notEligibleReason: '',
        email: '',
        otherCompanyId: '',
        otherCompanyStatus: bill.companyOwed.status,
        otherCompanyVerificationStatus: bill.companyOwed.verificationStatus,
        actionLabel: 'View' as TableAction,
        action: () => {},
        unformattedAmountDue: 0,
        total: 0,
        ripeBillDocumentId,
      };

      row.invoiceNumber = billNumber || '-';
      row.supplier = issuedByCompanyName || '-';
      row.dueDate = dueDate ? formatDateForDisplay(dueDate) : '-';
      row.amountDue = discountedTotal ? formatCurrency(discountedTotal) : '-';
      row.includeInPurchaserOffer = includeInPurchaserOffer;
      row.invoiceStatus = invoiceStatus;
      row.otherCompanyId = otherCompanyId;
      row.email = contactEmail || '';
      row.unformattedAmountDue = discountedTotal ? discountedTotal : 0;
      row.total = total;

      const otherCompanyStatus = findOtherCompanyStatus({
        loggedInCompanyId: companyId,
        otherCompanyStatus: companyOwed.status,
        invitedBy: companyOwed.invitedBy,
      });

      row.otherCompanyStatus = otherCompanyStatus;

      const supplierOffer = supplierOfffers.find((offer) => {
        const { invoiceIds, offerStatus } = offer;
        return (
          (offerStatus === 'CLEARING' || offerStatus === 'TOPUP_PENDING') &&
          invoiceIds.includes(id)
        );
      });

      // Let's check if the bill under iteration is from a supplier
      // for which purchaser offers are disabled
      const isPurchaserOffersDisabled =
        purchaserOfferDisabledCounterPartyIDs.some(
          (counterPartyId) => counterPartyId === bill.issuedBy
        );

      const statusValues = findBillStatus({
        billStatus,
        cashReward,
        dueDate,
        total: discountedTotal,
        includeInPurchaserOffer,
        isPurchaserOffersEnabled: !isPurchaserOffersDisabled,
        otherCompanyStatus,
        sentToSupplier,
        purchaserOffer: activePurchaserOffer?.purchaserOffer,
        supplierOffer,
      });

      row.status = statusValues.status;
      row.cashback = statusValues.cashback;
      row.cashbackPercentage = statusValues.cashbackPercentage;
      row.isNotEligible = statusValues.isNotEligible;
      row.notEligibleReason = statusValues.notEligibleReason;
      row.actionLabel = statusValues.actionLabel;
      row.isPurchaserOffersDisabled = isPurchaserOffersDisabled;

      switch (statusValues.actionLabel) {
        case 'Cancel':
          if (supplierOffer) {
            row.action = () => cancelPayment(supplierOffer);
          }
          break;
        case 'Include':
          row.action = () => includeExcludeBill({ id, action: 'INCLUDE' });
          break;
        case 'Exclude':
          row.action = () => includeExcludeBill({ id, action: 'EXCLUDE' });
          break;
        case 'Notify':
          row.action = () => sendNotification(otherCompanyId);
          break;
        case 'Pay Now':
          row.action = () => {
            supplierOffer && setSupplierOfferToExecute(supplierOffer);
            setSelectedRow(row);
          };
          break;
        case 'View':
          row.action = () => {
            setPaidInvoiceModalVisible(true);
            setSelectedInvoiceId(id);
          };
          break;
        default:
          break;
      }

      if (
        statusValues.status === 'Payment Offered' &&
        otherCompanyStatus === 'INACTIVE'
      ) {
        row.actionLabel = 'Invite';
        if (contactEmail && validateEmail(contactEmail)) {
          row.action = () => invite({ invoiceType: 'BILL', invoice: bill });
        } else {
          row.action = () =>
            dispatch(showInviteWindow({ type: 'BILL', data: bill }));
        }
      }

      if (
        billStatus === 'CASHBACK_AVAILABLE' &&
        supplierOffer &&
        showPayNow &&
        !selectedRow
      ) {
        setSelectedRow(row);
        setSupplierOfferToExecute(supplierOffer);
        dispatch(setShowPayNow(false));
      }

      return row;
    });

    setRows(rows);
  }, [
    activePurchaserOffer,
    bills,
    cancelPayment,
    companyId,
    dispatch,
    includeExcludeBill,
    invite,
    purchaserOfferDisabledCounterPartyIDs,
    selectedRow,
    sendNotification,
    setPaidInvoiceModalVisible,
    setSelectedInvoiceId,
    setSelectedRow,
    setSelectedInvoice,
    supplierOfffers,
    setSupplierOfferToExecute,
    showPayNow,
  ]);

  const columns = [
    {
      title: <Header title="INVOICE #" hasDot columnKey="invoiceNumber" />,
      render: (record: TableRow) => (
        <InvoiceNumber
          id={record.key}
          number={record.invoiceNumber}
          includeInPurchaserOffer={record.includeInPurchaserOffer}
          ripeBillDocumentId={record.ripeBillDocumentId}
        />
      ),
      key: 'invoiceNumber',
      width: '15%',
    },
    {
      title: (
        <Header
          title="SUPPLIER"
          hasDot
          hasSort
          columnKey="issuedByCompanyName"
        />
      ),
      render: (record: TableRow) => (
        <CompanyName
          isPurchaserOffersDisabled={record.isPurchaserOffersDisabled}
          name={record.supplier}
          companyStatus={record.otherCompanyStatus}
          companyVerificationStatus={record.otherCompanyVerificationStatus}
        />
      ),
      key: 'supplier',
      width: '15%',
    },
    {
      title: <Header title="PAYMENT DATE" hasSort columnKey="dueDate" />,
      dataIndex: 'dueDate',
      key: 'dueDate',
      align: 'right' as AlignType,
      className: 'custom-sorted-column',
      width: '13%',
    },
    {
      title: <Header title="AMOUNT DUE" hasSort columnKey="discountedTotal" />,
      render: (record: TableRow) => (
        <Amount
          total={record.total}
          totalEligible={record.unformattedAmountDue}
          type="BILL"
          onClick={() => {
            setSelectedBillForDetails(record.key);
          }}
        />
      ),
      key: 'amountDue',
      align: 'right' as AlignType,
      className: 'custom-sorted-column',
      width: '11%',
    },
    {
      title: <Header title="STATUS" columnKey="status" />,
      render: (record: TableRow) => (
        <Status
          status={record.status}
          isNotEligible={record.isNotEligible}
          notEligibleReason={record.notEligibleReason}
        />
      ),
      key: 'status',
      align: 'center' as AlignType,
      width: '11%',
    },
    {
      title: <Header title="CASHBACK" columnKey="status" />,
      render: (record: TableRow) => (
        <CashBack
          amount={record.cashback}
          percentage={record.cashbackPercentage}
        />
      ),
      key: 'cashback',
      align: 'right' as AlignType,
      width: '10%',
      className: 'custom-spacing',
    },
    {
      title: (
        <MailOutlined
          style={{
            color: '#FFF',
            marginRight: 0,
            fontSize: 10,
            fontWeight: 'bold',
          }}
        />
      ),
      render: (record: TableRow) => (
        <Email
          email={record.email}
          status={record.otherCompanyStatus}
          onClick={() => {
            const invoice = bills.find(
              (bill: Invoice) => bill.id === record.key
            );
            setVisibleEditContactEmail(true);
            setSelectedInvoice(invoice);
          }}
        />
      ),
      key: 'email',
      align: 'center' as AlignType,
      width: '4%',
    },
    {
      title: <Header title="ACTION" columnKey="status" />,
      render: (record: TableRow) => (
        <Action onClick={record.action}>{record.actionLabel}</Action>
      ),
      key: 'action',
      align: 'center' as AlignType,
      width: '7%',
    },
    {
      title: '',
      key: 'space-element',
      width: '3%',
    },
  ];

  return {
    columns,
    rows,
    isLoading,
    isPending,
    isPaidInvoiceModalVisible,
    selectedInvoiceId,
    selectedInvoice,
    selectedRow,
    selectedBillForDetails,
    setPaidInvoiceModalVisible,
    setSelectedInvoiceId,
    setSelectedInvoice,
    setSelectedRow,
    setSelectedBillForDetails,
    setSupplierOfferToExecute,
    setVisibleEditContactEmail,
    supplierOfferToExecute,
    visibleEditContactEmail,
  };
};

export default useInvoiceTable;
