import React, { useState } from 'react';

import Form from './Form';
import Confirmation from './Confirmation';

const ForgotPassword = () => {
  const [resetStatus, setResetStatus] = useState(false);

  // callback function to sucessfull completion of Form.js
  const resetStatusHandler = () => {
    setResetStatus(true);
  };

  // if the Form.js returns a successful completion of email submission we need to show the confirmation Screen
  if (resetStatus) {
    return <Confirmation />; // This Confirmation Screen shows the details of the email to be recieved by the user
  } else {
    return <Form resetStatusHandler={resetStatusHandler} />;
  }
};

export default ForgotPassword;
