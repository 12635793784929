const processAmount = ({
  value,
  defaultValue,
}: {
  value: string;
  defaultValue?: string | null;
}) => {
  if (isNaN(Number(value))) {
    if (value === '.') {
      return '0.';
    } else {
      return defaultValue;
    }
  } else {
    return value.trim();
  }
};

export default processAmount;
