import React, { useEffect, useState } from 'react';
import AuthContainer from 'components/AuthContainer';
import AuthCard from 'components/AuthCard';
import InputItem from 'components/InputItem';
import Mfa from './Mfa';
import styled from 'styled-components';
import queryString from 'query-string';
import { Button, Input, Typography, message, notification } from 'antd';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageTracker from 'components/PageTracker';

import api from 'api';
import processError from 'utils/processError';
import validateEmail from 'utils/validateEmail';
import { login } from 'store/auth';
import { getCompanySettings } from 'store/settings';

const StyledButton = styled(Button)`
  margin-top: 13px;
  margin-bottom: 25px;
  width: 160px;
  align-self: center;
`;

const Error = styled(Typography.Text)`
  text-align: left;
`;

const API_ERROR_MFA = 'invalid mfa code';
const EMAIL_EMPTY_ERROR = 'Please enter your email address';
const EMAIL_INVALID_ERROR = 'Please enter a valid email address';
const PASSWORD_ERROR = 'Please enter your password';
const MFA_EMPTY_ERROR = 'Please enter the code shown on your authenticator app';
const MFA_ERROR = 'Invalid code';

const Login = ({ history, location, login, getCompanySettings }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [mfaError, setMfaError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [showMfaInput, setMfaVisibility] = useState(false);

  useEffect(() => {
    const { state } = location;
    if (state && state.autoLoggedOut) {
      message.error('Your session has expired. Please login again.');
    }
  }, [location]);

  const finalise = async ({ isAdmin, user, token }) => {
    const { companyId } = user;
    let company = {};
    let settings = {};
    if (companyId) {
      try {
        company = await api.company.get({ token, companyId });
      } catch (e) {
        return;
      }
      try {
        settings = await api.company.settings.get({ token, companyId });
      } catch (error) {
        return;
      }
    }
    setLoading(false);
    login({ user: { ...user, token, company }, loggedIn: true });
    getCompanySettings(settings);
    const queryParams = queryString.parse(location.search);
    const { next } = queryParams;
    // A value will be available in next if a logged out user was trying to access a protected route
    if (next) {
      let url = next;
      if (Object.values(queryParams).length > 0) {
        delete queryParams.next;
        const extraParams = Object.keys(queryParams).reduce((prev, key) => {
          return `${prev}&${key}=${queryParams[key]}`;
        }, '');
        url += extraParams;
      }
      history.push(url);
    } else {
      history.push(isAdmin ? '/' : '/invoices');
    }
  };

  const onLoginPress = () => {
    let hasErrors = false;
    if (!email) {
      hasErrors = true;
      setEmailError(EMAIL_EMPTY_ERROR);
    } else if (!validateEmail(email)) {
      hasErrors = true;
      setEmailError(EMAIL_INVALID_ERROR);
    }
    if (!password) {
      hasErrors = true;
      setPasswordError(PASSWORD_ERROR);
    }
    if (showMfaInput && !mfaCode) {
      hasErrors = true;
      setMfaError(MFA_EMPTY_ERROR);
    }
    if (hasErrors) {
      return;
    }
    setLoading(true);
    const user = {
      username: email,
      password,
      mfaCode,
    };
    api.auth
      .login(user)
      .then(async (data) => {
        const token = data.accessToken;

        let user;
        let userState;
        try {
          user = await api.user.get(token);
          const isAdmin =
            user.roles && user.roles[0] === 'ROLE_ADMIN' ? true : false;
          if (isAdmin) {
            setLoading(true);
            finalise({ isAdmin, user, history, login, token, setLoading });
            return;
          } else {
            const res = await api.user.getState({ token, userId: user.id });
            userState = res.state;
          }
        } catch (e) {
          setLoading(false);
          return;
        }
        switch (userState) {
          case 'SIGNED_UP':
            history.replace(`/activate?set_name=true&token=${token}`);
            break;
          case 'EMAIL_VERIFIED':
            history.replace(`/activate?set_name=true&token=${token}`);
            break;
          case 'PASSWORD_UPDATED':
            history.replace(`/activate?set_phone_number=true&token=${token}`);
            break;
          case 'PHONE_NUMBER_VERIFIED':
            if (user.verificationStatus === 'VERIFICATION_FAILED') {
              history.replace(
                `/verify?step=user_details&upload_document=true&token=${token}`
              );
            } else {
              history.replace(`/verify?step=user_details&token=${token}`);
            }
            break;
          case 'OWNER_VERIFIED':
            history.replace(`/verify?step=business_details&token=${token}`);
            break;
          case 'BUSINESS_DETAILS_CONFIRMED':
            setLoading(true);
            finalise({ user, history, login, token, setLoading });
            break;
          case 'ONBOARDED':
            setLoading(true);
            finalise({ user, history, login, token, setLoading });
            break;
          default:
            setLoading(true);
            finalise({ user, history, login, token, setLoading });
        }
      })
      .catch((error) => {
        const { status, message, debugMessage } = processError(error);
        if (debugMessage === API_ERROR_MFA) {
          setMfaVisibility(true);
          showMfaInput && setMfaError(MFA_ERROR);
        } else if (status === 401) {
          setLoginError(true);
        } else {
          notification.error({
            message: 'Error',
            description: message,
          });
        }
        setLoading(false);
      });
  };

  const onKeyPress = (e) => {
    if (showMfaInput && e.key === 'Enter') {
      if (mfaCode) {
        onLoginPress();
      }
    } else if (email && password && e.key === 'Enter') {
      onLoginPress();
    }
  };

  return (
    <AuthContainer>
      <Helmet>
        <title>Login | Relay</title>
      </Helmet>
      <AuthCard
        message={showMfaInput ? 'Multi-Factor Authentication' : 'Welcome back'}
        isLogin
        visibleIcon
        isMfa={showMfaInput}
      >
        <PageTracker />
        {showMfaInput ? (
          <Mfa
            mfaCode={mfaCode}
            setMfaCode={setMfaCode}
            mfaError={mfaError}
            setMfaError={setMfaError}
            onKeyPress={onKeyPress}
          />
        ) : (
          <>
            <InputItem label="Email" error={emailError}>
              <Input
                name="email"
                label="Email address"
                type="email"
                placeholder="Your email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                onKeyPress={onKeyPress}
                autoFocus
                size="large"
                data-testid="email-input"
              />
            </InputItem>
            <InputItem label="Password" error={passwordError}>
              <Input.Password
                placeholder="Your password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
                onKeyPress={onKeyPress}
                size="large"
                data-testid="password-input"
              />
            </InputItem>
            {!!loginError && (
              <Error type="danger">
                Error logging in. Check your credentials.
              </Error>
            )}
          </>
        )}

        <StyledButton
          type="primary"
          onClick={onLoginPress}
          size="large"
          loading={isLoading}
          data-testid="login-button"
        >
          {showMfaInput ? 'Continue' : 'Log In'}
        </StyledButton>
      </AuthCard>
    </AuthContainer>
  );
};

export default connect(null, { login, getCompanySettings })(Login);
