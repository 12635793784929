import React from 'react';
import InputItem from 'components/InputItem';
import styled from 'styled-components';

const StyledInputItem = styled(InputItem)`
  width: 100%;
`;

type Props = {
  error?: string;
  label?: string;
};

const InputContainer: React.FC<Props> = ({ children, error, label }) => (
  <StyledInputItem error={error} label={label}>
    {children}
  </StyledInputItem>
);

export default InputContainer;
