import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Details from './Details';
import PageTracker from 'components/PageTracker';

import { setToolbarTitle } from 'store/ui';

class Topups extends Component {
  componentDidMount() {
    const { setToolbarTitle } = this.props;
    setToolbarTitle('Topups');
  }

  render() {
    return (
      <div style={{ paddingTop: 20 }}>
        <Helmet>
          <title>Topups | Relay</title>
        </Helmet>
        <PageTracker />
        <Details />
      </div>
    );
  }
}

export default connect(null, { setToolbarTitle })(Topups);
