import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, notification } from 'antd';
import api from 'api';
import { Preferences } from 'types/Company';
import { processError } from 'utils';
import spacing from 'styles/layout/spacing';
import { LoadingOutlined } from '@ant-design/icons';
import { ProcessingQueue } from 'types/ProcessingQueue';
import { DefaultProcessingQueueFormItem } from 'components';

export const DefaultProcessingQueue: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [defaultQueue, setDefaultQueue] = useState<
    Preferences['defaultProcessingQueue']
  >(ProcessingQueue.Manual);
  const [preference, setPreference] = useState<Preferences | null>(null);

  const entityId = useParams<{ id: string }>().id;

  useEffect(() => {
    setLoading(true);
    api.admin.entities
      .getEntityPreference({ entityId })
      .then((data) => {
        setPreference(data);
        setDefaultQueue(data.defaultProcessingQueue);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [entityId]);

  const saveNotification = () => {
    const message = 'Default Processing Queue ' + defaultQueue;
    notification.success({ message });
  };

  const handleSave = () => {
    if (!preference) {
      return;
    } else if (defaultQueue === preference.defaultProcessingQueue) {
      saveNotification();
      setEditing(false);
      return;
    }
    setSaving(true);
    const payload = {
      id: preference.id,
      modificationTime: preference.modificationTime,
      value: defaultQueue,
      path: '/defaultProcessingQueue',
      operation: 'replace',
    };
    api.admin.entities
      .patchEntityPreference({ entityId, payload })
      .then((data) => {
        saveNotification();
        setPreference(data);
      })
      .catch((error) => {
        setDefaultQueue(preference.defaultProcessingQueue);
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => {
        setEditing(false);
        setSaving(false);
      });
  };

  if (isLoading || !preference) {
    return (
      <Form.Item label="Default Processing Queue">
        <LoadingOutlined />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item label="Default Processing Queue">
        {isEditing ? (
          <DefaultProcessingQueueFormItem
            defaultValue={preference.defaultProcessingQueue}
            onChange={(value) => setDefaultQueue(value)}
            style={{ width: '150px' }}
          />
        ) : (
          defaultQueue?.charAt(0) + defaultQueue?.slice(1).toLowerCase()
        )}
        <Button
          onClick={() => {
            isEditing ? handleSave() : setEditing(true);
          }}
          loading={isSaving}
          style={{ marginLeft: spacing.gutter.sm }}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Form.Item>
    );
  }
};
