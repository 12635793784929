import React from 'react';

import styled from 'styled-components';

import Button from 'components/Button';
import { xeroLogo } from 'assets/images';
import { colors } from 'themes';
import BetaBadge from 'components/BetaBadge';

const ConnectionStatus = styled.p`
  width: 250px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
`;
const Message = styled.p`
  ${'' /* width: 265px; */}
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.greys900};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -10px;
`;
const Icon = styled.img`
  width: 150px;
  height: 150px;
  margin-top: -30px;
  ${'' /* margin-left: 20px; */}
`;

const Xero = ({ company, loading, handleClick }) => {
  return (
    <div>
      <Row>
        <div>
          <ConnectionStatus>
            {company && company.connectedToXero
              ? 'Connected to Xero'
              : 'Not connected to Xero'}
          </ConnectionStatus>
          <Message>
            {company && company.connectedToXero
              ? 'Your Xero account is currently connected to Relay'
              : 'Connect your Xero account to get started.'}
          </Message>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
            //marginTop: 20
          }}
        >
          <BetaBadge />
          <Icon src={xeroLogo} />
        </div>
      </Row>
      <Button
        label={
          company && company.connectedToXero ? 'Disconnect' : 'Connect to Xero'
        }
        loading={loading}
        onClick={() => handleClick()}
      />
    </div>
  );
};

export default Xero;
