import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, ButtonProps } from 'antd';

const StyledButton = styled(AntButton)`
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  height: 24px;
  border: 1px solid #848b94;
  span {
    font-weight: 700;
    font-size: 10px;
  }
`;

const BulkActionButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

export default BulkActionButton;
