import React from 'react';
import colors from 'colors';
import Company from 'types/Company';
import { Tooltip } from 'antd';
import { StatusDot } from 'ds';

const CompanyStatusDot = ({
  companyStatus,
  companyVerificationStatus,
  isPurchaserOffersDisabled,
}: {
  companyStatus?: Company['status'];
  companyVerificationStatus?: Company['verificationStatus'];
  isPurchaserOffersDisabled?: boolean;
}) => {
  let color = colors.greys200;
  let tooltipTitle = '';

  if (isPurchaserOffersDisabled) {
    color = colors.warms.red;
    tooltipTitle = 'Offers disabled';
  } else if (
    companyVerificationStatus === 'VERIFIED' ||
    companyVerificationStatus === 'PARTIALLY_VERIFIED'
  ) {
    color = colors.blues.light;
    tooltipTitle = 'Member';
  } else if (companyStatus === 'INVITED') {
    color = colors.orange;
    tooltipTitle = 'Invited';
  }

  return (
    <Tooltip color={color} title={tooltipTitle}>
      <StatusDot color={color} />
    </Tooltip>
  );
};

export default CompanyStatusDot;
