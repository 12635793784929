import api from 'api';
import EntityPreferences from 'types/EntityPreferences';
import { Dispatch } from 'redux';
import { processError } from 'utils';

const SET_ENTITY_PREFERENCES =
  'relay/entity-preferneces/SET_ENTITY_PREFERENCES';
const SET_LOADING = 'relay/entity-preferneces/SET_LOADING';
const SET_UPDATING = 'relay/entity-preferneces/SET_UPDATING';
const SET_ERROR = 'relay/entity-preferneces/SET_ERROR';
const LOGOUT = 'LOGOUT';

export type State = {
  preferences: EntityPreferences | null;
  isLoading: boolean;
  isUpdating: boolean;
  error: string | null;
};

export const initialState: State = {
  preferences: null,
  isLoading: false,
  isUpdating: false,
  error: null,
};

type SetPreferencesAction = {
  type: typeof SET_ENTITY_PREFERENCES;
  payload: EntityPreferences;
};

type SetLoadingAction = {
  type: typeof SET_LOADING;
  payload: boolean;
};

type SetUpdatingAction = {
  type: typeof SET_UPDATING;
  payload: boolean;
};

type SetErrorAction = {
  type: typeof SET_ERROR;
  payload: string | null;
};

type LogoutAction = {
  type: typeof LOGOUT;
};

type Actions =
  | SetPreferencesAction
  | SetLoadingAction
  | SetUpdatingAction
  | SetErrorAction
  | LogoutAction;

export default (state = initialState, action: Actions): State => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case SET_ENTITY_PREFERENCES:
      return { ...state, preferences: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_UPDATING:
      return { ...state, isUpdating: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const getPreferences = ({ entityId }: { entityId: string }) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: SET_ERROR, payload: false });
    api.entityPreferences
      .getEntityPreferences({ entityId })
      .then((data) => dispatch({ type: SET_ENTITY_PREFERENCES, payload: data }))
      .catch((error) => {
        const { message } = processError(error);
        dispatch({ type: SET_ERROR, payload: message });
      })
      .finally(() => dispatch({ type: SET_LOADING, payload: false }));
  };
};

export const updatePreferences = ({
  entityId,
  payload,
}: {
  entityId: string;
  payload: EntityPreferences;
}) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_UPDATING, payload: true });
    dispatch({ type: SET_ERROR, payload: false });
    api.entityPreferences
      .updateEntityPreferences({ entityId, payload })
      .then((data) => dispatch({ type: SET_ENTITY_PREFERENCES, payload: data }))
      .catch((error) => {
        const { message } = processError(error);
        dispatch({ type: SET_ERROR, payload: message });
      })
      .finally(() => dispatch({ type: SET_UPDATING, payload: false }));
  };
};

export const updatePreferencesState = (payload: EntityPreferences) => ({
  type: SET_ENTITY_PREFERENCES,
  payload,
});
