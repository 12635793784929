import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid #DDD;
`;

const Ruler = () => (
  <div style = {{ paddingRight: 10, paddingLeft: 10 }}>
    <Line/>
  </div>
)

export default Ruler;
