import SplitNameResults from 'types/NameProps';

const splitName = (name?: string): SplitNameResults => {
  let firstName = '';
  let lastName = '';
  let middleName = '';

  if (!name) {
    return { firstName, middleName, lastName };
  }

  const components = name.split(' ');
  const componentsCount = components.length;
  components.forEach((item, i) => {
    if (i === 0) {
      firstName = item;
    }
    if (i > 0 && i < componentsCount - 1) {
      if (middleName) {
        middleName = `${middleName} ${item}`;
      } else {
        middleName = `${item}`;
      }
    }
    if (i > 0 && i === componentsCount - 1) {
      lastName = item;
    }
  });
  return { firstName, middleName, lastName };
};

export default splitName;
