import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import AuthCard from 'components/AuthCard';
import PageTracker from 'components/PageTracker';

const Text = styled.span`
  font-size: 16px;
  text-align: center;
  margin-bottom: -20px;
`;

const Email = styled.span`
  color: #000;
`;

const SignupEntry = ({ location }) => {
  const { email } = queryString.parse(location.search);
  return (
    <AuthCard message="Welcome to Relay" showCheckIcon>
      <PageTracker />
      <Text>
        We sent an email to <Email>{email}</Email>. Follow the instructions in
        the email to activate your account.
      </Text>
    </AuthCard>
  );
};
export default withRouter(SignupEntry);
