import { useCallback, useEffect, useRef } from 'react';
import {
  filterBills,
  filterInvoices,
  filterPayableSummaries,
} from 'store/invoices';
import { setDataToRefresh, setRefreshData } from 'store/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTimestamp, getTodayTimestamp } from 'utils';
import moment from 'moment';

type BuildApiUrlParams = {
  amount?: string | null;
  date?: string | null;
  inTheNext?: string | null;
  invoiceNumber?: string | null;
  matchesXero?: string | null;
  status?: string | null;
  entity?: string | null;
  page: number;
  size?: string | null;
  type: 'BILL' | 'INVOICE';
  sort?: string;
  supplier?: string | null;
  fromTime?: number;
  toTime?: number;
};

const buildApiUrl = (
  {
    amount,
    date,
    inTheNext,
    invoiceNumber,
    status,
    entity,
    page,
    size,
    type,
    sort,
    matchesXero,
    supplier,
    fromTime,
    toTime,
  }: BuildApiUrlParams,
  isSupplierBills: boolean
): string => {
  const params = new URLSearchParams();
  if (entity) {
    const key =
      type === 'BILL' ? 'issuedByCompanyName' : 'issuedForCompanyName';
    params.set(key, entity);
  }

  if (sort) {
    params.set('sort', sort);
  }

  params.set('page', String(page));

  params.set('size', size || '25');

  params.set('type', type);

  if (isSupplierBills) {
    if (matchesXero) {
      params.set('isMatchedPayablesValue', String(matchesXero));
    }

    if (supplier) {
      params.set('supplierName', String(supplier));

      // Show suppliers with no activity if filter by supplier name is active
      params.set('hasCreditOrAmountOwing', 'false');
    } else {
      // Hide suppliers with no activity by default
      params.set('hasCreditOrAmountOwing', 'true');
    }

    if (fromTime) {
      params.set('fromTime', String(fromTime));
    }

    if (toTime) {
      params.set('toTime', String(toTime));
    }

    if (status && status !== 'ALL_ELIGIBLE') {
      params.set('status', status);
    }
  } else {
    if (amount) {
      params.set('discountedTotal', String(amount));
    }

    if (invoiceNumber) {
      const [number, operator] = invoiceNumber.split(':');
      if (operator === 'in') {
        params.set('invoiceNumbers', number);
      } else if (operator === 'eq') {
        params.set('invoiceNumber', number);
      } else {
        params.set('invoiceNumber', `${number}:${operator}`);
      }
    }

    if (inTheNext) {
      const [number, operator] = inTheNext.split(':');
      const startTimeStamp = getTodayTimestamp();
      if ((operator === 'days' || operator === 'months') && number) {
        const end = moment().add(number, operator);
        const endTimeStamp = getTimestamp(end);
        params.set('dueDate', `${startTimeStamp}-${endTimeStamp}:range`);
      }
    } else if (date) {
      const [datePart, operator] = date.split(':');
      if (operator) {
        if (operator === 'eq') {
          params.set('dueDate', String(getTimestamp(datePart, 'DDMMYYYY')));
        } else if (operator === 'range') {
          const [startDate, endDate] = datePart.split('-');
          if (startDate && endDate) {
            const startTimeStamp = getTimestamp(startDate, 'DDMMYYYY');
            const endTimeStamp = getTimestamp(endDate, 'DDMMYYYY');
            params.set(
              'dueDate',
              `${startTimeStamp}-${endTimeStamp}:${operator}`
            );
          }
        } else {
          params.set(
            'dueDate',
            `${String(getTimestamp(datePart, 'DDMMYYYY'))}:${operator}`
          );
        }
      } else {
        params.set('dueDate', String(getTimestamp(date, 'DDMMYYYY')));
      }
    }

    if (status) {
      params.set('status', status);
    } else {
      params.set('status', 'ALL_ELIGIBLE');
    }

    params.set('loadedBy', 'RELAY,SYSTEM,RIPE');
  }

  return `?${params}`;
};

const useInvoiceFilters = (isSupplierBills: boolean = false) => {
  const refreshData: boolean = useSelector(
    (store: any) => store.ui.refreshData
  );

  const abortControllerRef = useRef<AbortController>();

  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, search } = location;

  const locationParams = new URLSearchParams(search);
  const amount = locationParams.get('amount');
  const dateDue = locationParams.get('date');
  const inTheNext = locationParams.get('in');
  const invoiceNumber = locationParams.get('number');
  const status = locationParams.get('status');
  const entity = locationParams.get('entity');
  const page = locationParams.get('page');
  const size = locationParams.get('size');
  const sort = locationParams.get('sort') || 'dueDate,asc';
  const supplier = locationParams.get('supplier');
  const matchesXero = locationParams.get('isMatchedPayablesValue');
  const fromTime = moment().startOf('month').valueOf();
  const toTime = moment().endOf('month').valueOf();

  const getData = useCallback(() => {
    abortControllerRef?.current?.abort();
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const apiUrl = buildApiUrl(
      {
        amount,
        invoiceNumber,
        date: dateDue,
        inTheNext,
        status,
        entity,
        page: page ? Number(page) - 1 : 0,
        size,
        type: pathname === '/bills' ? 'BILL' : 'INVOICE',
        sort,
        supplier,
        matchesXero,
        fromTime,
        toTime,
      },
      isSupplierBills
    );
    if (isSupplierBills) {
      dispatch(filterPayableSummaries({ abortController, url: apiUrl }));
    } else if (pathname === '/bills') {
      dispatch(filterBills({ abortController, url: apiUrl }));
    } else if (pathname === '/invoices') {
      dispatch(filterInvoices({ abortController, url: apiUrl }));
    }
  }, [
    dispatch,
    isSupplierBills,
    amount,
    inTheNext,
    invoiceNumber,
    status,
    entity,
    page,
    size,
    pathname,
    dateDue,
    sort,
    matchesXero,
    supplier,
    fromTime,
    toTime,
  ]);

  useEffect(() => {
    getData();
    setDataToRefresh('INVOICE');
  }, [getData]);

  useEffect(() => {
    if (refreshData) {
      getData();
      dispatch(setRefreshData(false));
    }
  }, [dispatch, getData, refreshData]);

  const filters = {
    amount,
    dateDue,
    entity,
    invoiceNumber,
    inTheNext,
    matchesXero,
    isSupplierBills,
  };

  return filters;
};

export default useInvoiceFilters;
