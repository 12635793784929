import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Modal } from 'ds';
import BankTransferDetails from 'components/BankTransferDetails';
import TopupEntry from './TopupEntry';
import TransferDetails from './TransferDetails';
import { hideTopUpWindow, updateCompanyDetails, getCompany } from 'store/auth';
import { setRefreshData } from 'store/ui';
import {
  getSupplierOffersAvailable,
  getPurchaserOffersOffered,
  updatePurchaserOfferOffered,
  updateSupplierOfferOffered,
} from 'store/offers';
import { getTransactions, bootstrapTransactions } from 'store/transactions';
import { getAnalytics } from 'store/analytics';

import api from 'api';
import processError from 'utils/processError';
import { notification } from 'antd';

const TopupModal = (props) => {
  const selectAmountOption = (label) => {
    const update = amountOptions.map((op) => {
      if (op.id === label) {
        return { ...op, isSelected: true };
      } else {
        return { ...op, isSelected: false };
      }
    });
    setAmountOptions(update);
  };

  const { trigger, triggerAmount } = props;

  const fundsAvailable =
    props.user.company.company && props.user.company.company.fundsAvailable;
  const minimumAmount = triggerAmount && triggerAmount - fundsAvailable;

  let options = [
    {
      id: trigger === 'SUPPLIER_OFFER' ? 'Invoice Amount' : 'Minimum Amount',
      amount: minimumAmount,
      isSelected: false,
    },
    {
      id:
        trigger === 'PURCHASER_OFFER' || trigger === 'PURCHASER_OFFER_EDIT'
          ? 'Cashpool Amount'
          : trigger === 'NO_OFFER'
          ? 'Topup Amount'
          : 'Total Amount Due',
      amount: triggerAmount,
      isSelected: false,
      last: true,
    },
    {
      id: trigger === 'SUPPLIER_OFFER' ? 'Other Amount' : 'Amount',
      amount: null,
      custom: true,
      last: true,
      isSelected: false,
    },
  ];

  const [amountOptions, setAmountOptions] = useState(options);
  const [currentState, updateCurrentState] = useState('Topup Entry');
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateCustomInput = (value) => {
    const customOption = amountOptions.find(
      (amountOption) => amountOption.custom
    );
    if (customOption) {
      let newAmount = value.trim();
      if (isNaN(newAmount)) {
        if (newAmount === '.') {
          newAmount = '0.';
        } else {
          newAmount = customOption.amount;
        }
      }

      const updatedAmountOptions = amountOptions.map((amountOption) => {
        if (amountOption.isSelected) {
          return { ...amountOption, amount: newAmount };
        } else {
          return amountOption;
        }
      });
      setAmountOptions(updatedAmountOptions);
    }
  };

  const handleError = (message, isInfo) => {
    if (isInfo) {
      notification.info({ message });
    } else if (message) {
      notification.error({ message });
    } else {
      notification.error({
        message:
          'Something went wrong. Please try again or contact Relay if the issue persists.',
      });
    }
  };

  if (triggerAmount && minimumAmount) {
    if (String(minimumAmount) === String(triggerAmount)) {
      const filtered = options.filter(
        (i) =>
          i.id !==
          (trigger === 'PURCHASER_OFFER' || trigger === 'PURCHASER_OFFER_EDIT'
            ? 'Cashpool Amount'
            : trigger === 'NO_OFFER'
            ? 'Topup Amount'
            : 'Total Amount Due')
      );
      options = filtered.map((item) => {
        return { ...item, last: true };
      });
    }
  }

  if (trigger === 'CUSTOM') {
    options = [options[2]];
  }

  useEffect(() => {
    setAmountOptions(options); // eslint-disable-next-line
  }, []);

  const selectedOption = amountOptions.find((op) => op.isSelected === true);

  const hideModal = () => {
    if (
      modalStates[currentState].title === 'Topup Account' ||
      modalStates[currentState].title === 'Topup Details'
    ) {
      (trigger === 'PURCHASER_OFFER' || trigger === 'PURCHASER_OFFER_EDIT') &&
        handleError('Topup cancelled. Offer not created.', true);
      props.hideTopUpWindow();
    } else {
      props.hideTopUpWindow();
    }
  };

  const modalStates = {
    'Topup Entry': {
      component: TopupEntry,
      props: {
        fundsAvailable,
        triggerAmount,
        amountOptions,
        trigger,
        selectAmountOption,
        updateCustomInput,
        errorMessage,
      },
      actions: [
        {
          label: 'Cancel',
          secondary: true,
          do: hideModal,
        },
        {
          label: 'OK',
          do: () => {
            selectedOption && selectedOption.amount > 0
              ? updateCurrentState('Topup Details')
              : setErrorMessage(true);
          },
        },
      ],
      title: 'Topup Account',
    },
    'Topup Details': {
      component: BankTransferDetails,
      props: {
        amount: selectedOption?.amount,
        showMessage: trigger === 'SUPPLIER_OFFER',
      },
      actions: [
        {
          label: trigger === 'CUSTOM' ? 'Cancel' : 'I will pay later',
          secondary: true,
          do: hideModal,
        },
        {
          label: 'Done',
          do: () => {
            !loading && doPayment(selectedOption);
          },
        },
      ],
      title: 'Topup Details',
    },
    'Bank Transfer': {
      component: TransferDetails,
      actions: [
        {
          label: 'Done',
          do: () => {
            props.hideTopUpWindow();
          },
        },
      ],
      title: 'Bank Transfer',
    },
  };

  const doPayment = (selectedOption) => {
    const { bootstrapTransactions, bills, triggerAmount, Crr, pageSize } =
      props;
    if (selectedOption) {
      setLoading(true);
      const fundsAvailable =
        props.user.company.company && props.user.company.company.fundsAvailable;
      const companyDetails = {
        ...props.user.company.company,
        fundsAvailable:
          parseFloat(fundsAvailable) + parseFloat(selectedOption.amount),
      };
      if (trigger === 'PURCHASER_OFFER') {
        const { id } = companyDetails;
        const fundsAvailable = parseFloat(selectedOption.amount);
        api.admin.topups
          .createTopup({ fundsAvailable, id })
          .then(() => {
            updateCurrentState('Bank Transfer');
            // create purchaser offer
            let invoiceIds = [];
            bills.length &&
              bills.forEach((bill) => {
                if (bill.invoice.includeInPurchaserOffer) {
                  invoiceIds.push(bill.invoice.id);
                }
              });

            // const expiresIn = moment()
            //   .add(1, 'Y')
            //   .format('YYYY-MM-DD');
            // const expirationTime = moment(expiresIn).valueOf();
            const params = {
              createdByCompany: props.user.company.company.id,
              cashpool: String(triggerAmount),
              crr: String(Crr),
              //expirationTime,
              invoiceIds,
            };
            api.purchaserOffers
              .post(params)
              .then((res) => {
                props.updatePurchaserOfferOffered(res);
                setLoading(false);
              })
              .catch((err) => {
                if (err.response.data.errors) {
                  err.response.data.errors.map((item) => handleError(item));
                } else {
                  handleError();
                }
                setLoading(false);
              });
            bootstrapTransactions({ size: pageSize });
          })
          .catch((error) => {
            const { message } = processError(error);
            handleError(message);
            setLoading(false);
          });
      } else if (trigger === 'PURCHASER_OFFER_EDIT') {
        const fundsAvailable = parseFloat(selectedOption.amount);
        api.admin.topups
          .createTopup({ fundsAvailable, id: companyDetails.id })
          .then(() => {
            updateCurrentState('Bank Transfer');

            // update purchaser offer
            let invoiceIds = [];
            props.bills.length &&
              props.bills.forEach((bill) => {
                if (bill.invoice.includeInPurchaserOffer) {
                  invoiceIds.push(bill.invoice.id);
                }
              });
            const purchaserOffersOffered = props.purchaserOffers.filter(
              (offer) =>
                offer.purchaserOffer.offerStatus === 'ACTIVE' ||
                offer.purchaserOffer.offerStatus === 'TOPUP_PENDING'
            );
            const params = {
              ...purchaserOffersOffered[0].purchaserOffer,
              cashpool: String(
                triggerAmount +
                  purchaserOffersOffered[0].purchaserOffer.cashpool
              ),
              crr: String(Crr),
              invoiceIds,
            };
            const { id } = purchaserOffersOffered[0].purchaserOffer;
            api.purchaserOffers
              .edit({ params, uriParam: id })
              .then((res) => {
                props.updatePurchaserOfferOffered(res);
                setLoading(false);
                props.getCompany(companyDetails.id);
              })
              .catch((err) => {
                if (err.response.data.errors) {
                  err.response.data.errors.map((item) => handleError(item));
                } else {
                  handleError();
                }
              });

            setLoading(false);
            bootstrapTransactions({ size: pageSize });
          })
          .catch((err) => {
            if (err.response.data.errors) {
              err.response.data.errors.map((item) => handleError(item));
            } else {
              handleError();
            }
            setLoading(false);
          });
      } else if (trigger === 'CUSTOM') {
        const { id } = companyDetails;
        const fundsAvailable = parseFloat(selectedOption.amount);
        api.admin.topups
          .createTopup({ fundsAvailable, id })
          .then(() => {
            updateCurrentState('Bank Transfer');
            setLoading(false);
            bootstrapTransactions({ size: pageSize });
          })
          .catch((err) => {
            if (err.response.data.errors) {
              err.response.data.errors.map((item) => handleError(item));
            } else {
              handleError();
            }
            setLoading(false);
          });
      } else if (trigger === 'NO_OFFER') {
        const { id } = companyDetails;
        const fundsAvailable = parseFloat(selectedOption.amount);
        api.admin.topups
          .createTopup({ fundsAvailable, id })
          .then(() => {
            updateCurrentState('Bank Transfer');
            setLoading(false);
            bootstrapTransactions({ size: pageSize });
          })
          .catch((err) => {
            if (err.response.data.errors) {
              err.response.data.errors.map((item) => handleError(item));
            } else {
              handleError();
            }
            setLoading(false);
          });
      } else {
        const { id } = companyDetails;
        const fundsAvailable = parseFloat(selectedOption.amount);
        api.admin.topups
          .createTopup({ fundsAvailable, id })
          .then(() => {
            acceptSupplierOffer();
            bootstrapTransactions({ size: pageSize });
            updateCurrentState('Bank Transfer');
          })
          .catch((error) => {
            const { message } = processError(error);
            handleError(message);
            handleError('Error creating topup. Offer not accepted.');
            setLoading(false);
          });
      }
    }
  };

  const acceptSupplierOffer = () => {
    const supplierOffers = props.supplierOffersAvailable.filter(
      (offer) =>
        offer.offerStatus !== 'EXPIRED' &&
        offer.invoiceIds[0] &&
        offer.invoiceIds[0] === props.selectedInvoiceId
    );
    if (!supplierOffers.length) {
      handleError('Cannot pay now!');
      return;
    }
    const params = {
      invoiceIds: supplierOffers[0].invoiceIds,
      state: 'EXECUTE',
    };
    const uriParam = supplierOffers[0].id;
    api.supplierOffers
      .execute({ params, uriParam })
      .then((res) => {
        if (res.status === 'FAILED') {
          if (res.invoicesExecutionResult[0]?.status !== 'PENDING') {
            handleError(res.invoicesExecutionResult[0]?.reason);
          }
        }
        if (props.dataToRefresh !== 'INVOICE') {
          props.setRefreshData(true);
        }
        props.getSupplierOffersAvailable();
        props.getAnalytics('bill');
        setLoading(false);
      })
      .catch((err) => {
        const { message, errors } = processError(err);
        if (errors) {
          errors.map((item) => handleError(item));
        } else {
          handleError(message);
        }
        setLoading(false);
      });
  };

  const actions = modalStates[currentState].actions;
  const secondaryAction = actions.find((action) => action.secondary);
  const primaryAction = actions.find((action) => !action.secondary);
  const ComponentToRender = modalStates[currentState].component;
  return (
    <Modal
      okText={primaryAction.label}
      okButtonProps={{ loading }}
      onOk={primaryAction.do}
      cancelText={secondaryAction?.label}
      onCancel={secondaryAction?.do}
      cancelButtonProps={{
        style: { display: secondaryAction ? 'block' : 'none' },
      }}
      title={modalStates[currentState].title}
      errorMessage={errorMessage}
      visible={props.isVisibleTopUpModal}
    >
      <ComponentToRender {...modalStates[currentState].props} />
    </Modal>
  );
};

const mapStateToProps = ({ auth, offers, invoices, transactions, ui }) => ({
  isVisibleTopUpModal: auth.isVisibleTopUpModal,
  user: auth.user,
  bills: invoices.bills,
  selectedInvoice: auth.selectedInvoice && auth.selectedInvoice,
  supplierOffersAvailable: offers.supplierOffers.available,
  purchaserOffers: offers.purchaserOffers.offered,
  trigger: auth.trigger,
  triggerAmount: auth.triggerAmount,
  Crr: auth.Crr,
  selectedInvoiceId: auth.selectedInvoice && auth.selectedInvoice.id,
  pageSize: transactions.pageSize,
  pageNumber: transactions.pageNumber,
  dataToRefresh: ui.dataToRefresh,
});

const mapDispatchToProps = {
  hideTopUpWindow,
  getSupplierOffersAvailable,
  updateCompanyDetails,
  getPurchaserOffersOffered,
  updateSupplierOfferOffered,
  updatePurchaserOfferOffered,
  getTransactions,
  bootstrapTransactions,
  getCompany,
  getAnalytics,
  setRefreshData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopupModal);
