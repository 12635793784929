import axios from 'axios';

const URL = '/supplier-offers';

const get = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${URL}?page=0&size=1000`)
      .then(({ data }) =>
        resolve(
          data.supplierOfferResources.map((resource) => resource.supplierOffer)
        )
      )
      .catch((error) => reject(error));
  });
};

const getAvailable = ({ notified, token }) => {
  return new Promise(function (resolve, reject) {
    const urlFragment = notified ? 'notified' : 'available';
    let url = `${URL}/${urlFragment}?page=0&size=1000`;
    if (notified && token) {
      url = `${url}&token=${token}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) =>
        resolve(
          data.supplierOfferResources.map((resource) => resource.supplierOffer)
        )
      )
      .catch((error) => reject(error));
  });
};

const post = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(URL, params)
      .then(({ data }) => resolve(data.supplierOffer))
      .catch((error) => reject(error));
  });
};

const edit = ({ params, uriParam }) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`${URL}/${uriParam}`, params)
      .then(({ data }) => resolve(data.supplierOffer))
      .catch((error) => reject(error));
  });
};

const cancel = ({ uriParam }) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`${URL}/${uriParam}/cancel`)
      .then(({ data }) => resolve(data.supplierOffer))
      .catch((error) => reject(error));
  });
};

const executedOffers = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${URL}/executed?page=0&size=1000`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const execute = ({ params, uriParam }) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}/${uriParam}/execute`, params)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

export default {
  get,
  getAvailable,
  post,
  edit,
  cancel,
  execute,
  executedOffers,
};
