import React from 'react';
import styled from 'styled-components';

import colors from 'colors';

const Container = styled.span`
  color: ${colors.greys600};
  font-size: 14px;
`;

type Props = { children: React.ReactNode; className?: string };

const RowLabel: React.FC<Props> = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

export default RowLabel;
