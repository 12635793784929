import React from 'react';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';

import { colors } from 'themes';

const StyledIcon = styled(SearchOutlined)`
  color: ${colors.greys700};
`;

const Icon = () => <StyledIcon />;

export default Icon;
