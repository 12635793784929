import React, { Key, useState } from 'react';
import styled from 'styled-components';
import DataRow from './DataRow';
import Invoice from 'types/Invoice';
import PurchaserOffer from 'types/PurchaserOffer';
import spacing from 'styles/layout/spacing';
import { InvoiceWithServiceFee } from './InvoiceWithServiceFee';
import PaymentTable from './PaymentTable';

const DetailsWrapper = styled.div`
  margin-bottom: ${spacing.gutter.sm};
`;

type Props = {
  invoice: Invoice | null;
  purchaserOffer: PurchaserOffer | null;
  eligibleInvoices: InvoiceWithServiceFee[];
  loading: boolean;
  setPaymentSelection: (selection: InvoiceWithServiceFee[]) => void;
};

const PaymentSelection: React.FC<Props> = ({
  invoice,
  purchaserOffer,
  eligibleInvoices,
  loading,
  setPaymentSelection,
}) => {
  const [serviceFeeTotal, setServiceFeeTotal] = useState(
    eligibleInvoices.reduce((a, b) => a + b?.serviceFee ?? 0, 0)
  );
  const [discountTotal, setDiscountTotal] = useState(
    eligibleInvoices.reduce((a, b) => a + b?.invoice.discountedTotal ?? 0, 0)
  );

  if (!invoice || !purchaserOffer) {
    return null;
  }

  const onRowSelection = (selectedIds: Key[]) => {
    const eligibleInvoicesById: { [id: string]: InvoiceWithServiceFee } =
      Object.assign(
        {},
        ...eligibleInvoices.map((item) => ({ [item.invoice.id]: item }))
      );
    const selectedInvoices = selectedIds.map((id) => eligibleInvoicesById[id]);
    const serviceFeeTotal = selectedInvoices.reduce(
      (a, b) => a + (b?.serviceFee ?? 0),
      0
    );
    const discountTotal = selectedInvoices.reduce(
      (a, b) => a + (b?.invoice.discountedTotal ?? 0),
      0
    );
    setServiceFeeTotal(serviceFeeTotal);
    setDiscountTotal(discountTotal);
    setPaymentSelection(selectedInvoices);
  };

  return (
    <>
      <DetailsWrapper>
        <PaymentTable
          invoices={eligibleInvoices.map((invoice) => invoice.invoice)}
          onRowSelection={onRowSelection}
          allowSelection
          loading={loading}
          scroll={{ y: 230 }}
        />
      </DetailsWrapper>
      <DataRow label="Service Fee" value={serviceFeeTotal} color="grey" />
      <DataRow
        label="Amount Due (inc. GST)"
        value={discountTotal}
        color="blue"
      />
    </>
  );
};

export default PaymentSelection;
