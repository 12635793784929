import React from 'react';
import { Tag as AntTag, Tooltip } from 'antd';
import { TagProps } from './types';

export const Tag: React.FC<TagProps> = (props) => {
  const { text, tooltipText, type } = props;
  return (
    <Tooltip title={tooltipText ? tooltipText : undefined} placement="bottom">
      <AntTag color={type}>{text}</AntTag>
    </Tooltip>
  );
};
