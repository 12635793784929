import React from 'react';
import styled from 'styled-components';
import { AmountStrip } from 'components';
import { formatCurrency } from 'utils';
import colors from 'colors';

const StyledAmountStrip = styled(AmountStrip)`
  position: relative;
  left: -2%;
  margin-top: 20px;
  width: 104%;
  .amount-strip-label {
    color: ${colors.greys900};
  }
`;

type Props = {
  successFee: number | null;
};

export const SuccessFee: React.FC<Props> = (props) => {
  const { successFee } = props;
  return (
    <StyledAmountStrip
      label="Success Fee"
      value={formatCurrency(successFee || 0)}
      greyScale
    />
  );
};
