import React, { useEffect, useState } from 'react';
import api from 'api';
import ModalHeader from 'components/ModalHeader';
import { InvoiceWithServiceFee } from './InvoiceWithServiceFee';
import PaymentSelection from './PaymentSelection';
import PaymentSummary from './PaymentSummary';
import ProgressBar from 'components/ProgressBar';
import PurchaserOffer, { ExecutionResult } from 'types/PurchaserOffer';
import Invoice from 'types/Invoice';
import {
  getLiveInvoices,
  getServiceFeeForPurchaserOffer,
  getServiceFeeWithMinServiceFee,
  knapsack,
} from 'utils';
import { processError } from 'utils';
import { Alert, Modal, notification, Skeleton } from 'antd';
import { getAnalytics } from 'store/analytics';
import {
  setPaymentsAvailableModalVisibility,
  setRefreshData,
  showWithdrawModal,
} from 'store/ui';
import { getPurchaserOffersAvailable } from 'store/offers';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'hooks';
import { MAX_FEE_PERCENTAGE } from 'config';

type DispatchProps = {
  getPurchaserOffersAvailable: () => void;
  getAnalytics: (invoiceType: 'invoice') => void;
  setRefreshData: (flag: boolean) => void;
  showWithdrawModal: () => void;
};

type Props = {
  selectedInvoice: Invoice;
  setSelectedInvoice: (invoice: Invoice | null) => void;
} & DispatchProps;

const MAX_TRIES_TO_GET_PROGRESS = 3;
const PROGRESS_INTERVAL = 1000; // delay in trying to get progress in milliseconds

const GetPaidModal: React.FC<Props> = ({
  setRefreshData,
  selectedInvoice,
  setSelectedInvoice,
  showWithdrawModal,
  getPurchaserOffersAvailable,
  getAnalytics,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [eligibleInvoices, setEligibleInvoices] = useState<
    Array<{ invoice: Invoice; serviceFee: number }>
  >([]);
  const [purchaserOffer, setPurchaserOffer] = useState<PurchaserOffer | null>(
    null
  );
  const [paymentSelection, setPaymentSelection] = useState<
    InvoiceWithServiceFee[]
  >([]);
  const [confirming, setConfirming] = useState(false);
  const [executionResults, setExecutionResults] = useState<
    Array<ExecutionResult>
  >([]);
  const [success, setSuccess] = useState(0);
  const [failed, setFailed] = useState(0);
  const [paid, setPaid] = useState(false);
  const [offerExpired, setOfferExpired] = useState(false);

  // to show payment progress
  const [progressView, setProgressView] = useState(false);
  const [paymentProgress, setPaymentProgress] = useState('0.00');
  const [progressInterval, setProgressInterval] = useState<NodeJS.Timer | null>(
    null
  );

  useEffect(() => {
    getPurchaserOffersAvailable();
  }, [getPurchaserOffersAvailable]);

  useEffect(() => {
    api.purchaserOffers
      .getAvailable({})
      .then((response) => {
        const offers = response.map((item) => item.purchaserOffer);
        const purchaserOffer = offers.find(
          (offer) =>
            (offer.offerStatus === 'ACTIVE' ||
              offer.offerStatus === 'VIRTUAL') &&
            offer.createdByCompany === selectedInvoice.issuedFor
        );
        if (purchaserOffer) {
          setPurchaserOffer(purchaserOffer);
        } else {
          setOfferExpired(true);
        }
      })
      .catch(() => {
        setLoadingError(true);
      })
      .finally(() => setLoading(false));
  }, [selectedInvoice]);

  useEffect(() => {
    if (!purchaserOffer) {
      return;
    }
    if (selectedInvoice) {
      setLoading(true);
      const issuedForCompanyName = encodeURIComponent(
        selectedInvoice.issuedForCompanyName
      );
      const page = 0;
      const size = 1000;
      const invoiceType = 'INVOICE';
      const url = `?page=${page}&size=${size}&issuedForCompanyName=${issuedForCompanyName}&type=${invoiceType}&status=PAYMENT_READY`;
      api.invoices
        .filter({ url })
        .then(async (response) => {
          const invoices = response.invoices.map((invoice) => invoice.invoice);
          const validatedInvoices: Array<Invoice> = [];
          await Promise.all(
            invoices.map(async (invoice) => {
              const { invoiceLoadedBy, billLoadedBy } = invoice;
              const isLoadedByXero =
                invoiceLoadedBy === 'XERO' || billLoadedBy === 'XERO';
              if (isLoadedByXero) {
                try {
                  await api.xero.validateOffer({ invoiceId: invoice.id });
                  validatedInvoices.push(invoice);
                } catch (e) {}
              } else {
                validatedInvoices.push(invoice);
              }
            })
          );
          const { cashpool } = purchaserOffer;
          const liveInvoices = getLiveInvoices(validatedInvoices);
          const invoicesIncludedInPurchaserOffer = liveInvoices.filter(
            (invoice) => invoice.includeInPurchaserOffer
          );
          const invoicesThatFitCashpool =
            invoicesIncludedInPurchaserOffer.filter(
              (invoice) => invoice.discountedTotal <= cashpool
            );
          const validInvoices: Array<{
            invoice: Invoice;
            serviceFee: number;
          }> = [];
          invoicesThatFitCashpool.forEach((invoice) => {
            const serviceFeeWithoutMinServiceFee =
              getServiceFeeForPurchaserOffer({ invoice, purchaserOffer });
            const serviceFeePercentage =
              (serviceFeeWithoutMinServiceFee * 100) / invoice.discountedTotal;
            if (serviceFeePercentage <= MAX_FEE_PERCENTAGE) {
              // The percentage of service fee without MIN_PURCHASER_OFFER_FEE is within the MAX_FEE_PERCETAGE
              // This invoice is a candidate for payment
              const serviceFee = getServiceFeeWithMinServiceFee(
                serviceFeeWithoutMinServiceFee
              );
              validInvoices.push({ invoice, serviceFee });
            }
          });
          const amountApproved = validInvoices.reduce(
            (accumulator, invoice) =>
              accumulator + invoice.invoice.discountedTotal,
            0
          );
          if (amountApproved > cashpool) {
            const { selectedInvoices } = knapsack(validInvoices, cashpool);
            setEligibleInvoices(selectedInvoices);
            setPaymentSelection(selectedInvoices as InvoiceWithServiceFee[]);
          } else {
            setEligibleInvoices(validInvoices);
            setPaymentSelection(validInvoices as InvoiceWithServiceFee[]);
          }
        })
        .catch(() => {
          setLoadingError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [selectedInvoice, purchaserOffer]);

  const acceptOffer = () => {
    if (paymentSelection.length > 0 && purchaserOffer) {
      const invoiceIds = paymentSelection.map((invoice) => invoice.invoice.id);
      const offerId = purchaserOffer.id;
      const payload = { invoiceIds };
      api.purchaserOffers
        .execute({ payload, offerId })
        .then((executionId) => {
          setConfirming(true);
          setProgressView(true);
          let triesToGetProgress = 1;
          const progressInterval = setInterval(() => {
            api.purchaserOffers
              .executionStatus({
                offerId,
                executionId,
              })
              .then((response) => {
                const { success, failed, progress, executionResults } =
                  response;
                if (parseInt(progress) === 100) {
                  setRefreshData(true);
                  clearInterval(progressInterval);
                  setPaymentProgress(progress);
                  setTimeout(() => {
                    if (success === 0) {
                      setOfferExpired(true);
                    } else {
                      setExecutionResults(executionResults);
                      setFailed(failed);
                      setSuccess(success);
                      setPaid(true);
                    }
                    setProgressView(false);
                  }, 2000);
                } else {
                  setPaymentProgress(progress);
                }
              })
              .catch((err) => {
                const { status, message } = processError(err);
                if (
                  status === 404 &&
                  triesToGetProgress < MAX_TRIES_TO_GET_PROGRESS
                ) {
                  triesToGetProgress++;
                } else {
                  if (
                    message.toLowerCase() === 'offer not available to supplier'
                  ) {
                    setOfferExpired(true);
                  } else {
                    notification.error({ message });
                  }
                  setProgressView(false);
                  triesToGetProgress = 0;
                  clearInterval(progressInterval);
                  setProgressInterval(null);
                }
              });
          }, PROGRESS_INTERVAL);
          setProgressInterval(progressInterval);
        })
        .catch((error) => {
          const { message } = processError(error);
          if (message.toLowerCase() === 'offer not available to supplier') {
            setOfferExpired(true);
          } else {
            notification.error({ message });
          }
        })
        .finally(() => setConfirming(false));
    }
  };

  const handleCloseModalAfterPaid = () => {
    const queryParams = new URLSearchParams();
    navigate({ params: queryParams, reset: true });
    getPurchaserOffersAvailable();
    getAnalytics('invoice');
    setSelectedInvoice(null);
    setRefreshData(true);
  };

  const handleCloseModalAfterOfferExpired = () => {
    getPurchaserOffersAvailable();
    setRefreshData(true);
    setSelectedInvoice(null);
  };

  const onCancel = () => {
    if (progressInterval) {
      clearInterval(progressInterval);
    }
    if (paid) {
      handleCloseModalAfterPaid();
      dispatch(setPaymentsAvailableModalVisibility(false));
    } else if (offerExpired) {
      handleCloseModalAfterOfferExpired();
    } else {
      if (progressView) {
        // To refresh data if user closes dialog box when waiting for offer execution progress
        setRefreshData(true);
      }
      setSelectedInvoice(null);
      dispatch(setPaymentsAvailableModalVisibility(false));
    }
  };

  const onOk = () => {
    if (paid) {
      showWithdrawModal();
      handleCloseModalAfterPaid();
    } else if (offerExpired) {
      handleCloseModalAfterOfferExpired();
    } else if (loadingError) {
      setSelectedInvoice(null);
    } else {
      acceptOffer();
    }
  };

  const handleViewPaidInvoices = () => {
    const paidInvoices = eligibleInvoices.filter((invoice) =>
      executionResults.some(
        (result) =>
          result.invoiceId === invoice.invoice.id && result.status === 'SUCCESS'
      )
    );
    const paidInvoiceNumbers = paidInvoices.map(
      (invoice) => invoice.invoice.invoiceNumber
    );
    const invoiceNumbersToFilter = paidInvoiceNumbers.join();
    const queryParams = new URLSearchParams();
    queryParams.set('entity', selectedInvoice.issuedForCompanyName);
    queryParams.set('number', `${invoiceNumbersToFilter}:in`);
    queryParams.set('status', 'PAYMENT_RECEIVED');
    setSelectedInvoice(null);
    dispatch(setPaymentsAvailableModalVisibility(false));
    navigate({ params: queryParams });
  };

  const title = loadingError
    ? 'Prompt Payment'
    : progressView
    ? 'Payment In Progress'
    : offerExpired
    ? 'Offer Expired'
    : paid
    ? 'Payment Summary'
    : 'Prompt Payment Available';
  const cancelText = paid
    ? 'Close'
    : loading || loadingError || offerExpired || progressView
    ? ' '
    : 'Cancel';
  const okText = loading
    ? 'Loading...'
    : paid
    ? 'Withdraw Payment'
    : loadingError || offerExpired || progressView
    ? 'OK'
    : 'Get Paid';
  const progressPercent = paymentProgress.split('%').join('');
  const subtitle =
    loading || loadingError || offerExpired || progressView
      ? undefined
      : paid
      ? `${eligibleInvoices.length} invoice${
          eligibleInvoices.length === 1 ? '' : 's'
        } from ${selectedInvoice.issuedForCompanyName}`
      : `${eligibleInvoices.length} available invoice${
          eligibleInvoices.length === 1 ? '' : 's'
        } from ${selectedInvoice.issuedForCompanyName}`;
  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={!confirming}
      title={
        <ModalHeader
          title={title}
          subtitle={subtitle}
          onClose={paid ? onOk : onCancel}
          hideClose={confirming}
        />
      }
      visible={!!selectedInvoice}
      okText={okText}
      onOk={onOk}
      okButtonProps={{
        loading: confirming,
        disabled:
          paymentSelection.length === 0 &&
          !(loading || paid || loadingError || offerExpired || progressView),
      }}
      cancelText={cancelText}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: confirming, type: 'link' }}
      closable={false}
      footer={progressView ? null : undefined}
    >
      {loading ? (
        <Skeleton active paragraph />
      ) : progressView ? (
        <ProgressBar percent={progressPercent} />
      ) : loadingError ? (
        <Alert message="Something went wrong. Please try again." type="error" />
      ) : offerExpired ? (
        <Alert
          message="Oops! This offer is no longer available."
          type="error"
        />
      ) : paid ? (
        <PaymentSummary
          eligibleInvoices={eligibleInvoices}
          executionResults={executionResults}
          handleViewPaidInvoices={handleViewPaidInvoices}
          successCount={success}
          failedCount={failed}
        />
      ) : (
        <PaymentSelection
          loading={loading}
          invoice={selectedInvoice}
          purchaserOffer={purchaserOffer}
          setPaymentSelection={setPaymentSelection}
          eligibleInvoices={eligibleInvoices}
        />
      )}
    </Modal>
  );
};

const mapDispatchToProps = {
  getPurchaserOffersAvailable,
  getAnalytics,
  setRefreshData,
  showWithdrawModal,
};

export default connect(null, mapDispatchToProps)(GetPaidModal);
