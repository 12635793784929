import React from 'react';
import { Typography, Space, Divider } from 'antd';
import styled from 'styled-components';

import Card from './WelcomeCard';
import { colors } from 'themes';

const { Title, Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.subTitle ? '46px' : '82.92px')};
  margin-bottom: 45.75px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Welcome = ({ data, title, footer, subTitle }) => {
  return (
    <Container subTitle={subTitle}>
      <Title
        style={{ ...styles.heading, marginBottom: subTitle ? '14px' : '45px' }}
      >
        {title}
      </Title>
      {!!subTitle && <Text style={styles.subTitle}>{subTitle}</Text>}

      <Content>
        <Space size={20}>
          {data.map((item) => {
            const { title, content, buttonText, id } = item;
            return (
              <Card
                title={title}
                content={content}
                buttonText={buttonText}
                user={subTitle ? true : false}
                key={id}
              />
            );
          })}
        </Space>
        <Text style={{ ...styles.footer, marginTop: footer ? 54.25 : 6 }}>
          {footer}
        </Text>

        <Divider
          style={{ ...styles.divider, height: footer ? '1px' : '6px' }}
        />
      </Content>
    </Container>
  );
};
const styles = {
  heading: {
    fontSize: '40px',
    lineHeight: '55px',
    color: '#3B4048',
  },
  footer: {
    fontSize: 18,
    marginBottom: 10.77,
  },
  divider: {
    backgroundColor: colors.greys200,
  },
  subTitle: {
    textAlign: 'center',
    maxWidth: 420,
    marginBottom: 44,
  },
};

export default Welcome;
