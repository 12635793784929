const validateEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  return format.test(email);
};

export default validateEmail;
