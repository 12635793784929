import React from 'react';
import styled from 'styled-components/macro';
import { Button, Tooltip } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

import colors from 'colors';

const StyledButton = styled(Button)<{ $isLocked: boolean }>`
  border: none;
  background: ${(props) =>
    props.$isLocked ? colors.greys100 : colors.blues.lighter};
  :hover,
  :active,
  :focus {
    background: ${(props) =>
      props.$isLocked ? colors.greys100 : colors.blues.lighter};
    color: ${colors.greys700};
  }
  transition: background 200ms;
`;

type PeriodLockProps = {
  isDisabled: boolean;
  isLoading: boolean;
  isLocked: boolean;
  onClick: () => void;
  tooltip: string;
};

export const PeriodLockButton: React.FC<PeriodLockProps> = ({
  isDisabled,
  isLoading,
  isLocked,
  onClick,
  tooltip,
}) => {
  return (
    <Tooltip placement="right" title={tooltip}>
      <StyledButton
        data-testid="period-lock-button"
        disabled={isDisabled || isLocked}
        icon={
          isLocked ? (
            <LockOutlined data-testid="period-lock-icon-locked" />
          ) : (
            <UnlockOutlined
              css={isDisabled ? '' : `color: ${colors.primary};`}
              data-testid="period-lock-icon-unlocked"
            />
          )
        }
        $isLocked={isLocked}
        loading={isLoading}
        onClick={onClick}
      />
    </Tooltip>
  );
};
