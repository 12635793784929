import React from 'react';
import styled from 'styled-components';
import { AmountStrip } from 'components';
import { Moment } from 'moment';
import { formatCurrency } from 'utils';

const StyledAmountStrip = styled(AmountStrip)`
  position: relative;
  left: -2%;
  margin-top: 20px;
  width: 104%;
`;

type Props = {
  expiryDate: Moment | null;
  serviceFee: number;
};

export const ServiceFee: React.FC<Props> = (props) => {
  const { expiryDate, serviceFee } = props;
  return (
    <StyledAmountStrip
      label={
        expiryDate
          ? `Total Fee if invoice is paid by ${expiryDate.format(
              'DD MMM YYYY'
            )}`
          : 'Total Fee'
      }
      value={formatCurrency(serviceFee)}
    />
  );
};
