import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination as PaginationBase } from 'components';
import { useHistory, useLocation } from 'react-router';
import debounce from 'lodash.debounce';

const DEBOUNCE_DELAY = 100; // in milliseconds
const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 25;
const MIN_PAGE_SIZE = 10;

const InvoicePagination: React.FC<{ type: 'INVOICE' | 'BILL' | 'SUPPLIER' }> =
  ({ type }) => {
  const history = useHistory();
  const location = useLocation();

  const { pathname, search } = location;

  const params = new URLSearchParams(search);

  const pageNumberParam = params.has('page')
    ? Number(params.get('page'))
    : DEFAULT_PAGE_NUMBER;
  const pageSizeParam = params.has('size')
    ? Number(params.get('size'))
    : DEFAULT_PAGE_SIZE;
  const total = useSelector((store: any) => {
    switch (type) {
      case 'INVOICE':
        return store.invoices.totalNumberOfInvoices;
      case 'BILL':
        return store.invoices.totalNumberOfBills;
      case 'SUPPLIER':
        return store.invoices.totalNumberOfPayableSummaries;
    }
  });

  const [pageNumber, setPageNumber] = useState(pageNumberParam);
  const [pageSize, setPageSize] = useState(pageSizeParam);

  useEffect(() => {
    setPageNumber(pageNumberParam);
  }, [pageNumberParam]);

  useEffect(() => {
    setPageSize(pageSizeParam);
  }, [pageSizeParam]);

  let startRow = Math.ceil((Number(pageNumber) - 1) * Number(pageSize) + 1);
  let endRow = Math.ceil(Number(pageNumber) * Number(pageSize));

  const handlePageChange = debounce((pageNumber: number) => {
    params.set('page', String(pageNumber));
    history.push(`${pathname}?${params}`);
  }, DEBOUNCE_DELAY);

  const handleSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    params.set('page', String(DEFAULT_PAGE_NUMBER));
    params.set('size', String(e.target.value));
    history.push(`${pathname}?${params}`);
  };

  if (total > MIN_PAGE_SIZE) {
    return (
      <PaginationBase
        rows={total}
        startRow={startRow}
        endRow={endRow}
        pageSize={pageSize}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
      />
    );
  } else {
    return null;
  }
};

export default InvoicePagination;
