import React from 'react';
import styled from 'styled-components';
import {Typography} from 'antd';

const {Text} = Typography;

const Info = styled.div`
  background-color: #EBF7FF;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
`;

const Pending: React.FC = () => (
  <Info>
  <Text>
  We have received your identification document and this is currently under review by our team. This usually takes less than 1 hour. We will let you know by email when everything is ready to go.
  </Text>
  </Info>
)

export default Pending;
