import React, { useState } from 'react';
import moment from 'moment';
import { Col, notification, Row, Typography } from 'antd';

import api from 'api';
import { Toggle } from 'ds';
import { processError } from 'utils';
import { CounterParty } from 'types/CounterParty';
import { Preferences } from 'types/Company';

const { Text } = Typography;

const previousMonth = moment().subtract(1, 'month').endOf('month');
const previousMonthName = previousMonth.format('MMMM YYYY');

const isPeriodLocked = (periodLockDate: Preferences['periodLockDate']) => {
  if (!periodLockDate) {
    return false;
  }
  const lockedMonth = moment(periodLockDate);
  return lockedMonth.isSame(previousMonth, 'month');
};

export const PeriodLockToggle: React.FC<{
  counterParty: CounterParty;
  entityId: string;
  entityPeriodLockDate: Preferences['periodLockDate'];
  onToggle: (data: CounterParty) => void;
}> = ({ counterParty, entityId, entityPeriodLockDate, onToggle }) => {
  const isEntityPeriodLocked = isPeriodLocked(entityPeriodLockDate);
  const isCounterPartyPeriodLocked =
    isEntityPeriodLocked || isPeriodLocked(counterParty.periodLockDate);
  const isLocked = isEntityPeriodLocked || isCounterPartyPeriodLocked;
  const isDisabled = isEntityPeriodLocked;

  const [isSubmitting, setSubmitting] = useState(false);

  const handleToggle = (checked: boolean) => {
    setSubmitting(true);
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: checked ? previousMonth.valueOf() : null,
      path: '/periodLockDate' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((data) => {
        const outcome = checked ? 'locked' : 'unlocked';
        const message = `Period ${outcome} for ${counterParty.counterParty.companyName}`;
        notification.info({ message });
        onToggle(data.counterParty);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Row align="middle">
      <Col span={18}>
        <Text>{previousMonthName}</Text>
      </Col>
      <Col span={6}>
        <Toggle
          checked={isLocked}
          disabled={isDisabled}
          loading={isSubmitting}
          onChange={handleToggle}
          checkedChildren="Locked"
          unCheckedChildren="Unlocked"
        />
      </Col>
    </Row>
  );
};
