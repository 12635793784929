import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import styled from 'styled-components';
import queryString from 'query-string';

import Login from 'pages/Login';
import LogInWait from 'pages/Login/LogInWait';
import Signup from 'pages/Signup';
import ActivateAccount from 'pages/ActivateAccount';
import HomePage from 'pages/HomePage';
import ForgotPassword from 'pages/ForgotPassword';
import { ResetPassword, ResetPasswordSuccess } from 'pages/ResetPassword';
import Welcome from 'pages/Welcome';
import Error404 from 'pages/ErrorPages/Error404';

import OfferLandingPage from 'pages/OfferLandingPage';
import ConnectAccountError from 'components/ConnectAccountError';
import MyobCallback from 'pages/MyobCallback';
import Verification from 'pages/Verification';

const Container = styled.div`
  background-color: #f6f7f8 !important;
  height: 100vh !important;
  width: 100vw !important;
  .verification-screen {
    min-height: 100vh !important;
    background-color: white !important;
  }
`;

const protectedRoutes = [
  '/invoices',
  '/bills',
  '/account',
  '/settings',
  '/myob/callback',
  '/settings/connect-business-account',
  '/create-new-invoice',
  '/welcome',
  '/verify',
  // admin routes follow
  '/bank-accounts',
  '/companies',
];

const AuthRouter: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Checking if a logged out user is trying to access a protected route
    const { pathname, search } = location;
    const isProtectedRoute = protectedRoutes.some((route) =>
      pathname.includes(route)
    );
    if (isProtectedRoute) {
      // If the accessed URL includes invoiceId, invoiceType and token as query params, then it is an invite link
      // In that case we don't need to do anything
      const { invoiceId, invoiceType, token } = queryString.parse(
        location.search
      );
      if (!invoiceId && !invoiceType && !token) {
        history.push(`login?next=${pathname}${search}`);
      }
    }
  }, [history, location]);
  const invoiceType = queryString.parse(history.location.search).invoiceType;
  const tokenToUse = queryString.parse(window.location.search).token;
  const connectError = queryString.parse(history.location.search).error;
  if (connectError) {
    return <ConnectAccountError />;
  }
  const { token } = queryString.parse(history.location.search);
  return (
    <Container>
      {invoiceType && !tokenToUse ? (
        <LogInWait message="Fetching your details" fromApprouter={true} />
      ) : null}
      <Route
        render={({ location }) => (
          <Switch key={location.key}>
            <Route exact path="/" location={location} component={HomePage} />
            <Route exact path="/login" location={location} component={Login} />
            <Route
              exact
              path="/invoices"
              location={location}
              component={OfferLandingPage}
            />
            <Route
              exact
              path="/signup"
              location={location}
              component={Signup}
            />
            <Route
              path="/activate"
              location={location}
              component={ActivateAccount}
            />
            <Route
              path="/verify"
              location={location}
              render={(props) => (
                <Verification
                  {...props}
                  token={Array.isArray(token) ? token[0] : token || ''}
                />
              )}
            />
            <Route
              exact
              path="/myob/callback"
              location={location}
              component={MyobCallback}
            />
            <Route
              exact
              path="/forgot-password"
              location={location}
              component={ForgotPassword}
            />
            <Route
              exact
              path="/resetpassword"
              location={location}
              component={ResetPassword}
            />
            <Route
              exact
              path="/resetpassword/success"
              location={location}
              component={ResetPasswordSuccess}
            />
            <Route
              exact
              path="/welcome"
              location={location}
              component={Welcome}
            />
            <Route component={Error404} />
          </Switch>
        )}
      />
    </Container>
  );
};

export default AuthRouter;
