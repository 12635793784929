import React, { useState, useRef, createRef } from 'react';
import { Input } from 'antd';
import PageTracker from 'components/PageTracker';
import api from 'api';
import { InputContainer } from 'components';
import styled from 'styled-components';
import { StyledButton } from './SignupEntry';

const Row = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  text-align: center;
  width: 60px;
  margin-right: ${(props) => (props.last ? '0' : '10px')};
`;

const StyledInput = styled(Input)`
  text-align: center;
`;

const CODE_INVALID_ERROR = 'You entered an invalid code';
const CODE_MISSING_ERROR = 'Please enter the code recieved via email';
const CODE_LENGTH = 6;
const initialCodeArray = Array(CODE_LENGTH).fill('');

const InviteCode = ({ setInviteCodeEntryVisibility }) => {
  const [codeArray, setCodeArray] = useState(initialCodeArray.slice());
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [pasting, setPasting] = useState(false);

  let inputRefs = useRef([]);

  if (inputRefs.current.length !== CODE_LENGTH) {
    inputRefs.current = Array(CODE_LENGTH)
      .fill()
      .map((_, i) => inputRefs.current[i] || createRef());
  }

  const onCodeInput = (e, i) => {
    let value = e.target.value;
    if (value === ' ') {
      // Don't let user tap the space key or paste a single space char.
      // If they paste multiple space chars, that is handled in later code block.
      setPasting(false);
      return;
    }
    if (!isNaN(value)) {
      if (pasting) {
        setCodeArray([...value.split('')]);
        if (value.length < 6) {
          inputRefs.current[value.length].focus();
        }
        setPasting(false);
      } else {
        if (value.length > 1) {
          // A digit already exists in the input field.
          // We need to replace it with the newly entered digit.
          const quotient = Math.floor(value / 10);
          const remainder = value % 10;
          value =
            codeArray[i] === String(remainder)
              ? String(quotient)
              : String(remainder);
        }
        let updatedCodeArray = codeArray;
        updatedCodeArray[i] = value;
        setCodeArray([...updatedCodeArray]);
        if (value !== '' && i < 5) {
          // Shift focus to next cell
          inputRefs.current[i + 1].focus();
        }
        if (value === '' && i > 0) {
          // Shift focus to previous cell
          inputRefs.current[i - 1].focus();
        }
        setPasting(false);
      }
    }
  };

  const onSubmit = async () => {
    const code = codeArray.join('');
    if (!code || code.length < CODE_LENGTH) {
      // User has either not entered the whole code or any digit at all.
      setError(CODE_MISSING_ERROR);
      return;
    }
    setLoading(true);
    const isCodeValid = await api.inviteCode.validate(code);
    setLoading(false);
    if (isCodeValid) {
      setInviteCodeEntryVisibility(false);
    } else {
      setError(CODE_INVALID_ERROR);
    }
  };

  return (
    <>
      <PageTracker />
      <InputContainer error={error}>
        <Row>
          {[...Array(CODE_LENGTH).keys()].map((i) => (
            <InputWrapper key={String(i)} last={i === CODE_LENGTH - 1}>
              <StyledInput
                onChange={(e) => {
                  onCodeInput(e, i);
                }}
                onPaste={() => {
                  setPasting(true);
                  return;
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                ref={(el) => (inputRefs.current[i] = el)}
                value={codeArray[i]}
                size="large"
              />
            </InputWrapper>
          ))}
        </Row>
      </InputContainer>
      <StyledButton
        loading={isLoading}
        onClick={onSubmit}
        type="primary"
        size="large"
      >
        Continue
      </StyledButton>
    </>
  );
};

export default InviteCode;
