import React from 'react';
import AuthContainer from 'components/AuthContainer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PageTracker from 'components/PageTracker';
import AuthCard from 'components/AuthCard';

const Error404: React.FC = () => {
  return (
    <AuthContainer>
      <AuthCard
        message="404"
        subMessage="You followed a broken link. Please go to home page to find your way
        forward."
        showWarningIcon
      >
        <PageTracker />
        <Button type="primary" size="large">
          <Link to="/">Go to home page</Link>
        </Button>
      </AuthCard>
    </AuthContainer>
  );
};
export default Error404;
