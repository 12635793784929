import { Key, useState } from 'react';

const useTableSelection = () => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<Key[]>([]);

  const onChange = (selectedIds: Key[]) => {
    setSelectedInvoiceIds(selectedIds);
  };

  const rowSelection = {
    selectedRowKeys: selectedInvoiceIds,
    onChange,
  };

  const clearRowSelection = () => setSelectedInvoiceIds([]);

  return { clearRowSelection, rowSelection };
};

export default useTableSelection;
