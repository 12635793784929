import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, notification, Typography } from 'antd';
import { useDispatch } from 'react-redux';

import api from 'api';
import EntityPreferences from 'types/EntityPreferences';
import { updatePreferencesState } from 'store/entityPreferences';
import { processError } from 'utils';

const { Text } = Typography;

const StyledText = styled(Text)`
  text-align: center;
`;

const Message = styled(StyledText)`
  margin-top: 1rem;
`;

const promptIfNotAlreadyLocked = 'Are you sure you want to enable period lock?';

const messageIfAlreadyLocked =
  'Invoices from this period will no longer be processed automatically and will instead go to the Blocked tab';

export const PeriodLockConfirmation: React.FC<{
  alreadyLocked: boolean;
  entityId: string;
  preferences: EntityPreferences | null;
  onCancel: () => void;
  onSuccess: () => void;
  monthToLock: { monthName: string; timestamp: number } | null;
}> = ({
  alreadyLocked,
  entityId,
  preferences,
  onCancel,
  onSuccess,
  monthToLock,
}) => {
  const dispatch = useDispatch();

  const [isUpdating, setUpdating] = useState(false);

  const lockPeriod = () => {
    if (!preferences || !monthToLock) {
      return;
    }
    setUpdating(true);
    const { id, modificationTime } = preferences;
    const payload = {
      id,
      modificationTime,
      operation: 'replace' as const,
      path: '/periodLockDate' as const,
      value: monthToLock.timestamp,
    };
    api.entityPreferences
      .patchEntityPreference({ entityId, payload })
      .then((preferences) => {
        dispatch(updatePreferencesState(preferences));
        const message = `Period locked upto ${monthToLock.monthName}`;
        notification.success({ message });
        onSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setUpdating(false));
  };

  const prompt = alreadyLocked
    ? `Are you sure you want to lock ${monthToLock?.monthName}?`
    : promptIfNotAlreadyLocked;

  const message = alreadyLocked
    ? messageIfAlreadyLocked
    : ` Invoices from ${monthToLock?.monthName} and before will no longer be automatically processed and will instead go to the Blocked tab.`;

  return (
    <Modal
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
      closable={false}
      okButtonProps={{ loading: isUpdating }}
      onOk={lockPeriod}
      onCancel={onCancel}
      title={null}
      visible={!!monthToLock}
      width={400}
    >
      <StyledText>{prompt}</StyledText>
      <Message>{message}</Message>
    </Modal>
  );
};
