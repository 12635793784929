import React from 'react';
import { Button } from 'antd';

import { OtpInput } from 'ds';
import { TitleSection } from 'components';

type VerificationCodeProps = {
  disabled: boolean;
  error: string;
  phoneNumberFragment: string | null;
  onChange: (code: string) => void;
  onSend: () => void;
  value: string;
};

export const VerificationCode: React.FC<VerificationCodeProps> = ({
  disabled,
  error,
  phoneNumberFragment,
  onChange,
  onSend,
  value,
}) => {
  return (
    <>
      <TitleSection
        title="We just sent you an SMS"
        subTitle={`Enter the six digit code we sent to phone number ending in ${phoneNumberFragment}`}
      />
      <OtpInput
        disabled={disabled}
        error={error}
        onChange={onChange}
        value={value}
      />
      <Button onClick={onSend} type="ghost">
        Send New Code
      </Button>
    </>
  );
};
