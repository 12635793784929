import React from 'react';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import EntityPreferences from 'types/EntityPreferences';
import { RelayTable } from 'components';

const progressTooltip = 'Period still in progress';
const previousUnlockedTooltip = 'Previous period is unlocked';

const getMonthName = (date: moment.Moment) => date.format('MMMM YYYY');

type MonthItem = {
  monthName: string;
  lockable: boolean;
  locked: boolean;
  timestamp: number;
  tooltip?: string;
};

type Months = MonthItem[];

type LockButtonProps = MonthItem & { onClick: () => void };

const LockButton: React.FC<LockButtonProps> = ({
  onClick,
  lockable,
  locked,
  tooltip,
}) =>
  locked ? null : (
    <Tooltip placement="right" title={tooltip}>
      <Button disabled={!lockable} onClick={onClick} size="small" type="ghost">
        Lock
      </Button>
    </Tooltip>
  );

const getMonthsIfNotLocked = (): Months => {
  const currentMonth = moment();
  const currentMonthName = getMonthName(currentMonth);
  const currentMonthTimestamp = currentMonth.endOf('month').valueOf();

  const firstMonth = currentMonth.clone().subtract(2, 'months');
  const firstMonthName = getMonthName(firstMonth);
  const firstMonthTimestamp = firstMonth.endOf('month').valueOf();

  const secondMonth = currentMonth.clone().subtract(1, 'month');
  const secondMonthName = getMonthName(secondMonth);
  const secondMonthTimestamp = secondMonth.endOf('month').valueOf();

  return [
    {
      monthName: firstMonthName,
      lockable: true,
      locked: false,
      timestamp: firstMonthTimestamp,
    },
    {
      monthName: secondMonthName,
      lockable: true,
      locked: false,
      timestamp: secondMonthTimestamp,
    },
    {
      monthName: currentMonthName,
      lockable: false,
      locked: false,
      timestamp: currentMonthTimestamp,
      tooltip: progressTooltip,
    },
  ];
};

const getMonthsIfLocked = (periodLockDate: number): Months => {
  const currentMonth = moment().endOf('month');

  const lockedMonth = moment(periodLockDate);

  // Number of months between locked month and current month,
  // including the start and end months
  const monthCount = currentMonth.diff(lockedMonth, 'months') + 1;

  // Iterate over number of months, starting from locked month upto current month
  const months = Array(monthCount)
    .fill(0)
    .map((_, index) => {
      const month = lockedMonth.clone().add(index, 'month');
      const monthName = getMonthName(month);

      const timestamp = month.endOf('month').valueOf();

      const locked = index === 0;

      const isCurrentMonth = index === monthCount - 1;

      // Only the month that immediately succeeds the locked month can be locked
      const lockable = index === 1 && !isCurrentMonth;

      const tooltip =
        index > 1
          ? isCurrentMonth
            ? progressTooltip
            : previousUnlockedTooltip
          : undefined;

      return {
        monthName,
        locked,
        lockable,
        timestamp,
        tooltip,
      };
    });

  return months;
};

export const PeriodLockTable: React.FC<{
  handleClickLock: (month: { monthName: string; timestamp: number }) => void;
  isLoading: boolean;
  periodLockDate?: EntityPreferences['periodLockDate'];
}> = ({ handleClickLock, isLoading, periodLockDate }) => {
  const months = periodLockDate
    ? getMonthsIfLocked(periodLockDate)
    : getMonthsIfNotLocked();

  const columns = [
    { title: 'PERIOD', dataIndex: 'monthName' },
    {
      align: 'center' as const,
      title: 'LOCKED',
      dataIndex: 'locked',
      render: (locked: boolean) => locked && <CheckOutlined />,
    },
    {
      align: 'center' as const,
      title: 'ACTION',
      render: (record: MonthItem) => {
        const { monthName, timestamp } = record;
        const handleClick = () => {
          if (monthName && timestamp) {
            handleClickLock({ monthName, timestamp });
          }
        };
        return <LockButton onClick={handleClick} {...record} />;
      },
    },
  ];
  return (
    <RelayTable
      columns={columns}
      dataSource={months}
      loading={isLoading}
      pagination={false}
      rowKey="timestamp"
      scroll={{ x: 600 }}
    />
  );
};
