import React from 'react';

import { Typography, Card, Button } from 'antd';
import { colors } from 'themes';
import styled from 'styled-components';

const { Text, Title } = Typography;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  max-width: 220px;
`;
const WelcomeCard = ({ title, content, buttonText, user }) => {
  return (
    <Card
      style={{
        ...styles.card,
        boxShadow: user ? 'none' : '0px 10px 20px rgba(27, 20, 65, 0.1)',
      }}
    >
      <Content>
        <Title style={styles.title}>{title}</Title>
        <Text style={styles.content}>{content}</Text>
        <Button type="primary" style={styles.button} size='large'>
          <Text style={styles.buttonTitle}>{buttonText}</Text>
        </Button>
      </Content>
    </Card>
  );
};
const styles = {
  card: {
    borderRadius: '4px',
    border: `1px solid ${colors.greys300}`,
  },
  title: {
    fontSize: 18,
    marginBottom: 35,
    textAlign: 'center',
    minHeight: 45,
    color: '#3B4048',
  },
  content: {
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  button: {
    borderRadius: '2px',
    marginTop: 38,
  },
  buttonTitle: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '22px',
  },
};
export default WelcomeCard;
