export const daysOfWeek = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const;
export type DayOfWeek = typeof daysOfWeek[number];

export const paymentFrequencies = ['MONTHLY', 'WEEKLY'] as const;
export type PaymentFrequency = typeof paymentFrequencies[number];

export type PaymentTerm = {
  id: string;
  dayOfWeek: DayOfWeek | null;
  date: number | null;
  frequency: PaymentFrequency;
  defaultTerm: boolean;
  creationTime: number;
  modificationTime: number;
};

export type AddPaymentTermApiPayload = {
  frequency: PaymentFrequency;
  date?: number | null;
  dayOfWeek?: DayOfWeek | null;
};

export type GetPaymentTermsApiResponse = Array<PaymentTerm>;
