import React, { FC } from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Table } from 'antd';
import { formatCurrency } from 'utils';

const DataTable = styled(Table)`
  margin-top: 24px;

  .ant-table-tbody > tr > td {
    border-bottom: 8px solid #fff;
  }
  .ant-table-thead > tr > th {
    border-bottom: 8px solid #fff;
  }
  .ant-table-thead > tr > th {
    font-size: 10px;
    font-weight: bold !important;
    background-color: ${colors.greys100};

    color: ${colors.greys700};
    line-height: 14px;
    padding: 6px 16px;
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    color: ${colors.greys900};
    padding-bottom: 9px;
    padding-top: 9px;
    align-items: center;
    background: ${colors.greys100};
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${colors.greys100};
  }
`;

type Invoice = {
  invoiceNumber: string;
  creditApplied: number;
  amountDue: number;
};

type Props = {
  invoices: Array<Invoice>;
  loading: boolean;
  scrollHeight?: number;
};

const InvoiceList: FC<Props> = ({ invoices, loading, scrollHeight }) => {
  const columns = [
    {
      title: 'INVOICE #',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'CREDIT',
      key: 'allocatedAmount',
      align: 'right' as 'right',
      render: (record: Invoice) => formatCurrency(record.creditApplied),
    },
    {
      title: 'AMOUNT DUE',
      key: 'amountDue',
      align: 'right' as 'right',
      render: (record: Invoice) => formatCurrency(record.amountDue),
    },
  ];

  return (
    <DataTable
      dataSource={invoices}
      columns={columns}
      pagination={false}
      loading={loading}
      scroll={{ y: scrollHeight || 120 }}
      data-testid="invoice-list"
    />
  );
};

export default InvoiceList;
