import React from 'react';
import styled from 'styled-components';
import colors from 'colors';

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Dot = styled.div<{ $isSelected: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  padding: 1px;
  border: 1px solid
    ${(props) => (props.$isSelected ? colors.greys600 : colors.greys300)};
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${colors.greys600};
`;

const Label = styled.span<{ $isSelected: boolean }>`
  color: ${colors.greys600};
  font-size: 14px;
  font-weight: ${(props) => (props.$isSelected ? 'bold' : 'normal')};
`;

type Props = {
  className: string;
  disabled: boolean;
  isSelected: boolean;
  onClick: () => {};
};

const Radio: React.FC<Props> = ({
  children,
  className,
  disabled,
  isSelected,
  onClick,
}) => {
  return (
    <Row className={className} onClick={onClick || null}>
      <Dot $isSelected={isSelected}>{isSelected && <InnerDot />}</Dot>
      {!!children && <Label $isSelected={isSelected}>{children}</Label>}
    </Row>
  );
};

export default Radio;
