import React from 'react';
import styled from 'styled-components';

import AmountInput from 'components/AmountInput';
import AprInput from 'components/AprInput';
import HelpIndicator from 'components/HelpIndicator';

import { RowContainer } from 'components/row';

import { colors } from 'themes';

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-size: 14px;
  color: ${colors.greys600};
`;

const Help = styled(HelpIndicator)`
  margin-left: 10px;
`;

const Amount = styled(AmountInput)`
  border: 1px solid ${colors.greys300};
`;

const APR = styled(AprInput)`
  border: 1px solid ${colors.greys300};
`;

const Inputs = ({ cashpoolAmount, crr, updateCashpoolAmount, updateCrr }) => {
  // TODO: Limit the cashpool and crr values to have maximum two values after decimal point.

  const onChangeCashpoolAmount = (e, cashpoolAmount) => {
    const { value } = e.target;
    const amount = value.trim();
    if (isNaN(amount)) {
      if (value === '.') {
        updateCashpoolAmount('0.');
      } else {
        updateCashpoolAmount(cashpoolAmount);
      }
    } else {
      updateCashpoolAmount(amount);
    }
  };

  const onChangeCrr = (e) => {
    const { value } = e.target;
    if (!isNaN(value)) {
      updateCrr(e.target.value);
    }
  };

  const onBlur = () => {
    const cashPoolAmount = cashpoolAmount
      ? parseFloat(cashpoolAmount).toFixed(2)
      : null;
    updateCashpoolAmount(cashPoolAmount);
  };

  return (
    <Container>
      <RowContainer>
        <Left>
          <Label>Cash Reserve</Label>
          <Help />
        </Left>
        <Amount
          value={cashpoolAmount}
          onChange={(e) => onChangeCashpoolAmount(e, cashpoolAmount)}
          onBlur={onBlur}
        />
      </RowContainer>
      <RowContainer>
        <Left>
          <Label>Required Rate of Return</Label>
          <Help />
        </Left>
        <APR value={crr} onChange={onChangeCrr} />
      </RowContainer>
    </Container>
  );
};

export default Inputs;
