import React from 'react';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';
import { Container, Item, Label, Row } from './components';
import colors from 'colors';

type Props = {
  driversLicenceNumber: string;
  setDriversLicenseNumber: (driversLicenceNumber: string) => void;
  driversLicenceVersion: string;
  setDriversLicenseVersion: (driversLicenceVersion: string) => void;
  dlNumberError: string;
  dlVersionError: string;
};

const DriverLicence: React.FC<Props> = ({
  driversLicenceNumber,
  setDriversLicenseNumber,
  driversLicenceVersion,
  setDriversLicenseVersion,
  dlNumberError,
  dlVersionError,
}) => {
  return (
    <Container>
      <Item>
        <Row>
          <Label>Number</Label>
          <Input
            style={{ flex: 1, marginBottom: 0 }}
            placeholder="e.g. AB123456"
            maxLength={8}
            value={driversLicenceNumber}
            onChange={(e) => setDriversLicenseNumber(e.target.value)}
          />
        </Row>
        {!!dlNumberError && <p style={styles.error}>{dlNumberError}</p>}
      </Item>
      <Item>
        <Row>
          <Label>Version</Label>
          <NumberFormat
            customInput={Input}
            format="###"
            placeholder="e.g. 123"
            onValueChange={({ value }) => setDriversLicenseVersion(value)}
            style={{ width: 100 }}
            value={driversLicenceVersion}
          />
        </Row>
        {!!dlVersionError && <p style={styles.error}>{dlVersionError}</p>}
      </Item>
    </Container>
  );
};

const styles = {
  error: {
    color: colors.error,
    marginLeft: 80,
  },
};

export default DriverLicence;
