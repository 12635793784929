import Invoice from 'types/Invoice';
import SupplierOffer from 'types/SupplierOffer';

export const findSupplierOfferOffered = (
  invoice: Invoice,
  offers: SupplierOffer[]
) => {
  if (!invoice || !offers) {
    return null;
  }

  let offer: SupplierOffer | null | undefined = null;

  if (invoice.invoiceStatus === 'CASHBACK_OFFERED') {
    offer = offers.find(
      (offer) =>
        (offer.offerStatus === 'CLEARING' ||
          offer.offerStatus === 'TOPUP_PENDING') &&
        offer.invoiceIds.includes(invoice.id)
    );
  }

  return offer;
};
