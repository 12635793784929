import { InvoiceMetaData } from 'pages/Invoices/utils/findInvoiceStatus';
import Invoice from 'types/Invoice';

const isInvitedByLoggedUserCompany = (
  invoice: Invoice | (Invoice & InvoiceMetaData),
  companyId: string
): boolean => {
  const invitedBy = invoice.owingCompany.invitedBy;
  const invitedByLoggedUser = invitedBy
    ? invitedBy.some((id) => id === companyId)
    : false;
  return invitedByLoggedUser;
};

export default isInvitedByLoggedUserCompany;
