import React from 'react';
import styled from 'styled-components';

import LogInWait from 'pages/Login/LogInWait';
import Button from 'components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  width: 100vw;
`;
const ErrorMessage = styled.p`
  font-size: 18px;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
`;

const MyobError = ({ history, loading, callbackError }) => {
  return (
    <>
      {loading ? (
        <LogInWait message="Fetching your details" fromApprouter={true} />
      ) : callbackError ? (
        <Container>
          <ErrorMessage>Error in connecting to your MYOB account.</ErrorMessage>
          <Row>
            <Button
              label="Login"
              raised
              primary
              full
              style={{ marginRight: 5 }}
              onClick={() => history.push('/login')}
            />
            <Button
              label="Signup"
              raised
              primary
              full
              onClick={() => history.push('/signup')}
            />
          </Row>
        </Container>
      ) : null}
    </>
  );
};

export default MyobError;
