import api from 'api';
import { Dispatch } from 'redux';

const BOOTSTRAP = 'relay/transactions/BOOTSTRAP';
const GET_TRANSACTIONS = 'relay/transactions/GET_TRANSACTIONS';
const SET_LOADING = 'relay/transactions/SET_LOADING';
const UPDATE_PAGE_SIZE = 'relay/transactions/UPDATE_PAGE_SIZE';
const UPDATE_PAGE_NUMBER = 'relay/transactions/UPDATE_PAGE_NUMBER';

const LOGOUT = 'LOGOUT';

type Action = {
  type: string;
  payload?: any;
};

const initialState = {
  transactions: [],
  spillOverElements: [],
  loadingTransactions: false,
  totalNumberOfTransactions: 0,
  pageSize: 25,
  pageNumber: 1,
};

export default (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case BOOTSTRAP: {
      return {
        ...state,
        transactions: payload.transactions,
        totalNumberOfTransactions: payload.totalElements,
        spillOverElements: payload.spillOverElements,
        spillOverCount: payload.spillOverCount,
        loadingTransactions: false,
      };
    }
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload.transactions,
        loadingTransactions: false,
        spillOverElements: payload.spillOverElements,
      };
    case SET_LOADING:
      return { ...state, loadingTransactions: true };
    case UPDATE_PAGE_SIZE: {
      return { ...state, pageSize: payload };
    }
    case UPDATE_PAGE_NUMBER: {
      return { ...state, pageNumber: payload };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const bootstrapTransactions = ({
  page,
  size,
  companyId,
}: {
  page?: number;
  size: number;
  companyId?: string;
}) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: UPDATE_PAGE_NUMBER, payload: 1 });
    api.account
      .getPendingTransactions({ page: page || 0, size, companyId })
      .then((pending: any) => {
        api.account
          .getTransactions({ page: page || 0, size, companyId })
          .then((completed: any) => {
            const pendingTransactions = pending.transactions;
            const pendingCount = pendingTransactions.length;
            const completedTransactions = completed.transactions;
            const completedTransactionsToDisplay = completedTransactions.slice(
              0,
              size - pendingCount
            );
            const spillOverElements = completedTransactions.slice(
              size - pendingCount,
              completedTransactions.length
            );
            dispatch({
              type: BOOTSTRAP,
              payload: {
                transactions: [
                  ...pendingTransactions,
                  ...completedTransactionsToDisplay,
                ],
                totalElements: pending.totalElements + completed.totalElements,
                spillOverElements,
              },
            });
          });
      });
  };
};

export const getTransactions = ({
  page,
  size,
  spillOverElements,
  companyId,
}: {
  page: number;
  size: number;
  spillOverElements: any;
  companyId?: string;
}) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_LOADING });
    api.account
      .getTransactions({ page, size, companyId })
      .then((completed: any) => {
        const spillOverCount =
          spillOverElements &&
          spillOverElements.length &&
          spillOverElements.length;
        const completedTransactions = completed.transactions;
        const completedTransactionsToDisplay = completedTransactions.slice(
          0,
          size - spillOverCount
        );
        const updatedSpillOverElements = completedTransactions.slice(
          size - spillOverCount,
          completedTransactions.length
        );
        dispatch({
          type: GET_TRANSACTIONS,
          payload: {
            transactions: [
              ...spillOverElements,
              ...completedTransactionsToDisplay,
            ],
            spillOverElements: updatedSpillOverElements,
          },
        });
      });
  };
};

export const updatePageSize = (size: number) => ({
  type: UPDATE_PAGE_SIZE,
  payload: size,
});

export const updatePageNumber = (pageNumber: number) => ({
  type: UPDATE_PAGE_NUMBER,
  payload: pageNumber,
});

// export const setLoadingTransactions = () => ({
//   type: SET_LOADING
// });
