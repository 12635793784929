import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';

import Title from './Title';
import Row from './Row';

import {
  showEarlyPaymentOffer,
  updatePurchaserOfferOffered,
} from 'store/offers';
import { showTopUpWindow, getCompany } from 'store/auth';
import { setRefreshData, setBackgroundCheckPurchaserOffer } from 'store/ui';

import api from 'api';
import { colors } from 'themes';
import processError from 'utils/processError';

import formatter from 'utils/currencyFormatter';

const Container = styled.div`
  width: 452px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-top: 14px;
  border-top: 1px solid #ddd;
`;

class EarlyPaymentOfferPanel extends Component {
  constructor(props) {
    super(props);
    const purchaserOffersOffered = props.purchaserOffers.filter(
      (offer) =>
        offer.purchaserOffer.offerStatus === 'ACTIVE' ||
        offer.purchaserOffer.offerStatus === 'VIRTUAL'
    );
    this.state = {
      isVisibleEdit: false,
      cashpoolAmount:
        purchaserOffersOffered[0] &&
        (purchaserOffersOffered[0].purchaserOffer.pendingCashpool
          ? purchaserOffersOffered[0].purchaserOffer.pendingCashpool +
            purchaserOffersOffered[0].purchaserOffer.cashpool
          : purchaserOffersOffered[0].purchaserOffer.cashpool),
      crr:
        purchaserOffersOffered[0] &&
        purchaserOffersOffered[0].purchaserOffer.crr,
      purchaserOffersOffered,
      loading: false,
      loadingReset: false,
      errorMessage: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.purchaserOffers) {
      const nextPurchserOffers = nextProps.purchaserOffers.filter(
        (offer) =>
          offer.purchaserOffer.offerStatus === 'ACTIVE' ||
          offer.purchaserOffer.offerStatus === 'VIRTUAL'
      );
      const currentPurchaserOffers = this.state.purchaserOffersOffered;
      if (currentPurchaserOffers !== nextPurchserOffers) {
        const nextCashpoolAmount =
          nextPurchserOffers[0] &&
          (nextPurchserOffers[0].purchaserOffer.pendingCashpool
            ? nextPurchserOffers[0].purchaserOffer.pendingCashpool +
              nextPurchserOffers[0].purchaserOffer.cashpool
            : nextPurchserOffers[0].purchaserOffer.cashpool);
        const nextCrr =
          nextPurchserOffers[0] && nextPurchserOffers[0].purchaserOffer.crr;
        this.setState({
          purchaserOffersOffered: nextPurchserOffers,
          cashpoolAmount: nextCashpoolAmount,
          crr: nextCrr,
        });
      } else {
        const currentCashpoolAmount =
          currentPurchaserOffers[0] &&
          (currentPurchaserOffers[0].purchaserOffer.pendingCashpool
            ? currentPurchaserOffers[0].purchaserOffer.pendingCashpool +
              currentPurchaserOffers[0].purchaserOffer.cashpool
            : currentPurchaserOffers[0].purchaserOffer.cashpool);
        const currentCrr =
          currentPurchaserOffers[0] &&
          currentPurchaserOffers[0].purchaserOffer.crr;
        this.setState({
          purchaserOffersOffered: currentPurchaserOffers,
          cashpoolAmount: currentCashpoolAmount,
          crr: currentCrr,
        });
      }
    }
  }

  updateCrr = (e) => {
    this.setState({ crr: e.target.value });
    this.setState({ isVisibleEdit: true });
  };

  updateCashpoolAmount = (e) => {
    const cashpoolAmount = e.target.value;
    if (isNaN(cashpoolAmount)) {
      if (cashpoolAmount === '.') {
        this.setState({ cashpoolAmount: '0.' });
      } else {
        this.setState({ cashpoolAmount: 0 });
      }
    } else {
      this.setState({ cashpoolAmount });
    }
    this.setState({ isVisibleEdit: true });
  };

  onBlur = () => {
    const cashPoolAmount = this.state.cashpoolAmount
      ? parseFloat(this.state.cashpoolAmount).toFixed(2)
      : null;
    this.setState({ cashpoolAmount: cashPoolAmount });
  };

  handleError = (error) => {
    notification.error({
      message:
        error ??
        'Something went wrong. Please try again or contact Relay if the issue persists.',
    });
  };

  updateEarlyPaymentOffer = () => {
    if (this.state.cashpoolAmount < 1) {
      this.setState({ errorMessage: 'Cashpool amount cannot be less than $1' });
    } else {
      this.setState({ loading: true, errorMessage: '' });
      const { id } = this.state.purchaserOffersOffered[0].purchaserOffer;
      const params = {
        ...this.state.purchaserOffersOffered[0].purchaserOffer,
        cashpool: String(this.state.cashpoolAmount),
        crr: String(this.state.crr),
      };
      const extraCashpoolAmount =
        parseFloat(this.state.cashpoolAmount) -
        parseFloat(
          this.state.purchaserOffersOffered[0].purchaserOffer.cashpool
        );
      if (
        extraCashpoolAmount >
        parseFloat(this.props.user.company.company.fundsAvailable)
      ) {
        this.props.showTopUpWindow({
          trigger: 'PURCHASER_OFFER_EDIT',
          triggerAmount: extraCashpoolAmount,
          crr: this.state.crr,
        });
        // if topup canceled update the cashpool value
        const { purchaserOffersOffered } = this.state;
        const cashpool =
          purchaserOffersOffered[0] &&
          (purchaserOffersOffered[0].purchaserOffer.pendingCashpool
            ? purchaserOffersOffered[0].purchaserOffer.pendingCashpool +
              purchaserOffersOffered[0].purchaserOffer.cashpool
            : purchaserOffersOffered[0].purchaserOffer.cashpool);

        this.setState({
          isVisibleEdit: false,
          loading: false,
          cashpoolAmount: cashpool,
        });
      } else {
        api.purchaserOffers
          .edit({ params, uriParam: id })
          .then((res) => {
            this.props.updatePurchaserOfferOffered(res);
            const { companyId } = this.props.user;
            this.props.getCompany(companyId);
            this.setState({ isVisibleEdit: false, loading: false });
            if (this.props.dataToRefresh !== 'INVOICE') {
              this.props.setRefreshData(true);
            }
          })
          .catch((err) => {
            const { message, errors } = processError(err);
            if (errors) {
              errors.map((message) => this.handleError(message));
            } else {
              this.handleError(message);
            }
            this.setState({ loading: false });
          });
      }
    }
  };

  updateArrForVirtualOffer = () => {};
  // updateArrForVirtualOffer = () => {
  //   const { purchaserOffersOffered } = this.state;
  //   if (purchaserOffersOffered[0] && purchaserOffersOffered[0].purchaserOffer) {
  //     const offer =
  //       purchaserOffersOffered[0] && purchaserOffersOffered[0].purchaserOffer;
  //     const { id } = offer;
  //     const { companyId } = this.props;
  //     this.setState({ loading: true, errorMessage: '' });
  //     api.company.settings
  //       .get({ companyId })
  //       .then((settings) => {
  //         const updatedSettings = {
  //           ...settings,
  //           virtualPurchaserOfferArr: this.state.crr,
  //         };
  //         api.company.settings
  //           .put({ companyId, settings: updatedSettings })
  //           .then(() => {
  //             api.purchaserOffers.getSingle(id).then((response) => {
  //               this.props.updatePurchaserOfferOffered(response);
  //               this.props.getCompany(companyId);
  //             });
  //             this.props.setBackgroundCheckPurchaserOffer({
  //               shouldCheck: true,
  //               direction: 'OFFERED',
  //             });
  //             this.setState({ isVisibleEdit: false, loading: false });
  //           })
  //           .catch((error) => {
  //             const { message } = processError(error);
  //             this.handleError(message);
  //             this.setState({ loading: false });
  //           });
  //       })
  //       .catch((error) => {
  //         const { message } = processError(error);
  //         this.handleError(message);
  //         this.setState({ loading: false });
  //       });
  //   }
  // };

  handleConfirm = () => {
    const offer = this.state?.purchaserOffersOffered[0]?.purchaserOffer;
    if (offer) {
      if (offer.offerStatus === 'ACTIVE') {
        this.updateEarlyPaymentOffer();
      } else {
        this.updateArrForVirtualOffer();
      }
    }
  };

  disableVirtualOffer = () => {};
  // disableVirtualOffer = () => {
  //   this.setState({ loadingReset: true, errorMessage: '' });
  //   const { companyId } = this.props;
  //   api.company.settings.get({ companyId }).then((settings) => {
  //     const updatedSettings = {
  //       ...settings,
  //       enableVirtualPurchaserOffer: false,
  //     };
  //     api.company.settings
  //       .put({ companyId, settings: updatedSettings })
  //       .then(() => {
  //         this.props.setBackgroundCheckPurchaserOffer({
  //           shouldCheck: true,
  //           direction: 'OFFERED',
  //         });
  //         this.setState({ isVisibleEdit: false, loadingReset: false });
  //       });
  //   });
  // };

  resetEarlypaymentOffer = () => {
    this.setState({ loadingReset: true, errorMessage: '' });
    const { id } = this.state.purchaserOffersOffered[0].purchaserOffer;
    const params = {
      ...this.state.purchaserOffersOffered[0].purchaserOffer,
      offerStatus: 'CANCELLED',
    };
    api.purchaserOffers
      .reset({ params, uriParam: id })
      .then((res) => {
        // this.props.updatePurchaserOfferOffered(res);
        const { companyId } = this.props.user;
        this.props.getCompany(companyId);
        this.setState({ isVisibleEdit: false, loadingReset: false });
        this.props.setBackgroundCheckPurchaserOffer({
          shouldCheck: true,
          direction: 'OFFERED',
        });
        if (this.props.dataToRefresh !== 'INVOICE') {
          this.props.setRefreshData(true);
        }
      })
      .catch((err) => {
        const { message, errors } = processError(err);
        if (errors) {
          errors.map((item) => this.handleError(item));
        } else {
          this.handleError(message);
        }
        this.setState({ loadingReset: false });
      });
  };

  render() {
    const { showEarlyPaymentOffer, count, invoicesTotal } = this.props;
    const totalCount = count ? count : 0;
    const totalAmountDue = invoicesTotal ? invoicesTotal : 0;
    const {
      purchaserOffersOffered,
      isVisibleEdit,
      cashpoolAmount,
      crr,
      loading,
      loadingReset,
      errorMessage,
    } = this.state;
    const title =
      purchaserOffersOffered[0] &&
      purchaserOffersOffered[0].purchaserOffer.offerStatus === 'VIRTUAL'
        ? 'VIRTUAL OFFER'
        : 'PROMPT PAYMENT OFFER';
    return (
      <Container>
        <Skeleton active loading={this.props.isLoadingPurchaseOffer}>
          <Title
            title={title}
            subTitle={
              purchaserOffersOffered[0] &&
              purchaserOffersOffered[0].purchaserOffer.pendingCashpool > 0 &&
              'Awaiting Funds'
            }
          />
          <Row
            label={
              purchaserOffersOffered[0]
                ? isVisibleEdit
                  ? 'Cash Reserve Amount'
                  : 'Cash reserved for prompt payment'
                : `You have ${totalCount} approved invoice${
                    totalCount === 1 ? '' : 's'
                  } awaiting payment of ${formatter.format(totalAmountDue)}`
            }
            highlighted
            value={
              purchaserOffersOffered[0]
                ? `${formatter.format(cashpoolAmount)}`
                : null
            }
            isVisibleEdit={isVisibleEdit}
            inputValue={cashpoolAmount}
            placeholder="Enter Amount"
            onChange={this.updateCashpoolAmount}
            multiline={true}
            onBlur={this.onBlur}
            editable={
              purchaserOffersOffered[0] &&
              purchaserOffersOffered[0].purchaserOffer.offerStatus === 'ACTIVE'
            }
          />
          <Row
            prominent
            value={
              purchaserOffersOffered[0]
                ? `${purchaserOffersOffered[0].purchaserOffer.crr}%`
                : null
            }
            isVisibleEdit={isVisibleEdit}
            inputValue={crr}
            placeholder="Enter Rate"
            onChange={this.updateCrr}
            multiline={true}
            crrInput={true}
            label={
              purchaserOffersOffered[0]
                ? isVisibleEdit
                  ? 'Required Rate of Return'
                  : 'Rate of return required to trigger prompt payment'
                : 'Earn cashback when you pay your suppliers early'
            }
            editable={true}
          />
          {errorMessage ? (
            <p style={styles.errorMessage}>{errorMessage}</p>
          ) : null}
          {isVisibleEdit ? (
            <ButtonContainer>
              <div style={{ marginRight: 10 }}>
                <Button
                  onClick={
                    purchaserOffersOffered[0] &&
                    purchaserOffersOffered[0].purchaserOffer.offerStatus ===
                      'VIRTUAL'
                      ? this.disableVirtualOffer
                      : this.resetEarlypaymentOffer
                  }
                  transparent
                  loading={loadingReset}
                >
                  {purchaserOffersOffered[0] &&
                  purchaserOffersOffered[0].purchaserOffer.offerStatus ===
                    'VIRTUAL'
                    ? 'Disable'
                    : 'Reset'}
                </Button>
              </div>

              <Button
                type="primary"
                onClick={this.handleConfirm}
                loading={loading}
              >
                Confirm
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                type="primary"
                onClick={
                  purchaserOffersOffered[0]
                    ? purchaserOffersOffered[0].purchaserOffer.pendingCashpool >
                      0
                      ? this.resetEarlypaymentOffer
                      : () => {
                          this.setState({ cashpoolAmount });
                          this.setState({ crr });
                          this.setState({ isVisibleEdit: true });
                        }
                    : () => {
                        showEarlyPaymentOffer();
                      }
                }
              >
                {purchaserOffersOffered[0]
                  ? purchaserOffersOffered[0].purchaserOffer.pendingCashpool > 0
                    ? 'Reset'
                    : 'Edit'
                  : 'Create Offer'}
              </Button>
            </ButtonContainer>
          )}
        </Skeleton>
      </Container>
    );
  }
}

const styles = {
  errorMessage: {
    color: colors.error,
    fontSize: 12,
    textAlign: 'right',
    marginRight: 12,
    marginTop: -10,
  },
};

const mapStateToProps = ({ auth, invoices, offers, analytics, ui }) => ({
  purchaserOffers: offers.purchaserOffers.offered,
  user: auth.user,
  bills: invoices.bills,
  count: analytics.unpaidInvoicesCount,
  invoicesTotal: analytics.invoicesTotal,
  companyId: auth.user?.company?.company?.id,
  dataToRefresh: ui.dataToRefresh,
  isLoadingPurchaseOffer: offers.isLoadingPurchaseOffer,
});

const mapDispatchToProps = {
  showTopUpWindow,
  showEarlyPaymentOffer,
  updatePurchaserOfferOffered,
  getCompany,
  setBackgroundCheckPurchaserOffer,
  setRefreshData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EarlyPaymentOfferPanel);
