import React, { useState } from 'react';
import api from 'api';
import styled from 'styled-components';
import { Button, Form, Modal, notification } from 'antd';
import { ModalHeader } from 'components';
import { AddUpdateTermForm } from './AddUpdateTermForm';
import { DayOfWeek, PaymentFrequency, PaymentTerm } from 'types/PaymentTerm';
import { processError } from 'utils';

const CentreAlignedText = styled.span`
  display: block;
  text-align: center;
`;
type Props = {
  entityId: string;
  onClose: () => void;
  isOpen: boolean;
  paymentTerm?: PaymentTerm;
  existingPaymentTerms: PaymentTerm[];
  onDeleteSuccess: (paymentTermId: PaymentTerm['id']) => void;
  onSuccess: (paymentTerm: PaymentTerm) => void;
};

export const AddUpdateTermModal: React.FC<Props> = ({
  entityId,
  isOpen,
  onClose,
  paymentTerm,
  existingPaymentTerms,
  onDeleteSuccess,
  onSuccess,
}) => {
  const [form] = Form.useForm<{
    frequency: PaymentFrequency;
    date?: number;
    dayOfWeek?: DayOfWeek;
    defaultTerm: boolean;
  }>();

  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const isDeleteButtonVisible =
    !!paymentTerm && !deleteMode && !paymentTerm?.defaultTerm;

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setSubmitting(true);
      const duplicateTerm = existingPaymentTerms.find((term) => {
        if (values.frequency === 'MONTHLY') {
          return term.date === values.date;
        } else {
          return term.dayOfWeek === values.dayOfWeek;
        }
      });
      if (duplicateTerm && duplicateTerm !== paymentTerm) {
        notification.error({
          message: 'Duplicate payment terms are not allowed',
        });
        setSubmitting(false);
      } else if (paymentTerm) {
        api.paymentTerms
          .updatePaymentTerm({
            entityId,
            payload: {
              ...paymentTerm,
              frequency: values.frequency,
              date: values.date || null,
              dayOfWeek: values.dayOfWeek || null,
              defaultTerm: values.defaultTerm,
            },
          })
          .then((updatedTerm) => onSuccess(updatedTerm))
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          })
          .finally(() => setSubmitting(false));
      } else {
        api.paymentTerms
          .addPaymentTerm({
            entityId,
            payload: {
              frequency: values.frequency,
              date: values.date,
              dayOfWeek: values.dayOfWeek,
            },
          })
          .then((newPaymentTerm) => onSuccess(newPaymentTerm))
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          })
          .finally(() => setSubmitting(false));
      }
    });
  };

  const handleDelete = () => {
    if (paymentTerm) {
      setDeleting(true);
      const paymentTermId = paymentTerm.id;
      api.paymentTerms
        .deletePaymentTerm({ entityId, paymentTermId })
        .then(() => {
          onDeleteSuccess(paymentTermId);
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setDeleting(false));
    }
  };

  const handleClickCancelDeleteButton = () => {
    setDeleteMode(false);
  };

  const handleClickDeleteButton = () => {
    setDeleteMode(true);
  };

  return (
    <Modal
      title={
        !deleteMode ? (
          <ModalHeader
            title={!!paymentTerm ? 'Edit Payment Terms' : 'Add Payment Terms'}
            onClose={onClose}
          />
        ) : (
          ''
        )
      }
      visible={isOpen}
      closable={false}
      onOk={handleSubmit}
      onCancel={onClose}
      centered
      footer={[
        isDeleteButtonVisible && (
          <Button
            type="ghost"
            key="delete"
            className="tertiary-button"
            onClick={handleClickDeleteButton}
          >
            Delete Term
          </Button>
        ),
        <Button
          key="cancel"
          onClick={deleteMode ? handleClickCancelDeleteButton : onClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting || isDeleting}
          onClick={deleteMode && paymentTerm ? handleDelete : handleSubmit}
        >
          {deleteMode ? 'OK' : 'Submit'}
        </Button>,
      ]}
    >
      {deleteMode ? (
        <CentreAlignedText>
          Are you sure you want to delete this payment term?
        </CentreAlignedText>
      ) : (
        <AddUpdateTermForm
          form={form}
          handleSubmit={handleSubmit}
          paymentTerm={paymentTerm}
        />
      )}
    </Modal>
  );
};
