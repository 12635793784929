import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import InfoHead from './InfoHead';
import InfoRow from './InfoRow';
import Ruler from 'components/Ruler';
import accountNumberFormatter from 'utils/accountNumberFormatter';

import BankAccount from 'types/BankAccount';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 13px;
  padding-left: 10px;
  padding-right: 10px;
`;

type Props = { amount: number; showMessage: boolean };

const BankTransferDetails: React.FC<Props> = ({ amount, showMessage }) => {
  const user = useSelector((state: any) => state.auth.user);
  const countryCode = user?.address?.countryCode;
  const bankAccounts: BankAccount[] = user.account;
  const relayBankAccount =
    bankAccounts && bankAccounts.length
      ? bankAccounts.find((account) => account.loadedBy === 'SYSTEM')
      : null;

  return (
    <div>
      {showMessage && (
        <Row>
          Send the Transfer Amount to the{' '}
          {countryCode === 'AU' ? 'PayID' : 'bank account'} shown below to
          complete the payment. Your cashback will appear in your Relay account
          once funds are received.
        </Row>
      )}

      <InfoHead amount={amount} />
      <Row>
        Your Relay {countryCode === 'AU' ? 'PayID' : 'bank account'} details:
      </Row>
      {countryCode === 'AU' && (
        <InfoRow label="PayID" value={relayBankAccount?.payId} />
      )}
      {countryCode !== 'AU' && (
        <>
          <InfoRow
            label="Account Number"
            value={
              relayBankAccount
                ? accountNumberFormatter(relayBankAccount.bankAccountNumber)
                : ''
            }
          />
          <InfoRow label="Bank Name" value="BNZ" />
        </>
      )}

      <Ruler />
    </div>
  );
};

export default BankTransferDetails;
