import React from 'react';
import styled from 'styled-components';
import CreateButtons from './CreateButtons';
import Filter from './Filter';
import SearchBar from './SearchBar';
import colors from 'colors';
import { Space } from 'antd';
import { InvoiceFilters } from 'types/InvoiceFilters';

const Container = styled.div<{ $hasBulkActions: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.$hasBulkActions ? 'space-between' : 'flex-end'};
  padding: 10px 20px;
  background: ${colors.greys100};
`;

type Props = {
  filters?: InvoiceFilters;
  bulkActions?: React.ReactNode;
  hideFilter?: boolean;
  hideSearch?: boolean;
  isAddButtonDisabled?: boolean;
  search?: React.ReactNode;
  showAddButton?: boolean;
  type: 'BILL' | 'INVOICE';
};

const TableActions: React.FC<Props> = ({
  filters,
  bulkActions,
  hideFilter,
  hideSearch,
  isAddButtonDisabled,
  search,
  showAddButton,
  type,
}) => {
  return (
    <Container $hasBulkActions={!!bulkActions}>
      <Space size={16}>{bulkActions}</Space>

      <Space size={16}>
        {!hideFilter && !!filters && <Filter filters={filters} type={type} />}
        {!hideSearch && <SearchBar entity={filters?.entity} type={type} />}
        {!!search && search}
        <CreateButtons
          type={type}
          isAddButtonDisabled={isAddButtonDisabled}
          showAddButton={showAddButton}
        />
      </Space>
    </Container>
  );
};

export default TableActions;
