import React, { FC, useState, useEffect } from 'react';
import api from 'api';
import styled from 'styled-components';
import colors from 'colors';
import SupplierInfo from './SupplierInfo';
import CreditInfo from './CreditInfo';
import DistributeButton from './DistributeButton';
import Table from './Table';
import EditSection from './EditSelection';

import { CreditAmount, ModalHeader, SupplierReference } from 'components';
import CreditNoteDetails from 'components/CreditNoteDetails';
import { processError } from 'utils';
import { Modal, Button, Typography, Alert, notification } from 'antd';
import { PayablesSummaryDetailsRecord } from 'types/PayablesSummary';
import { Return } from 'assets/icons';
import CreditNote from 'types/CreditNote';
import { InvoiceItem } from 'types/Invoice';

const { Text } = Typography;
const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding-top: 24px !important;
  }
  .cancel-button {
    border-color: ${colors.blues.dark};
    color: ${colors.blues.dark};
    min-width: 80px;
    :hover {
      background: ${colors.blues.lighter};
    }
    :active {
      background: #d6efff;
    }
  }
  .ant-btn-primary:disabled {
    color: #fff;
    background: ${colors.blues.dark};
    border-color: ${colors.blues.dark};
    opacity: 0.4;
    cursor: auto;
  }
`;

const StyledAlert = styled(Alert)<{ $showError: boolean }>`
  visibility: ${({ $showError }) => ($showError ? 'inherit' : 'hidden')};
  padding: 0.5rem !important;
  margin-top: 10px;
  .ant-alert-message {
    font-size: 16px !important;
    margin-bottom: 0px !important;
  }
`;
const EditSelectionButton = styled(Button)`
  color: ${colors.blues.dark};
  &:hover {
    background: ${colors.blues.lighter};
    border-color: ${colors.greys300};
  }
  height: 38px;
`;
const ErrorText = styled(Text)`
  font-size: 14px;
  line-height: 20px;
`;

const ReturnImg = styled.img`
  width: 12.67px;
  height: 9.33px;
`;
const ViewAllocations = styled.div`
  margin-left: 34px;
  margin-top: 10px;
`;
const LinkText = styled(Text)`
  color: ${colors.primary};
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
`;

const ErrorContainer = styled.div`
  height: 22px;
  overflow-y: scroll;
`;

export type AllocationType = {
  invoiceId: string;
  allocatedAmount: number | string;
};

export type Credit = AllocationType & {
  amountDue: number;
  invoiceNumber: string;
};

type Props = {
  closeModal: () => void;
  creditNote: PayablesSummaryDetailsRecord;
  fromTime: number;
  isOpen: boolean;
  refreshTableData: () => void;
  supplierName: string;
  toTime: number;
};

const AllocateCreditNote: FC<Props> = ({
  closeModal,
  creditNote,
  fromTime,
  isOpen,
  refreshTableData,
  supplierName,
  toTime,
}) => {
  const [completeCreditNote, setCompleteCreditNote] =
    useState<CreditNote | null>(null);
  const [credits, setCredits] = useState<Credit[]>([]);
  const [equalDistribute, setEqualDistribute] = useState(false);
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [newCreditList, setNewCreditList] = useState<Credit[]>([]);
  const [showAllocations, setShowAllocations] = useState(false);
  const [showEditSelection, setShowEditSelection] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const loadData = (invoices: InvoiceItem[]) => {
    let creditList: Credit[] = [];
    invoices.forEach((element) => {
      if (element.invoice.total && element.invoice.discountedTotal) {
        creditList.push({
          invoiceId: element.invoice.id,
          amountDue: element.invoice.discountedTotal,
          allocatedAmount: '0.00',
          invoiceNumber: element.invoice.billNumber,
        });
      }
    });
    setCredits(creditList);
    setNewCreditList(creditList);
    setLoading(false);
  };
  useEffect(() => {
    const urlParams = `?type=BILL&issuedByCompanyName=${encodeURIComponent(
      supplierName
    )}&size=3&sort=discountedTotal,desc&status=ALL_ELIGIBLE&dueDate=${fromTime}-${toTime}:range`;
    api.invoices
      .filter({ url: urlParams })
      .then((results) => {
        if (creditNote.status === 'PARTIALLY_ALLOCATED') {
          api.creditNotes
            .get(creditNote.id)
            .then((data) => {
              setCompleteCreditNote(data);
              loadData(results.invoices);
            })
            .catch((err) => {
              const { message } = processError(err);
              notification.error({ message });
              setLoading(false);
            });
        } else {
          loadData(results.invoices);
        }
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({ message });
        setLoading(false);
      });
  }, [creditNote, fromTime, supplierName, toTime]);

  const distributeCreditEqually = () => {
    if (!equalDistribute && credits.length > 0) {
      setEqualDistribute(true);
      setInputErrors([]);

      let totalRemaining = creditNote.totalEligible;

      // Clone credits so we can reset them correctly if required
      let newCredits: Credit[] = [];
      credits.forEach((credit) => {
        newCredits.push({ ...credit, allocatedAmount: 0 });
      });

      let processingInvoices = [...newCredits];
      while (totalRemaining > 0 && processingInvoices.length > 0) {
        let allocationAmount = round(
          totalRemaining / processingInvoices.length - 0.005
        ); // Round down not up
        if (allocationAmount < 0.01 && totalRemaining > 0) {
          allocationAmount = 0.01;
        }

        let newProcessingInvoices = [];
        for (let invoice of processingInvoices) {
          let actualAllocationAmount = allocationAmount;
          if (
            invoice.amountDue - Number(invoice.allocatedAmount) <
            allocationAmount
          ) {
            actualAllocationAmount =
              invoice.amountDue - Number(invoice.allocatedAmount);
          }
          invoice.allocatedAmount = round(
            Number(invoice.allocatedAmount) + actualAllocationAmount
          );
          totalRemaining = round(totalRemaining - actualAllocationAmount);

          if (invoice.amountDue !== Number(invoice.allocatedAmount)) {
            newProcessingInvoices.push(invoice);
          }

          if (totalRemaining === 0)
            // Need this because we want to distribute any remaining cents that are left as well
            break;
        }

        processingInvoices = newProcessingInvoices;
      }

      setCredits([...newCredits]);
    }
  };

  const round = (value: Number) => {
    return Number((Math.round(Number(value) * 100) / 100).toFixed(2));
  };

  const resetDistribution = () => {
    setInputErrors([]);
    setEqualDistribute(false);

    setCredits(newCreditList);
  };

  const hideModal = () => {
    closeModal();
  };

  const getCreditApplied = (rounded?: boolean) => {
    const appliedCredit = credits.reduce((total, item) => {
      if (item.allocatedAmount && Number(item.allocatedAmount) > 0) {
        return total + parseFloat(`${item.allocatedAmount}`);
      }
      return total;
    }, 0.0);
    if (rounded && appliedCredit > creditNote.total) {
      return creditNote.total;
    } else {
      const totalApplied =
        creditNote.total - creditNote.totalEligible + appliedCredit;
      return Number(totalApplied.toFixed(2));
    }
  };

  const getCreditRemaining = () => {
    const total = completeCreditNote
      ? completeCreditNote.total
      : creditNote.total;
    return total - getCreditApplied();
  };

  const onCreditChange = (invoiceId: string, credit: number | string) => {
    setInputErrors([]);
    let updatedErrors: string[] = [];
    if (equalDistribute) {
      setEqualDistribute(false);
    }
    if (Number(credit) < 0) {
      updatedErrors.push('Invalid credit input');
    }

    const updatedCredits = credits.map((item) => {
      if (item.invoiceId === invoiceId) {
        if (item.amountDue < credit) {
          updatedErrors.push('Allocated amount exceeds invoice amount');
        }
        return {
          ...item,
          allocatedAmount: credit,
        };
      } else {
        if (Number(item.allocatedAmount) < 0) {
          updatedErrors.push('Invalid credit input');
        } else if (Number(item.allocatedAmount) > Number(item.amountDue)) {
          updatedErrors.push('Allocated amount exceeds invoice amount');
        }
        return item;
      }
    });
    updatedErrors = Array.from(new Set(updatedErrors));
    setInputErrors(updatedErrors);
    setCredits(updatedCredits);
  };

  const onBlurFormat = (invoiceId: string) => {
    const updatedCredits = credits.map((item) => {
      if (item.invoiceId === invoiceId) {
        const numCredit = item.allocatedAmount
          ? parseFloat(item.allocatedAmount.toString()).toFixed(2)
          : '0.00';

        return { ...item, allocatedAmount: numCredit };
      } else {
        return item;
      }
    });
    setCredits(updatedCredits);
  };

  const onClickEditSelection = () => {
    const updatedCredits = newCreditList.map((item) => {
      return {
        ...item,
        allocatedAmount: '0.00',
      };
    });
    setNewCreditList(updatedCredits);
    setShowEditSelection(true);
    setInputErrors([]);
  };

  const hideEditSelection = () => {
    setShowEditSelection(false);
    setNewCreditList(credits);
  };

  const onSubmit = () => {
    if (showEditSelection) {
      setCredits(newCreditList);
      setShowEditSelection(false);
      setEqualDistribute(false);
    } else {
      setSubmitting(true);
      const allocations: AllocationType[] = [];
      if (
        creditNote.status === 'PARTIALLY_ALLOCATED' &&
        completeCreditNote?.invoiceAllocations
      ) {
        allocations.push(...completeCreditNote.invoiceAllocations);
      }

      credits.forEach((item) => {
        if (parseFloat(`${item.allocatedAmount}`) !== 0) {
          allocations.push({
            allocatedAmount: item.allocatedAmount,
            invoiceId: item.invoiceId,
          });
        }
      });

      const payload = allocations.map((allocation) => ({
        invoiceId: allocation.invoiceId,
        allocatedAmount: Number(allocation.allocatedAmount),
      }));

      api.creditNotes
        .allocateCredit({ id: creditNote.id, payload })
        .then(() => {
          const message = 'Credit allocation successful';
          notification.success({ message });
          refreshTableData();
          hideModal();
        })
        .catch((err) => {
          const { message } = processError(err);
          notification.error({ message });
        })
        .finally(() => setSubmitting(false));
    }
  };
  const onCancel = () => {
    if (showAllocations) {
      setShowAllocations(false);
    } else if (showEditSelection) {
      hideEditSelection();
    } else {
      hideModal();
    }
  };
  const getErrorsLength = () => {
    let errorsLength = inputErrors.length;
    if (getCreditRemaining() < 0) {
      errorsLength++;
    }
    return errorsLength;
  };

  let modalTitle = `Allocate Credit Note #${
    completeCreditNote
      ? completeCreditNote.creditNumber
      : creditNote.recordNumber
  }`;
  if (showEditSelection) {
    modalTitle = 'Edit Selection';
  } else if (showAllocations) {
    modalTitle = `Credit Note #${
      completeCreditNote
        ? completeCreditNote.creditNumber
        : creditNote.recordNumber
    }`;
  }

  return (
    <StyledModal
      title={<ModalHeader title={modalTitle} onClose={onCancel} />}
      closable={false}
      visible={isOpen}
      onCancel={onCancel}
      onOk={onSubmit}
      okText={showEditSelection ? 'Save Changes' : 'Allocate Credit'}
      style={{ top: 30 }}
      bodyStyle={{ paddingBottom: 0, paddingTop: 0, height: 690 }}
      okButtonProps={{
        loading: submitting,
        disabled:
          (getCreditApplied(true) === 0.0 ||
            getCreditApplied(true) ===
              creditNote.total - creditNote.totalEligible ||
            inputErrors.length > 0 ||
            getCreditRemaining() < 0) &&
          !showEditSelection,
        style: { display: showAllocations ? 'none' : 'initial' },
      }}
      cancelButtonProps={{
        type: 'text',
        className: 'cancel-button',
      }}
      cancelText={showAllocations ? 'Back' : 'Cancel'}
    >
      {showEditSelection ? (
        <EditSection
          creditList={newCreditList}
          fromTime={fromTime}
          setNewCreditList={setNewCreditList}
          supplierName={supplierName}
          toTime={toTime}
          prevAllocations={completeCreditNote?.invoiceAllocations}
        />
      ) : showAllocations && completeCreditNote ? (
        <CreditNoteDetails
          invoiceType="BILL"
          creditNote={completeCreditNote}
          scrollHeight={260}
        />
      ) : (
        <>
          <SupplierInfo name={supplierName} />
          <SupplierReference
            supplierReference={
              completeCreditNote
                ? completeCreditNote.supplierReference
                : creditNote.supplierReference
            }
            creditNoteId={creditNote.id}
            modificationTime={
              completeCreditNote
                ? completeCreditNote.modificationTime
                : creditNote.modificationTime
            }
            setCreditNote={setCompleteCreditNote}
            refreshTableData={refreshTableData}
            isEditable={creditNote.status === 'UNALLOCATED'}
          />
          <CreditAmount
            totalAmount={
              completeCreditNote ? completeCreditNote.total : creditNote.total
            }
            creditNoteId={creditNote.id}
            modificationTime={
              completeCreditNote
                ? completeCreditNote.modificationTime
                : creditNote.modificationTime
            }
            setCreditNote={setCompleteCreditNote}
            refreshTableData={refreshTableData}
            isEditable={creditNote.status === 'UNALLOCATED'}
          />
          <CreditInfo label="Credit Remaining" amount={getCreditRemaining()} />
          <DistributeButton
            distributeCreditEqually={distributeCreditEqually}
            resetDistribution={resetDistribution}
            equalDistribute={equalDistribute}
          />
          <Table
            dataSource={credits}
            onCreditChange={onCreditChange}
            onBlurFormat={onBlurFormat}
            loading={loading}
            error={getCreditRemaining() < 0}
          />
          <EditSelectionButton
            type="dashed"
            block
            onClick={onClickEditSelection}
          >
            Edit selection
          </EditSelectionButton>

          <CreditInfo
            label="Credit Applied"
            amount={getCreditApplied(true)}
            background="#F6F2FF"
          />
          {creditNote.status === 'PARTIALLY_ALLOCATED' && (
            <ViewAllocations>
              <ReturnImg src={Return} alt="" />
              <LinkText onClick={() => setShowAllocations(true)}>
                View allocations
              </LinkText>
            </ViewAllocations>
          )}

          <StyledAlert
            message=""
            type="error"
            $showError={getErrorsLength() > 0}
            description={
              <ErrorContainer>
                {inputErrors.map((error, index) => (
                  <React.Fragment key={index + 'error'}>
                    <ErrorText>{error}</ErrorText>
                    <br />
                  </React.Fragment>
                ))}
                {getCreditRemaining() < 0 && (
                  <ErrorText>Amount exceeds available credit</ErrorText>
                )}
              </ErrorContainer>
            }
          />
        </>
      )}
    </StyledModal>
  );
};

export default AllocateCreditNote;
