import React from 'react';
import { Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import colors from 'colors';
import spacing from 'styles/layout/spacing';

const { Text } = Typography;

const Container = styled.div`
  margin-bottom: ${spacing.gutter.lg};
  position: relative;
`;

const Error = styled(Text)`
  margin-top: 5px;
  display: block;
`;
interface LabelProps {
  readonly sublabel: boolean;
}
const Label = styled.p<LabelProps>`
  color: ${colors.text};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: ${(props) => (props.sublabel ? 0 : spacing.gutter.xs)};
`;

const Sublabel = styled.p`
  color: ${colors.text};
  font-size: 14px;
  margin-bottom: ${spacing.gutter.sm};
`;

const HelpIcon = styled(InfoCircleOutlined)`
  font-size: 14px;
  color: ${colors.greys400};
  margin-left: ${spacing.gutter.xs};
`;

type Props = {
  className?: string;
  error?: string | boolean;
  label?: string;
  sublabel?: string;
  helpText?: string;
  style?: React.CSSProperties;
};

const InputItem: React.FC<Props> = ({
  children,
  className,
  error,
  label,
  sublabel,
  helpText,
  style,
}) => (
  <Container className={`input-item ${className || ''}`} style={style}>
    {!!label && (
      <Label className="input-item__label" sublabel={!!sublabel}>
        {label}
        {!!helpText && (
          <Tooltip title={helpText} placement="right">
            <HelpIcon />
          </Tooltip>
        )}
      </Label>
    )}
    {!!sublabel && <Sublabel>{sublabel}</Sublabel>}
    {children}
    {!!error && <Error type="danger">{error}</Error>}
  </Container>
);

export default InputItem;
