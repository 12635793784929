import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes';

const InputField = styled.input`
  border: ${(props) =>
    props.fromCreateInvoice
      ? '0px'
      : `1px solid ${props.error ? colors.error : colors.greys300}`};
  font-size: 14px;
  width: 100%;
  height: ${(props) => (props.fromCreateInvoice ? '34px' : '36px')};
  background: ${(props) => (props.disabled ? colors.greys100 : '#FFF')};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: ${(props) =>
    props.fromCreateInvoice ? '0px' : props.last ? '20px' : '19px'};
  color: ${colors.greys900};
  ::placeholder {
    color: ${colors.greys600};
  }
  :hover {
    border-color: ${(props) => (props.error ? '#c90a39' : '#829ca9')};
  }
  transition-property: border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  :focus {
    border-color: ${(props) => (props.error ? 'red' : '#00a2dd')};
  }
`;

const Label = styled.label`
  color: ${colors.greys700};
  font-size: 14px;
`;

const Input = (props) => {
  const {
    type,
    placeholder,
    value,
    label,
    onChange,
    error,
    required,
    onFocus,
    halfWidth,
    last,
    className,
    disabled,
    inputProps,
    onBlur,
    shouldReturnEvent,
  } = props;

  const Wrapper = label ? Label : React.Fragment;

  return (
    <Wrapper>
      {!!label && label}
      <InputField
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={
          disabled
            ? () => {}
            : (e) => onChange(shouldReturnEvent ? e : e.target.value)
        }
        error={error}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        halfWidth={halfWidth}
        last={last}
        className={className}
        disabled={disabled}
        {...inputProps}
        size="1"
      />
    </Wrapper>
  );
};

export default Input;
