import React from 'react';
import { Button, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

type Props = {
  onClickSubmit: () => void;
  onClickCancel: () => void;
};

export const FormButtons: React.FC<Props> = ({
  onClickSubmit,
  onClickCancel,
}) => {
  return (
    <Space>
      <Button
        className="save-add-row-button"
        type="primary"
        shape="circle"
        size="small"
        icon={<CheckOutlined />}
        onClick={onClickSubmit}
      />
      <Button
        className="close-add-row-button"
        type="ghost"
        shape="circle"
        size="small"
        icon={<CloseOutlined />}
        onClick={onClickCancel}
      />
    </Space>
  );
};
