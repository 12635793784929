import React from 'react';
import styled from 'styled-components/macro';
import AccountDropDown from './AccountDropDown';
import { useSelector } from 'react-redux';
import { Dropdown, Typography } from 'antd';
import {
  Account as AccountIcon,
  ChevronDown as ChevronIcon,
} from 'assets/icons';

const Box = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const DropdownTrigger: React.FC<{ isDesktop: boolean }> = ({ isDesktop }) => {
  const user = useSelector((store: any) => store.auth.user);
  const { company } = user;

  let title = 'Unknown User';
  let isCompanyVerified = false;
  const isAdmin = user.roles && user.roles.includes('ROLE_ADMIN');
  if (isAdmin) {
    title = 'Admin';
  }
  if (company && company.company) {
    title = company.company.companyName || user.username;
    isCompanyVerified = company.company.verificationStatus === 'VERIFIED';
  } else {
    title = user.username;
  }

  return (
    <Dropdown
      overlay={() => (
        <AccountDropDown
          companyName={
            user.roles.includes('ROLE_ADMIN')
              ? 'Admin'
              : company.company && company.company.companyName
          }
          isCompanyVerified={isCompanyVerified}
        />
      )}
      trigger={['click']}
      css={`
        margin-right: ${!isDesktop ? '1.5em' : undefined};
      `}
    >
      <Box onClick={(e) => e.preventDefault()}>
        <AccountIcon
          css={`
            margin-right: ${isDesktop ? '0.5em' : undefined};
          `}
        />
        {isDesktop && (
          <Box>
            <Typography.Text
              css={`
                font-size: 14px;
                margin: 0.2em 0.3em 0 0;
              `}
            >
              {title}
            </Typography.Text>
            <ChevronIcon />
          </Box>
        )}
      </Box>
    </Dropdown>
  );
};

export default DropdownTrigger;
