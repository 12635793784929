import React from 'react';
import AuthContainer from 'components/AuthContainer';
import AuthCard from 'components/AuthCard';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const Container = styled.div`
  align-self: center;
`;

const ResetPasswordSuccess = () => {
  return (
    <AuthContainer>
      <AuthCard
        message="Success"
        subMessage="You have successfully changed the password"
      >
        <Container>
          <Button type="primary" size="large">
            <Link to="/login">Go to login page</Link>
          </Button>
        </Container>
      </AuthCard>
    </AuthContainer>
  );
};

export default ResetPasswordSuccess;
