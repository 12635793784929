import React, { useEffect } from 'react';
import {
  getPurchaserOffersAvailable,
  getPurchaserOffersOffered,
} from 'store/offers';
import { setBackgroundCheckPurchaserOffer } from 'store/ui';
import { useDispatch, useSelector } from 'react-redux';
type BackgroundCheckPurhcaserOffer = {
  shouldCheck: boolean;
  direction: null | 'AVAILABLE' | 'OFFERED';
};

const timeoutToCallApi = 2000;

const BackgroundCheckPurhcaserOffer: React.FC = () => {
  const backgroundCheckPurchaserOffer: BackgroundCheckPurhcaserOffer = useSelector(
    (state: any) => state.ui.backgroundCheckPurchaserOffer
  );
  const { shouldCheck, direction } = backgroundCheckPurchaserOffer;

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldCheck) {
      setTimeout(() => {
        if (direction === 'AVAILABLE') {
          dispatch(getPurchaserOffersAvailable());
        } else if (direction === 'OFFERED') {
          dispatch(getPurchaserOffersOffered());
        }
        dispatch(
          setBackgroundCheckPurchaserOffer({
            shouldCheck: false,
            direction: null,
          })
        );
      }, timeoutToCallApi);
    }
  }, [shouldCheck, direction, dispatch]);
  return null;
};

export default BackgroundCheckPurhcaserOffer;
