import React from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

import { colors } from 'themes';

import Container from './Container';
import Icon from './Icon';

const StyledInput = styled.input`
  width: 250px;
  height: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  ::placeholder {
    color: ${colors.greys600};
  }
  background: transparent;
  border: 0;
`;

const StyledIcon = styled(CloseOutlined)`
  cursor: pointer;
  color: ${colors.greys700};
  padding: 2px;
  :hover {
    background-color: ${colors.greys200} !important;
    border-radius: 15px;
  }
  transition-property: opacity !important;
  transition-duration: 0.25s !important;
  transition-timing-function: ease-in-out !important;
  transition-delay: 0s !important;
`;

const Input = ({ inputProps }) => {
  return (
    <Container className="input">
      <Icon />
      <StyledInput {...inputProps} />
      {inputProps.value && (
        <StyledIcon
          onClick={() => {
            inputProps.value && inputProps.clearSearchValue();
          }}
        />
      )}
    </Container>
  );
};

export default Input;
