import axios from 'axios';

const sendInvite = (payload: {
  email: string;
  companyId: string;
  note?: string;
  type: 'BILL' | 'INVOICE';
}): Promise<boolean> => {
  return new Promise(function (resolve, reject) {
    axios
      .post('/invite', payload)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  sendInvite,
};
