import React from 'react';
import { useDispatch } from 'react-redux';
import { setBackgroundCheckPurchaserOffer, setRefreshData } from 'store/ui';
import { useInvoiceTable } from './hooks';
import {
  BillWithCreditNote,
  InvoicePagination,
  PaidInvoice,
  RelayTable,
  TableActions,
  BulkIncludeExcludeButton,
} from 'components';
import { BulkDeleteButton, EditContactEmail, PayInvoice } from './components';
import { InvoiceFilters } from 'types/InvoiceFilters';
import { useInvoiceFilters, useTableSelection } from 'hooks';

type Props = {
  filters?: InvoiceFilters;
  hideFilter?: boolean;
  hideSearch?: boolean;
};

const InvoiceTable: React.FC<Props> = ({ hideFilter, hideSearch }) => {
  const filters = useInvoiceFilters();

  const dispatch = useDispatch();

  const {
    columns,
    rows,
    isLoading,
    isPending,
    isPaidInvoiceModalVisible,
    selectedInvoiceId,
    selectedInvoice,
    selectedRow,
    selectedBillForDetails,
    setPaidInvoiceModalVisible,
    setSelectedInvoiceId,
    setSelectedInvoice,
    setSelectedRow,
    setSelectedBillForDetails,
    setSupplierOfferToExecute,
    setVisibleEditContactEmail,
    supplierOfferToExecute,
    visibleEditContactEmail,
  } = useInvoiceTable();

  const { clearRowSelection, rowSelection } = useTableSelection();

  const showBulkActions = rowSelection.selectedRowKeys.length > 0;

  const selectedIds = rowSelection.selectedRowKeys.map((key) => String(key));

  const selectedRows = rows.filter((row) => selectedIds.includes(row.key));

  const shouldShowBulkIncludeButton = selectedRows.every(
    (row) => row.isPurchaserOffersDisabled === false
  );

  const refreshData = () => {
    dispatch(setRefreshData(true));
  };

  const onSuccessBulkDelete = () => {
    refreshData();
    dispatch(
      setBackgroundCheckPurchaserOffer({
        shouldCheck: true,
        direction: 'AVAILABLE',
      })
    );
  };

  const onSuccessBulkIncludeExclude = () => {
    clearRowSelection();
    refreshData();
  };

  const hidePayInvoice = () => {
    setSelectedRow(null);
    setSupplierOfferToExecute(null);
  };
  const onSuccessPayInvoice = () => {
    if (selectedRow) {
      setSelectedInvoiceId(selectedRow.key);
      setPaidInvoiceModalVisible(true);
    }

    setSelectedRow(null);
    setSupplierOfferToExecute(null);
  };

  const hidepaidInvoiceModal = () => {
    setSelectedInvoiceId(null);
    setPaidInvoiceModalVisible(false);
  };
  const clearBillSelectionForDetails = () => setSelectedBillForDetails('');

  return (
    <>
      <TableActions
        bulkActions={
          showBulkActions
            ? [
                shouldShowBulkIncludeButton && (
                  <BulkIncludeExcludeButton
                    action="include"
                    onSuccess={onSuccessBulkIncludeExclude}
                    selectedRows={selectedRows}
                  />
                ),
                <BulkIncludeExcludeButton
                  action="exclude"
                  onSuccess={onSuccessBulkIncludeExclude}
                  selectedRows={selectedRows}
                />,
                <BulkDeleteButton
                  selectedInvoiceIDs={selectedIds}
                  invoiceType="BILL"
                  onSuccess={onSuccessBulkDelete}
                />,
              ]
            : undefined
        }
        filters={filters}
        hideFilter={hideFilter}
        hideSearch={hideSearch}
        type="BILL"
      />

      <RelayTable
        columns={columns}
        dataSource={rows}
        rowSelection={rowSelection}
        loading={isLoading || isPending}
        pagination={false}
        $shouldNotHeaderApplyFontWeight={true}
      />
      <InvoicePagination type="BILL" />
      {selectedRow && supplierOfferToExecute && (
        <PayInvoice
          invoice={selectedRow}
          supplierOffer={supplierOfferToExecute}
          hideModal={hidePayInvoice}
          amountDue={selectedRow.unformattedAmountDue}
          onSuccessPayInvoice={onSuccessPayInvoice}
        />
      )}
      {selectedInvoiceId && isPaidInvoiceModalVisible && (
        <PaidInvoice
          invoiceId={selectedInvoiceId}
          invoiceType="BILL"
          hideModal={hidepaidInvoiceModal}
        />
      )}
      {visibleEditContactEmail && !!selectedInvoice && (
        <EditContactEmail
          visibleEditModal={visibleEditContactEmail}
          toggleEditModal={() => {
            setVisibleEditContactEmail(false);
            setSelectedInvoice(null);
          }}
          invoice={selectedInvoice}
          invoiceType="BILL"
        />
      )}
      {!!selectedBillForDetails && (
        <BillWithCreditNote
          invoiceId={selectedBillForDetails}
          isOpen={!!selectedBillForDetails}
          closeModal={clearBillSelectionForDetails}
          type="BILL"
        />
      )}
    </>
  );
};

export default InvoiceTable;
