import React from 'react';
import { GlCode } from './GlCode';
import { ProcessingQueue } from './ProcessingQueue';
import { FilterDialogContainer } from 'components';
import { PaymentTerms } from './PaymentTerms';
import { ScheduledPayments } from './ScheduledPayments';

const FilterDialog: React.FC<{
  glCodeProps: {
    options: { accountCode: string; accountName: string }[];
    value: string[] | null;
    isChecked: boolean;
    handleChange: (value: string[]) => void;
    handleCheck: (flag: boolean) => void;
  };
  processingQueueProps: {
    value: string | null;
    handleChange: (value: string) => void;
    handleCheck: (flag: boolean) => void;
    isChecked: boolean;
  };
  paymentTermsProps: {
    value: string | null;
    handleChange: (value: string) => void;
    handleCheck: (flag: boolean) => void;
    isChecked: boolean;
  };
  scheduledPaymentsProps: {
    value: string | null;
    handleChange: (value: string) => void;
    handleCheck: (flag: boolean) => void;
    isChecked: boolean;
  };
  handleDone: () => void;
  handleReset: () => void;
  hideDropdown: () => void;
}> = ({
  glCodeProps,
  processingQueueProps,
  paymentTermsProps,
  scheduledPaymentsProps,
  handleDone,
  handleReset,
}) => {
  return (
    <FilterDialogContainer handleDone={handleDone} handleReset={handleReset}>
      <PaymentTerms {...paymentTermsProps} />
      <ScheduledPayments {...scheduledPaymentsProps} />
      <GlCode {...glCodeProps} />
      <ProcessingQueue {...processingQueueProps} />
    </FilterDialogContainer>
  );
};

export default FilterDialog;
