import React, { FC } from 'react';
import styled from 'styled-components/macro';
import colors from 'colors';
import AmountStrip from 'components/AmountStrip';
import Invoice from 'types/Invoice';
import { Typography, Col, Row, Divider, Empty } from 'antd';
import { Return } from 'assets/icons';
import { AllocationType } from 'types/CreditNote';
import { ModalName } from './BillWithCreditNote';
import { formatDateForDisplay, formatCurrency } from 'utils';

const { Text } = Typography;

const Header = styled(Text)`
  font-size: 10px;
  font-weight: 700;
  color: ${colors.greys700};
`;
const HeaderRow = styled(Row)`
  background: ${colors.greys100};
  padding-left: 16px;
`;
const ContentRow = styled(Row)`
  padding: 10px;
  padding-left: 16px;
  padding-right: 6px;
`;

const Details = styled(Text)`
  font-size: 14px;
  color: ${colors.greys900};
`;

const StyledDivider = styled(Divider)`
  margin-top: 0px;
  margin-bottom: 32px;
`;
const WiderAmountStrip = styled(AmountStrip)`
  padding: 9px 16px;
  margin-top: 8px;
`;

const StyledAmountStrip = styled(WiderAmountStrip)<{ $labelColor?: string }>`
  .amount-strip-label {
    color: ${({ $labelColor }) =>
      $labelColor ? $labelColor : colors.greys900};
  }
  .amount-strip-value {
    color: ${colors.greys900};
    font-weight: normal;
  }
`;

const ReturnImg = styled.img`
  width: 12.67px;
  height: 9.33px;
`;
const ViewDetails = styled.div`
  margin-left: 34px;
  margin-top: 10px;
`;

// type definitions
export type InvoiceAllocation = AllocationType & {
  amountDue: number;
  invoiceNumber: string;
};

type Props = {
  invoice: Invoice | null;
  onViewClick: (value: ModalName) => void;
};

const BillDetails: FC<Props> = ({ invoice, onViewClick }) => {
  return invoice ? (
    <>
      <HeaderRow>
        <Col span={8}>
          <Header>INVOICE #</Header>
        </Col>
        <Col span={10}>
          <Header>SUPPLIER</Header>
        </Col>
        <Col span={6}>
          <Header>DUE DATE</Header>
        </Col>
      </HeaderRow>
      <ContentRow>
        <Col span={8}>
          <Details>{invoice.billNumber}</Details>
        </Col>
        <Col span={10}>
          <Details>{invoice.issuedByCompanyName}</Details>
        </Col>
        <Col span={6}>
          <Details>{formatDateForDisplay(invoice.dueDate)}</Details>
        </Col>
      </ContentRow>
      <StyledDivider />
      <StyledAmountStrip
        label="Amount Due (inc. GST)"
        value={`${formatCurrency(invoice.total)}`}
      />

      <StyledAmountStrip
        label="Credit Applied"
        value={
          invoice.creditNoteValue
            ? `-${formatCurrency(invoice.creditNoteValue)}`
            : '-'
        }
        background="#F6F2FF"
        $labelColor={colors.greys700}
      />
      <ViewDetails>
        <ReturnImg src={Return} alt="" />
        <Text
          css={`
            color: ${colors.primary};
            margin-left: 10px;
            font-size: 14px;
            cursor: pointer;
          `}
          onClick={() => onViewClick('CREDIT_ALLOCATIONS')}
        >
          View details
        </Text>
      </ViewDetails>
      <StyledDivider
        css={`
          margin-top: 10px;
          margin-bottom: 8px;
        `}
      />
      <WiderAmountStrip
        label="Balance Payable (inc. GST)"
        value={
          invoice.discountedTotal
            ? `${formatCurrency(invoice.discountedTotal)}`
            : '-'
        }
        background={colors.blues.lighter}
      />
    </>
  ) : (
    <Empty />
  );
};

export default BillDetails;
