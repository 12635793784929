import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Card, Statistic, StatisticProps, Typography } from 'antd';

const { Text } = Typography;

const StyledCard = styled(Card)<{ $active?: boolean }>`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .sub-text {
    margin-top: auto;
  }
  border-color: ${({ $active }) =>
    $active ? colors.primary + '5e' : undefined};
`;

type Props = {
  title: string;
  subText?: string;
  className?: string;
  active?: boolean;
};

const KpiStatistic: React.FC<Props & StatisticProps> = ({
  title,
  subText,
  value,
  className,
  prefix,
  suffix,
  active,
}) => {
  const valueStyles: React.CSSProperties = {
    fontSize: '24px',
    fontWeight: 'bold',
  };
  return (
    <StyledCard className={className} title={title} $active={active}>
      <Statistic
        value={value}
        valueStyle={valueStyles}
        prefix={prefix}
        suffix={suffix}
      />
      {subText && <Text className="sub-text">{subText}</Text>}
    </StyledCard>
  );
};

export default KpiStatistic;
