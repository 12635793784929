import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';

import {
  InvoiceDataFormatter,
  DeleteInvoices,
  InviteInvoice,
} from 'components/Table/components';
import GetPaidModal from 'components/Table/GetPaidModal';
import {
  CompanyStatusDot,
  InvoicePagination,
  PaidInvoice,
  RelayTable,
  TableCellWithStatusDot,
} from 'components';
import { useInvoiceFilters, useTableSelection } from 'hooks';
import { TableActions } from 'components';
import { setPaymentsAvailableModalVisibility } from 'store/ui';
import processError from 'utils/processError';

import { xeroLogo, myobLogo } from 'assets/images';
import BetaBadge from 'components/BetaBadge';

import { BillWithCreditNote, SupplierOfferModal } from 'components';

import { BASE_URL } from 'config';
import { notification, Button, Tooltip, Tag } from 'antd';
import PaymentsAvailableModal from './PaymentsAvailableModal';
import { MailOutlined } from '@ant-design/icons';
import colors from 'colors';
import Text from 'antd/lib/typography/Text';
import { formatCurrency, isInvitedByLoggedUserCompany } from 'utils';
import moment from 'moment';
import Invoice from 'types/Invoice';
import featureFlags from 'config/featureFlags';
import { InvoiceMetaData } from './InvoiceMetaData';
import { StatusDot } from 'ds';
import CurrencyAmount from 'components/CurrencyAmount';
import EditContactEmail from 'components/Table/Row/EditContactEmail';
import RowButton from 'components/Table/RowButton';
import spacing from 'styles/layout/spacing';
import { Header } from 'components/InvoiceTable/components';

declare global {
  interface Window {
    env: any;
  }
}

const RightAlignedCell = styled.div<{
  clickable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${spacing.gutter.xs};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
`;
const EmptyMessage = styled.p`
  display: flex;
  text-align: center;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;
const Icon = styled.img`
  width: 150px;
  height: 150px;
`;
const AccountConnectText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-top: 10px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const ButtonGroup = styled.div`
  display: flex;
  column-gap: ${spacing.gutter.sm};
  margin-right: ${spacing.gutter.sm};
  .ant-btn {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    height: 24px;
    border: 1px solid ${colors.greys500};
    span {
      font-weight: 700;
      font-size: 10px;
    }
  }
`;
const ConnectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${spacing.gutter.md};
`;
const Scope = styled.span`
  position: absolute;
  margin-top: 35px;
  font-weight: bold;
`;

const Table: React.FC<{
  data: {
    invoice: Invoice;
  }[];
  type: 'INVOICE' | 'BILL';
  isActiveAmountDueFilter: boolean;
  isActiveDateDueFilter: boolean;
  loadingInvoices: boolean;
  activeTab: string;
  isPaymentsAvailableModalVisible: boolean;
}> = ({
  data,
  type,
  isActiveAmountDueFilter,
  isActiveDateDueFilter,
  activeTab,
}) => {
  const user = useSelector((store: any) => store.auth.user);
  const loadingInvoices = useSelector(
    (store: any) => store.invoices.loadingInvoices
  );
  const isWithdrawModalVisible = useSelector(
    (store: any) => store.ui.isWithdrawModalVisible
  );
  const isPaymentsAvailableModalVisible = useSelector(
    (store: any) => store.ui.isPaymentsAvailableModalVisible
  );
  const supplierOffersOffered = useSelector(
    (state: any) => state.offers.supplierOffers.offered
  );
  const purchaserOffersOffered = useSelector(
    (state: any) => state.offers.purchaserOffers.available
  );

  const filters = useInvoiceFilters();
  const dispatch = useDispatch();

  const { rowSelection } = useTableSelection();
  const selectedIds = rowSelection.selectedRowKeys.map((key) => String(key));
  const selectedRows = data.filter((row) =>
    selectedIds.includes(row.invoice.id)
  );

  const columns = [
    {
      title: <Header title="INVOICE #" hasSort columnKey="invoiceNumber" />,
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 100,
    },
    {
      title: (
        <Header
          title="CUSTOMER"
          hasSort
          hasDot
          columnKey="issuedForCompanyName"
        />
      ),
      key: 'customerName',
      render: (record: Invoice & InvoiceMetaData) => (
        <TableCellWithStatusDot>
          <CompanyStatusDot
            companyStatus={record.owingCompany.status}
            companyVerificationStatus={record.owingCompany.verificationStatus}
          />
          <Text ellipsis={{ tooltip: true }}>
            {record.owingCompany.companyName}
          </Text>
        </TableCellWithStatusDot>
      ),
      width: 150,
    },
    {
      title: <Header title="DUE DATE" hasSort columnKey="dueDate" />,
      key: 'dueDate',
      dataIndex: 'dueDate',
      width: 100,
      align: 'right' as const,
      render: (date: number) => moment(date).format('D MMM YYYY').valueOf(),
    },
    {
      title: <Header title="AMOUNT DUE" hasSort columnKey="discountedTotal" />,
      width: 120,
      align: 'right' as const,
      key: 'amountDue',
      render: (record: Invoice & InvoiceMetaData) => {
        const creditApplied = record.total !== record.discountedTotal;
        return (
          <RightAlignedCell
            clickable={creditApplied}
            onClick={() => {
              if (creditApplied) showInvoiceDetailsModal(record.id);
            }}
          >
            {creditApplied ? (
              <Tooltip color={colors.purple} title="Credit Applied">
                <StatusDot color={colors.purple} />
                <CurrencyAmount
                  amount={record.discountedTotal}
                  style={
                    creditApplied
                      ? {
                          borderBottom: `1px dashed ${colors.purple}`,
                          cursor: 'clickable',
                        }
                      : undefined
                  }
                />
              </Tooltip>
            ) : (
              formatCurrency(record.discountedTotal)
            )}
          </RightAlignedCell>
        );
      },
    },
    {
      title: 'STATUS',
      width: 120,
      align: 'center' as const,
      render: (record: Invoice & InvoiceMetaData) => {
        let statusLabel = record.status.label;
        // display status of invited company
        if (
          record.owingCompany.status === 'INVITED' &&
          isInvitedByLoggedUserCompany(record, user.companyId)
        ) {
          statusLabel = 'Invited';
        }

        if (statusLabel === 'Payment Offered' && record.sentToSupplier) {
          statusLabel = 'Notified';
        }

        if (
          record.billStatus === 'NOT_ELIGIBLE_EXPIRED' ||
          record.invoiceStatus === 'EXPIRED'
        ) {
          statusLabel = 'Expired';
        }
        return record.status.highlighted ? (
          <Tag>{statusLabel}</Tag>
        ) : (
          statusLabel
        );
      },
    },
    {
      title: 'MAX. FEE',
      width: 100,
      align: 'right' as const,
      render: (record: Invoice & InvoiceMetaData) =>
        record.discount ? (
          <div style={{ margin: '-10px 0' }}>
            <Text strong>{formatCurrency(record.discount)}</Text>
            <br />
            {record.discountPercent && (
              <Text type="secondary">
                {record.discountPercent.toFixed(2)}%
              </Text>
            )}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: <MailOutlined />,
      width: 50,
      align: 'center' as const,
      render: (record: Invoice) =>
        record.owingCompany.status === 'INACTIVE' &&
        record.contactEmail && (
          <Tooltip placement="bottom" title={record.contactEmail}>
            <MailOutlined
              style={{ color: colors.greys500, fontSize: 10 }}
              onClick={() => setEditingEmail(record)}
            />
          </Tooltip>
        ),
    },
    {
      title: 'ACTION',
      render: (record: Invoice & InvoiceMetaData) => (
        <RowButton
          record={record}
          type={type}
          loggedUserCompanyId={user.companyId}
        />
      ),
      fixed: 'right' as const,
      align: 'center' as const,
      width: 140,
    },
  ];

  const [editingEmail, setEditingEmail] = useState<Invoice | null>(null);

  //handle visibility of invoice delete modal
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  //handle visibility of invoice invite modal
  const [visibleInviteModal, setVisibleInviteModal] = useState(false);

  //loading for xero connection
  const [loading, setLoading] = useState(false);
  // loading for myob connection
  const [loadingMyob, setLoadingMyob] = useState(false);
  const [loadingMyobAccountRight, setLoadingMyobAccountRight] = useState(false);

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [invoicesByCompany, setInvoicesByCompany] = useState({});

  const [invoiceIdToShowDetails, setInvoiceIdToShowDetails] = useState<
    string | null
  >(null);

  // To show details of paid invoice
  const [selectedPaidInvoice, setSelectedPaidInvoice] =
    useState<Invoice | null>(null);

  const [invoiceForSupplierOffer, setInvoiceForSupplierOffer] =
    useState<Invoice | null>(null);

  const showInvoiceDetailsModal = (id: string) => {
    setInvoiceIdToShowDetails(id);
  };

  const hideInvoiceDetailsModal = () => {
    setInvoiceIdToShowDetails(null);
  };

  const toggleDeleteModal = () => {
    setVisibleDeleteModal(!visibleDeleteModal);
  };

  const hidePaidInvoiceModal = () => {
    setSelectedPaidInvoice(null);
  };

  const handleHideSupplierOfferModal = () => {
    setInvoiceForSupplierOffer(null);
  };

  useEffect(() => {
    const invoicesByCompany: {
      [key: string]: {
        id: string;
        invoice: Invoice;
        discountedTotal: number;
      };
    } = {};
    data.forEach((item) => {
      if (item.invoice.invoiceStatus === 'PAYMENT_READY') {
        if (!invoicesByCompany[item.invoice.owingCompany.companyName]) {
          invoicesByCompany[item.invoice.owingCompany.companyName] = {
            id: item.invoice.owingCompany.id,
            invoice: item.invoice,
            discountedTotal: item.invoice.discountedTotal,
          };
        } else {
          invoicesByCompany[
            item.invoice.owingCompany.companyName
          ].discountedTotal += item.invoice.discountedTotal;
        }
      }
    });
    const invoices = Object.values(invoicesByCompany);
    if (
      invoices.length === 1 &&
      !selectedInvoice &&
      !isWithdrawModalVisible &&
      isPaymentsAvailableModalVisible
    ) {
      setSelectedInvoice(invoices[0].invoice);
    }
    setInvoicesByCompany(invoicesByCompany);
  }, [
    data,
    isWithdrawModalVisible,
    selectedInvoice,
    isPaymentsAvailableModalVisible,
  ]);

  const { company } = user.company;

  const connectToXero = () => {
    const { token } = user;
    setLoading(true);
    axios
      .get(`${BASE_URL}/xero/connect`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const { authUrl } = data;
        window.location.assign(authUrl);
        setLoading(false);
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({ message });
        setLoading(false);
      });
  };
  const connectToMyob = () => {
    setLoadingMyob(true);
    const MYOB_CLIENT_ID = window.env.MYOB_CLIENT_ID;
    const MYOB_REDIRECT_URI = window.env.MYOB_REDIRECT_URI;
    const MYOB_ESSENTIALS_SCOPE = window.env.MYOB_ESSENTIALS_SCOPE;
    const myobAuthurl = `https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_CLIENT_ID}&redirect_uri=${MYOB_REDIRECT_URI}&response_type=code&scope=${MYOB_ESSENTIALS_SCOPE}`;
    window.location.assign(myobAuthurl);
  };
  const connectToMyobAccountRight = () => {
    setLoadingMyobAccountRight(true);
    const MYOB_CLIENT_ID = window.env.MYOB_CLIENT_ID;
    const MYOB_REDIRECT_URI = window.env.MYOB_REDIRECT_URI;
    const MYOB_ACCOUNT_RIGHT_SCOPE = window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
    const myobAuthurl = `https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_CLIENT_ID}&redirect_uri=${MYOB_REDIRECT_URI}&response_type=code&scope=${MYOB_ACCOUNT_RIGHT_SCOPE}`;
    window.location.assign(myobAuthurl);
  };

  return (
    <div>
      <TableActions
        bulkActions={
          selectedRows.length > 0 &&
          activeTab !== 'Payment Received' &&
          activeTab !== 'Cashback Received' ? (
            <ButtonGroup>
              <Button onClick={toggleDeleteModal}>DELETE</Button>
              {featureFlags.batchInvite && (
                <Button onClick={() => setVisibleInviteModal(true)}>
                  INVITE
                </Button>
              )}
            </ButtonGroup>
          ) : null
        }
        filters={filters}
        type={type}
      />
      {data.length ? (
        <RelayTable
          dataSource={data.map((item) =>
            InvoiceDataFormatter({
              invoice: item.invoice,
              supplierOffersOffered,
              purchaserOffersOffered,
              setSelectedInvoice: () => {
                setSelectedInvoice(item.invoice);
              },
              showPaidInvoice: () => {
                setSelectedPaidInvoice(item.invoice);
              },
              showSupplierOfferModal: () => {
                setInvoiceForSupplierOffer(item.invoice);
              },
            })
          )}
          rowKey={(record) => record.id}
          pagination={false}
          columns={columns}
          loading={loadingInvoices}
          rowSelection={
            activeTab !== 'Payment Received' &&
            activeTab !== 'Cashback Received'
              ? rowSelection
              : undefined
          }
          style={{ minHeight: 250 }}
        />
      ) : company.connectedToXero ||
        company.connectedToMyobEssentials ||
        company.connectedToMyobAccountRight ||
        isActiveDateDueFilter ||
        isActiveAmountDueFilter ? ( // eslint-disable-line
        <EmptyMessage>No items to show</EmptyMessage>
      ) : null}
      {!company.connectedToXero &&
        !company.connectedToMyobEssentials &&
        !company.connectedToMyobAccountRight &&
        !data.length &&
        activeTab !== 'ALL' &&
        !loadingInvoices &&
        !featureFlags.myobConnect &&
        !featureFlags.xeroConnect &&
        !isActiveDateDueFilter &&
        !isActiveAmountDueFilter && (
          <EmptyMessage>No items to show</EmptyMessage>
        )}
      {company &&
        !company.connectedToXero &&
        !company.connectedToMyobEssentials &&
        !company.connectedToMyobAccountRight &&
        !data.length &&
        !loadingInvoices &&
        activeTab === 'ALL' &&
        !isActiveDateDueFilter &&
        !isActiveAmountDueFilter &&
        (featureFlags.myobConnect || featureFlags.xeroConnect ? (
          <ConnectionContainer>
            <AccountConnectText>
              Connect your business accounts
            </AccountConnectText>
            <Row>
              {featureFlags.xeroConnect ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 20,
                  }}
                >
                  <BetaBadge />
                  <Icon src={xeroLogo} />
                  <Button loading={loading} onClick={() => connectToXero()}>
                    Connect
                  </Button>
                </div>
              ) : null}
              {featureFlags.myobConnect && featureFlags.myobEssentials ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 20,
                  }}
                >
                  <BetaBadge />
                  <Icon src={myobLogo} />
                  <Scope>Essentials</Scope>
                  <Button loading={loadingMyob} onClick={() => connectToMyob()}>
                    Connect
                  </Button>
                </div>
              ) : null}
              {featureFlags.myobConnect && featureFlags.myobAccountRight ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 32,
                  }}
                >
                  <Icon src={myobLogo} />
                  <Scope style={{ marginTop: 30 }}>Account Right</Scope>
                  <Button
                    loading={loadingMyobAccountRight}
                    onClick={() => connectToMyobAccountRight()}
                  >
                    Connect
                  </Button>
                </div>
              ) : null}
            </Row>
          </ConnectionContainer>
        ) : (
          <EmptyMessage>No items to show</EmptyMessage>
        ))}
      {!loadingInvoices && <InvoicePagination type="INVOICE" />}
      <DeleteInvoices
        toggleDeleteInvoiceModel={toggleDeleteModal}
        visibleDeleteModal={visibleDeleteModal}
        selectedInvoiceIDs={selectedIds}
        invoiceType={type}
      />
      {selectedRows && visibleInviteModal && (
        <InviteInvoice
          toggleInviteInvoiceModel={() => setVisibleInviteModal(false)}
          visibleInviteModal={visibleInviteModal}
          selectedInvoices={selectedRows.map((item) => item.invoice)}
          companyId={company.id}
        />
      )}
      {Object.keys(invoicesByCompany).length > 1 &&
        isPaymentsAvailableModalVisible &&
        !selectedInvoice && (
          <PaymentsAvailableModal
            invoicesByCompany={invoicesByCompany}
            setSelectedInvoice={setSelectedInvoice}
            onClose={() => dispatch(setPaymentsAvailableModalVisibility(false))}
          />
        )}
      {!!selectedInvoice && (
        <GetPaidModal
          selectedInvoice={selectedInvoice}
          setSelectedInvoice={(invoice) => setSelectedInvoice(invoice)}
        />
      )}
      {!!invoiceIdToShowDetails && (
        <BillWithCreditNote
          invoiceId={invoiceIdToShowDetails}
          isOpen={!!invoiceIdToShowDetails}
          closeModal={hideInvoiceDetailsModal}
          type="INVOICE"
        />
      )}
      {!!editingEmail && (
        <EditContactEmail
          visibleEditModal={!!editingEmail}
          toggleEditModal={() => setEditingEmail(null)}
          invoice={editingEmail}
          invoiceType={type}
        />
      )}
      {!!selectedPaidInvoice && (
        <PaidInvoice
          invoice={selectedPaidInvoice}
          invoiceType="INVOICE"
          hideModal={hidePaidInvoiceModal}
        />
      )}
      <SupplierOfferModal
        invoice={invoiceForSupplierOffer}
        hideModal={handleHideSupplierOfferModal}
      />
    </div>
  );
};

export default Table;
