import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ highlighted?: boolean }>`
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ highlighted }) =>
    highlighted ? '#F6F7F8' : 'transparent'};
  margin: ${({ highlighted }) => (highlighted ? '24px 0' : '10px 0')};
`;

type Props = {
  children: React.ReactNode;
  highlighted?: boolean;
  className?: string;
};

const RowContainer: React.FC<Props> = ({
  children,
  highlighted,
  className,
}) => {
  return (
    <Container highlighted={highlighted} className={className}>
      {children}
    </Container>
  );
};

export default RowContainer;
