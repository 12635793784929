import api from 'api';
import { updateInvoice, updateBill } from './invoices';

const GET_PURCHASER_OFFERS_OFFERED = 'GET_PURCHASER_OFFERS_OFFERED';
const GET_PURCHASER_OFFERS_AVAILABLE = 'GET_PURCHASER_OFFERS_AVAILABLE';

const GET_SUPPLIER_OFFERS_OFFERED = 'GET_SUPPLIER_OFFERS_OFFERED';
const GET_SUPPLIER_OFFERS_AVAILABLE = 'GET_SUPPLIER_OFFERS_AVAILABLE';

const SHOW_EARLY_PAYMENT_OFFER = 'SHOW_EARLY_PAYMENT_OFFER';
const HIDE_EARLY_PAYMENT_OFFER = 'HIDE_EARLY_PAYMENT_OFFER';
const SET_PURCHASE_OFFER_LOADING = 'SET_PURCHASE_OFFER_LOADING';

const UPDATE_PURCHASER_OFFER_OFFERED = 'UPDATE_PURCHASER_OFFER_OFFERED';
const UPDATE_SUPPLIER_OFFER_OFFERED = 'UPDATE_SUPPLIER_OFFER_OFFERED';
const ADD_SUPPLIER_OFFER_OFFERED = 'ADD_SUPPLIER_OFFER_OFFERED';
const UPDATE_SUPPLIER_OFFER_AVAILABLE = 'UPDATE_SUPPLIER_OFFER_AVAILABLE';
// const UPDATE_PURCHASER_OFFER_AVAILABLE = 'UPDATE_PURCHASER_OFFER_AVAILABLE';
const LOGOUT = 'LOGOUT';

const initialState = {
  supplierOffers: { offered: [], available: [] },
  purchaserOffers: { offered: [], available: [] },
  isVisibleEarlyPaymentOffer: false,
  isVisibleConfirmEarlyPaymentOffer: false,
  isLoadingPurchaseOffer: true,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return initialState;
    case GET_PURCHASER_OFFERS_OFFERED:
      return {
        ...state,
        purchaserOffers: { ...state.purchaserOffers, offered: payload },
      };

    case GET_PURCHASER_OFFERS_AVAILABLE:
      return {
        ...state,
        purchaserOffers: { ...state.purchaserOffers, available: payload },
      };

    case GET_SUPPLIER_OFFERS_OFFERED:
      return {
        ...state,
        supplierOffers: { ...state.supplierOffers, offered: payload },
      };

    case GET_SUPPLIER_OFFERS_AVAILABLE:
      return {
        ...state,
        supplierOffers: { ...state.supplierOffers, available: payload },
      };

    case SHOW_EARLY_PAYMENT_OFFER:
      return {
        ...state,
        isVisibleEarlyPaymentOffer: true,
        isVisibleConfirmEarlyPaymentOffer: false,
      };

    case HIDE_EARLY_PAYMENT_OFFER:
      return {
        ...state,
        isVisibleEarlyPaymentOffer: false,
        isVisibleConfirmEarlyPaymentOffer: false,
      };
    case SET_PURCHASE_OFFER_LOADING:
      return {
        ...state,
        isLoadingPurchaseOffer: payload,
      };
    case UPDATE_PURCHASER_OFFER_OFFERED:
      return {
        ...state,
        purchaserOffers: { ...state.purchaserOffers, offered: [payload] },
      };
    case UPDATE_SUPPLIER_OFFER_OFFERED: {
      const updatedSupplierOfferOffered = state.supplierOffers.offered.map(
        (item) => {
          if (item.id === payload.id) {
            return (item = payload);
          } else {
            return item;
          }
        }
      );
      return {
        ...state,
        supplierOffers: {
          ...state.supplierOffers,
          offered: updatedSupplierOfferOffered,
        },
      };
    }
    case ADD_SUPPLIER_OFFER_OFFERED:
      return {
        ...state,
        supplierOffers: {
          ...state.supplierOffers,
          offered: [...state.supplierOffers.offered, payload],
        },
      };
    case UPDATE_SUPPLIER_OFFER_AVAILABLE: {
      const updatedSupplierOfferAvailable = state.supplierOffers.available.map(
        (item) => {
          if (item.id === payload.id) {
            return (item = payload);
          } else {
            return item;
          }
        }
      );
      return {
        ...state,
        supplierOffers: {
          ...state.supplierOffers,
          available: updatedSupplierOfferAvailable,
        },
      };
    }
    // case UPDATE_PURCHASER_OFFER_AVAILABLE: {
    //   const updatedPurchaserOfferAvailable = state.purchaserOffers.available.map(
    //     item => {
    //       if (item.id === payload.id) {
    //         return (item = payload);
    //       } else {
    //         return item;
    //       }
    //     }
    //   );
    //   return {
    //     ...state,
    //     purchaserOffers: {
    //       ...state.purchaserOffers,
    //       available: updatedPurchaserOfferAvailable
    //     }
    //   };
    // }

    default:
      return state;
  }
};

export const getPurchaserOffersOffered = () => {
  return (dispatch) => {
    dispatch(setPurchaseOfferLoading(true));
    api.purchaserOffers.get().then((offeredInvoices) => {
      dispatch({
        type: GET_PURCHASER_OFFERS_OFFERED,
        payload: offeredInvoices,
      });
      dispatch(setPurchaseOfferLoading(false));
    });
  };
};

export const getPurchaserOffersAvailable = (token) => {
  return (dispatch) => {
    api.purchaserOffers
      .getAvailable({ token })
      .then((offers) =>
        dispatch({ type: GET_PURCHASER_OFFERS_AVAILABLE, payload: offers })
      );
  };
};

export const getSupplierOffers = () => {
  return (dispatch) => {
    api.supplierOffers
      .get()
      .then((offers) =>
        dispatch({ type: GET_SUPPLIER_OFFERS_OFFERED, payload: offers })
      );
  };
};

export const getSupplierOffersAvailable = (token) => {
  return (dispatch) => {
    api.supplierOffers
      .getAvailable({ token })
      .then((offers) =>
        dispatch({ type: GET_SUPPLIER_OFFERS_AVAILABLE, payload: offers })
      );
  };
};

export const updatePurchaserOfferOffered = (offer) => ({
  type: UPDATE_PURCHASER_OFFER_OFFERED,
  payload: offer,
});
export const updateSupplierOfferOffered = (offer) => ({
  type: UPDATE_SUPPLIER_OFFER_OFFERED,
  payload: offer,
});
export const addSupplierOfferOffered = (offer) => {
  return (dispatch) => {
    dispatch(
      updateInvoice({
        id: offer.invoiceIds[0],
        status: 'CASHBACK_OFFERED',
      })
    );
    dispatch({
      type: ADD_SUPPLIER_OFFER_OFFERED,
      payload: offer,
    });
  };
};
export const updateSupplierOfferAvailable = (offer) => {
  return (dispatch) => {
    if (
      offer.offerStatus !== 'CLEARING' &&
      offer.offerStatus !== 'TOPUP_PENDING'
    ) {
      dispatch(updateBill({ id: offer.invoiceIds[0], status: 'PAID' }));
    }
    dispatch({
      type: UPDATE_SUPPLIER_OFFER_AVAILABLE,
      payload: offer,
    });
  };
};

export const updateSupplierOfferAvailableNoReturn = (offer, dispatch) => {
  if (
    offer.offerStatus !== 'CLEARING' &&
    offer.offerStatus !== 'TOPUP_PENDING'
  ) {
    dispatch(updateBill({ id: offer.invoiceIds[0], status: 'PAID' }));
  }
  dispatch({
    type: UPDATE_SUPPLIER_OFFER_AVAILABLE,
    payload: offer,
  });
};
// export const updatePurchaserOfferAvailable = offer => ({
//   type: UPDATE_PURCHASER_OFFER_AVAILABLE,
//   payload: offer
// });

export const showEarlyPaymentOffer = () => ({ type: SHOW_EARLY_PAYMENT_OFFER });
export const hideEarlyPaymentOffer = () => ({ type: HIDE_EARLY_PAYMENT_OFFER });
export const setPurchaseOfferLoading = (loading) => ({
  type: SET_PURCHASE_OFFER_LOADING,
  payload: loading,
});
