import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { InvoiceDetails } from 'components';
import Amount from 'components/Amount';
import DiscountEntry from 'components/DiscountEntry';
import currencyFormatter from 'utils/currencyFormatter';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { MIN_PURCHASER_OFFER_FEE, RELAY_FEE_PERCENTAGE } from 'config';
import getTimestamp from 'utils/getTimestamp';
import { setShowPayNow } from 'store/offerLanding';
import { useDispatch } from 'react-redux';

const StyledButton = styled(Button)`
  margin: 30px 0 20px 0;
`;

const OfferDetails = (props) => {
  const dispatch = useDispatch();

  const {
    history,
    invoice,
    invoiceType,
    purchaserOffersAvailable,
    supplierOffersAvailable,
    token,
    setMessage,
    setSubMessage,
    user,
    type,
  } = props;

  const expirationTime = getTimestamp(moment());
  // Check if a purchaser offer exists for the invoice.
  const purchaserOffers =
    purchaserOffersAvailable.length &&
    purchaserOffersAvailable.filter(
      (offer) =>
        (offer.purchaserOffer.offerStatus === 'ACTIVE' ||
          offer.purchaserOffer.offerStatus === 'TOPUP_PENDING') &&
        offer.purchaserOffer.createdByCompany === invoice.issuedFor &&
        invoice.dueDate >= expirationTime &&
        invoice.includeInPurchaserOffer
    );
  // check if supplier offer exists for the bill
  const supplierOffers =
    supplierOffersAvailable.length &&
    supplierOffersAvailable.filter(
      (offer) =>
        offer.offerStatus !== 'EXPIRED' &&
        offer.invoiceIds[0] === invoice.id &&
        offer.expirationTime >= expirationTime &&
        invoice.dueDate >= expirationTime
    );
  let discount = 0;
  if (invoiceType === 'INVOICE') {
    const discountWithoutMinServiceFee =
      purchaserOffers.length &&
      (purchaserOffers[0].purchaserOffer.crr *
        invoice.discountedTotal *
        (moment(invoice.dueDate).diff(moment(), 'days') + 1)) /
        (100 * 365 * ((100 - RELAY_FEE_PERCENTAGE) / 100));
    discount = discountWithoutMinServiceFee + MIN_PURCHASER_OFFER_FEE;
  } else if (invoiceType === 'BILL') {
    discount = supplierOffers.length && supplierOffers[0].cashReward;
  }

  useEffect(() => {
    if (discount) {
      // The offer is still valid
      setMessage(
        invoiceType === 'INVOICE'
          ? 'Get Paid Today'
          : `Save ${currencyFormatter.format(discount)} on this invoice `
      );
      setSubMessage(
        invoiceType === 'INVOICE'
          ? `${invoice.issuedForCompanyName} has approved your invoice for early payment`
          : 'Pay early to collect your cashback reward'
      );
    } else {
      // The offer is invalid
      setMessage(
        type === 'notify'
          ? 'Sorry, your early payment offer has expired.'
          : 'Oops! This offer is no longer available.'
      );
      setSubMessage(
        invoiceType === 'INVOICE'
          ? type === 'notify'
            ? 'Login below to review your approved invoices and request early payment.'
            : 'Join Relay to start getting paid faster'
          : 'Join Relay to start earning cashback for prompt payment'
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, invoice, invoiceType]);

  const handleButtonClick = () => {
    if (invoiceType === 'BILL') {
      // To take user to bills tab of bills page, and to show Pay Now modal
      dispatch(setShowPayNow(true));
    }

    if (user) {
      history.push('/signup', {
        tokenFromInviteLink: token,
        invitedUser: user,
      });
    } else {
      history.push('/login');
    }
  };

  return (
    <>
      <InvoiceDetails invoices={[invoice]} invoiceType={invoiceType} />
      <Amount
        amount={invoice.discountedTotal}
        label="Amount Due (inc. GST)"
        containerStyle={styles.discountedAmountContainer}
      />
      <div style={styles.line} />

      {!!discount && (
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <DiscountEntry
            discount={discount ? discount : 0}
            label={
              invoiceType === 'INVOICE' ? 'Service Fee' : 'Cashback Reward'
            }
            isEditing={false}
            fromInvite
          />
        </div>
      )}

      <StyledButton size="large" type="primary" onClick={handleButtonClick}>
        {invoiceType === 'INVOICE'
          ? type === 'notify'
            ? 'Log In'
            : 'Get Paid Today'
          : `Pay Now & Save ${currencyFormatter.format(discount)}`}
      </StyledButton>
    </>
  );
};

const styles = {
  discountedAmountContainer: {
    backgroundColor: '#EBF7FF',
    height: 40,
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  line: {
    borderBottom: '1px solid #DDD',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
};

export default withRouter(OfferDetails);
