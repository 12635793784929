import axios from 'axios';
import moment from 'moment'

const getAnalytics = ({ invoiceType, fromTime }) => {
  const timeStamp = moment().date(1).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf();
  const url = fromTime?
              `/analytics/${invoiceType}?fromTime=${timeStamp}`
              :
              `/analytics/${invoiceType}`
  return new Promise(function(resolve, reject) {
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error));
  });
};

export default {
  getAnalytics
};
