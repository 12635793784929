import api from 'api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentTerm } from 'types/PaymentTerm';

// const formatDueDate = (date: Moment) => date.format('MM/DD/YYYY');

const convertPaymentTermToDate = (paymentTerm: PaymentTerm) => {
  const { frequency, dayOfWeek, date } = paymentTerm;

  if (frequency === 'MONTHLY' && date) {
    const nextMonth = moment().add(1, 'month');
    let dueDate = nextMonth.clone().date(date);
    if (dueDate.isAfter(nextMonth, 'month')) {
      // The calculated due date rolls over to the second month after current month
      // So we return the last date of the next month
      return nextMonth.endOf('month');
    } else {
      return dueDate;
    }
  } else if (dayOfWeek) {
    const dueDate = moment().add(1, 'week').day(dayOfWeek);
    return dueDate;
  }
};

type UseDueDateParams = {
  invoiceType: 'BILL' | 'INVOICE';
  supplierName?: string | null;
};

// 20th of next month, to be used if payment term is not set
const fallbackDueDate = moment().add(1, 'month').date(20);

export const useDueDate = ({ invoiceType, supplierName }: UseDueDateParams) => {
  const companyId = useSelector((state: any) => state.auth.user.companyId);

  const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([]);
  const [defaultDueDate, setDefaultDueDate] = useState(fallbackDueDate);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (invoiceType === 'BILL') {
      setLoading(true);
      api.paymentTerms
        .getPaymentTerms({ entityId: companyId })
        .then((paymentTermsResponse) => {
          setPaymentTerms(paymentTermsResponse);
        })
        .finally(() => setLoading(false));
    }
  }, [companyId, invoiceType]);

  useEffect(() => {
    if (invoiceType === 'BILL' && supplierName && paymentTerms.length) {
      setLoading(true);
      api.company
        .getCounterParties({
          companyId,
          counterPartyName: supplierName,
        })
        .then((counterPartiesResponse) => {
          if (counterPartiesResponse.counterParties.length) {
            const counterParty = counterPartiesResponse.counterParties[0];
            const paymentTermId = counterParty.counterParty.paymentTermId;
            let paymentTermInEffect: PaymentTerm | undefined;

            if (paymentTermId) {
              // Payment term is set at supplier level
              paymentTermInEffect = paymentTerms.find(
                (paymentTerm) => paymentTerm.id === paymentTermId
              );
            } else {
              // Payment term not set at supplier level
              // Let's see if default payment term is set at buyer level
              paymentTermInEffect = paymentTerms.find(
                (paymentTerm) => paymentTerm.defaultTerm
              );
            }
            if (paymentTermInEffect) {
              const dueDateFromPaymentTerm =
                convertPaymentTermToDate(paymentTermInEffect);
              dueDateFromPaymentTerm &&
                setDefaultDueDate(dueDateFromPaymentTerm);
            }
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [companyId, invoiceType, paymentTerms, supplierName]);

  return { defaultDueDate, loadingDefaultDueDate: loading };
};
