import React from 'react';
import styled from 'styled-components/macro';
import { Select } from 'antd';
import { FilterItem } from 'ds';
import spacing from 'styles/layout/spacing';

const { Option } = Select;

const SelectContainer = styled.div`
  padding: ${spacing.gutter.md} ${spacing.gutter.lg};
`;

type Option = { accountCode: string; accountName: string };

export const GlCode: React.FC<{
  options: Option[];
  value: string[] | null;
  handleChange: (value: string[]) => void;
  handleCheck: (flag: boolean) => void;
  isChecked: boolean;
}> = ({ options, value, handleChange, handleCheck, isChecked }) => {
  const onChange = (value: string[]) => {
    handleChange(value);
  };

  return (
    <FilterItem label="GL Code" isChecked={isChecked} handleCheck={handleCheck}>
      {isChecked && (
        <SelectContainer>
          <Select
            mode="multiple"
            onChange={onChange}
            placeholder="GL Codes"
            style={{ width: '100%' }}
            value={value || undefined}
          >
            {options.map((option) => (
              <Option
                key={option.accountCode}
                value={option.accountCode}
              >{`${option.accountCode} - ${option.accountName}`}</Option>
            ))}
          </Select>
        </SelectContainer>
      )}
    </FilterItem>
  );
};
