import axios from 'axios';

const validate = async (code: string): Promise<boolean> => {
  try {
    const response = await axios.get(`/invite-code/validate?code=${code}`);
    return response.data;
  } catch {
    return false;
  }
};

export default { validate };
