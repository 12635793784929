import account from './account';
import admin from './admin';
import analytics from './analytics';
import auth from './auth';
import bankAccount from './bankAccount';
import company from './company';
import creditNotes from './creditNotes';
import dashboard from './dashboard';
import entityPreferences from './entityPreferences';
import identity from './identity';
import invite from './invite';
import inviteCode from './inviteCode';
import invoices from './invoices';
import notify from './notify';
import myob from './myob';
import payablesSummary from './payablesSummary';
import paymentTerms from './paymentTerms';
import purchaserOffers from './purchaserOffers';
import recaptcha from './recaptcha';
import ripe from './ripe';
import statements from './statements';
import supplierOffers from './supplierOffers';
import user from './user';
import xero from './xero';

const api = {
  account,
  admin,
  analytics,
  auth,
  bankAccount,
  company,
  creditNotes,
  dashboard,
  entityPreferences,
  identity,
  invite,
  inviteCode,
  invoices,
  myob,
  notify,
  payablesSummary,
  paymentTerms,
  purchaserOffers,
  recaptcha,
  ripe,
  statements,
  supplierOffers,
  user,
  xero,
};

export default api;
