const buildUrl = ({
  base,
  status,
  supplier,
  sort,
  page,
  size,
  tab,
  matchesXero,
}: {
  base: string;
  status?: string | null;
  supplier?: string | null;
  sort?: string | null;
  page: number;
  size: number;
  tab: string;
  matchesXero?: string | null;
}): string => {
  const params = new URLSearchParams();

  params.append('tab', tab);
  params.append('page', String(page));
  params.append('size', String(size));
  if (status) {
    params.append('status', status);
  }
  if (sort) {
    params.append('sort', sort);
  }
  if (supplier) {
    params.append('supplier', supplier);
  }
  if (matchesXero) {
    params.append('isMatchedPayablesValue', matchesXero);
  }

  return `${base}?${params}`;
};

export default buildUrl;
