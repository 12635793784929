import React from 'react';
import styled from 'styled-components';
import breakpoints from 'styles/layout/breakpoints';
import spacing from 'styles/layout/spacing';
import colors from 'colors';
import { Tag } from './Tag';
import { TagProps } from './types';

const Container = styled.div`
  padding: ${spacing.gutter.xs} ${spacing.gutter.sm};
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props: { highlighted?: boolean }) =>
    props.highlighted ? '#F6F7F8' : 'transparent'};
  @media screen and (min-width: ${breakpoints.mobile}) {
    margin-bottom: ${spacing.gutter.sm};
  }
`;

const Label = styled.span`
  font-size: 14px;
  color: ${colors.greys700};
  margin-right: ${spacing.gutter.md};
`;

const Value = styled.span<{
  prominent?: boolean;
  fromAccount?: boolean;
}>`
  color: ${colors.greys700};
  font-weight: bold;
  font-size: ${({ fromAccount }) => (fromAccount ? '14px' : '16px')};
  @media screen and (min-width: ${breakpoints.mobile}) {
    font-size: ${({ prominent, fromAccount }) =>
      prominent ? '24px' : fromAccount ? '14px' : '16px'};
  }
`;

type Props = {
  label?: string;
  value?: string;
  highlighted?: boolean;
  prominent?: boolean;
  fromAccount?: boolean;
  tag?: TagProps;
};

export const Row: React.FC<Props> = (props) => {
  const { label, value, highlighted, prominent, fromAccount, tag } = props;
  return (
    <Container highlighted={highlighted}>
      <div>
        <Label>{label}</Label>
        {tag && (
          <Tag text={tag.text} tooltipText={tag.tooltipText} type={tag.type} />
        )}
      </div>
      {value && (
        <Value prominent={prominent} fromAccount={fromAccount}>
          {value}
        </Value>
      )}
    </Container>
  );
};
