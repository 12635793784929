import React, { useState } from 'react';
import styled from 'styled-components';
import { Steps, Typography, message as msg } from 'antd';
import { connect } from 'react-redux';
import { History } from 'history';
import { Close } from 'assets/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { relayNewLogo } from 'assets/images';
import { login } from 'store/auth';

import api from 'api';
import processError from 'utils/processError';
import spacing from 'styles/layout/spacing';
import breakpoints from 'styles/layout/breakpoints';

const { Step } = Steps;
const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 ${spacing.gutter.xl};
  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 ${spacing.gutter.sm};
  }
`;
const Logo = styled.img`
  width: 100px;
  height: 33.33px;
  display: block;
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
const CloseContainer = styled.div`
  width: 100px;
  img {
    margin-left: auto;
    cursor: pointer;
    display: block;
    width: 15px;
    height: 15px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
const StepsContainer = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
`;
const Title = styled(Text)`
  font-size: 14px;
`;

type Props = {
  current: number;
  history: History;
  isBusinessDetailsConfirmed: boolean;
  isLoggedIn: boolean;
  loginAction: (user: any) => void;
  token: string;
};

const Header: React.FC<Props> = (props) => {
  const {
    current,
    history,
    isBusinessDetailsConfirmed,
    isLoggedIn,
    loginAction,
    token,
  } = props;
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(true);
    if (isBusinessDetailsConfirmed || isLoggedIn) {
      api.user
        .get(token)
        .then((user) => {
          api.company
            .get({ companyId: user.companyId, token })
            .then((company) => {
              loginAction({
                user: { ...user, company, token },
                loggedIn: true,
              });
              history.push('/invoices');
            })
            .catch((error) => {
              const { message } = processError(error);
              msg.error(message);
              setLoading(false);
            });
        })
        .catch((error) => {
          const { message } = processError(error);
          msg.error(message);
          setLoading(false);
        });
    } else {
      api.auth
        .revokeToken(token)
        .then(() => {
          history.replace('/login');
        })
        .catch((error) => {
          const { message } = processError(error);
          msg.error(message);
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <Logo src={relayNewLogo} />
      {!isLoggedIn && (
        <StepsContainer>
          <Steps size="small" current={current} responsive={false}>
            <Step title={<Title>Your Details</Title>} />
            <Step title={<Title>Business Details</Title>} />
          </Steps>
        </StepsContainer>
      )}
      {isLoading ? (
        <LoadingOutlined spin />
      ) : (
        <CloseContainer>
          <img alt="" onClick={handleClose} src={Close} />
        </CloseContainer>
      )}
    </Container>
  );
};

export default connect(null, { loginAction: login })(Header);
