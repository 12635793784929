import axios from 'axios';
import EntityPreferences, {
  PatchEntityPreferencePayload,
} from 'types/EntityPreferences';

const getEntityPreferences = ({
  entityId,
}: {
  entityId: string;
}): Promise<EntityPreferences> =>
  new Promise((resolve, reject) => {
    const url = `/entities/${entityId}/preferences`;
    axios
      .get(url)
      .then(({ data }: { data: EntityPreferences }) => resolve(data))
      .catch((error) => reject(error));
  });

const patchEntityPreference = ({
  entityId,
  payload,
}: {
  entityId: string;
  payload: PatchEntityPreferencePayload;
}): Promise<EntityPreferences> =>
  new Promise((resolve, reject) => {
    if (!payload) {
      reject('No payload available');
    }
    if (!payload.id) {
      reject('No entity id available');
    }
    const url = `/entities/${entityId}/preferences/${payload.id}`;
    axios
      .patch(url, payload)
      .then(({ data }: { data: EntityPreferences }) => resolve(data))
      .catch((error) => reject(error));
  });

const updateEntityPreferences = ({
  entityId,
  payload,
}: {
  entityId: string;
  payload: EntityPreferences;
}): Promise<EntityPreferences> =>
  new Promise((resolve, reject) => {
    if (!payload) {
      reject('No payload available');
    }
    if (!payload.id) {
      reject('No entity id available');
    }
    const url = `/entities/${entityId}/preferences/${payload.id}`;
    axios
      .put(url, payload)
      .then(({ data }: { data: EntityPreferences }) => resolve(data))
      .catch((error) => reject(error));
  });

export default {
  getEntityPreferences,
  patchEntityPreference,
  updateEntityPreferences,
};
