import React, { useEffect } from 'react';
import BankFileProcess from './BankFileProcess';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageTracker from 'components/PageTracker';
import { setToolbarTitle } from 'store/ui';

type DispatchProps = {
  setToolbarTitle: (title: string) => void;
};
type Props = DispatchProps;

const System: React.FC<Props> = ({ setToolbarTitle }) => {
  useEffect(() => {
    setToolbarTitle('System');
  });

  return (
    <div style={{ paddingTop: 20 }}>
      <Helmet>
        <title>Topups | Relay</title>
      </Helmet>
      <PageTracker />
      <BankFileProcess />
    </div>
  );
};

export default connect<{}, DispatchProps, {}>(null, { setToolbarTitle })(
  System
);
