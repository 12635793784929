import colors from 'colors';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import formatter from 'utils/currencyFormatter';
import Transaction from 'types/Transaction';
import PaginationControl from './PaginationControl';
import RelayTable from 'components/RelayTable';
import spacing from 'styles/layout/spacing';
import { LoadingOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import Text from 'antd/lib/typography/Text';

const EmptyMessage = styled.p`
  text-align: center;
  margin-top: ${spacing.gutter.md};
  align-items: center;
  justify-content: center;
`;

const Transactions = ({
  transactions,
  companyId,
  loadingTransactions,
  totalNumberOfTransactions,
}: {
  transactions: {
    transaction: Transaction;
  }[];
  companyId: string;
  loadingTransactions: boolean;
  totalNumberOfTransactions: number;
}) => {
  const columns = [
    {
      title: 'DATE',
      dataIndex: 'modificationTime',
      render: (modificationTime: Transaction['modificationTime']) =>
        moment(modificationTime).format('D MMMM YYYY').valueOf(),
    },
    {
      title: 'DETAILS',
      render: ({
        sourceCompanyId,
        destinationCompanyName,
        sourceCompanyName,
        sourceParticulars,
        destinationParticulars,
      }: Transaction) => {
        let details =
          sourceCompanyId === companyId
            ? destinationCompanyName
            : sourceCompanyName;
        if (sourceParticulars && sourceParticulars === 'Commission') {
          details = 'Service Fee';
        }
        if (destinationParticulars && destinationParticulars === 'Commission') {
          details = 'Cashback';
        }
        return details;
      },
    },
    {
      title: 'REFERENCE',
      render: ({
        destinationCompanyId,
        completeDestinationReference,
        destinationReference,
        sourceCompanyId,
        completeSourceReference,
        sourceReference,
        transactionClass,
      }: Transaction) => {
        let reference = '-';
        if (transactionClass !== 'PAYMENT') {
          return <Text ellipsis={{ tooltip: true }}>{reference}</Text>;
        }
        if (destinationCompanyId === companyId) {
          reference = completeDestinationReference ?? destinationReference;
        }
        if (sourceCompanyId === companyId) {
          reference = completeSourceReference ?? sourceReference ?? '-';
        }
        return <Text ellipsis={{ tooltip: true }}>{reference}</Text>;
      },
      ellipsis: true,
      width: '50',
    },
    {
      title: 'MONEY IN',
      align: 'right' as const,
      render: ({
        destinationCompanyId,
        transactionAmount,
        transactionClass,
      }: Transaction) => {
        const moneyIn =
          (destinationCompanyId === companyId ||
            transactionClass === 'TOPUP') &&
          transactionAmount;
        return (
          moneyIn && (
            <span style={{ color: colors.greens.dark }}>
              {formatter.format(moneyIn)}
            </span>
          )
        );
      },
    },
    {
      title: 'MONEY OUT',
      align: 'right' as const,
      render: ({
        sourceCompanyId,
        transactionAmount,
        transactionClass,
      }: Transaction) => {
        const moneyOut =
          sourceCompanyId === companyId &&
          transactionClass !== 'TOPUP' &&
          transactionAmount;
        return moneyOut && `-${formatter.format(moneyOut)}`;
      },
    },
    {
      title: 'BALANCE',
      align: 'right' as const,
      render: ({
        transactionClass,
        status,
        sourceAccountBalance,
        destinationAccountBalance,
      }: Transaction) => {
        const balance = sourceAccountBalance || destinationAccountBalance;
        return (transactionClass === 'TOPUP' && status === 'PENDING') ||
          (transactionClass === 'WITHDRAWAL' && status === 'PENDING')
          ? 'Pending'
          : balance
          ? formatter.format(balance)
          : '-';
      },
    },
  ];
  if (loadingTransactions) {
    return (
      <Row justify="center">
        <LoadingOutlined />
      </Row>
    );
  }

  if (transactions.length) {
    // let pendingTransactions = [], completedTransactions = [];
    // transactions.forEach(trans=> {
    //     if (trans.transaction.transactionClass === 'TOPUP' && trans.transaction.status === 'PENDING') {
    //       pendingTransactions.push(trans);
    //     } else {
    //       completedTransactions.push(trans);
    //     }
    // })
    // let result = [...pendingTransactions,...completedTransactions];
    return (
      <>
        <RelayTable
          columns={columns}
          dataSource={transactions.map((item) => item.transaction)}
          rowKey="id"
          pagination={false}
        />
        <PaginationControl
          transactions={transactions}
          rows={totalNumberOfTransactions}
        />
      </>
    );
  } else {
    return <EmptyMessage>You have no transactions yet</EmptyMessage>;
  }
};

export default Transactions;
