import React, { useState } from 'react';
import api from 'api';
import processError from 'utils/processError';
import { Modal, Form, Input, message } from 'antd';

import User from 'types/User';

type Props = {
  hide: () => void;
  companyId: string;
  appendToContacts: (contact: User) => void;
};

const NewContact: React.FC<Props> = ({ hide, companyId, appendToContacts }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (values: any) => {
    setSubmitting(true);
    api.user
      .post({ ...values, companyId })
      .then((response) => {
        appendToContacts(response);
        hide();
      })
      .catch((error) => {
        message.error(processError(error).message);
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    firstName: null,
    lastName: null,
    username: null,
    phoneNumber: null,
  };

  return (
    <Modal
      visible
      onCancel={hide}
      title="New Contact"
      onOk={form.submit}
      okButtonProps={{ loading: submitting }}
      okText="Submit"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[{ required: true, message: 'First name is required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Last name" name="lastName">
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="username"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Email is not in valid format' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Phone number" name="phoneNumber">
          <Input addonBefore="+64" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewContact;
