import ErrorType, { ReturnType } from 'types/Error';

const GENERIC_MESSAGE = 'Something went wrong';

const processError = (error?: ErrorType | string): ReturnType => {
  if (!error) {
    return { status: null, message: GENERIC_MESSAGE, errors: null };
  }

  if (typeof error === 'string') {
    return { status: null, message: error, errors: null };
  }

  if (error.response) {
    // Error from API, most probably
    const { status, data } = error.response;
    const message = data && data.message ? data.message : GENERIC_MESSAGE;
    const debugMessage = data && data.debugMessage ? data.debugMessage : '';
    const errors = data && data.errors;
    return {
      status,
      message,
      debugMessage,
      errors,
    };
  }

  const message = error.message || GENERIC_MESSAGE;
  return {
    status: null,
    message,
    debugMessage: null,
    errors: null,
  };
};

export default processError;
