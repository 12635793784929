import api from 'api';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

const GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS';
const SHOW_ADD_BANK_ACCOUNT_WINDOW = 'SHOW_ADD_BANK_ACCOUNT_WINDOW';
const HIDE_ADD_BANK_ACCOUNT_WINDOW = 'HIDE_ADD_BANK_ACCOUNT_WINDOW';
const CONFIRM_ADD_BANK_ACCOUNT = 'CONFIRM_ADD_BANK_ACCOUNT';
const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
const GET_COMPANY = 'GET_COMPANY';

const SHOW_TOP_UP_WINDOW = 'SHOW_TOP_UP_WINDOW';
const HIDE_TOP_UP_WINDOW = 'HIDE_TOP_UP_WINDOW';
const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';

const UPDATE_USER = 'UPDATE_USER';

const REFRESH_TOKEN = 'REFRESH_TOKEN';
const SET_TOKEN = 'SET_TOKEN';

const SHOW_DELETE_BANK_ACCOUNT = 'SHOW_DELETE_BANK_ACCOUNT';
const HIDE_DELETE_BANK_ACCOUNT = 'HIDE_DELETE_BANK_ACCOUNT';

const SHOW_DEFAULT_WITHDRAWAL_ACCOUNT = 'SHOW_DEFAULT_WITHDRAWAL_ACCOUNT';
const HIDE_DEFAULT_WITHDRAWAL_ACCOUNT = 'HIDE_DEFAULT_WITHDRAWAL_ACCOUNT';

const initialState = {
  user: null,
  loggedIn: false,
  trigger: null,
  triggerAmount: null,
  selectedInvoice: null,
  inviteError: false, //handle error when invited user tries to access api calls,
  isVisibleDeleteBankAccount: false,
  isVisibleDefaultWithdrawalWindow: false,
  deleteBankAccount: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload.user,
        loggedIn: payload.loggedIn,
        inviteError: false,
      };
    case LOGOUT:
      return initialState;

    case SHOW_TOP_UP_WINDOW:
      return {
        ...state,
        isVisibleTopUpModal: true,
        trigger: payload.trigger,
        triggerAmount: payload.triggerAmount,
        Crr: payload.crr ? payload.crr : null,
        isVisibleEarlyPaymentOffer: false,
        isVisibleConfirmEarlyPaymentOffer: false,
        selectedInvoice: payload.selectedInvoice
          ? payload.selectedInvoice
          : null,
      };
    case HIDE_TOP_UP_WINDOW:
      return {
        ...state,
        isVisibleTopUpModal: false,
        trigger: null,
        triggerAmount: null,
        paymentSuccess: false,
        Crr: null,
      };
    case UPDATE_COMPANY_DETAILS:
      return {
        ...state,
        user: {
          ...state.user,
          company: payload,
        },
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: payload,
        },
      };
    case SET_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: payload,
        },
      };
    // for xero connection
    case UPDATE_USER:
      const userData = payload.user ? payload.user : state.user; // eslint-disable-line
      return {
        ...state,
        user: {
          ...userData,
          company: {
            ...userData.company,
            company: {
              ...userData.company.company,
              connectedToXero: payload.connectedToXero
                ? false
                : userData.company.company.connectedToXero,
              connectedToMyobEssentials: payload.connectedToMyobEssentials
                ? false
                : userData.company.company.connectedToMyobEssentials,
              connectedToMyobAccountRight: payload.connectedToMyobAccountRight
                ? false
                : userData.company.company.connectedToMyobAccountRight,
            },
          },
        },
      };
    case GET_BANK_ACCOUNTS:
      return {
        ...state,
        user: {
          ...state.user,
          account: payload,
        },
      };

    case GET_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          company: payload,
        },
      };

    case SHOW_ADD_BANK_ACCOUNT_WINDOW:
      return {
        ...state,
        isVisibleAddBankAccount: true,
        verifyAccountDetails: payload ? payload : null,
      };
    case HIDE_ADD_BANK_ACCOUNT_WINDOW:
      return {
        ...state,
        isVisibleAddBankAccount: false,
        verifyAccountDetails: null,
      };

    case SHOW_DEFAULT_WITHDRAWAL_ACCOUNT:
      return { ...state, isVisibleDefaultWithdrawalWindow: true };
    case HIDE_DEFAULT_WITHDRAWAL_ACCOUNT:
      return { ...state, isVisibleDefaultWithdrawalWindow: false };

    case SHOW_DELETE_BANK_ACCOUNT:
      return {
        ...state,
        isVisibleDeleteBankAccount: true,
        deleteBankAccount: payload,
      };
    case HIDE_DELETE_BANK_ACCOUNT:
      return {
        ...state,
        isVisibleDeleteBankAccount: false,
        deleteBankAccount: null,
      };
    case CONFIRM_ADD_BANK_ACCOUNT:
      return {
        ...state,
        user: {
          ...state.user,
          account: [...state.user.account, payload],
        },
      };
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        user: {
          ...state.user,
          account: state.user.account.filter(
            (account) => account.id !== payload
          ),
        },
      };

    default:
      return state;
  }
};

export const login = ({ user, loggedIn }) => ({
  type: LOGIN,
  payload: { user, loggedIn },
});

export const logout = () => ({ type: LOGOUT });

export const getBankAccounts = (companyId, countryCode) => {
  return (dispatch) => {
    api.company.getBankAccounts({ companyId }).then((bankAccounts) =>
      dispatch({
        type: GET_BANK_ACCOUNTS,
        payload: bankAccounts.filter((account) => {
          if (countryCode === 'AU') {
            return (
              (account.bankAccountNumber !== null && account.bsb !== null) ||
              account.payId !== null
            );
          } else {
            return account.bankAccountNumber !== null;
          }
        }),
      })
    );
  };
};

export const refreshToken = (token) => {
  return (dispatch) => {
    api.auth
      .refreshToken(token)
      .then((token) => dispatch({ type: REFRESH_TOKEN, payload: token }));
  };
};

export const getCompany = (companyId) => {
  return (dispatch) => {
    api.company
      .get({ companyId })
      .then((details) => dispatch({ type: GET_COMPANY, payload: details }));
  };
};

export const showAddBankAccountWindow = (account) => ({
  type: SHOW_ADD_BANK_ACCOUNT_WINDOW,
  payload: account,
});
export const hideAddBankAccountWindow = () => ({
  type: HIDE_ADD_BANK_ACCOUNT_WINDOW,
});

export const showDefaultWithdrawalWindow = () => ({
  type: SHOW_DEFAULT_WITHDRAWAL_ACCOUNT,
});
export const hideDefaultWithdrawalWindow = () => ({
  type: HIDE_DEFAULT_WITHDRAWAL_ACCOUNT,
});

export const confirmAddBankAccount = (account) => {
  return {
    type: CONFIRM_ADD_BANK_ACCOUNT,
    payload: account,
  };
};
export const deleteBankAccount = (bankId) => {
  return {
    type: DELETE_BANK_ACCOUNT,
    payload: bankId,
  };
};

export const showDeleteBankAccount = (account) => ({
  type: SHOW_DELETE_BANK_ACCOUNT,
  payload: account,
});
export const hideDeleteBankAccount = () => ({
  type: HIDE_DELETE_BANK_ACCOUNT,
});

export const showTopUpWindow = ({
  trigger,
  triggerAmount,
  crr,
  selectedInvoice,
}) => ({
  type: SHOW_TOP_UP_WINDOW,
  payload: { trigger, triggerAmount, crr, selectedInvoice },
});

export const hideTopUpWindow = () => ({ type: HIDE_TOP_UP_WINDOW });

export const updateCompanyDetails = (company) => {
  return (dispatch) => {
    api.company
      .put({ company })
      .then((details) =>
        dispatch({ type: UPDATE_COMPANY_DETAILS, payload: details })
      );
  };
};

export const updateUser = ({
  connectedToXero,
  user,
  connectedToMyobEssentials,
  connectedToMyobAccountRight,
}) => ({
  type: UPDATE_USER,
  payload: {
    connectedToXero,
    user,
    connectedToMyobEssentials,
    connectedToMyobAccountRight,
  },
});
// set token for invited user
export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});
