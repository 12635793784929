import React from 'react';
import Amount from './Amount';
import DateDue from './DateDue';
import InvoiceNumber from './InvoiceNumber';
import BalancePayable from './BalancePayable';
import { FilterDialogContainer } from 'components';
import { InvoiceFilters } from 'types/InvoiceFilters';

const FilterDialog: React.FC<{
  amountProps: {
    amount: string | null;
    handleChangeAmount: (value: string | null) => void;
    handleChangeHigherAmount: (value: string | null) => void;
    handleChangeOperator: (operator: string) => void;
    handleCheck: (flag: boolean) => void;
    higherAmount: string | null;
    isChecked: boolean;
    operator: string;
  };
  dateProps: {
    date: string | null;
    endDate: string | null;
    handleChangeDateDue: (value: string) => void;
    handleChangeEndDate: (value: string) => void;
    handleChangeOperator: (value: string) => void;
    handleCheck: (flag: boolean) => void;
    inTheNext: number | null;
    handleChangeInTheNext: (value: number) => void;
    inTheNextSelection: 'days' | 'months';
    handleChangeInTheNextSelection: (option: 'days' | 'months') => void;
    isChecked: boolean;
    operator: string;
  };
  invoiceNumberProps: {
    invoiceNumber: string | null;
    isChecked: boolean;
    handleChangeInvoiceNumber: (value: string) => void;
    handleCheck: (flag: boolean) => void;
    handleChangeOperator: (operator: string) => void;
    operator: string;
  };
  matchesXeroProps: {
    matchesXero: string | null;
    isChecked: boolean;
    handleChangeMatchesXero: (value: string) => void;
    handleCheck: (flag: boolean) => void;
  };
  filters: InvoiceFilters;
  invoiceType: 'BILL' | 'INVOICE';
  handleDone: () => void;
  handleReset: () => void;
  hideDropdown: () => void;
}> = ({
  amountProps,
  dateProps,
  invoiceNumberProps,
  matchesXeroProps,
  filters,
  handleDone,
  handleReset,
}) => {
  return (
    <FilterDialogContainer handleDone={handleDone} handleReset={handleReset}>
      {filters.isSupplierBills ? (
        <BalancePayable {...matchesXeroProps} />
      ) : (
        <>
          <Amount {...amountProps} />
          <DateDue {...dateProps} />
          <InvoiceNumber {...invoiceNumberProps} />
        </>
      )}
    </FilterDialogContainer>
  );
};

export default FilterDialog;
