import { useEffect } from 'react';
import { connect } from 'react-redux';

import api from 'api';

const InvoiceLoadTrigger = ({
  connectedToXero,
  connectedToMyobEssentials,
  connectedToMyobAccountRight,
  isAdmin,
}) => {
  useEffect(() => {
    if (!isAdmin) {
      if (connectedToMyobEssentials || connectedToMyobAccountRight) {
        api.myob.triggerInvoiceLoad({
          myobAccount: connectedToMyobEssentials
            ? 'Essentials'
            : 'Account Right',
        });
      }
      if (connectedToXero) {
        api.xero.triggerInvoiceLoad();
      }
    }
  });
  return null;
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  const company = user?.company?.company;
  const isAdmin = user?.roles && user.roles[0] === 'ROLE_ADMIN';
  return {
    connectedToXero: company && company.connectedToXero,
    connectedToMyobEssentials: company && company.connectedToMyobEssentials,
    connectedToMyobAccountRight: company && company.connectedToMyobAccountRight,
    isAdmin,
  };
};

export default connect(mapStateToProps)(InvoiceLoadTrigger);
