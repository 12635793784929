import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  padding-right: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 11px;
`;

const TitleText = styled.span`
  font-weight: bold;
`;

const Title = (props) => {
  const { title, subTitle } = props;
  return (
    <Container>
      <TitleText>{title}</TitleText>
      {subTitle && <span>{subTitle}</span>}
    </Container>
  );
};

export default Title;
