import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const Spinner = styled(LoadingOutlined)`
  font-size: 25px;
  margin-right: 15px;
`;

const PageLoading: React.FC = () => (
  <Page>
    <TextContainer>
      <Spinner />
      <h1>Loading...</h1>
    </TextContainer>
  </Page>
);

export default PageLoading;
