import api from 'api';
import Invoice from 'types/Invoice';
import SupplierOffer from 'types/SupplierOffer';
import { TableRow } from '../types';
import { processError } from 'utils';
import { useDispatch } from 'react-redux';
import { updateSupplierOfferAvailableNoReturn } from 'store/offers';
import { useCallback, useState } from 'react';
import { setRefreshData } from 'store/ui';
import { notification } from 'antd';

const useTableActions = () => {
  const dispatch = useDispatch();

  const [isPending, setPending] = useState(false);
  const [isPaidInvoiceModalVisible, setPaidInvoiceModalVisible] =
    useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [supplierOfferToExecute, setSupplierOfferToExecute] =
    useState<SupplierOffer | null>(null);
  const [visibleEditContactEmail, setVisibleEditContactEmail] = useState(false);
  const [selectedBillForDetails, setSelectedBillForDetails] = useState('');

  const cancelPayment = useCallback(
    (supplierOffer: SupplierOffer) => {
      setPending(true);
      api.supplierOffers
        .cancel({ uriParam: supplierOffer?.id })
        .then((response) =>
          updateSupplierOfferAvailableNoReturn(response, dispatch)
        )
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setPending(false));
    },
    [dispatch]
  );

  const includeExcludeBill = useCallback(
    ({ id, action }: { id: string; action: 'INCLUDE' | 'EXCLUDE' }) => {
      if (id) {
        const payload = [
          {
            id,
            path: '/include_in_purchaser_offer' as const,
            value: action === 'INCLUDE',
            operation: 'replace' as const,
          },
        ];
        setPending(true);
        api.invoices
          .patch(payload)
          .then((response) => {
            if (Array.isArray(response) && response[0]) {
              const { updated, message } = response[0];
              if (!updated) {
                notification.error({ message });
              } else {
                dispatch(setRefreshData(true));
              }
            }
          })
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          })
          .finally(() => setPending(false));
      }
    },
    [dispatch]
  );

  const invite = useCallback(
    ({
      invoiceType,
      invoice,
    }: {
      invoiceType: 'BILL' | 'INVOICE';
      invoice: Invoice;
    }) => {
      if (!invoice.contactEmail) {
        return;
      }
      setPending(true);
      const payload = {
        email: invoice.contactEmail,
        companyId:
          invoiceType === 'INVOICE'
            ? invoice.owingCompany.id
            : invoice.companyOwed.id,
        note: '',
        type: invoiceType,
      };
      if (invoiceType === 'BILL') {
        api.invite
          .sendInvite(payload)
          .then(() => {
            dispatch(setRefreshData(true));
          })
          .catch((err) => {
            const { status, message, debugMessage } = processError(err);
            if (status !== 403 || debugMessage !== 'company not verified') {
              notification.error({ message });
            }
          })
          .finally(() => {
            setPending(false);
          });
      } else {
        api.invite
          .sendInvite(payload)
          .then(() => {
            dispatch(setRefreshData(true));
            setPending(false);
          })
          .catch((err) => {
            const { message } = processError(err);
            notification.error({ message });
            setPending(false);
          });
      }
    },
    [dispatch]
  );

  const sendNotification = useCallback(
    (companyId: string) => {
      setPending(true);
      const payload = {
        companyId,
        invoiceIds: [],
        type: 'BILL' as const,
      };
      api.notify
        .notify(payload)
        .then(() => {
          const message = 'Notification sent';
          notification.success({ message });
          dispatch(setRefreshData(true));
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setPending(false));
    },
    [dispatch]
  );

  return {
    cancelPayment,
    isPending,
    includeExcludeBill,
    invite,
    isPaidInvoiceModalVisible,
    sendNotification,
    selectedInvoiceId,
    selectedRow,
    selectedInvoice,
    selectedBillForDetails,
    setPaidInvoiceModalVisible,
    setSelectedInvoiceId,
    setSelectedInvoice,
    setSelectedRow,
    setSelectedBillForDetails,
    setSupplierOfferToExecute,
    setVisibleEditContactEmail,
    supplierOfferToExecute,
    visibleEditContactEmail,
  };
};

export default useTableActions;
