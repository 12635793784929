import { SortBy, SortDirection } from 'types/RipeCompanyInvoice';

const SET_PAGE_NUMBER = 'relay/ripe/SET_PAGE_NUMBER';
const SET_PAGE_SIZE = 'relay/ripe/SET_PAGE_SIZE';
const SET_SORT_BY = 'relay/ripe/SET_SORT_BY';
const SET_SORT_DIRECTION = 'relay/ripe/SET_SORT_DIRECTION';
const LOGOUT = 'LOGOUT';

type State = {
  pageNumber: number;
  pageSize: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
};

type Action = {
  type: string;
  payload?: any;
};

const initialState: State = {
  pageNumber: 1,
  pageSize: 25,
  sortBy: null,
  sortDirection: 'asc',
};

export default (state = initialState, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return initialState;
    case SET_PAGE_NUMBER:
      return { ...state, pageNumber: payload };
    case SET_PAGE_SIZE:
      return { ...state, pageSize: payload };
    case SET_SORT_BY:
      return { ...state, sortBy: payload };
    case SET_SORT_DIRECTION:
      return { ...state, sortDirection: payload };
    default:
      return state;
  }
};

export const setPageNumber = (pageNumber: number) => ({
  type: SET_PAGE_NUMBER,
  payload: pageNumber,
});

export const setPageSize = (pageSize: number) => ({
  type: SET_PAGE_SIZE,
  payload: pageSize,
});

export const setSortBy = (sortBy: SortBy) => ({
  type: SET_SORT_BY,
  payload: sortBy,
});

export const setSortDirection = (sortDirection: SortDirection) => ({
  type: SET_SORT_DIRECTION,
  payload: sortDirection,
});
