import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AuthCard from 'components/AuthCard';
import LoadingAnimation from 'components/LoadingAnimation';
import Account from './Account';
import PageTracker from 'components/PageTracker';

import api from 'api';
import { setCompanyId } from 'store/signup';

import { suggestCode, suggestName } from './suggest';

const { Step } = Steps;

const Message = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 13px;
  margin-top: 30px;
`;

const SubMessage = styled.span`
  margin-bottom: 20px;
  font-size: 12px;
  color: #151b25;
`;

const MapXeroAccounts = ({
  token,
  companyId,
  setCompanyId,
  history,
  next,
  myobConnect,
  myobAccount,
}) => {
  // Chart of accounts loaded from Xero/MYOB
  const [existingAccounts, setExistingAccounts] = useState([]);

  const [bankAccount, setBankAccount] = useState(null);

  // Accounts to suggest for Relay Rewards
  const [revenueAccounts, setRevenueAccounts] = useState([]);

  // Accounts to suggest for Relay Fees
  const [expenseAccounts, setExpenseAccounts] = useState([]);

  const [newDiscountReceivedAccount, setNewDiscountReceivedAccount] =
    useState(null);

  const [newDiscountGivenAccount, setNewDiscountGivenAccount] = useState(null);

  // Are we waiting for the chart of accounts to load?
  const [isLoading, setLoading] = useState(true);

  const [step, updateStep] = useState(0);

  useEffect(() => {
    if (token) {
      api.auth.getUser(token).then(({ companyId }) => {
        setCompanyId(companyId);
        // We are safe to make the API call to get MYOB/Xero Relay Accounts!
        const getRelayAccount = myobConnect
          ? api.myob.getMyobRelayAccount
          : api.xero.getXeroRelayAccount;
        getRelayAccount({ token, companyId, myobAccount }).then((accounts) => {
          setExistingAccounts(accounts);

          let revenueAccounts = [];
          let expenseAccounts = [];

          if (myobConnect) {
            // Split the accounts array into two arrays based on accountClassification
            if (myobAccount === 'Essentials') {
              revenueAccounts = accounts.filter(
                (a) => a.accountClassification === 'OTHER_INCOME'
              );
              expenseAccounts = accounts.filter(
                (a) => a.accountClassification === 'EXPENSE'
              );
            } else {
              revenueAccounts = accounts.filter(
                (a) =>
                  a.accountClassification === 'OTHER_INCOME' && a.taxExempted
              );
              expenseAccounts = accounts.filter(
                (a) => a.accountClassification === 'EXPENSE' && a.taxExempted
              );
            }
          } else {
            // Split the accounts array into two arrays based on accountClass
            revenueAccounts = accounts.filter(
              (a) => a.xeroAccountType === 'REVENUE'
            );
            expenseAccounts = accounts.filter(
              (a) => a.xeroAccountType === 'EXPENSE'
            );
          }

          const accountType = myobConnect
            ? 'myobAccountType'
            : 'xeroAccountType';

          setNewDiscountReceivedAccount({
            accountName: suggestName(accounts, 'Relay Cashback Income'),
            code: myobConnect
              ? suggestCode(accounts, '1500')
              : suggestCode(accounts, '280'),
            relayAccountType: 'RELAY_REWARDS',
            [accountType]: myobConnect ? 'OTHER_INCOME' : 'REVENUE',
            accountClassification: myobConnect && 'OTHER_INCOME',
          });
          setNewDiscountGivenAccount({
            accountName: suggestName(accounts, 'Relay Fees'),
            code: myobConnect
              ? suggestCode(accounts, '1900')
              : suggestCode(accounts, '480'),
            relayAccountType: 'RELAY_FEES',
            [accountType]: myobConnect ? 'EXPENSE' : 'DIRECTCOSTS',
            accountClassification: myobConnect && 'EXPENSE',
          });

          setBankAccount({
            accountName: suggestName(accounts, 'Relay Bank Account'),
            code: myobConnect
              ? suggestCode(accounts, '1245')
              : suggestCode(accounts, '088'),
            [accountType]:
              myobConnect && myobAccount === 'Essentials' ? 'BANKING' : 'BANK',
            relayAccountType: 'RELAY_BANK_ACCOUNT',
            accountClassification: myobConnect && 'ASSET',
          });
          setRevenueAccounts(revenueAccounts);
          setExpenseAccounts(expenseAccounts);

          setLoading(false);
        });
      });
    } // eslint-disable-next-line
  }, []);

  const finish = () => {
    const nextLocation =
      next ||
      `/signup?step=final&token=${token}&connection=${
        myobConnect ? 'myob' : 'xero'
      }`;
    history.push(nextLocation);
  };

  let message = '';
  let subMessage = '';

  if (step === 0) {
    message = 'Set up an account for Relay fees';
    subMessage = `Where should Relay fees appear in ${
      myobConnect ? 'MYOB' : 'Xero'
    }?`;
  }
  if (step === 1) {
    message = 'Set up an account for Relay cashback';
    subMessage = `Where should Relay cashback income appear in ${
      myobConnect ? 'MYOB' : 'Xero'
    }?`;
  }
  if (step === 2) {
    message = `Add your Relay bank account to ${myobConnect ? 'MYOB' : 'Xero'}`;
    subMessage =
      'This is a unique bank account that your business can use to send and receive payments on Relay';
  }

  return (
    <AuthCard>
      <PageTracker />
      {!isLoading && (
        <>
          <Steps size="small" current={step} labelPlacement="vertical">
            <Step />
            <Step />
            <Step />
          </Steps>
          <Message>{message}</Message>
          <SubMessage>{subMessage}</SubMessage>
        </>
      )}
      {isLoading && (
        <LoadingAnimation message="Loading your chart of accounts" />
      )}
      {!isLoading && step === 0 && newDiscountGivenAccount && (
        <Account
          accounts={expenseAccounts}
          companyId={companyId}
          existingAccounts={existingAccounts}
          myobAccount={myobAccount}
          myobConnect={myobConnect}
          newAccount={newDiscountGivenAccount}
          token={token}
          type="RELAY_FEES"
          updateStep={() => {
            updateStep(1);
          }}
        />
      )}
      {!isLoading && step === 1 && newDiscountReceivedAccount && (
        <Account
          accounts={revenueAccounts}
          companyId={companyId}
          existingAccounts={existingAccounts}
          myobAccount={myobAccount}
          myobConnect={myobConnect}
          newAccount={newDiscountReceivedAccount}
          token={token}
          type="RELAY_REWARDS"
          updateStep={() => {
            updateStep(2);
          }}
        />
      )}
      {!isLoading && step === 2 && bankAccount && (
        <Account
          companyId={companyId}
          myobAccount={myobAccount}
          myobConnect={myobConnect}
          newAccount={bankAccount}
          token={token}
          type="RELAY_BANK_ACCOUNT"
          updateStep={finish}
        />
      )}
    </AuthCard>
  );
};

const mapDispatchToProps = { setCompanyId };

export default connect(null, mapDispatchToProps)(withRouter(MapXeroAccounts));
