import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import MapXeroAccounts from 'components/connectAccount/MapAccounts';
import AddBankAccountToXero from 'components/connectAccount/AddBankAccount';
import LoadCompanyFile from 'components/connectAccount/LoadCompanyFile';

import Settings from 'pages/Settings';

const XeroConfig = ({ token, companyId, xeroRelayAccounts }) => {
  const { config, connection } = queryString.parse(window.location.search);
  // The token to be passed to the component.
  // If token is available in URL, use that. Else, use token from store.
  const tokenToUse = queryString.parse(window.location.search).token || token;

  let Component;
  let next = '';

  switch (config) {
    case 'map-accounts':
      Component = MapXeroAccounts;
      next = `/settings/connect-business-account?config=final&connection=${connection}`;
      break;
    case 'final':
      Component = AddBankAccountToXero;
      next = '/settings';
      break;
    case 'myob-company-file':
      Component = LoadCompanyFile;
      break;
    default:
      Component = Settings;
  }

  return (
    <Component
      token={tokenToUse}
      companyId={companyId}
      xeroRelayAccounts={xeroRelayAccounts}
      next={next}
      myobConnect={connection === 'myob' ? true : false}
    />
  );
};

const mapStateToProps = ({ auth, xero }) => ({
  token: auth.user?.token,
  companyId: auth.user?.companyId,
  xeroRelayAccounts: xero.xeroRelayAccounts,
});

export default connect(mapStateToProps)(XeroConfig);
