import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const ShowPayNowHelper = () => {
  const history = useHistory();

  const showPayNow: boolean = useSelector(
    (state: any) => state.offerLanding.showPayNow
  );

  useEffect(() => {
    if (showPayNow) {
      history.push('/bills?tab=bills&status=ALL_ELIGIBLE');
    }
  }, [history, showPayNow]);

  return null;
};
