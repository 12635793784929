const colors = {
  greys900: '#151B25',
  greys800: '#272D36',
  greys700: '#3B4048',
  greys600: '#676C74',
  greys500: '#848B94',
  greys400: '#A1A7AF',
  greys300: '#BDC3CB',
  greys200: '#D7DADF',
  greys100: '#F6F7F8',
  error: '#FF0000',
  primary: '#286EFF',
  success: '#00CC83',
  text: '#3B4048',
  warning: '#FF9300'
};

export { colors };
