import React from 'react';
import InputItem from 'components/InputItem';
import styled from 'styled-components';
import { Radio } from 'antd';
import { colors } from 'themes';

import DriversLicence from './DriversLicence';
// import Passport from './Passport';

import featureFlags from 'config/featureFlags';
import spacing from 'styles/layout/spacing';

const OptionContainer = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;
type RadioProps = { selected: boolean; first?: boolean };
const StyledRadio = styled.div<RadioProps>`
  flex: 1;
  height: 50px;
  border: ${(props) =>
    props.selected
      ? `1px solid ${colors.primary}`
      : `1px solid ${colors.greys300}`};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  :first-child {
    margin-right: ${spacing.gutter.md};
  }
  @media (max-width: 400px) {
    span {
      font-size: 14px !important;
    }
  }
`;

type Date = {
  year: string;
  month: string;
  day: string;
};

type Props = {
  countryCode: 'AU' | 'NZ';
  driversLicenceNumber: string;
  setDriversLicenseNumber: (driversLicenceNumber: string) => void;
  driversLicenceVersion: string;
  setDriversLicenseVersion: (driversLicenceVersion: string) => void;
  passportNumber: string;
  setPassportNumber: (passportNumber: string) => void;
  passportExpiry: Date;
  setPassportExpiry: (date: Date) => void;
  verifyWith: 'DRIVERS_LICENCE' | 'PASSPORT';
  setVerifyWith: (verifyWith: 'DRIVERS_LICENCE' | 'PASSPORT') => void;
  dlNumberError: string;
  dlVersionError: string;
  passportNumberError: string;
};

const IdentityVerification: React.FC<Props> = ({
  countryCode,
  driversLicenceNumber,
  setDriversLicenseNumber,
  driversLicenceVersion,
  setDriversLicenseVersion,
  passportNumber,
  setPassportNumber,
  passportExpiry,
  setPassportExpiry,
  verifyWith,
  setVerifyWith,
  dlNumberError,
  dlVersionError,
  passportNumberError,
}) => {
  return (
    <OptionContainer>
      <InputItem label="Identity Document">
        <StyledRadioGroup
          onChange={(e) => {
            setVerifyWith(e.target.value);
          }}
          value={verifyWith}
        >
          <Row>
            <StyledRadio selected={verifyWith === 'DRIVERS_LICENCE'}>
              <Radio value="DRIVERS_LICENCE">{`${
                countryCode || 'NZ'
              } Driver Licence`}</Radio>
            </StyledRadio>
            {featureFlags.verifyWithPassport && (
              <StyledRadio selected={verifyWith === 'PASSPORT'}>
                <Radio value="PASSPORT">Passport</Radio>
              </StyledRadio>
            )}
          </Row>
        </StyledRadioGroup>
      </InputItem>
      {verifyWith === 'DRIVERS_LICENCE' ? (
        <DriversLicence
          driversLicenceNumber={driversLicenceNumber}
          setDriversLicenseNumber={setDriversLicenseNumber}
          driversLicenceVersion={driversLicenceVersion}
          setDriversLicenseVersion={setDriversLicenseVersion}
          dlNumberError={dlNumberError}
          dlVersionError={dlVersionError}
        />
      ) : null}
    </OptionContainer>
  );
};

export default IdentityVerification;
