import React, { Key } from 'react';
import moment from 'moment';
import { TableProps } from 'antd';

import Invoice from 'types/Invoice';
import { useMediaQuery } from 'hooks';
import breakpoints from 'styles/layout/breakpoints';
import Text from 'antd/lib/typography/Text';
import SimpleTable from 'ds/atoms/SimpleTable';

export const InvoiceDetails: React.FC<
  {
    invoices: Invoice[];
    invoiceType: 'INVOICE' | 'BILL';
    allowSelection?: boolean;
    onRowSelection?: (selectedIds: Key[]) => void;
  } & TableProps<Invoice>
> = ({ invoices, invoiceType, allowSelection, scroll, onRowSelection }) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

  const columns = [
    {
      title: 'INVOICE #',
      key: 'invoiceNumber',
      render: (invoice: Invoice) => (
        <Text
          data-testid={'invoice-details-number_' + invoice.id}
          ellipsis={{ tooltip: true }}
        >
          {invoiceType === 'INVOICE'
            ? invoice.invoiceNumber
            : invoice.billNumber}
        </Text>
      ),
    },
    {
      title: invoiceType === 'BILL' ? 'SUPPLIER' : 'CUSTOMER',
      key: invoiceType === 'BILL' ? 'SUPPLIER' : 'CUSTOMER',
      render: (invoice: Invoice) => (
        <Text
          data-testid={'invoice-details-company-name_' + invoice.id}
          ellipsis={{ tooltip: true }}
        >
          {invoiceType === 'BILL'
            ? invoice.companyOwed.companyName
            : invoice.owingCompany.companyName}
        </Text>
      ),
    },
    {
      title: 'DUE DATE',
      key: 'dueDate',
      align: 'right' as 'right',
      render: (invoice: Invoice) => (
        <Text
          data-testid={'invoice-details-due-date_' + invoice.id}
          ellipsis={{ tooltip: true }}
        >
          {isMobile
            ? moment(invoice.dueDate).format('l').valueOf()
            : moment(invoice.dueDate).format('DD MMM YYYY').valueOf()}
        </Text>
      ),
    },
  ];

  return (
    <SimpleTable
      dataSource={invoices}
      columns={columns}
      pagination={false}
      onRowSelection={onRowSelection}
      allowSelection={allowSelection}
      rowKey="id"
      scroll={scroll}
    />
  );
};
