import { combineReducers } from 'redux';

import signup from './signup';
import xero from './xero';
import auth from './auth';
import bankAccounts from './bankAccounts';
import entityPreferences from './entityPreferences';
import ui from './ui';
import offers from './offers';
import invoices from './invoices';
import admin from './admin';
import transactions from './transactions';
import analytics from './analytics';
import ripe from './ripe';
import settings from './settings';
import ripeDraw from './ripeDraw';
import user from './user';
import offerLanding from './offerLanding';

export default combineReducers({
  signup,
  xero,
  auth,
  bankAccounts,
  entityPreferences,
  ui,
  offers,
  invoices,
  admin,
  transactions,
  analytics,
  ripe,
  settings,
  ripeDraw,
  user,
  offerLanding,
});
