export default {
  gutter: {
    xs: '4px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '48px',
  },
  gutterNumbers: {
    xs: 4,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 48,
  },
  topBar: {
    height: '64px',
  },
  sideBar: {
    width: '256px',
  },
};
