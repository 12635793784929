import React from 'react';
import DataRow from './DataRow';
import moment from 'moment';
import styled from 'styled-components';

import { InvoiceWithServiceFee } from './InvoiceWithServiceFee';
import { Alert, Button } from 'antd';
import { ExecutionResult } from 'types/PurchaserOffer';
import PaymentTable from './PaymentTable';
import spacing from 'styles/layout/spacing';
import Invoice from 'types/Invoice';

const DetailsWrapper = styled.div`
  margin-bottom: ${spacing.gutter.sm};
`;

const StyledAlert = styled(Alert)`
  font-size: 14px;
`;

type Props = {
  eligibleInvoices: Array<InvoiceWithServiceFee>;
  executionResults: Array<ExecutionResult>;
  successCount: number;
  failedCount: number;
  handleViewPaidInvoices: () => void;
};

const date = moment().format('D MMM YY');

const PaymentSummary: React.FC<Props> = ({
  eligibleInvoices,
  executionResults,
  successCount,
  failedCount,
  handleViewPaidInvoices,
}) => {
  const eligibleInvoicesCount = eligibleInvoices.length;
  const successfulResults = executionResults.filter(
    (result) => result.status === 'SUCCESS'
  );
  const invoicesById: { [id: string]: InvoiceWithServiceFee } = Object.assign(
    {},
    ...eligibleInvoices.map((item) => ({ [item.invoice.id]: item }))
  );
  const paidInvoices: Invoice[] = [];
  executionResults.forEach((result) => {
    if (invoicesById[result.invoiceId]) {
      paidInvoices.push(invoicesById[result.invoiceId].invoice);
    }
  });

  const discountedTotal = successfulResults.reduce(
    (accumulator, result) => accumulator + result.amount,
    0
  );
  const serviceFee = paidInvoices.reduce((a, b) => a + b.serviceFee, 0);
  let message = '';
  let unPaidMessage = '';
  if (!failedCount) {
    message = `Early payment received for ${successCount} invoice${
      successCount === 1 ? '.' : 's.'
    }`;
  } else {
    message = `Early payment received for ${successCount} out of ${eligibleInvoicesCount} eligible invoice${
      eligibleInvoicesCount === 1 ? '.' : 's.'
    }`;
    unPaidMessage = `Offer expired for ${failedCount} out of ${eligibleInvoicesCount} eligible invoice${
      eligibleInvoicesCount === 1 ? '' : 's'
    }`;
  }

  const viewInvoicesLinkText = `View Invoice${successCount === 1 ? '' : 's'}`;
  return (
    <>
      <DetailsWrapper>
        <PaymentTable invoices={paidInvoices} scroll={{ y: 250 }} />
      </DetailsWrapper>
      <DataRow label="Service Fee" value={serviceFee} color="grey" />
      <DataRow
        label={`Payment Received on ${date}`}
        value={discountedTotal}
        color="blue"
      />
      <StyledAlert
        message={message}
        type="success"
        action={
          <Button type="link" size="small" onClick={handleViewPaidInvoices}>
            {viewInvoicesLinkText}
          </Button>
        }
      />
      {!!unPaidMessage && (
        <StyledAlert
          message={unPaidMessage}
          type="warning"
          style={{ marginTop: spacing.gutter.md }}
        />
      )}
    </>
  );
};

export default PaymentSummary;
