import React from 'react';
import styled from 'styled-components';
import Authorise from './Authorise';
import ActionsFooter from './ActionsFooter';
import { Button, Card, Popconfirm } from 'antd';
import { RipeType } from 'types/RipeCompanyInvoice';
import spacing from 'styles/layout/spacing';

const ActionsContainer = styled(Card)`
  flex: 0 0 auto;
  .ant-btn-block {
    margin-bottom: ${spacing.gutter.sm};
  }
`;

type Props = {
  moveToNextBill: () => void;
  moveToPreviousBill: () => void;
  handleConfirm: () => void;
  handleDelete: () => void;
  handleSendForReview: () => void;
  confirming: boolean;
  deleting: boolean;
  sendingForReview: boolean;
  editingConfirmedBill: boolean;
  editingInfo: boolean;
  enableSendToPendingApproval: boolean;
  enableEditingConfirmedBill: () => void;
  disableEditingConfirmedBill: () => void;
  handleSendToPendingApproval: () => void;
  type: RipeType;
  invoiceAmount: string | null;
  posAmount: string;
  documentId: string;
  showNavigation: boolean;
  currentItemNumber: number;
  totalElements: number;
};

const Actions: React.FC<Props> = ({
  moveToNextBill,
  moveToPreviousBill,
  handleConfirm,
  handleDelete,
  handleSendForReview,
  confirming,
  deleting,
  sendingForReview,
  enableSendToPendingApproval,
  editingConfirmedBill,
  editingInfo,
  enableEditingConfirmedBill,
  disableEditingConfirmedBill,
  handleSendToPendingApproval,
  type,
  invoiceAmount,
  posAmount,
  documentId,
  showNavigation,
  currentItemNumber,
  totalElements,
}) => {
  return (
    <ActionsContainer bordered={false}>
      {(type === RipeType.PENDING_REVIEW || type === RipeType.BLOCKED) && (
        <>
          <Button
            type="primary"
            block
            onClick={handleConfirm}
            loading={confirming}
            disabled={confirming || deleting || sendingForReview || editingInfo}
          >
            Confirm
          </Button>
          {enableSendToPendingApproval && (
            <Button
              block
              onClick={handleSendToPendingApproval}
              loading={sendingForReview}
              disabled={
                confirming || deleting || sendingForReview || editingInfo
              }
            >
              Send to Pending Approval
            </Button>
          )}
        </>
      )}
      {(type === RipeType.VARIANCE || type === RipeType.PENDING_APPROVAL) && (
        <Authorise
          isPendingApproval={type === RipeType.PENDING_APPROVAL}
          onAuthorisationSuccess={moveToNextBill}
          documentId={documentId}
          invoiceAmount={invoiceAmount}
          posAmount={posAmount}
          disabled={editingInfo}
        />
      )}
      {type === RipeType.PROCESSING && editingConfirmedBill && (
        <Button type="primary" block onClick={disableEditingConfirmedBill}>
          Done Editing
        </Button>
      )}
      {type === RipeType.PROCESSING && !editingConfirmedBill && (
        <Button type="primary" block onClick={enableEditingConfirmedBill}>
          Enable Editing
        </Button>
      )}
      {type === RipeType.UNMATCHED && (
        <Button
          type="primary"
          block
          onClick={handleConfirm}
          loading={confirming}
        >
          Submit for Processing
        </Button>
      )}
      {type === RipeType.VARIANCE && (
        <Popconfirm
          title="Are you sure you want to send this bill for review?"
          onConfirm={handleSendForReview}
          okText="Yes"
          cancelText="No"
        >
          <Button block loading={sendingForReview}>
            Send for Review
          </Button>
        </Popconfirm>
      )}
      {showNavigation && (
        <ActionsFooter
          moveToNextBill={moveToNextBill}
          moveToPreviousBill={moveToPreviousBill}
          currentItemNumber={currentItemNumber}
          totalElements={totalElements}
          handleDelete={handleDelete}
          deleting={deleting}
          showDelete={type !== RipeType.AUTHORISED}
        />
      )}
    </ActionsContainer>
  );
};

export default Actions;
