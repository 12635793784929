import { Button, Form, FormInstance } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import spacing from 'styles/layout/spacing';
import {
  GlCodeAllocation,
  GlCodeAllocationItem,
} from 'types/RipeCompanyInvoice';

import { GLCodeAllocationModal, GLCodeAllocationTable } from 'components';

const renderPlaceholder = () => <Text>Add GL Code Allocation</Text>;

const renderSingleLineItem = (glCode: string) => <Text>{glCode}</Text>;

const renderAllocationTable = (allocation: GlCodeAllocation) => (
  <GLCodeAllocationTable allocation={allocation} isLoading={false} />
);

interface Props {
  label: string;
  invoiceAmount: string;
  updating: boolean;
  editing: boolean;
  form: FormInstance;
  companyId: string;
  value: GlCodeAllocation;
  trackingCategories: string[];
}

const Container = styled.div`
  position: relative;
`;

const StyledInputItem = styled(Form.Item)<{ $marginBottom: boolean }>`
  .ant-form-item-label {
    margin-bottom: ${({ $marginBottom }) =>
      $marginBottom ? spacing.gutter.xs : '0'};
  }
`;

const EditButton = styled(Button)`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
`;

const GlCodeAllocationInput: React.FC<Props> = ({
  label,
  invoiceAmount,
  updating,
  editing,
  form,
  companyId,
  value,
  trackingCategories,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentValue, setCurrentValue] =
    useState<GlCodeAllocationItem[]>(value);

  const handleCancel = () => {
    setOpenModal(false);
    form.setFieldsValue({
      glCodeAllocation: form.getFieldValue('glCodeAllocation'),
    });
  };

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <Container>
      {editing && (
        <EditButton size="small" onClick={() => setOpenModal(true)}>
          Edit allocation
        </EditButton>
      )}
      <StyledInputItem
        label={label}
        name="glCodeAllocation"
        $marginBottom={currentValue?.length > 1}
      >
        {!currentValue || currentValue?.length === 0
          ? renderPlaceholder()
          : null}

        {currentValue && currentValue.length === 1
          ? renderSingleLineItem(currentValue[0].glCode)
          : null}

        {currentValue && currentValue.length > 1
          ? renderAllocationTable(currentValue)
          : null}
      </StyledInputItem>
      {editing && openModal && (
        <GLCodeAllocationModal
          currentAllocation={currentValue}
          isVisible={editing && openModal}
          companyId={companyId}
          loading={updating}
          invoiceAmount={parseFloat(invoiceAmount)}
          handleSave={(value) => {
            setCurrentValue(value);
            form.setFieldsValue({ glCodeAllocation: value });
            setOpenModal(false);
          }}
          handleCancel={handleCancel}
          trackingCategories={trackingCategories}
        />
      )}
    </Container>
  );
};

export default GlCodeAllocationInput;
