import React from 'react';
import styled from 'styled-components';
import { Space, Typography } from 'antd';
import { APAutomation, PaymentTerms, AccountCodes } from './components';

const { Title } = Typography;

const Container = styled(Space)`
  padding-top: 20px;
  width: 100%;
`;

const BuyerSettings: React.FC = () => {
  return (
    <Container direction="vertical" size="large">
      <Title level={4}>Global Buyer Settings</Title>
      <PaymentTerms />
      <APAutomation />
      <AccountCodes />
    </Container>
  );
};

export default BuyerSettings;
