import React from 'react';
import styled from 'styled-components/macro';
import { Form, FormInstance, Select, Space, Typography } from 'antd';
import { NumberFormatValues } from 'react-number-format';

import { AccountCodeFormItem, CurrencyInput } from 'components';
import { GlAccount } from 'types/AccountCode';
import { FormButtons } from './FormButtons';

const { Text } = Typography;

const StyledForm = styled(Form)`
  display: flex;
  align-items: flex-start;
`;

const Container = styled.div``;

type FormData = {
  accountCode: string;
  costCenter: string | null;
  amount: number;
};

type Props = {
  availableAccountCodes: GlAccount[];
  form: FormInstance<FormData>;
  handleAdd: (values: FormData) => void;
  handleCancel: () => void;
  invoiceAmount: number;
  loading: boolean;
  trackingCategories: string[];
  error: string;
};

export const AllocationForm: React.FC<Props> = (props) => {
  const {
    availableAccountCodes,
    form,
    handleAdd,
    handleCancel,
    invoiceAmount,
    loading,
    trackingCategories,
    error,
  } = props;

  return (
    <Space direction="vertical">
      <StyledForm
        form={form}
        layout="inline"
        onFinish={(values) => handleAdd(values as FormData)}
      >
        <Container
          css={`
            width: 40%;
          `}
        >
          <AccountCodeFormItem
            rules={[{ required: true, message: 'GL code required' }]}
            name="accountCode"
            availableAccountCodes={availableAccountCodes}
            placeholder="Select GL Code"
            size="small"
            loading={loading}
          />
        </Container>
        <Container
          css={`
            width: 30%;
            .ant-select-selector {
              width: 142px !important;
            }
          `}
        >
          <Form.Item name="costCenter">
            <Select
              allowClear
              options={trackingCategories.map((trackingCategory) => ({
                label: trackingCategory,
                value: trackingCategory,
              }))}
              placeholder="Cost Center"
              showSearch
              size="small"
            />
          </Form.Item>
        </Container>
        <Container
          css={`
            width: 17%;
          `}
        >
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: 'Amount required',
              },
              {
                message: 'min amount 0.01',
                type: 'number',
                min: 0.01,
              },
            ]}
          >
            <CurrencyInput
              value={invoiceAmount}
              hideError
              min={0.01}
              allowNegative={false}
              onValueChange={(values: NumberFormatValues) => {
                const { floatValue } = values;
                form.setFieldsValue({ amount: floatValue });
              }}
              onEnter={form.submit}
              size="small"
              onKeyPress={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                  form.submit();
                }
              }}
            />
          </Form.Item>
        </Container>
        <FormButtons onClickSubmit={form.submit} onClickCancel={handleCancel} />
      </StyledForm>
      {!!error && <Text type="danger">{error}</Text>}
    </Space>
  );
};
