import styled from 'styled-components';
import spacing from 'styles/layout/spacing';
import { colors } from 'themes';

const Container = styled.div`
  background: ${colors.greys100};
  border-radius: 4px;
  padding: ${spacing.gutter.sm} ${spacing.gutter.md};
`;
const Item = styled.div`
  :not(:last-child) {
    margin-bottom: ${spacing.gutter.sm};
  }
`;
const Label = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.greys700};
  margin-right: ${spacing.gutter.md};
  width: 60px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export { Container, Item, Label, Row };
