// Use this on every component that renders a page that needs to be tracked.

import { useEffect } from 'react';

const PageTracker = () => {
  // We need the tracker to run when the page mounts.
  useEffect(() => {
    // We have added Segment analytics script in public/index.html
    // That script exposes analytics object on window which we use here.
    if (window.analytics) {
      window.analytics.page();
    }
  }, []);

  return null;
};

export default PageTracker;
