import React from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { ProcessingQueue } from 'types/ProcessingQueue';

const { Option } = Select;

type Props = {
  defaultValue?: ProcessingQueue;
  onChange?: (queue: ProcessingQueue) => void;
};

const DefaultProcessingQueueFormItem: React.FC<
  Props & FormItemProps & SelectProps<ProcessingQueue>
> = ({ defaultValue, onChange, style, name, label, rules }) => {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select defaultValue={defaultValue} onChange={onChange} style={style}>
        {Object.values(ProcessingQueue).map((value) => (
          <Option value={value} key={value}>
            {value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DefaultProcessingQueueFormItem;
