import User from 'types/User';

const getHistoryItemsToRender = (items: User[]): User[] => {
  const length = items.length;
  const itemsToRender: User[] = [];
  items.forEach((current, index) => {
    if (index === 0) {
      itemsToRender.push(current);
    }
    if (index < length - 1) {
      const next = items[index + 1];
      if (current.verificationStatus !== next.verificationStatus) {
        itemsToRender.push(next);
      }
    }
  });
  return itemsToRender;
};

export default getHistoryItemsToRender;
