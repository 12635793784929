import React from 'react';
import { Breadcrumb } from 'antd';

type Props = {
  breadcrumbs: {
    label: string;
    href?: string;
    onClick?: () => void;
  }[];
};

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => (
  <Breadcrumb>
    {breadcrumbs.map((crumb) => (
      <Breadcrumb.Item key={crumb.label}>
        {crumb.href ? (
          <a
            href={crumb.href}
            onClick={(e) => {
              e.preventDefault();
              crumb.onClick && crumb.onClick();
            }}
          >
            {crumb.label}
          </a>
        ) : (
          crumb.label
        )}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default Breadcrumbs;
