import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import AuthRouter from 'routers/AuthRouter';
import AppRouter from 'routers/AppRouter';
import { useSelector } from 'react-redux';

const App: React.FC = () => {
  const user = useSelector((store: any) => store.auth.user);

  return (
    <>
      <LoadingIndicator />
      {user ? <AppRouter /> : <AuthRouter />}
    </>
  );
};

export default App;
