import React, { useState } from 'react';
import styled from 'styled-components';

import Radio from 'components/Radio';
import Button from 'components/Button';
import ExistingAccounts from './ExistingAccounts';
import NewAccount from './NewAccount';
import Error from './Error';

import api from 'api';
import { BASE_URL } from 'config';

const Container = styled.div`
  margin-bottom: ${(props) =>
    props.first ? '48px' : props.last ? '0px' : '25px'};
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
`;

const Account = ({
  accounts,
  companyId,
  newAccount,
  myobAccount,
  myobConnect,
  token,
  type,
  updateStep,
}) => {
  const [isCreatingNewAccount, setIsCreatingNewAccount] = useState(true);
  const [selectedAccount, selectAccount] = useState(null);
  const [errors, setErrors] = useState({
    alreadyInRelay: false,
    nullSelectedAccount: false,
  });
  const [posting, setPosting] = useState(false);

  const accountType = myobConnect
    ? newAccount.myobAccountType
    : newAccount.xeroAccountType;

  let option = '';
  if (accountType === 'INCOME') {
    option = '4-';
  }
  if (accountType === 'OTHER_INCOME') {
    option = '8-';
  }
  if (accountType === 'EXPENSE') {
    option = '6-';
  }
  if (accountType === 'OTHER_EXPENSE') {
    option = '9-';
  }
  if (accountType === 'BANKING' || accountType === 'BANK') {
    option = '1-';
  }

  const onSelectAccount = (code) => {
    // Grab the account corresponding tp the selected option
    const account = accounts.find((a) => a.code.includes(code));

    // Let's format it according to the necessary and sufficeinet fields of API payload
    const accountType = myobConnect ? 'myobAccountType' : 'xeroAccountType';
    const accountId = myobConnect ? 'myobAccountId' : 'xeroAccountId';

    const formattedAccount = {
      [accountId]: myobConnect ? account.myobAccountId : account.xeroAccountId,
      accountName: account.accountName,
      accountStatus: null,
      code: account.code,
      [accountType]: myobConnect
        ? account.myobAccountType
        : account.xeroAccountType,
      relayAccountType: type,
      accountClassification: account.accountClassification,
      newRelayBankAccount: account.newRelayBankAccount,
      newreceivedAccount: account.newreceivedAccount,
    };
    selectAccount(formattedAccount);
    setIsCreatingNewAccount(false);
    setErrors({ ...errors, nullSelectedAccount: false });
  };

  const onButtonClick = () => {
    if (errors.alreadyInRelay) {
      updateStep();
      return;
    }
    if (!isCreatingNewAccount && !selectedAccount) {
      setErrors({ ...errors, nullSelectedAccount: true });
    } else {
      setPosting(true);
      const url = myobConnect
        ? `${BASE_URL}/company/${companyId}/${
            myobAccount === 'Essentials'
              ? 'myobessentialsrelayaccount'
              : 'myobaccountrightrelayaccount'
          }`
        : `${BASE_URL}/company/${companyId}/xerorelayaccount`;
      const accountToPost = isCreatingNewAccount ? newAccount : selectedAccount;
      api.myob
        .postMyobRelayAccount({ url, account: accountToPost, token })
        .then(() => {
          setPosting(false);
          updateStep();
        })
        .catch(({ response }) => {
          const { message } = response && response.data;
          if (message.includes('already exist')) {
            setErrors({ ...errors, alreadyInRelay: true });
          } else {
            setErrors({
              ...errors,
              generic: message || 'Something went wrong',
            });
          }
          setPosting(false);
        });
    }
  };

  let newAccountText = '';
  let existingAccountText = '';
  if (type === 'RELAY_FEES') {
    newAccountText = 'Create a new expense account';
    existingAccountText = 'Use an existing expense account';
  }
  if (type === 'RELAY_REWARDS') {
    newAccountText = 'Create a new income account';
    existingAccountText = 'Use an existing income account';
  }
  if (type === 'RELAY_BANK_ACCOUNT') {
    newAccountText = `Create a new bank account in ${
      myobConnect ? 'MYOB' : 'Xero'
    }`;
  }

  return (
    <Container>
      <Radio
        isSelected={isCreatingNewAccount}
        onClick={() => {
          selectAccount(null);
          setIsCreatingNewAccount(true);
          setErrors({ ...errors, nullSelectedAccount: false });
        }}
      >
        {newAccountText}
      </Radio>
      <NewAccount account={newAccount} option={option} />
      {type !== 'RELAY_BANK_ACCOUNT' && (
        <>
          <Radio
            isSelected={!isCreatingNewAccount}
            onClick={() => {
              setIsCreatingNewAccount(false);
            }}
          >
            {existingAccountText}
          </Radio>
          <ExistingAccounts
            accounts={accounts}
            onSelectAccount={onSelectAccount}
            selected={selectedAccount}
            onClick={() => {
              setIsCreatingNewAccount(false);
            }}
          />
        </>
      )}

      {myobConnect &&
        myobAccount !== 'Essentials' &&
        !isCreatingNewAccount &&
        accounts.length === 0 && (
          <Error>
            No GST exempt {`${type === 'RELAY_FEES' ? 'expense' : 'income'}`}{' '}
            accounts found
          </Error>
        )}
      {errors.nullSelectedAccount && (
        <Error>
          Please select an existing account or choose the option to create new
          account
        </Error>
      )}
      {errors.alreadyInRelay && (
        <Error>
          The mapping already exists in your Relay account. Your selection will
          not have any effect and the existing mapping will be used. Press the
          button below to continue.
        </Error>
      )}
      {!!errors.generic && <Error>{errors.generic}</Error>}
      <StyledButton
        full
        label="Continue"
        loading={posting}
        onClick={onButtonClick}
      />
    </Container>
  );
};

export default Account;
