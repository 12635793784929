import React, { useState } from 'react';
import VerificationList from './VerificationList';
import UserDetailsDisplay from '../UserVerification/UserDetailsDisplay';
import Upload from '../UserVerification/Upload';
import queryString from 'query-string';
import UserVerification from '../UserVerification';
import { Address } from 'types/Address';
import { connect } from 'react-redux';
import { login } from 'store/auth';

import api from 'api';

import User from 'types/User';

type Props = {
  countryCode: Address['countryCode'];
  primaryOwner: User;
  history: any;
  location: any;
  loginAction: (user: any) => void;
  token: string;
  isLoggedIn: boolean;
};

const BusinessOwners: React.FC<Props> = (props) => {
  const {
    countryCode,
    primaryOwner,
    history,
    location,
    loginAction,
    token,
    isLoggedIn,
  } = props;
  const { list, details, success, upload } = queryString.parse(location.search);

  const [isLoggingIn, setLoggingIn] = useState(false);

  const doLogin: () => void = async () => {
    setLoggingIn(true);
    const { companyId } = primaryOwner;
    let company = {};
    try {
      company = await api.company.get({ token, companyId });
    } catch (e) {
      return;
    }
    setLoggingIn(false);
    loginAction({ user: { ...primaryOwner, token, company }, loggedIn: true });
    history.replace('/invoices');
  };

  return (
    <>
      {list && (
        <VerificationList
          countryCode={countryCode}
          token={token}
          history={history}
          location={location}
          loginAction={loginAction}
          primaryOwner={primaryOwner}
        />
      )}
      {details && (
        <UserVerification
          countryCode={countryCode}
          isLoggedIn={isLoggedIn}
          token={token}
        />
      )}
      {success && (
        <UserDetailsDisplay
          countryCode={countryCode}
          doLogin={doLogin}
          history={history}
          isLoggedIn={isLoggedIn}
          isLoggingIn={isLoggingIn}
          location={location}
          token={token}
        />
      )}
      {upload && (
        <Upload
          history={history}
          isLoggedIn={isLoggedIn}
          location={location}
          token={token}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  loginAction: login,
};

export default connect(null, mapDispatchToProps)(BusinessOwners);
