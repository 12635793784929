import React, { Key } from 'react';
import moment from 'moment';
import { TableProps } from 'antd';

import Invoice from 'types/Invoice';
import { useMediaQuery } from 'hooks';
import breakpoints from 'styles/layout/breakpoints';
import Text from 'antd/lib/typography/Text';
import SimpleTable from 'ds/atoms/SimpleTable';
import { formatCurrency } from 'utils';

const PaymentTable: React.FC<
  {
    invoices: Invoice[];
    allowSelection?: boolean;
    onRowSelection?: (selectedIds: Key[]) => void;
  } & TableProps<Invoice>
> = ({ invoices, allowSelection, scroll, onRowSelection }) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

  const columns = [
    {
      title: 'INVOICE #',
      key: 'invoiceNumber',
      render: (invoice: Invoice) => (
        <Text ellipsis={{ tooltip: true }}>{invoice.invoiceNumber}</Text>
      ),
    },
    {
      title: 'DUE DATE',
      key: 'dueDate',
      render: (invoice: Invoice) => (
        <Text ellipsis={{ tooltip: true }}>
          {isMobile
            ? moment(invoice.dueDate).format('l').valueOf()
            : moment(invoice.dueDate).format('DD MMM YYYY').valueOf()}
        </Text>
      ),
      align: 'right' as const,
    },
    {
      title: 'AMOUNT',
      key: 'AMOUNT',
      render: (invoice: Invoice) => (
        <Text ellipsis={{ tooltip: true }}>
          {formatCurrency(invoice.discountedTotal)}
        </Text>
      ),
      align: 'right' as const,
    },
  ];

  return (
    <SimpleTable
      dataSource={invoices}
      columns={columns}
      pagination={false}
      onRowSelection={onRowSelection}
      allowSelection={allowSelection}
      rowKey="id"
      scroll={scroll}
    />
  );
};

export default PaymentTable;
