import api from 'api';

const GET_INVOICE_ANALYTICS = 'GET_INVOICE_ANALYTICS';
const GET_INVOICE_ANALYTICS_FROM_TIME = 'GET_INVOICE_ANALYTICS_FROM_TIME';
const GET_BILL_ANALYTICS = 'GET_BILL_ANALYTICS';
const GET_BILL_ANALYTICS_FROM_TIME = 'GET_BILL_ANALYTICS_FROM_TIME';
const LOGOUT = 'LOGOUT';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGOUT:
      return initialState;
    case GET_INVOICE_ANALYTICS: {
      return {
        ...state,
        totalAmountRequested: payload.totalAmountRequested,
        maxServiceFeesRequested: payload.maxServiceFeesRequested,
      };
    }
    case GET_INVOICE_ANALYTICS_FROM_TIME: {
      return {
        ...state,
        totalAmountPaid: payload.totalAmountPaid,
        maxServiceFeesReceived: payload.maxServiceFeesReceived,
      };
    }
    case GET_BILL_ANALYTICS: {
      return {
        ...state,
        unpaidInvoicesCount: payload.unpaidInvoicesCount,
        invoicesTotal: payload.totalAmountRequested,
      };
    }
    case GET_BILL_ANALYTICS_FROM_TIME: {
      return {
        ...state,
        cashbackReceived: payload.cashbackReceived,
        discountedAmountPaid: payload.totalAmountPaid,
      };
    }
    default:
      return state;
  }
};

export const getAnalytics = (invoiceType) => {
  return (dispatch) => {
    api.analytics.getAnalytics({ invoiceType }).then((res) =>
      dispatch({
        type:
          invoiceType === 'invoice'
            ? GET_INVOICE_ANALYTICS
            : GET_BILL_ANALYTICS,
        payload: res,
      })
    );

    api.analytics.getAnalytics({ invoiceType, fromTime: true }).then((res) =>
      dispatch({
        type:
          invoiceType === 'invoice'
            ? GET_INVOICE_ANALYTICS_FROM_TIME
            : GET_BILL_ANALYTICS_FROM_TIME,
        payload: res,
      })
    );
  };
};
