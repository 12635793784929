import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import moment from 'moment';
import { FilterItem } from 'ds';

const { Text } = Typography;

const { Option } = Select;

const InputContainer = styled.div`
  padding: 7px 11px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const options = [
  { label: 'is in the next', value: 'in' },
  { label: 'is equal to', value: 'eq' },
  { label: 'is between', value: 'range' },
  { label: 'is after', value: 'gt' },
  { label: 'is on or after', value: 'gte' },
  { label: 'is before', value: 'lt' },
  { label: 'is before or on', value: 'lte' },
];

const inTheNextOptions = ['days', 'months'];

const DateDue: React.FC<{
  date: string | null;
  endDate: string | null;
  handleChangeDateDue: (value: string) => void;
  handleChangeEndDate: (value: string) => void;
  handleChangeOperator: (value: string) => void;
  handleCheck: (flag: boolean) => void;
  inTheNext: number | null;
  handleChangeInTheNext: (value: number) => void;
  inTheNextSelection: 'days' | 'months';
  handleChangeInTheNextSelection: (option: 'days' | 'months') => void;
  isChecked: boolean;
  operator: string;
}> = ({
  date,
  endDate,
  handleChangeDateDue,
  handleChangeEndDate,
  handleChangeOperator,
  handleCheck,
  inTheNext,
  handleChangeInTheNext,
  inTheNextSelection,
  handleChangeInTheNextSelection,
  isChecked,
  operator,
}) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);

  const openDatePicker = () => setDatePickerOpen(true);

  const openEndDatePicker = () => setEndDatePickerOpen(true);

  return (
    <FilterItem
      label="Date Due"
      isChecked={isChecked}
      handleCheck={handleCheck}
      css={`
        .date-picker-button {
          padding: 0 7px !important;
          position: relative;
          text-align: left;
          width: 100%;
        }
        .ant-picker {
          visibility: hidden;
          width: 0;
          position: absolute;
          left: 0;
        }
      `}
    >
      {isChecked && (
        <InputContainer>
          <Row gutter={12}>
            <Col span={15}>
              <FlexContainer>
                <Select
                  defaultValue="EQUAL"
                  css={`
                    width: 100%;
                  `}
                  onChange={handleChangeOperator}
                  size="small"
                  value={operator}
                >
                  {options.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
                {operator === 'range' && (
                  <Text
                    css={`
                      position: relative;
                      top: 13px;
                    `}
                  >
                    and
                  </Text>
                )}
              </FlexContainer>
            </Col>
            <Col span={9}>
              <Space
                css={`
                  width: 100%;
                `}
                direction="vertical"
              >
                {operator === 'in' ? (
                  <InputNumber
                    min={1}
                    onChange={handleChangeInTheNext}
                    size="small"
                    value={inTheNext || undefined}
                  />
                ) : (
                  <Button
                    className="date-picker-button"
                    onClick={openDatePicker}
                    size="small"
                  >
                    {date ? moment(date, 'DDMMYYYY').format('DD/MM/YYYY') : ''}
                    <DatePicker
                      open={isDatePickerOpen}
                      onOpenChange={(e) => setDatePickerOpen(e)}
                      value={date ? moment(date, 'DDMMYYYY') : undefined}
                      onChange={(d) => {
                        if (d) {
                          handleChangeDateDue(d.format('DDMMYYYY'));
                        }
                      }}
                    />
                  </Button>
                )}
                {operator === 'range' && (
                  <Button
                    className="date-picker-button"
                    onClick={openEndDatePicker}
                    size="small"
                  >
                    {endDate
                      ? moment(endDate, 'DDMMYYYY').format('DD/MM/YYYY')
                      : ''}
                    <DatePicker
                      open={isEndDatePickerOpen}
                      onOpenChange={(e) => setEndDatePickerOpen(e)}
                      value={endDate ? moment(endDate, 'DDMMYYYY') : undefined}
                      onChange={(d) => {
                        if (d) {
                          handleChangeEndDate(d.format('DDMMYYYY'));
                        }
                      }}
                    />
                  </Button>
                )}
                {operator === 'in' && (
                  <Select
                    defaultValue="days"
                    css={`
                      width: 100%;
                    `}
                    onChange={handleChangeInTheNextSelection}
                    size="small"
                    value={inTheNextSelection}
                  >
                    {inTheNextOptions.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                )}
              </Space>
            </Col>
          </Row>
        </InputContainer>
      )}
    </FilterItem>
  );
};

export default DateDue;
