import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, notification, Switch } from 'antd';
import api from 'api';
import { Settings } from 'types/Company';
import { processError } from 'utils';
import spacing from 'styles/layout/spacing';
import { LoadingOutlined } from '@ant-design/icons';

export const PendingApprovalQueue: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isEnabled, setEnabled] = useState(false);
  const [settings, setSettings] = useState<Settings | null>(null);

  const entityId = useParams<{ id: string }>().id;

  useEffect(() => {
    setLoading(true);
    api.admin.entities
      .getEntitySettings({ entityId })
      .then((data) => {
        setSettings(data);
        setEnabled(data.isRipeEnabled);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [entityId]);

  const saveNotification = () => {
    const message = `Pending approval queue ${
      isEnabled ? 'enabled' : 'disabled'
    }`;
    notification.info({ message });
  };

  const handleSave = () => {
    if (!settings) {
      return;
    } else if (isEnabled === settings.isRipeEnabled) {
      saveNotification();
      setEditing(false);
      return;
    }
    setSaving(true);
    const payload = {
      id: settings.id,
      modificationTime: settings.modificationTime,
      value: isEnabled,
      path: '/isRipeLiteEnabled',
      operation: 'replace',
    };
    api.admin.entities
      .patchEntitySettings({ entityId, payload })
      .then((data) => {
        saveNotification();
        setSettings(data);
      })
      .catch((error) => {
        setEnabled(settings.isRipeEnabled);
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => {
        setEditing(false);
        setSaving(false);
      });
  };

  if (isLoading || !settings) {
    return (
      <Form.Item label="Pending Approval Queue">
        <LoadingOutlined />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item label="Pending Approval Queue">
        {isEditing ? (
          <Switch checked={isEnabled} onChange={() => setEnabled(!isEnabled)} />
        ) : isEnabled ? (
          'Enabled'
        ) : (
          'Disabled'
        )}
        <Button
          onClick={() => {
            isEditing ? handleSave() : setEditing(true);
          }}
          loading={isSaving}
          style={{ marginLeft: spacing.gutter.sm }}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Form.Item>
    );
  }
};
