import React, { useState } from 'react';
import { Space, Skeleton } from 'antd';
import { useSelector } from 'react-redux';

import {
  PeriodLockConfirmation,
  PeriodLockMessage,
  PeriodLockTable,
  Title,
} from './components';
import { State } from 'store/entityPreferences';
import { getMonthNameFromTimestamp } from 'utils';

export const PeriodLockSettings: React.FC = () => {
  const entityId: string = useSelector(
    (store: any) => store.auth.user.companyId
  );

  const entityPreferences: State = useSelector(
    (store: any) => store.entityPreferences
  );

  const [monthToLock, setMonthToLock] = useState<{
    monthName: string;
    timestamp: number;
  } | null>(null);

  const { isLoading, isUpdating, preferences } = entityPreferences;

  const lastLockedMonth = preferences?.periodLockDate
    ? getMonthNameFromTimestamp(preferences.periodLockDate)
    : null;

  const handleClickLock = (month: { monthName: string; timestamp: number }) =>
    setMonthToLock(month);

  const cancelLock = () => setMonthToLock(null);

  const onSuccess = () => setMonthToLock(null);

  return (
    <Space direction="vertical">
      <Title />
      <Skeleton active={true} loading={isLoading}>
        <Space direction="vertical">
          <PeriodLockMessage lastLockedMonth={lastLockedMonth} />
          <PeriodLockTable
            handleClickLock={handleClickLock}
            isLoading={isLoading || isUpdating}
            periodLockDate={preferences?.periodLockDate}
          />
          <PeriodLockConfirmation
            alreadyLocked={!!preferences?.periodLockDate}
            entityId={entityId}
            preferences={preferences}
            onCancel={cancelLock}
            onSuccess={onSuccess}
            monthToLock={monthToLock}
          />
        </Space>
      </Skeleton>
    </Space>
  );
};
