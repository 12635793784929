import React, { useState } from 'react';
import { Input, Select } from 'antd';
import PageTracker from 'components/PageTracker';
import { InputContainer } from 'components';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import styled from 'styled-components';
import { TitleSection } from 'components';

import api from 'api';
import processError from 'utils/processError';
import { StyledButton } from './ActivateAccount';

const Row = styled.div`
  display: flex;
`;

const INVALID_LINK_ERROR = 'The link might be broken or expired';
const PHONE_MISSING_ERROR = 'Please enter your phone number';
const PHONE_INCOMPLETE_ERROR =
  'Please enter all the digits in your phone number';

const PhoneNumberEntry = ({ history, location, setAlert, user, company }) => {
  const { token } = queryString.parse(location.search);

  const country = company?.address?.country;

  const [error, setError] = useState('');
  const [prefix, setPrefix] = useState(country === 'AU' ? '+61' : '+64');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  const onPhoneInput = ({ value }) => {
    setPhone(value);
    setError('');
  };

  const onPrefixChange = (value) => {
    setPrefix(value);
  };

  const onSubmit = () => {
    if (!phone) {
      setError(PHONE_MISSING_ERROR);
      return;
    }
    if (phone.length < 8) {
      setError(PHONE_INCOMPLETE_ERROR);
      return;
    }

    // Strip away the leading 0 if present
    const strippedNumber = phone[0] === '0' ? phone.slice(1) : phone;
    const phoneNumber = `${prefix}${strippedNumber}`;
    setLoading(true);
    const payload = {
      operation: 'replace',
      path: '/phone_number',
      value: phoneNumber,
    };
    api.user
      .patch({ payload, userId: user.id, token })
      .then(() => {
        api.user.code
          .send({ token, userId: user.id })
          .then(() => {
            history.push(`/activate?verify_code=true&token=${token}`, {
              phoneNumber,
            });
          })
          .catch((error) => {
            const { message, status } = processError(error);
            if (status === 401) {
              setAlert({
                message: 'Error',
                description: INVALID_LINK_ERROR,
                type: 'error',
              });
            } else {
              setAlert({
                message: 'Error',
                description: message,
                type: 'error',
              });
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        const { message, status } = processError(error);
        if (status === 401) {
          setAlert({
            message: 'Error',
            description: INVALID_LINK_ERROR,
            type: 'error',
          });
        } else {
          setError(message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageTracker />
      <TitleSection
        title="Secure your account"
        subTitle="Enter your number here and we’ll send you a security code."
      />
      <InputContainer error={error} label="Mobile phone number">
        <Row>
          <Select
            value={prefix}
            onChange={onPrefixChange}
            style={styles.select}
            size="large"
          >
            <Select.Option value={prefix}>{prefix}</Select.Option>
          </Select>
          <NumberFormat
            customInput={Input}
            format="### ### #####"
            onValueChange={onPhoneInput}
            placeholder="Enter your phone number..."
            value={phone}
            size="large"
          />
        </Row>
      </InputContainer>
      <StyledButton
        loading={loading}
        onClick={onSubmit}
        type="primary"
        size="large"
      >
        Send Code
      </StyledButton>
    </>
  );
};

const styles = {
  select: {
    width: 100,
    marginRight: 10,
    height: '100%',
  },
};

export default PhoneNumberEntry;
