import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Tabs as AntTabs, TabsProps } from 'antd';

const { TabPane } = AntTabs;

const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav::before {
    // Horizontal line below tabs list
    border-bottom: 1px solid #ddd;
  }

  &.ant-tabs > .ant-tabs-nav {
    margin: 0;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    margin-left: 16px;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0px 0px;
    color: ${colors.greys400};
    padding: 8px 16px;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    // Active tab
    background: ${colors.greys100};
    border: 1px solid #ddd;
    border-bottom: 1px solid transparent;
    color: ${colors.blues.dark};
  }

  .ant-tabs-tab-btn {
    font-weight: 700;
  }
`;

const Tabs = (props: TabsProps) => <StyledTabs {...props} />;

Tabs.TabPane = TabPane;

export default Tabs;
