import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button, Col, DatePicker, Row, Select, Space, Typography } from 'antd';
import { IssueDateOperator } from 'types/RipeCompanyInvoice';
import moment from 'moment';
import { FilterItem } from 'ds';

const { Text } = Typography;

const { Option } = Select;

const InputContainer = styled.div`
  padding: 7px 11px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const options = [
  { label: 'is equal to', value: 'eq' },
  { label: 'is between', value: 'between' },
  { label: 'is after', value: 'gt' },
  { label: 'is on or after', value: 'ge' },
  { label: 'is before', value: 'lt' },
  { label: 'is before or on', value: 'le' },
];

const IssueDate: React.FC<{
  date: string | null;
  endDate: string | null;
  handleChangeDate: (value: string) => void;
  handleChangeEndDate: (value: string) => void;
  handleChangeOperator: (value: IssueDateOperator) => void;
  handleCheck: (flag: boolean) => void;
  isChecked: boolean;
  operator: IssueDateOperator;
}> = ({
  date,
  endDate,
  handleChangeDate,
  handleChangeEndDate,
  handleChangeOperator,
  handleCheck,
  isChecked,
  operator,
}) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);

  const openDatePicker = () => setDatePickerOpen(true);

  const openEndDatePicker = () => setEndDatePickerOpen(true);

  return (
    <FilterItem
      label="Issue Date"
      isChecked={isChecked}
      handleCheck={handleCheck}
      css={`
        .date-picker-button {
          position: relative;
        }
        .ant-picker {
          visibility: hidden;
          width: 0;
          position: absolute;
          left: 0;
        }
      `}
    >
      {isChecked && (
        <InputContainer>
          <Row gutter={12}>
            <Col span={15}>
              <FlexContainer>
                <Select
                  defaultValue="eq"
                  css={`
                    width: 100%;
                  `}
                  onChange={handleChangeOperator}
                  size="small"
                  value={operator}
                >
                  {options.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
                {operator === 'between' && (
                  <Text
                    css={`
                      position: relative;
                      top: 13px;
                    `}
                  >
                    and
                  </Text>
                )}
              </FlexContainer>
            </Col>
            <Col span={9}>
              <Space direction="vertical">
                <Button
                  className="date-picker-button"
                  onClick={openDatePicker}
                  size="small"
                >
                  {date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                  <DatePicker
                    open={isDatePickerOpen}
                    onOpenChange={(e) => setDatePickerOpen(e)}
                    value={date ? moment(date, 'YYYY-MM-DD') : undefined}
                    onChange={(d) => {
                      if (d) {
                        handleChangeDate(d.format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Button>
                {operator === 'between' && (
                  <Button
                    className="date-picker-button"
                    onClick={openEndDatePicker}
                    size="small"
                  >
                    {endDate
                      ? moment(endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      : ''}
                    <DatePicker
                      open={isEndDatePickerOpen}
                      onOpenChange={(e) => setEndDatePickerOpen(e)}
                      value={
                        endDate ? moment(endDate, 'YYYY-MM-DD') : undefined
                      }
                      onChange={(d) => {
                        if (d) {
                          handleChangeEndDate(d.format('YYYY-MM-DD'));
                        }
                      }}
                    />
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </InputContainer>
      )}
    </FilterItem>
  );
};

export default IssueDate;
