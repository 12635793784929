import React, { useState } from 'react';
import styled from 'styled-components';
import FilterDialog from './FilterDialog';
import useFilter from './useFilter';
import { Button, Dropdown, Typography } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { CounterpartyFilters } from './CounterpartyFilters';

const { Text, Link } = Typography;

const ResetLink = styled(Link)`
  font-size: 15px;
  margin-left: 12px;
`;

type Props = {
  filters: CounterpartyFilters;
};

export const Filter: React.FC<Props> = ({ filters }) => {
  const [open, setOpen] = useState(false);

  const onVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const hideDropdown = () => {
    setOpen(false);
  };

  const {
    activeFilterCount,
    glCodeProps,
    processingQueueProps,
    paymentTermsProps,
    scheduledPaymentsProps,
    handleDone,
    handleReset,
  } = useFilter({ filters, hideDropdown });

  const text = activeFilterCount ? `Filter (${activeFilterCount})` : 'Filter';

  return (
    <>
      <Dropdown
        overlay={() => (
          <FilterDialog
            glCodeProps={glCodeProps}
            processingQueueProps={processingQueueProps}
            paymentTermsProps={paymentTermsProps}
            scheduledPaymentsProps={scheduledPaymentsProps}
            handleDone={handleDone}
            handleReset={handleReset}
            hideDropdown={hideDropdown}
          />
        )}
        trigger={['click']}
        onVisibleChange={onVisibleChange}
        placement="bottomRight"
        visible={open}
      >
        <Button onClick={(e) => e.preventDefault()}>
          <Text>{text}</Text>
          {open ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </Button>
      </Dropdown>
      {activeFilterCount > 0 && (
        <ResetLink onClick={handleReset}>Reset all</ResetLink>
      )}
    </>
  );
};
