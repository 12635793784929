import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import BillDetails from './BillDetails';
import CreditAllocations from './CreditAllocations';
import colors from 'colors';
import Invoice from 'types/Invoice';
import CreditNote from 'types/CreditNote';
import api from 'api';
import { Modal, Button, Skeleton } from 'antd';
import { ModalHeader, CreditNoteDetails } from 'components';

const FooterButton = styled(Button)`
  border-color: ${colors.blues.dark};
  color: ${colors.blues.dark};
  margin-top: 14px;
  min-width: 80px;
  :hover {
    background: ${colors.blues.lighter};
  }
  :active {
    background: #d6efff;
  }
`;
const StyledModal = styled(Modal)``;

export type ModalName =
  | 'BILL_DETAILS'
  | 'CREDIT_ALLOCATIONS'
  | 'VIEW_CREDIT_NOTE';

type Props = {
  type: 'BILL' | 'INVOICE';
  invoiceId: string;
  isOpen: boolean;
  closeModal: () => void;
  refreshTableData?: () => void;
};

const BillWithCreditNote: FC<Props> = ({
  type,
  invoiceId,
  isOpen,
  closeModal,
  refreshTableData,
}) => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [loading, setLoading] = useState(false);
  const [choosenModal, setChoosenModal] = useState<ModalName>('BILL_DETAILS');
  const [selectedCN, setSelectedCN] = useState<CreditNote | null>(null);

  useEffect(() => {
    setLoading(true);
    api.invoices
      .getSingle(invoiceId)
      .then((data) => setInvoice(data))
      .finally(() => setLoading(false));
  }, [invoiceId]);

  const changeModal = (value: ModalName) => {
    setChoosenModal(value);
  };

  const onClose = () => {
    closeModal();
  };
  const onClickCreditNote = (creditNoteId: string) => {
    setLoading(true);
    api.creditNotes
      .get(creditNoteId)
      .then((result) => {
        setSelectedCN(result);
        setChoosenModal('VIEW_CREDIT_NOTE');
      })
      .finally(() => setLoading(false));
  };

  return (
    <StyledModal
      title={
        <ModalHeader
          title={
            choosenModal === 'CREDIT_ALLOCATIONS'
              ? `Credit Applied to  ${
                  type === 'BILL' ? invoice?.billNumber : invoice?.invoiceNumber
                }`
              : choosenModal === 'VIEW_CREDIT_NOTE'
              ? `Credit Note #${selectedCN?.creditNumber}`
              : 'Bill Details'
          }
          onClose={onClose}
        />
      }
      closable={false}
      visible={isOpen}
      style={{ top: 60 }}
      bodyStyle={{
        paddingBottom: 0,
        paddingTop: 0,
        height: 530,
        border: '1px solid transparent',
      }}
      footer={[
        <FooterButton
          key="close"
          onClick={() => {
            if (choosenModal === 'CREDIT_ALLOCATIONS') {
              setChoosenModal('BILL_DETAILS');
            } else if (choosenModal === 'VIEW_CREDIT_NOTE') {
              setChoosenModal('CREDIT_ALLOCATIONS');
            } else {
              onClose();
            }
          }}
        >
          {choosenModal === 'CREDIT_ALLOCATIONS'
            ? 'Back'
            : choosenModal === 'VIEW_CREDIT_NOTE'
            ? 'Back'
            : 'Close'}
        </FooterButton>,
      ]}
    >
      {loading ? (
        <Skeleton />
      ) : choosenModal === 'CREDIT_ALLOCATIONS' ? (
        <CreditAllocations
          allocations={invoice?.creditNoteAllocations}
          allocatedAmount={invoice?.creditNoteValue || 0}
          onClickCreditNote={onClickCreditNote}
        />
      ) : choosenModal === 'VIEW_CREDIT_NOTE' && selectedCN ? (
        <CreditNoteDetails
          invoiceType={type}
          creditNote={selectedCN}
          scrollHeight={120}
        />
      ) : (
        <BillDetails invoice={invoice} onViewClick={changeModal} />
      )}
    </StyledModal>
  );
};

export default BillWithCreditNote;
