import React, { useEffect, useState } from 'react';
import api from 'api';
//eslint-disable-next-line
import styled from 'styled-components/macro';
import formatter from 'utils/currencyFormatter';
import moment from 'moment';
import queryString from 'query-string';
import SuppliersTab from './SuppliersTab';
import { BillsTabContainer, FilterChips, InvoiceTable } from 'components';
import { Tabs } from 'ds';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { clearCompanyNameToAddInvoice } from 'store/invoices';
import {
  getPurchaserOffersOffered,
  getSupplierOffersAvailable,
} from 'store/offers';
import { setToolbarTitle } from 'store/ui';
import { getAnalytics } from 'store/analytics';
import { getCompany, getBankAccounts } from 'store/auth';
import { getCompanySettings } from 'store/settings';

import EarlyPaymentOffer from 'components/EarlyPaymentOffer';
import EarlyPaymentOfferPanel from 'components/EarlyPaymentOfferPanel';
import { SummaryBlock } from 'components';
import InvoiceLoadTrigger from 'components/InvoiceLoadTrigger';
import PageTracker from 'components/PageTracker';
import buildUrl from './buildUrl';

const filterOptions = [
  {
    label: 'ALL',
    value: 'ALL_ELIGIBLE',
    supplierFilter: true,
    billFilter: true,
  },
  {
    label: 'Payment Offered',
    value: 'PAYMENT_OFFERED',
    supplierFilter: true,
    billFilter: true,
  },
  {
    label: 'Cashback Available',
    value: 'CASHBACK_AVAILABLE',
    supplierFilter: true,
    billFilter: true,
  },
  {
    label: 'Paid',
    value: 'PAID',
    supplierFilter: false,
    billFilter: true,
  },
  {
    label: 'Unallocated Credit',
    value: 'UNALLOCATED_CREDIT',
    supplierFilter: true,
    billFilter: false,
  },
];

const DEFAULT_STATUS_VALUE = filterOptions[0].value;

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 25;

const BillsWithSummary: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const locationParams = new URLSearchParams(location.search);

  const sortParam = locationParams.get('sort');
  const sizeParam = locationParams.get('size');
  const supplierParam = locationParams.get('supplier');

  const user = useSelector((store: any) => store.auth.user);
  const analytics = useSelector((store: any) => store.analytics);

  const { companyId } = user;
  const { discountedAmountPaid, cashbackReceived } = analytics;

  const [activeStatus, setActiveStatus] = useState<string>();

  useEffect(() => {
    dispatch(setToolbarTitle('Bills'));
    dispatch(clearCompanyNameToAddInvoice());

    if (companyId) {
      dispatch(getCompany(companyId));
      dispatch(getBankAccounts(companyId));
      api.company.settings.get({ companyId }).then((data) => {
        dispatch(getCompanySettings(data));
      });
    }

    dispatch(getSupplierOffersAvailable());
    dispatch(getPurchaserOffersOffered());
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(getAnalytics('bill'));
  }, [dispatch]);

  const { tab } = queryString.parse(location.search);
  const activeTab = tab && typeof tab === 'string' ? tab : 'suppliers';

  const statusParam =
    queryString.parse(location.search).status || DEFAULT_STATUS_VALUE;

  useEffect(() => {
    if (typeof statusParam === 'string') {
      const isValidStatus = filterOptions.some((item) =>
        activeTab === 'suppliers'
          ? item.value === statusParam && item.supplierFilter
          : item.value === statusParam && item.billFilter
      );
      isValidStatus && setActiveStatus(statusParam);
    }
  }, [activeTab, statusParam]);

  const handleTabChange = (selectedTab: string) => {
    setActiveStatus(DEFAULT_STATUS_VALUE);
    history.push(`/bills?tab=${selectedTab}&status=${DEFAULT_STATUS_VALUE}`);
  };

  const handleClickFilterChip = (selectedValue: string) => {
    const filter = filterOptions.find(
      (filter) => filter.value === selectedValue
    );

    if (!filter) {
      return;
    }

    if (activeTab === 'bills') {
      const params = new URLSearchParams(location.search);
      params.set('status', filter.value);
      history.push(`/bills?${params}`);
    } else {
      setActiveStatus(selectedValue);
      const url = buildUrl({
        base: location.pathname,
        status: selectedValue,
        sort: sortParam,
        page: DEFAULT_PAGE,
        size: Number(sizeParam) || DEFAULT_SIZE,
        tab: activeTab,
        supplier: supplierParam,
      });
      history.push(url);
    }
  };

  return (
    <div style={{ marginBottom: 50 }}>
      <Helmet>
        <title>Bills | Relay</title>
      </Helmet>
      <PageTracker />
      <InvoiceLoadTrigger />
      <Row gutter={[10, 32]} justify="space-between">
        <Col>
          <EarlyPaymentOfferPanel />
        </Col>
        <Col>
          <SummaryBlock
            title="CASHBACK RECEIVED"
            subTitle={moment().format('MMMM YYYY')}
            highlightedRowLabel="Amount Paid Promptly"
            highlightedRowValue={formatter.format(
              discountedAmountPaid ? discountedAmountPaid : 0
            )}
            label="Cashback Received"
            value={formatter.format(cashbackReceived ? cashbackReceived : 0)}
          />
        </Col>
      </Row>
      <EarlyPaymentOffer />

      <BillsTabContainer
        css={`
          margin-top: 5em;
        `}
        filterChips={
          <FilterChips
            isTableFilters
            onChange={handleClickFilterChip}
            options={
              activeTab === 'bills'
                ? filterOptions.filter((f) => f.billFilter)
                : filterOptions.filter((f) => f.supplierFilter)
            }
            selected={activeStatus}
          />
        }
      >
        <Tabs
          activeKey={activeTab}
          destroyInactiveTabPane
          onChange={handleTabChange}
          type="card"
        >
          <Tabs.TabPane tab="Suppliers" key="suppliers">
            <SuppliersTab
              isActive={activeTab === 'suppliers'}
              summaryStatus={activeStatus}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bills" key="bills">
            <InvoiceTable />
          </Tabs.TabPane>
        </Tabs>
      </BillsTabContainer>
    </div>
  );
};

export default BillsWithSummary;
