import api from 'api';
import SupplierOffer, {
  OfferExecutePayload,
  OfferExecutionResponse,
} from 'types/SupplierOffer';
import { processError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalytics } from 'store/analytics';
import { getSupplierOffersAvailable } from 'store/offers';
import { showTopUpWindow } from 'store/auth';
import { useCallback, useState } from 'react';
import { setRefreshData } from 'store/ui';
import { notification } from 'antd';

const usePayInvoice = () => {
  const dispatch = useDispatch();

  const [isPending, setPending] = useState(false);

  const fundsAvailable = useSelector(
    (store: any) => store.auth?.user?.company?.company?.fundsAvailable
  );
  const payInvoice = useCallback(
    ({
      hideModal,
      supplierOffer,
      amountDue,
      selectedInvoiceId,
      onSuccessPayInvoice,
    }: {
      hideModal: () => void;
      onSuccessPayInvoice: () => void;
      supplierOffer: SupplierOffer;
      amountDue?: number;
      selectedInvoiceId?: string;
    }) => {
      if (supplierOffer && amountDue) {
        const showTopup = amountDue > parseFloat(fundsAvailable);
        if (showTopup && amountDue && selectedInvoiceId) {
          hideModal();
          dispatch(
            showTopUpWindow({
              trigger: 'SUPPLIER_OFFER',
              triggerAmount: amountDue,
              crr: 0,
              selectedInvoice: { id: selectedInvoiceId },
            })
          );
        } else {
          const payload: OfferExecutePayload = {
            invoiceIds: supplierOffer.invoiceIds,
            state: 'EXECUTE',
          };
          setPending(true);

          api.supplierOffers
            .execute({ params: payload, uriParam: supplierOffer.id })
            .then((res: OfferExecutionResponse) => {
              if (res?.status === 'FAILED') {
                if (res?.invoicesExecutionResult[0]?.status === 'PENDING') {
                  // The offer is in PENDING state.
                  // We don't need to show error.
                  // The bill will be paid once the topup succeeds.
                  dispatch(setRefreshData(true));
                  dispatch(getAnalytics('bill'));
                  onSuccessPayInvoice();
                } else {
                  notification.error({
                    message: res?.invoicesExecutionResult[0]?.reason,
                  });
                }
              } else {
                dispatch(setRefreshData(true));
                dispatch(getAnalytics('bill'));
                onSuccessPayInvoice();
              }
              dispatch(getSupplierOffersAvailable());
            })
            .catch((error) => {
              const { message } = processError(error);
              notification.error({ message });
            })
            .finally(() => setPending(false));
        }
      }
    },
    [dispatch, fundsAvailable]
  );

  return {
    isPending,
    payInvoice,
  };
};

export default usePayInvoice;
