import React, { useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import api from 'api';
import currencyFormatter, { formatCurrency } from 'utils/currencyFormatter';
import { useSelector } from 'react-redux';
import { Button, Typography, Form, Input, notification } from 'antd';

import processError from 'utils/processError';
import spacing from 'styles/layout/spacing';

declare global {
  interface Window {
    ripeAuthorisedAmountOffset: any;
  }
}

const ripeAuthorisedAmountOffset = window.ripeAuthorisedAmountOffset;

const DEFAULT_OFFSET = 5; // In dollars

const { Text } = Typography;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: ${spacing.gutter.sm};
  column-gap: ${spacing.gutter.sm};
`;

const StyledForm = styled(Form)`
  flex: 1;
`;

const ErrorText = styled(Text)`
  position: relative;
`;

type Props = {
  onAuthorisationSuccess: () => void;
  documentId: string;
  invoiceAmount: string | null;
  posAmount: string;
  isPendingApproval: boolean;
  disabled: boolean;
};

const Authorise: React.FC<Props> = ({
  onAuthorisationSuccess,
  documentId,
  invoiceAmount,
  posAmount,
  isPendingApproval,
  disabled,
}) => {
  const companyId = useSelector((store: any) => store.auth.user?.companyId);

  const [authorisedAmount, setAuthorisedAmount] = useState('');
  const [authorisedAmountError, setAuthorisedAmountError] = useState('');
  const [authorising, setAuthorising] = useState(false);
  const [form] = Form.useForm();

  const handleAuthorise = (amount: string) => {
    if (!amount) {
      setAuthorisedAmountError('Authorised amount is required');
      return;
    }
    if (parseFloat(amount) < 0) {
      setAuthorisedAmountError('Authorised amount should be greater than 0');
      return;
    }
    const limitOffset =
      ripeAuthorisedAmountOffset && ripeAuthorisedAmountOffset[companyId]
        ? ripeAuthorisedAmountOffset[companyId]
        : DEFAULT_OFFSET;
    const baseAmount = isPendingApproval ? invoiceAmount || '0' : posAmount;
    const limit = parseFloat(baseAmount) + limitOffset;
    const limitToDisplay = currencyFormatter.format(limit);
    if (parseFloat(amount) > limit) {
      setAuthorisedAmountError(
        `Authorised amount should not be greater than ${limitToDisplay}`
      );
      return;
    }
    setAuthorisedAmountError('');
    setAuthorising(true);
    api.ripe
      .authoriseDocument({
        documentId,
        authorisedAmount: amount,
      })
      .then(() => {
        const message = 'Document authorised';
        notification.success({ message });
        onAuthorisationSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => {
        setAuthorising(false);
      });
  };

  return (
    <>
      <Row>
        {!isPendingApproval && (
          <StyledForm
            form={form}
            onFinish={() => handleAuthorise(authorisedAmount)}
          >
            <Form.Item
              validateStatus={authorisedAmountError && 'error'}
              style={{ marginBottom: 0 }}
            >
              <NumberFormat
                customInput={Input}
                value={authorisedAmount}
                onChange={(e) => {
                  if (!e.target.value) {
                    setAuthorisedAmount('');
                  }
                  if (isNaN(parseFloat(e.target.value))) {
                    return;
                  } else {
                    setAuthorisedAmount(e.target.value);
                  }
                }}
                style={{ height: 32 }}
                placeholder="Amount"
                disabled={authorising}
              />
            </Form.Item>
          </StyledForm>
        )}
        <Button
          type="primary"
          onClick={() => {
            if (isPendingApproval && invoiceAmount) {
              handleAuthorise(invoiceAmount);
            } else {
              form.submit();
            }
          }}
          loading={authorising}
          block={isPendingApproval}
          disabled={disabled}
        >
          Authorise {isPendingApproval && formatCurrency(Number(invoiceAmount))}
        </Button>
      </Row>
      {!!authorisedAmountError && (
        <ErrorText type="danger">{authorisedAmountError}</ErrorText>
      )}
    </>
  );
};

export default Authorise;
