import React from 'react';
import styled from 'styled-components/macro';
import colors from 'colors';
import { Tooltip, Typography } from 'antd';
import { PayablesSummaryDetailsRecord } from 'types/PayablesSummary';
import { StatusDot } from 'ds';
import { formatCurrency } from 'utils';

const { Text } = Typography;

const Container = styled.div<{ $clickable: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: ${(props) => props.$clickable && 'pointer'};
`;

const purple = '#9666FF';

type Props = {
  total: PayablesSummaryDetailsRecord['total'];
  totalEligible: PayablesSummaryDetailsRecord['totalEligible'];
  type: PayablesSummaryDetailsRecord['type'];
  onClick?: () => void;
};

const Amount: React.FC<Props> = ({ total, totalEligible, type, onClick }) => {
  const isCreditAllocated = type === 'BILL' && total !== totalEligible;
  const isClickable = !!onClick && isCreditAllocated;
  const prefix = type === 'CREDIT_NOTE' && totalEligible !== 0 ? '-' : '';
  return (
    <Container
      $clickable={isClickable}
      onClick={isClickable ? onClick : undefined}
    >
      <Text
        css={
          isCreditAllocated
            ? `border-bottom: 1px dashed ${colors.greys900};`
            : ''
        }
      >{`${prefix}${formatCurrency(totalEligible)}`}</Text>
      {isCreditAllocated && (
        <Tooltip
          color={purple}
          placement="topRight"
          title={isCreditAllocated ? 'Credit Applied' : ''}
        >
          <StatusDot
            color={purple}
            css={`
              position: absolute;
              right: -15px;
              z-index: 100;
            `}
          />
        </Tooltip>
      )}
    </Container>
  );
};

export default Amount;
