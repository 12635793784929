import api from 'api';
import User from 'types/User';
import { Dispatch } from 'redux';

const SET_LOADING = 'relay/user/SET_LOADING';
const SET_USER = 'relay/user/SET_USER';
const LOGOUT = 'LOGOUT';

type LogoutAction = {
  type: 'LOGOUT';
};

type SetLoadingAction = {
  type: typeof SET_LOADING;
  payload: boolean;
};

type SetUserAction = {
  type: typeof SET_USER;
  payload: User;
};

const initialState: { user: User | null; isLoading: boolean } = {
  user: null,
  isLoading: false,
};

const reducer = (
  state: typeof initialState = initialState,
  action: SetLoadingAction | SetUserAction | LogoutAction
) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const getUser = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api.user
      .get()
      .then((user) => dispatch({ type: SET_USER, payload: user }))
      .finally(() => dispatch({ type: SET_LOADING, payload: false }));
  };
};

export default reducer;
