import axios from 'axios';
import {
  RipeCompanyInvoiceAPIResolve,
  InvoiceStatistics,
} from 'types/RipeCompanyInvoice';

import RipeCompanyInvoice, {
  ProcessingStatus,
  RipeCompanyInvoiceAPIResponse,
  RipeCompanyInvoiceResource,
} from 'types/RipeCompanyInvoice';
import { readImageFromZip } from 'utils';
import UnionConcat from 'types/UnionConcat';

type GetDocumentsParams = {
  abortController?: AbortController;
  processingStatus?: ProcessingStatus;
  pageNumber?: number;
  pageSize?: number;
  reviewedInRelay?: boolean;
  paramsString?: string;
  matchStatus?: string;
  matchStatuses?: string;
  authorisedAmount?: number;
  searchParam?: string;
  filterParams?: string;
  sort?: string;
};
type GetStatisticsParams = {
  processingStatus?: ProcessingStatus;
  processingStatuses?: UnionConcat<ProcessingStatus, ','>;
  reviewedInRelay?: boolean;
  matchStatus?: string;
  matchStatuses?: string;
  authorisedAmount?: number;
  supplierName?: string;
};
const getDocuments = ({
  abortController,
  processingStatus,
  pageNumber,
  pageSize,
  reviewedInRelay,
  paramsString,
  matchStatus,
  authorisedAmount,
  searchParam,
  filterParams,
  sort,
  matchStatuses,
}: GetDocumentsParams): Promise<RipeCompanyInvoiceAPIResolve> =>
  new Promise<RipeCompanyInvoiceAPIResolve>((resolve, reject) => {
    let urlToUse = '/ripe/documents?';
    if (paramsString) {
      urlToUse += paramsString;
    } else {
      if (processingStatus && processingStatus.length > 0) {
        urlToUse += `processingStatus=${processingStatus}&`;
      }
      if (matchStatus && matchStatus.length > 0) {
        urlToUse += `matchStatus=${matchStatus}&`;
      }
      if (matchStatuses && matchStatuses.length > 0) {
        urlToUse += `matchStatuses=${matchStatuses}&`;
      }
      if (reviewedInRelay !== undefined) {
        urlToUse += `reviewedInRelay=${reviewedInRelay}&`;
      }
      if (authorisedAmount !== undefined) {
        urlToUse += `authorisedAmount=${authorisedAmount}&`;
      }
      if (searchParam) {
        urlToUse += searchParam;
      }
      if (filterParams) {
        urlToUse += filterParams;
      }
      if (sort) {
        urlToUse += sort;
      }
      urlToUse += `page=${pageNumber}&size=${pageSize}`;
    }
    axios
      .get(urlToUse, { signal: abortController?.signal })
      .then(({ data }: { data: RipeCompanyInvoiceAPIResponse }) => {
        const {
          ripeCompanyInvoiceResources,
          pageNumber,
          pageSize,
          totalPages,
          totalElements,
          invoiceStatistics,
          _links,
        } = data;
        const docs: RipeCompanyInvoice[] = ripeCompanyInvoiceResources.map(
          (item) => item.ripeCompanyInvoice
        );

        resolve({
          docs,
          pageNumber,
          pageSize,
          totalPages,
          totalElements,
          invoiceStatistics,
          _links,
        });
      })
      .catch((error) => reject(error));
  });

const getDocument = (
  documentId: RipeCompanyInvoice['documentId']
): Promise<RipeCompanyInvoice> =>
  new Promise<RipeCompanyInvoice>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}`;
    axios
      .get(url)
      .then(({ data }: { data: RipeCompanyInvoiceResource }) =>
        resolve(data.ripeCompanyInvoice)
      )
      .catch((error) => reject(error));
  });

const patchDocumentProcessingStatus = (
  document: RipeCompanyInvoice
): Promise<RipeCompanyInvoice> =>
  new Promise<RipeCompanyInvoice>((resolve, reject) => {
    const url = `/ripe/documents/${document.documentId}/processing-status`;
    axios
      .patch(url, {
        processingStatus: document.processingStatus,
      })
      .then(({ data }: { data: RipeCompanyInvoiceResource }) =>
        resolve(data.ripeCompanyInvoice)
      )
      .catch((error) => reject(error));
  });

const putDocument = (
  document: RipeCompanyInvoice
): Promise<RipeCompanyInvoice> =>
  new Promise<RipeCompanyInvoice>((resolve, reject) => {
    const url = `/ripe/documents/${document.documentId}`;
    axios
      .put(url, document)
      .then(({ data }: { data: RipeCompanyInvoiceResource }) =>
        resolve(data.ripeCompanyInvoice)
      )
      .catch((error) => reject(error));
  });

const getStatistics = (params: GetStatisticsParams) =>
  new Promise<InvoiceStatistics>((resolve, reject) => {
    axios
      .get('/ripe/documents/statistics', { params })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });

const getInvoice = (
  documentId: RipeCompanyInvoice['documentId']
): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/invoice`;
    axios
      .get(url)
      .then(({ data }: { data: string }) => resolve(data))
      .catch((error) => reject(error));
  });

const reviewDocument = ({
  state,
  documentId,
}: {
  state: 'CONFIRM' | 'DELETE';
  documentId: RipeCompanyInvoice['documentId'];
}): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/review`;
    const payload = { state };
    axios
      .post(url, payload)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });

const batchReviewDocuments = (payload: {
  documentsToReview: Array<{
    documentId: number | string;
    state: 'CONFIRM' | 'DELETE';
  }>;
}): Promise<string> =>
  new Promise((resolve, reject) => {
    const url = '/ripe/documents/review';
    axios
      .post(url, payload)
      .then(({ data }: { data: string }) => resolve(data))
      .catch((error) => reject(error));
  });

const getBatchReviewStatus = (
  reviewId: string
): Promise<{
  progress: string;
  total: number;
  success: number;
  failed: number;
  failedDocuments: string[];
}> =>
  new Promise((resolve, reject) => {
    const url = `/ripe/documents/review/${reviewId}/status`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });

const authoriseDocument = ({
  documentId,
  authorisedAmount,
}: {
  documentId: RipeCompanyInvoice['documentId'];
  authorisedAmount: string;
}): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/authorise`;
    const payload = { authorisedAmount };
    axios
      .post(url, payload)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });

const putDocumentInvoiceContent = ({
  documentId,
  payload,
}: {
  documentId: RipeCompanyInvoice['documentId'];
  payload: RipeCompanyInvoice;
}): Promise<RipeCompanyInvoiceResource> =>
  new Promise<RipeCompanyInvoiceResource>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}`;
    axios
      .put(url, payload)
      .then(({ data }: { data: RipeCompanyInvoiceResource }) => resolve(data))
      .catch((error) => reject(error));
  });

const lockDocument = ({
  documentId,
}: {
  documentId: RipeCompanyInvoice['documentId'];
}): Promise<RipeCompanyInvoice> =>
  new Promise<RipeCompanyInvoice>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/lock`;
    axios
      .post(url)
      .then(({ data }: { data: RipeCompanyInvoiceResource }) =>
        resolve(data.ripeCompanyInvoice)
      )
      .catch((error) => reject(error));
  });

const unlockDocument = ({
  documentId,
}: {
  documentId: RipeCompanyInvoice['documentId'];
}): Promise<RipeCompanyInvoice> =>
  new Promise<RipeCompanyInvoice>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/unlock`;
    axios
      .post(url)
      .then(({ data }: { data: RipeCompanyInvoiceResource }) =>
        resolve(data.ripeCompanyInvoice)
      )
      .catch((error) => reject(error));
  });

const getInvoiceImage = (
  documentId: RipeCompanyInvoice['documentId']
): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/page`;
    axios
      .get(url)
      .then(({ data }: { data: any }) => {
        const { pageId } = data[0];
        const urlForContent = `/ripe/documents/${documentId}/page/${pageId}`;
        axios
          .get(urlForContent, {
            headers: { 'content-type': 'application/zip' },
            responseType: 'arraybuffer',
          })
          .then(({ data }: { data: any }) => {
            return resolve(Buffer.from(data, 'binary').toString('base64'));
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });

const getInvoiceImageByContentUrl = (urlForContent: string): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    axios
      .get(urlForContent, {
        responseType: 'arraybuffer',
      })
      .then(({ data }: { data: any }) => {
        return resolve(Buffer.from(data, 'binary').toString('base64'));
      })
      .catch((error) => reject(error));
  });

const getZippedInvoiceImageByContentUrl = (
  urlForContent: string
): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    axios
      .get(urlForContent, {
        data: {},
        headers: { 'Content-Type': 'application/zip' },
        responseType: 'arraybuffer',
      })
      .then(async ({ data }: { data: ArrayBuffer }) => {
        const imageUrl = await readImageFromZip(data);
        return resolve(imageUrl);
      })
      .catch((error) => reject(error));
  });

const getInvoicePages = (
  documentId: RipeCompanyInvoice['documentId']
): Promise<string[]> =>
  new Promise<string[]>((resolve, reject) => {
    const url = `/ripe/documents/${documentId}/page`;
    const invoicePages: string[] = [];
    axios
      .get(url)
      .then(({ data }: { data: any }) => {
        if (data && data.length > 0) {
          data.forEach((page: { pageId: any }) => {
            const { pageId } = page;
            invoicePages.push(`/ripe/documents/${documentId}/page/${pageId}`);
          });
        }
        return resolve(invoicePages);
      })
      .catch((error) => reject(error));
  });

export default {
  getDocuments,
  getDocument,
  patchDocumentProcessingStatus,
  putDocument,
  getStatistics,
  getInvoice,
  reviewDocument,
  batchReviewDocuments,
  getBatchReviewStatus,
  authoriseDocument,
  putDocumentInvoiceContent,
  lockDocument,
  unlockDocument,
  getInvoiceImage,
  getInvoicePages,
  getInvoiceImageByContentUrl,
  getZippedInvoiceImageByContentUrl,
};
