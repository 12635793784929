import React, { useState } from 'react';
import api from 'api';
import { processError } from 'utils';
import { BulkActionButton } from 'components';
import { Modal, notification } from 'antd';

const { confirm } = Modal;

const BulkDeleteButton: React.FC<{
  selectedInvoiceIDs: string[];
  invoiceType: 'BILL' | 'INVOICE';
  onSuccess: () => void;
}> = ({ selectedInvoiceIDs, invoiceType, onSuccess }) => {
  const [isPending, setIsPending] = useState(false);

  const count = selectedInvoiceIDs.length;

  const noun = `${invoiceType.toLowerCase()}${count === 1 ? '' : 's'}`;

  const content = `Are you sure you want to delete ${count} ${noun}?`;

  const deleteSingle = () => {
    const invoiceIdToDelete = selectedInvoiceIDs[0];
    setIsPending(true);
    api.invoices
      .remove(invoiceIdToDelete)
      .then(() => {
        const message = `Deleted ${noun}`;
        notification.info({ message });
        onSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setIsPending(false));
  };

  const deleteMultiple = () => {
    setIsPending(true);
    api.invoices
      .bulkDelete({ invoiceIds: selectedInvoiceIDs, type: invoiceType })
      .then((response) => {
        const deletedCount = response.results.reduce(
          (count, item) => (item.status === 'SUCCESS' ? count + 1 : count),
          0
        );
        let message = '';
        if (deletedCount === count) {
          message = `Deleted ${count} ${invoiceType.toLowerCase()}${
            count === 1 ? '' : 's'
          }`;
        } else {
          message = `Deleted ${deletedCount} out of ${count} ${invoiceType.toLowerCase()}${
            count === 1 ? '' : 's'
          }`;
        }
        notification.info({ message });
        onSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setIsPending(false));
  };

  const handleClick = () => {
    confirm({
      title: 'Confirm Delete',
      content,
      onOk: count === 1 ? deleteSingle : deleteMultiple,
    });
  };

  return (
    <BulkActionButton loading={isPending} onClick={handleClick}>
      DELETE
    </BulkActionButton>
  );
};

export default BulkDeleteButton;
