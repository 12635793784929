import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setToolbarTitle } from 'store/ui';
import { Helmet } from 'react-helmet';

import CreateNewInvoice from 'components/CreateNewInvoice';

class CreateInvoice extends Component {
  componentDidMount() {
    const { location, setToolbarTitle } = this.props;
    setToolbarTitle(
      location && location.state.type === 'INVOICE'
        ? 'Create Invoice'
        : 'Create Bill'
    );
  }

  render() {
    const { location } = this.props;
    return <>
    <Helmet>
      <title> {location && location.state.type === 'INVOICE'
        ? 'Create Invoice'
        : 'Create Bill'} | Relay</title>
    </Helmet>
    <CreateNewInvoice type={location && location.state.type} />
    </>
  }
}

export default connect(
  null,
  { setToolbarTitle }
)(CreateInvoice);
