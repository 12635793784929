import React from 'react';
import styled from 'styled-components';

import formatter from 'utils/currencyFormatter';
import Ruler from 'components/Ruler';
import colors from 'colors';

const Container = styled.div`
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const Text = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.greys600};
  padding-left: 10px;
  padding-right: 10px;
`;

type Props = { amount: number };

const InfoRow: React.FC<Props> = ({ amount }) => {
  return (
    <Container>
      <Row>
        <Text>FUNDS AVAILABLE</Text>
        <Text>{`${formatter.format(amount)}`}</Text>
      </Row>
      <Ruler />
    </Container>
  );
};

export default InfoRow;
