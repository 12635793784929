import React from 'react';
import styled from 'styled-components';

import { Title } from './Title';
import { Row } from './Row';
import { TagProps } from './types';

import breakpoints from 'styles/layout/breakpoints';
import colors from 'colors';
import { LoadingIndicator } from './LoadingIndicator';

const Container = styled.div`
  width: ${breakpoints.mobile};
  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const Line = styled.div`
  border-top: 1px solid ${colors.greys200}; ;
`;

type Props = {
  className?: string;
  title?: string;
  subTitle?: string | JSX.Element;
  highlightedRowLabel?: string;
  highlightedRowValue?: string;
  label?: string;
  value?: string;
  fromAccount?: boolean;
  hideFooterLine?: boolean;
  loading?: boolean;
  tag?: TagProps;
};

export const SummaryBlock: React.FC<Props> = (props) => {
  const {
    className,
    title,
    subTitle,
    highlightedRowLabel,
    highlightedRowValue,
    label,
    value,
    fromAccount,
    hideFooterLine,
    loading,
    tag,
  } = props;

  return (
    <Container className={className}>
      <Title title={title} subTitle={subTitle} />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Row
            label={highlightedRowLabel}
            highlighted
            value={highlightedRowValue}
            fromAccount={fromAccount}
          />
          <Row prominent value={value} label={label} tag={tag} />
        </>
      )}
      {!hideFooterLine && <Line />}
    </Container>
  );
};
