import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { setToolbarTitle } from 'store/ui';
import {
  showAddBankAccountWindow,
  getCompany,
  getBankAccounts as getBankAccounts_toDeprecate,
} from 'store/auth';
import { getTransactions, bootstrapTransactions } from 'store/transactions';
import { getPurchaserOffersOffered } from 'store/offers';
import { getUser } from 'store/user';
import { getBankAccounts } from 'store/bankAccounts';

import accountNumberFormatter from 'utils/accountNumberFormatter';
import formatter from 'utils/currencyFormatter';
import { SummaryBlock } from 'components';
import Transactions from 'components/Transactions';
import PageTracker from 'components/PageTracker';
import BankAccountActions from './BankAccountActions';

import api from 'api';
import featureFlags from 'config/featureFlags';

const StyledBankAccountActions = styled(BankAccountActions)`
  margin-top: 10px;
`;

const StyledSubtitle = styled.span`
  white-space: nowrap;
`;

class Account extends Component {
  constructor() {
    super();
    this.state = { loadingExport: false };
  }

  componentDidMount() {
    this.props.setToolbarTitle('Account');

    const { user } = this.props;
    const { companyId } = user;

    const countryCode = user.company?.company?.address?.countryCode;

    this.props.getUser();

    this.props.getBankAccounts(companyId, countryCode);

    this.props.getCompany(companyId);
    this.props.bootstrapTransactions({ size: this.props.pageSize });

    const connectedToXero =
      user.company.company && user.company.company.connectedToXero;
    const connectedToMyobEssentials =
      user.company.company && user.company.company.connectedToMyobEssentials;
    const connectedToMyobAccountRight =
      user.company.company && user.company.company.connectedToMyobAccountRight;

    (connectedToMyobEssentials || connectedToMyobAccountRight) &&
      api.myob.triggerAccountsLoad({
        myobAccount: connectedToMyobEssentials ? 'Essentials' : 'Account Right',
      });
    connectedToXero && api.xero.triggerAccountsLoad();

    this.props.getBankAccounts_toDeprecate(companyId, countryCode);
    this.props.getPurchaserOffersOffered();
  }

  exportTransactions = () => {
    this.setState({ loadingExport: true });
    api.account
      .exportTransactions()
      .then(({ data }) => {
        this.setState({ loadingExport: false });
        let hiddenElement = document.createElement('a');
        hiddenElement.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'transactions.csv';
        hiddenElement.click();
      })
      .catch(() => {
        this.setState({ loadingExport: false });
      });
  };

  render() {
    const {
      user,
      purchaserOffers,
      transactions,
      totalNumberOfTransactions,
      loadingTransactions,
    } = this.props;
    const { company } = user.company;
    const { account } = user;

    const countryCode = company?.address?.countryCode;

    const purchaserOffersOffered = purchaserOffers.filter(
      (offer) =>
        offer.purchaserOffer.offerStatus === 'ACTIVE' ||
        offer.purchaserOffer.offerStatus === 'TOPUP_PENDING'
    );
    const cashPoolAmount = purchaserOffersOffered.length
      ? purchaserOffersOffered[0].purchaserOffer.cashpool
      : 0;

    const fromBankAccount =
      account &&
      account.length &&
      account.filter((acc) => acc.loadedBy === 'SYSTEM')[0];

    let accountNumber = fromBankAccount
      ? fromBankAccount.bankAccountNumber
      : '';
    let formattedAccountNumber = (
      <StyledSubtitle>{accountNumberFormatter(accountNumber)}</StyledSubtitle>
    );
    const { loadingExport } = this.state;

    return (
      <>
        <Helmet>
          <title>Account | Relay</title>
        </Helmet>
        <PageTracker />
        <Row>
          <Col>
            <Row>
              <SummaryBlock
                title={
                  countryCode === 'AU'
                    ? 'YOUR RELAY PAYID'
                    : 'WORKING CAPITAL ACCOUNT'
                }
                subTitle={
                  countryCode === 'AU'
                    ? fromBankAccount?.payId
                    : formattedAccountNumber
                }
                highlightedRowLabel="Cash Reserve"
                highlightedRowValue={`${formatter.format(cashPoolAmount)}`}
                label="Funds Available"
                value={company && `${formatter.format(company.fundsAvailable)}`}
                fromAccount
              />
            </Row>
            <StyledBankAccountActions />
          </Col>
        </Row>

        <Row
          align="bottom"
          css={`
            margin-top: 30px;
          `}
          justify="space-between"
        >
          <Typography.Text
            css={`
              font-size: 14px;
            `}
            strong
          >
            TRANSACTIONS
          </Typography.Text>
          {featureFlags.exportTransactions && (
            <Button
              onClick={() => this.exportTransactions()}
              loading={loadingExport}
              small
            >
              <ExportOutlined />
              Export
            </Button>
          )}
        </Row>
        <Divider
          css={`
            margin-top: 10px;
            margin-bottom: 20px;
          `}
        />
        <Transactions
          transactions={transactions}
          companyId={user.companyId}
          loadingTransactions={loadingTransactions}
          totalNumberOfTransactions={totalNumberOfTransactions}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, offers, transactions }) => ({
  user: auth.user,
  purchaserOffers: offers.purchaserOffers.offered,
  transactions: transactions.transactions,
  totalNumberOfTransactions: transactions.totalNumberOfTransactions,
  loadingTransactions: transactions.loadingTransactions,
  pageSize: transactions.pageSize,
  pageNumber: transactions.pageNumber,
});
const mapDispatchToProps = {
  showAddBankAccountWindow,
  setToolbarTitle,
  getCompany,
  getBankAccounts_toDeprecate,
  getTransactions,
  bootstrapTransactions,
  getPurchaserOffersOffered,
  getUser,
  getBankAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
