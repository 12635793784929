import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Table, Typography, notification } from 'antd';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { setToolbarTitle } from 'store/ui';
import api from 'api';
import moment from 'moment';
import processError from 'utils/processError';
import accountNumberFormatter from 'utils/accountNumberFormatter';
import BankAccount from 'types/BankAccount';

const { Text } = Typography;

const initialDocument = { accountId: '', uri: '' };

type Props = {
  match: any;
  setToolbarTitle: any;
  token: string;
};
const CompanyBankAccounts: React.FC<Props> = ({ match, setToolbarTitle }) => {
  const { companyId } = match.params;

  const [companyName, setCompanyName] = useState('');
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [document, setDocument] = useState(initialDocument);

  useEffect(() => {
    setToolbarTitle('Bank Accounts');
  }, [setToolbarTitle]);

  useEffect(() => {
    api.company.get({ companyId }).then((response) => {
      const { companyName } = response.company;
      setCompanyName(companyName);
      setToolbarTitle(`${companyName} - Bank Accounts`);
      api.company
        .getBankAccounts({ companyId })
        .then((accounts) => {
          setAccounts(accounts);
        })
        .finally(() => setLoading(false));
    });
  }, [companyName, companyId, setToolbarTitle]);

  const viewDocument = (
    bankAccountId: BankAccount['id'],
    bankAccountNumber: BankAccount['bankAccountNumber']
  ): void => {
    setDocument({ accountId: bankAccountId, uri: '' });
    setAccountNumber(bankAccountNumber);
    api.company
      .getBankAccountDocumentLink({ companyId, bankAccountId })
      .then((uri) => setDocument({ accountId: bankAccountId, uri }))
      .catch((error) => {
        setDocument(initialDocument);
        const { message } = processError(error);
        notification.error({
          message: 'Error',
          description: message,
        });
      });
  };

  const onUpdate = (
    account: BankAccount,
    status: 'VERIFIED' | 'VERIFICATION_FAILED'
  ) => {
    setLoading(true);
    api.admin.bankAccounts
      .put({ ...account, bankAccountStatus: status })
      .then((updatedAccount) => {
        const updatedAccounts = accounts.map((account) =>
          account.id === updatedAccount.id ? updatedAccount : account
        );
        setAccounts(updatedAccounts);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message: 'Error',
          description: message,
        });
      })
      .finally(() => setLoading(false));
  };

  const onCloseDocument = (): void => {
    setDocument(initialDocument);
    setAccountNumber(null);
  };

  const columns = [
    {
      title: 'Account Name',
      key: 'name',
      render: (account: BankAccount) => {
        const { bankAccountName, bankAccountStatus } = account;
        return (
          <Text strong={bankAccountStatus === 'VERIFICATION_PENDING'}>
            {bankAccountName}
          </Text>
        );
      },
    },
    {
      title: 'BSB',
      key: 'bsb',
      dataIndex: 'bsb',
      render: (bsb: string) => bsb || '-',
    },
    {
      title: 'Account Number',
      key: 'number',
      render: (account: BankAccount) => {
        const { bankAccountNumber, bankAccountStatus } = account;
        return bankAccountNumber ? (
          <Text strong={bankAccountStatus === 'VERIFICATION_PENDING'}>
            {accountNumberFormatter(bankAccountNumber)}
          </Text>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'PayID',
      key: 'payid',
      dataIndex: 'payId',
      render: (payId: string) => payId || '-',
    },
    {
      title: 'Last updated on',
      key: 'timestamp',
      render: (account: BankAccount) => {
        const { modificationTime } = account;
        return (
          <span>
            {moment(modificationTime).format('D MMM YYYY')}
            <br />
            {moment(modificationTime).format('hh:mm A')}
          </span>
        );
      },
    },
    {
      title: 'Status',
      key: 'number',
      render: (account: BankAccount) => {
        const { bankAccountStatus } = account;
        return bankAccountStatus === 'UNVERIFIED' ? (
          'Unverified'
        ) : bankAccountStatus === 'VERIFICATION_PENDING' ? (
          <Text strong>Verification pending</Text>
        ) : bankAccountStatus === 'VERIFIED' ? (
          'Verified'
        ) : bankAccountStatus === 'VERIFICATION_FAILED' ? (
          'Verification failed'
        ) : bankAccountStatus === 'DELETED' ? (
          'Deleted'
        ) : (
          bankAccountStatus || 'Not available'
        );
      },
    },
    {
      title: 'Statement',
      key: 'link',
      render: (account: BankAccount) => {
        const { id, bankAccountNumber, documentFileName } = account;
        return (
          documentFileName && (
            <Button
              type="link"
              onClick={() => viewDocument(id, bankAccountNumber)}
              loading={id === document.accountId}
            >
              View
            </Button>
          )
        );
      },
    },
    {
      title: 'Code Entered?',
      key: 'code',
      render: (account: BankAccount) => {
        const { code } = account;
        return code ? 'Yes' : 'No';
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (account: BankAccount) => {
        const { bankAccountStatus, documentFileName } = account;
        if (bankAccountStatus === 'VERIFICATION_PENDING' && documentFileName) {
          return (
            <Space>
              <Button
                type="primary"
                onClick={() => onUpdate(account, 'VERIFIED')}
              >
                Approve
              </Button>
              <Button
                danger
                onClick={() => onUpdate(account, 'VERIFICATION_FAILED')}
                type="link"
              >
                Reject
              </Button>
            </Space>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  return (
    <Space>
      <Helmet>
        {companyName ? (
          <title>{companyName} | Bank Accounts | Relay</title>
        ) : (
          <title>Bank Accounts | Relay</title>
        )}
      </Helmet>

      {document.uri ? (
        <Card
          title={
            accountNumber
              ? accountNumberFormatter(accountNumber)
              : 'Account number not found'
          }
          extra={<Button onClick={onCloseDocument}>Close</Button>}
        >
          {document.uri.includes('.pdf') || document.uri.includes('.PDF') ? (
            <embed
              src={document.uri}
              type="application/pdf"
              height="100%"
              width="100%"
            />
          ) : (
            <img src={document.uri} alt="Bank statement file" />
          )}
        </Card>
      ) : (
        <Table
          dataSource={accounts}
          columns={columns}
          loading={isLoading}
          pagination={{ hideOnSinglePage: true }}
        />
      )}
    </Space>
  );
};

const mapDispatchToProps = { setToolbarTitle };

export default connect(null, mapDispatchToProps)(CompanyBankAccounts);
