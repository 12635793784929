import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';

import Table from './Table';
import { getTopups, updatePageNumber } from 'store/admin/topups';

const { TabPane } = Tabs;

const Details = (props) => {
  const { getTopups, size, updatePageNumber } = props;

  useEffect(() => {
    getTopups({ page: 0, size });
  }, [getTopups, size]);

  const onChangeTab = (activeKey) => {
    if (activeKey === '1') {
      getTopups({ size, page: 0 });
      updatePageNumber(1);
    }
    if (activeKey === '2') {
      getTopups({ status: 'PENDING', size, page: 0 });
      updatePageNumber(1);
    }
    if (activeKey === '3') {
      getTopups({ status: 'UNKNOWN', size, page: 0 });
      updatePageNumber(1);
    }
    if (activeKey === '4') {
      getTopups({ status: 'COMPLETED', size, page: 0 });
      updatePageNumber(1);
    }
    if (activeKey === '5') {
      getTopups({ status: 'CANCELLED', size, page: 0 });
      updatePageNumber(1);
    }
  };

  return (
    <Tabs defaultActiveKey="1" onChange={onChangeTab}>
      <TabPane tab="All" key="1">
        <Table />
      </TabPane>
      <TabPane tab="Pending" key="2">
        <Table status={'PENDING'} />
      </TabPane>
      <TabPane tab="Unknown" key="3">
        <Table status={'UNKNOWN'} />
      </TabPane>
      <TabPane tab="Approved" key="4">
        <Table status={'COMPLETED'} />
      </TabPane>
      <TabPane tab="Cancelled" key="5">
        <Table status={'CANCELLED'} />
      </TabPane>
    </Tabs>
  );
};
const mapStateToProps = ({ admin }) => ({
  size: admin.topups.size,
});

export default connect(mapStateToProps, { getTopups, updatePageNumber })(
  Details
);
