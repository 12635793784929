import React from 'react';
import colors from 'colors';
import { Tooltip } from 'antd';
import { StatusDot } from 'ds';

const InvoiceStatusDot = ({
  includeInPurchaserOffer,
  type,
}: {
  includeInPurchaserOffer: boolean;
  type?: 'CN' | 'INV';
}) => {
  const color =
    !!type && type === 'CN'
      ? 'transparent'
      : includeInPurchaserOffer
      ? colors.greens.dark
      : colors.greys200;

  const tooltipTitle = includeInPurchaserOffer ? 'Included' : '';

  return (
    <Tooltip color={color} title={tooltipTitle}>
      <StatusDot color={color} />
    </Tooltip>
  );
};

export default InvoiceStatusDot;
