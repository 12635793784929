import React from 'react';
import styled from 'styled-components/macro';
import { Form, Select } from 'antd';

import { Modal } from 'ds';
import colors from 'colors';
import accountNumberFormatter from 'utils/accountNumberFormatter';
import BankAccount from 'types/BankAccount';

const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.greys600};
  margin-bottom: auto;
  margin-top: auto;
`;
const SubValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: ${colors.greys600};
  margin-bottom: auto;
  margin-top: auto;
`;
const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const VerifiedBadge = styled.div`
  width: 60px;
  height: 14px;
  background: #1db100;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 35px;
`;
const VerifiedText = styled.span`
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: ${colors.greys100};
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1px;
`;

type SetDefaultAccountProps = {
  isVisible: boolean;
  isSetting: boolean;
  accounts: BankAccount[];
  onSubmit: (selectedAccountId: BankAccount['id']) => void;
  onHide: () => void;
};

type FormValues = { selectedAccountId: BankAccount['id'] };

export const SetDefaultAccount: React.FC<SetDefaultAccountProps> = ({
  isVisible,
  isSetting,
  accounts,
  onSubmit,
  onHide,
}) => {
  const [form] = Form.useForm<FormValues>();

  const defaultAccountId = accounts.length ? accounts[0].id : null;

  const options = accounts
    .filter((account) => account.bankAccountNumber !== null)
    .map((account) => {
      return {
        label: (
          <OptionContainer>
            {account.bankAccountStatus === 'VERIFIED' ? (
              <Row>
                <Value>{account.bankAccountName}</Value>
                <VerifiedBadge>
                  <VerifiedText>VERIFIED</VerifiedText>
                </VerifiedBadge>
              </Row>
            ) : (
              <Value>{account.bankAccountName}</Value>
            )}
            <SubValue>
              {accountNumberFormatter(account.bankAccountNumber)}
            </SubValue>
          </OptionContainer>
        ),
        value: account.id,
      };
    });

  const handleSubmit = (values: FormValues) => {
    onSubmit(values.selectedAccountId);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onHide}
      okText="Confirm"
      onOk={form.submit}
      okButtonProps={{ loading: isSetting }}
      title="Set Default Withdrawal Account"
    >
      <Form
        form={form}
        initialValues={{ selectedAccountId: defaultAccountId }}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item name="selectedAccountId">
          <Select
            css={`
              width: 100%;
            `}
            options={options}
            size="large"
          />
        </Form.Item>
      </Form>
      {/* <Select
        css={`
          width: 100%;
        `}
      >
        {options}
      </Select> */}
      {/* <Select
        selected={defaultAccount}
        onChange={onSelectAccount}
        options={options}
      /> */}
    </Modal>
  );
};
