import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Button, Space, Typography } from 'antd';

const { Text } = Typography;

const Container = styled(Space)`
  background: ${colors.greys100};
  border-bottom: 0.5px solid ${colors.divider};
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const Head: React.FC<{ handleDone: () => void; handleReset: () => void }> = ({
  handleDone,
  handleReset,
}) => {
  return (
    <Container align="center" size={12}>
      <Button onClick={handleReset} size="small">
        Clear
      </Button>
      <Text>Filters</Text>
      <Button onClick={handleDone} size="small">
        Done
      </Button>
    </Container>
  );
};

export default Head;
