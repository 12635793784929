import React, { useEffect, useState } from 'react';
import api from 'api';
import { Form, Modal, notification, Select } from 'antd';
import { ModalHeader } from 'components';
import { PaymentTerm } from 'types/PaymentTerm';
import { getPaymentTermString, processError } from 'utils';
import { CounterParty } from 'types/CounterParty';

const getPaymentTermLabel = (term: PaymentTerm) => {
  let label = getPaymentTermString({ term });
  if (term.defaultTerm) {
    return `${label}*`;
  }
  return label;
};

export const EditPaymentTerms: React.FC<{
  counterParty: CounterParty;
  currentPaymentTerm?: PaymentTerm | null;
  entityId: string;
  handleClose: () => void;
  isOpen: boolean;
  onSuccess: (updatedCounterParty: CounterParty) => void;
  paymentTerms: PaymentTerm[];
}> = ({
  counterParty,
  currentPaymentTerm,
  entityId,
  handleClose,
  isOpen,
  onSuccess,
  paymentTerms,
}) => {
  const options = paymentTerms.map((term) => ({
    label: getPaymentTermLabel(term),
    value: term.id,
  }));

  const [isSubmitting, setSubmitting] = useState(false);

  const [form] = Form.useForm<{ paymentTermId: string }>();

  useEffect(() => {
    if (currentPaymentTerm) {
      form.setFieldsValue({ paymentTermId: currentPaymentTerm.id });
    } else {
      form.resetFields();
    }
  }, [currentPaymentTerm, form]);

  const handleSubmit = () => {
    form.validateFields().then(({ paymentTermId }) => {
      setSubmitting(true);
      const newPaymentTerm = paymentTerms.find(
        (paymentTerm) => paymentTerm.id === paymentTermId
      );
      const isDefault = !!newPaymentTerm?.defaultTerm;
      const payload = {
        id: counterParty.id,
        modificationTime: counterParty.modificationTime,
        value: isDefault ? null : paymentTermId,
        path: '/paymentTermId' as const,
        operation: 'replace' as const,
      };
      api.admin.counterParties
        .patchCounterParty({ entityId, payload })
        .then((data) => {
          const message = 'Success';
          const description = `Payment term updated for ${counterParty.counterParty.companyName}`;
          notification.success({ message, description });
          onSuccess(data.counterParty);
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setSubmitting(false));
    });
  };

  return (
    <Modal
      closable={false}
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{ loading: isSubmitting }}
      okText="Confirm"
      title={<ModalHeader title="Edit Payment Terms" onClose={handleClose} />}
      visible={isOpen}
    >
      <Form form={form} layout="vertical" wrapperCol={{ span: 12 }}>
        <Form.Item label="Select payment terms" name="paymentTermId" required>
          <Select>
            {options.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
