/*
Param: matchStatus from RIPE company invoice resource
Returns: a string that describes the reason why the bill ended up in the current state
*/

import RipeCompanyInvoice from 'types/RipeCompanyInvoice';

type ResponseType =
  | '-'
  | 'No PO Found'
  | 'No PO Match'
  | 'No Status Match'
  | 'Variance Found'
  | 'Closed Bill'
  | 'Period Locked'
  | 'Missing Data'
  | 'Duplicate Bill'
  | 'Contact Not Found';

export const getReasonFromMatchStatus = (
  matchStatus: RipeCompanyInvoice['matchStatus']
): ResponseType => {
  if (matchStatus === 'ORDER_NOT_FOUND_OCR') {
    return 'No PO Found';
  } else if (matchStatus === 'ORDER_NOT_FOUND_GPOS') {
    return 'No PO Match';
  } else if (matchStatus === 'STATUS_MISMATCH') {
    return 'No Status Match';
  } else if (matchStatus === 'AMOUNT_UNDER' || matchStatus === 'AMOUNT_OVER') {
    return 'Variance Found';
  } else if (matchStatus === 'ALREADY_CLOSED') {
    return 'Closed Bill';
  } else if (matchStatus === 'EXPIRED') {
    return 'Period Locked';
  } else if (matchStatus === 'INVOICE_DETAILS_MISSING') {
    return 'Missing Data';
  } else if (matchStatus === 'POTENTIAL_DUPLICATE') {
    return 'Duplicate Bill';
  } else if (matchStatus === 'CONTACT_NOT_FOUND') {
    return 'Contact Not Found';
  } else {
    return '-';
  }
};
