import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import api from 'api';
import AuthContainer from 'components/AuthContainer';
import AuthCard from 'components/AuthCard';
import { Button, Input } from 'antd';
import InputItem from 'components/InputItem';
import styled from 'styled-components';

const Prompt = styled.span`
  align-self: center;
  margin-top: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;
const StyledButton = styled(Button)`
  width: 160px;
  align-self: center;
  margin-bottom: 15px;
`;

const Form = (props) => {
  const [email, setEmail] = useState(null);
  const [emailValidationError, setEmailValidationError] = useState(null); // this will show the validity of the email entered as a message
  const [apiError, setApiError] = useState(null); // error responded by api :/user/forgotpassword requested on Button Submission
  const [defaultApiError, setDefaultApiError] = useState(null); // if we have an unknown error in the catch of api request error
  const [apiCallWait, setApiCallWait] = useState(false);
  const validateEmail = () => {
    // eslint-disable-next-line no-useless-escape
    const eformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (eformat.test(email) === false) {
      setEmailValidationError('The email address is invalid');
    } else {
      setApiCallWait(true);
      // payload to api call
      let username = {
        username: email,
      };
      // api call to /user/forgotpassword
      api.auth
        .resetPassword(username)
        .then(() => {
          props.resetStatusHandler();
        })
        .catch((error) => {
          setApiError(error.response.data.message);
          if (!error.response.data.message) {
            setDefaultApiError(
              'Something went wrong, please try after sometime'
            ); // if the error is not given as a response message
          }
        });
      return setEmailValidationError('');
    }
  };

  return (
    <AuthContainer>
      <AuthCard
        message="Forgot Password"
        subMessage="Enter your email address below and we'll send you an email with instructions to reset your password"
      >
        <Helmet>
          <title>Forgot password | Relay</title>
        </Helmet>
        <InputItem
          label="Email"
          error={emailValidationError || apiError || defaultApiError}
        >
          <Input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailValidationError}
            required={true}
          />
        </InputItem>
        {/* shown if there is an error catched after api call */}
        {apiError && (
          <p style={{ textAlign: 'center', color: 'red' }}>{apiError}</p>
        )}
        {defaultApiError && (
          <p style={{ textAlign: 'center', color: 'red' }}>{defaultApiError}</p>
        )}
        <StyledButton
          size="large"
          type="primary"
          onClick={validateEmail}
          loading={apiCallWait}
        >
          Send
        </StyledButton>
        <Prompt>
          <StyledLink to="/login">Go to login page</StyledLink>
        </Prompt>
      </AuthCard>
    </AuthContainer>
  );
};

export default Form;
