import React from 'react';
import colors from 'colors';
import getDesignation from 'utils/getDesignation';
import styled, { keyframes } from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { VerificationStatus } from 'types/User';
import {
  CheckCircleFilledGreen,
  CheckCircleFilledGrey,
  CircleFilledGrey,
  CheckCircleFilledOrange,
  QuestionMark,
} from 'assets/icons';
import { UserRole } from 'types/User';

const { Text } = Typography;

const styles = {
  designation: {
    fontSize: 14,
    color: '#286EFF',
  },
  name: { flex: 1 },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
    position: 'relative',
    bottom: 1,
  } as React.CSSProperties,
  footerText: { marginLeft: 27 },
};

type ContainerProps = {
  clickable: boolean;
  isSelected?: boolean;
};

const iconKeyframes = keyframes`
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(0.4);
  }
  90% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  background: ${colors.greys100};
  border: ${(props: ContainerProps): string =>
    props.isSelected ? `1px solid #286EFF` : `1px solid ${colors.greys300}`};
  box-shadow: ${(props: ContainerProps): string =>
    props.isSelected ? `0 0 5px ${colors.primary}` : 'none'};
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
  :hover {
    background: ${(props: ContainerProps): string =>
      props.clickable ? '#ebf7ff' : 'auto'};
    cursor: ${(props: ContainerProps): string =>
      props.clickable ? 'pointer' : 'auto'};
  }
  transition: all 300ms;
  .username-icon-animated {
    animation: ${iconKeyframes} 1s ease-in;
  }
`;

type Props = {
  animatedIcon?: boolean;
  clickable?: boolean;
  isSelected?: boolean;
  name: string;
  onClick?: () => void;
  roles?: UserRole[];
  verificationStatus?: VerificationStatus;
};

const Name: React.FC<Props> = ({
  animatedIcon,
  clickable,
  isSelected,
  name,
  onClick,
  roles,
  verificationStatus,
}: Props) => {
  let iconSrc = '';
  let tooltip = '';
  switch (verificationStatus) {
    case 'NOT_VERIFIED':
      iconSrc = CircleFilledGrey;
      tooltip = 'Unverified';
      break;
    case 'VERIFIED':
      iconSrc = CheckCircleFilledGreen;
      tooltip = 'Verified';
      break;
    case 'VERIFICATION_FAILED':
      iconSrc = CheckCircleFilledGrey;
      tooltip = 'Incomplete';
      break;
    case 'VERIFICATION_PENDING':
      iconSrc = CheckCircleFilledOrange;
      tooltip = 'Pending';
      break;
    default:
      iconSrc = QuestionMark;
      tooltip = 'Unknown';
      break;
  }

  let designation = '';
  if (roles && roles.length) {
    designation = getDesignation(roles);
  }

  return (
    <Container
      clickable={!!clickable}
      onClick={onClick && onClick}
      isSelected={isSelected}
    >
      {verificationStatus && (
        <Tooltip title={tooltip}>
          <img
            className={animatedIcon ? 'username-icon-animated' : ''}
            src={iconSrc}
            style={styles.icon}
            alt=""
          />
        </Tooltip>
      )}

      <Text style={styles.name}>{name}</Text>
      <Text style={styles.designation}>{designation}</Text>
    </Container>
  );
};

export default Name;
