import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import api from 'api';
import { CounterpartyFilters } from './CounterpartyFilters';

const DEFAULT_PAGE_NUMBER = 1;

const buildBrowserUrl = ({
  pathname,
  q,
  page,
  size,
  tab,
  isGlCodeChecked,
  glCode,
  isProcessingQueueChecked,
  processingQueue,
  isPaymentTermsChecked,
  paymentTerms,
  isScheduledPaymentsChecked,
  scheduledPayments,
}: {
  pathname: string;
  q?: string | null;
  page?: string | null;
  size?: string | null;
  tab?: string | null;
  isGlCodeChecked: boolean;
  glCode?: string | null;
  isProcessingQueueChecked: boolean;
  processingQueue?: string | null;
  isPaymentTermsChecked: boolean;
  paymentTerms?: string | null;
  isScheduledPaymentsChecked: boolean;
  scheduledPayments?: string | null;
}) => {
  const params = new URLSearchParams();

  if (q) {
    params.set('q', q);
  }

  if (page) {
    params.set('page', page);
  }

  if (size) {
    params.set('size', size);
  }

  if (tab) {
    params.set('tab', tab);
  }

  if (isGlCodeChecked && glCode) {
    params.set('glcode', glCode);
  }

  if (isProcessingQueueChecked && processingQueue) {
    params.set('queue', processingQueue);
  }

  if (isPaymentTermsChecked && paymentTerms) {
    params.set('pids', paymentTerms);
  }

  if (isScheduledPaymentsChecked && scheduledPayments) {
    params.set('sp', scheduledPayments);
  }

  return `${pathname}?${params}`;
};

const useFilter = ({
  filters,
  hideDropdown,
}: {
  filters: CounterpartyFilters;
  hideDropdown: () => void;
}) => {
  const history = useHistory();
  const location = useLocation();

  const match = useRouteMatch<{ id: string }>();
  const { pathname } = location;

  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get('q');
  const size = queryParams.get('size');
  const tab = queryParams.get('tab');

  const [isGlCodeActive, setGlCodeActive] = useState(false);
  const [isGlCodeChecked, setGlCodeChecked] = useState(false);
  const [glCode, setGlCode] = useState<string | null>(null);

  const [glCodeList, setGlCodeList] = useState<
    { accountCode: string; accountName: string }[]
  >([]);

  const [isProcessingQueueActive, setProcessingQueueActive] = useState(false);
  const [isProcessingQueueChecked, setProcessingQueueChecked] = useState(false);
  const [processingQueue, setProcessingQueue] = useState<string | null>(null);

  const [isPaymentTermsActive, setPaymentTermsActive] = useState(false);
  const [isPaymentTermsChecked, setPaymentTermsChecked] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<string | null>(null);

  const [isScheduledPaymentsActive, setScheduledPaymentsActive] =
    useState(false);
  const [isScheduledPaymentsChecked, setScheduledPaymentsChecked] =
    useState(false);
  const [scheduledPayments, setScheduledPayments] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (!match.params.id) {
      return;
    }
    api.admin.xeroAccounts
      .get({ entityId: match.params.id, isVisibleInRelay: true, size: 1000 })
      .then((response) =>
        setGlCodeList(
          response.accounts.map(({ account }) => ({
            accountCode: account.accountCode,
            accountName: account.accountName,
          }))
        )
      );
  }, [match.params.id]);

  const activeFilterCount = [
    isGlCodeActive,
    isProcessingQueueActive,
    isPaymentTermsActive,
    isScheduledPaymentsActive,
  ].filter((item) => item).length;

  useEffect(() => {
    if (filters.glCode) {
      setGlCodeActive(true);
      setGlCodeChecked(true);
      setGlCode(filters.glCode);
    } else {
      setGlCodeActive(false);
      setGlCodeChecked(false);
    }
  }, [filters.glCode]);

  useEffect(() => {
    if (filters.processingQueue) {
      setProcessingQueueActive(true);
      setProcessingQueueChecked(true);
      setProcessingQueue(filters.processingQueue);
    } else {
      setProcessingQueueActive(false);
      setProcessingQueueChecked(false);
    }
  }, [filters.processingQueue]);

  useEffect(() => {
    if (filters.paymentTerms) {
      setPaymentTermsActive(true);
      setPaymentTermsChecked(true);
      setPaymentTerms(filters.paymentTerms);
    } else {
      setPaymentTermsActive(false);
      setPaymentTermsChecked(false);
    }
  }, [filters.paymentTerms]);

  useEffect(() => {
    if (filters.scheduledPayments) {
      setScheduledPaymentsActive(true);
      setScheduledPaymentsChecked(true);
      setScheduledPayments(filters.scheduledPayments);
    } else {
      setScheduledPaymentsActive(false);
      setScheduledPaymentsChecked(false);
    }
  }, [filters.scheduledPayments]);

  const handleChangeGlCode = (value: string[]) => {
    if (value.length === 0) {
      // User has cleared the GL code selection
      setGlCode(null);
    } else {
      setGlCode(value.join(','));
    }
  };

  const handleChangeProcessingQueue = (value: string) => {
    if (value === 'AUTOMATIC' || value === 'MANUAL') {
      setProcessingQueue(value);
    }
  };

  const handleDone = () => {
    const url = buildBrowserUrl({
      pathname,
      q,
      page: String(DEFAULT_PAGE_NUMBER),
      size,
      tab,
      glCode,
      isGlCodeChecked,
      processingQueue,
      isProcessingQueueChecked,
      isPaymentTermsChecked,
      paymentTerms,
      isScheduledPaymentsChecked,
      scheduledPayments,
    });
    history.push(url);
    hideDropdown();
  };

  const handleReset = () => {
    const url = buildBrowserUrl({
      pathname,
      q,
      page: String(DEFAULT_PAGE_NUMBER),
      size,
      tab,
      isGlCodeChecked: false,
      isProcessingQueueChecked: false,
      isPaymentTermsChecked: false,
      isScheduledPaymentsChecked: false,
    });
    setGlCode(null);
    setGlCodeChecked(false);
    setGlCodeActive(false);

    setProcessingQueue(null);
    setProcessingQueueChecked(false);
    setProcessingQueueActive(false);

    setPaymentTerms(null);
    setPaymentTermsChecked(false);
    setPaymentTermsActive(false);

    setScheduledPayments(null);
    setScheduledPaymentsChecked(false);
    setScheduledPaymentsActive(false);

    history.push(url);
    hideDropdown();
  };

  const glCodeProps = {
    options: glCodeList,
    value: glCode?.split(',') || null,
    isChecked: isGlCodeChecked,
    handleChange: handleChangeGlCode,
    handleCheck: setGlCodeChecked,
  };

  const processingQueueProps = {
    value: processingQueue,
    isChecked: isProcessingQueueChecked,
    handleChange: handleChangeProcessingQueue,
    handleCheck: setProcessingQueueChecked,
  };

  const paymentTermsProps = {
    value: paymentTerms,
    isChecked: isPaymentTermsChecked,
    handleChange: setPaymentTerms,
    handleCheck: setPaymentTermsChecked,
  };

  const scheduledPaymentsProps = {
    value: scheduledPayments,
    isChecked: isScheduledPaymentsChecked,
    handleChange: setScheduledPayments,
    handleCheck: setScheduledPaymentsChecked,
  };

  return {
    activeFilterCount,
    glCodeList,
    glCodeProps,
    processingQueueProps,
    paymentTermsProps,
    scheduledPaymentsProps,
    handleDone,
    handleReset,
  };
};

export default useFilter;
