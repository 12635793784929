export const getTextWithCount = (
  baseText: string,
  count?: number | undefined
) => {
  if (!baseText) {
    return '';
  }

  if (!count) {
    return `0 ${baseText}s`;
  }

  if (count > 1) {
    return `${count} ${baseText}s`;
  }

  return `${count} ${baseText}`;
};
