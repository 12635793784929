import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AuthCard from 'components/AuthCard';
import Button from 'components/Button';
import Input from 'components/Input';

import api from 'api';
import { colors } from 'themes';
import { notification } from 'antd';

const defaultErrorMessage =
  'Something went wrong. Please try again or contact Relay if the issue persists.';
const companyFileMissingErrorMessage = 'companyId, must not be null';
const alreadyConnectedErrorMessage =
  'Cannot connect to other company once onboarded';

const AuthContainer = styled.div`
  margin-top: 20px;
`;

const AuthMessage = styled.p`
  background-color: #fffecf;
  color: #7a6000;
  padding: 10px;
  border-radius: 5px;
`;

const ErrorMessage = styled.p`
  color: ${colors.error};
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const LoadCompanyFile = ({
  accountRightCompanyFiles,
  token,
  myobAccount,
  history,
  user,
}) => {
  const [companyFileId, setCompanyFileId] = useState(null);
  const [companyFileMissingError, setCompanyFileMissingError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [credentialsError, setCredentialsError] = useState(false);
  const [alreadyConnectedError, setAlreadyConnectedError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const message = 'Select Account Right Company File';

  const continueToNextScreen = () => {
    setAuthError(false);
    setCredentialsError(false);
    setUsername('');
    setPassword('');
    if (user) {
      history.push('/settings?step=map-accounts&connection=myob');
    } else {
      history.push('/signup?step=map-accounts&connection=myob');
    }
  };

  const handleError = (error) => {
    const status = error && (error.status || '');
    const errors = error && error.data && (error.data.errors || []);
    const message =
      error && error.data && (error.data.message || defaultErrorMessage);
    if (status === 400 && errors.length) {
      errors.forEach((e) => {
        if (e === companyFileMissingErrorMessage) {
          setCompanyFileMissingError(true);
        }
      });
    } else if (status === 401) {
      // Needs username and password
      if (authError) {
        // User had supplied username and password.
        // But the credentials are wrong.
        setCredentialsError(true);
      }
      setAuthError(true);
    } else if (status === 403 && message === alreadyConnectedErrorMessage) {
      setAuthError(false);
      setCredentialsError(false);
      setAlreadyConnectedError(true);
    } else {
      notification.error({ message });
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      companyId: companyFileId,
      username,
      password,
      myobAccount,
      token,
    };

    // Let's see if the company file needs username and password.
    // If this API call returns 401, we need to get username and password from user.
    api.myob
      .validate(payload)
      .then(() => {
        // No need of username and password. Let's load the company file.
        api.myob
          .loadCompanyFile(payload)
          .then(() => {
            setLoading(false);
            continueToNextScreen();
          })
          .catch((error) => {
            setLoading(false);
            handleError(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const onChangeOption = ({ value }) => {
    setCompanyFileId(value);
    setCompanyFileMissingError(false);
    setAuthError(false);
    setCredentialsError(false);
    setUsername('');
    setPassword('');
  };

  const authErrorMessage =
    'To load the selected company file, you need to supply the username and password. Please enter them below.';
  const credentialsErrorMessage =
    'Invalid credentials. Please check the username and password you entered.';

  return (
    <AuthCard message={message}>
      <Select
        placeholder="Select a company file"
        options={accountRightCompanyFiles}
        onChange={onChangeOption}
        styles={selectStyles({ error: companyFileMissingError })}
        disabled={loading}
      />
      {companyFileMissingError && (
        <ErrorMessage>Please select a company file</ErrorMessage>
      )}
      {authError && (
        <AuthContainer>
          <AuthMessage>{authErrorMessage}</AuthMessage>
          <Input
            label="Username"
            placeholder="Username for your company file"
            onChange={(value) => setUsername(value)}
            error={credentialsError}
            disabled={loading}
          />
          <Input
            type="password"
            label="Password"
            placeholder="Password for your company file"
            onChange={(value) => setPassword(value)}
            error={credentialsError}
            disabled={loading}
          />
          {credentialsError && (
            <ErrorMessage>{credentialsErrorMessage}</ErrorMessage>
          )}
        </AuthContainer>
      )}
      {alreadyConnectedError ? (
        <>
          <ErrorMessage>
            Cannot connect to other company once onboarded. Click on the button
            below to continue.
          </ErrorMessage>
          <StyledButton label="Continue" onClick={continueToNextScreen} />
        </>
      ) : (
        <StyledButton
          label="Load Company File"
          onClick={onSubmit}
          loading={loading}
        />
      )}
    </AuthCard>
  );
};

const mapStateToProps = ({ auth, signup }) => ({
  user: auth.user,
  accountRightCompanyFiles: signup.accountRightCompanyFiles,
  token: auth.user ? auth.user.token : signup.token,
  myobAccount: signup.myobAccount,
});

const selectStyles = ({ error }) => {
  const style = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      borderColor:
        error && !state.isFocused ? colors.error : provided.borderColor,
    }),
  };
  return style;
};

export default connect(mapStateToProps)(withRouter(LoadCompanyFile));
