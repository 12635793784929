import React, { useState } from 'react';
import {
  AutoComplete,
  AutoCompleteProps,
  Input,
  Tooltip,
  Typography,
} from 'antd';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { useScript } from 'hooks';
import { InputItem } from 'components';
import { Address } from 'types/Address';
import colors from 'colors';

const { Text } = Typography;

type Props = {
  countryCode: Address['countryCode'];
  error?: string;
  label?: string;
  onSelect: (address: Address | null) => void;
  size?: AutoCompleteProps['size'];
};

type Option = { label: string; value: string };

export const AddressInput: React.FC<Props> = (props) => {
  const {
    countryCode,
    error,
    label = 'Address',
    onSelect,
    size = 'large',
  } = props;

  const apiKey = window.env.GOOGLE_MAPS_API_KEY;
  const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
  useScript(scriptSrc);

  const [options, setOptions] = useState<Array<Option>>([]);
  const [selected, setSelected] = useState<string>();

  const handleClear = () => {
    setSelected('');
    onSelect(null);
  };

  const handleSearch = (value: string): void => {
    if (selected) {
      setSelected('');
    } else {
      setSelected(undefined);
    }
    onSelect(null);
    if (!value) {
      setOptions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: countryCode },
      },
      (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error(status);
          return;
        }
        if (predictions) {
          setOptions(
            predictions.map((prediction) => ({
              label: prediction.description,
              value: prediction.place_id,
            }))
          );
        }
      }
    );
  };

  const handleSelect = (value: string): void => {
    const selectedAddress = options.find((option) => option.value === value);
    if (selectedAddress) {
      setSelected(selectedAddress.label);
    }
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: value }, (geocodes) => {
      if (geocodes && geocodes.length) {
        const address = geocodes[0].address_components?.reduce(
          (result: any, component) => {
            const { types, long_name, short_name } = component;
            if (types.includes('administrative_area_level_1')) {
              result.state = short_name;
            } else if (types.includes('locality')) {
              result.city = long_name;
            } else if (types.includes('route')) {
              result.streetAddress = long_name;
            } else if (types.includes('postal_code')) {
              result.postcode = short_name;
            }
            result.country = countryCode;
            result.countryCode = countryCode;
            return result;
          },
          {}
        );
        onSelect(address);
      }
    });
  };

  return (
    <InputItem error={error} label={label}>
      <Tooltip title={selected}>
        <AutoComplete
          disabled={!!selected}
          options={options}
          onSelect={handleSelect}
          onSearch={handleSearch}
          placeholder="Start typing to get suggestions..."
          css={`
            width: 100%;
          `}
          size={size}
          value={selected}
        >
          <Input
            size={size}
            suffix={
              selected ? (
                <Text
                  onClick={handleClear}
                  css={`
                    cursor: pointer;
                    color: ${colors.primary};
                  `}
                >
                  Clear
                </Text>
              ) : null
            }
          />
        </AutoComplete>
      </Tooltip>
    </InputItem>
  );
};
