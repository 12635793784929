import React from 'react';
import styled from 'styled-components';

import Amount from './Amount';

const Container = styled.div`
  margin-top: 22px;
  padding-left: 10px;
  padding-right: 10px;
`;

type AmountOption = {
  id: string;
  label: string;
  amount: number;
  last: boolean;
  custom: boolean;
  isSelected: boolean;
};

type Props = {
  amountOptions: AmountOption[];
  selectAmountOption: (label: string) => void;
  updateCustomInput: (value: string) => void;
};

const AmountSelection: React.FC<Props> = ({
  updateCustomInput,
  selectAmountOption,
  amountOptions,
}) => {
  return (
    <Container>
      {amountOptions.map((option) => (
        <Amount
          key={option.id}
          label={option.id}
          value={option.amount}
          last={option.last}
          custom={option.custom}
          isSelected={option.isSelected}
          onClick={selectAmountOption}
          updateCustomInput={updateCustomInput}
        />
      ))}
    </Container>
  );
};

export default AmountSelection;
