// const toTitleCase = (str: string): string =>
//   str.toLowerCase().replace(/\.\s*([a-z])|^[a-z]/gm, (s) => s.toUpperCase());

const toTitleCase = (str: string): string => {
  const wordArray = str.split(' ').map((word) => {
    const firstLetter = word[0];
    const remaining = word.slice(1);
    return `${firstLetter.toUpperCase()}${remaining.toLowerCase()}`;
  });
  return wordArray.join(' ');
};

export default toTitleCase;
