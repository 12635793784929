import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'antd';

const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
`;

type Props = {
  amount: string;
  percentage: string;
};

const CashBack: React.FC<Props> = ({ amount, percentage }) => {
  if (amount === '-' || percentage === '-') {
    return <p>-</p>;
  }
  return (
    <Container>
      <Text
        css={`
          font-weight: 700;
        `}
      >
        {amount}
      </Text>
      <Text
        css={`
          font-size: 10px;
        `}
      >
        {percentage}
      </Text>
    </Container>
  );
};

export default CashBack;
