import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { colors } from 'themes';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 14px;
`;

const ExistingAccounts = ({ accounts, onClick, onSelectAccount, selected }) => {
  const handleOptionChange = ({ value }) => {
    onSelectAccount(value);
  };

  const options = [
    ...accounts.map(a => ({
      value: a.code,
      label: `${a.code} - ${a.accountName}`
    }))
  ];

  const value = selected && options.find(o => o.value === selected.code);

  return (
    <Container onClick={onClick}>
      <StyledSelect
        onChange={handleOptionChange}
        options={options}
        placeholder="Select account"
        styles={selectStyles()}
        value={value}
      />
    </Container>
  );
};

const selectStyles = () => {
  const style = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      borderColor: state.isFocused ? provided.borderColor : colors.greys200,
      height: 40,
      paddingLeft: 20
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      paddingLeft: 20,
      color: state.isSelected ? '#FFF' : colors.greys600
    }),
    singleValue: provided => ({
      ...provided,
      color: colors.greys600
    }),
    valueContainer: provided => ({
      ...provided,
      fontSize: 12
    })
  };
  return style;
};

export default ExistingAccounts;
