import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  background: #fffecf;
  color: #7a6000;
  margin-bottom: 1rem;
`;

const EditedNotice: React.FC = () => (
  <Container>This unmatched bill has been edited</Container>
);

export default EditedNotice;
