import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import spacing from 'styles/layout/spacing';

const Tabs = styled.div`
  display: flex;
  margin-top: ${spacing.gutter.xl};
  border-bottom: 1px solid ${colors.greys200};
  overflow-x: auto;
`;

const Label = styled.span`
  margin-right: 40px;
  border-bottom: ${(props) => props.isActive && `1px solid ${colors.greys700}`};
  cursor: pointer;
  color: ${colors.greys700};
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 6px;
  font-style: normal;
  line-height: 16px;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const TabsContainer = (props) => {
  return (
    <Tabs>
      {props.tabs.map((tab) => {
        return (
          <Label
            key={tab.name}
            isActive={props.activeTab.name === tab.name}
            onClick={() => props.changeTab(tab)}
          >
            {tab.name}
          </Label>
        );
      })}
    </Tabs>
  );
};

export default TabsContainer;
