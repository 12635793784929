import React from 'react';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import { RelayTable } from 'components';
import { GlAccount } from 'types/AccountCode';
import TableButton from 'components/TableButton';

const StyledRelayTable = styled(RelayTable)`
  min-height: auto;
`;

export const AccountCodeTable: React.FC<{
  accountCodes: GlAccount[];
  handleClickEdit: (AccountCode: GlAccount) => void;
  isLoading: boolean;
}> = ({ accountCodes, handleClickEdit, isLoading }) => {
  const columns = [
    {
      title: 'NAME',
      key: 'day',
      dataIndex: 'accountName',
    },
    {
      title: 'GL CODE',
      key: 'code',
      dataIndex: 'accountCode',
    },
    {
      title: 'DEFAULT',
      key: 'default',
      dataIndex: 'isDefault',
      render: (isDefault: boolean) => (isDefault ? <CheckOutlined /> : null),
    },
    {
      title: 'ACTION',
      key: 'ACTION',
      render: (accountCode: GlAccount) => (
        <TableButton
          className="table-row-button"
          onClick={() => handleClickEdit(accountCode)}
        >
          Edit
        </TableButton>
      ),
      align: 'center' as const,
    },
  ];

  return (
    <StyledRelayTable
      columns={columns}
      dataSource={accountCodes}
      pagination={false}
      loading={isLoading}
      scroll={{ x: 100 }}
      rowKey={(record) => record.code}
    />
  );
};
