import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, Modal, notification, Tooltip } from 'antd';

import api from 'api';
import spacing from 'styles/layout/spacing';
import { processError } from 'utils';
import { GLCodeAllocationTable, ModalHeader } from 'components';
import { GlAccount } from 'types/AccountCode';
import {
  GlCodeAllocation,
  GlCodeAllocationItem,
} from 'types/RipeCompanyInvoice';

import { AllocationForm } from './components/AllocationForm';
import { AddRowButton } from './components';
import { AmountRemaining } from './components/AmountRemaining';

const DUPLICATE_ERROR =
  'The combination of GL Code and Cost Center should be unique';
const INSUFFICIENT_ALLOCATION_ERROR =
  'Total allocated amount must equal invoice amount';
const AMOUNT_HIGHER_ERROR = 'Amount cannot exceed remaining amount';

interface Props {
  currentAllocation: GlCodeAllocation;
  isVisible: boolean;
  loading: boolean;
  companyId: string;
  invoiceAmount: number;
  handleSave: (newAllocation: GlCodeAllocation) => void;
  handleCancel: () => void;
  trackingCategories: string[];
}
interface FormResult {
  accountCode: string;
  costCenter: string | null;
  amount: number;
}

export const GLCodeAllocationModal: React.FC<Props> = ({
  currentAllocation,
  isVisible,
  loading,
  companyId,
  invoiceAmount,
  handleSave,
  handleCancel,
  trackingCategories,
}) => {
  const [form] = Form.useForm<FormResult>();

  const [allocation, setAllocation] = useState<GlCodeAllocation>([]);
  const [accountCodes, setAccountCodes] = useState<GlAccount[]>([]);
  const [formActive, setFormActive] = useState(false);

  const [saveError, setSaveError] = useState('');
  const [formError, setFormError] = useState('');

  const [loadingAccountCodes, setLoadingAccountCodes] = useState(true);

  const allocatedAmountString = allocation
    .reduce((accumulator, current) => accumulator + current.amount, 0)
    .toFixed(2);
  const allocatedAmount = parseFloat(allocatedAmountString);
  const remainingAmount = invoiceAmount - allocatedAmount;

  const getAccountCodes = useCallback(() => {
    api.xero
      .getAccounts({
        companyId,
        size: 1000,
        isVisibleInRelay: true,
      })
      .then((data) => {
        const retrievedAccounts: GlAccount[] = data.accounts.map(
          ({ account }) => {
            const {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            } = account;
            return {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            };
          }
        );
        setAccountCodes(retrievedAccounts);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => {
        setAllocation(currentAllocation);
        setLoadingAccountCodes(false);
      });
  }, [companyId, currentAllocation]);

  useEffect(() => {
    getAccountCodes();
  }, [getAccountCodes]);

  const handleDelete = (deletedItem: GlCodeAllocationItem) => {
    const updatedAllocation = allocation.filter(
      (allocationItem) =>
        !(
          allocationItem.glCode === deletedItem.glCode &&
          allocationItem.costCenter === deletedItem.costCenter
        )
    );
    setAllocation(updatedAllocation);
  };

  const handleAdd = (result: FormResult) => {
    setSaveError('');
    const { accountCode, amount, costCenter } = result;
    const glCodeToAdd = accountCodes.find(
      (item) => item.accountCode === accountCode
    );
    if (!glCodeToAdd) {
      notification.error({
        message: 'GL Code is invalid',
      });
      return;
    }

    const isDuplicate = allocation.some(
      (allocationItem) =>
        allocationItem.glCode === accountCode &&
        allocationItem.costCenter === costCenter
    );
    if (isDuplicate) {
      setFormError(DUPLICATE_ERROR);
      return;
    }

    const isAmountHigher = amount > parseFloat(remainingAmount.toFixed(2));
    if (isAmountHigher) {
      setFormError(AMOUNT_HIGHER_ERROR);
      return;
    }

    setFormError('');

    const newAllocationItem = {
      name: glCodeToAdd?.accountName,
      glCode: glCodeToAdd?.accountCode,
      amount: amount,
      costCenter,
    };
    const updatedAllocation = [...allocation, newAllocationItem];
    setAllocation(updatedAllocation);
    setFormActive(false);
    form.resetFields();
  };

  const handleClickAddButton = () => {
    setSaveError('');
    setFormActive(true);
  };

  const handleCancelForm = () => {
    form.resetFields();
    setFormActive(false);
  };

  const onClickSave = () => {
    // User should be able to save the allocation if
    // either nothing is allocated
    // or whole of invoice amount is allocated
    if (allocation.length > 0 && allocatedAmount < invoiceAmount) {
      // There is atleast one allocation, but the whole of invoice amount is not allocated
      setSaveError(INSUFFICIENT_ALLOCATION_ERROR);
    } else {
      handleSave(allocation);
    }
  };

  return (
    <Modal
      title={
        <ModalHeader
          title="Line Item Allocation"
          subtitle={[
            'Please set the invoice amount before creating line items.',
            'Total allocated amount must equal invoice amount.',
          ]}
          hideClose
        />
      }
      width={580}
      visible={isVisible}
      closable={false}
      getContainer={false}
      footer={[
        <Tooltip
          key="cancel"
          placement="bottom"
          title="Unsaved changes will be lost"
        >
          <Button onClick={handleCancel}>Cancel</Button>
        </Tooltip>,
        <Button
          key="save"
          type="primary"
          onClick={onClickSave}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <GLCodeAllocationTable
        allocation={allocation}
        editable
        isLoading={loadingAccountCodes}
        handleDelete={handleDelete}
      />

      {formActive ? (
        <AllocationForm
          availableAccountCodes={accountCodes}
          loading={loadingAccountCodes}
          form={form}
          handleAdd={handleAdd}
          handleCancel={handleCancelForm}
          invoiceAmount={invoiceAmount}
          trackingCategories={trackingCategories}
          error={formError}
        />
      ) : (
        <AddRowButton onClick={handleClickAddButton} />
      )}

      <AmountRemaining amount={remainingAmount} />

      {saveError && (
        <Alert
          message={saveError}
          type="error"
          style={{ marginTop: spacing.gutter.md }}
        />
      )}
    </Modal>
  );
};
