/*
Function to check if a RIPE document needs to be locked.
To be used when updating document content, or when confirming or deleting a document
Takes in two params, status and errorMessage.
Sstatus - HTTP status code sent back by API.
errorMessage - error sent back by API.
Returns boolean.
*/

type Params = {
  status: number | null;
  errorMessage: string;
};

const shouldLockDocument = ({ status, errorMessage }: Params): boolean =>
  status === 400 &&
  (errorMessage ===
    'Document needs to be locked, before any changes are permitted' ||
    errorMessage ===
      'Document needs to be locked by the user, before any changes are permitted');

export default shouldLockDocument;
