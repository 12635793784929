import React, { useState } from 'react';
import Header from './Header';
import SideBar from './SideBar';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'hooks';
import { RouteComponentProps } from 'react-router';
import { Layout } from 'antd';
import spacing from 'styles/layout/spacing';

const { Content } = Layout;

type Props = {
  isAdmin: boolean;
  pathname: string;
  title: string;
  children: React.ReactElement;
  history: RouteComponentProps['history'];
};

const StyledContent = styled(Content)<{ $isDesktop: boolean }>`
  height: calc(100vh - ${spacing.topBar.height});
  max-width: ${(props) =>
    props.$isDesktop
      ? `calc(100vw - ${spacing.sideBar.width})`
      : 'calc(100vw)'};
  padding: ${(props) =>
    props.$isDesktop ? spacing.gutter.lg : spacing.gutter.md};
  position: relative;
  z-index: 1;
  overflow: auto;
`;

const AppLayout: React.FC<Props> = ({
  isAdmin,
  pathname,
  title,
  children,
  history,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <Layout
      css={`
        flex-direction: row;
        max-height: 100vh;
      `}
    >
      <SideBar
        isAdmin={isAdmin}
        isDesktop={isDesktop}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        pathname={pathname}
        history={history}
      />
      <Layout
        css={`
          overflow: hidden;
        `}
      >
        <Header isDesktop={isDesktop} openDrawer={openDrawer} title={title} />
        <StyledContent $isDesktop={isDesktop}>{children}</StyledContent>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
