import React, { useState, useRef, createRef, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Typography, notification } from 'antd';

import api from 'api';
import processError from 'utils/processError';

import { Modal } from 'ds';
import {
  hideAddBankAccountWindow,
  confirmAddBankAccount,
  getBankAccounts,
} from 'store/auth';
import colors from 'colors';

import AccountDetails from './AccountDetails';
import VerificationCode from './VerificationCode';
import BankStatement from './BankStatement';

const { Text } = Typography;

const Message = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.greys900};
  position: relative;
  bottom: 20px;
`;

const CODE_INVALID_EXCEED_LIMIT =
  'You have made 3 failed attempts. Please request for a new code.';
const CODE_INVALID_ERROR = 'Invalid code. Please check the code you received.';
const CODE_MISSING_ERROR =
  'Please enter the code recieved on your phone number';
const CODE_LENGTH = 6;
const initialCodeArray = Array(CODE_LENGTH).fill('');

const AddBankAccount = (props) => {
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [isConfirmAddBankAccount, setIsConfirmAddBankaccount] = useState(false);

  const [showCodeEnter, setShowCodeEnter] = useState(false);
  const [codeArray, setCodeArray] = useState(initialCodeArray.slice());
  const [error, setError] = useState('');
  const [invalidCodes, setInvalidCodes] = useState(0);
  const [pasting, setPasting] = useState(false);

  let inputRefs = useRef([]);

  if (inputRefs.current.length !== CODE_LENGTH) {
    inputRefs.current = Array(CODE_LENGTH)
      .fill()
      .map((_, i) => inputRefs.current[i] || createRef());
  }

  const [accountNumberValidationError, setAccountNumberValidationError] =
    useState(false);

  const {
    isVisibleAddBankAccount,
    hideAddBankAccountWindow,
    confirmAddBankAccount,
    getBankAccounts,
    user,
    company,
    verifyAccountDetails,
  } = props;

  const [step, updateStep] = useState(0);

  const showVerificationCode =
    user.account &&
    user.account.some((item) => item.bankAccountStatus === 'DELETED');

  useEffect(() => {
    const toVerifyAccount =
      user.account &&
      user.account.some(
        (item) =>
          item.loadedBy !== 'SYSTEM' &&
          (item.bankAccountStatus === 'UNVERIFIED' ||
            item.bankAccountStatus === 'VERIFICATION_FAILED')
      );
    if (toVerifyAccount && verifyAccountDetails) {
      showVerificationCode ? updateStep(2) : updateStep(1);
    }
  }, [user, verifyAccountDetails, showVerificationCode]);

  const handleError = (error) => {
    notification.error({
      message:
        error ??
        'Something went wrong. Please try again or contact Relay if the issue persists.',
    });
  };
  // remove dash from entered account number
  const unformattedNumber = accountNumber && accountNumber.replace(/\D/g, '');
  let finalAccountNumber = unformattedNumber;
  const isCountryAU = company?.address?.country === 'AU';
  if (!isCountryAU) {
    // formatting the 15 digit account number
    let a = unformattedNumber ? unformattedNumber : '';
    let b = '0';
    let position = 13;
    let outputNumber = [a.slice(0, position), b, a.slice(position)].join('');

    finalAccountNumber =
      unformattedNumber && unformattedNumber.length === 15
        ? outputNumber
        : unformattedNumber;
  }

  const account = {
    bankAccountName: String(accountName),
    bankAccountNumber: finalAccountNumber,
    defaultAccount: true,
  };

  const validateAccountNumber = () => {
    if (finalAccountNumber.length < 15) {
      setAccountNumberValidationError(true);
    } else {
      setIsConfirmAddBankaccount(true);
    }
  };

  // add bank account
  const addBankAccount = (account) => {
    setLoading(true);
    const { companyId, token } = user;
    api.company
      .postBankAccount({ companyId, account, token })
      .then((account) => {
        confirmAddBankAccount(account);
        getBankAccounts(companyId);
        showVerificationCode ? updateStep(2) : updateStep(1);
        //hideAddBankAccountWindow();
        setLoading(false);
        setAccountNumberValidationError(false);
      })
      .catch((err) => {
        const { errors, message, status } = processError(err);
        if (errors) {
          errors.map((item) => handleError(item));
        } else {
          if (message === '2fa code missing' && status === 400) {
            setShowCodeEnter(true);
            sendCode();
            updateStep(1);
            setLoading(false);
            return;
          }
          handleError(message);
        }
        setLoading(false);
      });
  };
  //add bank account with verification code
  const onSubmitCode = () => {
    const code = codeArray.join('');
    if (!code || code.length < CODE_LENGTH) {
      // User has either not entered the whole code or any digit at all.
      setError(CODE_MISSING_ERROR);
      return;
    }
    setLoading(true);
    const { companyId, token } = user;
    const accountPayload = { ...account, code };
    api.company
      .postBankAccount({ companyId, account: accountPayload, token })
      .then((account) => {
        confirmAddBankAccount(account);
        getBankAccounts(companyId);
        updateStep(2);
        setInvalidCodes(0);
        //hideAddBankAccountWindow();
        setLoading(false);
        setShowCodeEnter(false);
      })
      .catch((err) => {
        const { errors, message, status } = processError(err);
        if (errors) {
          errors.map((item) => handleError(item));
        } else if (status === 400 && !errors) {
          setInvalidCodes(invalidCodes + 1);
          if (invalidCodes >= 2) {
            // User has made 3 consecutive failed attempts.
            setError(CODE_INVALID_EXCEED_LIMIT);
          } else {
            setError(CODE_INVALID_ERROR);
          }
        } else {
          if (message === '2fa code missing' && status === 400) {
            setShowCodeEnter(true);
            sendCode();
            updateStep(1);
            setLoading(false);
            return;
          }
          handleError(message);
        }
        setLoading(false);
      });
  };

  const sendCode = () => {
    api.user.code
      .send({ token: user.token, userId: user.id })
      .then(() => {
        setInvalidCodes(0);
        setCodeArray(initialCodeArray.slice());
        setError('');
      })
      .catch((error) => {
        const { message } = processError(error);
        setError(message);
      });
  };

  const onCodeInput = (e, i) => {
    let value = e.target.value;
    if (value === ' ') {
      // Don't let user tap the space key or paste a single space char.
      // If they paste multiple space chars, that is handled in later code block.
      setPasting(false);
      return;
    }
    if (!isNaN(value)) {
      if (pasting) {
        setCodeArray([...value.split('')]);
        if (value.length < 6) {
          inputRefs.current[value.length].focus();
        }
        setPasting(false);
      } else {
        if (value.length > 1) {
          // A digit already exists in the input field.
          // We need to replace it with the newly entered digit.
          const quotient = Math.floor(value / 10);
          const remainder = value % 10;
          value =
            codeArray[i] === String(remainder)
              ? String(quotient)
              : String(remainder);
        }
        let updatedCodeArray = codeArray;
        updatedCodeArray[i] = value;
        setCodeArray([...updatedCodeArray]);
        if (value !== '' && i < 5) {
          // Shift focus to next cell
          inputRefs.current[i + 1].focus();
        }
        if (value === '' && i > 0) {
          // Shift focus to previous cell
          inputRefs.current[i - 1].focus();
        }
        setPasting(false);
      }
    }
  };

  const isFooterButtonsVisible =
    step === 0 || (step === 1 && showVerificationCode && showCodeEnter);

  return (
    <Modal
      visible={isVisibleAddBankAccount}
      okText={isConfirmAddBankAccount ? 'Next' : 'Review'}
      onOk={() => {
        isConfirmAddBankAccount
          ? showCodeEnter
            ? onSubmitCode()
            : addBankAccount(account)
          : validateAccountNumber();
      }}
      okButtonProps={{
        disabled: showCodeEnter
          ? invalidCodes === 3
          : !accountName || !accountNumber,
        loading,
        style: { display: isFooterButtonsVisible ? 'initial' : 'none' },
      }}
      cancelText={isConfirmAddBankAccount && !showCodeEnter ? 'Edit' : 'Cancel'}
      onCancel={() => {
        isConfirmAddBankAccount && !showCodeEnter
          ? setIsConfirmAddBankaccount(false)
          : hideAddBankAccountWindow();
      }}
      cancelButtonProps={{
        disabled: loading,
        style: { display: isFooterButtonsVisible ? 'initial' : 'none' },
        type: 'ghost',
      }}
      title={
        step === 2 || (step === 1 && !showVerificationCode && !showCodeEnter)
          ? 'Verify Bank Account'
          : isConfirmAddBankAccount
          ? 'New Bank Account'
          : 'Add Bank Account'
      }
    >
      <Message>
        {step === 2 || (step === 1 && !showVerificationCode && !showCodeEnter)
          ? 'Please upload a screenshot or photo of your recent bank statement that includes your company name. Only JPG, PNG, and PDF files of size less than 10 MB are supported.'
          : 'Withdrawals from your Relay account will be sent to this bank account'}
      </Message>

      {step === 0 && (
        <AccountDetails
          isConfirmAddBankAccount={isConfirmAddBankAccount}
          accountName={accountName}
          setAccountName={setAccountName}
          unformattedNumber={unformattedNumber}
          setAccountNumberValidationError={setAccountNumberValidationError}
          setAccountNumber={setAccountNumber}
          accountNumber={accountNumber}
          accountNumberValidationError={accountNumberValidationError}
          isCountryAU={isCountryAU}
        />
      )}
      {step === 1 && showVerificationCode && showCodeEnter && (
        <VerificationCode
          onCodeInput={onCodeInput}
          showCodeEnter={showCodeEnter}
          error={error}
          setPasting={setPasting}
          codeArray={codeArray}
          invalidCodes={invalidCodes}
          inputRefs={inputRefs}
          phoneNumber={user && user.phoneNumber}
          sendCode={sendCode}
        />
      )}
      {(step === 2 ||
        (step === 1 && !showVerificationCode && !showCodeEnter)) && (
        <BankStatement />
      )}
    </Modal>
  );
};

const mapStateToProps = ({ auth }) => ({
  isVisibleAddBankAccount: auth.isVisibleAddBankAccount,
  user: auth.user,
  company: auth.user.company.company,
  verifyAccountDetails: auth.verifyAccountDetails,
});

const mapDispatchToProps = {
  hideAddBankAccountWindow,
  confirmAddBankAccount,
  getBankAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount);
