import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 11px;
  border: 1px solid ${colors.greys100};
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  width: 150px;
  background: white;
`;

const Percentage = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: ${colors.greys700};
  text-align: right;
  margin-left: 0px;
  white-space: nowrap;
`;

const Field = styled.input`
  border: none;
  font-size: 14px;
  max-width: 85px; /*110*/
  background: white;
`;

const AprInput = (props) => {
  const { value, placeholder, onChange, multiline, className, error } = props;
  return (
    <Container className={className} error={error}>
      <Field
        value={value ? value : ''}
        placeholder={placeholder || 'Enter Rate'}
        onChange={onChange}
        multiline={multiline}
        data-testid="apr-input-field"
      />
      <Percentage>
        % <span style={{ fontSize: 12 }}>p.a.</span>
      </Percentage>
    </Container>
  );
};

export default AprInput;
