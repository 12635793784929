const suggestCode = (accounts, code) => {
  const codeExists = accounts.some(a => a.code.includes(code));
  if (codeExists) {
    return suggestCode(accounts, parseInt(code) + 1);
  } else {
    return String(code);
  }
};

const suggestName = (accounts, name) => {
  const nameExists = accounts.some(a => a.accountName === name);
  if (nameExists) {
    const length = name.length;
    if (isNaN(name[length - 1])) {
      return suggestName(accounts, `${name} 1`);
    } else {
      const lastChar = name[length - 1];
      const newName = `${name.slice(0, length - 1)}${parseInt(lastChar) + 1}`;
      return suggestName(accounts, newName);
    }
  } else {
    return String(name);
  }
};

export { suggestCode, suggestName };
