import React, { ReactNode, useEffect, useState } from 'react';
import CounterParty from 'types/CounterParty';
import styled from 'styled-components/macro';
import moment from 'moment';
import { notification, Space, Tag, TagProps, Typography } from 'antd';
import { CounterpartyMerge } from 'components';
import { PaymentTerm } from 'types/PaymentTerm';
import { getPaymentTermString, processError } from 'utils';
import { DetailsItem } from './DetailsItem';
import { EditPaymentTerms } from './EditPaymentTerms';
import { EditExternalBankAccount } from './EditExternalBankAccount';
import { PurchaserOffersToggle } from './PurchaserOffersToggle';
import { ScheduledPaymentsToggle } from './ScheduledPaymentsToggle';
import api from 'api';
import accountNumberFormatter from 'utils/accountNumberFormatter';
import spacing from 'styles/layout/spacing';
import { ButtonType } from 'antd/lib/button';
import { DefaultProcessingQueue } from './DefaultProcessingQueue';
import { Preferences } from 'types/Company';
import { EditDefaultGlCode } from './EditDefaultGlCode';
import { GlAccount } from 'types/AccountCode';
import { PeriodLockToggle } from './PeriodLockToggle/PeriodLockToggle';

const { Text } = Typography;

const statusMap = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  INVITED: 'Invited',
};

const TitleContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  .ant-tag {
    margin-left: ${spacing.gutter.sm};
  }
`;

enum DetailType {
  ExternalBankAccount,
  PaymentTerms,
  DefaultGlCode,
}

const SectionTitle: React.FC<{ title: string; tag?: JSX.Element }> = ({
  title,
  tag,
}) => {
  return (
    <TitleContainer>
      <Text
        css={`
          font-size: 18px;
        `}
        strong
      >
        {title}
      </Text>
      {tag}
    </TitleContainer>
  );
};

const SectionItems: React.FC<{ items: ReactNode }> = ({ items }) => (
  <Space
    css={`
      width: 100%;
    `}
    direction="vertical"
  >
    {items}
  </Space>
);

const DetailsSection: React.FC<{
  title: string;
  items: ReactNode;
  tag?: JSX.Element;
}> = ({ title, items, tag }) => (
  <>
    <SectionTitle title={title} tag={tag} />
    <SectionItems items={items} />
  </>
);

export const CounterPartyDetails: React.FC<{
  counterParty: CounterParty;
  entityId: string;
  onMergeComplete: () => void;
  shouldShowMergeButton: boolean;
  paymentTerms: PaymentTerm[];
  preferences?: Preferences;
  availableCodes: GlAccount[];
  updateCounterParty: (counterParty: CounterParty) => void;
}> = ({
  counterParty,
  entityId,
  onMergeComplete,
  shouldShowMergeButton,
  paymentTerms,
  preferences,
  availableCodes,
  updateCounterParty,
}) => {
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [currentExternalBankAccount, setCurrentExternalBankAccount] = useState<
    string | null
  >(null);
  const [currentDefaultGlCode, setCurrentDefaultGlCode] = useState<
    string | null
  >(null);
  const [isEditPaymentTermsVisible, setEditPaymentTermsVisible] =
    useState(false);
  const [isEditExternalBankAccountVisible, setEditExternalBankAccountVisible] =
    useState(false);
  const [isEditDefaultGlCodeVisible, setEditDefaultGlCodeVisible] =
    useState(false);

  const handleClickMerge = () => {
    setOpenMergeModal(true);
  };

  const toggleModal = (detailType: DetailType) => {
    switch (detailType) {
      case DetailType.PaymentTerms:
        setEditPaymentTermsVisible((state) => !state);
        break;
      case DetailType.ExternalBankAccount:
        setEditExternalBankAccountVisible((state) => !state);
        break;
      case DetailType.DefaultGlCode:
        setEditDefaultGlCodeVisible((state) => !state);
        break;
    }
  };

  const onSuccess = (
    updatedCounterParty: CounterParty,
    detailType: DetailType
  ) => {
    switch (detailType) {
      case DetailType.ExternalBankAccount:
        setCurrentExternalBankAccount(
          updatedCounterParty.externalBankAccountNumber
        );
        break;
      case DetailType.DefaultGlCode:
        setCurrentDefaultGlCode(updatedCounterParty.glAccount.accountCode);
        break;
    }
    updateCounterParty(updatedCounterParty);
    toggleModal(detailType);
  };

  useEffect(() => {
    setCurrentExternalBankAccount(null);
    setCurrentDefaultGlCode(null);
    api.admin.counterParties
      .getCounterParty({
        entityId,
        counterpartyId: counterParty.id,
      })
      .then((data) => {
        const { externalBankAccountNumber, glAccount } = data.counterParty;
        setCurrentExternalBankAccount(externalBankAccountNumber || '');
        if (glAccount) {
          setCurrentDefaultGlCode(glAccount.accountCode);
        } else {
          const defaultCode = availableCodes.find(
            (account) => account.isDefault
          );
          if (defaultCode) {
            setCurrentDefaultGlCode((state) =>
              !state ? defaultCode.accountCode : state
            );
          }
        }
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      });
  }, [entityId, counterParty.id, availableCodes]);

  const purchaserOffersToggle = {
    label: 'Purchaser Offers',
    control: (
      <PurchaserOffersToggle
        counterParty={counterParty}
        entityId={entityId}
        onToggle={updateCounterParty}
      />
    ),
  };

  const informationItems = [
    {
      label: 'Creation Date',
      value: moment(counterParty.creationTime).format('DD-MM-YYYY'),
      actionLabel: shouldShowMergeButton ? 'Merge' : undefined,
      action: shouldShowMergeButton ? handleClickMerge : undefined,
    },
    {
      label: 'External Account ID',
      value: counterParty.externalAccountId,
    },
    { label: 'NZBN', value: counterParty.counterParty.nzbn },
    { label: 'GST', value: counterParty.gstNumber },
    purchaserOffersToggle,
  ];

  // The payment term that is marked as default among the global payment terms for the entity
  const defaultPaymentTerm = paymentTerms.find((term) => term.defaultTerm);

  // The payment term that is set specifically to this counterparty,
  // by overriding the global default term
  const assignedPaymentTerm = counterParty.paymentTermId
    ? paymentTerms.find((term) => term.id === counterParty.paymentTermId)
    : null;

  // The payment term in effect for this counterparty
  const paymentTermInEffect = assignedPaymentTerm || defaultPaymentTerm;
  const paymentTermString = getPaymentTermString({ term: paymentTermInEffect });

  const scheduledPaymentsToggle = {
    label: 'Enable Scheduled Payments',
    control: (
      <ScheduledPaymentsToggle
        counterParty={counterParty}
        entityId={entityId}
        isDisabled={!currentExternalBankAccount || !paymentTermInEffect}
        onToggle={updateCounterParty}
      />
    ),
  };

  const defaultProcessingQueue = {
    label: 'Default Processing Queue',
    control: (
      <DefaultProcessingQueue
        fallbackQueue={preferences?.defaultProcessingQueue}
        counterParty={counterParty}
        entityId={entityId}
        onSuccess={(updatedCounterParty: CounterParty) =>
          updateCounterParty(updatedCounterParty)
        }
      />
    ),
  };

  const externalBankAccountDetailItem = {
    label: 'External Bank Account',
    value: accountNumberFormatter(currentExternalBankAccount) ?? null,
    actionLabel: 'Edit',
    action: () => toggleModal(DetailType.ExternalBankAccount),
    actionType: 'ghost' as ButtonType,
  };

  const paymentTermDetailItem = {
    label: 'Payment Terms',
    value: paymentTermString,
    actionLabel: paymentTerms.length ? 'Edit' : '',
    action: paymentTerms.length
      ? () => toggleModal(DetailType.PaymentTerms)
      : undefined,
    actionType: 'ghost' as ButtonType,
  };

  const glCodeDetailItem = {
    label: 'Default GL Code',
    value: currentDefaultGlCode ?? undefined,
    actionLabel: 'Edit',
    action: () => toggleModal(DetailType.DefaultGlCode),
    actionType: 'ghost' as ButtonType,
  };

  const paymentItems = [
    counterParty.isSupplier ? scheduledPaymentsToggle : undefined,
    counterParty.isSupplier ? defaultProcessingQueue : undefined,
    externalBankAccountDetailItem,
    paymentTermDetailItem,
    glCodeDetailItem,
  ];

  const periodLockToggle = {
    label: 'Period Lock',
    control: (
      <PeriodLockToggle
        counterParty={counterParty}
        entityId={entityId}
        entityPeriodLockDate={preferences?.periodLockDate || null}
        onToggle={updateCounterParty}
      />
    ),
  };

  const apaSettingsItems = [periodLockToggle];

  const isCounterAU = counterParty.counterParty.address?.country === 'AU';

  const statusTag = (companyStatus: string) => {
    let color: TagProps['color'] = undefined;
    if (companyStatus === 'Active') {
      color = 'processing';
    } else if (companyStatus === 'Invited') {
      color = 'warning';
    }
    return <Tag color={color}>{companyStatus}</Tag>;
  };

  return (
    <>
      <Space direction="vertical" size="large" css={{ width: '100%' }}>
        <DetailsSection
          title={counterParty.counterParty.companyName}
          tag={statusTag(statusMap[counterParty.counterParty.status])}
          items={informationItems.map((item) => (
            <DetailsItem key={item.label} {...item} />
          ))}
        />
        <DetailsSection
          title="Scheduled Payments"
          items={paymentItems.map(
            (item) => item && <DetailsItem key={item.label} {...item} />
          )}
        />
        <DetailsSection
          title="APA Settings"
          items={apaSettingsItems.map(
            (item) => item && <DetailsItem key={item.label} {...item} />
          )}
        />
      </Space>

      {openMergeModal && (
        <CounterpartyMerge
          isOpen={openMergeModal}
          setIsOpen={setOpenMergeModal}
          fromEntityName={counterParty.counterParty.companyName}
          fromEntityId={counterParty.counterParty.id}
          entityId={entityId}
          onMergeComplete={onMergeComplete}
        />
      )}
      <EditPaymentTerms
        counterParty={counterParty}
        currentPaymentTerm={paymentTermInEffect}
        entityId={entityId}
        handleClose={() => toggleModal(DetailType.PaymentTerms)}
        isOpen={isEditPaymentTermsVisible}
        onSuccess={(updatedCounterparty) =>
          onSuccess(updatedCounterparty, DetailType.PaymentTerms)
        }
        paymentTerms={paymentTerms}
      />
      <EditExternalBankAccount
        counterParty={counterParty}
        currentExternalBankAccount={currentExternalBankAccount || ''}
        entityId={entityId}
        handleClose={() => toggleModal(DetailType.ExternalBankAccount)}
        isOpen={isEditExternalBankAccountVisible}
        onSuccess={(updatedCounterparty) =>
          onSuccess(updatedCounterparty, DetailType.ExternalBankAccount)
        }
        isCountryAU={isCounterAU}
      />
      <EditDefaultGlCode
        counterParty={counterParty}
        currentCode={currentDefaultGlCode ?? undefined}
        availableCodes={availableCodes}
        entityId={entityId}
        handleClose={() => toggleModal(DetailType.DefaultGlCode)}
        isOpen={isEditDefaultGlCodeVisible}
        onSuccess={(updatedCounterparty) =>
          onSuccess(updatedCounterparty, DetailType.DefaultGlCode)
        }
      />
    </>
  );
};
