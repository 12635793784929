import React, { useCallback, useEffect, useState, Key } from 'react';
import ReactDOM from 'react-dom';
import colors from 'colors';
import moment from 'moment';
import styled from 'styled-components/macro';
import Table from 'components/RelayTable';
import TableButton from 'components/TableButton';
import { SummaryBlock, TableCellWithStatusDot } from 'components';
import Pagination from 'components/Pagination';
import api from 'api';
import getTimestamps from './getTimestamps';
import featureFlags from 'config/featureFlags';
import debounce from 'lodash.debounce';
import {
  formatCurrency,
  formatDateForDisplay,
  getTimestamp,
  processError,
} from 'utils';
import {
  Button,
  Popconfirm,
  Tooltip,
  Typography,
  Row,
  Col,
  Space,
  notification,
  Tag,
} from 'antd';
import {
  AllocateCreditNote,
  BillsTabContainer,
  BillWithCreditNote,
  BulkActionButton,
  FilterChips,
  InvoiceStatusDot,
  PaidInvoice,
  TableActions,
  TableHeaderDot,
  ViewCreditNote,
} from 'components';
import { Tabs } from 'ds';
import {
  Amount,
  BillDetails,
  MonthDropdown,
  MonthNavigation,
  PayInvoice,
  PeriodLock,
} from './components';
import {
  useHistory,
  useLocation,
  useParams,
  RouteComponentProps,
} from 'react-router-dom';
import {
  PayablesSummaryDetails,
  PayablesSummaryDetailsRecord,
} from 'types/PayablesSummary';
import SupplierOffer from 'types/SupplierOffer';
import {
  setDataToRefresh,
  setRefreshData,
  setToolbarSubtitle,
  setToolbarTitle,
} from 'store/ui';
import { setCompanyNameToAddInvoice, showInviteWindow } from 'store/invoices';
import {
  getSupplierOffersAvailable,
  updateSupplierOfferAvailableNoReturn,
} from 'store/offers';
import { useDispatch, useSelector } from 'react-redux';
import { Settings } from 'types/Company';
import { getCompanySettings } from 'store/settings';
import CounterParty from 'types/CounterParty';
import { PendingInvoicesSummary } from './components/PendingInvoicesSummary';

const { Text } = Typography;

const DEBOUNCE_DELAY = 500; // in milliseconds

const DetailsRow = styled(Row)<{ $marginBottom: string }>`
  margin-bottom: ${(props) => props.$marginBottom};
`;

const StyledTable = styled(Table)`
  .ant-table-tbody .ant-table-cell {
    padding: 0px 10px;
  }
  .custom-spacing {
    padding-right: 2% !important;
  }
`;

const InvoiceNumber = styled.div<{ $clickable: boolean }>`
  padding-left: 10px;
  position: relative;
  left: -10px;
  :hover {
    background: ${(props) => props.$clickable && colors.greys100};
    border-radius: 3px;
    cursor: ${(props) => props.$clickable && 'pointer'};
    text-decoration: ${(props) => props.$clickable && 'underline dotted'};
  }
`;

const StatusPaymentOffered = styled(Text)`
  background: #c4c4c4;
  border-radius: 8px;
  color: ${colors.greys900};
  padding: 5px 8px;
`;

const CashBackColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionColumn = styled.div`
  padding: 1em;
`;

const DEFAULT_FILTER = 'all';
const DEFAULT_FILTER_VALUE = 'ALL';
const DEFAULT_TAB = '1';
const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 25;

const filterOptions = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Payment Offered', value: 'PAYMENT_OFFERED' },
  { label: 'Cashback Available', value: 'CASHBACK_AVAILABLE' },
  { label: 'Paid', value: 'PAID' },
  { label: 'Unallocated Credit', value: 'UNALLOCATED_CREDIT' },
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const today = moment();
const currentMonthName = today.format('MMMM');
const currentYear = today.format('YYYY');
const nextMonth = today.clone().add(1, 'month');
const nextMonthName = nextMonth.format('MMMM');
const nextMonthYear = nextMonth.format('YYYY');

const getStartAt = ({ page, size }: { page: number; size: number }) =>
  (page - 1) * size;

const getEndAt = ({ page, size }: { page: number; size: number }) =>
  page * size;

type AlignType = 'left' | 'center' | 'right';

const buildUrl = ({
  base,
  filter,
  from,
  to,
  history,
  tab,
  page,
  size,
}: {
  base: string;
  filter: string;
  from?: number;
  to?: number;
  history?: boolean;
  tab: string;
  page: number;
  size: number;
}): string => {
  const params = new URLSearchParams();
  params.append('tab', tab);
  params.append('filter', filter);
  params.append('page', String(page));
  params.append('size', String(size));
  if (history) {
    params.append('history', 'true');
  }
  if (from) {
    params.append('from', String(from));
  }
  if (to) {
    params.append('to', String(to));
  }

  return `${base}?${params}`;
};

const navigateToRipeDetails = ({
  id,
  ripeDocumentId,
  history,
}: {
  id: string;
  ripeDocumentId: string;
  history: RouteComponentProps['history'];
}) => {
  history.push(`/bills/r/${id}?rid=${ripeDocumentId}`);
};

const renderInvoiceNumberColumn = ({
  id,
  history,
  type,
  includeInPurchaserOffer,
  recordNumber,
  ripeDocumentId,
}: {
  id: PayablesSummaryDetailsRecord['id'];
  history: RouteComponentProps['history'];
  type: PayablesSummaryDetailsRecord['type'];
  includeInPurchaserOffer: boolean;
  recordNumber: PayablesSummaryDetailsRecord['recordNumber'];
  ripeDocumentId: PayablesSummaryDetailsRecord['ripeDocumentId'];
}) => {
  const handleClick = () => {
    if (ripeDocumentId) {
      navigateToRipeDetails({ id, ripeDocumentId, history });
    }
  };

  return (
    <InvoiceNumber $clickable={!!ripeDocumentId} onClick={handleClick}>
      <TableCellWithStatusDot>
        <InvoiceStatusDot
          includeInPurchaserOffer={includeInPurchaserOffer}
          type={type === 'CREDIT_NOTE' ? 'CN' : 'INV'}
        />
        <Text>{recordNumber}</Text>
      </TableCellWithStatusDot>
    </InvoiceNumber>
  );
};
const renderStatus = ({
  id,
  cashReward,
  companyStatus,
  dueDate,
  notified,
  status,
  supplierOffersAvailable,
  isPurchaserOffersEnabled,
}: {
  id: PayablesSummaryDetailsRecord['id'];
  cashReward: PayablesSummaryDetailsRecord['cashReward'];
  companyStatus: PayablesSummaryDetails['companyOwedStatus'];
  dueDate: PayablesSummaryDetailsRecord['dueDate'];
  notified: boolean;
  status: PayablesSummaryDetailsRecord['status'];
  supplierOffersAvailable: SupplierOffer[];
  isPurchaserOffersEnabled: boolean;
}) => {
  let statusLabel =
    status === 'PAID'
      ? 'Paid'
      : status === 'CASHBACK_AVAILABLE'
      ? `SAVE ${formatCurrency(cashReward || 0)}`
      : status === 'PAYMENT_OFFERED' && isPurchaserOffersEnabled
      ? 'Payment Offered'
      : status === 'NOT_ELIGIBLE'
      ? 'Not Eligible'
      : status === 'NOT_ELIGIBLE_EXPIRED'
      ? 'Expired'
      : status === 'NOT_ELIGIBLE_OFFER_PENDING'
      ? 'Offer Pending'
      : status === 'PARTIALLY_ALLOCATED' || status === 'UNALLOCATED'
      ? 'UNALLOCATED'
      : status === 'ALLOCATED'
      ? 'Allocated'
      : '-';

  if (status === 'PAYMENT_OFFERED' && isPurchaserOffersEnabled) {
    if (companyStatus === 'INVITED') {
      statusLabel = 'Invited';
    } else if (notified) {
      statusLabel = 'Notified';
    }
  }

  const today = getTimestamp(moment());
  let tooltipTitle = '';
  if (status === 'NOT_ELIGIBLE_EXPIRED') {
    if (dueDate === today) {
      tooltipTitle = 'Due date is today';
    } else if (dueDate && dueDate < today) {
      tooltipTitle = 'Due date has passed';
    }
  } else if (status === 'NOT_ELIGIBLE') {
    tooltipTitle = 'Invoice amount is higher than available cash pool';
  }

  if (status === 'CASHBACK_AVAILABLE') {
    const supplierOfferPendingTopup = supplierOffersAvailable.find((offer) => {
      const { invoiceIds, offerStatus } = offer;
      return offerStatus === 'TOPUP_PENDING' && invoiceIds.includes(id);
    });
    if (supplierOfferPendingTopup) {
      statusLabel = 'Topup Pending';
    } else {
      return <StatusPaymentOffered>{statusLabel}</StatusPaymentOffered>;
    }
  }

  if (status === 'NO_CASHBACK' && !isPurchaserOffersEnabled) {
    statusLabel = 'Not eligible';
  }

  return statusLabel === 'UNALLOCATED' ? (
    <Tag color="purple">{statusLabel}</Tag>
  ) : (
    <Tooltip title={tooltipTitle}>
      <Text css={tooltipTitle ? `text-decoration: underline dotted` : ''}>
        {statusLabel}
      </Text>
    </Tooltip>
  );
};

const renderCashBackColumn = ({
  cashback,
  isPurchaserOffersEnabled,
  status,
  total,
}: {
  cashback: PayablesSummaryDetailsRecord['cashReward'];
  isPurchaserOffersEnabled: boolean;
  status: PayablesSummaryDetailsRecord['status'];
  total: PayablesSummaryDetailsRecord['total'];
}) => {
  if (
    !cashback ||
    (status === 'PAYMENT_OFFERED' && !isPurchaserOffersEnabled)
  ) {
    return '-';
  }
  const cashbackPercentage = ((cashback * 100) / total).toFixed(2);
  return (
    <CashBackColumn>
      <Text strong>{formatCurrency(cashback)}</Text>
      <Text
        css={`
          font-size: 10px;
        `}
      >
        {cashbackPercentage}%
      </Text>
    </CashBackColumn>
  );
};

const renderActionColumn = ({
  id,
  status,
  type,
  companyStatus,
  includeInPurchaserOffer,
  isPurchaserOffersEnabled,
  notified,
  ripeDocumentId,
  cancelPayment,
  include,
  exclude,
  openAllocateModal,
  openCNDetailsModal,
  sendNotification,
  showPaidDetails,
  showPayInvoiceModal,
  showBillDetailsModal,
  invite,
  supplierOffersAvailable,
  history,
}: {
  id: PayablesSummaryDetailsRecord['id'];
  status: PayablesSummaryDetailsRecord['status'];
  type: PayablesSummaryDetailsRecord['type'];
  companyStatus: PayablesSummaryDetails['companyOwedStatus'];
  includeInPurchaserOffer: boolean;
  isPurchaserOffersEnabled: boolean;
  notified: boolean;
  ripeDocumentId: PayablesSummaryDetailsRecord['ripeDocumentId'];
  cancelPayment: (offer: SupplierOffer) => void;
  include: () => void;
  exclude: () => void;
  openAllocateModal: () => void;
  openCNDetailsModal: () => void;
  sendNotification: () => void;
  showPaidDetails: () => void;
  showPayInvoiceModal: () => void;
  showBillDetailsModal: () => void;
  invite: () => void;
  supplierOffersAvailable: SupplierOffer[];
  history: RouteComponentProps['history'];
}) => {
  let label = '';
  let onClick = () => {};

  if (type === 'CREDIT_NOTE') {
    if (status === 'ALLOCATED') {
      label = 'View';
      onClick = openCNDetailsModal;
    } else {
      label = 'Allocate';
      onClick = openAllocateModal;
    }
  } else {
    if (status === 'NOT_ELIGIBLE_EXPIRED') {
      label = 'View';
      if (ripeDocumentId) {
        onClick = () => navigateToRipeDetails({ id, ripeDocumentId, history });
      } else {
        onClick = showBillDetailsModal;
      }
    } else if (status === 'PAID') {
      label = 'View';
      onClick = showPaidDetails;
    } else if (status === 'PAYMENT_OFFERED' && isPurchaserOffersEnabled) {
      if (companyStatus === 'INACTIVE') {
        label = 'Invite';
        onClick = invite;
      } else if (!notified) {
        label = 'Notify';
        onClick = sendNotification;
      } else {
        label = 'Exclude';
        onClick = exclude;
      }
    } else if (status === 'CASHBACK_AVAILABLE') {
      const supplierOfferPendingTopup = supplierOffersAvailable.find(
        (offer) => {
          const { invoiceIds, offerStatus } = offer;
          return offerStatus === 'TOPUP_PENDING' && invoiceIds.includes(id);
        }
      );
      if (supplierOfferPendingTopup) {
        label = 'Cancel';
        onClick = () => cancelPayment(supplierOfferPendingTopup);
      } else {
        label = 'Pay Now';
        onClick = showPayInvoiceModal;
      }
    } else if (!includeInPurchaserOffer) {
      if (isPurchaserOffersEnabled) {
        label = 'Include';
        onClick = include;
      } else {
        label = '-';
      }
    } else if (isPurchaserOffersEnabled) {
      label = 'Exclude';
      onClick = exclude;
    } else {
      label = '-';
    }
  }

  return (
    <ActionColumn>
      <TableButton className="table-row-button" onClick={onClick}>
        {label}
      </TableButton>
    </ActionColumn>
  );
};

const SupplierDetails: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const locationParams = new URLSearchParams(location.search);

  const settings = useSelector(
    (state: { settings: Settings | null }) => state.settings
  );
  const isRipeEnabled = settings?.isRipeEnabled;
  const showXeroBadge =
    isRipeEnabled && settings?.isRipeXeroAgedPayablesFilterEnabled;

  const isCounterPartyPeriodLockDateEnabled =
    settings?.isCounterPartyPeriodLockDateEnabled;

  const companyId = useSelector(
    (state: any) => state.auth?.user?.company.company.id
  );

  const filter = locationParams.get('filter') || DEFAULT_FILTER;
  const selectedPage = Number(locationParams.get('page')) || DEFAULT_PAGE;
  const selectedSize = Number(locationParams.get('size')) || DEFAULT_SIZE;
  const from = Number(locationParams.get('from'));
  const to = Number(locationParams.get('to'));
  const isHistoricalData = locationParams.get('history') || false;
  const selectedFilter =
    filterOptions.find(
      (item) => item.label.toLowerCase().split(' ').join('_') === filter
    ) || filterOptions[0];

  const tab = locationParams.get('tab') || DEFAULT_TAB;

  const [supplierName, setSupplierName] = useState('');
  const [counterParty, setCounterParty] = useState<CounterParty | null>(null);
  const [selectedMonthName, setSelectedMonthName] = useState(
    isHistoricalData && from
      ? `${months[moment(from).month()]} ${moment(from).year()}`
      : tab === '2'
      ? `${nextMonthName} ${nextMonthYear}`
      : `${currentMonthName} ${currentYear}`
  );
  const [isAllocateModalOpen, setAllocateModalOpen] = useState(false);
  const [isCNDetailsModalOpen, setCNDetailsModalOpen] = useState(false);
  const [selectedCN, setSelectedCN] =
    useState<PayablesSummaryDetailsRecord | null>(null);
  const [isBillDetailsModalOpen, setBillDetailsModalOpen] = useState(false);
  const [isPayInvoiceModalOpen, setPayInvoiceModalOpen] = useState(false);
  const [isPaidInvoiceModalOpen, setPaidInvoiceModalOpen] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState<string | null>(null);

  const [items, setItems] = useState<PayablesSummaryDetailsRecord[]>([]);
  const [companyData, setCompanyData] = useState<{
    email: string;
    status: PayablesSummaryDetails['companyOwedStatus'];
  }>({ email: '', status: 'INACTIVE' });
  const [selectedRows, setSelectedRows] = useState<
    PayablesSummaryDetailsRecord[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [isBulkDeleting, setBulkDeleting] = useState(false);
  const [isBulkIncludePending, setBulkIncludePending] = useState(false);
  const [isBulkExcludePending, setBulkExcludePending] = useState(false);
  const [rows, setRows] = useState(0);
  const [page, setPage] = useState(selectedPage);
  const [size, setSize] = useState(selectedSize);
  const [startAt, setStartAt] = useState(
    getStartAt({ page: selectedPage, size: selectedSize })
  );
  const [endAt, setEndAt] = useState(
    getEndAt({ page: selectedPage, size: selectedSize })
  );
  const [fromTime, setFromTime] = useState(
    from
      ? from
      : tab === '2'
      ? getTimestamps.nextMonthStart
      : getTimestamps.currentMonthStart
  );
  const [toTime, setToTime] = useState(
    to
      ? to
      : tab === '2'
      ? getTimestamps.nextMonthEnd
      : getTimestamps.currentMonthEnd
  );
  const [status, setStatus] = useState<string>(selectedFilter.value);
  const [summaryData, setSummaryData] = useState<{
    dueBy: number;
    unallocatedCreditNoteBalance: number;
    totalAmountPayable: number;
    totalAmountPaid: number;
    paidDate: number;
    xeroAmountPayable: number;
  }>({
    dueBy: 0,
    unallocatedCreditNoteBalance: 0,
    totalAmountPayable: 0,
    totalAmountPaid: 0,
    paidDate: 0,
    xeroAmountPayable: 0,
  });

  const [isShowingHistoricData, setShowingHistoricData] =
    useState(isHistoricalData);
  const [monthDropdownKey, setMonthDropdownKey] = useState(moment.now());

  const dispatch = useDispatch();

  const handleRowSelectionChange = (selectedIds: Key[]) => {
    setSelectedRows(items.filter((item) => selectedIds.includes(item.id)));
  };

  const rowSelection = {
    selectedRowKeys: selectedRows.map((row) => row.id),
    onChange: handleRowSelectionChange,
  };

  const selectedRowCount = selectedRows.length;
  const showBulkIncludeExclude =
    selectedRows.filter((row) => row.type !== 'CREDIT_NOTE').length > 0;

  const showBulkIncludeButton = counterParty?.isPurchaserOffersEnabled;

  const clearRowSelection = () => setSelectedRows([]);

  const supplierOffersAvailable: SupplierOffer[] = useSelector(
    (store: any) => store.offers.supplierOffers.available
  );

  const refreshData: boolean = useSelector(
    (store: any) => store.ui.refreshData
  );

  const supplierId = useParams<{ supplierId: string }>().supplierId;

  useEffect(() => {
    dispatch(setCompanyNameToAddInvoice(supplierName));
  }, [dispatch, supplierName]);

  useEffect(() => {
    if (companyId) {
      api.company.settings
        .get({ companyId })
        .then((settings) => dispatch(getCompanySettings(settings)));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyId && supplierName) {
      setLoading(true);
      api.company
        .getCounterParties({
          companyId,
          counterPartyName: supplierName,
        })
        .then((response) => {
          const counterParty = response.counterParties[0];
          if (counterParty) {
            setCounterParty(counterParty.counterParty);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [companyId, supplierName]);

  const getDetails = useCallback(() => {
    if (supplierId) {
      setLoading(true);
      api.payablesSummary
        .getPayablesSummaryDetails({
          status: isShowingHistoricData ? 'ALL_HISTORICAL' : status,
          supplierId,
          fromTime,
          toTime,
          startAt,
          endAt,
          includeUnallocatedCreditNotes: true,
        })
        .then((data) => {
          const {
            companyOwedEmail,
            companyOwedStatus,
            records,
            totalRecords,
            dueBy,
            unallocatedCreditNoteBalance,
            totalAmountPayable,
            totalAmountPaid,
            paidDate,
            supplierName,
          } = data;
          setSupplierName(supplierName);
          setItems(records);
          setRows(totalRecords);
          setCompanyData({
            email: companyOwedEmail,
            status: companyOwedStatus,
          });
          api.xero
            .getAgedPayables({
              companyId,
              relaySupplierCompanyId: supplierId,
              reportDate:
                tab === '2'
                  ? getTimestamps.currentMonthEnd
                  : getTimestamps.lastMonthEnd,
              size: 10000,
            })
            .then((res) => {
              const xeroAmountPayable = res.agedPayablesList.reduce(
                (total, item) => {
                  return total + item.agedPayables.amountDue;
                },
                0
              );
              setSummaryData({
                dueBy,
                unallocatedCreditNoteBalance,
                totalAmountPayable,
                totalAmountPaid,
                paidDate,
                xeroAmountPayable,
              });
            })
            .finally(() => setLoading(false));
        });
    }
  }, [
    isShowingHistoricData,
    status,
    startAt,
    endAt,
    supplierId,
    fromTime,
    toTime,
    companyId,
    tab,
  ]);

  useEffect(() => {
    setStartAt(getStartAt({ page, size }));
    setEndAt(getEndAt({ page, size }));
  }, [page, size]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    if (refreshData) {
      getDetails();
      dispatch(setRefreshData(false));
    }
  }, [dispatch, getDetails, refreshData]);

  useEffect(() => {
    dispatch(setToolbarTitle('Bills'));
    if (supplierName) {
      dispatch(setToolbarSubtitle(supplierName));
    }
    return () => {
      dispatch(setToolbarSubtitle(''));
    };
  }, [dispatch, supplierName]);

  useEffect(() => {
    dispatch(setDataToRefresh('PAYABLES_SUMMARY_DETAILS'));
    dispatch(getSupplierOffersAvailable());
  }, [dispatch]);

  const cancelPayment = (supplierOffer: SupplierOffer) => {
    setLoading(true);
    api.supplierOffers
      .cancel({ uriParam: supplierOffer?.id })
      .then((response) => {
        updateSupplierOfferAvailableNoReturn(response, dispatch);
        getDetails();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  };

  const includeExclude = ({
    id,
    action,
  }: {
    id: string;
    action: 'INCLUDE' | 'EXCLUDE';
  }) => {
    if (id) {
      const payload = [
        {
          id,
          path: '/include_in_purchaser_offer' as const,
          value: action === 'INCLUDE',
          operation: 'replace' as const,
        },
      ];
      setLoading(true);
      api.invoices
        .patch(payload)
        .then((response) => {
          if (Array.isArray(response) && response[0]) {
            const { updated, message } = response[0];
            if (!updated) {
              setLoading(false);
              notification.error({ message });
            } else {
              getDetails();
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          const { message } = processError(error);
          notification.error({ message });
        });
    }
  };

  const include = (id: string) => includeExclude({ action: 'INCLUDE', id });

  const exclude = (id: string) => includeExclude({ action: 'EXCLUDE', id });

  const bulkIncludeExclude = (action: 'include' | 'exclude') => {
    const invoicesToInclude = selectedRows.filter(
      (row) =>
        row.type === 'BILL' &&
        (action === 'include'
          ? row.includedInPurchaserOffer === false
          : row.includedInPurchaserOffer === true)
    );
    if (invoicesToInclude.length === 0) {
      notification.info({ message: `No bills to ${action}` });
      return;
    }
    setLoading(true);
    action === 'include'
      ? setBulkIncludePending(true)
      : setBulkExcludePending(true);
    const invoiceIdsToInclude = invoicesToInclude.map((invoice) => invoice.id);
    const payload = invoiceIdsToInclude.map((id) => ({
      path: '/include_in_purchaser_offer' as const,
      id,
      operation: 'replace' as const,
      value: action === 'include' ? true : false,
    }));
    api.invoices
      .patch(payload)
      .then((response) => {
        const totalItemsCount = response.length;
        const successItemsCount = response.reduce((count, item) => {
          if (item.updated) {
            return count + 1;
          } else {
            return count;
          }
        }, 0);
        const failedItemsCount = totalItemsCount - successItemsCount;
        if (successItemsCount) {
          const noun = successItemsCount === 1 ? 'bill' : 'bills';
          const message =
            action === 'include'
              ? `Included ${successItemsCount} ${noun} in your offer`
              : `Excluded ${successItemsCount} ${noun} from your offer`;
          notification.success({ message });
        }
        if (failedItemsCount) {
          const noun = failedItemsCount === 1 ? 'bill' : 'bills';
          const failedItemMessage = response.find(
            (item) => item.updated === false
          )?.message;
          const message =
            action === 'include'
              ? `Could not include ${failedItemsCount} ${noun} in your offer. ${failedItemMessage}`
              : `Could not exclude ${failedItemsCount} ${noun} from your offer. ${failedItemMessage}`;
          notification.error({ message });
        }
        getDetails();
        clearRowSelection();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
        setLoading(false);
      })
      .finally(() => {
        action === 'include'
          ? setBulkIncludePending(false)
          : setBulkExcludePending(false);
      });
  };

  const bulkInclude = () => bulkIncludeExclude('include');

  const bulkExclude = () => bulkIncludeExclude('exclude');

  const invoiceIdsToDelete: string[] = [];
  const creditNoteIdsToDelete: string[] = [];

  selectedRows.forEach((invoice) => {
    if (invoice.type === 'BILL') {
      invoiceIdsToDelete.push(invoice.id);
      return;
    }
    if (invoice.type === 'CREDIT_NOTE') {
      creditNoteIdsToDelete.push(invoice.id);
      return;
    }
  });

  const invoicesToDeleteCount = invoiceIdsToDelete.length;
  const creditNotesToDeleteCount = creditNoteIdsToDelete.length;

  const getDeleteConfirmationText = useCallback(() => {
    let deleteConfirmationText = 'Are you sure you want to delete';
    if (invoicesToDeleteCount > 0 && creditNotesToDeleteCount === 0) {
      deleteConfirmationText =
        deleteConfirmationText +
        ` ${invoicesToDeleteCount} bill${
          invoicesToDeleteCount === 1 ? '' : 's'
        }?`;
    } else if (invoicesToDeleteCount === 0 && creditNotesToDeleteCount > 0) {
      deleteConfirmationText =
        deleteConfirmationText +
        ` ${creditNotesToDeleteCount} credit note${
          creditNotesToDeleteCount === 1 ? '' : 's'
        }?`;
    } else {
      deleteConfirmationText =
        deleteConfirmationText +
        ` ${invoicesToDeleteCount} bill${
          invoicesToDeleteCount === 1 ? '' : 's'
        } and ${creditNotesToDeleteCount} credit note${
          creditNotesToDeleteCount === 1 ? '' : 's'
        } ?`;
    }
    return deleteConfirmationText;
  }, [creditNotesToDeleteCount, invoicesToDeleteCount]);

  const deleteBulkInvoices = (payload: {
    invoiceIds: Array<string>;
    type: 'INVOICE' | 'BILL';
  }): Promise<{ deletedInvoicesCount: number; deletedCNsCount: number }> => {
    return new Promise((resolve, reject) => {
      api.invoices
        .bulkDelete(payload)
        .then((response) => {
          const deletedInvoicesCount = response.results.reduce(
            (count, item) => (item.status === 'SUCCESS' ? count + 1 : count),
            0
          );
          resolve({ deletedInvoicesCount, deletedCNsCount: 0 });
        })
        .catch((error) => {
          const { message } = processError(error);
          reject(message);
        });
    });
  };
  const deleteBulkCreditnotes = (payload: {
    creditNoteIds: Array<string>;
  }): Promise<{ deletedInvoicesCount: number; deletedCNsCount: number }> => {
    return new Promise((resolve, reject) => {
      api.creditNotes
        .bulkDelete(payload)
        .then((response) => {
          const deletedCNsCount = response.results.reduce(
            (count, item) => (item.status === 'SUCCESS' ? count + 1 : count),
            0
          );

          resolve({ deletedCNsCount, deletedInvoicesCount: 0 });
        })
        .catch((error) => {
          const { message } = processError(error);
          reject(message);
        });
    });
  };

  const bulkDeleteFeedbackText = useCallback(
    ({
      deletedCount,
      total,
      type,
      shouldReturnMessage,
    }: {
      deletedCount: number;
      total: number;
      type: 'BILL' | 'CREDIT_NOTE';
      shouldReturnMessage?: boolean;
    }) => {
      let message = 'Deleted';
      if (deletedCount === total) {
        message =
          message +
          ` ${deletedCount} ${type === 'BILL' ? 'bill' : 'credit note'}${
            deletedCount === 1 ? '' : 's'
          }`;
      } else {
        message =
          message +
          ` ${deletedCount} out of ${total}  ${
            type === 'BILL' ? 'bill' : 'credit note'
          }${total === 1 ? '' : 's'}`;
      }
      if (shouldReturnMessage) {
        return message;
      }
      notification[deletedCount === 0 ? 'warning' : 'info']({ message });
      return;
    },
    []
  );

  const bulkDelete = () => {
    setBulkDeleting(true);
    const listOfPromises = [];
    if (invoicesToDeleteCount) {
      listOfPromises.push(
        deleteBulkInvoices({
          invoiceIds: invoiceIdsToDelete,
          type: 'BILL' as const,
        })
      );
    }
    if (creditNotesToDeleteCount) {
      listOfPromises.push(
        deleteBulkCreditnotes({ creditNoteIds: creditNoteIdsToDelete })
      );
    }

    listOfPromises.length &&
      Promise.allSettled(listOfPromises)
        .then((values) => {
          if (invoicesToDeleteCount > 0 && creditNotesToDeleteCount === 0) {
            const deletedCount =
              values[0].status === 'fulfilled'
                ? values[0].value.deletedInvoicesCount
                : 0;
            bulkDeleteFeedbackText({
              deletedCount,
              total: invoicesToDeleteCount,
              type: 'BILL',
            });
          } else if (
            invoicesToDeleteCount === 0 &&
            creditNotesToDeleteCount > 0
          ) {
            const deletedCount =
              values[0].status === 'fulfilled'
                ? values[0].value.deletedCNsCount
                : 0;
            bulkDeleteFeedbackText({
              deletedCount,
              total: creditNotesToDeleteCount,
              type: 'CREDIT_NOTE',
            });
          } else {
            const deletedInvoiceCount =
              values[0].status === 'fulfilled'
                ? values[0].value.deletedInvoicesCount
                : 0;
            const deletedCNCount =
              values[1].status === 'fulfilled'
                ? values[1].value.deletedCNsCount
                : 0;
            const messageForInvoice = bulkDeleteFeedbackText({
              deletedCount: deletedInvoiceCount,
              total: invoicesToDeleteCount,
              type: 'BILL',
              shouldReturnMessage: true,
            });
            const messageForCN = bulkDeleteFeedbackText({
              deletedCount: deletedCNCount,
              total: creditNotesToDeleteCount,
              type: 'CREDIT_NOTE',
              shouldReturnMessage: true,
            });
            const notificationVariant =
              deletedInvoiceCount + deletedCNCount === 0 ? 'warning' : 'info';
            notification[notificationVariant]({
              message: messageForInvoice,
              description: messageForCN,
            });
          }

          getDetails();
        })
        .catch((errorMessage: string) => {
          notification.error({ message: errorMessage });
        })
        .finally(() => {
          setBulkDeleting(false);
          setSelectedRows([]);
        });
  };

  const invite = () => {
    if (!supplierId) {
      return;
    }
    setLoading(true);
    const payload = {
      email: companyData.email,
      companyId: supplierId,
      note: '',
      type: 'BILL' as const,
    };
    api.invite
      .sendInvite(payload)
      .then(() => {
        getDetails();
      })
      .catch((err) => {
        const { status, message, debugMessage } = processError(err);
        if (status !== 403 || debugMessage !== 'company not verified') {
          notification.error({ message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendNotification = () => {
    if (!supplierId) {
      return;
    }
    setLoading(true);
    api.notify
      .notify({ companyId: supplierId, invoiceIds: [], type: 'BILL' })
      .then(() => {
        notification.success({ message: 'Notification sent' });
        getDetails();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
        setLoading(false);
      });
  };

  const clearBillSelection = () => setSelectedBillId(null);

  const closeBillDetailsModal = () => {
    clearBillSelection();
    setBillDetailsModalOpen(false);
    setSelectedCN(null);
  };

  const closeCNDetailsModal = () => {
    setSelectedCN(null);
    setCNDetailsModalOpen(false);
  };

  const closeAllocateModal = () => {
    setSelectedCN(null);
    setAllocateModalOpen(false);
  };

  const closePaidInvoiceModal = () => {
    clearBillSelection();
    setPaidInvoiceModalOpen(false);
  };

  const closePayInvoiceModal = () => {
    setSelectedCN(null);
    setPayInvoiceModalOpen(false);
  };

  const onSuccessPayInvoice = () => {
    if (selectedCN) {
      setSelectedBillId(selectedCN.id);
      setSelectedCN(null);
      setPayInvoiceModalOpen(false);
      setPaidInvoiceModalOpen(true);
      getDetails();
    }
  };

  const columns = [
    {
      title: 'TYPE',
      key: 'type',
      render: (record: PayablesSummaryDetailsRecord) =>
        record.type === 'CREDIT_NOTE' ? 'CN' : 'INV',
      width: '5%',
    },
    {
      title: 'ISSUE DATE',
      key: 'issueDate',
      render: (record: PayablesSummaryDetailsRecord) =>
        record.issueDate ? formatDateForDisplay(record.issueDate) : '-',
      align: 'right' as AlignType,
      width: '12%',
      className: 'custom-spacing',
    },
    {
      title: () => (
        <TableCellWithStatusDot>
          <TableHeaderDot /> INVOICE #
        </TableCellWithStatusDot>
      ),
      key: 'invoiceNumber',
      render: (record: PayablesSummaryDetailsRecord) =>
        renderInvoiceNumberColumn({
          id: record.id,
          history,
          type: record.type,
          includeInPurchaserOffer: record.includedInPurchaserOffer,
          recordNumber: record.recordNumber,
          ripeDocumentId: record.ripeDocumentId,
        }),
      width: '23%',
    },
    {
      title: 'PAYMENT DATE',
      key: 'dueDate',
      render: (record: PayablesSummaryDetailsRecord) =>
        record.dueDate ? formatDateForDisplay(record.dueDate) : '-',
      align: 'right' as AlignType,
      width: '14%',
    },
    {
      title: 'AMOUNT DUE',
      key: 'amountDue',
      render: (record: PayablesSummaryDetailsRecord) => (
        <Amount
          total={record.total}
          totalEligible={record.totalEligible}
          type={record.type}
          onClick={() => {
            setSelectedBillId(record.id);
            setBillDetailsModalOpen(true);
          }}
        />
      ),
      align: 'right' as AlignType,
      width: '12%',
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (record: PayablesSummaryDetailsRecord) =>
        renderStatus({
          id: record.id,
          cashReward: record.cashReward,
          companyStatus: companyData.status,
          dueDate: record.dueDate,
          notified: record.notified,
          status: record.status,
          supplierOffersAvailable,
          isPurchaserOffersEnabled:
            counterParty?.isPurchaserOffersEnabled || false,
        }),
      align: 'center' as AlignType,
      width: '15%',
    },
    {
      title: 'CASHBACK',
      key: 'cashback',
      render: (record: PayablesSummaryDetailsRecord) =>
        record.type === 'BILL'
          ? renderCashBackColumn({
              cashback: record.cashReward,
              isPurchaserOffersEnabled:
                counterParty?.isPurchaserOffersEnabled || false,
              status: record.status,
              total: record.total,
            })
          : null,
      align: 'right' as AlignType,
      width: '9.5%',
      className: 'custom-spacing',
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record: PayablesSummaryDetailsRecord) =>
        renderActionColumn({
          id: record.id,
          status: record.status,
          type: record.type,
          companyStatus: companyData.status,
          includeInPurchaserOffer: record.includedInPurchaserOffer,
          isPurchaserOffersEnabled:
            counterParty?.isPurchaserOffersEnabled || false,
          notified: record.notified,
          ripeDocumentId: record.ripeDocumentId,
          supplierOffersAvailable,
          cancelPayment,
          include: () => include(record.id),
          exclude: () => exclude(record.id),
          openAllocateModal: () => {
            setSelectedCN(record);
            setAllocateModalOpen(true);
          },
          openCNDetailsModal: () => {
            setSelectedCN(record);
            setCNDetailsModalOpen(true);
          },
          sendNotification,
          showPaidDetails: () => {
            setSelectedBillId(record.id);
            setPaidInvoiceModalOpen(true);
          },
          showPayInvoiceModal: () => {
            setSelectedCN(record);
            setPayInvoiceModalOpen(true);
          },
          showBillDetailsModal: () => {
            setSelectedCN(record);
            setBillDetailsModalOpen(true);
          },
          invite: async () => {
            if (companyData.email) {
              invite();
            } else {
              const bill = await api.invoices.getSingle(record.id);
              if (bill) {
                dispatch(showInviteWindow({ type: 'BILL', data: bill }));
              }
            }
          },
          history,
        }),
      align: 'center' as AlignType,
      width: '16%',
    },
  ];

  const startRow = Math.ceil((page - 1) * size + 1);
  const endRow = Math.ceil(page * size);

  const handlePageChange = (pageNumber: number) => {
    const url = buildUrl({
      base: location.pathname,
      filter,
      tab,
      page: pageNumber,
      size,
    });

    history.push(url);
    setPage(pageNumber);
  };

  const handleSizeChange = (e: any) => {
    const pageSize = e.target.value;
    const url = buildUrl({
      base: location.pathname,
      filter,
      tab,
      page: DEFAULT_PAGE,
      size: pageSize,
    });

    history.push(url);
    setSize(pageSize);
    setPage(DEFAULT_PAGE);
  };

  const MIN_PAGE_SIZE = 10;

  const handleTabChange = (tab: string) => {
    const url = buildUrl({
      base: location.pathname,
      filter,
      tab,
      page: DEFAULT_PAGE,
      size,
    });

    history.push(url);
    setPage(1);

    if (tab === '1') {
      setFromTime(getTimestamps.currentMonthStart);
      setToTime(getTimestamps.currentMonthEnd);
      setSelectedMonthName(`${currentMonthName} ${currentYear}`);
    } else {
      setFromTime(getTimestamps.nextMonthStart);
      setToTime(getTimestamps.nextMonthEnd);
      setSelectedMonthName(`${nextMonthName} ${nextMonthYear}`);
    }
  };

  const checkResetNeeded = (selectedToTime: number) => {
    // If user selects/navigates to current month,
    // we need to reset the period
    return getTimestamps.currentMonthEnd === selectedToTime;
  };

  // To prevent multiple api calls when user repeatedly clicks onnext/prev month buttons
  const debounceSetTime = useCallback(
    debounce((newFromTime: number, newToTime: number) => {
      // To prevent double re-render
      ReactDOM.unstable_batchedUpdates(() => {
        setFromTime(newFromTime);
        setToTime(newToTime);
      });

      const url = buildUrl({
        base: location.pathname,
        filter: DEFAULT_FILTER,
        history: true,
        from: newFromTime,
        to: newToTime,
        tab: DEFAULT_TAB,
        page: DEFAULT_PAGE,
        size,
      });
      history.push(url);
    }, DEBOUNCE_DELAY),
    [size]
  );

  const goToPreviousMonth = () => {
    const previousMonth = moment(fromTime).subtract(1, 'months');
    const previousMonthStart = previousMonth.startOf('month').valueOf();
    const previousMonthEnd = previousMonth.endOf('month').valueOf();
    const previousMonthIndex = previousMonth.month();
    const previousMonthYear = previousMonth.year();
    const previousMonthName = months[previousMonthIndex];
    const previousMonthLabel = `${previousMonthName} ${previousMonthYear}`;

    setSelectedMonthName(previousMonthLabel);

    const shouldReset = checkResetNeeded(previousMonthEnd);
    if (shouldReset) {
      handleResetPeriod();
    } else {
      debounceSetTime(previousMonthStart, previousMonthEnd);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = moment(fromTime).add(1, 'months');
    const nextMonthStart = nextMonth.valueOf();
    const nextMonthEnd = nextMonth.endOf('month').valueOf();
    const nextMonthIndex = nextMonth.month();
    const nextMonthYear = nextMonth.year();
    const nextMonthName = months[nextMonthIndex];
    const nextMonthLabel = `${nextMonthName} ${nextMonthYear}`;

    setSelectedMonthName(nextMonthLabel);

    const shouldReset = checkResetNeeded(nextMonthEnd);
    if (shouldReset) {
      handleResetPeriod();
    } else {
      debounceSetTime(nextMonthStart, nextMonthEnd);
    }
  };

  const handleSelectMonth = (value: moment.Moment) => {
    const monthStart = value.startOf('month').valueOf();
    const monthEnd = value.endOf('month').valueOf();
    const monthIndex = value.month();
    const monthName = months[monthIndex];
    const monthYear = value.year();
    const monthLabel = `${monthName} ${monthYear}`;
    setSelectedMonthName(monthLabel);
    setFromTime(monthStart);
    setToTime(monthEnd);
    setShowingHistoricData(true);

    const shouldReset = checkResetNeeded(monthEnd);
    if (shouldReset) {
      hideHistoricView();
    } else {
      const url = buildUrl({
        base: location.pathname,
        filter: DEFAULT_FILTER,
        history: true,
        from: monthStart,
        to: monthEnd,
        tab: DEFAULT_TAB,
        page: DEFAULT_PAGE,
        size,
      });
      history.push(url);
    }
  };

  const hideHistoricView = () => {
    triggerMonthPickerRerender();
    setShowingHistoricData(false);
    const url = buildUrl({
      base: location.pathname,
      filter: DEFAULT_FILTER,
      tab: DEFAULT_TAB,
      page: DEFAULT_PAGE,
      size,
    });
    history.push(url);
  };

  const handleResetPeriod = () => {
    setSelectedMonthName(moment().format('MMMM YYYY'));
    setFromTime(getTimestamps.currentMonthStart);
    setToTime(getTimestamps.currentMonthEnd);
    setStatus(DEFAULT_FILTER_VALUE);
    hideHistoricView();
  };

  const triggerMonthPickerRerender = () => {
    // To re-render month dropdown component and
    // thereby reset the calender popper with current month
    setMonthDropdownKey(moment.now());
  };

  const handleClickFilterChip = (status: string) => {
    const filter = filterOptions.find((filter) => filter.value === status);

    if (!filter) {
      return;
    }

    setPage(1);
    setStatus(status);
    const urlParam = filter.label.toLowerCase().split(' ').join('_');
    const url = buildUrl({
      base: location.pathname,
      filter: urlParam,
      tab,
      page: DEFAULT_PAGE,
      size,
    });
    history.push(url);
  };

  const bulkActions = [
    ...(showBulkIncludeExclude
      ? [
          showBulkIncludeButton && (
            <BulkActionButton
              key="include"
              loading={isBulkIncludePending}
              onClick={bulkInclude}
            >
              INCLUDE
            </BulkActionButton>
          ),
          <BulkActionButton
            key="exclude"
            loading={isBulkExcludePending}
            onClick={bulkExclude}
          >
            EXCLUDE
          </BulkActionButton>,
        ]
      : []),
    <Popconfirm
      okButtonProps={{ danger: true }}
      okText="Delete"
      onConfirm={bulkDelete}
      title={getDeleteConfirmationText()}
    >
      <BulkActionButton loading={isBulkDeleting}>DELETE</BulkActionButton>
    </Popconfirm>,
  ];

  // User should not be able to navigate to next month,
  // if the selcted month is current month
  const isNextMonthDisabled = moment(toTime).isSame(today, 'month');

  // payment amount/balance available  considering historical data
  const paymentAmount = isShowingHistoricData
    ? summaryData.totalAmountPaid
      ? formatCurrency(summaryData.totalAmountPaid)
      : '-'
    : summaryData.totalAmountPayable
    ? formatCurrency(summaryData.totalAmountPayable)
    : '-';

  //payment date considering historical data
  const paymentDate = isShowingHistoricData
    ? summaryData.paidDate
      ? formatDateForDisplay(summaryData.paidDate)
      : '-'
    : summaryData.dueBy
    ? formatDateForDisplay(summaryData.dueBy)
    : '-';

  const xeroPayableMatches =
    Math.abs(summaryData.xeroAmountPayable - summaryData.totalAmountPayable) <=
    1;
  const xeroPayableStatus = xeroPayableMatches
    ? 'Match'
    : formatCurrency(summaryData.xeroAmountPayable);
  const xeroPayableContext = xeroPayableMatches
    ? undefined
    : `Payable balance in Relay is 
      ${formatCurrency(
        Math.abs(summaryData.xeroAmountPayable - summaryData.totalAmountPayable)
      )}
      ${
        summaryData.xeroAmountPayable < summaryData.totalAmountPayable
          ? 'greater than'
          : 'less than'
      }
      Xero`;

  return (
    <>
      <DetailsRow
        gutter={[10, 32]}
        justify="space-between"
        $marginBottom={isShowingHistoricData ? '77px' : '125px'}
      >
        <Col>
          <Space direction="vertical" align="end" size={16}>
            <SummaryBlock
              loading={loading}
              title="PAYMENT PERIOD"
              subTitle={
                <Space>
                  {featureFlags.historicalInvoices ? (
                    <MonthDropdown
                      value={fromTime}
                      onSelectMonth={handleSelectMonth}
                      selectedMonthName={selectedMonthName}
                      key={monthDropdownKey}
                      onClose={triggerMonthPickerRerender}
                    />
                  ) : (
                    moment().format('MMMM YYYY')
                  )}
                  {isCounterPartyPeriodLockDateEnabled && counterParty && (
                    <PeriodLock
                      counterParty={counterParty}
                      entityId={companyId}
                      isDisabled={tab === '2'}
                      setCounterParty={setCounterParty}
                      selectedMonth={toTime}
                    />
                  )}
                </Space>
              }
              highlightedRowLabel="Payment Date"
              highlightedRowValue={paymentDate}
              label={
                isShowingHistoricData ? 'Payment Amount' : 'Balance Payable'
              }
              value={paymentAmount}
              tag={
                paymentAmount === '-' || !showXeroBadge
                  ? undefined
                  : {
                      text: xeroPayableStatus,
                      type: xeroPayableMatches ? 'success' : 'error',
                      tooltipText: xeroPayableContext,
                    }
              }
            />
            {isShowingHistoricData && (
              <Button type="primary" onClick={handleResetPeriod}>
                Reset Period
              </Button>
            )}
          </Space>
        </Col>
        {isRipeEnabled && supplierName && !isShowingHistoricData && (
          <Col>
            <PendingInvoicesSummary supplierName={supplierName} />
          </Col>
        )}
      </DetailsRow>

      <BillsTabContainer
        css={`
          padding-bottom: 5rem;
        `}
        monthNavigation={
          isShowingHistoricData && (
            <MonthNavigation
              handleClickNext={goToNextMonth}
              handleClickPrevious={goToPreviousMonth}
              isNextMonthDisabled={isNextMonthDisabled}
            />
          )
        }
        filterChips={
          !isShowingHistoricData && (
            <FilterChips
              isTableFilters
              onChange={handleClickFilterChip}
              options={filterOptions}
              selected={status}
            />
          )
        }
      >
        <Tabs activeKey={tab} type="card" onChange={handleTabChange}>
          <Tabs.TabPane
            tab={isShowingHistoricData ? selectedMonthName : currentMonthName}
            key="1"
          >
            {!isShowingHistoricData && (
              <TableActions
                bulkActions={selectedRowCount > 0 ? bulkActions : undefined}
                hideFilter={true}
                hideSearch={true}
                isAddButtonDisabled={!supplierName}
                showAddButton
                type="BILL"
              />
            )}
            <StyledTable
              pagination={false}
              rowSelection={rowSelection}
              dataSource={items}
              columns={columns}
              loading={loading}
              rowKey="id"
            />
            {items.length && rows > MIN_PAGE_SIZE ? (
              <Pagination
                rows={rows}
                startRow={startRow}
                endRow={endRow}
                pageSize={size}
                pageNumber={page}
                handlePageChange={handlePageChange}
                handleSizeChange={handleSizeChange}
              />
            ) : null}
          </Tabs.TabPane>
          {!isShowingHistoricData && (
            <Tabs.TabPane tab={nextMonthName} key="2">
              <TableActions
                bulkActions={selectedRowCount > 0 ? bulkActions : undefined}
                hideFilter={true}
                hideSearch={true}
                isAddButtonDisabled={!supplierName}
                showAddButton
                type="BILL"
              />
              <StyledTable
                pagination={false}
                rowSelection={rowSelection}
                dataSource={items}
                columns={columns}
                loading={loading}
                rowKey="id"
              />
              {items.length && rows > MIN_PAGE_SIZE ? (
                <Pagination
                  rows={rows}
                  startRow={startRow}
                  endRow={endRow}
                  pageSize={size}
                  pageNumber={page}
                  handlePageChange={handlePageChange}
                  handleSizeChange={handleSizeChange}
                />
              ) : null}
            </Tabs.TabPane>
          )}
        </Tabs>
      </BillsTabContainer>
      {isAllocateModalOpen && !!selectedCN && (
        <AllocateCreditNote
          closeModal={closeAllocateModal}
          creditNote={selectedCN}
          fromTime={fromTime}
          isOpen={isAllocateModalOpen}
          refreshTableData={getDetails}
          supplierName={supplierName}
          toTime={toTime}
        />
      )}
      {!!selectedBillId && isBillDetailsModalOpen && (
        <BillWithCreditNote
          invoiceId={selectedBillId}
          isOpen={!!selectedBillId}
          closeModal={closeBillDetailsModal}
          type="BILL"
          refreshTableData={getDetails}
        />
      )}
      {isCNDetailsModalOpen && !!selectedCN && (
        <ViewCreditNote
          isOpen={isCNDetailsModalOpen}
          closeModal={closeCNDetailsModal}
          id={selectedCN.id}
          invoiceType="BILL"
          refreshTableData={getDetails}
        />
      )}
      {!!selectedBillId && isPaidInvoiceModalOpen && (
        <PaidInvoice
          hideModal={closePaidInvoiceModal}
          invoiceType="BILL"
          invoiceId={selectedBillId}
        />
      )}
      {!!selectedCN && isPayInvoiceModalOpen && (
        <PayInvoice
          invoiceId={selectedCN.id}
          amountDue={selectedCN.totalEligible}
          cashReward={selectedCN.cashReward || 0}
          invoiceNumber={selectedCN.recordNumber}
          hideModal={closePayInvoiceModal}
          onSuccessPayInvoice={onSuccessPayInvoice}
          dueDate={selectedCN.dueDate || 0}
          companyName={supplierName}
        />
      )}
      {!!selectedCN && isBillDetailsModalOpen && (
        <BillDetails
          amountDue={selectedCN.totalEligible}
          invoiceNumber={selectedCN.recordNumber}
          hideModal={closeBillDetailsModal}
          dueDate={selectedCN.dueDate || 0}
          companyName={supplierName}
        />
      )}
    </>
  );
};

export default SupplierDetails;
