import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import styled from 'styled-components/macro';
import { AutoComplete, Input, Typography, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';

import colors from 'colors';
import api from 'api';
import { EntityType } from '../CounterpartyMerge/components/types';

const SearchIcon = styled(SearchOutlined)`
  font-size: 15px;
  color: ${colors.greys700};
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  .ant-input-prefix {
    margin-right: 8px;
  }
`;

type Props = {
  fromEntityId?: string;
  placeholder?: string;
  entityId: string;
  isAdmin: boolean;
  value?: string;
  setToEntity: (value: EntityType) => void;
  focusHandler?: () => void;
  blurHandler?: () => void;
};

const { Text } = Typography;

const DEBOUNCE_DELAY = 500; // in milliseconds

const CounterpartySearch: React.FC<Props> = ({
  fromEntityId,
  entityId,
  placeholder,
  isAdmin,
  value,
  setToEntity,
  focusHandler,
  blurHandler,
}) => {
  const [fetching, setFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [optionsData, setOptionsData] = useState<EntityType[]>([]);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const getSearchResults = useCallback(
    (searchTerm: string) => {
      setFetching(true);
      const counterPartyPromise = isAdmin
        ? api.admin.counterParties.getAll({
            counterPartyName: searchTerm ? `${searchTerm}:like` : undefined,
            entityId,
            status: undefined,
            statuses: undefined,
          })
        : api.company.getCounterParties({
            counterPartyName: searchTerm ? `${searchTerm}` : '',
            companyId: entityId,
          });
      counterPartyPromise
        .then((data) => {
          if (data.counterParties) {
            // Filter out self (source counter party) from result
            const filteredCounterparties = data.counterParties.filter(
              (counterParty) =>
                counterParty.counterParty.counterParty.id !== fromEntityId
            );

            const options = filteredCounterparties.map((counterParty) => ({
              entityName: counterParty.counterParty.counterParty.companyName,
              entityId: counterParty.counterParty.counterParty.id,
            }));

            setOptionsData(options);
          }
        })
        .finally(() => setFetching(false));
    },
    [entityId, fromEntityId, isAdmin]
  );

  useEffect(() => {
    getSearchResults('');
  }, [getSearchResults]);

  const onFocus = () => {
    focusHandler && focusHandler();
    setIsOpen(true);
  };

  const onBlur = () => {
    blurHandler && blurHandler();
    setIsOpen(false);
  };

  const renderOptions = () => {
    return optionsData.map((option) => {
      return {
        value: option.entityId,
        label: <Text>{option.entityName}</Text>,
      };
    });
  };

  const debounceLoadData = useCallback(
    debounce((searchTerm: string) => {
      getSearchResults(searchTerm);
    }, DEBOUNCE_DELAY),
    []
  );

  const onSearch = (value: string) => {
    setIsOpen(true);
    setSearchValue(value);
    debounceLoadData(value);
  };

  const onChange = () => {
    setIsOpen(true);
  };

  const onSelect = (entityId: SelectValue) => {
    const selected = optionsData.find((item) => item.entityId === entityId);
    if (selected) {
      setToEntity(selected);
      setSearchValue(selected.entityName);
    }
    setIsOpen(false);
  };

  return (
    <StyledAutoComplete
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={renderOptions()}
      onSearch={onSearch}
      onSelect={onSelect}
      value={searchValue || ''}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      listHeight={212}
      open={isOpen}
    >
      <Input
        size="middle"
        placeholder={placeholder || 'Enter Company Name'}
        prefix={<SearchIcon onClick={() => onSearch('')} />}
        css={`
          height: 38px;
          border-radius: 2px;
        `}
      />
    </StyledAutoComplete>
  );
};

export default CounterpartySearch;
