import React from 'react';
import { connect } from 'react-redux';

import AuthContainer from 'components/AuthContainer';
import WelcomeComponent from 'components/Welcome';

const data = [
  {
    id: 1,
    title: 'Get Paid this Week',
    content:
      'Bid for prompt payment from your customers and get paid this week.',
    buttonText: 'Get Started',
  },
  {
    id: 2,
    title: 'Pay Suppliers Early to Earn Cashback',
    content: 'Offer same day payment to your suppliers and earn cashback',
    buttonText: 'Get Started',
  },
  {
    id: 3,
    title: 'Automate your cash flow',
    content: 'Automatically pay your bills when your invoices get paid',
    buttonText: 'Get Started',
  },
];

const Welcome = ({ user }) => {
  return (
    <AuthContainer welcome isLoggedIn={user ? true : false}>
      <WelcomeComponent
        data={data}
        title="How can we help?"
        footer={
          user
            ? ''
            : "Don't worry, you can come back to try the other options later!"
        }
        subTitle={
          user ? (
            <>
              Hi {user.firstName} {user.lastName}, welcome to Relay. Complete
              <span style={{ fontWeight: 'bold' }}> 5 steps</span> to start
              earning cashback from prompt payments.
            </>
          ) : null
        }
      />
    </AuthContainer>
  );
};
const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(Welcome);
