import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Helmet } from 'react-helmet';
import moment, { Moment } from 'moment';

import { setToolbarTitle } from 'store/ui';
import colors from 'colors';
import BarChart from 'components/BarChart';
import api from 'api';
import RipeSummary from 'types/RipeSummary';
import featureFlags from 'config/featureFlags';
import { KpiBar } from 'ds';
import { TooltipItem, TooltipModel } from 'chart.js';
import { useMediaQuery } from 'hooks';
import breakpoints from 'styles/layout/breakpoints';
type Props = {
  setToolbarTitle: (title: string) => void;
  token: string;
};
type Summary = {
  dateLabel: string;
  totalInvoices: number;
  totalInvoicesAutoCreated: number;
  totalInvoicesManuallyCreated: number;
};
type BarData = {
  label: string;
  data: number[];
  backgroundColor: string;
};
const Dashboard: React.FC<Props> = ({ token }) => {
  const [dateLabels, setDateLabels] = useState<string[]>([]);
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [barData, setBarData] = useState<BarData[]>([]);
  const [annualSummary, setAnnualSummary] = useState<Summary>();
  const user = useSelector((store: any) => store.auth.user);
  const companyId = user?.companyId;

  const dispatch = useDispatch();
  const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

  useEffect(() => {
    dispatch(setToolbarTitle('Dashboard'));
  }, [dispatch]);

  useEffect(() => {
    // Get total summary for last 12 months
    const fromDate = getMomentByMonthOffset(12).endOf('month').valueOf();
    const toDate = moment().utc().endOf('month').valueOf();
    const payload = {
      fromDate,
      toDate,
      companyId,
      token,
    };
    api.dashboard.getRipeSummary(payload).then((summary) => {
      setAnnualSummary(formatSummary(summary));
    });

    // Get summaries for last 6 months
    const promiseQueue = [5, 4, 3, 2, 1, 0].map((index) => {
      const fromDate = getMomentByMonthOffset(index).startOf('month').valueOf();
      const toDate = getMomentByMonthOffset(index).endOf('month').valueOf();
      const payload = {
        fromDate,
        toDate,
        companyId,
        token,
      };
      return api.dashboard.getRipeSummary(payload);
    });
    Promise.all(promiseQueue).then((ripeSummary) => {
      const formattedSummaries = ripeSummary.map((summary) => {
        return formatSummary(summary);
      });
      const dateLabels = formattedSummaries.map((summary) => summary.dateLabel);
      setSummaries(formattedSummaries);
      setDateLabels(dateLabels);
      setBarData([
        {
          label: 'Automatic',
          data: formattedSummaries.map(
            (summary) => summary.totalInvoicesAutoCreated
          ),
          backgroundColor: colors.primary,
        },
        {
          label: 'Manual',
          data: formattedSummaries.map(
            (summary) => summary.totalInvoicesManuallyCreated
          ),
          backgroundColor: colors.greys200,
        },
      ]);
    });
  }, [companyId, token]);

  function getTooltipLabel(
    this: TooltipModel<'bar'>,
    item: TooltipItem<'bar'>
  ) {
    const dataset = summaries[item.dataIndex];
    const percentage =
      item.raw === 0
        ? 0
        : Math.round((Number(item.raw) / dataset.totalInvoices) * 100);
    return `${item.raw}/${dataset.totalInvoices} (${percentage}%)`;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Relay</title>
      </Helmet>
      <Row>
        <Col span={isTablet ? 24 : 12}>
          {featureFlags.dashboardEnabled && (
            <Row gutter={[20, 20]} justify="space-between">
              {summaries[summaries.length - 1] && (
                <Col span={isTablet ? 24 : 12}>
                  <KpiBar
                    title={`Auto Processed (${
                      dateLabels[dateLabels.length - 1]
                    })`}
                    value={
                      summaries[summaries.length - 1].totalInvoicesAutoCreated
                    }
                    totalValue={summaries[summaries.length - 1].totalInvoices}
                    showFraction
                  />
                </Col>
              )}
              {annualSummary && (
                <Col span={isTablet ? 24 : 12}>
                  <KpiBar
                    title="Auto Processed (12 months)"
                    value={annualSummary.totalInvoicesAutoCreated}
                    totalValue={annualSummary.totalInvoices}
                    showFraction
                  />
                </Col>
              )}
              <Col span={24}>
                <BarChart
                  title="Invoices Auto Processed per Month"
                  labels={dateLabels}
                  datasets={barData}
                  tooltipLabelCallback={getTooltipLabel}
                  legendPosition={isTablet ? 'bottom' : 'right'}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

const getMomentByMonthOffset = (monthOffset: number): Moment => {
  return moment().subtract(monthOffset, 'months');
};
const formatSummary = (summary: RipeSummary): Summary => {
  return {
    dateLabel: moment(summary.fromDate).format('MMM YYYY'),
    totalInvoicesAutoCreated: summary.totalInvoicesAutoCreated,
    totalInvoicesManuallyCreated: summary.totalInvoicesCreatedAfterReview,
    totalInvoices:
      summary.totalInvoicesAutoCreated +
      summary.totalInvoicesCreatedAfterReview,
  };
};

export default Dashboard;
