import React from 'react';
import { Button, Result } from 'antd';
import { RouteComponentProps } from 'react-router-dom';

type Props = {
  history: RouteComponentProps['history'];
};

const Verified: React.FC<Props> = ({ history }) => (
  <Result
    status="success"
    title="Verification is complete"
    extra={[
      <Button
        key="continue"
        type="primary"
        onClick={() => {
          history.push('/invoices');
        }}
        size="large"
      >
        Continue
      </Button>,
    ]}
  />
);

export default Verified;
