import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import spacing from 'styles/layout/spacing';

export const Breadcrumbs: React.FC = () => {
  const history = useHistory();

  const handleGoBack = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    history.go(-1);
  };

  return (
    <Breadcrumb
      css={`
        margin-bottom: ${spacing.gutter.md};
      `}
    >
      <Breadcrumb.Item>
        <a href="/">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <a href="/companies" onClick={handleGoBack}>
          Companies
        </a>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};
