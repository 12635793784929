export enum licenceValidatorResult {
  Empty = "Please enter driver's licence number",
  Invalid = "Please enter a valid driver's licence number",
  Valid = 'Valid',
}

export const driversLicenceValidator = (
  licenceNumber: string
): licenceValidatorResult => {
  if (!licenceNumber) {
    return licenceValidatorResult.Empty;
  }
  const correctLength = licenceNumber.length === 8;
  const firstTwoAreLetters = /^[A-Za-z]*$/.test(licenceNumber.slice(0, 2));
  const lastSixAreNumbers = /^[0-9]*$/.test(licenceNumber.slice(2));
  if (!(correctLength && firstTwoAreLetters && lastSixAreNumbers)) {
    return licenceValidatorResult.Invalid;
  }
  return licenceValidatorResult.Valid;
};
