import React from 'react';
import { Card } from 'antd';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Tooltip,
  ChartOptions,
  TooltipModel,
  TooltipItem,
  LayoutPosition,
} from 'chart.js';
import spacing from 'styles/layout/spacing';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

type DataPoint = {
  label: string;
  data: number[];
  backgroundColor: string;
};

type Props = {
  title?: string;
  labels: string[];
  datasets: DataPoint[];
  className?: string;
  legendPosition?: LayoutPosition;
  tooltipLabelCallback: (
    this: TooltipModel<'bar'>,
    tooltipItem: TooltipItem<'bar'>
  ) => string | string[];
};

const BarChart: React.FC<Props> = ({
  className,
  title,
  labels,
  datasets,
  legendPosition,
  tooltipLabelCallback,
}) => {
  const datasetsWithStyles = datasets.map((set) => {
    return {
      ...set,
      barPercentage: 0.3,
      borderRadius: 5,
    };
  });
  const barData = {
    labels,
    datasets: datasetsWithStyles,
  };
  const callbacks = {
    label: tooltipLabelCallback,
  };
  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        position: legendPosition ?? 'right',
      },
      tooltip: {
        padding: spacing.gutterNumbers.sm,
        callbacks,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <Card className={className} title={title}>
      <Bar options={options} data={barData} />
    </Card>
  );
};

export default BarChart;
