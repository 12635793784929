import React, { useEffect, useState } from 'react';
import api from 'api';
import moment from 'moment';
import styled from 'styled-components';
import currencyFormatter from 'utils/currencyFormatter';
import { Card, Table, Tooltip, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import {
  MAX_FEE_PERCENTAGE,
  MIN_PURCHASER_OFFER_FEE,
  RELAY_FEE_PERCENTAGE,
} from 'config';
import PurchaserOffer from 'types/PurchaserOffer';
import Invoice from 'types/Invoice';
import processError from 'utils/processError';
import getTimestamp from 'utils/getTimestamp';

type InvoiceAPIResponse = {
  invoices: {
    invoice: Invoice;
    _links: {
      companyOwed: { href: string };
      owingCompany: { href: string };
      self: { href: string };
    };
  }[];
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
};

type PurchaserOfferAPIResponse = {
  purchaserOffer: PurchaserOffer;
  _links: {
    createdCompany: { href: string };
    self: { href: string };
  };
}[];

type InvoicesWithOffers = {
  key: string;
  customerCompanyId: string;
  customerName: string;
  numberOfInvoices: number;
  total: number;
  serviceFee: number;
  contactEmail: null | string;
  invoices: {
    key: string;
    invoiceNumber: string;
    dueDate: string;
    total: number;
    serviceFee: number;
  }[];
}[];

const RelayTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    background-color: rgb(103, 108, 116);
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-variant: tabular-nums;
    line-height: 1.5715;
    height: 24px;
    padding: 4px 14px;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 14px 14px;
    font-size: 14px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ant-table-expanded-row .ant-spin-nested-loading {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

const today = getTimestamp(moment());

const contactData = (email: string): React.ReactNode =>
  email && (
    <Tooltip placement="top" title={email}>
      <MailOutlined />
    </Tooltip>
  );

const Offers: React.FC<{ companyId: string }> = ({ companyId }) => {
  const [data, setData] = useState<InvoicesWithOffers>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    api.invoices
      .filter({ url: `?type=INVOICE&companyId=${companyId}&size=1000` })
      .then((invoicesResponse: InvoiceAPIResponse) => {
        const invoices = invoicesResponse.invoices.map((i) => i.invoice);
        api.purchaserOffers
          .getAvailable({ companyId })
          .then((offersResponse: PurchaserOfferAPIResponse) => {
            const offers = offersResponse.map((o) => o.purchaserOffer);
            const validOffers = offers.filter(
              (o) => o.offerStatus === 'ACTIVE' || o.offerStatus === 'VIRTUAL'
            );
            const invoicesIncludedInPurchaserOffer = invoices.filter(
              (i) => i.includeInPurchaserOffer && i.dueDate >= today
            );

            const invoicesWithOffers: InvoicesWithOffers = [];

            invoicesIncludedInPurchaserOffer.forEach((invoice) => {
              const offer = validOffers.find(
                (offer) => offer.createdByCompany === invoice.issuedFor
              );
              if (offer && invoice.discountedTotal <= offer.cashpool) {
                const numberOfDaysPaidEarly =
                  moment(invoice.dueDate).diff(moment(), 'days') + 1;
                let serviceFee =
                  (offer.crr *
                    invoice.discountedTotal *
                    numberOfDaysPaidEarly) /
                  (100 * 365 * ((100 - RELAY_FEE_PERCENTAGE) / 100));
                serviceFee += MIN_PURCHASER_OFFER_FEE;
                serviceFee = parseFloat(serviceFee.toFixed(2));
                const serviceFeePercent =
                  (serviceFee / invoice.discountedTotal) * 100;
                if (serviceFeePercent <= MAX_FEE_PERCENTAGE) {
                  const customerIndex = invoicesWithOffers.findIndex(
                    (x) => x.customerCompanyId === invoice.issuedFor
                  );
                  const invoiceRecord = {
                    invoiceNumber: invoice.invoiceNumber,
                    dueDate: moment(invoice.dueDate).format('DD MMM YYYY'),
                    total: invoice.discountedTotal,
                    serviceFee,
                    key: invoice.id,
                  };
                  if (customerIndex === -1) {
                    invoicesWithOffers.push({
                      key: invoice.issuedFor,
                      customerCompanyId: invoice.issuedFor,
                      customerName: invoice.issuedForCompanyName,
                      numberOfInvoices: 1,
                      total: invoice.discountedTotal,
                      serviceFee: serviceFee,
                      contactEmail: invoice.contactEmail,
                      invoices: [invoiceRecord],
                    });
                  } else {
                    const existingRecord = invoicesWithOffers[customerIndex];
                    invoicesWithOffers[customerIndex] = {
                      ...existingRecord,
                      numberOfInvoices: existingRecord.numberOfInvoices + 1,
                      total: existingRecord.total + invoice.discountedTotal,
                      serviceFee: existingRecord.serviceFee + serviceFee,
                      invoices: [...existingRecord.invoices, invoiceRecord],
                    };
                  }
                }
              }
            });

            setData(invoicesWithOffers);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            const { message } = processError(error);
            notification.error({
              message: 'Error fetching offers',
              description: message,
            });
          });
      })
      .catch((error) => {
        setLoading(false);
        const { message } = processError(error);
        notification.error({
          message: 'Error fetching invoices',
          description: message,
        });
      });
  }, [companyId]);

  const expandedRowRender = (rowdata: any) => {
    const columns = [
      {
        title: 'Invoice #',
        dataIndex: 'invoiceNumber',
        key: 'invoiceNumber',
        width: '24.5%',
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        width: '23.5%',
      },
      {
        title: 'Amount',
        dataIndex: 'total',
        key: 'amount',
        width: '19%',
        render: (amount: number) => currencyFormatter.format(amount),
      },
      {
        title: 'Service Fee',
        dataIndex: 'serviceFee',
        key: 'serviceFee',
        width: '20%',
        render: (amount: number) => currencyFormatter.format(amount),
      },
      {
        title: 'Dummy',
        dataIndex: 'dummyColumn',
        key: 'dummyColumn',
        width: '8%',
      },
    ];

    return (
      <RelayTable
        columns={columns}
        dataSource={rowdata.invoices}
        pagination={false}
        showHeader={false}
        indentSize={5}
      />
    );
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customerName',
      key: 'customer',
      width: '27%',
    },
    {
      title: '# of Invoices',
      dataIndex: 'numberOfInvoices',
      key: 'numberOfInvoices',
      width: '25%',
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      render: (amount: number) => currencyFormatter.format(amount),
    },
    {
      title: 'Service Fee',
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      width: '20%',
      render: (amount: number) => currencyFormatter.format(amount),
    },
    {
      title: 'Contact',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      render: (email: string) => contactData(email),
      width: '8%',
    },
  ];

  return (
    <Card>
      <RelayTable
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={data}
        pagination={false}
        loading={loading}
      />
    </Card>
  );
};

export default Offers;
