import { Form, notification } from 'antd';
import api from 'api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BankAccount from 'types/BankAccount';
import Company from 'types/Company';
import User from 'types/User';
import { processError, trimAccountNumber } from 'utils';

import {
  AccountFormValues,
  AustraliaOptions,
  StatementFormValues,
  Steps,
} from './types';

const processNZAccountNumber = (accountNumber: string) => {
  if (accountNumber.length === 16) {
    return accountNumber;
  }
  const charToInsert = '0';
  const positionToInsert = 13;
  return [
    accountNumber.slice(0, positionToInsert),
    charToInsert,
    accountNumber.slice(positionToInsert),
  ].join('');
};

const CODE_INVALID_EXCEED_LIMIT =
  'You have made 3 failed attempts. Please request for a new code.';
const CODE_INVALID_ERROR = 'Invalid code. Please check the code you received.';
const CODE_MISSING_ERROR =
  'Please enter the code recieved on your phone number';
const CODE_LENGTH = 6;

type UseAddAccountParams = {
  addNewBankAccount: (newBankAccount: BankAccount) => void;
  defaultWithdrawalAccount?: BankAccount;
  onHide: () => void;
  onStatementUploadSuccess: () => void;
};

export const useAddAccount = ({
  addNewBankAccount,
  defaultWithdrawalAccount,
  onHide,
  onStatementUploadSuccess,
}: UseAddAccountParams) => {
  const [australiaOption, setAustraliaOption] = useState(
    AustraliaOptions.PAYID
  );

  const [accountForm] = Form.useForm<AccountFormValues>();
  const [statementForm] = Form.useForm<StatementFormValues>();

  const [step, setStep] = useState(Steps.DETAILS_ENTRY);

  const [accountFormValues, setAccountFormValues] =
    useState<AccountFormValues>();
  const [statementFile, setStatementFile] = useState<File | null>(null);

  const [mfaCode, setMfaCode] = useState('');
  const [tries, setTries] = useState(0);
  const [mfaError, setMfaError] = useState('');

  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (defaultWithdrawalAccount) {
      setStep(Steps.BANK_STATEMENT);
    }
  }, [defaultWithdrawalAccount]);

  useEffect(() => {
    if (accountFormValues && step === Steps.DETAILS_ENTRY) {
      accountForm.setFieldsValue(accountFormValues);
    }
  }, [accountForm, accountFormValues, step]);

  const user: User = useSelector((state: any) => state.auth.user);
  const { id: userId, phoneNumber } = user;
  const phoneNumberFragment = phoneNumber
    ? phoneNumber.slice(phoneNumber.length - 4)
    : null;

  const company: Company = useSelector(
    (state: any) => state.auth.user?.company?.company
  );
  const isCountryAU = company?.address?.country === 'AU';
  const accountNumberFormat = isCountryAU
    ? '### ### ### #'
    : '##-####-#######-###';

  const moveToDetailsEntry = () => setStep(Steps.DETAILS_ENTRY);

  const moveToReview = (formValues: AccountFormValues) => {
    setAccountFormValues(formValues);
    setStep(Steps.DETAILS_REVIEW);
  };

  const moveToCodeEntry = () => setStep(Steps.CODE_ENTRY);

  const movetoStatementUpload = () => setStep(Steps.BANK_STATEMENT);

  const sendMfaCode = () => {
    api.user.code.send({ userId });
  };

  const postAccount = () => {
    if (!accountFormValues) {
      notification.error({
        message: 'Values are missing',
      });
      return;
    }

    if (
      step === Steps.CODE_ENTRY &&
      (!mfaCode || mfaCode?.length < CODE_LENGTH)
    ) {
      setMfaError(CODE_MISSING_ERROR);
      return;
    }

    setSubmitting(true);

    const bankAccountNumber = accountFormValues.bankAccountNumber
      ? trimAccountNumber(accountFormValues.bankAccountNumber)
      : '';

    const nzAccount = {
      bankAccountName: accountFormValues.bankAccountName,
      bankAccountNumber: processNZAccountNumber(bankAccountNumber),
      code: step === Steps.CODE_ENTRY ? mfaCode : undefined,
    };

    const auAccount =
      australiaOption === AustraliaOptions.ACCOUNT_NUMBER
        ? {
            bankAccountName: accountFormValues.bankAccountName,
            bsb: accountFormValues.bsb,
            bankAccountNumber: trimAccountNumber(bankAccountNumber),
            code: step === Steps.CODE_ENTRY ? mfaCode : undefined,
          }
        : {
            bankAccountName: accountFormValues.bankAccountName,
            payId: accountFormValues.payid,
            code: step === Steps.CODE_ENTRY ? mfaCode : undefined,
          };

    const account = isCountryAU ? auAccount : nzAccount;

    api.company
      .postBankAccount({ account, companyId: company.id })
      .then((newBankAccount) => {
        addNewBankAccount(newBankAccount);
        movetoStatementUpload();
      })
      .catch((error) => {
        const { message, status } = processError(error);
        if (status === 400 && message === '2fa code missing') {
          sendMfaCode();
          setTries(0);
          setMfaError('');
          moveToCodeEntry();
        } else if (step === Steps.CODE_ENTRY) {
          if (tries === 2) {
            // User had already tried two times.
            // Current is third try.
            setMfaError(CODE_INVALID_EXCEED_LIMIT);
          } else {
            setMfaError(CODE_INVALID_ERROR);
          }
          setTries(tries + 1);
        } else {
          notification.error({ message });
        }
      })
      .finally(() => setSubmitting(false));
  };

  const uploadStatement = () => {
    if (!defaultWithdrawalAccount) {
      notification.error({ message: 'No default account added to verify' });
      return;
    }

    if (!statementFile) {
      notification.error({ message: 'No statement file selected' });
      return;
    }

    setSubmitting(true);

    const formData = new FormData();
    formData.append('file', statementFile);

    api.company
      .verifyBankAccount({
        bankAccountId: defaultWithdrawalAccount.id,
        companyId: company.id,
        formData,
      })
      .then(() => {
        onStatementUploadSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setSubmitting(false));
  };

  const handleHide = () => {
    accountForm.resetFields();
    setAccountFormValues(undefined);

    statementForm.resetFields();
    setStatementFile(null);

    setAustraliaOption(AustraliaOptions.PAYID);

    setSubmitting(false);

    setMfaCode('');
    setMfaError('');
    setTries(0);

    onHide();
  };

  let modalTitle = 'Add Bank Account';

  let modalSubTitle =
    'Withdrawals from your Relay account will be sent to this bank account';

  let primaryAction = {
    action: () => {},
    label: '',
  };

  let secondaryAction = {
    action: handleHide,
    label: 'Cancel',
  };

  if (step === Steps.DETAILS_ENTRY) {
    primaryAction = {
      action: accountForm.submit,
      label: 'Review',
    };
  }

  if (step === Steps.DETAILS_REVIEW) {
    modalTitle = 'New Bank Account';
    primaryAction = {
      action: postAccount,
      label: 'Next',
    };
    secondaryAction = {
      action: moveToDetailsEntry,
      label: 'Edit',
    };
  }

  if (step === Steps.BANK_STATEMENT) {
    modalTitle = 'Verify Bank Account';
    modalSubTitle =
      'Please upload a screenshot or photo of your recent bank statement that includes your company name. Only JPG, PNG, and PDF files of size less than 10 MB are supported.';
    primaryAction = {
      action: statementForm.submit,
      label: 'Submit',
    };
  }

  if (step === Steps.CODE_ENTRY) {
    primaryAction = {
      action: postAccount,
      label: 'Next',
    };
  }

  const okButtonProps = {
    loading: isSubmitting,
    disabled: step === Steps.CODE_ENTRY && tries === 3,
  };

  const cancelButtonProps = {
    disabled: isSubmitting,
  };

  const accountFormProps = {
    accountNumberFormat,
    australiaOption,
    setAustraliaOption,
    form: accountForm,
    isCountryAU,
    onSubmit: moveToReview,
  };

  const accountReviewProps = {
    ...accountFormValues,
    isCountryAU,
    australiaOption,
  };

  const bankStatementProps = {
    form: statementForm,
    onChange: setStatementFile,
    onSubmit: uploadStatement,
  };

  const verificationCodeProps = {
    disabled: tries === 3,
    error: mfaError,
    onChange: setMfaCode,
    onSend: sendMfaCode,
    phoneNumberFragment,
    value: mfaCode,
  };

  return {
    accountFormProps,
    accountReviewProps,
    bankStatementProps,
    verificationCodeProps,

    okButtonProps,
    cancelButtonProps,

    modalTitle,
    modalSubTitle,
    primaryAction,
    secondaryAction,
    step,
  };
};
