import React, { useState } from 'react';
import { Tabs } from 'ds';

import BuyerSettings from './BuyerSettings';

const tabs = [
  { label: 'Buyer Settings', key: 'BUYER' },
  { label: 'Supplier Settings', key: 'SUPPLIER' },
];

const Settings: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('BUYER');

  const handleChangeTab = (tabKey: string) => {
    setSelectedTab(tabKey);
  };

  return (
    <Tabs activeKey={selectedTab} onChange={handleChangeTab}>
      {tabs.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.key}>
          {tab.key === 'BUYER' && <BuyerSettings />}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default Settings;
