import moment from 'moment';

const getTimestamp = (
  date: string | number | moment.Moment,
  format?: string
): number => {
  return moment(date, format)
    .set({ hour: 23, minute: 59, second: 59 })
    .valueOf();
};

export default getTimestamp;
