import React from 'react';
import { Button, Popconfirm } from 'antd';

type Props = {
  selectedDocumentsCount: number;
  handleDelete: () => void;
  deleting: boolean;
};

const DeleteButton: React.FC<Props> = ({
  selectedDocumentsCount,
  handleDelete,
  deleting,
}) => {
  return (
    <Popconfirm
      title={`Delete the selected document${
        selectedDocumentsCount === 1 ? '' : 's'
      }?`}
      onConfirm={handleDelete}
      okText="Yes"
      cancelText="No"
      okButtonProps={{ danger: true, type: 'primary' }}
    >
      <Button loading={deleting} size="small">
        DELETE
      </Button>
    </Popconfirm>
  );
};

export default DeleteButton;
