import React, { useState } from 'react';
import PrimaryButton from 'pages/Verification/PrimaryButton';
import Footer from 'pages/Verification/Footer';
import Container from 'pages/Verification/Container';
import Error500 from 'pages/ErrorPages/Error500';
import styled from 'styled-components';
import { History } from 'history';
import { UserName } from 'components/UserVerification';
import { Typography, Button, Divider, notification, Alert } from 'antd';

import api from 'api';
import colors from 'colors';
import joinName from 'utils/joinName';
import processError from 'utils/processError';
import styles from './uploadStyles';
import urls from 'urls';

import User from 'types/User';
import { InputItem } from 'components';
import spacing from 'styles/layout/spacing';

const { Text } = Typography;

const IdentityContainer = styled.div`
  background: ${colors.greys100};
  border-radius: 4px;
  padding: 14px 20px 17px 20px;
  display: flex;
  flex-direction: column;
`;

const FILE_EMPTY_ERROR = 'Please select your document.';
const FILE_TYPE_ERROR =
  'Unsupported file format. Please upload your document in JPG or PNG format.';
const FILE_SIZE_ERROR = 'Please choose a file which is less than 10 MB in size';
const MAX_FILE_SIZE = 10 * 1000 * 1000; // 10 MB in bytes

type Props = {
  history: History;
  location?: { state?: { user?: User } };
  primaryUser?: User;
  owner?: User;
  token: string;
  isLoggedIn?: boolean;
  isLoggedInAsAdmin?: boolean;
  setUser?: (user: User) => void;
  updateOwner?: (user: User) => void;
};

const Upload: React.FC<Props> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    history,
    location,
    primaryUser,
    owner,
    token,
    isLoggedIn,
    isLoggedInAsAdmin,
    setUser,
    updateOwner,
  } = props;
  const isPrimaryUser = !!primaryUser;
  const user =
    primaryUser || (location && location.state && location.state.user) || owner;
  if (!user) {
    return <Error500 />;
  }
  const { firstName, middleName, lastName, roles, verificationStatus } = user;
  const name = joinName({ firstName, middleName, lastName });

  const selectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (!files || !files[0]) {
      setFile(null);
      return;
    }
    const file = files[0];
    const { size, type } = file;
    if (type !== 'image/jpeg' && type !== 'image/png') {
      setFileError(FILE_TYPE_ERROR);
      return;
    }
    if (size > MAX_FILE_SIZE) {
      setFileError(FILE_SIZE_ERROR);
      return;
    }
    setFileError('');
    setFile(file);
  };

  const onSubmit = (): void => {
    if (!file) {
      setFileError(FILE_EMPTY_ERROR);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    api.identity
      .upload({ formData, token, userId: user.id })
      .then(() => {
        if (isLoggedInAsAdmin) {
          notification.success({
            message: `Document upload successful for ${name}`,
          });
        }
        if (isPrimaryUser) {
          setUser &&
            setUser({ ...user, verificationStatus: 'VERIFICATION_PENDING' });
          history.replace(`${urls.verify.user}&token=${token}`);
        } else if (isLoggedIn) {
          updateOwner &&
            updateOwner({
              ...user,
              verificationStatus: 'VERIFICATION_PENDING',
            });
          history.replace(`${urls.verify.ownersList}`, {
            lastOperatedOwnerId: user.id,
          });
        } else if (isLoggedInAsAdmin) {
          updateOwner &&
            updateOwner({
              ...user,
              verificationStatus: 'VERIFICATION_PENDING',
            });
        } else {
          history.replace(`${urls.verify.ownersList}&token=${token}`, {
            lastOperatedOwnerId: user.id,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        const { debugMessage, message } = processError(error);
        if (debugMessage && debugMessage.includes('size exceeded')) {
          setFileError(FILE_SIZE_ERROR);
        } else {
          setError(message);
        }
      });
  };

  const onSkip = (): void => {
    let next = `${urls.verify.ownersList}`;
    if (!isLoggedIn) {
      next = `${next}&token=${token}`;
    }
    history.push(next);
  };

  return (
    <>
      <Container>
        <Alert
          type="warning"
          style={{ marginBottom: spacing.gutter.lg }}
          description={`Please upload a copy of ${
            isLoggedInAsAdmin ? 'the' : 'your'
          } identity document`}
        />
        <InputItem label="Full legal name">
          <UserName
            name={name}
            roles={roles}
            verificationStatus={verificationStatus}
          />
        </InputItem>
        <InputItem label="Upload Identity Document">
          <IdentityContainer>
            <Text>
              {`Please upload a copy of the photo page of ${
                isLoggedInAsAdmin ? 'the' : 'your'
              } identity document
              as a JPG or PNG file which is less than 10 MB in size.`}
            </Text>
            <input
              type="file"
              onChange={selectFile}
              accept="image/png, image/jpeg"
              style={{ marginTop: 10 }}
            />
            {fileError && (
              <Text style={styles.error} type="danger">
                {fileError}
              </Text>
            )}
          </IdentityContainer>
          {error && (
            <Text type="danger" style={styles.error}>
              {error}
            </Text>
          )}
        </InputItem>
      </Container>
      <Divider />
      <Footer>
        {!isPrimaryUser && !isLoggedInAsAdmin && (
          <Button size="large" type="ghost" onClick={onSkip}>
            Skip
          </Button>
        )}

        <PrimaryButton
          label={isLoggedInAsAdmin ? 'Upload' : 'Continue'}
          loading={loading}
          onClick={onSubmit}
          disabled={!!fileError}
        />
      </Footer>
    </>
  );
};

export default Upload;
