import React from 'react';
import { FilterDialogContainer } from 'components';

const FilterDialog = ({
  amountFilter,
  orderNumberFilter,
  invoiceNumberFilter,
  issueDateFilter,
  handleDone,
  handleReset,
}: {
  amountFilter: JSX.Element;
  orderNumberFilter: JSX.Element;
  invoiceNumberFilter: JSX.Element;
  issueDateFilter: JSX.Element;
  handleDone: () => void;
  handleReset: () => void;
}) => {
  return (
    <FilterDialogContainer handleDone={handleDone} handleReset={handleReset}>
      {amountFilter}
      {orderNumberFilter}
      {invoiceNumberFilter}
      {issueDateFilter}
    </FilterDialogContainer>
  );
};

export default FilterDialog;
