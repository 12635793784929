import React from 'react';
import styled from 'styled-components';

import colors from 'colors';
import { formatCurrency } from 'utils';

const Container = styled.div<{ prominent?: boolean }>`
  color: ${colors.greys900};
  font-size: 16px;
  font-weight: ${({ prominent }) => (prominent ? 'bold' : 'normal')};
`;

type Props = {
  children: number;
  prominent?: boolean;
  className?: string;
};

const RowValue: React.FC<Props> = ({ children, prominent, className }) => {
  return (
    <Container prominent={prominent} className={className}>
      {`${formatCurrency(children)}`}
    </Container>
  );
};

export default RowValue;
