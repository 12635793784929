import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AuthContainer from 'components/AuthContainer';
import AuthCard from 'components/AuthCard';
import { Typography, Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  width: 160px;
  align-self: center;
  margin-top: 50px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const Confirmation = () => {
  return (
    <AuthContainer>
      <AuthCard>
        <Helmet>
          <title>Password reset email sent | Relay</title>
        </Helmet>
        <Typography.Text>
          You should receive an email shortly and please follow the instructions
          to reset your password
        </Typography.Text>
        <StyledButton size="large" type="primary">
          <StyledLink to="/login">Go to login page</StyledLink>
        </StyledButton>
      </AuthCard>
    </AuthContainer>
  );
};

export default Confirmation;
