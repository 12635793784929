import React, { useCallback, useState, useEffect } from 'react';
import queryString from 'query-string';
import AuthContainer from 'components/AuthContainer';
import AuthCard from 'components/AuthCard';
import Rollup from './Rollup';
import Single from './Single';
import Error404 from 'pages/ErrorPages/Error404';
import api from 'api';
import urls from 'urls';
import Invoice from 'types/Invoice';
import User from 'types/User';
import { Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import { PurchaserOfferResource } from 'types/PurchaserOffer';

type QueryParams = {
  token: string;
  invoiceType: 'INVOICE' | 'BILL';
  type: 'invite' | 'notify';
};

const OfferLandingPage: React.FC<any> = (props) => {
  const { history, location } = props;

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [purchaserOffersAvailable, setPurchaserOffersAvailable] =
    useState<PurchaserOfferResource>([]);
  const [supplierOffersAvailable, setSupplierOffersAvailable] = useState([]);
  const [inviteError, setInviteError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [email, setEmail] = useState<User['username']>('');
  const [message, setMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');
  const [user, setUser] = useState<User | null>(null);

  const queryParams: any = queryString.parse(location.search);
  const { token, invoiceType, type }: QueryParams = queryParams;

  const getInvoicesForInvite = useCallback(() => {
    const url = `?type=${invoiceType}&usedForInvite=true&size=250&sort=total,asc`;
    api.invoices
      .filter({ url, token })
      .then((response) => {
        const invoices = response?.invoices?.map((invoice) => invoice?.invoice);
        if (invoices && Array.isArray(invoices)) {
          setInvoices(invoices);
        }
      })
      .finally(() => setLoading(false));
  }, [invoiceType, token]);

  const getNotifiedInvoices = useCallback(() => {
    api.invoices
      .getNotifiedInvoices({ invoiceType, token })
      .then((response) => {
        const invoices = response?.invoices?.map((invoice) => invoice?.invoice);
        if (invoices && Array.isArray(invoices)) {
          setInvoices(invoices);
        }
      })
      .finally(() => setLoading(false));
  }, [invoiceType, token]);

  useEffect(() => {
    if (!token || (invoiceType !== 'BILL' && invoiceType !== 'INVOICE')) {
      setInviteError(true);
      return;
    }
    const notified = type === 'notify';
    if (invoiceType === 'INVOICE') {
      api.purchaserOffers
        .getAvailable({ notified, token })
        .then((data) => setPurchaserOffersAvailable(data));
    } else if (invoiceType === 'BILL') {
      api.supplierOffers
        .getAvailable({ notified, token })
        .then((data) => setSupplierOffersAvailable(data));
    }

    if (type === 'invite') {
      api.user
        .get(token)
        .then(async (user) => {
          const userState = await api.user.getState({ token, userId: user.id });
          if (userState.state === 'EMAIL_VERIFIED') {
            // User has earlier clicked on signup link and confirmed their email and set their company
            // Now they need to set name and password
            history.push(`${urls.activate.name}&token=${token}`);
            return;
          }
          getInvoicesForInvite();
          setEmail(user.username);
          setUser(user);
        })
        .catch(() => {
          setInviteError(true);
          setLoading(false);
        });
    } else {
      getNotifiedInvoices();
    }
  }, [
    type,
    getInvoicesForInvite,
    getNotifiedInvoices,
    history,
    invoiceType,
    token,
  ]);

  if (inviteError) {
    return <Error404 />;
  }
  return (
    <AuthContainer welcome={false} isLoggedIn={false}>
      <AuthCard message={message} subMessage={subMessage}>
        {isLoading ? (
          <Skeleton active />
        ) : invoices.length === 1 ? (
          <Single
            email={email}
            invoice={invoices[0]}
            invoiceType={invoiceType}
            purchaserOffersAvailable={purchaserOffersAvailable}
            supplierOffersAvailable={supplierOffersAvailable}
            token={token}
            setMessage={setMessage}
            setSubMessage={setSubMessage}
            user={user}
            type={type}
          />
        ) : (
          <Rollup
            email={email}
            invoices={invoices}
            invoiceType={invoiceType}
            purchaserOffersAvailable={purchaserOffersAvailable}
            token={token}
            setMessage={setMessage}
            setSubMessage={setSubMessage}
            user={user}
            type={type}
          />
        )}
      </AuthCard>
    </AuthContainer>
  );
};

export default withRouter(OfferLandingPage);
