const arrowRight = require('./arrow-right.svg');
const help = require('./help.png');
const xero = require('./xero.svg');
const xerologo = require('./xerologo.svg');
const beta = require('./beta.svg');
const relayLogo = require('./relayLogo.png');
const calendarIcon = require('./calendarIcon.svg');
const relayLogoLight = require('./relay-logo-light.png');
const relayNewLogo = require('./relay-new-logo.svg');
const relayNewLogoLight = require('./relay-new-logo-light.svg');

const xeroLogo = require('./xero_logo.png');
const myobLogo = require('./myob_logo.png');

const iconCheckCircle = require('./icon-check-filled.png');
const iconCircle = require('./circle.png');
const disabledCheckCircle = require('./disabled-check-circle.png');

const bg1 = require('./bg-1.png');
const city1 = require('./city-1.jpg');

export {
  arrowRight,
  help,
  xero,
  xerologo,
  beta,
  relayLogo,
  calendarIcon,
  relayLogoLight,
  relayNewLogo,
  relayNewLogoLight,
  xeroLogo,
  myobLogo,
  iconCheckCircle,
  iconCircle,
  disabledCheckCircle,
  bg1,
  city1,
};
