import React from 'react';
import InputItem from 'components/InputItem';
import styled from 'styled-components';
import { Select } from 'antd';
import spacing from 'styles/layout/spacing';

const { Option } = Select;

const months = [
  '01 - January',
  '02 - February',
  '03 - March',
  '04 - April',
  '05 - May',
  '06 - June',
  '07 - July',
  '08 - August',
  '09 - September',
  '10 - October',
  '11 - November',
  '12 - December',
];

const generateDates = (monthValue: string, year: string) => {
  // @ts-ignore
  const totalDays = new Date(year, monthValue, 0).getDate();
  const dates = [];
  for (let i = 1; i <= totalDays; i++) {
    dates.push({ key: i, label: i, value: i < 10 ? `0${i}` : `${i}` });
  }
  return dates;
};

const currentYear = new Date().getFullYear();

const generateYears = () => {
  const years = [
    { key: currentYear, label: currentYear, value: `${currentYear}` },
  ];
  for (let i = 1; i <= 100; i++) {
    years.push({
      key: currentYear - i,
      label: currentYear - i,
      value: `${currentYear - i}`,
    });
  }
  return years;
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${spacing.gutter.md};
`;

type DOB = {
  year: string;
  month: string;
  day: string;
};

type Props = {
  dateOfBirth: DOB;
  setDateOfBirth: (dob: DOB) => void;
  dobError: string;
};

const DateOfBirth: React.FC<Props> = ({
  dateOfBirth,
  setDateOfBirth,
  dobError,
}) => {
  const { year, month, day } = dateOfBirth;

  const selectDate = (value: string) => {
    setDateOfBirth({ ...dateOfBirth, day: value });
  };
  const selectMonth = (value: string) => {
    // The value param is name of month eg. 'January'
    // We need to map it to the corresponding number eg. '01'
    let monthNumber: string | number = months.indexOf(value) + 1;
    if (monthNumber < 10) {
      // Prepend 0 for months from 1 to 9
      monthNumber = `0${monthNumber}`;
    }
    setDateOfBirth({ ...dateOfBirth, month: String(monthNumber) });
  };
  const selectYear = (value: string) => {
    setDateOfBirth({ ...dateOfBirth, year: value });
  };

  return (
    <InputItem label="Date of Birth" error={dobError}>
      <Row>
        <Select
          onChange={selectDate}
          showSearch
          style={{ flex: 1 }}
          value={day || undefined}
          optionFilterProp="children"
          filterOption={(input, option) =>
            // @ts-ignore
            option.children.toString().indexOf(input.toString()) === 0
          }
          notFoundContent={null}
          placeholder="Day"
          size="large"
        >
          {generateDates(month, year).map((date) => (
            <Option value={date.value} key={date.value}>
              {date.label}
            </Option>
          ))}
        </Select>
        <Select
          style={{ flex: 2 }}
          showSearch
          value={months[parseInt(month) - 1]}
          onChange={selectMonth}
          filterOption={true}
          notFoundContent={null}
          placeholder="Month"
          size="large"
        >
          {months.map((month) => (
            <Option value={month} key={month}>
              {month}
            </Option>
          ))}
        </Select>
        <Select
          style={{ flex: 1 }}
          showSearch
          value={year || undefined}
          onChange={selectYear}
          optionFilterProp="children"
          filterOption={(input, option) =>
            // @ts-ignore
            option.children.toString().indexOf(input.toString()) === 0
          }
          notFoundContent={null}
          placeholder="Year"
          size="large"
        >
          {generateYears().map((year) => (
            <Option value={year.value} key={year.value}>
              {year.label}
            </Option>
          ))}
        </Select>
      </Row>
    </InputItem>
  );
};

export default DateOfBirth;
