import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes';

const Container = styled.div`
  margin-top: 11px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Text = styled.span`
  font-size: 12px;
  color: ${colors.greys700};
`;

const Footer = () => (
  <Container>
    <Text>
      Suppliers are paid from the cash reserve if the prompt payment offer is
      accepted
    </Text>
  </Container>
);

export default Footer;
