import api from 'api';
import processError from 'utils/processError';
import { message } from 'antd';
import { getAnalytics } from './analytics';

const SHOW_CREATE_INVIOCE = 'SHOW_CREATE_INVIOCE';
const HIDE_CREATE_INOICE = 'HIDE_CREATE_INOICE';
const CREATE_NEW_INVOICE = 'CREATE_NEW_INVOICE';
const SHOW_IMPORT_FILE = 'SHOW_IMPORT_FILE';
const HIDE_IMPORT_FILE = 'HIDE_IMPORT_FILE';

const UPDATE_INVOICE = 'UPDATE_INVOICE';
const UPDATE_BILL = 'UPDATE_BILL';

const SHOW_INVITE_WINDOW = 'SHOW_INVITE_WINDOW';
const HIDE_INVITE_WINDOW = 'HIDE_INVITE_WINDOW';

const FILTER_INVOICES = 'FILTER_INVOICES';
const FILTER_BILLS = 'FILTER_BILLS';
const FILTER_PAYABLE_SUMMARIES = 'FILTER_PAYABLE_SUMMARIES';
const LOADING_INVOICES = 'LOADING_INVOICES';
const FINISH_LOADING = 'FINISH_LOADING';
const SORT_TABLE = 'SORT_TABLE';

const UPDATE_BILL_PURCHASER_OFFER_ACTION = 'UPDATE_BILL_PURCHASER_OFFER_ACTION';

const LOGOUT = 'LOGOUT';

const CLEAR_INVOICES = 'CLEAR_INVOICES';

const SET_COMPANY_NAME_TO_ADD_INVOICE = 'SET_COMPANY_NAME_TO_ADD_INVOICE';
const CLEAR_COMPANY_NAME_TO_ADD_INVOICE = 'CLEAR_COMPANY_NAME_TO_ADD_INVOICE';

const initialState = {
  invoices: [],
  bills: [],
  payableSummaries: [],
  invoiceStatus: 'ALL_ELIGIBLE',
  customers: [],
  isVisibleNewInvoiceWindow: false,
  selectedInvoice: null,
  status: null,
  isVisibleInvite: false,

  totalNumberOfInvoices: 0,
  totalNumberOfBills: 0,
  totalNumberOfPayableSummaries: 0,

  loadingInvoices: false,
  isVisibleImportFileWindow: false,
  // to preselect company name if user is trying to add bill/invoice from supplier/customer details page
  companyNameToAddInvoice: null,

  paidInvoice: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return initialState;
    case CLEAR_INVOICES:
      return {
        ...state,
        invoices: [],
        bills: [],
        payableSummaries: [],
      };

    case LOADING_INVOICES:
      return {
        ...state,
        loadingInvoices: true,
      };
    case FILTER_INVOICES: {
      return {
        ...state,
        invoices: payload.invoices,
        loadingInvoices: false,
        totalNumberOfInvoices: payload.totalElements,
      };
    }
    case FILTER_BILLS: {
      return {
        ...state,
        bills: payload.invoices,
        loadingInvoices: false,
        totalNumberOfBills: payload.totalElements,
      };
    }
    case FILTER_PAYABLE_SUMMARIES: {
      return {
        ...state,
        payableSummaries: payload.payablesSummaries,
        loadingInvoices: false,
        totalNumberOfPayableSummaries: payload.totalElements,
      };
    }
    case SORT_TABLE: {
      return {
        ...state,
        sortingLabel: payload.sortingLabel,
        sortingOrder: payload.sortingOrder,
      };
    }

    case SHOW_CREATE_INVIOCE:
      return { ...state, isVisibleNewInvoiceWindow: true, type: payload };

    case SHOW_IMPORT_FILE:
      return { ...state, isVisibleImportFileWindow: true, type: payload };

    case HIDE_CREATE_INOICE:
      return { ...state, isVisibleNewInvoiceWindow: false, type: '' };

    case HIDE_IMPORT_FILE:
      return { ...state, isVisibleImportFileWindow: false, type: '' };

    case CREATE_NEW_INVOICE:
      return {
        ...state,
        invoices: [...state.invoices, payload],
        isVisibleNewInvoiceWindow: false,
      };

    case UPDATE_INVOICE: {
      const updatedInvoice = state.invoices.map((item) => {
        if (item.invoice.id === payload.id) {
          if (payload.data) {
            return (item = payload.data);
          } else {
            return {
              ...item,
              invoice: {
                ...item.invoice,
                owingCompany: {
                  ...item.invoice.owingCompany,
                  status: payload.relayStatus
                    ? payload.relayStatus
                    : item.invoice.owingCompany.status,
                  invitedBy: payload.invitedBy
                    ? payload.invitedBy
                    : item.invoice.owingCompany.invitedBy,
                },
                invoiceStatus: payload.status
                  ? payload.status
                  : item.invoice.invoiceStatus,
                totalPaid: payload.totalPaid
                  ? payload.totalPaid
                  : item.invoice.totalPaid,
                usedForInvite: payload.usedForInvite
                  ? payload.usedForInvite
                  : item.invoice.usedForInvite,
              },
            };
          }
        } else {
          if (item.invoice.owingCompany.id === payload.invitedCompany) {
            return {
              ...item,
              invoice: {
                ...item.invoice,
                owingCompany: {
                  ...item.invoice.owingCompany,
                  status: payload.relayStatus
                    ? payload.relayStatus
                    : item.invoice.owingCompany.status,
                  invitedBy: payload.invitedBy
                    ? payload.invitedBy
                    : item.invoice.owingCompany.invitedBy,
                },
                usedForInvite: payload.usedForInvite
                  ? payload.usedForInvite
                  : item.invoice.usedForInvite,
              },
            };
          }
          return item;
        }
      });
      if (payload.status && payload.status === 'PAID') {
        return {
          ...state,
          invoices: updatedInvoice.filter(
            (item) => item.invoice.invoiceStatus !== 'PAID'
          ),
        };
      } else {
        return { ...state, invoices: updatedInvoice };
      }
    }
    case UPDATE_BILL: {
      const updatedBill = state.bills.map((bill) => {
        if (bill.invoice.id === payload.id) {
          if (payload.data) {
            return (bill = payload.data);
          } else {
            return {
              ...bill,
              invoice: {
                ...bill.invoice,
                companyOwed: {
                  ...bill.invoice.companyOwed,
                  status: payload.relayStatus
                    ? payload.relayStatus
                    : bill.invoice.companyOwed.status,
                  invitedBy: payload.invitedBy
                    ? payload.invitedBy
                    : bill.invoice.companyOwed.invitedBy,
                },
                billStatus: payload.status
                  ? payload.status
                  : bill.invoice.billStatus,
                totalPaid: payload.totalPaid
                  ? payload.totalPaid
                  : bill.invoice.totalPaid,
                includeInPurchaserOffer:
                  payload.includeInPurchaserOffer !== undefined
                    ? payload.includeInPurchaserOffer
                    : bill.invoice.includeInPurchaserOffer,
                usedForInvite: payload.usedForInvite
                  ? payload.usedForInvite
                  : bill.invoice.usedForInvite,
              },
            };
          }
        } else {
          if (bill.invoice.companyOwed.id === payload.invitedCompany) {
            return {
              ...bill,
              invoice: {
                ...bill.invoice,
                companyOwed: {
                  ...bill.invoice.companyOwed,
                  status: payload.relayStatus
                    ? payload.relayStatus
                    : bill.invoice.companyOwed.status,
                  invitedBy: payload.invitedBy
                    ? payload.invitedBy
                    : bill.invoice.companyOwed.invitedBy,
                },
                usedForInvite: payload.usedForInvite
                  ? payload.usedForInvite
                  : bill.invoice.usedForInvite,
              },
            };
          }
          return bill;
        }
      });
      if (payload.status && payload.status === 'PAID') {
        return {
          ...state,
          bills: updatedBill.filter(
            (bill) => bill.invoice.billStatus !== 'PAID'
          ),
        };
      } else {
        return { ...state, bills: updatedBill };
      }
    }

    case SHOW_INVITE_WINDOW: {
      return {
        ...state,
        isVisibleInvite: true,
        selectedInvoice: payload.data,
        invoiceType: payload.type,
      };
    }
    case HIDE_INVITE_WINDOW: {
      return { ...state, isVisibleInvite: false, invoiceType: null };
    }

    case UPDATE_BILL_PURCHASER_OFFER_ACTION: {
      const updatedInclusionBill = state.bills.map((bill) => {
        if (bill.invoice.id === payload.id) {
          return {
            ...bill,
            invoice: {
              ...bill.invoice,
              includeInPurchaserOffer: payload.inclusionPurchaserOffer,
            },
          };
        } else {
          return bill;
        }
      });
      return { ...state, bills: updatedInclusionBill };
    }

    case FINISH_LOADING:
      return { ...state, loadingInvoices: false };
    case SET_COMPANY_NAME_TO_ADD_INVOICE:
      return { ...state, companyNameToAddInvoice: payload };
    case CLEAR_COMPANY_NAME_TO_ADD_INVOICE:
      return { ...state, companyNameToAddInvoice: null };
    default:
      return state;
  }
};

//clear invoices when disconnect from xero
export const clearInvoices = () => ({ type: CLEAR_INVOICES });

// filter actions start here
export const filterInvoices = ({ abortController, url }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICES });
    api.invoices
      .filter({ abortController, url })
      .then((invoices) =>
        dispatch({ type: FILTER_INVOICES, payload: invoices })
      );
    dispatch(getAnalytics('invoice'));
  };
};
export const filterBills = ({ abortController, url }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICES });
    api.invoices
      .filter({ abortController, url })
      .then((bills) => dispatch({ type: FILTER_BILLS, payload: bills }))
      .catch((error) => {
        const errorMessage = processError(error).message;
        if (errorMessage !== 'canceled') {
          message.error(errorMessage);
        }
        dispatch({ type: FINISH_LOADING });
        dispatch({ type: CLEAR_INVOICES });
      });
    dispatch(getAnalytics('bill'));
  };
};
export const filterPayableSummaries = ({ abortController, url }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_INVOICES });
    api.payablesSummary
      .filter({ abortController, url })
      .then((payableSummaries) =>
        dispatch({ type: FILTER_PAYABLE_SUMMARIES, payload: payableSummaries })
      )
      .catch((error) => {
        const errorMessage = processError(error).message;
        if (errorMessage !== 'canceled') {
          message.error(errorMessage);
        }
        dispatch({ type: FINISH_LOADING });
        dispatch({ type: CLEAR_INVOICES });
      });
    dispatch(getAnalytics('bill'));
  };
};

export const showCreateInvoiceWindow = (type) => ({
  type: SHOW_CREATE_INVIOCE,
  payload: type,
});

export const hideCreateInvoiceWindow = () => ({ type: HIDE_CREATE_INOICE });

export const createNewInvoice = (invoice) => {
  return {
    type: CREATE_NEW_INVOICE,
    payload: invoice,
  };
};

export const updateInvoice = (payload) => {
  const {
    id,
    status,
    relayStatus,
    data,
    usedForInvite,
    invitedBy,
    invitedCompany,
  } = payload;
  return {
    type: UPDATE_INVOICE,
    payload: {
      id,
      status,
      relayStatus,
      data,
      usedForInvite,
      invitedBy,
      invitedCompany,
    },
  };
};
export const updateBill = (payload) => {
  const {
    id,
    status,
    data,
    totalPaid,
    relayStatus,
    includeInPurchaserOffer,
    usedForInvite,
    invitedBy,
    invitedCompany,
  } = payload;
  return {
    type: UPDATE_BILL,
    payload: {
      id,
      status,
      data,
      totalPaid,
      relayStatus,
      includeInPurchaserOffer,
      usedForInvite,
      invitedBy,
      invitedCompany,
    },
  };
};

export const showInviteWindow = ({ type, data }) => ({
  type: SHOW_INVITE_WINDOW,
  payload: { type, data },
});

export const hideInviteWindow = () => ({ type: HIDE_INVITE_WINDOW });

export const updateBillPurchaserOfferAction = ({
  id,
  inclusionPurchaserOffer,
}) => ({
  type: UPDATE_BILL_PURCHASER_OFFER_ACTION,
  payload: { id, inclusionPurchaserOffer },
});

export const showImportFileWindow = (type) => ({
  type: SHOW_IMPORT_FILE,
  payload: type,
});
export const hideImportFileWindow = () => ({ type: HIDE_IMPORT_FILE });

export const setCompanyNameToAddInvoice = (companyName) => ({
  type: SET_COMPANY_NAME_TO_ADD_INVOICE,
  payload: companyName,
});
export const clearCompanyNameToAddInvoice = () => ({
  type: CLEAR_COMPANY_NAME_TO_ADD_INVOICE,
});
