import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { bootstrapTransactions, getTransactions } from 'store/transactions';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateForDisplay, formatCurrency } from 'utils';
import Transaction from 'types/Transaction';
import { setPageNumber } from 'store/ripe';
import colors from 'colors';
import { Tooltip } from 'antd';
import { StatusDot } from 'ds';
import { TableCellWithStatusDot } from 'components';

type Props = {
  companyId: string;
};

const Transactions: React.FC<Props> = ({ companyId }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();
  const transactionsState = useSelector((state: any) => state.transactions);
  const transactions: Array<Transaction> = transactionsState.transactions.map(
    (i: any) => i.transaction
  );
  const loading: boolean = transactionsState.loadingTransactions;
  const totalNumberOfTransactions: number =
    transactionsState.totalNumberOfTransactions;
  const spillOverElements = transactionsState.spillOverElements;

  useEffect(() => {
    dispatch(bootstrapTransactions({ page: 0, size: 25, companyId }));
  }, [companyId, dispatch]);

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setPageSize(pageSize);

    if (pageNumber === 1) {
      dispatch(bootstrapTransactions({ page: 0, size: pageSize, companyId }));
    } else {
      dispatch(
        getTransactions({
          page: pageNumber - 1,
          size: pageSize,
          spillOverElements,
          companyId,
        })
      );
    }
  };
  const onPageSizeChange = (pageSize: number) => {
    //setPageSize(pageSize);
    setPageNumber(1);
    //dispatch(bootstrapTransactions({ size: pageSize, page: 0, companyId }));
  };

  const renderDate = (date: number) => formatDateForDisplay(date);

  const renderDetails = (transaction: Transaction) => {
    const {
      sourceCompanyId,
      destinationCompanyName,
      sourceCompanyName,
      sourceParticulars,
      destinationParticulars,
    } = transaction;
    let details =
      sourceCompanyId === companyId
        ? destinationCompanyName
        : sourceCompanyName;
    if (sourceParticulars && sourceParticulars === 'Commission') {
      details = 'Service Fee';
    }
    if (destinationParticulars && destinationParticulars === 'Commission') {
      details = 'Cashback';
    }
    return details;
  };

  const renderMoneyIn = (transaction: Transaction) => {
    const { destinationCompanyId, transactionClass, transactionAmount } =
      transaction;
    const moneyIn =
      (destinationCompanyId === companyId || transactionClass === 'TOPUP') &&
      transactionAmount;
    if (moneyIn) {
      return formatCurrency(moneyIn);
    } else {
      return '';
    }
  };

  const renderMoneyOut = (transaction: Transaction) => {
    const { sourceCompanyId, transactionClass, transactionAmount } =
      transaction;
    const moneyOut =
      sourceCompanyId === companyId &&
      transactionClass !== 'TOPUP' &&
      transactionAmount;
    if (moneyOut) {
      return `-${formatCurrency(moneyOut)}`;
    } else {
      return '';
    }
  };

  const renderBalance = (transaction: Transaction) => {
    const {
      sourceAccountBalance,
      destinationAccountBalance,
      transactionClass,
      status,
    } = transaction;
    if (
      (transactionClass === 'TOPUP' && status === 'PENDING') ||
      (transactionClass === 'WITHDRAWAL' && status === 'PENDING')
    ) {
      return 'Pending';
    }
    const balance = sourceAccountBalance || destinationAccountBalance;
    return balance ? formatCurrency(balance) : '';
  };

  type Column = {
    title: string;
    render: (transaction: Transaction) => string | any | null;
    key: string;
    align: 'left' | 'right' | 'center';
  };

  const columns: Array<Column> = [
    {
      title: 'Date',
      render: (transaction: Transaction) =>
        renderDate(transaction.modificationTime),
      key: 'date',
      align: 'left',
    },
    {
      title: 'Reconciled',
      render: (transaction: Transaction) => {
        return transaction.reconciled ? (
          <TableCellWithStatusDot>
            <Tooltip color={colors.greens.dark} title="Reconciled">
              <StatusDot color={colors.greens.dark} />
            </Tooltip>
          </TableCellWithStatusDot>
        ) : (
          <TableCellWithStatusDot>
            <Tooltip color={colors.warms.red} title="Not reconciled">
              <StatusDot color={colors.warms.red} />
            </Tooltip>
          </TableCellWithStatusDot>
        );
      },
      key: 'reconciled',
      align: 'center',
    },
    {
      title: 'Details',
      render: (transaction: Transaction) => renderDetails(transaction),
      key: 'details',
      align: 'left',
    },
    {
      title: 'Money In',
      render: (transaction: Transaction) => renderMoneyIn(transaction),
      key: 'money_in',
      align: 'right',
    },
    {
      title: 'Money Out',
      render: (transaction: Transaction) => renderMoneyOut(transaction),
      key: 'money_out',
      align: 'right',
    },
    {
      title: 'Balance',
      render: (transaction: Transaction) => renderBalance(transaction),
      key: 'balance',
      align: 'right',
    },
  ];
  return (
    <Table
      dataSource={transactions}
      columns={columns}
      loading={loading}
      pagination={{
        current: page,
        pageSize,
        total: totalNumberOfTransactions,
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        onShowSizeChange: onPageSizeChange,
        onChange: (page, pageSize) =>
          page && pageSize && onPageChange(page, pageSize),
      }}
    />
  );
};

export default Transactions;
