import React from 'react';
import styled from 'styled-components';

import { help } from 'assets/images';

const Container = styled.img`
  width: 14px;
  height: 14px;
`;

const HelpIndicator = ({ className }) => {
  return <Container src={help} className={className} />;
};

export default HelpIndicator;
