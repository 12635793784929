import { Alert } from 'antd';
import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import spacing from 'styles/layout/spacing';
import { formatCurrency } from 'utils';

const StyledAlert = styled(Alert)`
  margin-top: ${spacing.gutter.md};
  .ant-alert-action {
    position: relative;
    right: 50px;
  }
`;

type Props = {
  amount: number;
};

export const AmountRemaining: React.FC<Props> = (props) => {
  const { amount } = props;
  const formattedAmount = formatCurrency(amount);
  return (
    <StyledAlert
      message="Amount remaining"
      action={formattedAmount}
      type="info"
    />
  );
};
