import React from 'react';
import InputItem from 'components/InputItem';
import { Input } from 'antd';

type Props = {
  companyName: string;
  setCompanyName: (name: string) => void;
  error: string;
  onBlur?: () => void;
  placeholder?: string;
  label?: string;
};

const CompanyNameInput: React.FC<Props> = ({
  companyName,
  setCompanyName,
  error,
  onBlur,
  placeholder,
  label,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCompanyName(e.target.value);
  return (
    <InputItem label={label} error={error}>
      <Input
        value={companyName}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder || 'Company name'}
        size="large"
      />
    </InputItem>
  );
};

export default CompanyNameInput;
