/* This function can be used when you want to display the name of a user by
   jonining the firstName, middleName and lastName fields in the user record
   returned by the API.

   We simply cannot concatenate these fields because the middleName field might
   be missing which results in an additional space in between firstName and
   lastName.
*/

import { JoinNameProps } from 'types/NameProps';

const joinName = ({
  firstName,
  middleName,
  lastName,
}: JoinNameProps): string => {
  let name = '';
  if (firstName) {
    name = firstName;
  }
  if (middleName) {
    name = `${name} ${middleName}`;
  }
  if (lastName) {
    name = `${name} ${lastName}`;
  }
  return name;
};

export default joinName;
