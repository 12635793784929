import { KpiStat } from 'ds';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import spacing from 'styles/layout/spacing';
import { InvoiceStatistics, RipeType } from 'types/RipeCompanyInvoice';
import { formatCurrency } from 'utils';

const StatContainer = styled.div<{ $fiveAcross?: boolean }>`
  max-width: ${({ $fiveAcross }) => ($fiveAcross ? '1125px' : '900px')};
  display: grid;
  grid-template-columns: ${({ $fiveAcross }) =>
    $fiveAcross ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'};
  grid-column-gap: ${spacing.gutter.md};
  margin-bottom: ${spacing.gutter.md};
  overflow-x: auto;
  overflow-y: hidden;
  .sub-text {
    margin-top: 0;
  }
`;

type Props = {
  ripeType: RipeType.PENDING_REVIEW | RipeType.VARIANCE;
  invoiceStatistics?: InvoiceStatistics;
  tab?: string;
};

const InvoiceKpis: React.FC<Props> = ({ ripeType, invoiceStatistics, tab }) => {
  const [kpiData, setKpiData] = useState<
    {
      title: string;
      value: string;
      subText?: string;
      active?: boolean;
    }[]
  >([]);

  const formatInvoiceSubtext = (value: number) =>
    value === 1 ? value + ' invoice' : value + ' invoices';

  useEffect(() => {
    if (invoiceStatistics) {
      if (ripeType === RipeType.PENDING_REVIEW) {
        const formattedData = [];
        if (invoiceStatistics.retry) {
          const { totalAmountDue, totalCount } = invoiceStatistics.retry;
          formattedData.push({
            title: 'Total Pending Review',
            value: formatCurrency(totalAmountDue),
            subText: formatInvoiceSubtext(totalCount),
          });
        }
        if (invoiceStatistics.orderNotFoundOcr) {
          const { totalAmountDue, totalCount } =
            invoiceStatistics.orderNotFoundOcr;
          formattedData.push({
            title: 'Total No PO Found',
            value: formatCurrency(totalAmountDue),
            subText: formatInvoiceSubtext(totalCount),
            active: tab === 'NO_PO_FOUND',
          });
        }
        if (invoiceStatistics.orderNotFoundGpos) {
          const { totalAmountDue, totalCount } =
            invoiceStatistics.orderNotFoundGpos;
          formattedData.push({
            title: 'Total No PO Match',
            value: formatCurrency(totalAmountDue),
            subText: formatInvoiceSubtext(totalCount),
            active: tab === 'NO_PO_MATCH',
          });
        }
        if (invoiceStatistics.statusMismatch) {
          const { totalAmountDue, totalCount } =
            invoiceStatistics.statusMismatch;
          formattedData.push({
            title: 'Total Status Mismatch',
            value: formatCurrency(totalAmountDue),
            subText: formatInvoiceSubtext(totalCount),
            active: tab === 'STATUS_MISMATCH',
          });
        }
        if (invoiceStatistics.amountOver || invoiceStatistics.amountUnder) {
          const unformattedValue =
            (invoiceStatistics.amountOver?.totalAmountDue ?? 0) +
            (invoiceStatistics.amountUnder?.totalAmountDue ?? 0);
          const unformattedCount =
            (invoiceStatistics.amountOver?.totalCount ?? 0) +
            (invoiceStatistics.amountUnder?.totalCount ?? 0);
          formattedData.push({
            title: 'Total Variances',
            value: formatCurrency(unformattedValue),
            subText: formatInvoiceSubtext(unformattedCount),
            active: tab === 'VARIANCE',
          });
        }
        setKpiData(formattedData);
      } else if (invoiceStatistics.variance) {
        const totalVariance =
          invoiceStatistics.variance.totalAmountDue -
          invoiceStatistics.variance.totalPosAmount;
        setKpiData([
          {
            title: 'Total Amount Due',
            value: formatCurrency(invoiceStatistics.variance.totalAmountDue),
          },
          {
            title: 'Total POS Amount',
            value: formatCurrency(invoiceStatistics.variance.totalPosAmount),
          },
          {
            title: 'Net Variance',
            value: formatCurrency(totalVariance),
          },
        ]);
      }
    }
  }, [invoiceStatistics, ripeType, tab]);

  return !invoiceStatistics || kpiData.length === 0 ? null : (
    <StatContainer $fiveAcross={kpiData.length === 5}>
      {kpiData.map(({ title, value, subText, active }) => (
        <KpiStat
          title={title}
          value={value}
          subText={subText}
          key={title}
          active={active}
        />
      ))}
    </StatContainer>
  );
};

export default InvoiceKpis;
