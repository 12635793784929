import React from 'react';
import styled from 'styled-components';

import { bg1, relayNewLogo } from '../../assets/images';
import { colors } from 'themes';
import breakpoints from 'styles/layout/breakpoints';
import spacing from 'styles/layout/spacing';

type Props = {
  children: JSX.Element;
  welcome?: boolean;
  isLoggedIn?: boolean;
};

type ContainerProps = {
  welcome: boolean;
  isLoggedIn: boolean;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  ${({ welcome, isLoggedIn }: ContainerProps) =>
    welcome
      ? `background-color:${isLoggedIn ? 'transparent' : colors.greys100}`
      : `background-image:url(${bg1});`}
`;

const Logo = styled.img`
  position: absolute;
  top: ${spacing.gutter.xl};
  width: 150px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    top: ${spacing.gutter.lg};
  }
`;

const AuthContainer = ({ children, welcome, isLoggedIn }: Props) => (
  <Container welcome={!!welcome} isLoggedIn={!!isLoggedIn}>
    {!isLoggedIn && <Logo src={relayNewLogo} />}
    {children}
  </Container>
);

export default AuthContainer;
