import Invoice from 'types/Invoice';
import PurchaserOffer from 'types/PurchaserOffer';
import SupplierOffer from 'types/SupplierOffer';
import {
  getServiceFeeForPurchaserOffer,
  getServiceFeeWithMinServiceFee,
} from 'utils';
import { MAX_FEE_PERCENTAGE } from 'config';
import moment from 'moment';

export type InvoiceMetaData = {
  paymentDate: string;
  crr: number;
  discount?: number;
  discountPercent: number;
  status: {
    label: string;
    highlighted?: boolean;
  };
  action: {
    label: string;
    do: () => void;
  };
};

const defaultValues: InvoiceMetaData = {
  paymentDate: '-',
  crr: 0,
  discountPercent: 0,
  status: {
    label: '-',
  },
  action: {
    label: '',
    do: () => {},
  },
};

const findInvoiceStatus = ({
  invoice,
  purchaserOffer,
  supplierOffer,
  setSelectedInvoice,
  showPaidInvoice,
  showSupplierOfferModal,
}: {
  invoice: Invoice;
  purchaserOffer?: PurchaserOffer;
  supplierOffer?: SupplierOffer;
  setSelectedInvoice: () => void;
  showPaidInvoice: () => void;
  showSupplierOfferModal: () => void;
}): InvoiceMetaData => {
  const {
    billStatus,
    includeInPurchaserOffer,
    discountedTotal,
    invoiceStatus,
    serviceFee,
    paidDate,
  } = invoice;

  if (invoiceStatus === 'PAID') {
    const discount = serviceFee || 0;
    const discountPercent = (discount / discountedTotal) * 100;
    return {
      ...defaultValues,
      discount,
      discountPercent,
      paymentDate: moment(paidDate).format('DD MMM YYYY'),
      status: {
        label: 'Paid',
      },
      action: {
        label: 'View',
        do: showPaidInvoice,
      },
    };
  } else {
    if (supplierOffer) {
      const { autobid } = supplierOffer;
      return {
        ...defaultValues,
        discount: autobid,
        discountPercent: (autobid / discountedTotal) * 100,
        status: {
          label: 'Payment Requested',
        },
        action: {
          label: 'Edit',
          do: showSupplierOfferModal,
        },
      };
    }

    if (
      purchaserOffer &&
      includeInPurchaserOffer &&
      billStatus === 'PAYMENT_OFFERED'
    ) {
      const serviceFeeWithoutMinServiceFee = getServiceFeeForPurchaserOffer({
        invoice,
        purchaserOffer: purchaserOffer,
      });
      let serviceFeePercentage =
        (serviceFeeWithoutMinServiceFee * 100) / discountedTotal;
      if (serviceFeePercentage <= MAX_FEE_PERCENTAGE) {
        const serviceFee = getServiceFeeWithMinServiceFee(
          serviceFeeWithoutMinServiceFee
        );
        return {
          ...defaultValues,
          discount: serviceFee,
          discountPercent: (serviceFee / discountedTotal) * 100,
          crr: purchaserOffer.crr,
          status: {
            label: 'Payment Available',
            highlighted: true,
          },
          action: {
            label: 'Get Paid',
            do: setSelectedInvoice,
          },
        };
      }
    }
    return {
      ...defaultValues,
      action: {
        label: 'Request Payment',
        do: showSupplierOfferModal,
      },
    };
  }
};

export default findInvoiceStatus;
