import React from 'react';
import { Typography } from 'antd';
import { PendingApprovalQueue } from './PendingApprovalQueue';
import { DefaultProcessingQueue } from './DefaultProcessingQueue';

const { Title } = Typography;

export const APAutomation: React.FC = () => {
  return (
    <>
      <Title level={5}>AP Automation</Title>
      <PendingApprovalQueue />
      <DefaultProcessingQueue />
    </>
  );
};
