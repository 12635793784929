import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { iconCheckCircle } from 'assets/images';
import spacing from 'styles/layout/spacing';
import colors from 'colors';
import breakpoints from 'styles/layout/breakpoints';
import { Warning } from 'assets/icons';
import { TitleSection } from 'components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${2 * spacing.gutterNumbers.md}px);
  max-width: ${breakpoints.mobile};
  margin: 0 ${spacing.gutter.md};
  padding: ${spacing.gutter.xl};
  padding-bottom: 64px;
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0px 10px 20px rgba(27, 20, 65, 0.1);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Icon = styled.img`
  width: 53px;
  height: 53px;
  align-self: center;
`;

const Prompt = styled.span<{ password?: boolean }>`
  text-align: center;
  margin-top: ${(props) => props.password && '10px'};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;

const AuthCard: React.FC<{
  message?: string;
  subMessage?: string;
  isLogin?: boolean;
  isMfa?: boolean;
  showCheckIcon?: boolean;
  showWarningIcon?: boolean;
}> = ({
  children,
  message,
  subMessage,
  isLogin,
  isMfa,
  showCheckIcon,
  showWarningIcon,
}) => {
  return (
    <Container data-testid="auth-card-container">
      {showCheckIcon && (
        <Icon data-testid="auth-card-icon" src={iconCheckCircle} />
      )}
      {showWarningIcon && <Icon src={Warning} />}
      {message && (
        <div data-testid="auth-card-message">
          <TitleSection title={message} subTitle={subMessage} />
        </div>
      )}
      {children}
      {isLogin && !isMfa && (
        <Prompt>
          Got an invite code?{' '}
          <StyledLink to="/signup" data-testid="auth-card-signup">
            Sign Up
          </StyledLink>
        </Prompt>
      )}
      {isLogin && !isMfa && (
        <Prompt password>
          <StyledLink to="/forgot-password" data-testid="auth-card-forgot">
            Forgot password?
          </StyledLink>
        </Prompt>
      )}
    </Container>
  );
};

export default AuthCard;
