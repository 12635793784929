import React, { useState } from 'react';
import styled from 'styled-components';
import Container from '../Container';
import Footer from '../Footer';
import PrimaryButton from '../PrimaryButton';
import { Typography, Divider } from 'antd';
import { UserName } from 'components/UserVerification';

import { colors } from 'themes';
import urls from 'urls';
import api from 'api';
import joinName from 'utils/joinName';

import User from 'types/User';
import { Address } from 'types/Address';

const { Text } = Typography;

const TitleContainer = styled.div`
  height: 48px;
  background: #f5fffb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 54px;
  margin-top: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #3b4048;
  margin-bottom: 15px;
  margin-right: 10px;
`;

type ValueProps = {
  width?: number;
};
const Value = styled.div`
  min-height: 48px;
  width: ${(props: ValueProps) => (props.width ? `${props.width}px` : '100%')};
  background: ${colors.greys100};
  border: 1px solid ${colors.greys300};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
`;
const DriverLicenceContainer = styled.div`
  border: 1px solid ${colors.greys300};
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
`;
const DriverLicenceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;
const LicenceLabel = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #3b4048;
  margin-right: 10px;
  width: 60px;
`;
const LicenceValue = styled.div`
  height: 48px;
  width: ${(props: ValueProps) => `${props.width}px`};
  background: ${colors.greys100};
  border: 1px solid ${colors.greys300};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

type Styles = {
  designation: React.CSSProperties;
  title: React.CSSProperties;
};

const styles: Styles = {
  designation: {
    fontSize: '14px',
    color: '#286EFF',
  },
  title: {
    fontWeight: 700, //bold
    fontSize: '16px',
    textAlign: 'center',
    color: '#35A566',
  },
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type Props = {
  countryCode: Address['countryCode'];
  doLogin?: () => void;
  history?: any;
  location?: any;
  isLoggedIn?: boolean;
  isLoggedInAsAdmin?: boolean;
  isLoggingIn?: boolean;
  token: string;
  user?: User;
};

const VerificationSuccessful: React.FC<Props> = (props) => {
  const {
    countryCode,
    doLogin,
    history,
    isLoggedIn,
    isLoggedInAsAdmin,
    isLoggingIn,
    location,
    token,
  } = props;
  const user: User =
    (location && location.state && location.state.user) || props.user;
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    driversLicenceNumber,
    driversLicenceVersion,
    note,
    roles,
    verificationStatus,
  } = user;
  const name = joinName({ firstName, middleName, lastName });
  const [year, month, day] = dateOfBirth
    ? dateOfBirth.split('-')
    : ['', '', ''];
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    api.identity
      .getBeneficialOwners({ token, countryCode })
      .then((owners) => {
        const firstUnverifiedOwner = owners.find(
          (o: User) => o.verificationStatus === 'NOT_VERIFIED'
        );
        const firstVerificationFailedOwner = owners.find(
          (o: User) => o.verificationStatus === 'VERIFICATION_FAILED'
        );
        const nextOwner = firstUnverifiedOwner || firstVerificationFailedOwner;
        if (nextOwner) {
          let next = firstUnverifiedOwner
            ? `${urls.verify.ownerDetails}`
            : `${urls.verify.ownerUpload}`;
          if (!isLoggedIn) {
            next = `${next}&token=${token}`;
          }
          history.replace(next, { user: nextOwner });
        } else {
          if (isLoggedIn) {
            history.replace(urls.verify.ownersList);
          } else {
            !!doLogin && doLogin();
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container>
        {!isLoggedInAsAdmin && (
          <TitleContainer>
            <Text style={styles.title}>Verification Successful</Text>
          </TitleContainer>
        )}
        <Label>Full legal name</Label>
        <UserName
          name={name}
          roles={roles}
          verificationStatus={verificationStatus}
        />
        <>
          <Label> Date of birth </Label>
          <Row>
            <Value width={100}>{day}</Value>
            <Value width={200}>{months[parseInt(month) - 1]}</Value>
            <Value width={100}>{year}</Value>
          </Row>
        </>
        <>
          <Label>Driver Licence</Label>
          <DriverLicenceContainer>
            <DriverLicenceRow>
              <LicenceLabel>Number</LicenceLabel>
              <LicenceValue width={300}>{driversLicenceNumber}</LicenceValue>
            </DriverLicenceRow>
            <DriverLicenceRow>
              <LicenceLabel>Version</LicenceLabel>
              <LicenceValue width={100}>{driversLicenceVersion}</LicenceValue>
            </DriverLicenceRow>
          </DriverLicenceContainer>
        </>
        {!!note && (
          <>
            <Label> Note by admin </Label>
            <Value>{note}</Value>
          </>
        )}
      </Container>
      {!isLoggedInAsAdmin && (
        <>
          <Divider />
          <Footer>
            <PrimaryButton
              label="Continue"
              onClick={onSubmit}
              loading={loading || isLoggingIn}
            />
          </Footer>
        </>
      )}
    </>
  );
};

export default VerificationSuccessful;
