import React from 'react';

import Ruler from 'components/Ruler';
import { RowContainer, RowLabel, RowValue } from 'components/row';

type InfoHeadProps = {
  amount: number;
};

const InfoHead: React.FC<InfoHeadProps> = ({ amount }) => {
  return (
    <div>
      <RowContainer highlighted>
        <RowLabel>Withdrawal Amount</RowLabel>
        <RowValue>{amount}</RowValue>
      </RowContainer>
      <Ruler />
    </div>
  );
};

export default InfoHead;
