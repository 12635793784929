import React, { useState } from 'react';
import InputItem from 'components/InputItem';
import api from 'api';
import Invoice from 'types/Invoice';
import ModalHeader from 'components/ModalHeader';
import { processError, validateEmail } from 'utils';
import { useDispatch } from 'react-redux';
import { Modal, Button, Input, notification } from 'antd';
import { updateInvoice, updateBill } from 'store/invoices';

type Props = {
  invoiceType: 'INVOICE' | 'BILL';
  invoice: Invoice;
  visibleEditModal: boolean;
  toggleEditModal: () => void;
};

const EditContactEmail: React.FC<Props> = ({
  invoiceType,
  invoice,
  visibleEditModal,
  toggleEditModal,
}) => {
  const originalEmail = invoice.contactEmail;
  const [loading, setLoading] = useState(false);
  const [contactEmail, setContactEmail] = useState(invoice.contactEmail || '');
  const [emailError, setEmailError] = useState('');

  const dispatch = useDispatch();

  const hideWindow = () => {
    toggleEditModal();
  };

  const hasEmailNotChanged = contactEmail === originalEmail;

  const confirm = () => {
    if (hasEmailNotChanged) {
      return;
    }
    if (validateEmail(contactEmail)) {
      const payload = [
        {
          id: invoice.id,
          path: '/contact_email' as const,
          value: contactEmail,
          operation: 'replace' as const,
        },
      ];
      setLoading(true);
      api.invoices
        .patch(payload)
        .then(() => {
          setLoading(false);
          const updated = { ...invoice, contactEmail };
          if (invoiceType === 'BILL') {
            dispatch(
              updateBill({ id: invoice.id, data: { invoice: updated } })
            );
          } else {
            dispatch(
              updateInvoice({ id: invoice.id, data: { invoice: updated } })
            );
          }
          const message = 'Contact email updated';
          notification.success({ message });
          hideWindow();
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
          setLoading(false);
        });
    } else {
      setEmailError('Enter valid email address');
    }
  };

  const actions = [
    <Button key="back" type="ghost" onClick={hideWindow}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={loading}
      onClick={confirm}
      disabled={hasEmailNotChanged || !contactEmail}
    >
      Confirm
    </Button>,
  ];

  return (
    <Modal
      visible={visibleEditModal}
      onCancel={hideWindow}
      footer={actions}
      title={<ModalHeader title="Edit Contact Email" onClose={hideWindow} />}
      closable={false}
    >
      <InputItem label="Email address" error={emailError}>
        <Input
          name="email"
          type="email"
          placeholder="Contact email address"
          value={contactEmail}
          onChange={(e) => {
            setContactEmail(e.target.value);
            setEmailError('');
          }}
        />
      </InputItem>
    </Modal>
  );
};

export default EditContactEmail;
