import moment, { Moment } from 'moment';

type Params = {
  periodLockDate: number | null;
  issueDateMonth: Moment;
};

/**
 * Check if the period lock value coming from API matches the given period.
 * @param {Object} params - The params to the function received as an object.
 * @param {string} params.periodLockDate - Period lock value from API.
 * @param {string} params.issueDateMonth - Month against which the comparison needs to be made.
 */
export const isPeriodLocked = ({ periodLockDate, issueDateMonth }: Params) => {
  if (!periodLockDate) {
    return false;
  } else {
    const lockedMonth = moment(periodLockDate);
    return (
      issueDateMonth.isSame(lockedMonth, 'month') ||
      issueDateMonth.isBefore(lockedMonth, 'month')
    );
  }
};
