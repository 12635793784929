export default {
  primary: '#286EFF',
  success: '#00CC83',
  warning: '#FF9300',
  error: '#FF0000',
  text: '#3B4048',
  greens: {
    dark: '#1DB100',
  },
  white: '#fff',
  greys100: '#F6F7F8',
  greys200: '#D7DADF',
  greys300: '#BDC3CB',
  greys400: '#A1A7AF',
  greys500: '#848B94',
  greys600: '#676C74',
  greys700: '#3B4048',
  greys800: '#272D36',
  greys900: '#151B25',
  orange: '#FF9300',
  blues: {
    lighter: '#EBF7FF',
    light: '#00A2FF',
    dark: '#286EFF',
  },
  yellows: {
    lighter: '#FFFECF',
    dark: '#7A6000',
  },
  divider: '#DDDDDD',
  warms: {
    red: '#FF4D4F',
  },
  purple: '#9666FF',
};
