import React from 'react';

import { Table, Input, Typography, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const InvoiceItems = props => {
  const { items, updateInvoiceItem, deleteInvoiceItem } = props;
  const columns = [
  {
    title: 'Item',
    render: (record) => {
      return(
        <Input
          placeholder="Item No."
          value={record.id}
          onChange={e =>
            updateInvoiceItem({ field: 'id', id: record.itemNumber, value: e.target.value })
          }
          size="small"
        />
    )
    }
  },
  {
    title: 'Description',
    render: (record) => {
      return(
        <Input
          placeholder="Description"
          value={record.description}
          onChange={e =>
            updateInvoiceItem({
              field: 'description',
              id: record.itemNumber,
              value: e.target.value
            })
          }
          size="small"
        />
    )
    }
  },
  {
    title: 'Units',
    render: (record) => {
      return(
        <Input
          placeholder="Units"
          onChange={e =>
            updateInvoiceItem({ field: 'units', id: record.itemNumber, value: e.target.value })
          }
          value={record.units}
          type="number"
          min={0}
          size="small"
        />
    )
    }
  },
  {
    title: 'Units type',
    render: (record) => {
      return(
        <Select
          defaultValue="HOURS"
          style={{ width: 120 }}
          placeholder="Select unit type"
          value={record.unitType}
          onChange={(value) => updateInvoiceItem({
            field: 'unitType',
            id: record.itemNumber,
            value: value
          })}
          size="small"
        >
          <Option value="HOURS">Hours</Option>
          <Option value="QUANTITY">Quantity</Option>
        </Select>
    )
    }
  },
  {
    title: 'Units price',
    render: (record) => {
      return(
        <Input
          placeholder="Unit price"
          value={record.unitPrice}
          onChange={e =>
            updateInvoiceItem({
              field: 'unitPrice',
              id: record.itemNumber,
              value: e.target.value
            })
          }
          type="number"
          min={0}
          size="small"
        />
    )
    }
  },
  {
    title: 'Amount',
    render: (record) => {
      const amount = record.unitPrice * record.units
      return(
        <Typography.Text>
          {amount? amount : 0}
        </Typography.Text>
    )
    }
  },
  {
    title: '',
    dataIndex: 'itemNumber',
    render: (record) => {
      return <CloseCircleOutlined onClick={() => deleteInvoiceItem(record)} />
    }
  }
];

  return (
    <Table
      columns={columns}
      dataSource={items}
    />
  );
};

export default InvoiceItems;
