const getDesignation = (roles: string[]): string => {
  let designation = '';
  const isOwner = roles.includes('ROLE_BENEFICIAL_OWNER');
  const isDirector = roles.includes('ROLE_DIRECTOR');
  if (isDirector && isOwner) {
    designation = 'Director, Owner';
  } else if (isDirector) {
    designation = 'Director';
  } else if (isOwner) {
    designation = 'Owner';
  }
  return designation;
};

export default getDesignation;
