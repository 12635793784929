import React from 'react';

const uploadStyles = {
  designation: {
    fontSize: 14,
    lineHeight: 19,
    color: '#286EFF',
  } as React.CSSProperties,
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 22,
    textAlign: 'center',
    color: '#3B4048',
  } as React.CSSProperties,
  error: {
    marginTop: 10
  } as React.CSSProperties
};

export default uploadStyles;
