const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const formatDateForDisplay = (timestamp: number): string => {
  const dateObject = new Date(timestamp);
  const date = dateObject.getDate();
  const month = months[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  if (!date || !month || !year) {
    return '-';
  }

  return `${date} ${month} ${year}`;
};

export default formatDateForDisplay;
