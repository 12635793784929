import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { myobLogo } from 'assets/images';
import { colors } from 'themes';
import BetaBadge from 'components/BetaBadge';

const ConnectionStatus = styled.p`
  width: 250px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
`;
const Message = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.greys900};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -10px;
`;
const Icon = styled.img`
  width: 150px;
  height: 150px;
  margin-top: -30px;
`;

const Myob = ({ company, loadingMyob, myobHandleClick, myobAccount }) => {
  return (
    <div>
      <Row>
        <div>
          <ConnectionStatus>
            {company &&
            (company.connectedToMyobEssentials ||
              company.connectedToMyobAccountRight)
              ? `Connected to MYOB ${
                  company.connectedToMyobEssentials
                    ? 'Essentials'
                    : 'Account Right'
                }`
              : `Not connected to MYOB ${
                  myobAccount === 'Essentials' ? 'Essentials' : 'Account Right'
                }`}
          </ConnectionStatus>
          <Message>
            {company &&
            (company.connectedToMyobEssentials ||
              company.connectedToMyobAccountRight)
              ? `Your MYOB ${
                  company.connectedToMyobEssentials
                    ? 'Essentials'
                    : 'Account Right'
                } account is currently connected to Relay`
              : `Connect your MYOB ${
                  myobAccount === 'Essentials' ? 'Essentials' : 'Account Right'
                } account to get started.`}
          </Message>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: myobAccount === 'Essentials' ? '100%' : '15%'
          }}
        >
          {myobAccount === 'Essentials' && <BetaBadge />}
          <Icon src={myobLogo} />
        </div>
      </Row>
      <Button
        label={
          company &&
          (company.connectedToMyobEssentials ||
            company.connectedToMyobAccountRight)
            ? 'Disconnect'
            : `Connect to MYOB ${
                myobAccount === 'Essentials' ? 'Essentials' : 'Account Right'
              }`
        }
        loading={loadingMyob}
        onClick={() => myobHandleClick(myobAccount)}
      />
    </div>
  );
};

export default Myob;
