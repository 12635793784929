import React, { FC } from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { formatCurrency } from 'utils';
import { Typography } from 'antd';

const { Text } = Typography;

const Container = styled.div<{ $background?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ $background }) =>
    $background ? `${$background}` : `${colors.blues.lighter}`};
  padding: 0.5rem;
  margin-top: 1.5rem;
`;
const Label = styled(Text)`
  line-height: 20px;
  color: ${colors.blues.dark};
  font-size: 14px;
`;
const Amount = styled(Text)`
  color: ${colors.blues.dark};
  font-weight: 700;
  font-size: 16px;
  line-height: 21.82px;
`;

type Props = {
  label: string;
  amount: number;
  background?: string;
};

const CreditInfo: FC<Props> = ({ label, amount, background }) => {
  return (
    <Container $background={background}>
      <Label>{label}</Label>
      <Amount>{formatCurrency(amount)}</Amount>
    </Container>
  );
};

export default CreditInfo;
