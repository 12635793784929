import React from 'react';
import styled from 'styled-components';

import { RowContainer, RowLabel } from 'components/row';

import { colors } from 'themes';
import formatter from 'utils/currencyFormatter';

const Text = styled(RowLabel)`
  color: ${colors.greys700};
`;

const InvoiceInfo = ({ count, amount }) => {
  return (
    <RowContainer highlighted>
      <Text>
        {`You have ${count? count : 0} approved invoice${
          count === 1 ? '' : 's'
        } awaiting payment of ${amount? formatter.format(amount) : '$0.00'}`}
      </Text>
    </RowContainer>
  );
};

export default InvoiceInfo;
