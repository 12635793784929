import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export const SetDefaultBankAccountButton = () => {
  return (
    <Link to="settings?tab=withdrawals">
      <Button type="primary">Set Default Bank Account</Button>
    </Link>
  );
};
