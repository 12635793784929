import React from 'react';
import styled from 'styled-components';
import spacing from 'styles/layout/spacing';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  > :not(:last-child) {
    margin-right: ${spacing.gutter.md};
  }
  .footer-text {
    font-size: 12px;
  }
`;

type Props = {
  children: any;
};

const Footer: React.FC<Props> = ({ children }) => (
  <Container>{children}</Container>
);

export default Footer;
