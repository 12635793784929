import axios from 'axios';
import { store } from 'config/store';
import { setGetVerifiedModalVisibility } from 'store/ui';
import processError from 'utils/processError';

const UNAUTHORIZED = 401; // 401 error indicates token is not correct or expired
axios.interceptors.response.use(
  (response) => {
    // if the api gives a 200 response
    return response;
  },
  (error) => {
    const loadCompanyFile =
      error.response && error.response.config.url.search('scope=CompanyFile');
    const { status, debugMessage } = processError(error);
    if (status === 403 && debugMessage === 'company not verified') {
      // API returned 403 since verification is not complete
      store.dispatch(setGetVerifiedModalVisibility(true));
    } else if (
      status === UNAUTHORIZED &&
      store.getState().auth.user &&
      loadCompanyFile === -1
    ) {
      window.location.replace(`${process.env.PUBLIC_URL}/logout`); // redirect to login page after logout
    }
    return Promise.reject(error);
  }
);
