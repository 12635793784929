import React from 'react';
import { Space } from 'antd';

import { Modal } from 'ds';
import { AccountInfo } from './AccountInfo';
import BankAccount from 'types/BankAccount';

type Props = {
  account?: BankAccount;
  onDelete: () => void;
  onHide: () => void;
  isDeleting: boolean;
  isVisible: boolean;
};

export const DeleteAccount: React.FC<Props> = ({
  account,
  onDelete,
  onHide,
  isDeleting,
  isVisible,
}) => {
  return (
    <Modal
      okText="Delete"
      onOk={onDelete}
      okButtonProps={{ loading: isDeleting }}
      onCancel={onHide}
      cancelButtonProps={{ disabled: isDeleting }}
      title="Delete Bank Account"
      subtitle="Withdrawals will be disabled until you add a new verified bank account."
      visible={isVisible}
    >
      <Space style={{ width: '100%' }} direction="vertical">
        {account ? <AccountInfo account={account} /> : null}
      </Space>
    </Modal>
  );
};
