import React from 'react';
import styled from 'styled-components';
import { Checkbox, Form, FormInstance, Input } from 'antd';
import { GlAccount } from 'types/AccountCode';
import { AccountCodeFormItem } from 'components';

const StyledForm = styled(Form)`
  .account-code-select {
    width: 350px;
  }
`;

type Props = {
  accountCodeToEdit: GlAccount | null;
  form: FormInstance<{
    code: string;
    isDefault: boolean;
  }>;
  handleSubmit: () => void;
  availableAccountCodes: GlAccount[];
};

export const AddAccountCodeForm: React.FC<Props> = ({
  accountCodeToEdit,
  form,
  handleSubmit,
  availableAccountCodes,
}) => {
  return (
    <StyledForm
      colon={false}
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 6, offset: 0 }}
      labelAlign="left"
      initialValues={{
        code: accountCodeToEdit?.accountCode,
        isDefault: accountCodeToEdit?.isDefault || false,
      }}
    >
      {accountCodeToEdit ? (
        <Form.Item label="GL Code">
          <Input
            disabled
            value={`${accountCodeToEdit.accountName} - ${accountCodeToEdit.accountCode}`}
          />
        </Form.Item>
      ) : (
        <AccountCodeFormItem
          name="code"
          availableAccountCodes={availableAccountCodes}
          label="GL Code"
        />
      )}

      <Form.Item
        name="isDefault"
        valuePropName="checked"
        label="Set as default"
      >
        <Checkbox />
      </Form.Item>
    </StyledForm>
  );
};
