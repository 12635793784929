import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { getTextWithCount } from 'utils';

const { Text } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ValidCountMessage: React.FC<{
  fromEntityName: string;
  toEntityName: string;
  validBillCount: number;
  validCreditNoteCount: number;
  validInvoiceCount: number;
}> = ({
  fromEntityName,
  toEntityName,
  validBillCount,
  validCreditNoteCount,
  validInvoiceCount,
}) => (
  <Wrapper>
    <Text>
      <Text strong>{fromEntityName}</Text> will be merged into{' '}
      <Text strong>{toEntityName}</Text>
    </Text>
    <Text>
      <Text strong>{getTextWithCount('bill', validBillCount)}</Text>,{' '}
      <Text strong>{getTextWithCount('invoice', validInvoiceCount)}</Text> and{' '}
      <Text strong>
        {getTextWithCount('credit note', validCreditNoteCount)}
      </Text>{' '}
      will be merged
    </Text>
  </Wrapper>
);
