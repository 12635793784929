import React, { useEffect, useState } from 'react';
import { Modal, notification, Space } from 'antd';

import api from 'api';
import ProgressBar from 'components/ProgressBar';
import { ModalHeader, CounterpartySearch } from 'components';
import { MergeConfirmation } from './components';
import { EntityType } from './components/types';
import { MergeStats } from 'types/CounterParty';
import { getMergeStats, processError } from 'utils';
import Text from 'antd/lib/typography/Text';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  fromEntityName: string;
  fromEntityId: string;
  entityId: string;
  onMergeComplete: () => void;
};

const MAX_TRIES_TO_GET_PROGRESS = 3;
const PROGRESS_INTERVAL = 1000; // delay in trying to get progress in milliseconds

const CounterpartyMerge: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  fromEntityName,
  fromEntityId,
  entityId,
  onMergeComplete,
}) => {
  const [toEntity, setToEntity] = useState<EntityType | null>(null);
  const [mergeStatistics, setMergeStatistics] = useState<MergeStats | null>(
    null
  );
  const [loadingMergeStatistics, setLoadingMergeStatistics] = useState(false);
  const [loading, setLoading] = useState(false);

  // to show merge progress
  const [progressView, setProgressView] = useState(false);
  const [mergeProgress, setMergeProgress] = useState('0.00');
  const [progressInterval, setProgressInterval] = useState<any>(null);

  useEffect(() => {
    if (!toEntity) {
      return;
    }
    setLoadingMergeStatistics(true);
    api.admin.counterParties
      .getMergeExecutionPlanDetails({
        fromEntityId,
        rootCounterPartyEntityId: entityId,
        toEntityId: toEntity.entityId,
      })
      .then((data) => {
        const stats = getMergeStats(data);
        setMergeStatistics(stats);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
        setToEntity(null);
      })
      .finally(() => setLoadingMergeStatistics(false));
  }, [entityId, fromEntityId, toEntity]);

  const handleConfirm = () => {
    setLoading(true);
    api.admin.counterParties
      .merge({
        rootCounterPartyEntityId: entityId,
        fromEntityId,
        toEntityId: toEntity?.entityId,
      })
      .then((mergeId: string) => {
        setTimeout(() => {
          setLoading(false);
          setProgressView(true);
        }, 2000);

        let triesToGetProgress = 1;
        // api to get import progress and data
        const progressInterval = setInterval(() => {
          api.admin.counterParties
            .getMergeProgress(mergeId)
            .then(async (response) => {
              const { progressPercentage, status } = response;
              console.log({ progressView, progressPercentage });

              if (parseInt(progressPercentage) === 100) {
                clearInterval(progressInterval);
                await setMergeProgress(progressPercentage);

                setTimeout(() => {
                  setMergeProgress('0.00');
                  setToEntity(null);
                  onMergeComplete();
                  setIsOpen(false);
                  setProgressView(false);
                  if (status === 'SUCCESS') {
                    notification.success({ message: 'Successfully merged' });
                  } else if (status === 'FAILED') {
                    notification.error({ message: 'Merge failed' });
                  }
                }, 2000);
              } else {
                setMergeProgress(progressPercentage);
              }
            })
            .catch((err) => {
              const { status, message } = processError(err);
              if (
                status === 404 &&
                triesToGetProgress < MAX_TRIES_TO_GET_PROGRESS
              ) {
                triesToGetProgress++;
              } else {
                notification.error({
                  message: status === 404 ? 'Error in merging' : message,
                });
                setProgressView(false);
                triesToGetProgress = 0;
                clearInterval(progressInterval);
                setProgressInterval(null);
              }
            });
        }, PROGRESS_INTERVAL);
        setProgressInterval(progressInterval);
      })
      .catch((err: any) => {
        setLoading(false);
        const { message } = processError(err);
        notification.error({ message });
        setIsOpen(false);
      });
  };

  const handleCancel = () => {
    if (!!toEntity) {
      setToEntity(null);
    } else {
      setIsOpen(false);
    }
    !!progressInterval && clearInterval(progressInterval);
  };

  const handleClose = () => {
    if (!progressView) {
      setIsOpen(false);
      !!progressInterval && clearInterval(progressInterval);
      setToEntity(null);
    }
  };

  return (
    <Modal
      title={
        <ModalHeader
          title={
            progressView
              ? 'Merge in progress '
              : !!toEntity
              ? 'Confirm Merge'
              : 'Select Company'
          }
          onClose={handleClose}
        />
      }
      closable={false}
      visible={isOpen}
      onCancel={handleCancel}
      onOk={handleConfirm}
      okText={progressView ? 'Done' : 'Confirm'}
      okButtonProps={{
        disabled: !toEntity || !mergeStatistics,
        loading,
        hidden: progressView,
      }}
      cancelText={toEntity ? 'Back' : 'Cancel'}
      cancelButtonProps={{ hidden: progressView }}
    >
      {!!progressView ? (
        <ProgressBar percent={mergeProgress} />
      ) : toEntity ? (
        <MergeConfirmation
          fromEntityName={fromEntityName}
          loadingMergeStatistics={loadingMergeStatistics}
          mergeStatistics={mergeStatistics}
          toEntity={toEntity}
        />
      ) : (
        <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
          <Text>
            Select a company to merge <Text strong>{fromEntityName}</Text> into
          </Text>
          <CounterpartySearch
            fromEntityId={fromEntityId}
            entityId={entityId}
            setToEntity={setToEntity}
            isAdmin={true}
          />
        </Space>
      )}
    </Modal>
  );
};

export default CounterpartyMerge;
