import React from 'react';
import styled from 'styled-components';
import { Switch, SwitchProps } from 'antd';

const StyledSwitch = styled(Switch)`
  border-radius: 2px;
  height: 30px;
  line-height: 30px;
  .ant-switch-handle {
    height: 26px;
    width: 26px;
    ::before {
      border-radius: 2px;
      height: inherit;
      width: inherit;
    }
  }
  .ant-switch-inner {
    font-size: 16px;
    margin: 0 7px 0 35px;
  }
  &.ant-switch-checked {
    .ant-switch-inner {
      margin: 0 35px 0 7px;
    }
    .ant-switch-handle {
      left: calc(100% - 26px - 2px);
    }
  }
`;

const Toggle: React.FC<SwitchProps> = (props) => <StyledSwitch {...props} />;

export default Toggle;
