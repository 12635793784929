import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: relative;
`;

const BillsTabContainer: React.FC<{
  className?: string;
  filterChips?: React.ReactNode;
  monthNavigation?: React.ReactNode;
}> = ({ children, className, filterChips, monthNavigation }) => {
  return (
    <Container className={className} data-testid="bills-tab-container">
      {!!monthNavigation && monthNavigation}
      {!!filterChips && filterChips}
      {children}
    </Container>
  );
};

export default BillsTabContainer;
