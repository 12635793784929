import { notification } from 'antd';
import api from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BankAccount from 'types/BankAccount';
import User from 'types/User';
import Company from 'types/Company';
import { processError } from 'utils';
import { showWithdrawModal } from 'store/ui';

export const useWithdrawals = () => {
  const dispatch = useDispatch();

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [isLoading, setLoading] = useState(false);

  const [isAccountModalVisible, setAccountModalVisible] = useState(false);
  const [isSetDefaultAccountModalVisible, setDefaultAccountModalVisible] =
    useState(false);
  const [isDeleteAccountModalVisible, setDeleteAccountModalVisible] =
    useState(false);

  const [isDeleting, setDeleting] = useState(false);
  const [isSettingDefaultAccount, setSettingDefaultAccount] = useState(false);

  const defaultWithdrawalAccount = bankAccounts.find(
    (account) => account.defaultAccount
  );

  const user: User & { company: { company: Company } } = useSelector(
    (state: any) => state.auth.user
  );

  const companyId = user.companyId;
  const company = user.company.company;

  const isCompanyConnectedToBusinessAccount =
    company.connectedToMyobAccountRight ||
    company.connectedToMyobEssentials ||
    company.connectedToXero;

  const getBankAccounts = useCallback(() => {
    if (companyId) {
      setLoading(true);
      api.company
        .getBankAccounts({ companyId })
        .then((accounts) => {
          const activeAccounts = accounts.filter(
            (account) => account.bankAccountStatus !== 'DELETED'
          );
          const filteredAccounts = activeAccounts.filter((account) => {
            if (isCompanyConnectedToBusinessAccount) {
              return (
                account.loadedBy === 'RELAY' ||
                account.loadedBy === 'XERO' ||
                account.loadedBy === 'MYOB_ACCOUNTRIGHT'
              );
            }
            return account.loadedBy === 'RELAY';
          });
          setBankAccounts(filteredAccounts);
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setLoading(false));
    }
  }, [companyId, isCompanyConnectedToBusinessAccount]);

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);

  const setDefaultAccount = (selectedAccountId: BankAccount['id']) => {
    const selectedAccount = bankAccounts.find(
      (account) => account.id === selectedAccountId
    );
    if (!selectedAccount) {
      return;
    }
    setSettingDefaultAccount(true);
    api.company
      .updateBankAccount({
        companyId,
        payload: { ...selectedAccount, defaultAccount: true },
      })
      .then(() => {
        hideDefaultAccountModal();
        getBankAccounts();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setSettingDefaultAccount(false));
  };

  const deleteAccount = () => {
    if (defaultWithdrawalAccount && isDeleteAccountModalVisible) {
      setDeleting(true);

      api.company
        .deleteBankAccount({
          companyId,
          bankAccountId: defaultWithdrawalAccount['id'],
        })
        .then(() => {
          notification.info({ message: 'Bank account deleted' });
          hideDeleteAccountModal();
          getBankAccounts();
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setDeleting(false));
    }
  };

  const addNewBankAccount = (newBankAccount: BankAccount) => {
    const updatedBankAccounts = [...bankAccounts, newBankAccount];
    setBankAccounts(updatedBankAccounts);
  };

  const onStatementUploadSuccess = () => {
    setAccountModalVisible(false);
    getBankAccounts();
    dispatch(showWithdrawModal());
  };

  const showAccountModal = () => setAccountModalVisible(true);
  const hideAccountModal = () => setAccountModalVisible(false);

  const showDefaultAccountModal = () => setDefaultAccountModalVisible(true);
  const hideDefaultAccountModal = () => setDefaultAccountModalVisible(false);

  const showDeleteAccountModal = () => setDeleteAccountModalVisible(true);
  const hideDeleteAccountModal = () => setDeleteAccountModalVisible(false);

  const reset = () => {};

  let primaryAction;

  let secondaryAction;

  const bankAccountsCount = bankAccounts.length;

  if (bankAccountsCount === 0) {
    // No bank accounts available to set as default withdrawal account
    primaryAction = {
      action: showAccountModal,
      label: 'Add Bank Account',
    };
  }

  if (bankAccounts.length && !defaultWithdrawalAccount) {
    // One or more bank accounts are available.
    // But none is set as default withdrawal account.
    primaryAction = {
      action: showDefaultAccountModal,
      label: 'Select Account',
    };
  }

  if (defaultWithdrawalAccount) {
    if (defaultWithdrawalAccount.bankAccountStatus === 'VERIFIED') {
      primaryAction = {
        action: showDeleteAccountModal,
        buttonType: 'default' as const,
        label: 'Delete',
      };
    }

    if (
      defaultWithdrawalAccount.bankAccountStatus === 'UNVERIFIED' ||
      defaultWithdrawalAccount.bankAccountStatus === 'VERIFICATION_FAILED'
    ) {
      primaryAction = {
        action: showAccountModal,
        label: 'Verify',
      };

      secondaryAction = {
        action: company.connectedToXero ? reset : showDeleteAccountModal,
        label: company.connectedToXero ? 'Reset' : 'Delete',
      };
    }
  }

  const addAccountModalProps = {
    defaultWithdrawalAccount,
    isVisible: isAccountModalVisible,
    onHide: hideAccountModal,
    onStatementUploadSuccess,
    addNewBankAccount,
  };

  const setDefaultAccountProps = {
    isVisible: isSetDefaultAccountModalVisible,
    isSetting: isSettingDefaultAccount,
    accounts: bankAccounts,
    onSubmit: setDefaultAccount,
    onHide: hideDefaultAccountModal,
  };

  return {
    isLoading,
    defaultWithdrawalAccount,

    primaryAction,
    secondaryAction,

    isDeleteAccountModalVisible,
    isDeleting,
    hideDeleteAccountModal,
    deleteAccount,

    addAccountModalProps,
    setDefaultAccountProps,
  };
};
