import React from 'react';
import styled from 'styled-components';
import { Space, Typography } from 'antd';
import { useSelector } from 'react-redux';

import colors from 'colors';
import accountNumberFormatter from 'utils/accountNumberFormatter';
import BankAccount from 'types/BankAccount';

const Container = styled(Space)`
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Text = styled(Typography.Text)`
  color: ${colors.greys900};
`;

const TransferDetails: React.FC = () => {
  const user = useSelector((state: any) => state.auth.user);
  const countryCode = user?.address?.countryCode;
  const bankAccounts: BankAccount[] = user.account;
  const relayBankAccount =
    bankAccounts && bankAccounts.length
      ? bankAccounts.find((account) => account.loadedBy === 'SYSTEM')
      : null;

  return (
    <Container direction="vertical" size="large">
      <Text>
        Please transfer funds to your{' '}
        {countryCode === 'AU' ? 'PayID' : 'Relay bank account'}:
        <br />
        {relayBankAccount
          ? accountNumberFormatter(relayBankAccount.bankAccountNumber)
          : ''}
      </Text>
      <Space direction="vertical" size={0}>
        {countryCode !== 'AU' ? (
          <Text>Your bank might take up to 2 hours to transfer the funds.</Text>
        ) : (
          ''
        )}
        <Text>We’ll complete your payment once the funds arrive.</Text>
      </Space>
    </Container>
  );
};

export default TransferDetails;
