import React, { useState } from 'react';
import api from 'api';
import styled from 'styled-components';
import { Button, Form, Modal, notification } from 'antd';
import { AddAccountCodeForm } from './AddAccountCodeForm';
import { GlAccount, AccountCodeApiResponse } from 'types/AccountCode';
import { processError } from 'utils';
import { ModalHeader } from 'components';

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  accountCodeToEdit: GlAccount | null;
  accountCodes: AccountCodeApiResponse[];
  entityId: string;
  isOpen: boolean;
  onClose: () => void;
  availableAccountCodes: GlAccount[];
  onSuccess: () => void;
};

export const AddAccountCodeModal: React.FC<Props> = ({
  accountCodeToEdit,
  accountCodes,
  entityId,
  isOpen,
  onClose,
  availableAccountCodes,
  onSuccess,
}) => {
  const [form] = Form.useForm<{
    code: string;
    isDefault: boolean;
    name: string;
  }>();

  const [isDeleting, setDeleting] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const addAccountCode = () => {
    form.validateFields().then(({ code, isDefault }) => {
      setSubmitting(true);
      const codeToUpdate = accountCodes.find(
        (accountCode) => accountCode.accountCode === code
      );
      if (!codeToUpdate) {
        notification.error({
          message: 'GL Code is required',
        });
        setSubmitting(false);
      } else {
        api.admin.xeroAccounts
          .put({
            entityId,
            payload: {
              ...codeToUpdate,
              isDefault,
              isVisibleInRelay: true,
            },
          })
          .then(() => {
            notification.success({ message: 'GL code added' });
            onSuccess();
          })
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          })
          .finally(() => setSubmitting(false));
      }
    });
  };

  const editAccountCode = () => {
    form.validateFields().then(({ isDefault }) => {
      const codeToUpdate = accountCodes.find(
        (accountCode) =>
          accountCode.accountCode === accountCodeToEdit?.accountCode
      );
      if (!codeToUpdate) {
        return;
      }

      if (codeToUpdate.isDefault === isDefault) {
        // The only parameter that we update is "isDefault"
        // If user has not changed the default status, we need not do anything
        notification.info({ message: 'Nothing to update' });
        onClose();
        return;
      }

      setSubmitting(true);
      api.admin.xeroAccounts
        .put({
          entityId,
          payload: {
            ...codeToUpdate,
            isDefault,
            isVisibleInRelay: true,
          },
        })
        .then(() => {
          const message = isDefault
            ? 'GL code set as default'
            : 'GL code set as non-default';
          notification.success({ message });
          onSuccess();
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    });
  };

  const handleSubmit = () => {
    if (accountCodeToEdit) {
      editAccountCode();
    } else {
      addAccountCode();
    }
  };

  const handleDelete = () => {
    if (!accountCodeToEdit) {
      return;
    }
    const codeToUpdate = accountCodes.find(
      (accountCode) =>
        accountCode.accountCode === accountCodeToEdit?.accountCode
    );
    if (!codeToUpdate) {
      return;
    }

    setDeleting(true);

    api.admin.xeroAccounts
      .put({
        entityId,
        payload: {
          ...codeToUpdate,
          isDefault: false,
          isVisibleInRelay: false,
        },
      })
      .then(() => {
        notification.success({ message: 'GL code deleted' });
        onSuccess();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setDeleting(false));
  };

  const modalTitle = accountCodeToEdit ? 'Edit GL Code' : 'Add GL Code';

  return (
    <StyledModal
      title={<ModalHeader title={modalTitle} onClose={onClose} />}
      visible={isOpen}
      closable={false}
      onOk={handleSubmit}
      onCancel={onClose}
      centered
      footer={[
        accountCodeToEdit && (
          <Button
            className="tertiary-button"
            disabled={isSubmitting}
            key="delete"
            loading={isDeleting}
            onClick={handleDelete}
            type="ghost"
          >
            Delete GL Code
          </Button>
        ),
        <ButtonContainer key="main">
          <Button
            disabled={isDeleting || isSubmitting}
            key="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isDeleting}
            key="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ButtonContainer>,
      ]}
    >
      <AddAccountCodeForm
        accountCodeToEdit={accountCodeToEdit}
        form={form}
        handleSubmit={handleSubmit}
        availableAccountCodes={availableAccountCodes}
      />
    </StyledModal>
  );
};
