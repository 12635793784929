import React from 'react';
import styled from 'styled-components';
import { Badge as AntBadge, BadgeProps } from 'antd';

const StyledBadge = styled(AntBadge)`
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
  .ant-badge-status-text {
    display: none;
  }
`;

const StatusDot: React.FC<BadgeProps> = (props) => <StyledBadge {...props} />;

export default StatusDot;
