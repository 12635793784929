import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';
import CsvImport from 'components/CsvImport';
import CsvDownload from 'components/CsvDownload';
import { useParams } from 'react-router';
import api from 'api';
import {
  ImportCounterpartyApiPayload,
  ImportCounterpartyCsvItem,
  InvalidCounterpartyCsvItemWithReason,
} from 'types/CounterParty';
import {
  AddPaymentTermApiPayload,
  DayOfWeek,
  daysOfWeek,
  paymentFrequencies,
} from 'types/PaymentTerm';
import { processError, removeSpaces } from 'utils';
import { ConfirmationScreen } from './ConfirmationScreen';
import { ModalHeader } from 'components';
import Text from 'antd/lib/typography/Text';
import { removeHyphens } from 'utils/removeHyphens/removeHyphens';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onImportComplete: () => void;
};

type ImportCounterparty = {
  company_name: string;
  external_account_id: string;
  external_bank_account: string;
  gst_number: string;
  gl_code: string;
  payment_terms_frequency: string;
  payment_terms_day_date: string;
};

const MAX_TRIES_TO_GET_PROGRESS = 3;
const PROGRESS_INTERVAL = 1000; // delay in trying to get progress in milliseconds

const ImportCounterpartiesModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onImportComplete,
}) => {
  const [isEditing, setIsEditing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progressView, setProgressView] = useState(false);
  const [importedItems, setImportedItems] = useState<object[]>([]);
  const [importProgress, setImportProgress] = useState('0.00');
  const [validItemCount, setValidCount] = useState(0);
  const [totalItemCount, setTotalCount] = useState(0);
  const [failedItems, setFailedItems] = useState<
    InvalidCounterpartyCsvItemWithReason[]
  >([]);
  const [progressInterval, setProgressInterval] = useState<any>(null);

  const entityId = useParams<{ id: string }>().id;

  const processFileData = (importData: ImportCounterparty[]) => {
    const counterpartiesToImport: ImportCounterpartyApiPayload[] = [];
    importData.forEach((item) => {
      const entityName = item.company_name.replace(/\s\s+/g, ' ');
      const externalBankAccountNumber = removeHyphens(
        removeSpaces(item.external_bank_account)
      );
      const externalAccountId = removeHyphens(
        removeSpaces(item.external_account_id)
      );
      let gstNumber = removeHyphens(removeSpaces(item.gst_number));
      const glCode = removeSpaces(item.gl_code);
      const paymentTermsFrequency = removeSpaces(
        item.payment_terms_frequency?.toUpperCase()
      );
      const paymentTermsDayDate =
        typeof item.payment_terms_day_date === 'string'
          ? removeSpaces(item.payment_terms_day_date.toUpperCase())
          : item.payment_terms_day_date;
      const counterpartyItem: ImportCounterpartyCsvItem = {
        entityName,
        externalAccountId,
        externalBankAccountNumber,
        gstNumber,
        glCode,
      };
      if (gstNumber && gstNumber.length === 8) {
        gstNumber = '0' + gstNumber;
      }
      if (paymentTermsFrequency || paymentTermsDayDate) {
        counterpartyItem.paymentTerm = {
          paymentTermsFrequency,
          paymentTermsDayDate,
        };
      }
      if (!entityName || !externalAccountId) {
        addFailedItem(
          counterpartyItem,
          'Company name and external account ID number are mandatory fields'
        );
        return;
      } else if (gstNumber && isNaN(Number(gstNumber))) {
        addFailedItem(
          counterpartyItem,
          'GST Number must only contain numbers e.g. 123-456-789 or 123456789'
        );
      } else if (gstNumber && gstNumber.length < 8) {
        addFailedItem(
          counterpartyItem,
          'Invalid GST Number length (min: 8, max: 9).'
        );
      } else if (!counterpartyItem.paymentTerm) {
        counterpartiesToImport.push({
          entityName,
          externalAccountId,
          externalBankAccountNumber,
          gstNumber,
          glCode,
        });
      } else if (
        (paymentTermsDayDate && !paymentTermsFrequency) ||
        (paymentTermsFrequency && !paymentTermsDayDate)
      ) {
        addFailedItem(
          counterpartyItem,
          'Invalid payment term, both payment term fields must be filled to be valid'
        );
      } else if (
        paymentTermsFrequency &&
        !paymentFrequencies.includes(paymentTermsFrequency as any)
      ) {
        addFailedItem(
          counterpartyItem,
          'Invalid payment term, frequency must be Monthly or Weekly'
        );
      } else if (
        paymentTermsFrequency === 'MONTHLY' &&
        (isNaN(Number(paymentTermsDayDate)) ||
          !(
            1 <= Number(paymentTermsDayDate) &&
            Number(paymentTermsDayDate) <= 31
          ))
      ) {
        addFailedItem(
          counterpartyItem,
          'Monthly payment term frequency must have date of 1-31'
        );
      } else if (
        paymentTermsFrequency === 'WEEKLY' &&
        !daysOfWeek.includes(paymentTermsDayDate as any)
      ) {
        addFailedItem(
          counterpartyItem,
          'Weekly payment term frequency must have day of week e.g. Monday'
        );
      } else {
        const paymentTerm: AddPaymentTermApiPayload = {
          frequency: paymentTermsFrequency === 'MONTHLY' ? 'MONTHLY' : 'WEEKLY',
          date:
            paymentTermsFrequency === 'MONTHLY'
              ? Number(paymentTermsDayDate)
              : null,
          dayOfWeek:
            paymentTermsFrequency === 'WEEKLY' &&
            daysOfWeek.includes(paymentTermsDayDate as DayOfWeek)
              ? (paymentTermsDayDate as DayOfWeek)
              : null,
        };
        counterpartiesToImport.push({
          entityName,
          externalAccountId,
          externalBankAccountNumber,
          gstNumber,
          glCode,
          paymentTerm,
        });
      }
    });
    return counterpartiesToImport;
  };

  const handleFileLoaded = (data: ImportCounterparty[]) => {
    const processedData = processFileData(data);
    setImportedItems(processedData);
  };

  const addFailedItem = (item: ImportCounterpartyCsvItem, reason: string) => {
    setFailedItems((state) => [
      ...state,
      {
        failedObject: item,
        failureReason: reason,
        objectType: 'ImportCounterpartyCsvItem',
      },
    ]);
  };

  const onSubmit = () => {
    setIsEditing(false);
    setIsSubmitting(true);
    setProgressView(true);
    api.admin.counterParties
      .importCounterParties({
        entityId,
        counterPartiesForImport:
          importedItems as ImportCounterpartyApiPayload[],
      })
      .then((res) => {
        let triesToGetProgress = 1;
        const progressInterval = setInterval(() => {
          api.admin.counterParties
            .getImportCounterpartiesDetails({
              entityId,
              importId: res,
            })
            .then(async (response) => {
              const { success, progress, failedObjects } = response;
              if (parseInt(progress) === 100) {
                clearInterval(progressInterval);
                await setImportProgress(progress);
                setTimeout(() => {
                  setProgressView(false);
                  if (failedObjects) {
                    const formatFailedObjects = [...failedObjects].map(
                      ({ failedObject, failureReason, objectType }) =>
                        ({
                          failedObject: {
                            entityName: failedObject.entityName,
                            externalAccountId: failedObject.externalAccountId,
                            externalBankAccountNumber:
                              failedObject.externalBankAccountNumber,
                            gstNumber: failedObject.gstNumber,
                            glCode: failedObject.glCode,
                            paymentTerm: {
                              paymentTermsFrequency:
                                failedObject.paymentTerm?.frequency,
                              paymentTermsDayDate: failedObject.paymentTerm
                                ?.date
                                ? failedObject.paymentTerm?.date
                                : failedObject.paymentTerm?.dayOfWeek,
                            },
                          },
                          failureReason: failureReason,
                          objectType: objectType,
                        } as InvalidCounterpartyCsvItemWithReason)
                    );
                    setFailedItems([...failedItems, ...formatFailedObjects]);
                    setTotalCount(
                      failedItems.length + formatFailedObjects.length + success
                    );
                  } else {
                    setTotalCount(failedItems.length + success);
                  }
                  setImportProgress('0.00');
                  setValidCount(success);
                  setImportedItems([]);
                  if (success > 0) {
                    onImportComplete();
                  }
                }, PROGRESS_INTERVAL);
              } else {
                setImportProgress(progress);
              }
            })
            .catch((err) => {
              const { status, message } = processError(err);
              if (
                status === 404 &&
                triesToGetProgress < MAX_TRIES_TO_GET_PROGRESS
              ) {
                triesToGetProgress++;
              } else {
                notification.error({
                  message:
                    status === 404 ? 'Error in importing from file' : message,
                });
                setProgressView(false);
                triesToGetProgress = 0;
                clearInterval(progressInterval);
                setProgressInterval(null);
              }
            });
        }, PROGRESS_INTERVAL);
        setProgressInterval(progressInterval);
      })
      .catch((err) => {
        setTotalCount(failedItems.length);
        setProgressView(false);
        const { message } = processError(err);
        notification.error({ message });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsEditing(true);
    clearInterval(progressInterval);
    onClose();
  };

  return (
    <Modal
      data-testid="import-counterparties-modal"
      visible={isOpen}
      closable={false}
      onCancel={() => !progressView && handleClose()}
      title={
        <ModalHeader
          onClose={handleClose}
          title="Import counterparties"
          hideClose={progressView}
        />
      }
      footer={
        !progressView && [
          isEditing && (
            <CsvDownload
              className="tertiary-button"
              buttonType="ghost"
              key="download"
              disabled={isSubmitting}
              filename="import_counterparties"
            />
          ),
          isEditing && (
            <Button key="cancel" onClick={handleClose}>
              Cancel
            </Button>
          ),
          <Button
            data-testid="submit-button"
            key="submit"
            type="primary"
            onClick={!isEditing ? handleClose : onSubmit}
            loading={isSubmitting}
          >
            {!isEditing ? 'Finish' : 'Submit'}
          </Button>,
        ]
      }
    >
      {isEditing ? (
        <>
          <Text>
            Payment terms formatting:
            <br />
            - Monthly frequency must have day/date number of 1-31
            <br />- Weekly frequency must have day/date name e.g. Monday
          </Text>
          <CsvImport error="" fileLoadedHandler={handleFileLoaded} />
        </>
      ) : (
        <ConfirmationScreen
          totalItemCount={totalItemCount}
          validItemCount={validItemCount}
          progressView={progressView}
          importProgress={importProgress}
          failedItems={failedItems}
        />
      )}
    </Modal>
  );
};

export default ImportCounterpartiesModal;
