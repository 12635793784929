import React, { useState } from 'react';
import { Modal, notification, Typography } from 'antd';
import { ModalHeader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getBankAccounts } from 'store/auth';
import { addBankAccount } from 'store/bankAccounts';
import api from 'api';
import { processError } from 'utils';

const { Text } = Typography;

const ConfirmAccountActivation: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user?.user);
  const companyId = user?.companyId;
  const countryCode = user?.address?.countryCode;

  const [isActivating, setActivating] = useState(false);

  const handleConfirm = () => {
    if (!companyId) {
      return;
    }
    setActivating(true);
    api.bankAccount
      .activateSystemBankAccount({ entityId: companyId })
      .then((bankAccount) => {
        dispatch(getBankAccounts(companyId));
        dispatch(addBankAccount(bankAccount));
        notification.success({
          message:
            countryCode === 'AU'
              ? 'Your Relay PayID is now active!'
              : 'Your Relay working capital account is now active!',
        });
        onClose();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setActivating(false));
  };

  return (
    <Modal
      closable={false}
      data-testid="account-activation-confirmation-modal"
      visible={true}
      title={
        <ModalHeader
          onClose={onClose}
          title={
            countryCode === 'AU'
              ? 'Confirm PayID Activation'
              : 'Confirm Account Activation'
          }
        />
      }
      onOk={handleConfirm}
      okText="Confirm"
      okButtonProps={{ loading: isActivating }}
      onCancel={onClose}
      cancelText="Cancel"
      cancelButtonProps={{ disabled: isActivating, type: 'ghost' }}
    >
      <Text>
        Please confirm that you wish to activate your{' '}
        {countryCode === 'AU'
          ? 'Relay PayID.'
          : 'Relay Working Capital Account.'}
      </Text>
    </Modal>
  );
};

export default ConfirmAccountActivation;
