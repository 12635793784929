import React, { FC } from 'react';
import colors from 'colors';
import { formatCurrency } from 'utils';
import styled from 'styled-components';
import { Table, Typography } from 'antd';
import { CreditNoteAllocation } from 'types/Invoice';

const { Text } = Typography;

const DataTable = styled(Table)`
  margin-top: 10px;

  .ant-table-tbody > tr > td {
    border-bottom: 8px solid #fff;
  }
  .ant-table-thead > tr > th {
    border-bottom: 8px solid #fff;
  }
  .ant-table-thead > tr > th {
    font-size: 10px;
    font-weight: bold !important;
    background-color: ${colors.greys100};
    border-bottom: none;
    color: ${colors.greys700};
    line-height: 14px;
    padding: 9px 40px;
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    color: ${colors.greys900};
    border-bottom: none;
    padding: 9px 40px;
    align-items: center;
    :last-child {
      font-size: 16px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }
`;
const CreditNumber = styled(Text)`
  color: ${colors.blues.dark};
  border-bottom: 1px dashed ${colors.blues.dark};
  cursor: pointer;
`;

type Allocation = CreditNoteAllocation & { creditNumber: string };

type Props = {
  creditNotes: Array<Allocation>;
  onClick: (value: string) => void;
};

const CreditNoteList: FC<Props> = ({ creditNotes, onClick }) => {
  const columns = [
    {
      title: 'CREDIT NOTE #',
      key: 'creditNumber',
      render: (record: Allocation) => (
        <CreditNumber onClick={() => onClick(record?.creditNoteId)}>
          {record?.creditNumber}
        </CreditNumber>
      ),
    },
    {
      title: 'CREDIT APPLIED',
      key: 'allocatedAmount',
      dataIndex: 'allocatedAmount',
      align: 'right' as 'right',
      render: (value: number) => {
        return `${formatCurrency(value)}`;
      },
    },
  ];

  return (
    <DataTable
      dataSource={creditNotes}
      columns={columns}
      pagination={false}
      scroll={{ y: 284 }}
    />
  );
};

export default CreditNoteList;
