import React from 'react';
import moment, { Moment } from 'moment';
import { useMediaQuery } from 'hooks';
import breakpoints from 'styles/layout/breakpoints';
import SimpleTable from 'ds/atoms/SimpleTable';
import Text from 'antd/lib/typography/Text';

type Record = {
  companyName: string;
  invoiceNumber: string;
  dueDate: Moment;
  issueDate?: Moment;
  invoiceType: string;
};

const InvoiceDetails: React.FC<Record> = ({
  companyName,
  invoiceNumber,
  dueDate,
  issueDate,
  invoiceType,
}) => {
  const dataSource = [
    {
      id: invoiceNumber,
      companyName,
      invoiceNumber,
      dueDate,
      issueDate,
      invoiceType,
    },
  ];
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
  const columns = [
    {
      title: invoiceType === 'BILL' ? 'BILL #' : 'INVOICE #',
      key: invoiceType === 'BILL' ? 'billNumber' : 'invoiceNumber',
      render: (record: Record) => (
        <Text ellipsis={{ tooltip: true }}>{record.invoiceNumber}</Text>
      ),
      width: '25%',
    },
    {
      title: invoiceType === 'BILL' ? 'SUPPLIER' : 'CUSTOMER',
      key: invoiceType === 'BILL' ? 'SUPPLIER' : 'CUSTOMER',
      render: (record: Record) => (
        <Text ellipsis={{ tooltip: true }}>{record.companyName}</Text>
      ),
      ellipsis: true,
    },
    ...(issueDate
      ? [
          {
            title: 'ISSUE DATE',
            key: 'issueDate',
            align: 'right' as 'right',
            render: (record: Record) => {
              return isMobile
                ? moment(record.issueDate).format('l').valueOf()
                : moment(record.issueDate).format('DD MMM YYYY').valueOf();
            },
          },
        ]
      : []),
    {
      title: 'DUE DATE',
      key: 'dueDate',
      align: 'right' as 'right',
      render: (record: Record) => {
        return isMobile
          ? moment(record.dueDate).format('l').valueOf()
          : moment(record.dueDate).format('DD MMM YYYY').valueOf();
      },
    },
  ];
  return (
    <SimpleTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowKey="id"
    />
  );
};

export default InvoiceDetails;
