import axios from 'axios';

const getBeneficialOwners = ({ token, countryCode }) => {
  return new Promise(function (resolve, reject) {
    const params = {
      countryCode: countryCode === 'AU' ? countryCode : undefined,
    };
    axios
      .get(`/identity/owners`, {
        params,
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const verifyIdentity = ({ token, userId, countryCode }) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        '/identity/verify',
        {},
        {
          params: {
            userId,
            countryCode: countryCode === 'AU' ? countryCode : undefined,
          },
          headers: {
            Authorization: token
              ? 'Bearer ' + token
              : axios.defaults.headers.common['Authorization'],
          },
        }
      )
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const upload = ({ formData, token, userId }) => {
  return new Promise(function (resolve, reject) {
    const url = `/identity/verify/${userId}/upload`;
    axios
      .post(url, formData, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getVerificationDocLink = (userId) => {
  return new Promise(function (resolve, reject) {
    const url = `/identity/verify/${userId}/document`;
    axios
      .get(url)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export default {
  getBeneficialOwners,
  verifyIdentity,
  upload,
  getVerificationDocLink,
};
