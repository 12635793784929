import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { useCanvas } from './useCanvas';

type Coords = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

export type AreaType = {
  _id: number | string;
  shape: string;
  coords: string[] | number[];
  preFillColor: string;
  strokeColor: string;
  lineWidth: number;
  name: string;
};
type Props = Coords & {
  borderColor?: string;
  borderStyle?: string;
  borderWidth?: number;
  className?: string;
  zIndex?: number;
};

const PositionAbsolute = css`
  position: absolute;
  top: 0;
  left: 0;
`;
type ContainerProps = {
  width?: number;
  originalWidth?: number;
  height?: number;
  originalHeight?: number;
};

const Canvas = styled.canvas`
  ${PositionAbsolute}
  width: ${(props: ContainerProps) => props.width && props.width + 'px'};
  height: ${(props: ContainerProps) => props.height && props.height + 'px'};
  pointer-events: 'none';
  z-index: 2;
  background-color: 'black';
  pointer-events: none;
  overflow: auto;
`;

const ConnectingLine: React.FC<Props> = () => {
  const [canvasHeight, setCanvasHeight] = useState<number>(500);
  const [setCoordinates, canvasRef] = useCanvas();

  const coordinates: Coords = useSelector(
    (state: any) => state.ripeDraw.coordinates
  );
  useEffect(() => {
    coordinates && setCoordinates(coordinates);
    const canvasElement = document.getElementsByClassName(
      'invoice-highlight-container'
    )[0];
    let canvasContainerHeight = document.body.scrollHeight;

    if (canvasElement && canvasContainerHeight < canvasElement.clientHeight) {
      canvasContainerHeight = canvasElement.clientHeight;
    }
    setCanvasHeight(canvasContainerHeight);
  }, [coordinates, setCanvasHeight, setCoordinates]);
  return (
    <Canvas
      ref={canvasRef}
      width={document.body.scrollWidth}
      height={canvasHeight}
      id="connecting-line-layer-id"
    />
  );
};

export default ConnectingLine;
