import React from 'react';
import colors from 'colors';
import styled from 'styled-components/macro';
import { Col, Row, Typography, Divider } from 'antd';
import { AmountStrip } from 'components';

const { Text } = Typography;

const Header = styled(Text)`
  font-size: 12px;
  color: ${colors.greys600};
`;

const Details = styled(Text)`
  font-size: 14px;
  color: ${colors.greys900};
`;

const StyledDivider = styled(Divider)`
  margin-top: 4px;
  margin-bottom: 10px;
`;

type Props = {
  amountDue: string;
  companyName: string;
  dueDate: string;
  invoiceNumber: string;
  invoiceType: 'BILL' | 'INVOICE';
};

export const InvoiceInfo: React.FC<Props> = ({
  amountDue,
  companyName,
  dueDate,
  invoiceNumber,
  invoiceType,
}) => {
  return (
    <>
      <Row>
        <Col flex={1}>
          <Header>INVOICE #</Header>
          <StyledDivider />
          <Details>{invoiceNumber}</Details>
        </Col>
        <Col flex={2}>
          <Header>{invoiceType === 'BILL' ? 'SUPPLIER' : 'CUSTOMER'}</Header>
          <StyledDivider />
          <Details>{companyName}</Details>
        </Col>
        <Col
          flex={1}
          css={`
            text-align: right;
          `}
        >
          <Header>DUE DATE</Header>
          <StyledDivider />
          <Details>{dueDate}</Details>
        </Col>
      </Row>

      <Divider
        css={`
          margin-top: 14px;
          margin-bottom: 24px;
        `}
      />

      <AmountStrip
        label="Amount Due (inc. GST)"
        value={amountDue}
        css={`
          position: relative;
          left: -2%;
          width: 104%;
        `}
      />

      <Divider
        css={`
          margin-top: 24px;
          margin-bottom: 12px;
        `}
      />
    </>
  );
};
