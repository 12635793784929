import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';

const StyledButton = styled(Button)<{ $empty: boolean }>`
  font-size: 12px;
  padding: 0px 20px;
  height: 24px;
  background-color: ${({ $empty }) => $empty && 'transparent'}!important;
  cursor: ${({ $empty }) => ($empty ? 'initial' : 'pointer')}!important;
`;

const TableButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <StyledButton
    {...rest}
    type={rest.type ? rest.type : 'text'}
    $empty={children === '-'}
  >
    {children}
  </StyledButton>
);

export default TableButton;
