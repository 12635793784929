import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'colors';
import Head from './Head';

const Container = styled.div`
  background: ${colors.greys100};
  border-radius: 2px;
  box-shadow: rgb(194, 189, 194) 1px 15px 50px 5px;
  min-width: 400px;
  width: min-content;
`;

type Props = {
  handleDone: () => void;
  handleReset: () => void;
};

const FilterDialogContainer: React.FC<Props> = ({
  children,
  handleDone,
  handleReset,
}) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        // Trigger filter action if user presses Enter key when the filter dialog is open
        handleDone();
      }
    },
    [handleDone]
  );

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Container>
      <Head handleDone={handleDone} handleReset={handleReset} />
      {children}
    </Container>
  );
};

export default FilterDialogContainer;
