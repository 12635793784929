import React from 'react';
import styled from 'styled-components';
import { Space, Typography } from 'antd';

import { formatCurrency } from 'utils';
import colors from 'colors';

const { Text } = Typography;

const Message = styled(Text)`
  font-size: 14px;
  color: ${colors.greys700};
`;

type Props = {
  amount: number;
  isAdmin?: boolean;
  isBankAccountVerified: boolean;
};

export const WithdrawalPlaced: React.FC<Props> = ({
  amount,
  isAdmin,
  isBankAccountVerified,
}) => {
  const messageFragment = isBankAccountVerified
    ? `is on its way to ${isAdmin ? 'the' : 'your'} nominated bank account.`
    : `will be sent to ${
        isAdmin ? 'the' : 'your'
      } nominated bank account as soon as it has been verified.`;
  const formattedAmount = formatCurrency(amount);
  const message = `${formattedAmount} ${messageFragment}`;

  return (
    <Space direction="vertical">
      <Message>{message}</Message>

      {isBankAccountVerified && (
        <Message>
          {`${
            isAdmin ? 'The' : 'Your'
          } bank might take up to 2 hours to transfer the funds.`}
        </Message>
      )}
    </Space>
  );
};
