import React from 'react';
import styled from 'styled-components';

import CurrencyAmount from 'components/CurrencyAmount';

import { colors } from 'themes';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
  border-bottom: 1px solid #DDDDDD;
`;

const Text = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${colors.greys600}
`;

const FundsInfo = ({ balance }) => {
  return (
    <div style = {{ paddingRight: 10, paddingLeft: 10 }}>
      <Container>
        <Text>FUNDS AVAILABLE</Text>
        <Text><CurrencyAmount amount = { balance }/></Text>
      </Container>
    </div>
  )
}

export default FundsInfo;
