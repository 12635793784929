import React from 'react';
import CounterParty from 'types/CounterParty';
import colors from 'colors';
import { Table, TableProps } from 'antd';
import { StatusDot } from 'ds';
import styled from 'styled-components';
import spacing from 'styles/layout/spacing';
import { TableCellWithStatusDot } from 'components';

const StyledTable = styled(Table)`
  overflow: hidden;
  .ant-table-thead .ant-table-cell {
    line-height: 1.5715;
    height: 24px;
    padding-top: ${spacing.gutter.sm};
    padding-bottom: ${spacing.gutter.sm};
    border-radius: 0 !important;
  }
  .selected-row {
    background: ${colors.greys100};
    color: ${colors.primary};
  }
  .ant-table-cell {
    &:not(.ant-table-selection-column) {
      cursor: pointer;
    }
  }
`;

export const DirectoryTable: React.FC<
  {
    counterParties: CounterParty[];
    handleChange: (page: number, size: number) => void;
    loading: boolean;
    pageNumber: number;
    selectCounterParty: (id: string) => void;
    activeCounterPartyId?: string;
    pageSize: number;
    totalElements: number;
  } & TableProps<any>
> = ({
  counterParties,
  handleChange,
  pageNumber,
  selectCounterParty,
  activeCounterPartyId,
  pageSize,
  totalElements,
  loading,
  rowSelection,
}) => {
  const columns = [
    {
      title: 'Counterparty',
      render: (record: CounterParty) => (
        <TableCellWithStatusDot>
          <StatusDot
            color={record.counterParty.status === 'ACTIVE' ? 'blue' : '#d9d9d9'}
          />
          {record.counterParty.companyName}
        </TableCellWithStatusDot>
      ),
    },
  ];

  return (
    <StyledTable
      columns={columns}
      dataSource={counterParties}
      rowSelection={rowSelection}
      loading={loading}
      pagination={{
        current: pageNumber,
        pageSize,
        total: totalElements,
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        // onShowSizeChange: onSizeChange,
        onChange: handleChange,
      }}
      rowClassName={(record: any) =>
        record.id === activeCounterPartyId ? 'selected-row' : ''
      }
      onRow={(record: any) => ({
        onClick: () => {
          selectCounterParty(record.id);
        },
      })}
      rowKey="id"
    />
  );
};
