import React from 'react';
import colors from 'colors';
import styled from 'styled-components/macro';
import { Typography } from 'antd';

const { Text } = Typography;

const Container = styled.div<{
  greyScale?: boolean;
  compact?: boolean;
  background?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ compact }) => (compact ? '.5rem' : '10px')};
  background: ${({ greyScale, background }) =>
    background ? `${background}` : !greyScale && colors.greys100};
`;

const AmountStrip: React.FC<{
  className?: string;
  greyScale?: boolean;
  label: string;
  value: string;
  compact?: boolean;
  background?: string;
}> = ({ className, greyScale, label, value, compact, background }) => (
  <Container
    className={className}
    greyScale={greyScale}
    compact={compact}
    background={background}
  >
    <Text
      className="amount-strip-label"
      css={`
        color: ${greyScale ? colors.greys600 : colors.primary};
        font-size: 14px;
      `}
    >
      {label}
    </Text>
    <Text
      className="amount-strip-value"
      css={`
        color: ${greyScale ? colors.greys900 : colors.primary};
        font-weight: bold;
      `}
    >
      {value}
    </Text>
  </Container>
);

export default AmountStrip;
