import { Button } from 'antd';
import React from 'react';

type Props = {
  editing: boolean;
  loading: boolean;
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const Footer: React.FC<Props> = (props) => {
  const { editing, loading, primaryAction, secondaryAction } = props;
  return (
    <>
      {editing && (
        <Button disabled={loading} onClick={secondaryAction}>
          Reset
        </Button>
      )}
      <Button loading={loading} onClick={primaryAction} type="primary">
        {editing ? 'Confirm' : 'Done'}
      </Button>
    </>
  );
};
