import React, { useCallback, useEffect, useState } from 'react';
import api from 'api';
import { processError } from 'utils';
import { Modal, notification, Progress } from 'antd';
import { ModalHeader } from 'components';

const MAX_RETRIES = 3;
const PROGRESS_INTERVAL = 1000;

const DeleteProgress: React.FC<{
  onHide: ({ hasSuccess }: { hasSuccess: boolean }) => void;
  reviewId: string;
}> = ({ onHide, reviewId }) => {
  const [progressPercent, setProgressPercent] = useState<number>(0);

  const finalise = useCallback(
    ({
      total,
      success,
      failed,
    }: {
      total: number;
      success: number;
      failed: number;
    }) => {
      const noun = total === 1 ? 'document' : 'documents';
      if (failed && failed > 0) {
        const message = `Could not delete ${failed} out of ${total} selected ${noun}`;
        notification.error({ message });
      }
      if (success && success > 0) {
        const message = `Deleted ${success} out of ${total} selected ${noun}`;
        notification.info({ message });
      }
      onHide({ hasSuccess: success && success > 0 ? true : false });
    },
    [onHide]
  );

  useEffect(() => {
    let retryCount = 0;
    const interval = setInterval(() => {
      api.ripe
        .getBatchReviewStatus(reviewId)
        .then((response) => {
          const { progress } = response;
          const progressInteger = parseInt(progress);
          if (progressInteger === 100) {
            clearInterval(interval);
            const { total, success, failed } = response;
            finalise({ total, success, failed });
          } else {
            setProgressPercent(progressInteger);
          }
        })
        .catch((error) => {
          const { message, status } = processError(error);
          if (status === 404 && retryCount < MAX_RETRIES) {
            retryCount++;
          } else {
            clearInterval(interval);
            const errorMessage =
              status === 404 ? 'Could not delete documents' : message;
            notification.error({ message: errorMessage });
          }
        });
    }, PROGRESS_INTERVAL);
  }, [finalise, reviewId]);

  return (
    <Modal
      visible={true}
      closable={false}
      footer={null}
      title={<ModalHeader title="Delete Progress" />}
    >
      <Progress
        percent={progressPercent}
        status="active"
        showInfo={false}
        strokeLinecap="square"
      />
    </Modal>
  );
};

export default DeleteProgress;
