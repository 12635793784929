import React from 'react';
import styled from 'styled-components';
import { GlobalOutlined } from '@ant-design/icons';

import Company from 'types/Company';

const CompanyWrapper = styled.div`
  background-color: #213755;
  background-size: cover;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
`;

const CompanyName = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const CompanyDataRow = styled.div`
  margin-top: 10px;
`;

const CompanyData = styled.span`
  font-size: 18px;
  margin-right: 30px;
`;

const CompanyDataLabel = styled.span`
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
`;

const WebIcon = styled(GlobalOutlined)`
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
`;

type Props = {
  companyName: Company['companyName'];
  industryCodeDescription: Company['industryCodeDescription'];
  registrationNumber: Company['registrationNumber'];
  website: Company['website'];
};

export const CompanyInfo: React.FC<Props> = ({
  companyName,
  industryCodeDescription,
  registrationNumber,
  website,
}) => {
  return (
    <CompanyWrapper>
      <CompanyName>{companyName}</CompanyName>
      <CompanyDataRow>
        {!!registrationNumber && (
          <CompanyData>
            <CompanyDataLabel>Registration #</CompanyDataLabel>
            {registrationNumber}
          </CompanyData>
        )}
        {!!industryCodeDescription && (
          <CompanyData>
            <CompanyDataLabel>BIC Code</CompanyDataLabel>
            {industryCodeDescription}
          </CompanyData>
        )}
        {!!website && (
          <CompanyData>
            <WebIcon />
            <a
              href={
                website.includes('http://') || website.includes('https://')
                  ? website
                  : `http://${website}`
              }
              style={{ color: 'rgba(255, 255, 255, 0.9)' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {website}
            </a>
          </CompanyData>
        )}
      </CompanyDataRow>
    </CompanyWrapper>
  );
};
