import axios from 'axios';
import RipeSummary from 'types/RipeSummary';

const getRipeSummary = ({
  fromDate,
  toDate,
  companyId,
}: {
  fromDate: number;
  toDate: number;
  companyId?: string;
}): Promise<RipeSummary> => {
  return new Promise<RipeSummary>(function (resolve, reject) {
    axios
      .get('/ripe/summary', {
        params: {
          fromDate,
          toDate,
          companyId,
        },
      })
      .then(({ data }: { data: RipeSummary }) => {
        const dataWithDates = {
          ...data,
          fromDate,
          toDate,
        };
        resolve(dataWithDates);
      })
      .catch((error) => reject(error));
  });
};

export default { getRipeSummary };
