import moment from 'moment';

const getFormattedDateString = (dateString?: number | string | null) => {
  if (!dateString) {
    return '';
  }
  const formattedString = moment(dateString, ['YYYY-M-D', 'D/M/YYYY']).format(
    'YYYY-M-D'
  );
  return formattedString === 'Invalid date' ? '' : formattedString;
};

export default getFormattedDateString;
