import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';

import colors from 'colors';
import spacing from 'styles/layout/spacing';
import { DestinationAccountState } from './types';

const messageMap = {
  [DestinationAccountState.NO_ACCOUNT]:
    'Please set a default bank account to withdraw funds',
  [DestinationAccountState.PENDING_WITHDRAWAL]:
    'Bank account verification in progress. Please try again later.',
  [DestinationAccountState.VERIFICATION_PENDING]:
    'Withdrawal will be processed once your bank account has been verified',
  [DestinationAccountState.UNVERIFIED]: (
    <>
      Please verify your bank account in
      <Link
        css={`
          text-decoration: underline dotted;
          cursor: pointer;
          color: ${colors.yellows.dark};
          margin-left: 3px;
          margin-right: 3px;
        `}
        to="/settings?tab=withdrawals"
      >
        Settings
      </Link>
      <span style={{ marginRight: 3 }}>to </span> <span>enable</span>{' '}
      <span>withdrawals</span>
    </>
  ),
};

type DestinationAccountInfoProps = { state: DestinationAccountState | null };

export const DestinationAccountInfo: React.FC<DestinationAccountInfoProps> = ({
  state,
}) => {
  if (!state) {
    return null;
  }

  return (
    <Alert
      css={`
        margin-top: ${spacing.gutter.sm};
      `}
      message={messageMap[state]}
      type="warning"
    />
  );
};
