const urls = {
  verify: {
    user: '/verify?step=user_details',
    upload: '/verify?step=user_details&upload_document=true',
    business: '/verify?step=business_details',
    ownersList: '/verify?step=beneficial_owners&list=true',
    ownerDetails: '/verify?step=beneficial_owners&details=true',
    ownerSuccess: '/verify?step=beneficial_owners&success=true',
    ownerUpload: '/verify?step=beneficial_owners&upload=true',
  },
  activate: {
    name: '/activate?set_name=true',
    phoneEntry: '/activate?set_phone_number=true',
    verifyCode: '/activate?verify_code=true',
  }
}

export default urls;
