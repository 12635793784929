import React from 'react';
import colors from 'colors';
import styled from 'styled-components';
import { Radio, RadioGroupProps } from 'antd';

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  .ant-radio-wrapper {
    border: 1px solid ${colors.greys300};
    border-radius: 4px;
    padding: 10.5px 10px;
    display: flex;
    flex: 1;
  }
  .ant-radio-wrapper-checked {
    border: 1px solid #286eff;
  }
`;

const RadioButtons: React.FC<RadioGroupProps> = (props) => (
  <StyledRadioGroup {...props} />
);

export default RadioButtons;
