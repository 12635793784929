import React from 'react';
import CurrencyAmount from 'components/CurrencyAmount';
import { Typography } from 'antd';

const Amount = props => {
  const { label, amount } = props;
  return (
    <div style={styles.container}>
      <Typography.Text>{label}</Typography.Text>
      <CurrencyAmount amount={amount} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 150,
    margin: '10px 0'
  }
};

export default Amount;
