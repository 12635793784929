import React from 'react';

import Ruler from 'components/Ruler';
import { RowContainer, RowLabel, RowValue } from 'components/row';

type Props = { amount: number };

const InfoHead: React.FC<Props> = ({ amount }) => {
  return (
    <div>
      <RowContainer highlighted>
        <RowLabel>Transfer Amount</RowLabel>
        <RowValue>{amount}</RowValue>
      </RowContainer>
      <Ruler />
    </div>
  );
};

export default InfoHead;
