import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { TableCellWithStatusDot, TableHeaderDot } from 'components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { Typography } from 'antd';
import { useNavigate } from 'hooks';

type IconProps = {
  columnkey: string;
  sortby: string;
};
type Props = {
  title: string;
  columnKey: string;
  hasDot?: boolean;
  hasSort?: boolean;
};

const UpIcon = styled(CaretUpOutlined)`
  margin-left: 6px;
  display: ${({ sortby, columnkey }: IconProps) =>
    sortby !== columnkey && 'none'};
`;
const DownIcon = styled(CaretDownOutlined)`
  margin-left: 6px;
  display: ${({ sortby, columnkey }: IconProps) =>
    sortby !== columnkey && 'none'};
`;
const Title = styled(Typography.Text)`
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold !important;
`;
const Container = styled.div`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Header: React.FC<Props> = ({ title, columnKey, hasDot, hasSort }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const sortParam = queryParams.get('sort') || 'dueDate,asc';
  const [sortBy, sortOrder] = sortParam.split(',');

  const handleClick = () => {
    let newSortBy = sortBy;
    let newSortOrder = sortOrder;
    if (columnKey !== sortBy) {
      newSortOrder = 'asc';
      newSortBy = columnKey;
    } else if (sortOrder === 'asc') {
      newSortOrder = 'desc';
    } else {
      newSortOrder = 'asc';
    }
    const queryParams = new URLSearchParams();
    queryParams.set('sort', `${newSortBy},${newSortOrder}`);
    navigate({ params: queryParams });
  };

  if (hasSort) {
    return (
      <Container onClick={handleClick}>
        {hasDot ? (
          <TableCellWithStatusDot>
            <TableHeaderDot />
            <Title>{title}</Title>
          </TableCellWithStatusDot>
        ) : (
          <Title>{title}</Title>
        )}
        {sortOrder === 'asc' ? (
          <DownIcon sortby={sortBy} columnkey={columnKey} />
        ) : (
          <UpIcon sortby={sortBy} columnkey={columnKey} />
        )}
      </Container>
    );
  } else {
    return (
      <>
        {hasDot ? (
          <TableCellWithStatusDot>
            <TableHeaderDot />
            <Title>{title}</Title>
          </TableCellWithStatusDot>
        ) : (
          <Title>{title}</Title>
        )}
      </>
    );
  }
};

export default Header;
