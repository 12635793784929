import React from 'react';
import Button from './Button';
import featureFlags from 'config/featureFlags';
import { urls } from 'values';
import { Space } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { showCreateInvoiceWindow, showImportFileWindow } from 'store/invoices';

const CreateButtons: React.FC<{
  isAddButtonDisabled?: boolean;
  showAddButton?: boolean;
  type: 'BILL' | 'INVOICE';
}> = ({ isAddButtonDisabled, showAddButton, type }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleClickCreate = () => {
    history.push(urls.createInvoice, { type });
  };

  const handleClickImport = () => {
    dispatch(showImportFileWindow(type));
  };

  const handleClickAdd = () => {
    dispatch(showCreateInvoiceWindow(type));
  };

  return (
    <Space size={16}>
      {featureFlags.createInvoice && (
        <Button onClick={handleClickCreate}>CREATE BILL</Button>
      )}
      {(type === 'INVOICE' || featureFlags.importAddBillEnabled) && (
        <Button onClick={handleClickImport}>IMPORT</Button>
      )}
      {(type === 'INVOICE' ||
        (showAddButton && featureFlags.importAddBillEnabled)) && (
        <Button disabled={isAddButtonDisabled} onClick={handleClickAdd}>
          {featureFlags.addCreditNote ? 'ADD' : 'ADD BILL'}
        </Button>
      )}
    </Space>
  );
};

export default CreateButtons;
