import React, { useEffect } from 'react';
import AuthContainer from 'components/AuthContainer';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { History } from 'history';
import { logout } from 'store/auth';
import PageTracker from 'components/PageTracker';

type Props = {
  history: History;
  logout: () => void;
};

const LoggedIn401: React.FC<Props> = ({ history, logout }) => {
  useEffect(() => {
    logout();
    history.push('/login', { autoLoggedOut: true });
  }, [history, logout]);
  return (
    <AuthContainer>
      <>
        <PageTracker />
        <Spin />
      </>
    </AuthContainer>
  );
};
export default connect(null, { logout })(LoggedIn401);
