import React from 'react';
import styled from 'styled-components/macro';
import { Alert, Typography } from 'antd';

import accountNumberFormatter from 'utils/accountNumberFormatter';
import BankAccount from 'types/BankAccount';
import colors from 'colors';
import spacing from 'styles/layout/spacing';

const { Text } = Typography;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.greys200};
  padding: ${spacing.gutter.xs} ${spacing.gutter.sm};
`;

const HeaderItem = styled(Text)`
  font-weight: bold;
  font-size: 12px;
  color: ${colors.greys600};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AccountName = styled(Text)`
  font-size: 14px;
  color: ${colors.greys600};
`;

const AccountNumber = styled(Text)`
  font-size: 14px;
  text-align: right;
  color: ${colors.greys600};
`;

const ValueText = styled(Text)`
  font-size: 14px;
  color: ${colors.greys600};
`;

const InfoContainer = styled.div<{ width?: string | null }>`
  width: ${(props) => props.width || '100%'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.greys100};
  padding: ${spacing.gutter.sm} ${spacing.gutter.sm};
`;

const Badge = styled.div<{ status: BankAccount['bankAccountStatus'] }>`
  width: ${(props) => (props.status === 'VERIFIED' ? '70px' : '90px')};
  height: ${(props) => (props.status === 'VERIFIED' ? '14px' : '18px')};
  background: ${(props) =>
    props.status === 'VERIFIED' ? '#1db100' : '#ff9300'};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerifiedText = styled.span`
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: ${colors.greys100};
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

type AccountInfoProps = {
  account?: BankAccount;
  variant?: 'FULL' | 'MINIMAL';
};

export const AccountInfo: React.FC<AccountInfoProps> = ({
  account,
  variant = 'MINIMAL',
}) => {
  if (!account) {
    return (
      <Alert description="Add a default bank account to withdraw funds from Relay" />
    );
  }

  const { bankAccountName, bankAccountNumber, bankAccountStatus, bsb, payId } =
    account;

  let infoWidth = '100%';
  if (
    variant === 'FULL' &&
    bankAccountStatus &&
    (bankAccountStatus === 'VERIFIED' ||
      bankAccountStatus === 'VERIFICATION_PENDING')
  ) {
    infoWidth = bankAccountStatus === 'VERIFICATION_PENDING' ? '82%' : '86%';
  }

  return (
    <>
      {variant === 'FULL' && (
        <HeaderRow>
          <HeaderItem>ACCOUNT NAME</HeaderItem>
          {bsb ? <HeaderItem>BSB</HeaderItem> : null}
          {bankAccountNumber ? <HeaderItem>ACCOUNT NUMBER</HeaderItem> : null}
          {payId ? <HeaderItem>PayID</HeaderItem> : null}
          {(bankAccountStatus === 'VERIFIED' ||
            bankAccountStatus === 'VERIFICATION_PENDING') && (
            <HeaderItem>Status</HeaderItem>
          )}
        </HeaderRow>
      )}

      <Container>
        <InfoContainer width={infoWidth}>
          <AccountName ellipsis={{ tooltip: bankAccountName }}>
            {bankAccountName}
          </AccountName>
          {bsb ? (
            <ValueText ellipsis={{ tooltip: bsb }}>{bsb}</ValueText>
          ) : null}
          {bankAccountNumber ? (
            <AccountNumber ellipsis={{ tooltip: bankAccountNumber }}>
              {accountNumberFormatter(bankAccountNumber)}
            </AccountNumber>
          ) : null}
          {payId ? (
            <ValueText ellipsis={{ tooltip: payId }}>{payId}</ValueText>
          ) : null}
        </InfoContainer>

        {variant === 'FULL' &&
          (bankAccountStatus === 'VERIFIED' ||
            bankAccountStatus === 'VERIFICATION_PENDING') && (
            <Badge status={bankAccountStatus}>
              <VerifiedText>
                {bankAccountStatus === 'VERIFIED'
                  ? 'VERIFIED'
                  : 'Verification Pending'}
              </VerifiedText>
            </Badge>
          )}
      </Container>

      {bankAccountStatus === 'VERIFICATION_FAILED' && variant === 'FULL' && (
        <Alert
          message="Verification failed. Please try again."
          type="warning"
        />
      )}
    </>
  );
};
