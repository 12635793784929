import { CompanyStatus } from 'types/Company';
import Invoice from 'types/Invoice';
import PurchaserOffer from 'types/PurchaserOffer';
import SupplierOffer from 'types/SupplierOffer';
import {
  formatCurrency,
  getCashRewardForPurchaserOffer,
  getTodayTimestamp,
} from 'utils';
import { RELAY_FEE_PERCENTAGE } from 'config';
import { TableAction } from '../types';

const MAX_CASH_REWARD_PERCENTAGE = (100 - RELAY_FEE_PERCENTAGE) / 10;

const today = getTodayTimestamp();

type Status =
  | '-'
  | 'Paid'
  | 'Payment Offered'
  | 'Not Eligible'
  | 'Offer Pending'
  | 'Notified'
  | string;

type ReturnValue = {
  status: Status;
  cashback: string;
  cashbackPercentage: string;
  actionLabel: TableAction;
  isNotEligible: boolean;
  notEligibleReason: string;
};

const defaultValues: ReturnValue = {
  status: '-',
  cashback: '-',
  cashbackPercentage: '-',
  actionLabel: 'Include',
  isNotEligible: false,
  notEligibleReason: '',
};

const findBillStatus = ({
  billStatus,
  cashReward,
  dueDate,
  total,
  includeInPurchaserOffer,
  isPurchaserOffersEnabled,
  otherCompanyStatus,
  sentToSupplier,
  purchaserOffer,
  supplierOffer,
}: {
  billStatus: Invoice['billStatus'];
  cashReward: Invoice['cashReward'];
  dueDate: Invoice['dueDate'];
  total: Invoice['total'];
  includeInPurchaserOffer: Invoice['includeInPurchaserOffer'];
  isPurchaserOffersEnabled: boolean;
  otherCompanyStatus: CompanyStatus;
  sentToSupplier: Invoice['sentToSupplier'];
  purchaserOffer?: PurchaserOffer;
  supplierOffer?: SupplierOffer;
}): ReturnValue => {
  let { cashback, cashbackPercentage } = defaultValues;
  if (cashReward && total) {
    cashback = formatCurrency(cashReward);
    cashbackPercentage = `${((cashReward / total) * 100).toFixed(2)}%`;
  }

  if (billStatus === 'PAID') {
    return {
      ...defaultValues,
      status: 'Paid',
      actionLabel: 'View',
      cashback,
      cashbackPercentage,
    };
  }

  if (supplierOffer) {
    const { cashReward, offerStatus } = supplierOffer;
    if (offerStatus === 'TOPUP_PENDING') {
      return {
        ...defaultValues,
        status: 'Topup Pending',
        actionLabel: 'Cancel',
      };
    } else if (offerStatus === 'CLEARING') {
      const status = `SAVE ${formatCurrency(cashReward)}`;

      return {
        ...defaultValues,
        cashback,
        cashbackPercentage,
        status,
        actionLabel: 'Pay Now',
      };
    }
  }

  if (!includeInPurchaserOffer) {
    if (isPurchaserOffersEnabled) {
      return defaultValues;
    }
    return { ...defaultValues, status: 'Not Eligible', actionLabel: '-' };
  }

  if (!purchaserOffer) {
    return {
      ...defaultValues,
      actionLabel: includeInPurchaserOffer
        ? 'Exclude'
        : isPurchaserOffersEnabled
        ? 'Include'
        : '-',
    };
  }

  if (!isPurchaserOffersEnabled) {
    return {
      ...defaultValues,
      actionLabel: '-',
      status: 'Not Eligible',
    };
  }

  if (dueDate === today) {
    return {
      ...defaultValues,
      actionLabel: 'Exclude',
      isNotEligible: true,
      notEligibleReason: 'Due date is today',
      status: 'Not Eligible',
    };
  }

  if (dueDate < today) {
    return {
      ...defaultValues,
      actionLabel: 'Exclude',
      isNotEligible: true,
      notEligibleReason: 'Due date has passed',
      status: 'Not Eligible',
    };
  }

  const { cashpool, crr } = purchaserOffer;

  const pendingCashpool = purchaserOffer.pendingCashpool || 0;
  const totalCashpool = cashpool + pendingCashpool;
  if (total > cashpool) {
    if (pendingCashpool !== 0 && total <= totalCashpool) {
      return {
        ...defaultValues,
        actionLabel: 'Exclude',
        status: 'Offer Pending',
      };
    } else {
      return {
        ...defaultValues,
        actionLabel: 'Exclude',
        isNotEligible: true,
        notEligibleReason: 'Invoice amount is higher than available cash pool',
        status: 'Not Eligible',
      };
    }
  } else {
    const cashReward = getCashRewardForPurchaserOffer({
      total,
      crr,
      dueDate,
    });
    const cashRewardPercentage = (cashReward * 100) / total;
    if (cashRewardPercentage <= MAX_CASH_REWARD_PERCENTAGE) {
      if (sentToSupplier) {
        return {
          ...defaultValues,
          status: otherCompanyStatus === 'INVITED' ? 'Invited' : 'Notified',
          cashback: formatCurrency(cashReward),
          cashbackPercentage: cashRewardPercentage
            ? `${cashRewardPercentage.toFixed(2)}%`
            : '-',
          actionLabel: 'Exclude',
        };
      } else {
        return {
          ...defaultValues,
          status:
            otherCompanyStatus === 'INVITED' ? 'Invited' : 'Payment Offered',
          cashback: formatCurrency(cashReward),
          cashbackPercentage: cashRewardPercentage
            ? `${cashRewardPercentage.toFixed(2)}%`
            : '-',
          actionLabel: otherCompanyStatus === 'ACTIVE' ? 'Notify' : 'Exclude',
        };
      }
    } else {
      return {
        ...defaultValues,
        actionLabel: 'Exclude',
        isNotEligible: true,
        notEligibleReason: 'Discount is higher than maximum permitted',
        status: 'Not Eligible',
      };
    }
  }
};

export default findBillStatus;
