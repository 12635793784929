import React from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';

import colors from 'colors';

import Input from './Input';
import formatter from 'utils/currencyFormatter';

const Container = styled.div<{ custom: boolean; last: boolean }>`
  position: relative;
  border: 1px solid ${colors.greys300};
  border-bottom: ${({ last }) =>
    last ? `1px solid ${colors.greys300}` : 'none'};
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin-top: ${({ custom }) => (custom ? '19px' : '0px')};
  cursor: pointer;
`;

const Label = styled.span`
  margin-left: 14px;
  font-size: 14px;
  color: ${colors.greys800};
`;

const Value = styled.span`
  position: absolute;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.greys900};
`;

type Props = {
  label: string;
  value: number;
  last: boolean;
  custom: boolean;
  isSelected: boolean;
  onClick: (label: string) => void;
  updateCustomInput: (value: string) => void;
};

const Amount: React.FC<Props> = ({
  label,
  value,
  last,
  custom,
  isSelected,
  onClick,
  updateCustomInput,
}) => {
  return (
    <Container last={last} custom={custom} onClick={() => onClick(label)}>
      <Radio checked={isSelected} />
      <Label>{label}</Label>
      {custom ? (
        <Input updateCustomInput={updateCustomInput} value={value} />
      ) : (
        <Value>{`${formatter.format(value)}`}</Value>
      )}
    </Container>
  );
};

export default Amount;
