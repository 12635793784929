import React, { useEffect, useState } from 'react';
import api from 'api';
import colors from 'colors';
import processError from 'utils/processError';
import DCFile from 'types/DCFile';
import styled from 'styled-components';
import { Card, Switch, Col, Row, Typography, notification } from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

type StatusProps = { isRunning: boolean };
const Status = styled(Text)`
  color: ${(props: StatusProps): string =>
    props.isRunning ? colors.success : colors.error};
`;

const BankFileProcess: React.FC = () => {
  const [dcFile, setDCFile] = useState<DCFile | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    api.admin.dcFile
      .get()
      .then((dcFile: DCFile) => setDCFile(dcFile))
      .catch((error: any) => {
        const { message } = processError(error);
        notification.error({
          message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  let status = '';
  if (dcFile) {
    status = dcFile.status;
  }

  const handleClick = (): void => {
    if (dcFile === null) {
      return;
    }
    const newStatus = status === 'RUNNING' ? 'SUSPENDED' : 'RUNNING';
    const payload = {
      ...dcFile,
      status: newStatus,
    };
    setLoading(true);
    api.admin.dcFile
      .put(payload)
      .then((dcFile: DCFile) => {
        setDCFile(dcFile);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Extra = () => (
    <Switch
      checked={status === 'RUNNING'}
      loading={isLoading}
      onClick={handleClick}
    />
  );

  return (
    <Row>
      <Col xs={24} sm={16} md={12} lg={8}>
        <Card title="BNZ File Processing" extra={dcFile && <Extra />}>
          {isLoading ? (
            <>
              <LoadingOutlined />
              <Text> Please wait...</Text>
            </>
          ) : (
            <>
              {status === '' && (
                <>
                  <ExclamationCircleTwoTone twoToneColor={colors.warning} />
                  <Text> Could not fetch status</Text>
                </>
              )}
              {status === 'RUNNING' && (
                <>
                  <CheckCircleTwoTone twoToneColor={colors.success} />
                  <Status isRunning={true}> Running</Status>
                </>
              )}
              {status === 'SUSPENDED' && (
                <>
                  <CloseCircleTwoTone twoToneColor={colors.error} />
                  <Status isRunning={false}> Suspended</Status>
                </>
              )}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default BankFileProcess;
