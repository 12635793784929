import React from 'react';
import { formatCurrency, formatDateForDisplay } from 'utils';
import { Modal } from 'antd';
import { InvoiceInfo, ModalHeader } from 'components';

const BillDetails: React.FC<{
  amountDue: number;
  companyName: string;
  dueDate: number;
  invoiceNumber: string;
  hideModal: () => void;
}> = ({ amountDue, companyName, dueDate, invoiceNumber, hideModal }) => {
  return (
    <Modal
      closable={false}
      title={<ModalHeader onClose={hideModal} title="Bill Details" />}
      visible={true}
      onCancel={hideModal}
      onOk={hideModal}
      okText="OK"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <InvoiceInfo
        amountDue={formatCurrency(amountDue)}
        companyName={companyName}
        dueDate={formatDateForDisplay(dueDate)}
        invoiceNumber={invoiceNumber}
        invoiceType={'BILL'}
      />
    </Modal>
  );
};

export default BillDetails;
