import React, { useState } from 'react';
import api from 'api';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login, getBankAccounts } from 'store/auth';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  width: 100vw;
`;
const ErrorMessage = styled.p`
  font-size: 18px;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const ConnectAccountError = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((store: any) => store.signup.token);

  const account = queryString.parse(history.location.search).error;
  const [loadingDetails, setLoadingDetails] = useState(false);

  const loadUser = () => {
    setLoadingDetails(true);
    api.auth
      .getUser(token)
      .then((userDetails) => {
        const companyId = userDetails.companyId;
        // get company details
        api.company
          .get({ companyId, token })
          .then((company) => {
            const userData = {
              ...userDetails,
              token,
              company,
            };
            dispatch(login({ user: userData, loggedIn: true }));
            dispatch(getBankAccounts(companyId));
            history.push('/');
            setLoadingDetails(false);
          })
          .catch(() => {
            setLoadingDetails(false);
          });
        // get company details ends here
      })
      .catch(() => {
        setLoadingDetails(false);
      });
    // get user details ends here
  };

  return (
    <Container>
      <ErrorMessage>
        Error connecting to {`${account === 'myob' ? 'MYOB' : 'Xero'}`}.
        <br />
        You can click on the button below to continue to your account. You can
        try connecting to your {`${account === 'myob' ? 'MYOB' : 'Xero'}`}{' '}
        account once you are logged in.
        <br /> Please contact Relay if you need further assistance.
      </ErrorMessage>
      <Column>
        <Button
          block
          loading={loadingDetails}
          css={`
            margin-right: 5px;
          `}
          onClick={loadUser}
        >
          Continue to account
        </Button>
        <Button
          onClick={() => history.push('/login')}
          block
          css={`
            margin-top: 5px;
          `}
        >
          Back to Login
        </Button>
      </Column>
    </Container>
  );
};

export default ConnectAccountError;
