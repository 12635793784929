import React from 'react';
import moment from 'moment';
import RipeCompanyInvoice, {
  AuthorisedInvoice,
  RipeType,
} from 'types/RipeCompanyInvoice';
import { formatCurrency, getInvoiceVariance } from 'utils';
import { RouteComponentProps } from 'react-router';
import { InputItem } from 'components';

type Props = {
  supplierName: RipeCompanyInvoice['supplierName'];
  invoiceNumber: RipeCompanyInvoice['invoiceNumber'];
  orderNumber: RipeCompanyInvoice['orderNumber'];
  invoiceAmount: RipeCompanyInvoice['invoiceAmount'];
  issueDate: string;
  posAmount: RipeCompanyInvoice['posAmount'];
  reason: string;
  status: string;
  userNotes: string;
  history: RouteComponentProps['history'];
  type: RipeType;

  authorisedInvoice: AuthorisedInvoice | null;
};

const ViewInfo: React.FC<Props> = ({
  supplierName,
  invoiceNumber,
  orderNumber,
  invoiceAmount,
  issueDate,
  posAmount,
  reason,
  status,
  userNotes,
  type,

  authorisedInvoice,
}) => {
  return (
    <>
      {type !== 'VARIANCE' && type !== 'AUTHORISED' && (
        <InputItem label="Reason">{reason}</InputItem>
      )}
      {type === 'AUTHORISED' && <InputItem label="Status">{status}</InputItem>}

      {type === RipeType.AUTHORISED && authorisedInvoice && (
        <>
          <InputItem label="Supplier">
            {authorisedInvoice.supplierName}
          </InputItem>
          <InputItem label="Invoice Number">
            {authorisedInvoice.invoiceNumber}
          </InputItem>
          <InputItem label="Order Number">
            {authorisedInvoice.orderNumber}
          </InputItem>
          <InputItem label="Invoice Amount">
            {formatCurrency(authorisedInvoice.invoiceAmount)}
          </InputItem>
        </>
      )}

      {type !== RipeType.AUTHORISED && (
        <>
          <InputItem label="Supplier">
            {supplierName ? supplierName : '-'}
          </InputItem>
          <InputItem label="Invoice Number">
            {invoiceNumber ? invoiceNumber : '-'}
          </InputItem>
          <InputItem label="Order Number">
            {orderNumber ? orderNumber : '-'}
          </InputItem>
          <InputItem label="Invoice Amount">
            {invoiceAmount ? formatCurrency(parseFloat(invoiceAmount)) : '-'}
          </InputItem>
        </>
      )}

      {type !== 'VARIANCE' && (
        <InputItem label="Issue Date">
          {type === RipeType.AUTHORISED
            ? authorisedInvoice?.issueDate
            : issueDate
            ? moment(issueDate, 'D/M/YYYY').format('DD MMMM YYYY')
            : '-'}
        </InputItem>
      )}
      {type === 'VARIANCE' && (
        <>
          <InputItem label="POS Amount">
            {posAmount ? formatCurrency(parseFloat(posAmount)) : '-'}
          </InputItem>
          <InputItem label="Invoice Variance">
            {posAmount && invoiceAmount
              ? getInvoiceVariance({ posAmount, invoiceAmount })
              : '-'}
          </InputItem>
        </>
      )}
      <InputItem label="Notes">
        {type === RipeType.AUTHORISED
          ? authorisedInvoice?.userNotes
          : userNotes
          ? userNotes
          : '-'}
      </InputItem>
    </>
  );
};

export default ViewInfo;
