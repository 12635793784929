import { Button, ButtonProps } from 'antd';
import React, { useState } from 'react';

interface CsvDownloadProps {
  className?: string;
  label?: string;
  buttonType?: ButtonProps['type'];
  filename: string;
  disabled: boolean;
}

const CsvDownload: React.FC<CsvDownloadProps> = ({
  className,
  label,
  buttonType,
  filename,
  disabled,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadTemplate = () => {
    setIsDownloading(true);
    const filenameWithExtension = filename + '.csv';
    fetch(`/templates/${filenameWithExtension}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filenameWithExtension);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
    setIsDownloading(false);
  };
  return (
    <Button
      className={className}
      type={buttonType}
      key="download"
      onClick={downloadTemplate}
      loading={isDownloading}
      disabled={disabled}
    >
      {label ? label : 'Download CSV'}
    </Button>
  );
};

export default CsvDownload;
