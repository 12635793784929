import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { ModalHeader } from 'components';

type ModalProps = AntModalProps & {
  title: string;
  subtitle?: string;
};

export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  subtitle,
  ...rest
}) => {
  return (
    <AntModal
      css={`
        .ant-modal-footer > .ant-btn {
          border: none;
        }
      `}
      closable={false}
      title={
        <ModalHeader
          onClose={rest.onCancel}
          title={title}
          subtitle={subtitle}
        />
      }
      {...rest}
    >
      {children}
    </AntModal>
  );
};
