import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { TableProps } from 'antd/lib/table';
import { Table } from 'antd';

const StyledTable = styled(Table)<{
  $shouldNotHeaderApplyFontWeight?: boolean;
}>`
  min-height: 400px;
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
  }
  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 0;
    text-align: center;
  }

  .ant-table-thead .ant-table-cell {
    background-color: rgb(103, 108, 116);
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    font-variant: tabular-nums;
    line-height: 1.5715;
    height: 24px;
    padding: 0px 10px;
    border-radius: 0;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 10px;
    font-size: 12px;
    .ant-tag {
      margin-right: 0;
      font-size: 10px;
    }
  }
  .ant-table-summary .ant-table-cell {
    padding: 10px;
    font-size: 12px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: ${colors.greys900};
  }
  .ant-table-expanded-row .ant-spin-nested-loading {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .ant-table-tbody > tr:hover > td {
    button {
      background-color: ${colors.greys200};
    }
  }
  .ant-table-tbody > .ant-table-placeholder > .ant-table-cell {
    display: table-cell;
    border-bottom: none;
    padding: 20px 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 400;
  }
  .ant-table-tbody .custom-sorted-column {
    padding-right: 23px !important;
  }
  .ant-checkbox {
    top: 0.18em;
  }

  .ant-checkbox-inner {
    border-radius: 3px;
  }

  .ant-table-tbody .custom-sorted-column {
    padding-right: 23px !important;
  }
  .custom-spacing {
    padding-right: 3% !important;
  }
  .ant-table-thead > tr > th {
    font-weight: ${({ $shouldNotHeaderApplyFontWeight }) =>
      $shouldNotHeaderApplyFontWeight && 'unset !important'};
  }
`;

const RelayTable: React.FC<
  TableProps<any> & { $shouldNotHeaderApplyFontWeight?: boolean }
> = ({ scroll = { x: 1000 }, ...props }) => {
  return <StyledTable {...props} scroll={scroll} />;
};

export default RelayTable;
