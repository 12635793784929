import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Space } from 'antd';
import CounterParty from 'types/CounterParty';
import { ProcessingQueue } from 'types/ProcessingQueue';
import api from 'api';
import { processError } from 'utils';
import { DefaultProcessingQueueFormItem } from 'components';

export const DefaultProcessingQueue: React.FC<{
  counterParty: CounterParty;
  entityId: string;
  fallbackQueue?: ProcessingQueue;
  onSuccess: (counterParty: CounterParty) => void;
}> = ({ counterParty, entityId, fallbackQueue, onSuccess }) => {
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [originalDefaultQueue, setOriginalDefaultQueue] =
    useState<ProcessingQueue>();
  const [defaultQueue, setDefaultQueue] = useState<ProcessingQueue>();

  useEffect(() => {
    setOriginalDefaultQueue(
      counterParty.defaultProcessingQueue ?? fallbackQueue
    );
    setDefaultQueue(counterParty.defaultProcessingQueue ?? fallbackQueue);
  }, [counterParty, fallbackQueue]);

  const saveNotification = () => {
    const message = 'Default Processing Queue set to ' + defaultQueue;
    notification.success({ message });
  };

  const handleSave = () => {
    if (defaultQueue === counterParty.defaultProcessingQueue) {
      saveNotification();
      setEditing(false);
      return;
    }
    setSaving(true);
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: defaultQueue,
      path: '/processingQueue' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((res) => {
        setOriginalDefaultQueue(res.counterParty.defaultProcessingQueue);
        saveNotification();
        onSuccess(res.counterParty);
      })
      .catch((error) => {
        setDefaultQueue(originalDefaultQueue);
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => {
        setEditing(false);
        setSaving(false);
      });
  };

  return (
    <Space wrap>
      <div style={{ minWidth: '200px' }}>
        {isEditing ? (
          <DefaultProcessingQueueFormItem
            defaultValue={originalDefaultQueue}
            onChange={(value) => setDefaultQueue(value)}
            style={{ width: '150px' }}
          />
        ) : defaultQueue ? (
          defaultQueue.charAt(0) + defaultQueue.slice(1).toLowerCase()
        ) : (
          '-'
        )}
      </div>
      <Col offset={8}>
        <Button
          onClick={() => {
            isEditing ? handleSave() : setEditing(true);
          }}
          type="ghost"
          loading={isSaving}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Col>
    </Space>
  );
};
