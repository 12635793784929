import axios from 'axios';
import {
  PayablesSummaryApiResponse,
  PayablesSummaryDetails,
} from 'types/PayablesSummary';

const getAllPayablesSummary = ({
  abortController,
  page,
  size,
  fromTime,
  toTime,
  status,
  supplierName,
  sort,
  isMatchedPayablesValue,
  hasCreditOrAmountOwing,
}: {
  abortController?: AbortController;
  page: number;
  size: number;
  fromTime: number;
  toTime: number;
  status?: string;
  supplierName?: string;
  sort?: string;
  isMatchedPayablesValue?: string;
  hasCreditOrAmountOwing?: boolean;
}): Promise<PayablesSummaryApiResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get('/payables-summaries', {
        params: {
          page,
          size,
          fromTime,
          toTime,
          status,
          supplierName: supplierName && encodeURIComponent(supplierName),
          sort,
          isMatchedPayablesValue,
          hasCreditOrAmountOwing,
        },
        signal: abortController?.signal,
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });

const getPayablesSummaryDetails = ({
  status,
  supplierId,
  fromTime,
  toTime,
  startAt,
  endAt,
  includeUnallocatedCreditNotes,
}: {
  status: string;
  supplierId: string;
  fromTime: number;
  toTime: number;
  startAt?: number;
  endAt?: number;
  includeUnallocatedCreditNotes: boolean;
}): Promise<PayablesSummaryDetails> =>
  new Promise<PayablesSummaryDetails>((resolve, reject) => {
    const params = {
      status,
      supplierId,
      fromTime,
      toTime,
      startAt: startAt || 0,
      endAt: endAt || 25,
      includeUnallocatedCreditNotes,
    };
    axios
      .get(`/payables-summaries/details`, { params })
      .then(({ data }: { data: PayablesSummaryDetails }) => resolve(data))
      .catch((error) => reject(error));
  });

const filter = ({
  abortController,
  url,
}: {
  abortController?: AbortController;
  url: string;
}): Promise<PayablesSummaryApiResponse> => {
  return new Promise(function (resolve, reject) {
    axios
      .get('/payables-summaries' + url, {
        signal: abortController?.signal,
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};
export default { getAllPayablesSummary, getPayablesSummaryDetails, filter };
