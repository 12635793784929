import React, { useState } from 'react';
import colors from 'colors';
import styled from 'styled-components';
import moment from 'moment';
import { Button, DatePicker, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const StyledButton = styled(Button)`
  background: ${colors.greys100};
  border-radius: 3px;
  color: ${colors.greys700};
  font-size: 12px;
  padding: 3px 10px;
  :hover,
  :active,
  :focus {
    background: ${colors.greys100};
    color: ${colors.greys700};
  }
`;

const PickerContainer = styled.div`
  position: relative;
  right: 125px;
  top: -30px;
  .ant-picker {
    visibility: hidden;
  }
`;

const MonthDropdown: React.FC<{
  value: number;
  selectedMonthName: string;
  onSelectMonth: (value: moment.Moment) => void;
  onClose: () => void;
}> = ({ value, selectedMonthName, onSelectMonth, onClose }) => {
  const [isPickerOpen, setPickerOpen] = useState(false);

  const handleChange = (value: moment.Moment | null) => {
    if (value) {
      onSelectMonth(value);
      setPickerOpen(false);
    }
  };

  return (
    <Dropdown
      onVisibleChange={(visible) => setPickerOpen(visible)}
      overlay={() => (
        <PickerContainer>
          <DatePicker
            defaultValue={moment(value)}
            disabledDate={(date) => date > moment()}
            onChange={handleChange}
            open={isPickerOpen}
            picker="month"
            onOpenChange={(open) => {
              if (!open) {
                onClose();
              }
            }}
          />
        </PickerContainer>
      )}
      trigger={['click']}
    >
      <StyledButton data-testid="month-dropdown-label" type="ghost">
        {selectedMonthName}
        <DownOutlined />
      </StyledButton>
    </Dropdown>
  );
};

export default MonthDropdown;
