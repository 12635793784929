import React, { useState } from 'react';
import styled from 'styled-components';
import api from 'api';
import { useHistory, useLocation } from 'react-router-dom';
import { AutoComplete, Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { MIN_SEARCH_LENGTH } from 'values';
import colors from 'colors';
import Invoice from 'types/Invoice';

const StyledAutoComplete = styled(AutoComplete)`
  position: relative;
  top: 1.5px;
  width: 258px;
`;

const StyledInput = styled(Input)`
  height: 24px;

  border: 1px solid ${colors.greys500};
  border-radius: 2px;
`;

const SearchBar: React.FC<{
  entity?: string | null;
  type: 'INVOICE' | 'BILL';
}> = ({ entity, type }) => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);

  const [searchValue, setSearchValue] = useState(entity || '');
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const selectValueByType = (type: string, invoice: Invoice) => {
    const value =
      type === 'INVOICE'
        ? invoice?.issuedForCompanyName
        : invoice?.issuedByCompanyName;
    return value || '';
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setSearchValue('');
      setOptions([]);
      onClear();
    }
    if (value.length < MIN_SEARCH_LENGTH) {
      return;
    }
    setLoading(true);
    setSearchValue(value);
    const apiParams = new URLSearchParams();
    apiParams.set('page', '0');
    apiParams.set('size', '5');
    apiParams.set(
      type === 'INVOICE' ? 'issuedForCompanyName' : 'issuedByCompanyName',
      `${value}:matches`
    );
    apiParams.set('type', type);
    apiParams.set('loadedBy', 'RELAY,SYSTEM,RIPE');
    const apiUrl = `?${apiParams}`;
    api.invoices
      .filter({ url: apiUrl })
      .then((results) => {
        // to remove duplicate objects from results
        let resultsArray = [];
        resultsArray = results.invoices.filter(
          (ele, ind) =>
            ind ===
            results.invoices.findIndex(
              (elem) =>
                elem.invoice.owingCompany.id === ele.invoice.owingCompany.id
            )
        );
        setOptions(
          resultsArray.map((item) => ({
            label: selectValueByType(type, item.invoice),
            value: selectValueByType(type, item.invoice),
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  // User has selected an entry from suggestions.
  // Let's now filter the list of invoices/bills.
  const handleSelect = (value: string) => {
    if (value) {
      queryParams.set('page', '1');
      queryParams.set('entity', value);
      history.push(`${path}?${queryParams}`);
    }
  };

  const onClear = () => {
    queryParams.delete('entity');
    queryParams.set('page', '1');
    history.push(`${path}?${queryParams}`);
  };

  return (
    <StyledAutoComplete
      allowClear
      onSearch={debounce(handleSearch, 1000)}
      onSelect={handleSelect}
      options={options}
      defaultValue={searchValue}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <StyledInput
        placeholder="Search"
        prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
        size="small"
      />
    </StyledAutoComplete>
  );
};

export default SearchBar;
