import React from 'react';
import styled from 'styled-components';
import ProgressBar from 'components/ProgressBar';
import colors from 'colors';
import { InvalidCounterpartyCsvItemWithReason } from 'types/CounterParty';
import Papa from 'papaparse';

const ClickableText = styled.span`
  color: ${colors.error};
  text-decoration: underline;
  cursor: pointer;
`;

type Props = {
  totalItemCount: number;
  validItemCount: number;
  failedItems: InvalidCounterpartyCsvItemWithReason[];
  progressView: boolean;
  importProgress: string;
};

export const ConfirmationScreen: React.FC<Props> = ({
  totalItemCount,
  validItemCount,
  failedItems,
  progressView,
  importProgress,
}) => {
  const singleTotalItemCount = totalItemCount === 1;
  const singleValidItem = validItemCount === 1;
  const singleFailedItem = failedItems.length === 1;
  const progressPercent = importProgress.split('%').join('');

  const downloadItems = () => {
    const filename = 'failed_counterparty_imports.csv';
    const formattedItems = [...failedItems].map((item) => {
      return {
        'Company Name': item.failedObject.entityName,
        'External Account ID': item.failedObject.externalAccountId,
        'External Bank Account': item.failedObject.externalBankAccountNumber,
        'GST Number': item.failedObject.gstNumber,
        'GL Code': item.failedObject.glCode,
        'Payment Terms Frequency':
          item.failedObject.paymentTerm?.paymentTermsFrequency,
        'Payment Terms Day/Date':
          item.failedObject.paymentTerm?.paymentTermsDayDate,
        Reason: item.failureReason,
      };
    });
    let csv = Papa.unparse(formattedItems);
    if (csv == null) return;
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    const data = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {progressView ? (
        <ProgressBar percent={progressPercent} />
      ) : (
        <>
          <p>
            The file contains&nbsp;<b>{totalItemCount}</b>
            &nbsp;item{singleTotalItemCount ? '' : 's'}.
          </p>
          {totalItemCount !== 0 && (
            <p>
              <b>{validItemCount}</b>
              &nbsp;item{singleValidItem ? '' : 's'}
              {singleValidItem ? ' was ' : ' were '}
              imported successfully.
            </p>
          )}
          {failedItems.length > 0 && (
            <>
              <p style={{ color: colors.error }}>
                <b>{failedItems.length}</b>
                &nbsp;item{singleFailedItem ? '' : 's'}
                &nbsp;could not be imported. &nbsp;
                <ClickableText onClick={downloadItems}>
                  View Details
                </ClickableText>
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};
