import React, { useState } from 'react';
import styled from 'styled-components';

import colors from 'colors';
import { Input } from 'antd';

const StyledInput = styled(Input)<{
  $withdraw: boolean;
  $discountEntry: boolean;
  $error: boolean;
}>`
  box-sizing: border-box;
  ${(props) =>
    !props.$withdraw && props.$error
      ? `border: 1px solid ${colors.error}`
      : ''};
  width: 150px;
  height: ${(props) =>
    props.$discountEntry ? '36px' : props.$withdraw ? '38px' : '40px'};
  margin-left: ${(props) => (props.$withdraw ? '-10px' : 0)};
`;

type Props = {
  value: string | null;
  placeholder?: string;
  onChange: (event: any) => void;
  className?: string;
  error?: string | null;
  discountEntry: boolean;
  onBlur: () => void;
  withdraw?: string;
  testId?: string;
};

const AmountInput: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  className,
  error,
  discountEntry,
  onBlur,
  withdraw,
  testId,
}) => {
  const [isInFocus, updateFocus] = useState(false);
  const changeFocus = () => {
    updateFocus(!isInFocus);
  };

  return (
    <StyledInput
      className={className}
      data-testid={testId}
      prefix="$"
      value={value ? value : ''}
      placeholder={placeholder || 'Enter Amount'}
      onChange={onChange}
      onBlur={() => {
        onBlur();
        changeFocus();
      }}
      onFocus={changeFocus}
      $error={!!error}
      $discountEntry={!!discountEntry}
      $withdraw={!!withdraw}
    />
  );
};

export default AmountInput;
