import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import currencyFormatter from 'utils/currencyFormatter';
import CurrencyAmount from 'components/CurrencyAmount';
import Amount from 'components/Amount';
import PaymentDirectionIndicator from 'components/PaymentDirectionIndicator';
import colors from 'colors';
import knapsack from 'utils/knapsack';
import { Button, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  MAX_FEE_PERCENTAGE,
  MIN_PURCHASER_OFFER_FEE,
  RELAY_FEE_PERCENTAGE,
} from 'config';
import getTimestamp from 'utils/getTimestamp';
import { setShowPayNow } from 'store/offerLanding';
import { useDispatch } from 'react-redux';

const { Text } = Typography;

const AmountContainer = styled.div`
  padding: 10px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ApprovedAmountContainer = styled(AmountContainer)`
  background: ${colors.greys100};
`;

const AmountLabel = styled(Text)`
  font-size: 14px;
`;

const AmountValue = styled(CurrencyAmount)`
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  margin: 30px 0 20px 0;
`;

const today = getTimestamp(moment());

const OfferDetails = (props) => {
  const dispatch = useDispatch();

  const {
    history,
    invoices,
    invoiceType,
    purchaserOffersAvailable,
    token,
    setMessage,
    setSubMessage,
    user,
    type,
  } = props;

  const [validInvoices, setValidInvoices] = useState([]);
  const [purchaserCompanyName, setPurchaserCompanyName] = useState('');
  const [supplierCompanyName, setSupplierCompanyName] = useState('');
  const [amountApproved, setAmountApproved] = useState(0);
  const [amountAvailable, setAmountAvailable] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const purchaserCompanyName = invoices[0]?.issuedForCompanyName;
    const supplierCompanyName = invoices[0]?.issuedByCompanyName;
    setPurchaserCompanyName(purchaserCompanyName);
    setSupplierCompanyName(supplierCompanyName);

    if (invoiceType === 'INVOICE') {
      const purchaserCompanyId = invoices[0]?.issuedFor;
      const purchaserOffer = purchaserOffersAvailable.find(
        (offer) =>
          (offer.purchaserOffer.offerStatus === 'ACTIVE' ||
            offer.purchaserOffer.offerStatus === 'TOPUP_PENDING') &&
          offer.purchaserOffer.createdByCompany === purchaserCompanyId
      );

      if (purchaserOffer) {
        const { cashpool, crr } = purchaserOffer.purchaserOffer;
        let validInvoices = [];
        invoices.forEach((invoice) => {
          if (invoice.dueDate >= today && invoice.includeInPurchaserOffer) {
            const { dueDate, discountedTotal } = invoice;
            const daysPaidEarly = moment(dueDate).diff(moment(), 'days') + 1;
            const serviceFee =
              (crr * discountedTotal * daysPaidEarly) /
                (100 * 365 * ((100 - RELAY_FEE_PERCENTAGE) / 100)) +
              MIN_PURCHASER_OFFER_FEE;
            const serviceFeePercent = (serviceFee / discountedTotal) * 100;
            if (
              discountedTotal <= cashpool &&
              serviceFeePercent <= MAX_FEE_PERCENTAGE
            ) {
              validInvoices.push({
                invoice,
                serviceFee,
              });
            }
          }
        });
        setValidInvoices(validInvoices);
        const amountApproved = validInvoices.reduce(
          (accumulator, invoice) =>
            accumulator + invoice.invoice.discountedTotal,
          0
        );
        setAmountApproved(amountApproved);
        if (amountApproved > cashpool) {
          const { total, selectedInvoices } = knapsack(validInvoices, cashpool);
          const serviceFee = selectedInvoices.reduce(
            (accumulator, invoice) => accumulator + invoice.serviceFee,
            0
          );
          setAmountAvailable(total);
          setDiscount(serviceFee);
        } else {
          const serviceFee = validInvoices.reduce(
            (accumulator, invoice) => accumulator + invoice.serviceFee,
            0
          );
          setDiscount(serviceFee);
          setAmountAvailable(amountApproved);
        }
      }
    } else {
      const discount = invoices.reduce(
        (accumulator, current) => accumulator + current.cashReward,
        0
      );
      setDiscount(discount);
      const amountAvailable = invoices.reduce(
        (accumulator, current) => accumulator + current.discountedTotal,
        0
      );
      setAmountAvailable(amountAvailable);
      setValidInvoices(invoices);
    }
  }, [invoices, invoiceType, purchaserOffersAvailable]);

  useEffect(() => {
    if (discount) {
      // The offer is valid
      setMessage(
        invoiceType === 'INVOICE'
          ? `Get Paid Today`
          : `Save ${currencyFormatter.format(discount)}`
      );
      setSubMessage(
        invoiceType === 'INVOICE'
          ? `${purchaserCompanyName} has approved ${
              validInvoices.length
            } invoice${validInvoices.length === 1 ? '' : 's'} for early payment`
          : 'Pay early to collect your cashback reward'
      );
    } else {
      // The offer is invalid
      setMessage(
        type === 'notify'
          ? 'Sorry, your early payment offer has expired.'
          : 'Oops! This offer is no longer available.'
      );
      setSubMessage(
        invoiceType === 'INVOICE'
          ? type === 'notify'
            ? 'Login below to review your approved invoices and request early payment.'
            : 'Join Relay to start getting paid faster'
          : `Join Relay to start earning cashback for prompt payment`
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, purchaserCompanyName, validInvoices, invoiceType]);

  const handleButtonClick = () => {
    if (invoiceType === 'BILL') {
      // To take user to bills tab of bills page, and to show Pay Now modal
      dispatch(setShowPayNow(true));
    }

    if (user) {
      history.push('/signup', {
        tokenFromInviteLink: token,
        invitedUser: user,
      });
    } else {
      history.push('/login');
    }
  };

  return (
    <>
      {!!discount && (
        <>
          <PaymentDirectionIndicator
            purchaserCompanyName={purchaserCompanyName}
            supplierCompanyName={supplierCompanyName}
          />

          {invoiceType === 'INVOICE' ? (
            <ApprovedAmountContainer>
              <AmountLabel>Total Amount Due (inc. GST)</AmountLabel>
              <AmountValue amount={amountApproved} />
            </ApprovedAmountContainer>
          ) : (
            <ApprovedAmountContainer>
              <AmountLabel>{`${validInvoices.length} invoices from ${supplierCompanyName} are awaiting payment`}</AmountLabel>
            </ApprovedAmountContainer>
          )}

          <Amount
            amount={amountAvailable}
            label={
              invoiceType === 'INVOICE'
                ? 'Early Payment Available'
                : 'Total Due'
            }
            containerStyle={styles.discountedAmountContainer}
          />

          <div style={styles.line} />

          <AmountContainer>
            <AmountLabel>
              {invoiceType === 'INVOICE' ? 'Service Fee' : 'Cashback Reward'}
            </AmountLabel>
            <AmountValue amount={discount} />
          </AmountContainer>
        </>
      )}

      <StyledButton size="large" type="primary" onClick={handleButtonClick}>
        {invoiceType === 'INVOICE'
          ? type === 'notify'
            ? 'Log In'
            : 'Get Paid Today'
          : `Pay Now & Save ${currencyFormatter.format(discount)}`}
      </StyledButton>
    </>
  );
};

const styles = {
  discountedAmountContainer: {
    backgroundColor: '#EBF7FF',
    height: 40,
    marginTop: 10,
    marginBottom: 24,
    textAlign: 'center',
  },
  line: {
    borderBottom: '1px solid #DDD',
    marginLeft: 10,
    marginRight: 10,
  },
};

export default withRouter(OfferDetails);
