import React from 'react';

import InfoHead from './InfoHead';
import InfoRow from './InfoRow';
import Ruler from 'components/Ruler';
import accountNumberFormatter from 'utils/accountNumberFormatter';
import BankAccount from 'types/BankAccount';
import { Address } from 'types/Address';

type WithdrawalConfirmation = {
  countryCode: Address['countryCode'];
  selectedToAccount: BankAccount;
  withDrawalAmount: number;
};

export const WithdrawalConfirmation: React.FC<WithdrawalConfirmation> = ({
  countryCode,
  selectedToAccount,
  withDrawalAmount,
}) => {
  const { bankAccountName, bankAccountNumber, bsb, payId } = selectedToAccount;
  const formattedAccountNumber = accountNumberFormatter(bankAccountNumber);
  return (
    <div>
      <InfoHead amount={withDrawalAmount} />
      <InfoRow label="To" value={bankAccountName || ''} />
      {countryCode === 'AU' ? (
        payId ? (
          <InfoRow label="PayId" value={payId} />
        ) : (
          <>
            <InfoRow label="BSB" value={bsb || ''} />
            <InfoRow label="Account Number" value={formattedAccountNumber} />
          </>
        )
      ) : (
        <InfoRow label="Account Number" value={formattedAccountNumber} />
      )}

      <Ruler />
    </div>
  );
};
