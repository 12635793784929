const FEATURE_FLAGS = window.env?.FEATURE_FLAGS;

const addCreditNote = (FEATURE_FLAGS && FEATURE_FLAGS.ADD_CREDIT_NOTE) || false;
const addressForVerification =
  (FEATURE_FLAGS && FEATURE_FLAGS.ADDRESS_FOR_VERIFICATION) || false;
const australiaSignup =
  (FEATURE_FLAGS && FEATURE_FLAGS.AUSTRALIA_SIGNUP) || false;
const createInvoice = (FEATURE_FLAGS && FEATURE_FLAGS.CREATE_INVOICE) || false;
const exportTransactions = FEATURE_FLAGS && FEATURE_FLAGS.EXPORT_TRANSACTIONS;
const verifyWithPassport = FEATURE_FLAGS && FEATURE_FLAGS.VERIFY_WITH_PASSPORT;
const redirectToMarketingWebsite =
  FEATURE_FLAGS && FEATURE_FLAGS.REDIRECT_TO_MARKETING_WEBSITE;
const highlightInvoice = FEATURE_FLAGS && FEATURE_FLAGS.HIGHLIGHT_INVOICE;
const historicalInvoices = FEATURE_FLAGS && FEATURE_FLAGS.HISTORICAL_INVOICES;
const importAddBillEnabled =
  FEATURE_FLAGS && FEATURE_FLAGS.IMPORT_ADD_BILL_ENABLED;
const customerSupplierSettings =
  (FEATURE_FLAGS && FEATURE_FLAGS.CUSTOMER_SUPPLIER_SETTINGS) || false;
const dashboardEnabled = FEATURE_FLAGS && FEATURE_FLAGS.DASHBOARD_ENABLED;
const pendingApprovalEnabled = FEATURE_FLAGS && FEATURE_FLAGS.PENDING_APPROVAL;
const sendToPendingApprovalEnabled =
  FEATURE_FLAGS && FEATURE_FLAGS.SEND_TO_PENDING_APPROVAL;
const shouldGetZippedRipeImage =
  (FEATURE_FLAGS && FEATURE_FLAGS.GET_ZIPPED_RIPE_IMAGE) || false;
const myobConnect = (FEATURE_FLAGS && FEATURE_FLAGS.MYOB_CONNECT) || false;
const myobEssentials =
  (FEATURE_FLAGS && FEATURE_FLAGS.MYOB_ESSENTIALS) || false;
const myobAccountRight =
  (FEATURE_FLAGS && FEATURE_FLAGS.MYOB_ACCOUNT_RIGHT) || false;
const xeroConnect = (FEATURE_FLAGS && FEATURE_FLAGS.XERO_CONNECT) || false;
const batchInvite = (FEATURE_FLAGS && FEATURE_FLAGS.BATCH_INVITE) || false;

export default {
  addCreditNote,
  addressForVerification,
  australiaSignup,
  createInvoice,
  dashboardEnabled,
  exportTransactions,
  verifyWithPassport,
  redirectToMarketingWebsite,
  highlightInvoice,
  historicalInvoices,
  importAddBillEnabled,
  customerSupplierSettings,
  pendingApprovalEnabled,
  sendToPendingApprovalEnabled,
  shouldGetZippedRipeImage,
  myobConnect,
  myobEssentials,
  myobAccountRight,
  xeroConnect,
  batchInvite,
};
