import React, { useState } from 'react';
import { notification } from 'antd';

import api from 'api';
import { Toggle } from 'ds';
import { processError } from 'utils';
import { CounterParty } from 'types/CounterParty';

export const PurchaserOffersToggle: React.FC<{
  counterParty: CounterParty;
  entityId: string;
  onToggle: (data: CounterParty) => void;
}> = ({ counterParty, entityId, onToggle }) => {
  const [isSubmitting, setSubmiting] = useState(false);

  const handleToggle = (checked: boolean) => {
    setSubmiting(true);
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: checked,
      path: '/isPurchaserOffersEnabled' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((data) => {
        const outcome = checked ? 'enabled' : 'disabled';
        const message = `Purchaser offers ${outcome} for ${counterParty.counterParty.companyName}`;
        notification.info({ message });
        onToggle(data.counterParty);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setSubmiting(false));
  };

  return (
    <Toggle
      checked={counterParty.isPurchaserOffersEnabled}
      loading={isSubmitting}
      onChange={handleToggle}
      checkedChildren="Enabled"
      unCheckedChildren="Disabled"
    />
  );
};
