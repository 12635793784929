import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  notification,
  Skeleton,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from 'antd';

import accountNumberFormatter from 'utils/accountNumberFormatter';
import api from 'api';
import { formatCurrency, processError } from 'utils';
import Company from 'types/Company';
import { WithdrawModal } from 'components';
import BankAccount from 'types/BankAccount';

const StyledButton = styled(Button)`
  margin-top: 0.8rem;
`;

const { Text } = Typography;

type Props = {
  companyId?: string;
  balance: Company['fundsAvailable'];
  refreshCompanyData: () => void;
};

const Summary: React.FC<Props> = (props) => {
  const { companyId, balance, refreshCompanyData } = props;

  const [isWithdrawModalVisible, setWithdrawModalVisible] = useState(false);

  const [relayBankAccount, setRelayBankAccount] = useState<BankAccount | null>(
    null
  );
  const [defaultWithdrawalAccount, setDefaultWithdrawalAccount] =
    useState<BankAccount | null>(null);

  const [loading, setLoading] = useState(false);

  const showWithdrawModal = () => setWithdrawModalVisible(true);
  const hideWithdrawModal = () => setWithdrawModalVisible(false);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    api.company
      .getBankAccounts({ companyId })
      .then((accounts) => {
        const relayBankAccount = accounts.find(
          (account) => account.loadedBy === 'SYSTEM'
        );
        if (relayBankAccount) {
          setRelayBankAccount(relayBankAccount);
        }

        const defaultWithdrawalAccount = accounts.find(
          (account) =>
            account.defaultAccount && account.bankAccountStatus !== 'DELETED'
        );
        if (defaultWithdrawalAccount) {
          setDefaultWithdrawalAccount(defaultWithdrawalAccount);
        }
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message: 'Error fetching bank accounts',
          description: message,
        });
      })
      .finally(() => setLoading(false));
  }, [companyId]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Space direction="vertical" size="large">
        <div>
          <Statistic
            title="Relay Bank Account Number"
            value={
              accountNumberFormatter(relayBankAccount?.bankAccountNumber) ||
              'Not available'
            }
            formatter={(value) => {
              if (value === 'Not available') {
                return <Text type="secondary">{value}</Text>;
              } else {
                return value;
              }
            }}
          />
          {!relayBankAccount && (
            <StyledButton type="primary">Assign Bank Account</StyledButton>
          )}
        </div>

        <Space size="large" align="end">
          <Statistic title="Relay Balance" value={formatCurrency(balance)} />
          <Tooltip
            title={
              !defaultWithdrawalAccount &&
              'No default withdrawal account available'
            }
          >
            <Button
              css={`
                position: relative;
                bottom: 9px;
              `}
              disabled={!defaultWithdrawalAccount}
              onClick={showWithdrawModal}
            >
              Withdraw
            </Button>
          </Tooltip>
        </Space>
      </Space>

      <WithdrawModal
        isAdmin
        companyId={companyId}
        fundsAvailable={balance}
        onHide={hideWithdrawModal}
        visible={isWithdrawModalVisible}
        sourceAccount={relayBankAccount}
        destinationAccount={defaultWithdrawalAccount}
        onSuccess={refreshCompanyData}
      />
    </>
  );
};

export default Summary;
