import api from 'api';
import processError from 'utils/processError';
import { notification } from 'antd';

const GET_TOPUPS = 'GET_TOPUPS';
const SET_LOADING = 'SET_LOADING';
const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';

const LOGOUT = 'LOGOUT';

const initialState = {
  topups: [],
  loading: false,
  totalNumberOfTopups: 0,
  size: 25,
  page: 1
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOPUPS:
      return {
        ...state,
        topups: payload.topups,
        totalNumberOfTopups: payload.totalElements,
        loading: false
      };
    case SET_LOADING:
      return { ...state, loading: true };
    case UPDATE_PAGE_SIZE: {
      return { ...state, size: payload };
    }
    case UPDATE_PAGE_NUMBER: {
      return { ...state, page: payload };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const getTopups = ({ status, page, size }) => {
  return dispatch => {
    dispatch({ type: SET_LOADING });
    api.admin.topups.get({ status, page, size }).then(res => {
      dispatch({ type: GET_TOPUPS, payload: res });
    }).catch(error => {
      const {message} = processError(error);
      notification.error({
        message: 'Error',
        description: message
      });
      dispatch({ type: GET_TOPUPS, payload: {topups: [], totalElements: 0} });
    });
  };
};

export const updatePageSize = size => ({
  type: UPDATE_PAGE_SIZE,
  payload: size
});

export const updatePageNumber = page => ({
  type: UPDATE_PAGE_NUMBER,
  payload: page
});
