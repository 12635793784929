import React, { useEffect, useState } from 'react';
import TabsContainer from './TabsContainer';
import Table from 'components/Table';
import { connect } from 'react-redux';
import { getSupplierOffers, getPurchaserOffersAvailable } from 'store/offers';
import { withRouter } from 'react-router';

const tabs = [
  { name: 'ALL', status: 'ALL_ELIGIBLE' },
  { name: 'Payment Requested', status: 'CASHBACK_OFFERED' },
  { name: 'Payment Available', status: 'PAYMENT_READY' },
  { name: 'Payment Received', status: 'PAYMENT_RECEIVED' },
];

const Tabs = (props) => {
  const { data } = props;

  const { history, location } = props;
  const queryParams = new URLSearchParams(location.search);
  const statusParam = queryParams.get('status');
  const selectedTab = tabs.find((item) => item.status === statusParam);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (selectedTab) {
      setActiveTab(selectedTab);
    } else {
      setActiveTab(tabs[0]);
    }
  }, [selectedTab]);

  const changeActiveTab = (tab) => {
    const status = tab.status;
    const { user, getSupplierOffers, getPurchaserOffersAvailable } = props;
    const connectedToXero =
      user.company.company && user.company.company.connectedToXero;
    const connectedToMyobEssentials =
      user.company.company && user.company.company.connectedToMyobEssentials;
    const companyStatus = user.company.company && user.company.company.status;
    const connectedToMyobAccountRight =
      user.company.company && user.company.company.connectedToMyobAccountRight;

    // const urlParam = tab.name.toLowerCase().split(' ').join('_');

    if (
      connectedToXero ||
      connectedToMyobEssentials ||
      connectedToMyobAccountRight ||
      companyStatus === 'PENDING' ||
      companyStatus === 'ACTIVE' ||
      companyStatus === 'INVITED'
    ) {
      getSupplierOffers();
      getPurchaserOffersAvailable();
      history.push(`/invoices?status=${status}&page=1`);
    }
  };
  if (activeTab) {
    return (
      <>
        <TabsContainer
          key="tabs"
          activeTab={activeTab}
          changeTab={changeActiveTab}
          tabs={tabs}
        />
        <Table
          activeTab={activeTab?.name}
          type={'INVOICE'}
          data={data}
          showLegacyActionsBar
        />
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = ({ auth }) => ({ user: auth.user });

const mapDispatchToProps = {
  getSupplierOffers,
  getPurchaserOffersAvailable,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tabs));
