import {
  BarsOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  MailOutlined,
  SettingOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';

const items = [
  {
    name: 'Summary',
    Icon: BarsOutlined,
  },
  {
    name: 'Directory',
    Icon: IdcardOutlined,
  },
  {
    name: 'KYC',
    Icon: UserOutlined,
  },
  {
    name: 'Offers',
    Icon: DollarCircleOutlined,
  },
  {
    name: 'Contacts',
    Icon: MailOutlined,
  },
  {
    name: 'Transactions',
    Icon: WalletOutlined,
  },
  {
    name: 'Activity',
    Icon: ClockCircleOutlined,
  },
  {
    name: 'Settings',
    Icon: SettingOutlined,
  },
];

export const menuItems = items.map((item) => ({
  ...item,
  key: item.name.toLowerCase(),
}));
