/**
 * This function processes the "invalidObjects" in the
 * counter party merge execution plan details API response.
 * The function converts the said data into a JSON that can be fed to a
 * CSV downloader function to download the data in CSV format.
 */

import moment from 'moment';

import {
  InvalidBillWithReason,
  InvalidInvoiceWithReason,
  InvalidCreditNoteWithReason,
  MergeExecutionPlanDetails,
  MergeStats,
} from 'types/CounterParty';

const processDate = (timestamp: number | null) => {
  if (!timestamp) {
    return null;
  }
  return moment(timestamp).format('DD/MM/YYYY');
};

const defaultReturnItem = {
  'Invoice/Credit Note Number': null,
  'Order Number': null,
  'Supplier Name': null,
  'Issue Date': null,
  'Due Date': null,
  'Amount Due (inc. GST)': null,
};

type ReturnItemType = {
  'Invoice/Credit Note Number': string | null;
  'Order Number': string | null;
  'Supplier Name': string | null;
  'Issue Date': string | null;
  'Due Date': string | null;
  'Amount Due (inc. GST)': number | null;
};

type ReturnType = Array<ReturnItemType>;

const processBill = (bill: InvalidBillWithReason): ReturnItemType => {
  const { invalidObject } = bill;

  if (!invalidObject) {
    return defaultReturnItem;
  }

  return {
    'Invoice/Credit Note Number': invalidObject.billNumber,
    'Order Number': invalidObject.billOrderNumber,
    'Supplier Name': invalidObject.issuedByCompanyName,
    'Issue Date': processDate(invalidObject.issueDate),
    'Due Date': processDate(invalidObject?.dueDate),
    'Amount Due (inc. GST)': invalidObject.discountedTotal,
  };
};

const processInvoice = (invoice: InvalidInvoiceWithReason): ReturnItemType => {
  const { invalidObject } = invoice;

  if (!invalidObject) {
    return defaultReturnItem;
  }

  return {
    'Invoice/Credit Note Number': invalidObject.invoiceNumber,
    'Order Number': invalidObject.invoiceOrderNumber,
    'Supplier Name': invalidObject.issuedForCompanyName,
    'Issue Date': processDate(invalidObject.issueDate),
    'Due Date': processDate(invalidObject.dueDate),
    'Amount Due (inc. GST)': invalidObject.discountedTotal,
  };
};

const processCreditNote = (
  creditNote: InvalidCreditNoteWithReason
): ReturnItemType => {
  const { invalidObject } = creditNote;

  if (!invalidObject) {
    return defaultReturnItem;
  }

  return {
    'Invoice/Credit Note Number': invalidObject.creditNumber,
    'Order Number': invalidObject.orderNumber,
    'Supplier Name': invalidObject.issuedByCompanyName,
    'Issue Date': processDate(invalidObject.issueDate),
    'Due Date': null,
    'Amount Due (inc. GST)': invalidObject.total,
  };
};

const processBills = (bills: InvalidBillWithReason[]) =>
  bills.map((bill) => processBill(bill));

const processInvoices = (invoices: InvalidInvoiceWithReason[]) =>
  invoices.map((invoice) => processInvoice(invoice));

const processCreditNotes = (creditNotes: InvalidCreditNoteWithReason[]) =>
  creditNotes.map((creditNote) => processCreditNote(creditNote));

export const getMergeStats = (data: MergeExecutionPlanDetails): MergeStats => {
  const {
    totalBillsForTransfer,
    totalInvoicesForTransfer,
    totalCreditNotesForTransfer,
  } = data;
  const {
    invalidBillsWithReason,
    invalidInvoicesWithReason,
    invalidCreditNotesWithReason,
  } = data.invalidObjects;

  const billIds = Object.keys(invalidBillsWithReason);
  const invalidBillCount = billIds.length;
  const bills = billIds.map((id) => invalidBillsWithReason[id]);
  const processedBills = processBills(bills);

  const invoiceIds = Object.keys(invalidInvoicesWithReason);
  const invalidInvoiceCount = invoiceIds.length;
  const invoices = invoiceIds.map((id) => invalidInvoicesWithReason[id]);
  const processedInvoices = processInvoices(invoices);

  const creditNoteIds = Object.keys(invalidCreditNotesWithReason);
  const invalidCreditNoteCount = creditNoteIds.length;
  const creditNotes = creditNoteIds.map(
    (id) => invalidCreditNotesWithReason[id]
  );
  const processedCreditNotes = processCreditNotes(creditNotes);

  const processedItems = [
    ...processedBills,
    ...processedInvoices,
    ...processedCreditNotes,
  ];

  return {
    validBillCount: totalBillsForTransfer,
    validInvoiceCount: totalInvoicesForTransfer,
    validCreditNoteCount: totalCreditNotesForTransfer,
    invalidBillCount,
    invalidCreditNoteCount,
    invalidInvoiceCount,
    invalidItems: processedItems,
  };
};
