import React, { useState, useRef, createRef, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import { InputContainer } from 'components';

const Row = styled.div`
  display: flex;
`;

const InputWrapper = styled.div<{ last: boolean }>`
  text-align: center;
  width: 60px;
  margin-right: ${(props) => (props.last ? '0' : '10px')};
`;

const CodeInput = styled(Input)`
  text-align: center;
`;

const CODE_LENGTH = 6;
const initialCodeArray = Array(CODE_LENGTH).fill('');

type OtpInputProps = {
  disabled?: boolean;
  error?: string;
  onChange?: (code: string) => void;
  value?: string;
};

export const OtpInput: React.FC<OtpInputProps> = ({
  disabled,
  error,
  onChange,
  value,
}) => {
  const [codeArray, setCodeArray] = useState(initialCodeArray.slice());
  const [pasting, setPasting] = useState(false);

  useEffect(() => {
    if (value) {
      const valueAsArray = value.split('');
      setCodeArray(valueAsArray);
    }
  }, [value]);

  let inputRefs = useRef<Input[]>([]);

  if (inputRefs.current.length !== CODE_LENGTH) {
    inputRefs.current = Array(CODE_LENGTH)
      .fill(null)
      .map((_, i) => inputRefs.current[i] || createRef());
  }

  const onCodeInput = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    let value = e.target.value;
    if (value === ' ') {
      // Don't let user tap the space key or paste a single space char.
      // If they paste multiple space chars, that is handled in later code block.
      setPasting(false);
      return;
    }
    const parsedValue = Number(value);
    if (!isNaN(parsedValue)) {
      if (pasting) {
        setCodeArray([...value.split('')]);
        if (value.length < 6) {
          inputRefs.current[value.length].focus();
        }
        setPasting(false);
      } else {
        if (value.length > 1) {
          // A digit already exists in the input field.
          // We need to replace it with the newly entered digit.
          const quotient = Math.floor(parsedValue / 10);
          const remainder = parsedValue % 10;
          value =
            codeArray[i] === String(remainder)
              ? String(quotient)
              : String(remainder);
        }
        let updatedCodeArray = codeArray;
        updatedCodeArray[i] = value;
        setCodeArray([...updatedCodeArray]);
        const codeString = updatedCodeArray.join('');
        onChange && onChange(codeString);
        if (value !== '' && i < 5) {
          // Shift focus to next cell
          inputRefs.current[i + 1].focus();
        }
        if (value === '' && i > 0) {
          // Shift focus to previous cell
          inputRefs.current[i - 1].focus();
        }
        setPasting(false);
      }
    }
  };

  return (
    <InputContainer error={error}>
      <Row>
        {[...Array.from(Array(CODE_LENGTH).keys())].map((i) => (
          <InputWrapper key={String(i)} last={i === CODE_LENGTH - 1}>
            <CodeInput
              onChange={(e) => {
                onCodeInput(e, i);
              }}
              onPaste={() => {
                setPasting(true);
                return;
              }}
              onFocus={(e) => {
                e.target.select();
              }}
              ref={(el) => {
                if (el) {
                  inputRefs.current[i] = el;
                }
              }}
              value={codeArray[i]}
              disabled={disabled}
            />
          </InputWrapper>
        ))}
      </Row>
    </InputContainer>
  );
};
