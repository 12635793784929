import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  bootstrapTransactions,
  getTransactions,
  updatePageSize,
  updatePageNumber,
} from 'store/transactions';
import PaginationComponent from 'components/Pagination';
import Transaction from 'types/Transaction';

const PaginationControl = ({
  rows,
  transactions,
}: {
  rows: number;
  transactions: {
    transaction: Transaction;
  }[];
}) => {
  const dispatch = useDispatch();
  const pageNumber = useSelector((state: any) => state.transactions.pageNumber);
  const pageSize = useSelector((state: any) => state.transactions.pageSize);
  const spillOverElements = useSelector(
    (state: any) => state.transactions.spillOverElements
  );

  let totalPages = Math.ceil(rows / pageSize);
  let startRow = Math.ceil((pageNumber - 1) * pageSize + 1);
  let endRow = Math.ceil(pageNumber * pageSize);
  let pagesArray = [];

  for (var i = 1; i <= totalPages; i++) {
    pagesArray.push(i);
  }

  const handlePageChange = (pageNumber: number) => {
    dispatch(updatePageNumber(pageNumber));
    if (pageNumber === 1) {
      dispatch(bootstrapTransactions({ size: pageSize, page: pageNumber - 1 }));
    } else {
      dispatch(
        getTransactions({
          page: pageNumber - 1,
          size: pageSize,
          spillOverElements,
        })
      );
    }
  };

  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(updatePageSize(Number(e.target.value)));
    dispatch(updatePageNumber(1));
    dispatch(bootstrapTransactions({ size: Number(e.target.value) }));
  };
  const MIN_PAGE_SIZE = 10; //pagination control shows only if total elements is grater than 10
  if (transactions.length && rows > MIN_PAGE_SIZE) {
    return (
      <PaginationComponent
        rows={rows}
        startRow={startRow}
        endRow={endRow}
        pageSize={pageSize}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
      />
    );
  } else {
    return null;
  }
};

export default PaginationControl;
