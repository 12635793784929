import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { FilterItem } from 'ds';
import spacing from 'styles/layout/spacing';

export const ProcessingQueue: React.FC<{
  value: string | null;
  handleChange: (value: string) => void;
  handleCheck: (flag: boolean) => void;
  isChecked: boolean;
}> = ({ value, handleChange, handleCheck, isChecked }) => {
  const onChange = (e: RadioChangeEvent) => handleChange(e.target.value);

  return (
    <FilterItem
      label="Default processing queue"
      isChecked={isChecked}
      handleCheck={handleCheck}
    >
      {isChecked && (
        <Radio.Group onChange={onChange} value={value}>
          <Space
            css={`
              padding: ${spacing.gutter.md} ${spacing.gutter.lg};
            `}
            direction="vertical"
          >
            <Radio value={'AUTOMATIC'}>Automatic</Radio>
            <Radio value={'MANUAL'}>Manual</Radio>
          </Space>
        </Radio.Group>
      )}
    </FilterItem>
  );
};
