const SET_DATA_TO_REFRESH = 'relay/ui/SET_DATA_TO_REFRESH';
const SET_REFRESH_DATA = 'relay/ui/SET_REFRESH_DATA';
const SET_TOOLBAR_TITLE = 'SET_TOOLBAR_TITLE';
const SET_TOOLBAR_SUBTITLE = 'SET_TOOLBAR_SUBTITLE';
const SET_GET_VERIFIED_MODAL_VISIBILITY =
  'relay/ui/SET_GET_VERIFIED_MODAL_VISIBILITY';
const SET_PAYMENTS_AVAILABLE_MODAL_VISIBILITY =
  'relay/ui/SET_PAYMENTS_AVAILABLE_MODAL_VISIBILITY';
const SET_PURCHASER_OFFER_BG_CHECK = 'relay/ui/SET_PURCHASER_OFFER_BG_CHECK';
const SHOW_WITHDRAW_MODAL = 'relay/ui/SHOW_WITHDRAW_MODAL';
const HIDE_WITHDRAW_MODAL = 'relay/ui/HIDE_WITHDRAW_MODAL';
const LOGOUT = 'LOGOUT';

export type State = {
  dataToRefresh:
    | null
    | 'INVOICE'
    | 'PAYABLES_SUMMARY'
    | 'PAYABLES_SUMMARY_DETAILS';
  refreshData: boolean;
  toolbarTitle: string;
  toolbarSubtitle: string;
  isGetVerifiedModalVisible: boolean;
  isPaymentsAvailableModalVisible: boolean;
  isWithdrawModalVisible: boolean;
  backgroundCheckPurchaserOffer: {
    shouldCheck: boolean;
    direction: null | 'OFFERED' | 'AVAILABLE';
  };
};
const initialState: State = {
  dataToRefresh: null,
  refreshData: false,
  toolbarTitle: 'Relay',
  toolbarSubtitle: '',
  isGetVerifiedModalVisible: false,
  isPaymentsAvailableModalVisible: true,
  isWithdrawModalVisible: false,
  backgroundCheckPurchaserOffer: { shouldCheck: false, direction: null },
};

type Actions =
  | ReturnType<typeof setDataToRefresh>
  | ReturnType<typeof setRefreshData>
  | ReturnType<typeof setToolbarTitle>
  | ReturnType<typeof setToolbarSubtitle>
  | ReturnType<typeof setGetVerifiedModalVisibility>
  | ReturnType<typeof setPaymentsAvailableModalVisibility>
  | ReturnType<typeof showWithdrawModal>
  | ReturnType<typeof hideWithdrawModal>
  | ReturnType<typeof setBackgroundCheckPurchaserOffer>
  | { type: 'LOGOUT' };

export default (state = initialState, action: Actions): State => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case SET_DATA_TO_REFRESH:
      return { ...state, dataToRefresh: action.payload };
    case SET_REFRESH_DATA:
      return { ...state, refreshData: action.payload };
    case SET_TOOLBAR_TITLE:
      return { ...state, toolbarTitle: action.payload };
    case SET_TOOLBAR_SUBTITLE:
      return { ...state, toolbarSubtitle: action.payload };
    case SET_GET_VERIFIED_MODAL_VISIBILITY:
      return { ...state, isGetVerifiedModalVisible: action.payload };
    case SET_PAYMENTS_AVAILABLE_MODAL_VISIBILITY:
      return { ...state, isPaymentsAvailableModalVisible: action.payload };
    case SHOW_WITHDRAW_MODAL:
      return { ...state, isWithdrawModalVisible: true };
    case HIDE_WITHDRAW_MODAL:
      return { ...state, isWithdrawModalVisible: false };
    case SET_PURCHASER_OFFER_BG_CHECK:
      return { ...state, backgroundCheckPurchaserOffer: action.payload };
    default:
      return state;
  }
};

export const setDataToRefresh = (
  payload: 'INVOICE' | 'PAYABLES_SUMMARY' | 'PAYABLES_SUMMARY_DETAILS'
) => ({ type: SET_DATA_TO_REFRESH, payload } as const);

export const setRefreshData = (flag: boolean) =>
  ({ type: SET_REFRESH_DATA, payload: flag } as const);

export const setToolbarTitle = (title: string) =>
  ({
    type: SET_TOOLBAR_TITLE,
    payload: title,
  } as const);

export const setToolbarSubtitle = (subtitle: string) =>
  ({
    type: SET_TOOLBAR_SUBTITLE,
    payload: subtitle,
  } as const);

export const setGetVerifiedModalVisibility = (flag: boolean) =>
  ({
    type: SET_GET_VERIFIED_MODAL_VISIBILITY,
    payload: flag,
  } as const);

export type PurchaserOfferBgCheckParams = {
  shouldCheck: boolean;
  direction: null | 'OFFERED' | 'AVAILABLE';
};

export const setPaymentsAvailableModalVisibility = (flag: boolean) =>
  ({
    type: SET_PAYMENTS_AVAILABLE_MODAL_VISIBILITY,
    payload: flag,
  } as const);

export const showWithdrawModal = () =>
  ({
    type: SHOW_WITHDRAW_MODAL,
  } as const);

export const hideWithdrawModal = () =>
  ({
    type: HIDE_WITHDRAW_MODAL,
  } as const);

export const setBackgroundCheckPurchaserOffer = (
  payload: PurchaserOfferBgCheckParams
) =>
  ({
    type: SET_PURCHASER_OFFER_BG_CHECK,
    payload,
  } as const);
