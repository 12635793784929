import React, { useState } from 'react';
import api from 'api';
import colors from 'colors';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { MIN_SEARCH_LENGTH } from 'values';
import { AutoComplete, Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const StyledAutoComplete = styled(AutoComplete)`
  position: relative;
  top: 1.5px;
  width: 258px;
`;

const StyledInput = styled(Input)`
  height: 24px;

  border: 1px solid ${colors.greys500};
  border-radius: 2px;
`;

const fromTime = moment().startOf('month').valueOf();
const toTime = moment().endOf('month').valueOf();

const SupplierSearch: React.FC<{
  status?: string;
  setSupplierName: (name: string) => void;
  clearSupplierName: () => void;
  supplierName: string;
  isMatchedPayablesValue?: string;
}> = ({
  status,
  setSupplierName,
  clearSupplierName,
  supplierName,
  isMatchedPayablesValue,
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const handleSearch = (value: string) => {
    if (!value) {
      setOptions([]);
      clearSupplierName();
    }

    if (value.length < MIN_SEARCH_LENGTH) {
      return;
    }

    setLoading(true);
    api.payablesSummary
      .getAllPayablesSummary({
        page: 0,
        size: 5,
        fromTime,
        toTime,
        status: status !== 'ALL_ELIGIBLE' ? status : undefined,
        supplierName: `${value}:like`,
        isMatchedPayablesValue,
        hasCreditOrAmountOwing: false, // Include suppliers with no activity in the search results
      })
      .then((response) => {
        const { payablesSummaries } = response;
        const supplierNames = payablesSummaries.map(
          (summary) => summary.payablesSummary.supplierName
        );
        setOptions(supplierNames.map((name) => ({ label: name, value: name })));
      })
      .finally(() => setLoading(false));
  };

  const handleSelect = (value: string) => {
    setSupplierName(value);
  };

  return (
    <StyledAutoComplete
      allowClear
      onSearch={debounce(handleSearch, 1000)}
      onSelect={handleSelect}
      options={options}
      defaultValue={supplierName}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <StyledInput
        placeholder="Search"
        prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
        size="small"
      />
    </StyledAutoComplete>
  );
};

export default SupplierSearch;
