import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import CurrencyAmount from 'components/CurrencyAmount';
import { Typography } from 'antd';
import spacing from 'styles/layout/spacing';

const { Text } = Typography;

type ContainerProps = {
  $backgroundColor?: string;
};
const Container = styled.div<ContainerProps>`
  margin-bottom: ${spacing.gutter.sm};
  padding: 8px 15px;
  border-radius: 4px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.$backgroundColor && props.$backgroundColor};
`;

type TextProps = {
  $textColor?: string;
};
const Label = styled(Text)<TextProps>`
  font-size: 14px;
  color: ${(props) => (props.$textColor ? props.$textColor : colors.text)};
`;

const Value = styled(CurrencyAmount)<TextProps>`
  font-weight: bold;
  color: ${(props) => (props.$textColor ? props.$textColor : colors.text)};
`;

type Props = {
  label: string;
  value: number;
  color?: 'blue' | 'grey';
  className?: string;
};

const backgroundColorMap = {
  blue: colors.blues.lighter,
  grey: colors.greys100,
};

const textColorMap = {
  blue: colors.blues.dark,
  grey: colors.text,
};

const DataRow: React.FC<Props> = ({ label, value, color, className }) => {
  const backgroundColor = color ? backgroundColorMap[color] : undefined;
  const textColor = color ? textColorMap[color] : undefined;
  return (
    <Container $backgroundColor={backgroundColor} className={className}>
      <Label $textColor={textColor}>{label}</Label>
      <Value amount={value} $textColor={textColor} />
    </Container>
  );
};

export default DataRow;
