import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import api from 'api';
import AuthContainer from 'components/AuthContainer';
import AuthCard from 'components/AuthCard';
import InputItem from 'components/InputItem';
import { Button, Input } from 'antd';

const ResetPassword = ({ location }) => {
  const { token } = queryString.parse(location.search); // token denotes the token given in the url send to the email address of the user
  const [password, setPassword] = useState('');
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false); // result of the api call is success then it is true
  const [isValidPassword, setIsValidPassword] = useState(false); // result of validatePassword()
  const [visibleHelpText, setVisibleHelpText] = useState(false);
  const [passwordHelpText, setPasswordHelpText] = useState(
    'Password must be a minimum of 8 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
  );
  const [defaultApiError, setDefaultApiError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [apiCallWait, setApiCallWait] = useState(false);
  const validatePassword = (password) => {
    let hasLowerCase = 'one lowercase letter';
    let hasUpperCase = 'one uppercase letter';
    let hasNumber = 'one numeric digit';
    let hasLength = 'minimum of 8 characters';
    let hasSpecialChar = 'one special character';

    if (password.search(/[a-z]/) !== -1) {
      hasLowerCase = <strike>one lowercase letter</strike>;
    }
    if (password.search(/[A-Z]/) !== -1) {
      hasUpperCase = <strike>one uppercase letter</strike>;
    }
    if (password.search(/[!@#$%^&*]/) !== -1) {
      hasSpecialChar = <strike>one special character</strike>;
    }
    if (password.search(/\d/) !== -1) {
      hasNumber = <strike>one numeric digit</strike>;
    }
    if (password.length >= 8) {
      hasLength = <strike>minimum of 8 characters</strike>;
    }
    const helpText = (
      <p>
        Password be {hasLength} which contain at least {hasLowerCase},{' '}
        {hasUpperCase}, {hasNumber}, and {hasSpecialChar}
      </p>
    );
    setPasswordHelpText(helpText);
    if (
      password.search(/[a-z]/) !== -1 &&
      password.search(/[A-Z]/) !== -1 &&
      password.search(/[!@#$%^&*]/) !== -1 &&
      password.search(/\d/) !== -1 &&
      password.length >= 8
    ) {
      setIsValidPassword(true);
    }
    setPassword(password);
  };
  // handling the api call with payloads - 1.New password  2.token recieved via email
  const handleResetPasswordApiCall = () => {
    if (isValidPassword) {
      const passwordData = {
        password,
      };
      setApiCallWait(true);
      // api call
      api.auth
        .resetPasswordAfterToken({ token, passwordData })
        .then(() => {
          setPasswordChangeSuccess(true);
        })
        .then(() => {
          setPasswordChangeSuccess(true);
        })
        .catch((error) => {
          setApiCallWait(false);
          setApiError(error.response.data.message);
          if (!error.response.data.message) {
            setDefaultApiError(
              'Something went wrong, please try after sometime'
            );
          }
        });
    }
  };
  if (!passwordChangeSuccess) {
    return (
      <AuthContainer>
        <AuthCard
          message="Set Password"
          subMessage="Please enter your new password below"
        >
          <Helmet>
            <title>Reset password | Relay</title>
          </Helmet>
          <InputItem label="Password" error={apiError || defaultApiError}>
            <Input.Password
              type="password"
              placeholder="New password"
              value={password}
              onChange={(e) => {
                validatePassword(e.target.value);
              }}
              onFocus={() => setVisibleHelpText(true)}
            />
          </InputItem>
          {visibleHelpText && (
            <p style={{ marginTop: -5 }}>{passwordHelpText}</p>
          )}
          {apiError && (
            <p style={{ textAlign: 'left', color: 'red' }}>{apiError}</p>
          )}
          {defaultApiError && (
            <p style={{ textAlign: 'left', color: 'red' }}>{defaultApiError}</p>
          )}
          <Button
            size="large"
            onClick={handleResetPasswordApiCall}
            disabled={!isValidPassword}
            loading={apiCallWait}
            type="primary"
          >
            Submit
          </Button>
        </AuthCard>
      </AuthContainer>
    );
  } else {
    return <Redirect to="/resetpassword/success" />; // if the api successfully changed the password then redirecting to success screen
  }
};

export default ResetPassword;
