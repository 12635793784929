import React, { useEffect, useState } from 'react';
import api from 'api';
import PasswordHelpText from 'components/PasswordHelpText';
import PasswordValidationResults from 'types/PasswordValidationResults';
import { useSelector } from 'react-redux';
import { processError, validatePassword } from 'utils';
import { Form, Button, Input, Row, Col, notification } from 'antd';

const Password: React.FC = () => {
  const user = useSelector((state: any) => state.auth.user);
  const userId: string = user && user.id;

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showHelpText, setShowHelpText] = useState(false);
  const [validationResults, setValidationResults] =
    useState<PasswordValidationResults | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValidationResults(validatePassword(password));
  }, [password]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    setError('');

    if (!password) {
      setError('New password is required');
      return;
    }

    const isPasswordValid =
      validationResults?.hasLength &&
      validationResults?.hasLowerCase &&
      validationResults?.hasNumber &&
      validationResults?.hasSpecialChar &&
      validationResults?.hasUpperCase;
    if (!isPasswordValid) {
      setShowHelpText(true);
      setError('Password should match the criteria as shown below');
      return;
    }

    setLoading(true);
    setShowHelpText(false);

    const payload = {
      operation: 'replace',
      path: '/password',
      value: password,
    } as const;
    api.user
      .patch({ userId, payload })
      .then(() => {
        notification.success({ message: 'Password updated' });
        setShowHelpText(false);
      })
      .catch((error) => {
        const { message } = processError(error);
        if (
          typeof message === 'string' &&
          message.toLowerCase().includes('validation')
        ) {
          setError(message);
          setShowHelpText(true);
        } else {
          notification.error({ message });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Row>
      <Col md={8} xs={12}>
        <Form>
          <Form.Item
            name="password"
            validateStatus={error && 'error'}
            help={!!error && error}
          >
            <Input.Password
              value={password}
              onChange={handleChange}
              placeholder="New Password"
              onClick={() => setShowHelpText(true)}
              onKeyPress={handleKeyPress}
            />
          </Form.Item>
        </Form>
        {showHelpText && validationResults && (
          <PasswordHelpText validationResults={validationResults} />
        )}
        <Button type="primary" block onClick={handleSubmit} loading={loading}>
          Update Password
        </Button>
      </Col>
    </Row>
  );
};

export default Password;
