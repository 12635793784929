import React, { useState, useEffect } from 'react';
import { TitleSection } from 'components';
import PrimaryButton from 'pages/Verification/PrimaryButton';
import Footer from 'pages/Verification/Footer';
import Container from 'pages/Verification/Container';
import { History } from 'history';
import { LoadingIndicator, UserName } from 'components/UserVerification';
import { Button, Divider, Empty, notification } from 'antd';

import api from 'api';
import urls from 'urls';
import joinName from 'utils/joinName';
import processError from 'utils/processError';

import User from 'types/User';
import { Address } from 'types/Address';

type Props = {
  countryCode: Address['countryCode'];
  token: string;
  history: History;
  location: { state?: { lastOperatedOwnerId?: string } };
  loginAction: (o: object) => void;
  primaryOwner: User;
};

const VerificationList: React.FC<Props> = (props) => {
  const { token, history, location, loginAction, primaryOwner, countryCode } =
    props;
  const [loading, setLoading] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [owners, setOwners] = useState<User[]>([]);

  const lastOperatedOwnerId =
    location.state && location.state.lastOperatedOwnerId;

  useEffect(() => {
    api.identity
      .getBeneficialOwners({ token, countryCode })
      .then((owners: User[]) => {
        // We need to place relay user (ROLE_USER) at the top of the list.
        const relayUser = owners.find((o: User) =>
          o.roles.includes('ROLE_USER')
        );
        let otherUsers = owners;
        if (relayUser) {
          // Case when relay user is present.
          otherUsers = owners.filter((o: User) => o.id !== relayUser.id);
          // Placing relay user at the top of the list.
          setOwners([relayUser, ...otherUsers]);
        } else {
          // Case when relay user is not present.
          // This is not likely to happen. But handling it anyway.
          // Need no alteration on the array in this case.
          setOwners(owners);
        }
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message: 'Error',
          description: message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, countryCode]);

  const doLogin = (): void => {
    setLoggingIn(true);
    const { companyId } = primaryOwner;
    api.company
      .get({ token, companyId })
      .then((company) => {
        setLoggingIn(false);
        loginAction({
          user: { ...primaryOwner, token, company },
          loggedIn: true,
        });
        history.replace('/invoices');
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({
          message: 'Error',
          description: message,
        });
        setLoggingIn(false);
      });
  };

  const firstUnverifiedOwner = owners.find(
    (o: User) => o.verificationStatus === 'NOT_VERIFIED'
  );
  const firstVerificationFailedOwner = owners.find(
    (o: User) => o.verificationStatus === 'VERIFICATION_FAILED'
  );

  const onContinue = (): void => {
    if (firstUnverifiedOwner) {
      const { firstName, middleName, lastName } = firstUnverifiedOwner;
      const name = joinName({ firstName, middleName, lastName });
      const next = `${urls.verify.ownerDetails}`;
      history.push(next, { user: firstUnverifiedOwner, name });
    } else if (firstVerificationFailedOwner) {
      const { firstName, middleName, lastName } = firstVerificationFailedOwner;
      const name = joinName({ firstName, middleName, lastName });
      const next = `${urls.verify.ownerUpload}`;
      history.push(next, { user: firstVerificationFailedOwner, name });
    } else {
      doLogin();
    }
  };

  const title = 'Verify identity of owners and directors';
  const subTitle =
    'We need to verify the identity of your company directors and anyone who owns 25% or more of the company.';
  // const subTitle = isLoggedIn
  //   ? 'Please complete the verification for the individuals shown below'
  //   : 'We need to verify the identity of your company directors and anyone who owns 25% or more of the company.';

  return (
    <>
      <Container>
        <TitleSection title={title} subTitle={subTitle} />
        {loading ? (
          <LoadingIndicator />
        ) : owners.length ? (
          owners.map((owner: User) => {
            const { firstName, middleName, lastName, verificationStatus } =
              owner;
            const name = joinName({ firstName, middleName, lastName });
            let next = '';
            switch (verificationStatus) {
              case 'NOT_VERIFIED':
                next = `${urls.verify.ownerDetails}`;
                break;
              case 'VERIFIED':
                break;
              case 'VERIFICATION_FAILED':
                next = `${urls.verify.ownerUpload}`;
                break;
              case 'VERIFICATION_PENDING':
                break;
              default:
                break;
            }
            return (
              <UserName
                animatedIcon={
                  lastOperatedOwnerId ? lastOperatedOwnerId === owner.id : false
                }
                clickable={!!next}
                key={owner.id}
                name={name}
                onClick={() => {
                  next && history.push(next, { user: owner });
                }}
                roles={owner.roles}
                verificationStatus={verificationStatus}
              />
            );
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Container>
      <Divider />
      <Footer>
        {firstUnverifiedOwner || firstVerificationFailedOwner ? (
          <Button
            size="large"
            type="ghost"
            onClick={doLogin}
            disabled={loggingIn}
          >{`I'll do this later`}</Button>
        ) : null}
        <PrimaryButton
          onClick={onContinue}
          label={
            firstUnverifiedOwner || firstVerificationFailedOwner
              ? 'Continue'
              : 'Finish'
          }
          disabled={loggingIn}
        />
      </Footer>
    </>
  );
};

export default VerificationList;
