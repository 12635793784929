import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';

import SignupConfirmation from './SignupConfirmation';
import SignupEntry from './SignupEntry';
import ConnectAccount from './ConnectAccount';
import MyobAccount from './ConnectAccount/MyobAccount';
import MapAccounts from 'components/connectAccount/MapAccounts';
import LoadCompanyFile from 'components/connectAccount/LoadCompanyFile';
import AddBankAccount from 'components/connectAccount/AddBankAccount';
import AuthContainer from 'components/AuthContainer';
import { reCaptchaKey } from 'config';

const Signup = ({
  location,
  companyId,
  xeroRelayAccounts,
  myobAccount
}) => {

  const { tokenFromInviteLink, invitedUser } = (location && location.state) || {};

  const { step, connection } = location && queryString.parse(location.search);
  // The token to be passed to the component.
  // If token is available in URL, use that. Else, use token from store.
  // const tokenToUse =
  //   (location && queryString.parse(location.search).token) || token || invitedUser.token ;

  let Component;

  switch (step) {
    case 'signup-confirmation':
      Component = SignupConfirmation;
      break;
    case 'connect-account':
      Component = ConnectAccount;
      break;
    case 'map-accounts':
      Component = MapAccounts;
      break;
    case 'myob-company-file':
      Component = LoadCompanyFile;
      break;
    case 'final':
      Component = AddBankAccount;
      break;
    case 'connect-account-myob':
      Component = MyobAccount;
      break;
    default:
      Component = SignupEntry;
  }
  return (
    <AuthContainer>
    <Helmet>
      <title>Sign up | Relay</title>
    </Helmet>
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <Component
        companyId={companyId}
        xeroRelayAccounts={xeroRelayAccounts}
        myobConnect={connection === 'myob' ? true : false}
        myobAccount={myobAccount}
        tokenFromInviteLink={tokenFromInviteLink}
        invitedUser={invitedUser}
      />
    </GoogleReCaptchaProvider>
    </AuthContainer>
  );
};

const mapStateToProps = ({ signup, xero }) => ({
  companyId: signup.companyId,
  xeroRelayAccounts: xero.xeroRelayAccounts,
  myobAccount: signup.myobAccount
});

export default connect(mapStateToProps)(Signup);
