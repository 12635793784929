import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import { Button, ButtonProps } from 'antd';

const StyledButton = styled(Button)<{ $empty: boolean }>`
  font-size: 12px;
  color: ${colors.greys900}!important;
  padding: 0px 20px;
  height: 24px;
  background-color: ${({ $empty }) => $empty && 'transparent !important'};
  cursor: ${({ $empty }) => ($empty ? 'initial' : 'pointer')};
  :active {
    background-color: ${({ $empty }) =>
      $empty ? 'transparent !important' : `${colors.greys400}!important`};
  }
`;

const Action: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <StyledButton {...rest} $empty={children === '-'} type="link">
    {children}
  </StyledButton>
);

export default Action;
