import React from 'react';
import './LoadingAnimation.css';

const LoadingAnimation = ({ message }) => (
  <>
    <div className="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    {!!message && <span className="lds-message">{message}</span>}
  </>
);

export default LoadingAnimation;
