import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { connect } from 'react-redux';
import { notification, Tabs } from 'antd';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import PageTracker from 'components/PageTracker';
import featureFlags from 'config/featureFlags';
import { getPreferences } from 'store/entityPreferences';
import { setToolbarTitle } from 'store/ui';
import { updateUser, getCompany, refreshToken } from 'store/auth';
import { clearInvoices } from 'store/invoices';
import { hideWithdrawModal } from 'store/ui';
import { BASE_URL } from 'config';

import BusinessAccounts from './BusinessAccounts';
// import BankAccounts from './BankAccounts/components';
import Customers from './Customers';
import Password from './Password';
import Suppliers from './Suppliers';
import APAutomation from './APAutomation';
// import Offers from './Offers';

import { Withdrawals } from './BankAccounts/Withdrawals';

import api from 'api';
import processError from 'utils/processError';

const { TabPane } = Tabs;

const tabs = [
  'connected-apps',
  'withdrawals',
  'password',
  'customers',
  'suppliers',
  'ap-automation',
];

class Settings extends Component {
  constructor() {
    const showConnectedApps =
      window.env.FEATURE_FLAGS &&
      (window.env.FEATURE_FLAGS.XERO_CONNECT ||
        window.env.FEATURE_FLAGS.MYOB_CONNECT);

    super();
    this.defaultTab = showConnectedApps ? 'connected-apps' : 'withdrawals';
    this.state = {
      loading: false,
      loadingMyob: false,
      loadingMyobAccountRight: false,
      showConnectedApps,
      activeTab: this.defaultTab,
    };
  }

  componentDidMount() {
    const {
      user,
      setToolbarTitle,
      getCompany,
      getPreferences,
      hideWithdrawModal,
      refreshToken,
    } = this.props;

    const { companyId } = user;

    setToolbarTitle('Settings');
    getCompany(companyId);
    getPreferences({ entityId: companyId });
    hideWithdrawModal();
    refreshToken(user.token);
    const connectedToXero =
      user.company.company && user.company.company.connectedToXero;
    const connectedToMyobEssentials =
      user.company.company && user.company.company.connectedToMyobEssentials;

    const connectedToMyobAccountRight =
      user.company.company && user.company.company.connectedToMyobAccountRight;

    (connectedToMyobEssentials || connectedToMyobAccountRight) &&
      api.myob.triggerAccountsLoad({
        myobAccount: connectedToMyobEssentials ? 'Essentials' : 'Account Right',
      });
    connectedToXero && api.xero.triggerAccountsLoad();
  }

  handleBack = () => {
    this.props.closeSnackbar(this.key);
  };

  handleClick = () => {
    const { token, company } = this.props.user;
    this.setState({ loading: true });
    const url = company.company.connectedToXero
      ? `${BASE_URL}/xero/disconnect`
      : `${BASE_URL}/xero/connect`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (data) {
          const { authUrl } = data;
          window.location.assign(authUrl);
          this.setState({ loading: false });
        } else {
          this.props.updateUser({ connectedToXero: 'false' });
          this.props.clearInvoices();
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({ message });
        this.setState({ loading: false });
      });
  };

  myobHandleClick = (myobAccount) => {
    const { token, company } = this.props.user;
    const load =
      myobAccount === 'Essentials' ? 'loadingMyob' : 'loadingMyobAccountRight';
    this.setState({ [load]: true });
    if (
      company.company.connectedToMyobEssentials ||
      company.company.connectedToMyobAccountRight
    ) {
      const scope = company.company.connectedToMyobEssentials
        ? window.env.MYOB_ESSENTIALS_SCOPE
        : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
      axios
        .get(`${BASE_URL}/myob/disconnect?scope=${scope}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          this.props.updateUser({
            connectedToMyobEssentials: 'false',
            connectedToMyobAccountRight: 'false',
          });
          this.props.clearInvoices();
          this.setState({ [load]: false });
        })
        .catch((err) => {
          const { message } = processError(err);
          notification.error({ message });
          this.setState({ [load]: false });
        });
    } else {
      const MYOB_CLIENT_ID = window.env.MYOB_CLIENT_ID;
      const MYOB_REDIRECT_URI = window.env.MYOB_REDIRECT_URI;
      const MYOB_SCOPE =
        myobAccount === 'Essentials'
          ? window.env.MYOB_ESSENTIALS_SCOPE
          : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
      const myobAuthurl = `https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_CLIENT_ID}&redirect_uri=${MYOB_REDIRECT_URI}&response_type=code&scope=${MYOB_SCOPE}`;
      window.location.assign(myobAuthurl);
      this.setState({ [load]: false });
    }
  };

  handleTabChange = (tab) => {
    this.props.history.push(`/settings?tab=${tab}`);
  };

  render() {
    const { tab } =
      this.props.location && queryString.parse(this.props.location.search);
    let activeTab = this.defaultTab;
    if (tab && tabs.some((t) => t === tab)) {
      activeTab = tab;
    } else {
      this.props.history.push(`/settings?tab=${this.defaultTab}`);
    }
    const { company } = this.props.user.company;
    const { loading, loadingMyob, loadingMyobAccountRight } = this.state;
    return (
      <>
        <Helmet>
          <title>Settings | Relay</title>
        </Helmet>
        <PageTracker />
        <Tabs activeKey={activeTab} onChange={this.handleTabChange}>
          {this.state.showConnectedApps && (
            <TabPane tab="Connected Apps" key="connected-apps">
              <BusinessAccounts
                company={company}
                loading={loading}
                loadingMyob={loadingMyob}
                loadingMyobAccountRight={loadingMyobAccountRight}
                handleClick={this.handleClick}
                myobHandleClick={this.myobHandleClick}
                token={this.props.user.token}
              />
            </TabPane>
          )}

          <TabPane tab="Withdrawals" key="withdrawals">
            <Withdrawals />
          </TabPane>

          <TabPane tab="Password" key="password">
            <Password />
          </TabPane>

          {featureFlags.customerSupplierSettings && (
            <>
              <TabPane tab="Suppliers" key="suppliers">
                <Suppliers />
              </TabPane>

              <TabPane tab="Customers" key="customers">
                <Customers />
              </TabPane>
            </>
          )}

          {this.props.isRipeEnabled && (
            <TabPane tab="AP Automation" key="ap-automation">
              <APAutomation />
            </TabPane>
          )}

          {/* <TabPane tab="Offers" key="offers">
              <Offers />
            </TabPane> */}
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => ({
  user: auth.user,
  isRipeEnabled: settings?.isRipeEnabled,
});
const mapDispatchToProps = {
  setToolbarTitle,
  updateUser,
  getCompany,
  getPreferences,
  clearInvoices,
  hideWithdrawModal,
  refreshToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
