import axios from 'axios';

import BankAccount from 'types/BankAccount';
import {
  AccountCodeApiResponse,
  GetAccountCodesApiResponse,
} from 'types/AccountCode';
import { AdminBankAccountsResponse } from 'types/BankAccount';
import Company, { Preferences, Settings } from 'types/Company';
import {
  CounterParty,
  CounterPartiesApiResponse,
  CounterPartyWithLinks,
  ImportCounterpartyApiPayload,
  ImportCounterpartyDetailsApiResponse,
  MergeExecutionPlanDetails,
  MergeProgressApiResponse,
  PatchCounterPartyPayload,
} from 'types/CounterParty';

const counterParties = {
  getAll: ({
    counterPartyName,
    defaultProcessingQueue,
    entityId,
    glCodes,
    paymentTermIds,
    isScheduledPaymentsEnabled,
    page = 0,
    size = 10,
    status,
    statuses,
  }: {
    counterPartyName?: string;
    defaultProcessingQueue?: string;
    entityId?: string;
    glCodes?: string;
    paymentTermIds?: string;
    isScheduledPaymentsEnabled?: string;
    page?: number;
    size?: number;
    status?: string;
    statuses?: string;
  }) =>
    new Promise<CounterPartiesApiResponse>((resolve, reject) => {
      const url = `/admin/entities/${entityId}/counter-parties`;
      const params = {
        counterPartyName: counterPartyName
          ? encodeURIComponent(counterPartyName)
          : undefined,
        defaultProcessingQueue,
        glCodes,
        paymentTermIds,
        isScheduledPaymentsEnabled,
        page,
        size,
        status,
        statuses,
      };
      axios
        .get(url, { params })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),

  merge: ({
    rootCounterPartyEntityId,
    fromEntityId,
    toEntityId,
  }: {
    rootCounterPartyEntityId: string;
    fromEntityId: string;
    toEntityId?: string;
  }) =>
    new Promise<string>((resolve, reject) => {
      if (!toEntityId) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/merge`;
      const payload = { rootCounterPartyEntityId, fromEntityId, toEntityId };
      axios
        .post(url, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),

  getMergeExecutionPlanDetails: ({
    rootCounterPartyEntityId,
    fromEntityId,
    toEntityId,
  }: {
    rootCounterPartyEntityId: string;
    fromEntityId: string;
    toEntityId: string;
  }) =>
    new Promise<MergeExecutionPlanDetails>((resolve, reject) => {
      const url = '/admin/entities/merge/plan';
      if (!rootCounterPartyEntityId || !fromEntityId || !toEntityId) {
        reject('Required parameters missing');
      }
      const params = {
        rootCounterPartyEntityId,
        fromEntityId,
        toEntityId,
      };
      axios
        .get(url, { params })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),

  getMergeProgress: (mergeId: any) =>
    new Promise<MergeProgressApiResponse>((resolve, reject) => {
      const url = `/admin/entities/merge/${mergeId}`;
      axios
        .get(url)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),

  patchCounterParty: ({
    entityId,
    payload,
  }: {
    entityId: string;
    payload: PatchCounterPartyPayload;
  }) =>
    new Promise<CounterPartyWithLinks>((resolve, reject) => {
      if (!entityId || !payload) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/counter-parties/${payload.id}`;
      axios
        .patch(url, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),

  patchCounterParties: ({
    entityId,
    payload,
  }: {
    entityId: string;
    payload: PatchCounterPartyPayload[];
  }) =>
    new Promise<CounterParty[]>((resolve, reject) => {
      if (!entityId || !payload) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/counter-parties/`;
      axios
        .patch(url, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),

  getCounterParty: ({
    entityId,
    counterpartyId,
  }: {
    entityId: string;
    counterpartyId: string;
  }) =>
    new Promise<any>((resolve, reject) => {
      if (!entityId || !counterpartyId) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/counter-parties/${counterpartyId}`;
      axios
        .get(url)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }),
  importCounterParties: (payload: {
    entityId: string;
    counterPartiesForImport: ImportCounterpartyApiPayload[];
  }) =>
    new Promise<string>((resolve, reject) => {
      if (!payload.entityId || !payload.counterPartiesForImport) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${payload.entityId}/counter-parties/import`;
      axios
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  getImportCounterpartiesDetails: ({
    entityId,
    importId,
  }: {
    entityId: string;
    importId: string;
  }) =>
    new Promise<ImportCounterpartyDetailsApiResponse>((resolve, reject) => {
      if (!entityId || !importId) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/counter-parties/import/${importId}`;
      axios
        .get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }),
};

const entities = {
  getEntitySettings: ({ entityId }: any) => {
    return new Promise<Settings>((resolve, reject) => {
      if (!entityId) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/settings`;
      axios
        .get(url)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  patchEntitySettings: ({ entityId, payload }: any) => {
    return new Promise<Settings>((resolve, reject) => {
      if (!entityId || !payload) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/settings/${payload.id}`;
      axios
        .patch(url, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  getEntityPreference: ({ entityId }: any) => {
    return new Promise<Preferences>((resolve, reject) => {
      if (!entityId) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/preferences`;
      axios
        .get(url)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  patchEntityPreference: ({ entityId, payload }: any) => {
    return new Promise<Preferences>((resolve, reject) => {
      if (!entityId || !payload) {
        reject('Required parameters missing');
      }
      const url = `/admin/entities/${entityId}/preferences/${payload.id}`;
      axios
        .patch(url, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};

const dcFile = {
  get: () => {
    return new Promise<any>(function (resolve, reject) {
      axios
        .get('/admin/dcfile')
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  put: (payload: any) => {
    return new Promise<any>(function (resolve, reject) {
      const { id } = payload;
      axios
        .put(`/admin/dcfile/${id}`, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};

const topups = {
  get: ({ status, token, page, size }: any) => {
    return new Promise(function (resolve, reject) {
      let url = `/admin/topups?page=${page}&size=${size}`;
      if (status) {
        url = `/admin/topups?page=${page}&size=${size}&topupStatus=${status}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: token
              ? 'Bearer ' + token
              : axios.defaults.headers.common['Authorization'],
          },
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  put: (topup: any) => {
    return new Promise(function (resolve, reject) {
      const url = encodeURI(`/admin/topup/${topup.id}`);
      axios
        .put(url, topup)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },

  createTopup: ({ fundsAvailable, id }: any) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`/company/${id}/topup`, { topupAmount: fundsAvailable })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getCompanyName = ({ url, token }: any) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(url, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

type WithdrawBalanceParams = {
  entityId: Company['id'];
  payload: {
    bankAccountId: BankAccount['id'];
    withdrawalAmount: number;
  };
};

const withdrawBalance = ({ entityId, payload }: WithdrawBalanceParams) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/admin/entities/${entityId}/withdrawals`, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const bankAccounts = {
  get: ({
    status,
    page,
    size,
  }: {
    status?: BankAccount['bankAccountStatus'];
    page: number;
    size: number;
  }): Promise<AdminBankAccountsResponse> => {
    return new Promise(function (resolve, reject) {
      const params = {
        page,
        size,
        bankAccountStatus: status,
      };
      const url = `/admin/bankaccount`;
      axios
        .get(url, { params })
        .then(({ data }: { data: AdminBankAccountsResponse }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  put: (bankaccount: any) => {
    return new Promise<BankAccount>(function (resolve, reject) {
      const url = encodeURI(`/admin/bankaccount/${bankaccount.id}`);
      axios
        .put(url, bankaccount)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};

const offers = {
  getInvoice: (companyId: any) => {
    return new Promise(function (resolve, reject) {
      axios
        .get(`/invoice?type=INVOICE&companyId=${companyId}&page=0&size=1000`)
        .then(({ data }) => resolve(data.invoices))
        .catch((error) => reject(error));
    });
  },
  getAvailable: (companyId: any, token: any) => {
    return new Promise(function (resolve, reject) {
      axios
        .get(
          `/purchaseroffer/available?page=0&size=1000&companyId=${companyId}`,
          {
            headers: {
              Authorization: token
                ? 'Bearer ' + token
                : axios.defaults.headers.common['Authorization'],
            },
          }
        )
        .then(({ data }) => resolve(data.purchaserOfferResource))
        .catch((error) => reject(error));
    });
  },
};

const xeroAccounts = {
  get: ({
    entityId,
    isVisibleInRelay,
    size,
  }: {
    entityId: string;
    isVisibleInRelay?: boolean;
    size: number;
  }) => {
    return new Promise<GetAccountCodesApiResponse>(function (resolve, reject) {
      if (!entityId) {
        reject('Required parameters missing');
      }
      const params = {
        isVisibleInRelay:
          isVisibleInRelay !== undefined ? isVisibleInRelay : undefined,
        size,
      };
      axios
        .get(`/admin/entities/${entityId}/xero/accounts`, {
          params,
        })
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  },
  patch: ({
    entityId,
    payload,
  }: {
    entityId: string;
    payload: {
      accountId: string;
      updatedAt: number;
      value: boolean;
      path: string;
      operation: string;
    };
  }) => {
    return new Promise<AccountCodeApiResponse>(function (resolve, reject) {
      if (!entityId || !payload) {
        reject('Required parameters missing');
      }
      axios
        .patch(
          `/admin/entities/${entityId}/xero/accounts/${payload.accountId}`,
          payload
        )
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  },
  put: ({
    entityId,
    payload,
  }: {
    entityId: string;
    payload: AccountCodeApiResponse;
  }) => {
    return new Promise<AccountCodeApiResponse>(function (resolve, reject) {
      if (!entityId || !payload) {
        reject('Required parameters missing');
      }
      axios
        .put(
          `/admin/entities/${entityId}/xero/accounts/${payload.accountId}`,
          payload
        )
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  },
};

export default {
  counterParties,
  entities,
  dcFile,
  topups,
  bankAccounts,
  getCompanyName,
  withdrawBalance,
  offers,
  xeroAccounts,
};
