import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import styled from 'styled-components';

import colors from 'colors';
const { Text } = Typography;

const Container = styled.div``;
const Label = styled(Text)`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.greys600};
`;
const Name = styled(Input)`
  color: ${colors.greys700} !important;
  cursor: auto !important;
  padding: 13px 8px 13px 16px;
`;

type Props = {
  name: string;
};

const SupplierInfo: FC<Props> = ({ name }) => {
  return (
    <Container>
      <Label>SUPPLIER NAME</Label>
      <Name value={name} disabled={true} />
    </Container>
  );
};

export default SupplierInfo;
