import React, { useEffect, useState } from 'react';
import api from 'api';
import { Button, Form, Modal, notification } from 'antd';
import {
  AccountCodeFormItem,
  DefaultProcessingQueueFormItem,
  ModalHeader,
} from 'components';
import { processError } from 'utils';
import { CounterParty, PatchCounterPartyPayload } from 'types/CounterParty';
import { ProcessingQueue } from 'types/ProcessingQueue';
import { GlAccount } from 'types/AccountCode';

const PAGE_SIZE = 1000;

export const ConfigureCounterpartiesModal: React.FC<{
  counterParties: CounterParty[];
  entityId: string;
  onClose: () => void;
  isOpen: boolean;
  onSuccess: () => void;
}> = ({ counterParties, entityId, onClose, isOpen, onSuccess }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableCodes, setAvailableCodes] = useState<GlAccount[]>([]);
  const [form] = Form.useForm<{ queue: ProcessingQueue; code: string }>();

  useEffect(() => {
    setLoading(true);
    api.admin.xeroAccounts
      .get({
        entityId,
        size: PAGE_SIZE,
      })
      .then((data) => {
        const retrievedAccounts: GlAccount[] = data.accounts.map(
          ({ account }) => {
            const {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            } = account;
            return {
              accountId,
              accountCode,
              accountName,
              isVisibleInRelay,
              updatedAt,
              isDefault,
            };
          }
        );
        setAvailableCodes(
          retrievedAccounts.filter((account) => account.isVisibleInRelay)
        );
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [entityId]);

  const handleSubmit = () => {
    form.validateFields().then(({ code, queue }) => {
      setSubmitting(true);
      const payload: PatchCounterPartyPayload[] = [];
      if (queue) {
        counterParties.forEach((counterParty) => {
          payload.push({
            id: counterParty.id,
            modificationTime: counterParty.modificationTime,
            value: queue,
            path: '/processingQueue' as const,
            operation: 'replace' as const,
          });
        });
      }
      const selectedCode = availableCodes.find(
        ({ accountCode }) => accountCode === code
      );
      if (selectedCode) {
        counterParties.forEach((counterParty) => {
          payload.push({
            id: counterParty.id,
            modificationTime: counterParty.modificationTime,
            value: selectedCode,
            path: '/glAccount' as const,
            operation: 'replace' as const,
          });
        });
      }
      api.admin.counterParties
        .patchCounterParties({ entityId, payload })
        .then((data) => {
          const message = 'Success updated';
          const description = `${data.map(
            ({ counterParty }) => ' ' + counterParty.companyName
          )}`;
          notification.success({ message, description });
          form.resetFields();
          onSuccess();
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setSubmitting(false));
    });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      closable={false}
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{ loading: isSubmitting }}
      getContainer={false}
      visible={isOpen}
      title={
        <ModalHeader title="Configure Counterparties" onClose={handleClose} />
      }
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <DefaultProcessingQueueFormItem
          name="queue"
          label="Default Processing Queue"
        />
        <AccountCodeFormItem
          name="code"
          label="GL Code"
          loading={loading}
          placeholder={
            availableCodes.length === 0
              ? 'No GL codes have been set for this company'
              : undefined
          }
          disabled={availableCodes.length === 0}
          availableAccountCodes={availableCodes}
        />
      </Form>
    </Modal>
  );
};

export default ConfigureCounterpartiesModal;
