import React from 'react';
import { RadioChangeEvent } from 'antd';
import { RadioButtons } from 'ds';
import { AustraliaOptions as AustraliaOptionsType } from './types';

type Props = {
  selected: AustraliaOptionsType;
  onChange: (option: AustraliaOptionsType) => void;
};

export const AustraliaOptions: React.FC<Props> = ({ selected, onChange }) => {
  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };
  return (
    <RadioButtons
      options={[
        { label: 'Use PayID', value: AustraliaOptionsType.PAYID },
        {
          label: 'Use account number',
          value: AustraliaOptionsType.ACCOUNT_NUMBER,
        },
      ]}
      value={selected}
      onChange={handleChange}
    />
  );
};
