import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Divider } from 'antd';

import { AddressInput, InputItem, TitleSection } from 'components';
import CompanyName from 'components/SelectCompany';
import PrimaryButton from 'pages/Verification/PrimaryButton';
import Footer from 'pages/Verification/Footer';
import Container from 'pages/Verification/Container';
import api from 'api';
import processError from 'utils/processError';
import { login } from 'store/auth';
import { colors } from 'themes';
import Company, { SuggestedCompany } from 'types/Company';
import { Address } from 'types/Address';
import User from 'types/User';
import featureFlags from 'config/featureFlags';

const EditText = styled.span`
  font-size: 14px;
  color: ${colors.primary};
`;

const COMPANY_CONFIRMATION_ERROR =
  'Please confirm your change by clicking Done button';
const COMPANY_MISSING_ERROR =
  'Please select your company from the suggestions shown';
const ADDRESS_MISSING_ERROR = 'Plases select an address from the suggestions';
const WEBSITE_ERROR =
  'Please enter your business website address in correct format';

type Props = {
  acknowledgementText: string;
  company: Company;
  setCompany: (company: Company) => void;
  loginAction: (user: any) => void;
  token: string;
  user: User | null;
} & RouteComponentProps;

const BusinessVerification = ({
  acknowledgementText,
  company,
  history,
  setCompany,
  loginAction,
  token,
  user,
}: Props) => {
  const { companyName, registrationNumber } = company;
  const [newCompany, setNewCompany] = useState(company);
  const [isNewCompanySelected, setNewCompanySelected] = useState(true);
  const [address, setAddress] = useState<Address | null>(null);
  const [addressError, setAddressError] = useState('');
  const [website, setWebsite] = useState<string | null | undefined>('');
  const [companyError, setCompanyError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const countryCode = company.address?.country;

  useEffect(() => {
    setNewCompany(company);
    setWebsite(company.website);
  }, [company]);

  const onSelectCompany = (c: SuggestedCompany) => {
    setNewCompany({
      ...newCompany,
      nzbn: countryCode === 'AU' ? undefined : c.nzbn,
      businessNumber: c.businessNumber,
      companyName: c.companyName,
      registrationNumber: c.registrationNumber,
      industryCode: c.industryCode,
    });
    setCompanyError('');
    setNewCompanySelected(true);
  };

  const onDone = () => {
    if (!isNewCompanySelected && !companyName) {
      setCompanyError(COMPANY_MISSING_ERROR);
      return;
    }
    if (!isNewCompanySelected && companyName) {
      setCompanyError(COMPANY_MISSING_ERROR);
      return;
    }
    setLoading(true);
    api.company
      .put({ company: newCompany, token })
      .then(({ company }) => {
        setCompany(company);
        setEditing(false);
      })
      .catch((error) => {
        const { message } = processError(error);
        setCompanyError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    let hasErrors = false;
    if (!isNewCompanySelected && !companyName) {
      setCompanyError(COMPANY_MISSING_ERROR);
      hasErrors = true;
    }
    if (!isNewCompanySelected && companyName) {
      setCompanyError(COMPANY_MISSING_ERROR);
      hasErrors = true;
    }
    if (editing) {
      setCompanyError(COMPANY_CONFIRMATION_ERROR);
      hasErrors = true;
    }
    if (countryCode === 'AU' && !address) {
      setAddressError(ADDRESS_MISSING_ERROR);
      hasErrors = true;
    }
    if (website && website.indexOf('.') === -1) {
      setWebsiteError(WEBSITE_ERROR);
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    setLoading(true);
    const payload: Company = {
      ...newCompany,
      address: countryCode === 'AU' ? address : newCompany.address,
      acknowledgementText,
      acknowledgementTimestamp: Date.now().valueOf(),
      website,
    };
    api.company
      .put({ company: payload, token })
      .then((company) => {
        setCompany(company.company);
        loginAction({
          user: { ...user, token, company },
          loggedIn: true,
        });
        history.replace('/invoices');
      })
      .catch((error) => {
        const { message } = processError(error);
        setCompanyError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectAddress = (address: Address | null) => {
    setAddress(address);
    setAddressError('');
  };

  return (
    <>
      <Container>
        <TitleSection
          title="Confirm business details"
          subTitle="To continue please confirm your business details"
        />
        <InputItem
          label="Registered business name"
          error={
            companyError ||
            (!isNewCompanySelected && !companyName && COMPANY_MISSING_ERROR)
          }
        >
          <CompanyName
            countryCode={countryCode || undefined}
            selectedCompanyName={newCompany ? newCompany.companyName : ''}
            onInput={() => {
              setNewCompanySelected(false);
            }}
            onSelectCompany={onSelectCompany}
            style={{
              cursor: 'pointer',
            }}
            suffix={
              <EditText
                onClick={() => {
                  editing ? onDone() : setEditing(true);
                }}
              >
                {editing ? 'Done' : 'Edit'}
              </EditText>
            }
            disabled={!editing}
            error={''}
            placeholder="Search the companies register..."
          />
        </InputItem>
        <InputItem label="Registration number">
          <Input
            value={registrationNumber || undefined}
            style={{ backgroundColor: colors.greys100 }}
            disabled
            size="large"
          />
        </InputItem>
        {featureFlags.addressForVerification && countryCode === 'AU' && (
          <AddressInput
            countryCode={countryCode}
            error={addressError}
            label="Business address"
            onSelect={handleSelectAddress}
          />
        )}
        <InputItem label="Business website" error={websiteError}>
          <Input
            placeholder="e.g. http://www.ais.co.nz"
            onChange={(e) => {
              setWebsite(e.target.value);
            }}
            value={website || undefined}
            size="large"
          />
        </InputItem>
      </Container>
      <Divider />
      <Footer>
        <span className="footer-text">{acknowledgementText}</span>
        <PrimaryButton onClick={onSubmit} label="Confirm" loading={loading} />
      </Footer>
    </>
  );
};

const mapDispatchToProps = {
  loginAction: login,
};

export default withRouter(
  connect(null, mapDispatchToProps)(BusinessVerification)
);
