import React from 'react';
import styled from 'styled-components';
import AmountInput from 'components/AmountInput';
import AprInput from 'components/AprInput';

const Container = styled.div`
  padding: 10px 20px;
  padding-right: 0px;
  display: flex;
  width: 452px;
  height: 40px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.highlighted ? '#F6F7F8' : 'transparent'};
`;

const Label = styled.span`
  font-size: 14px;
`;

const Value = styled.span`
  font-size: ${(props) => (props.prominent ? '24px' : '16px')};
  font-weight: bold;
  margin-right: 10px;
`;

const Row = (props) => {
  const {
    label,
    value,
    highlighted,
    prominent,
    isVisibleEdit,
    inputValue,
    placeholder,
    onChange,
    multiline,
    crrInput,
    onBlur,
    editable,
  } = props;
  return (
    <Container highlighted={highlighted}>
      <Label>{label}</Label>
      {editable && isVisibleEdit ? (
        crrInput ? (
          <AprInput
            value={inputValue}
            placeholder={placeholder}
            onChange={onChange}
            multiline={multiline}
          />
        ) : (
          <AmountInput
            value={inputValue}
            placeholder={placeholder}
            onChange={onChange}
            multiline={multiline}
            onBlur={onBlur}
          />
        )
      ) : (
        !!value && (
          <Value prominent={prominent}>
            {value}
            {crrInput && <span style={{ fontSize: 14 }}> p.a.</span>}
          </Value>
        )
      )}
    </Container>
  );
};

export default Row;
