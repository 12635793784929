import React from 'react';
import styled from 'styled-components/macro';
import { Col, Input, Row, Select } from 'antd';
import { OrderNumberOperator } from 'types/RipeCompanyInvoice';
import { FilterItem } from 'ds';

const { Option } = Select;

const InputContainer = styled.div`
  padding: 7px 11px;
`;

const OrderNumber: React.FC<{
  orderNumber: string | null;
  isChecked: boolean;
  handleChangeOrderNumber: (value: string) => void;
  handleCheck: (flag: boolean) => void;
  handleChangeOperator: (operator: OrderNumberOperator) => void;
  operator: OrderNumberOperator;
}> = ({
  orderNumber,
  isChecked,
  handleChangeOrderNumber,
  handleCheck,
  handleChangeOperator,
  operator,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeOrderNumber(e.target.value);
  };

  return (
    <FilterItem
      label="Order Number"
      isChecked={isChecked}
      handleCheck={handleCheck}
    >
      {isChecked && (
        <InputContainer>
          <Row gutter={12}>
            <Col span={16}>
              <Select
                css={`
                  width: 100%;
                `}
                defaultValue="eq"
                onChange={handleChangeOperator}
                size="small"
                value={operator}
              >
                <Option value="eq">Exact match</Option>
                <Option value="contains">Contains</Option>
              </Select>
            </Col>
            <Col span={8}>
              <Input
                value={orderNumber || undefined}
                onChange={handleInputChange}
                size="small"
              />
            </Col>
          </Row>
        </InputContainer>
      )}
    </FilterItem>
  );
};

export default OrderNumber;
