import React from 'react';
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
} from 'antd';

import { NumberFormatValues } from 'react-number-format';
import { CurrencyInput } from 'components';
import { formatCurrency } from 'utils';
import moment from 'moment';
const { Text } = Typography;
const { Option } = Select;
type Props = {
  value?: string;
  label: string;
  name: string;
  warning?: string;
  className: string;
  format: 'currency' | 'string' | 'date' | 'option' | 'textarea';
  options?: string[];
  placeholder?: string;
  editing: boolean;
  form: FormInstance;
};

const InvoiceInput: React.FC<Props> = ({
  label,
  name,
  value,
  warning,
  className,
  format,
  options,
  placeholder,
  editing,
  form,
}) => {
  let formattedValue = '';
  switch (format) {
    case 'currency':
      formattedValue = formatCurrency(Number(value));
      break;
    case 'date':
      formattedValue = moment(value, 'YYYY-MM-DD').format('D MMM YYYY');
      break;
    case 'string':
    default:
      formattedValue = value || '';
      break;
  }

  return (
    <Form.Item name={name} label={label}>
      {!editing ? (
        <Text>{formattedValue ? formattedValue : '-'}</Text>
      ) : format === 'currency' ? (
        <CurrencyInput
          className={className}
          allowNegative={false}
          placeholder={placeholder}
          min={0.01}
          size="small"
          warning={warning}
          onValueChange={(values: NumberFormatValues) => {
            const { value } = values;
            form.setFieldsValue({ [name]: value });
          }}
        />
      ) : format === 'date' ? (
        <DatePicker
          className={className}
          disabledDate={(date) => date.isAfter(moment(), 'day')}
          format="DD MMM YYYY"
          showToday={false}
          style={{ width: '100%' }}
        />
      ) : format === 'option' && options ? (
        <Select
          allowClear
          className={className}
          placeholder={placeholder}
          showSearch
          size="small"
          style={{ width: '100%' }}
        >
          {options.map((item, idx) => {
            return (
              <Option key={idx} value={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      ) : (
        <Input className={className} placeholder={placeholder} size="small" />
      )}
    </Form.Item>
  );
};

export default InvoiceInput;
