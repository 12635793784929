import React, { useEffect, useState } from 'react';
import Company from 'types/Company';
import CurrencyAmount from 'components/CurrencyAmount';
import InputItem from 'components/InputItem';
import queryString from 'query-string';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageTracker from 'components/PageTracker';
import { RouteComponentProps } from 'react-router';
import { Col, Table, Tabs, Input, Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { setToolbarTitle } from 'store/ui';
import { getCompanies } from 'store/admin/companies';

const { TabPane } = Tabs;
const { Search } = Input;

const SearchTag = styled(Tag)`
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

type DispatchProps = {
  setToolbarTitle: (title: string) => void;
  getCompanies: ({
    size,
    page,
    token,
    status,
    companyName,
    verificationStatus,
  }: {
    size: number;
    page: number;
    token: string;
    status?: string;
    companyName?: string;
    verificationStatus?: string;
  }) => void;
};

type StateProps = {
  companies?: Company[];
  token: string;
  total: number;
  isLoading: boolean;
};

type Props = RouteComponentProps & StateProps & DispatchProps;

const Companies: React.FC<Props> = (props) => {
  const {
    companies,
    getCompanies,
    total,
    isLoading,
    token,
    setToolbarTitle,
    history,
    location,
  } = props;

  type QueryParams = {
    page?: number;
    size?: number;
    companyName?: string;
    verificationStatus?: string;
  };

  const {
    page = 1,
    size = 25,
    companyName = '',
    verificationStatus = '',
  }: QueryParams = queryString.parse(location.search, { parseNumbers: true });

  const [searchInput, setSearchInput] = useState(companyName);

  useEffect(() => {
    setToolbarTitle('Companies');
  }, [setToolbarTitle]);

  useEffect(() => {
    getCompanies({
      token,
      page: page - 1,
      size,
      companyName,
      verificationStatus,
    });
  }, [getCompanies, page, size, companyName, verificationStatus, token]);

  const onChangeTab = (activeKey: string): void => {
    const url = queryString.stringifyUrl(
      {
        url: '/companies',
        query: {
          page: '1',
          size: String(size),
          companyName,
          verificationStatus: activeKey === 'ALL' ? '' : activeKey,
        },
      },
      { skipNull: true, skipEmptyString: true }
    );
    history.push(url);
  };

  const onSearchCompany = (companyName: string): void => {
    const url = queryString.stringifyUrl(
      {
        url: '/companies',
        query: {
          page: '1',
          size: String(size),
          companyName,
          verificationStatus,
        },
      },
      { skipNull: true, skipEmptyString: true }
    );
    history.push(url);
  };

  const onPageChange = (page: number, pageSize: number | undefined) => {
    const url = queryString.stringifyUrl(
      {
        url: '/companies',
        query: {
          page: String(page),
          size: String(pageSize),
          companyName,
          verificationStatus,
        },
      },
      { skipNull: true, skipEmptyString: true }
    );
    history.push(url);
  };

  const onPageSizeChange = (_: number, size: number) => {
    const url = queryString.stringifyUrl(
      {
        url: '/companies',
        query: {
          page: '1',
          size: String(size),
          companyName,
          verificationStatus,
        },
      },
      { skipNull: true, skipEmptyString: true }
    );
    history.push(url);
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInput(e.target.value);

  const clearSearch = () => {
    setSearchInput('');
    onSearchCompany('');
  };

  const tabs = [
    { tab: 'All', key: 'ALL' },
    { tab: 'Not Verified', key: 'NOT_VERIFIED' },
    { tab: 'Partially Verified', key: 'PARTIALLY_VERIFIED' },
    { tab: 'Verification Pending', key: 'VERIFICATION_PENDING' },
    { tab: 'Verified', key: 'VERIFIED' },
  ];

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'NAME',
      render: (text: string, record: Company) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            history.push(`/companies/${record.id}`, { company: record });
          }}
          href={`/companies/${record.id}`}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Verification Status',
      dataIndex: 'verificationStatus',
      key: 'VERIFICATION_STATUS',
      render: (verificationStatus: string) => {
        if (verificationStatus === 'NOT_VERIFIED') {
          return 'Not verified';
        }
        if (verificationStatus === 'PARTIALLY_VERIFIED') {
          return 'Partially verified';
        }
        if (verificationStatus === 'VERIFICATION_PENDING') {
          return 'Verification pending';
        }
        if (verificationStatus === 'VERIFIED') {
          return 'Verified';
        } else {
          return 'Unknown';
        }
      },
    },
    {
      title: 'Funds Available',
      dataIndex: 'fundsAvailable',
      key: 'FUNDS_AVAILABLE',
      render: (fundsAvailable: number) => (
        <CurrencyAmount
          amount={fundsAvailable}
          style={{ textAlign: 'right' }}
        />
      ),
    },
    {
      title: 'Registration Number',
      dataIndex: 'registrationNumber',
      key: 'REG_NUMBER',
    },
  ];

  return (
    <div style={{ paddingTop: 20 }}>
      <Helmet>
        <title>Companies | Relay</title>
      </Helmet>
      <PageTracker />
      <Col lg={10}>
        <InputItem label="Search company by name">
          <Search
            placeholder="Enter the search term"
            enterButton="Search"
            size="large"
            value={searchInput}
            onChange={handleChangeSearchInput}
            onSearch={onSearchCompany}
          />
        </InputItem>
        {companyName && (
          <SearchTag style={{}} color="processing">
            {`Results for ${companyName}`}
            <CloseCircleOutlined
              style={{ fontSize: '18px' }}
              onClick={clearSearch}
            />
          </SearchTag>
        )}
      </Col>
      <Tabs
        animated={false}
        activeKey={verificationStatus || 'ALL'}
        onChange={onChangeTab}
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={companies}
              pagination={{
                current: page,
                pageSize: size,
                total,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onShowSizeChange: onPageSizeChange,
                onChange: onPageChange,
              }}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

const mapStateToProps = ({ auth, admin }: any): StateProps => ({
  token: auth.user?.token,
  companies: admin.companies.companies,
  total: admin.companies.total,
  isLoading: admin.companies.isLoading,
});

const mapDispatchToProps: DispatchProps = {
  setToolbarTitle,
  getCompanies,
};

export default connect<StateProps, DispatchProps, RouteComponentProps>(
  mapStateToProps,
  mapDispatchToProps
)(Companies);
