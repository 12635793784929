import React, { useState } from 'react';
import BankAccount from 'types/BankAccount';
import styled from 'styled-components/macro';
import ConfirmAccountActivation from './ConfirmAccountActivation';
import { Button, Skeleton } from 'antd';
import { showWithdrawModal } from 'store/ui';
import { showTopUpWindow } from 'store/auth';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type RootState = {
  bankAccounts: {
    bankAccounts: BankAccount[];
    isLoading: boolean;
  };
};

const BankAccountActions: React.FC<{ className?: string }> = ({
  className,
}) => {
  const dispatch = useDispatch();

  const bankAccounts = useSelector((store: RootState) => store.bankAccounts);

  const isLoading = bankAccounts.isLoading;

  const hasRelayAccount = bankAccounts.bankAccounts.some(
    (account) => account.loadedBy === 'SYSTEM'
  );

  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState(false);

  const showConfirmationModal = () => setConfirmationModalVisible(true);

  const hideConfirmationModal = () => setConfirmationModalVisible(false);

  if (isLoading) {
    return (
      <Container className={className}>
        <Skeleton.Button
          active
          css={`
            margin-right: 10px;
          `}
        />
        <Skeleton.Button active />
      </Container>
    );
  }
  if (hasRelayAccount) {
    return (
      <Container className={className}>
        <Button
          css={`
            margin-right: 10px;
          `}
          onClick={() => dispatch(showWithdrawModal())}
          type="ghost"
        >
          Withdraw
        </Button>
        <Button
          onClick={() =>
            dispatch(
              showTopUpWindow({
                trigger: 'CUSTOM',
                triggerAmount: null,
                crr: undefined,
                selectedInvoice: undefined,
              })
            )
          }
          type="primary"
        >
          Topup
        </Button>
      </Container>
    );
  } else {
    return (
      <Container className={className}>
        <Button onClick={showConfirmationModal} type="primary">
          Activate Account
        </Button>
        {isConfirmationModalVisible && (
          <ConfirmAccountActivation onClose={hideConfirmationModal} />
        )}
      </Container>
    );
  }
};

export default BankAccountActions;
