import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Modals from './Modals';
import AppLayout from 'components/AppLayout';
import BackgroundCheckPurhcaserOffer from 'components/BackgroundCheckPurhcaserOffer';
import ConnectingLine from 'components/ConnectingLine';

import Home from 'pages/Home';
import Invoices from 'pages/Invoices';
import Bills from 'pages/Bills';
import RipeBills from 'pages/RipeBills';
import InvoiceReview from 'pages/InvoiceReview';
import CreateInvoice from 'pages/CreateInvoice';
import Account from 'pages/Account';
import Dashboard from 'pages/Dashboard';
import Topups from 'pages/Topups';
import BankAccounts from 'pages/BankAccounts';
import CompanyBankAccounts from 'pages/BankAccounts/CompanyBankAccounts';
import Settings from 'pages/Settings';
import XeroConfig from 'pages/Settings/XeroConfig';
import MyobCallback from 'pages/MyobCallback';
import Welcome from 'pages/Welcome';
import Companies from 'pages/Companies';
import CompanyDetails from 'pages/Companies/CompanyDetails';
import VerifyOwner from 'pages/Companies/CompanyDetails/VerifyOwner';
import System from 'pages/System';
import Verification from 'pages/Verification';
import LoggedIn401 from 'pages/ErrorPages/LoggedIn401';
// import Statements from 'pages/Statements';
// import StatementDetails from 'pages/StatementDetails';
import SupplierDetails from 'pages/SupplierDetails';
import { RipeType } from 'types/RipeCompanyInvoice';

require('config/apiInterceptor');

const adminPaths = [
  { path: '/', Component: Topups, key: 'TOPUPS' },
  { path: '/bank-accounts', Component: BankAccounts, key: 'BANK_ACCOUNTS' },
  {
    path: '/companies/:companyId/bank-accounts',
    Component: CompanyBankAccounts,
    key: 'COMPANY_BANK_ACCOUNTS',
  },
  { path: '/companies', Component: Companies, key: 'COMPANIES' },
  {
    path: '/companies/:id',
    Component: CompanyDetails,
    key: 'COMPANY_DETAILS_BASE',
  },
  {
    path: '/companies/:id/:menu',
    Component: CompanyDetails,
    key: 'COMPANY_DETAILS',
  },
  {
    path: '/companies/:id/verify/:userName',
    Component: VerifyOwner,
    key: 'VERIFY_OWNER',
  },
  { path: '/system', Component: System, key: 'SYSTEM' },
  { path: '/live-offers', Component: Companies, key: 'LIVE_OFFERS' },
];

const userPaths = [
  { path: '/', Component: Home, key: 'HOME' },
  { path: '/account', Component: Account, key: 'ACCOUNT' },
  { path: '/dashboard', Component: Dashboard, key: 'Dashboard' },
  { path: '/invoices', Component: Invoices, key: 'Invoices' },
  { path: '/bills', Component: Bills, key: 'BILLS' },
  {
    path: '/bills/pending-review',
    Component: RipeBills,
    key: 'BILLS_PENDING_REVIEW',
    ripePageType: RipeType.PENDING_REVIEW,
  },
  {
    path: '/bills/pending-approval',
    Component: RipeBills,
    key: 'BILLS_PENDING_APPROVAL',
    ripePageType: RipeType.PENDING_APPROVAL,
  },
  {
    path: '/bills/processing',
    Component: RipeBills,
    key: 'BILLS_PROCESSING',
    ripePageType: RipeType.PROCESSING,
  },
  {
    path: '/bills/variances',
    Component: RipeBills,
    key: 'BILLS_VARIANCES',
    ripePageType: RipeType.VARIANCE,
  },
  {
    path: '/bills/blocked',
    Component: RipeBills,
    key: 'BILLS_BLOCKED',
    ripePageType: RipeType.BLOCKED,
  },
  // Not removing the next item, anticipating that it may be needed in the future
  // {
  //   path: '/bills/unmatched',
  //   Component: RipeBills,
  //   key: 'BILLS_UNMATCHED',
  //   ripePageType: 'UNMATCHED',
  // },
  { path: '/settings', Component: Settings, key: 'SETTINGS' },
  { path: '/myob/callback', Component: MyobCallback, key: 'MYOB_CALLBACK' },
  //The following route is a quick fix to address the issue of chart of accounts
  //screen not shown after connecting to Xero. This needs to be refactored.
  { path: '/signup', Component: Settings, key: 'SIGNUP' },
  {
    path: '/settings/connect-business-account',
    Component: XeroConfig,
    key: 'XERO_CONFIG',
  },
  {
    path: '/create-new-invoice',
    Component: CreateInvoice,
    key: 'CREATE_INVOICE',
  },
  { path: '/welcome', Component: Welcome, key: 'WELCOME' },
  // { path: '/bills/statements', Component: Statements, key: 'STATEMENTS' },
  // {
  //   path: '/bills/statements/:id',
  //   Component: StatementDetails,
  //   key: 'STATEMENT_DETAILS',
  // },
  {
    path: '/suppliers/:supplierId',
    Component: SupplierDetails,
    key: 'SUPPLIER_DETAILS',
  },
];

const commonPaths = [
  { path: '/logout', Component: LoggedIn401, key: 'LOGOUT' },
];

const AppRouter = () => {
  const history = useHistory();
  const location = useLocation();

  const title = useSelector((store) => store.ui.toolbarTitle);
  const user = useSelector((store) => store.auth.user);
  const coordinates = useSelector((store) => store.ripeDraw.coordinates);

  const isAdmin = user.roles && user.roles[0] === 'ROLE_ADMIN' ? true : false;
  const { pathname } = location;
  const { token } = user;

  let paths = isAdmin ? adminPaths : userPaths;
  paths = [...paths, ...commonPaths];

  return (
    <>
      {coordinates ? <ConnectingLine /> : null}
      <BackgroundCheckPurhcaserOffer />
      <Modals history={history} />
      <Switch>
        {/* The following routes are to be rendered outside the main app layout */}
        {!isAdmin && (
          <Route
            path="/verify"
            render={({ history, location, match }) => (
              <Verification
                history={history}
                isLoggedIn={true}
                location={location}
                match={match}
                token={token}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/r/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.AUTHORISED}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/pending-review/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.PENDING_REVIEW}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/pending-approval/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.PENDING_APPROVAL}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/processing/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.PROCESSING}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/blocked/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.BLOCKED}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/unmatched/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.UNMATCHED}
              />
            )}
          />
        )}
        {!isAdmin && (
          <Route
            path="/bills/variances/:id"
            render={({ history, location, match }) => (
              <InvoiceReview
                history={history}
                location={location}
                match={match}
                type={RipeType.VARIANCE}
              />
            )}
          />
        )}
        {isAdmin && (
          <Route
            path="/companies/:id/verify-owner/"
            render={({ history, location }) => (
              <VerifyOwner
                history={history}
                location={location}
                token={token}
              />
            )}
          />
        )}
        <AppLayout
          isAdmin={isAdmin}
          pathname={pathname}
          title={title}
          history={history}
        >
          <Switch>
            {paths.map(({ key, path, Component, ...rest }) => (
              <Route
                exact
                key={key}
                path={path}
                render={(routeProps) => <Component {...routeProps} {...rest} />}
              />
            ))}
            <Redirect to="/" />
          </Switch>
        </AppLayout>
      </Switch>
    </>
  );
};

export default AppRouter;
