import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import spacing from 'styles/layout/spacing';

const { Title, Text } = Typography;

const Container = styled.div`
  margin-bottom: ${spacing.gutter.lg};
  text-align: center;
`;
const StyledTitle = styled(Title)<{ $hasSubTitle?: boolean }>`
  margin-bottom: ${(props) => (props.$hasSubTitle ? 'unset' : '0 !important')};
`;

type Props = {
  title: string;
  subTitle?: string;
};

const TitleSection: React.FC<Props> = ({ title, subTitle }) => (
  <Container>
    <StyledTitle level={4} $hasSubTitle={!!subTitle}>
      {title}
    </StyledTitle>
    {subTitle && <Text>{subTitle}</Text>}
  </Container>
);

export default TitleSection;
