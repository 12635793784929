import React from 'react';
import styled from 'styled-components';
import breakpoints from 'styles/layout/breakpoints';
import spacing from 'styles/layout/spacing';
import colors from 'colors';

const Container = styled.div`
  padding: ${spacing.gutter.xs} ${spacing.gutter.sm};
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.greys200};
  @media screen and (max-width: ${breakpoints.mobile}) {
    margin-bottom: ${spacing.gutter.sm};
  }
`;

const TitleText = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${colors.greys700};
`;

type Props = {
  title?: string;
  subTitle?: string | JSX.Element;
};

export const Title: React.FC<Props> = (props) => {
  const { title, subTitle } = props;
  return (
    <Container>
      <TitleText>{title}</TitleText>
      {!!subTitle && typeof subTitle === 'string' && <span>{subTitle}</span>}
      {!!subTitle && typeof subTitle !== 'string' && subTitle}
    </Container>
  );
};
