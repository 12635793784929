import React, { FC, useEffect, useState } from 'react';
import api from 'api';
import styled from 'styled-components';
import colors from 'colors';

import CreditNoteDetails from 'components/CreditNoteDetails';
import CreditNote, { AllocationType } from 'types/CreditNote';
import { Modal, Button, notification } from 'antd';
import { ModalHeader } from 'components';
import { processError } from 'utils';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding-top: 24px !important;
  }
  .credit-note {
    margin-top: 24px;
  }
`;

const FooterButton = styled(Button)`
  border-color: ${colors.blues.dark};
  color: ${colors.blues.dark};
  margin-top: 14px;
  min-width: 80px;
  :hover {
    background: ${colors.blues.lighter};
  }
  :active {
    background: #d6efff;
  }
`;

// type definitions
export type InvoiceAllocation = AllocationType & {
  amountDue: number;
  invoiceNumber: string;
};

type Props = {
  id: string;
  isOpen: boolean;
  closeModal: () => void;
  footerButtonLabel?: string;
  invoiceType: 'BILL' | 'INVOICE';
  refreshTableData: () => void;
};

const ViewCreditNote: FC<Props> = ({
  id,
  isOpen,
  closeModal,
  footerButtonLabel,
  invoiceType,
  refreshTableData,
}) => {
  const [creditNote, setCreditNote] = useState<CreditNote | null>(null);

  useEffect(() => {
    if (id) {
      api.creditNotes
        .get(id)
        .then((data) => setCreditNote(data))
        .catch((err) => {
          const { message } = processError(err);
          notification.error({ message });
          closeModal();
        });
    }
  }, [id, closeModal]);

  const hideModal = () => {
    closeModal();
  };

  return (
    <StyledModal
      title={
        <ModalHeader
          title={`Credit Note #${creditNote ? creditNote.creditNumber : ''}`}
          onClose={hideModal}
        />
      }
      closable={false}
      visible={isOpen}
      style={{ top: 60 }}
      bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
      footer={[
        <FooterButton key="close" onClick={hideModal}>
          {footerButtonLabel || 'Close'}
        </FooterButton>,
      ]}
      data-testid="view-creditnote"
    >
      {!!creditNote && (
        <CreditNoteDetails invoiceType={invoiceType} creditNote={creditNote} />
      )}
    </StyledModal>
  );
};

export default ViewCreditNote;
