import React from 'react';
import styled from 'styled-components/macro';
import { Tag, Tooltip, Typography } from 'antd';

const { Text } = Typography;

const StatusText = styled(Text)`
  font-size: 10px;
`;

type Props = {
  status: string;
  isNotEligible: boolean;
  notEligibleReason: string;
};

const Status: React.FC<Props> = ({
  status,
  isNotEligible,
  notEligibleReason,
}) => {
  if (isNotEligible) {
    return (
      <Tooltip title={notEligibleReason}>
        <StatusText
          css={`
            text-decoration: underline dotted;
          `}
        >
          {status}
        </StatusText>
      </Tooltip>
    );
  }

  const highlightStatus = status.startsWith('SAVE');

  return highlightStatus ? (
    <Tag>{status}</Tag>
  ) : (
    <StatusText>{status}</StatusText>
  );
};

export default Status;
