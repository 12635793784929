import React from 'react';
import { Form, Select, SelectProps } from 'antd';
import { GlAccount } from 'types/AccountCode';
import { FormItemProps } from 'antd/lib/form';

const { Option } = Select;

type Props = {
  availableAccountCodes: GlAccount[];
};

const AccountCodeFormItem: React.FC<
  Props & SelectProps<GlAccount> & FormItemProps
> = ({
  name,
  defaultValue,
  availableAccountCodes,
  label,
  placeholder,
  size,
  rules,
  loading,
  disabled,
}) => {
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Select
        allowClear
        className="account-code-select"
        loading={loading}
        disabled={disabled}
        placeholder={placeholder}
        showSearch
        size={size}
        defaultValue={
          defaultValue
            ? `${defaultValue.accountName} - ${defaultValue.accountCode}`
            : undefined
        }
        filterOption={(input, option) => {
          const matchingAccount = availableAccountCodes.find(
            (item) => item.accountCode === option?.value
          );
          return matchingAccount
            ? matchingAccount.accountCode.includes(input) ||
                matchingAccount?.accountName
                  .toString()
                  .toLowerCase()
                  .includes(input.toString().toLowerCase())
            : false;
        }}
        filterSort={(optionA, optionB) =>
          optionA
            .toString()
            .toLowerCase()
            .localeCompare(optionB.toString().toLowerCase())
        }
      >
        {availableAccountCodes.map(({ accountCode, accountName }) => {
          return (
            <Option key={accountCode} value={accountCode}>
              {accountName} - {accountCode}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default AccountCodeFormItem;
