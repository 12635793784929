import React from 'react';
import AuthContainer from 'components/AuthContainer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PageTracker from 'components/PageTracker';
import AuthCard from 'components/AuthCard';

const Error500: React.FC = () => {
  return (
    <AuthContainer>
      <AuthCard
        message="Uh oh :("
        subMessage="Something went wrong. But not due to you."
        showWarningIcon
      >
        <PageTracker />
        <Button type="primary" size="large">
          <Link to="/">Go to home page</Link>
        </Button>
      </AuthCard>
    </AuthContainer>
  );
};
export default Error500;
