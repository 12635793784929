import React from 'react';
import styled from 'styled-components';
import { arrowRight } from 'assets/images';
import { Typography } from 'antd';

const { Text } = Typography;

const CompaniesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 19px;
`;

type CompanyProps = {
  $first?: boolean;
  $second?: boolean;
};

const Company = styled(Text)<CompanyProps>`
  font-size: 14px;
  font-weight: bold;
  ${(props) => props.$first && 'margin-right: 10px'}
  ${(props) => props.$second && 'margin-left: 10px'}
`;

type Props = {
  purchaserCompanyName: string;
  supplierCompanyName: string;
};

const PaymentDirectionIndicator: React.FC<Props> = ({
  purchaserCompanyName,
  supplierCompanyName,
}) => {
  return (
    <CompaniesContainer>
      <Company $first>{purchaserCompanyName}</Company>
      <img src={arrowRight} alt="" />
      <Company $second>{supplierCompanyName}</Company>
    </CompaniesContainer>
  );
};

export default PaymentDirectionIndicator;
