import React from 'react';
import { CompanyStatus } from 'types/Company';
import { Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const Email: React.FC<{
  email: string;
  status: CompanyStatus;
  onClick: () => void;
}> = ({ email, status, onClick }) => {
  if (email && status === 'INACTIVE') {
    return (
      <Tooltip title={email}>
        <MailOutlined onClick={onClick} />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default Email;
