import Company, { CompanyStatus } from 'types/Company';

const findOtherCompanyStatus = ({
  loggedInCompanyId,
  otherCompanyStatus,
  invitedBy,
}: {
  loggedInCompanyId: Company['id'];
  otherCompanyStatus: CompanyStatus;
  invitedBy: Company['invitedBy'];
}): CompanyStatus => {
  if (otherCompanyStatus === 'INVITED') {
    if (!invitedBy) {
      return 'INACTIVE';
    }
    const isInvitedByLoggedInCompany = invitedBy.some(
      (companyId) => companyId === loggedInCompanyId
    );
    if (isInvitedByLoggedInCompany) {
      return 'INVITED';
    } else {
      return 'INACTIVE';
    }
  }
  return otherCompanyStatus;
};

export default findOtherCompanyStatus;
