import React from 'react';
import moment, { Moment } from 'moment';
import { Form, DatePicker, DatePickerProps } from 'antd';
import styled from 'styled-components';
import colors from 'colors';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-form-item-label > label {
    font-size: 14px;
  }
  .ant-picker {
    width: 150px;
    display: block;
    margin-left: auto;
    &-input {
      display: flex;
      flex-direction: row-reverse;
    }
    &-suffix {
      margin-left: 0;
      margin-right: 4px;
      color: ${colors.text};
    }
  }
  .ant-form-item-control-input-content {
    text-align: right;
  }
`;
type Props = {
  label?: string;
  date: Moment | null;
  disabled?: boolean;
  disabledDate?: DatePickerProps['disabledDate'];
  onChange: (date: Moment | null) => void;
  isEditing: boolean;
  error?: string | null;
  fromCreateInvoice?: boolean;
  placeholder?: string;
  testId?: string;
};

const PaymentDueEntry: React.FC<Props> = ({
  label,
  date,
  disabled,
  disabledDate,
  onChange,
  isEditing,
  error,
  placeholder,
  testId,
}) => {
  const value = moment(date, 'DD MMM YYYY');

  return (
    <StyledFormItem
      label={label}
      colon={false}
      validateStatus={error ? 'error' : undefined}
    >
      {isEditing ? (
        <DatePicker
          allowClear={false}
          disabled={disabled}
          format="DD/MM/YYYY"
          value={date ? value : undefined}
          placeholder={placeholder}
          onChange={(date) => onChange(date)}
          disabledDate={disabledDate}
          data-testid={testId}
        />
      ) : (
        date?.format('DD MMM YYYY')
      )}
    </StyledFormItem>
  );
};

export default PaymentDueEntry;
