import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  notification,
  Form,
  Button,
  Input,
  Collapse,
  Tooltip,
} from 'antd';
import { RouteComponentProps } from 'react-router';
import ViewInfo from './ViewInfo';
import api from 'api';
import styled from 'styled-components';
import {
  getFormattedDateString,
  getReasonFromMatchStatus,
  processError,
  shouldLockDocument,
} from 'utils';
import RipeCompanyInvoice, {
  AuthorisedInvoice,
  RipeType,
} from 'types/RipeCompanyInvoice';

import { InvoiceContext, InvoiceContextProps } from './InvoiceReview';
import InvoiceInput from './InvoiceInput';
import { useSelector } from 'react-redux';
import { CounterpartySearch, InputItem } from 'components';
import GlCodeAllocationInput from './GlCodeAllocationInput';
import { GlCodeAllocation } from 'types/RipeCompanyInvoice';
import spacing from 'styles/layout/spacing';
import { CloseOutlined } from '@ant-design/icons';
import colors from 'colors';
import Text from 'antd/lib/typography/Text';
import moment, { Moment } from 'moment';
import { TrackingCategory } from 'types/TrackingCategory';

const { Panel } = Collapse;

const EditPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px - 2 * ${spacing.gutter.lg});
  row-gap: ${spacing.gutter.lg};
  .input-item,
  .ant-form-item {
    margin-bottom: ${spacing.gutter.sm};
    &:last-child {
      margin-bottom: 0;
    }
  }
  .input-item {
    &__label {
      font-size: 14px;
    }
  }
  .ant-form-item {
    &-label {
      padding: 0;
      > label {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .ant-form-item-control-input {
    min-height: 24px;
  }
`;
const FormContainer = styled(Card)`
  flex: 0 1 auto;
  overflow: auto;
  .ant-card-head {
    position: sticky;
    top: 0;
    z-index: 2;
    min-height: unset;
  }
`;
const EditButtonGroup = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
  right: ${spacing.gutter.md};
  column-gap: ${spacing.gutter.sm};
`;
const StyledCollapse = styled(Collapse)`
  background-color: ${colors.white};
  position: relative;
  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-content-box {
    padding: 16px 24px;
  }
  .edit-button-group {
    top: 15px;
  }
`;

declare global {
  interface Window {
    pendingApproval: any;
  }
}

type InvoiceFormData = {
  supplierName: RipeCompanyInvoice['supplierName'];
  invoiceNumber: RipeCompanyInvoice['invoiceNumber'];
  orderNumber: RipeCompanyInvoice['orderNumber'];
  invoiceAmount: RipeCompanyInvoice['invoiceAmount'];
  issueDate: Moment;
};

type AdditionalFormData = {
  glCodeAllocation: RipeCompanyInvoice['lineItems'];
  storeCode: RipeCompanyInvoice['storeCode'];
  userNotes: RipeCompanyInvoice['userNotes'];
};

const pendingApproval = window.pendingApproval;

type Props = {
  onUpdate: () => void;
  posAmount: RipeCompanyInvoice['posAmount'];
  matchStatus: RipeCompanyInvoice['matchStatus'];
  documentId: RipeCompanyInvoice['documentId'];
  document: RipeCompanyInvoice | null;
  setDocument: (document: RipeCompanyInvoice) => void;
  loading: boolean;
  type: RipeType;
  editingConfirmedBill: boolean;
  setEditingConfirmedBill: (flag: boolean) => void;
  history: RouteComponentProps['history'];
  shouldShowSupplierName: boolean;

  authorisedInvoice: AuthorisedInvoice | null;
};

const EditInfo: React.FC<Props> = ({
  onUpdate,
  posAmount,
  matchStatus,
  documentId,
  document,
  setDocument,
  type,
  loading,
  children,
  editingConfirmedBill,
  history,
  shouldShowSupplierName,

  authorisedInvoice,
}) => {
  const [invoiceForm] = Form.useForm<InvoiceFormData>();
  const [additionalForm] = Form.useForm<AdditionalFormData>();
  const editValues: InvoiceContextProps = useContext(InvoiceContext);

  const [updatingAdditionalData, setUpdatingAdditionalData] = useState(false);
  const [updatingInvoiceData, setUpdatingInvoiceData] = useState(false);
  const [editingInvoice, setEditingInvoice] = useState(false);
  const [editingAdditional, setEditingAdditional] = useState(false);
  const [glCodeAllocation, setGlCodeAllocation] = useState<GlCodeAllocation>(
    []
  );

  const [trackingCategories, setTrackingCategories] = useState<
    TrackingCategory['optionCode'][]
  >([]);

  const user = useSelector((store: any) => store.auth.user);
  const companyId = user?.companyId;

  const { setEditingInfo } = editValues;

  useEffect(() => {
    if (companyId) {
      api.xero
        .getTrackingCategories({ entityId: companyId })
        .then((trackingCategories) => {
          const activeCategories = trackingCategories.filter(
            (category) => category.optionStatus === 'ACTIVE'
          );
          setTrackingCategories(
            activeCategories.map((category) => category.optionCode)
          );
        });
    }
  }, [companyId]);

  useEffect(() => {
    if (document && type === RipeType.PENDING_APPROVAL && document.lineItems) {
      setGlCodeAllocation(document.lineItems);
      additionalForm.setFieldsValue({ glCodeAllocation: document.lineItems });
    }
  }, [additionalForm, document, type]);

  const prioritisedSupplierName =
    document?.counterPartySupplierName && type === RipeType.PENDING_APPROVAL
      ? document?.counterPartySupplierName
      : document?.supplierName;

  const supplierName = shouldShowSupplierName
    ? prioritisedSupplierName || ''
    : '';

  const invoiceNumber = document?.invoiceNumber || '';
  const orderNumber = document?.orderNumber || '';
  const invoiceAmount = document?.invoiceAmount || '';
  const issueDate = getFormattedDateString(document?.dateIssue);
  const storeCode = document?.storeCode;
  const userNotes = document?.userNotes || '';

  const defaultValues = {
    supplierName,
    invoiceNumber,
    orderNumber,
    invoiceAmount,
    issueDate: issueDate ? moment(issueDate, 'YYYY-MM-DD') : undefined,
  };

  useEffect(() => {
    setEditingInfo(editingAdditional || editingInvoice);
  }, [setEditingInfo, editingAdditional, editingInvoice]);

  const reason = getReasonFromMatchStatus(matchStatus);
  const status = type === RipeType.AUTHORISED ? 'Authorised' : '-';

  const handleSave = (formData: InvoiceFormData) => {
    if (document) {
      setUpdatingInvoiceData(true);

      let newDateIssue = null;
      if (type !== RipeType.VARIANCE) {
        newDateIssue = moment(formData.issueDate, 'D MMM YYYY').isValid()
          ? moment(formData.issueDate, 'D MMM YYYY').format('YYYY-MM-DD')
          : null;
      }

      const newInvoiceAmount = formData.invoiceAmount || null;
      const newInvoiceNumber = formData.invoiceNumber?.trim() || null;
      const newOrderNumber = formData.orderNumber?.trim() || null;
      const newSupplierName = formData.supplierName?.trim() || null;

      const supplierNamePayload =
        document?.counterPartySupplierName && type === RipeType.PENDING_APPROVAL
          ? { counterPartySupplierName: newSupplierName }
          : { supplierName: newSupplierName };

      let documentPayload = {
        ...document,
        invoiceAmount: newInvoiceAmount,
        invoiceNumber: newInvoiceNumber,
        dateIssue: newDateIssue,
        orderNumber: newOrderNumber,
        ...supplierNamePayload,
      };

      const hasInvoiceAmountChanged = newInvoiceAmount !== invoiceAmount;

      if (hasInvoiceAmountChanged) {
        // User has updated the invoice amount
        // We need to reset the GL code allocation
        documentPayload.lineItems = [];
      }

      api.ripe
        .putDocument(documentPayload)
        .then((updatedDocument) => {
          if (hasInvoiceAmountChanged) {
            setGlCodeAllocation([]);
            additionalForm.setFieldsValue({
              glCodeAllocation: [],
            });
          }
          onUpdate();
          setDocument(updatedDocument);
          setUpdatingInvoiceData(false);
          setEditingInvoice(false);
        })
        .catch((error) => {
          const { status, message } = processError(error);
          const documentNeedsLocking = shouldLockDocument({
            status,
            errorMessage: message,
          });
          if (documentNeedsLocking) {
            api.ripe
              .lockDocument({ documentId })
              .then((lockedDocument) => {
                const lockedDocumentPayload = {
                  ...documentPayload,
                  lockStatus: lockedDocument.lockStatus,
                  lockedAt: lockedDocument.lockedAt,
                  lockedBy: lockedDocument.lockedBy,
                };
                api.ripe
                  .putDocument(lockedDocumentPayload)
                  .then((updatedDocument) => {
                    if (hasInvoiceAmountChanged) {
                      setGlCodeAllocation([]);
                      additionalForm.setFieldsValue({
                        glCodeAllocation: [],
                      });
                    }
                    setDocument(updatedDocument);
                    onUpdate();
                    setEditingInvoice(false);
                  })
                  .catch((error) => {
                    const { message } = processError(error);
                    notification.error({ message });
                  })
                  .finally(() => setUpdatingInvoiceData(false));
              })
              .catch((error) => {
                setUpdatingInvoiceData(false);
                const { message } = processError(error);
                notification.error({ message });
              });
          } else {
            setUpdatingInvoiceData(false);
            notification.error({ message });
          }
        });
    }
  };

  const handleSaveAdditionalData = (formData: AdditionalFormData) => {
    if (document) {
      setUpdatingAdditionalData(true);

      const documentPayload: RipeCompanyInvoice = {
        ...document,
        lineItems: formData.glCodeAllocation,
        storeCode: formData.storeCode || null,
        userNotes: formData.userNotes || null,
      };

      api.ripe
        .putDocument(documentPayload)
        .then((updatedDocument) => {
          onUpdate();
          setDocument(updatedDocument);
          setUpdatingAdditionalData(false);
          setEditingAdditional(false);
        })
        .catch((error) => {
          const { status, message } = processError(error);
          const documentNeedsLocking = shouldLockDocument({
            status,
            errorMessage: message,
          });
          if (documentNeedsLocking) {
            api.ripe
              .lockDocument({ documentId })
              .then((lockedDocument) => {
                const lockedDocumentPayload = {
                  ...documentPayload,
                  lockStatus: lockedDocument.lockStatus,
                  lockedAt: lockedDocument.lockedAt,
                  lockedBy: lockedDocument.lockedBy,
                };
                api.ripe
                  .putDocument(lockedDocumentPayload)
                  .then((updatedDocument) => {
                    setDocument(updatedDocument);
                    onUpdate();
                    setEditingAdditional(false);
                  })
                  .catch((error) => {
                    const { message } = processError(error);
                    notification.error({ message });
                  })
                  .finally(() => setUpdatingAdditionalData(false));
              })
              .catch((error) => {
                setUpdatingAdditionalData(false);
                const { message } = processError(error);
                notification.error({ message });
              });
          } else {
            setUpdatingAdditionalData(false);
            notification.error({ message });
          }
        });
    }
  };

  return (
    <EditPanel>
      {type === RipeType.PENDING_REVIEW ||
      type === RipeType.PENDING_APPROVAL ||
      type === RipeType.BLOCKED ||
      type === RipeType.UNMATCHED ||
      editingConfirmedBill ? (
        <>
          <FormContainer
            loading={loading}
            bordered={false}
            extra={
              <EditButtonGroup>
                {!editingInvoice ? (
                  <Button
                    type="default"
                    size="small"
                    onClick={() => setEditingInvoice(true)}
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => invoiceForm.submit()}
                      loading={updatingInvoiceData}
                    >
                      Save
                    </Button>
                    <Tooltip
                      visible={false}
                      placement="bottom"
                      title="Unsaved changes will be lost"
                    >
                      <Button
                        size="small"
                        icon={<CloseOutlined color={colors.text} />}
                        onClick={() => {
                          invoiceForm.resetFields();
                          setEditingInvoice(false);
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </EditButtonGroup>
            }
          >
            <Form
              form={invoiceForm}
              layout="vertical"
              onFinish={handleSave}
              initialValues={defaultValues}
            >
              {type !== RipeType.VARIANCE &&
                type !== RipeType.AUTHORISED &&
                type !== RipeType.PENDING_APPROVAL && (
                  <InputItem label="Reason">{reason}</InputItem>
                )}
              {type === RipeType.PENDING_APPROVAL ? (
                <Form.Item label="Supplier" name="supplierName">
                  {editingInvoice ? (
                    <CounterpartySearch
                      entityId={companyId}
                      placeholder={'Search company'}
                      isAdmin={false}
                      value={
                        invoiceForm.getFieldValue('supplierName') ||
                        supplierName
                      }
                      setToEntity={(value) =>
                        invoiceForm.setFieldsValue({
                          supplierName: value.entityName,
                        })
                      }
                    />
                  ) : (
                    <Text>{supplierName ? supplierName : '-'}</Text>
                  )}
                </Form.Item>
              ) : (
                <InvoiceInput
                  form={invoiceForm}
                  name="supplierName"
                  label="Supplier"
                  value={supplierName}
                  format="string"
                  editing={editingInvoice}
                  placeholder={'Add supplier name'}
                  className="invoice-editing-area-supplier"
                />
              )}
              <InvoiceInput
                form={invoiceForm}
                name="invoiceNumber"
                label="Invoice Number"
                value={invoiceNumber}
                format="string"
                editing={editingInvoice}
                placeholder={'Add invoice number'}
                className="invoice-editing-area-invoice-number"
              />
              <InvoiceInput
                form={invoiceForm}
                name="orderNumber"
                label="Order Number"
                value={orderNumber}
                format="string"
                editing={editingInvoice}
                placeholder={'Add order number'}
                className="invoice-editing-area-order-number"
              />
              <InvoiceInput
                form={invoiceForm}
                name="invoiceAmount"
                label="Invoice Amount"
                warning={
                  glCodeAllocation.length > 0
                    ? 'Changing invoice amount will reset the GL Code Allocation'
                    : undefined
                }
                editing={editingInvoice}
                format="currency"
                value={invoiceAmount}
                placeholder={'Add invoice amount'}
                className="invoice-editing-area-invoice-amount"
              />
              {type !== RipeType.VARIANCE && (
                <InvoiceInput
                  form={invoiceForm}
                  name="issueDate"
                  label="Issue Date"
                  editing={editingInvoice}
                  format="date"
                  value={issueDate}
                  placeholder={'Add issue date'}
                  className="invoice-editing-area-issue-date"
                />
              )}
            </Form>
          </FormContainer>
          <StyledCollapse
            defaultActiveKey={userNotes ? 1 : undefined}
            bordered={false}
          >
            <Panel header="Additional Information" key="1">
              <EditButtonGroup className="edit-button-group">
                {!editingAdditional ? (
                  <Button
                    type="default"
                    size="small"
                    onClick={() => setEditingAdditional(true)}
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => additionalForm.submit()}
                      loading={updatingAdditionalData}
                    >
                      Save
                    </Button>
                    <Tooltip
                      placement="bottom"
                      title="Unsaved changes will be lost"
                    >
                      <Button
                        size="small"
                        icon={<CloseOutlined color={colors.text} />}
                        onClick={() => {
                          additionalForm.resetFields();
                          setEditingAdditional(false);
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </EditButtonGroup>
              <Form
                form={additionalForm}
                onFinish={handleSaveAdditionalData}
                layout="vertical"
                initialValues={{
                  storeCode: storeCode || '',
                  glCodeAllocation: glCodeAllocation || null,
                  userNotes: userNotes || '',
                }}
              >
                {type === RipeType.PENDING_APPROVAL && (
                  <>
                    <GlCodeAllocationInput
                      form={additionalForm}
                      value={additionalForm.getFieldValue('glCodeAllocation')}
                      label="GL Code Allocation"
                      editing={editingAdditional}
                      updating={updatingAdditionalData}
                      invoiceAmount={invoiceAmount}
                      companyId={companyId}
                      trackingCategories={trackingCategories}
                    />
                    <InvoiceInput
                      form={additionalForm}
                      name="storeCode"
                      label="Store Code"
                      editing={editingAdditional}
                      value={storeCode || undefined}
                      format="option"
                      options={trackingCategories}
                      placeholder={'Add store code'}
                      className="invoice-editing-area-store-code"
                    />
                  </>
                )}
                <Form.Item name="userNotes" label="Notes">
                  {editingAdditional ? (
                    <Input.TextArea
                      size="small"
                      className="invoice-editing-area-user-note"
                      placeholder="Add notes"
                      maxLength={512}
                    />
                  ) : (
                    <Text>{userNotes ? userNotes : '-'}</Text>
                  )}
                </Form.Item>
              </Form>
            </Panel>
          </StyledCollapse>
        </>
      ) : (
        <FormContainer loading={loading} bordered={false}>
          <ViewInfo
            supplierName={supplierName}
            invoiceNumber={invoiceNumber}
            orderNumber={orderNumber}
            invoiceAmount={invoiceAmount}
            posAmount={posAmount}
            issueDate={issueDate}
            reason={reason}
            status={status}
            userNotes={userNotes}
            history={history}
            type={type}
            authorisedInvoice={authorisedInvoice}
          />
        </FormContainer>
      )}
      {children}
    </EditPanel>
  );
};

export default EditInfo;
