import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import AuthCard from 'components/AuthCard';
import PageTracker from 'components/PageTracker';

import { xeroLogo, myobLogo } from 'assets/images';
import { CheckCircleFilledGreen } from 'assets/icons';
import { login, getBankAccounts } from 'store/auth';
import BetaBadge from 'components/BetaBadge';

import api from 'api';

import { BASE_URL } from 'config';

const ConnectButton = styled(Button)`
  margin-bottom: 20px;
`;

const Icon = styled.img`
  width: 150px;
  height: 150px;
`;

const CheckIcon = styled.img`
  position: absolute;
  left: 6px;
  top: 6px;
  width: 20px;
  height: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.length === 1 ? 'center' : 'space-between'};
  margin-bottom: 10px;
`;
const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 180px;
  border-radius: 8px;
  transition: all 300ms;
  :hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  }
  :active {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  }
  box-shadow: ${(props) =>
    props.isSelected ? '0px 2px 30px rgba(0, 0, 0, 0.15)' : null};
`;

const ConnectAccount = ({
  token,
  login,
  history,
  invitedUser,
  getBankAccounts,
  updateStepForInvitedUser,
}) => {
  const accounts =
    window.env.FEATURE_FLAGS &&
    window.env.FEATURE_FLAGS.MYOB_CONNECT &&
    window.env.FEATURE_FLAGS.XERO_CONNECT
      ? [
          {
            id: 1,
            logo: xeroLogo,
            label: 'Xero',
            isSelected: false,
          },
          {
            id: 2,
            logo: myobLogo,
            label: 'Myob',
            isSelected: false,
          },
        ]
      : window.env.FEATURE_FLAGS && window.env.FEATURE_FLAGS.XERO_CONNECT
      ? [
          {
            id: 1,
            logo: xeroLogo,
            label: 'Xero',
            isSelected: false,
          },
        ]
      : window.env.FEATURE_FLAGS && window.env.FEATURE_FLAGS.MYOB_CONNECT
      ? [
          {
            id: 2,
            logo: myobLogo,
            label: 'Myob',
            isSelected: false,
          },
        ]
      : [];
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [businessAccounts, setBusinessAccounts] = useState(accounts);

  const selectedAccount = businessAccounts.filter(
    (account) => account.isSelected === true
  )[0];

  const handleClick = () => {
    setLoading(true);
    if (selectedAccount.label === 'Xero') {
      axios
        .get(`${BASE_URL}/xero/connect`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
          const { authUrl } = data;
          window.location.assign(authUrl);
        });
    } else {
      if (invitedUser) {
        updateStepForInvitedUser();
      } else {
        history.push('/signup?step=connect-account-myob');
      }
    }
  };

  const skipAndSignup = () => {
    setLoadingDetails(true);
    loadUser();
  };

  const loadUser = () => {
    api.auth.getUser(token).then((userDetails) => {
      const { companyId } = userDetails;
      // get company details
      api.company.get({ companyId, token }).then((company) => {
        const userData = {
          ...userDetails,
          token,
          company,
        };
        login({ user: userData, loggedIn: true });
        getBankAccounts(companyId);
        history.push('/');
        setLoadingDetails(false);
      });
      // get company details ends here
    });
    // get user details ends here
  };

  const onSelectAccount = (account) => {
    const selected = businessAccounts.map((item) => {
      if (item.id === account.id) {
        return { ...item, isSelected: true };
      }
      return { ...item, isSelected: false };
    });
    setBusinessAccounts(selected);
  };

  const message = 'Connect your business accounts';
  const subMessage =
    'To get started, select your accounting service below and log in on the next screen.';

  return (
    <AuthCard message={message} subMessage={subMessage}>
      <PageTracker />
      <Row length={businessAccounts.length}>
        {businessAccounts.map((account) => (
          <Column
            onClick={() => onSelectAccount(account)}
            isSelected={account.isSelected}
            key={account.id}
          >
            {account.isSelected && (
              <CheckIcon src={CheckCircleFilledGreen} alt="" />
            )}
            {account.label === 'Xero' && <BetaBadge />}
            <Icon src={account.logo} />
          </Column>
        ))}
      </Row>
      <ConnectButton
        label="Continue"
        full
        raised
        primary
        onClick={handleClick}
        disabled={!selectedAccount}
        loading={loading}
        inviteSignup={
          invitedUser && invitedUser.status === 'INVITED' ? true : false
        }
      />
      <Button
        label="Skip"
        full
        transparent
        loading={loadingDetails}
        onClick={skipAndSignup}
        inviteSignup={
          invitedUser && invitedUser.status === 'INVITED' ? true : undefined
        }
      />
    </AuthCard>
  );
};

const mapStateToProps = ({ signup }) => ({
  token: signup.token,
  company: signup.company,
});

export default connect(mapStateToProps, {
  login,
  getBankAccounts,
})(withRouter(ConnectAccount));
