import { Divider, Form, Input, notification, Typography } from 'antd';
import api from 'api';
import { InvoiceDetails } from 'components';
import AmountStrip from 'components/AmountStrip';
import PaymentDueEntry from 'components/PaymentDueEntry';
import { MAX_CASHBACK_PERCENTAGE } from 'config';
import { Modal } from 'ds';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalytics } from 'store/analytics';
import {
  addSupplierOfferOffered,
  updateSupplierOfferOffered,
} from 'store/offers';
import { setRefreshData } from 'store/ui';
import styled from 'styled-components/macro';
import spacing from 'styles/layout/spacing';
import Invoice from 'types/Invoice';
import SupplierOffer from 'types/SupplierOffer';
import User from 'types/User';
import { formatCurrency, getTimestamp, processError } from 'utils';
import { Footer, Note, ServiceFee } from './components';
import { SuccessFee } from './components/SuccessFee';
import {
  calculateCashback,
  calculateServiceFee,
  calculateSuccessFee,
  findSupplierOfferOffered,
} from './utils';

const { Text } = Typography;

const today = moment();

const INPUT_FIELD_WIDTH = '150px';

const StyledForm = styled(Form)`
  padding-top: 10px;
  .ant-form-item-label > label {
    font-size: 14px;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
  .ant-input-affix-wrapper {
    width: ${INPUT_FIELD_WIDTH};
  }
  .ant-picker {
    width: ${INPUT_FIELD_WIDTH};
  }
`;

type State = {
  auth: { user: User };
  offers: {
    supplierOffers: {
      offered: SupplierOffer[];
    };
  };
};

type EditOfferParams = SupplierOffer['offerStatus'];

type ErrorMessageProps = {
  fee?: boolean;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, fee }) => (
  <Text
    css={`
      position: relative;
      bottom: ${fee ? '20px' : 'initial'};
      top: ${fee ? 'initial' : '8px'};
      font-size: 14px;
    `}
    type="danger"
  >
    {children}
  </Text>
);

const processCashback = (value: any) => {
  let processedValue = value;
  if (isNaN(value)) {
    if (value === '.') {
      processedValue = '0.';
    } else {
      processedValue = '0';
    }
  }
  return processedValue;
};

const MIN_CASHBACK = 5;

const CASHBACK_MISSING_ERROR = 'Please enter an amount';
const MIN_CASHBACK_ERROR = `Amount cannot be less than $${MIN_CASHBACK}`;
const MAX_CASHBACK_ERROR = `Amount cannot be greater than ${MAX_CASHBACK_PERCENTAGE}% of the invoice amount due`;

const EXPIRY_DATE_MISSING_ERROR = 'Please select an expiry date';

type Props = {
  invoice: Invoice | null;
  hideModal: () => void;
};

export const SupplierOfferModal: React.FC<Props> = (props) => {
  const { invoice, hideModal } = props;

  const dispatch = useDispatch();

  const companyId = useSelector((state: State) => state.auth.user.companyId);
  const supplierOffersOffered = useSelector(
    (state: State) => state.offers.supplierOffers.offered
  );

  const supplierOffer = invoice
    ? findSupplierOfferOffered(invoice, supplierOffersOffered)
    : null;

  const dueDate = moment(invoice?.dueDate);
  const maxCashback = invoice
    ? (invoice.discountedTotal * MAX_CASHBACK_PERCENTAGE) / 100
    : 0;

  const [cashback, setCashback] = useState<string | null>(null);
  const [expiryDate, setExpiryDate] = useState<Moment | null>(null);

  const [cashbackError, setCashbackError] = useState<string | null>(null);
  const [expiryDateError, setExpiryDateError] = useState<string | null>(null);

  const [submitting, setSubmitting] = useState(false);
  const [isEditing, setEditing] = useState(true);

  const parsedCashback = cashback ? parseFloat(cashback) : 0;

  const successFee = calculateSuccessFee(parsedCashback);
  const serviceFee = calculateServiceFee(parsedCashback, successFee);

  const resetModal = () => {
    setCashback(null);
    setExpiryDate(null);
    setCashbackError(null);
    setExpiryDateError(null);
    setSubmitting(false);
    setEditing(true);
  };

  useEffect(() => {
    if (supplierOffer) {
      setCashback(String(calculateCashback(supplierOffer.autobid)));
      setExpiryDate(moment(supplierOffer.expirationTime));
    }
  }, [supplierOffer]);

  const onChangeCashback = (e: any) => {
    const cashback = processCashback(e.target.value);
    setCashback(cashback);
    setCashbackError(null);
    if (cashback) {
      if (cashback < MIN_CASHBACK) {
        setCashbackError(MIN_CASHBACK_ERROR);
      } else if (cashback > maxCashback) {
        setCashbackError(MAX_CASHBACK_ERROR);
      }
    } else {
      setCashbackError(null);
    }
  };

  const onBlurCashback = () => {
    if (cashback) {
      setCashback(parseFloat(cashback).toFixed(2));
    }
  };

  const onChangeExpiryDate = (date: Moment | null) => {
    setExpiryDate(date);
    setExpiryDateError(null);
  };

  const handleHide = () => {
    resetModal();
    hideModal();
  };

  const handleError = (error: any) => {
    const { status, message, debugMessage } = processError(error);
    if (status === 403 && message === 'Change not permitted') {
      notification.error({
        message:
          'Transaction in progress. Please reset the offer if you wish to change your bid.',
      });
    } else if (status === 403 && debugMessage === 'company not verified') {
      handleHide();
    } else {
      notification.error({ message });
    }
  };

  const onSubmit = () => {
    if (cashbackError || expiryDateError) {
      return;
    }
    let hasErrors = false;
    if (!cashback) {
      setCashbackError(CASHBACK_MISSING_ERROR);
      hasErrors = true;
    }
    if (!expiryDate) {
      setExpiryDateError(EXPIRY_DATE_MISSING_ERROR);
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    if (supplierOffer) {
      editOffer();
    } else {
      createNewOffer();
    }
  };

  const createNewOffer = () => {
    if (!expiryDate || !invoice) {
      return;
    }
    setSubmitting(true);
    const payload = {
      createdByCompany: companyId,
      invoiceIds: [invoice.id],
      autobid: serviceFee,
      offerUnit: 'DOLLAR',
      expirationTime: getTimestamp(expiryDate),
    };
    api.supplierOffers
      .post(payload)
      .then((supplierOffer) => {
        dispatch(addSupplierOfferOffered(supplierOffer));
        dispatch(getAnalytics('invoice'));
        setSubmitting(false);
        setEditing(false);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setSubmitting(false));
  };

  const editOffer = (offerStatus?: EditOfferParams) => {
    if (!expiryDate || !supplierOffer) {
      return;
    }
    setSubmitting(true);
    const reset = offerStatus && offerStatus === 'EXPIRED';
    const payload = {
      ...supplierOffer,
      autobid: serviceFee,
      expirationTime: getTimestamp(expiryDate),
      offerStatus: reset ? 'EXPIRED' : supplierOffer.offerStatus,
    };
    api.supplierOffers
      .edit({ params: payload, uriParam: supplierOffer.id })
      .then((supplierOffer) => {
        dispatch(updateSupplierOfferOffered(supplierOffer));
        dispatch(getAnalytics('invoice'));
        setEditing(false);
        if (reset) {
          setRefreshData(true);
          handleHide();
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    if (isEditing && supplierOffer) {
      // Reset the offer
      editOffer('EXPIRED');
    } else {
      handleHide();
    }
  };

  const modalTitle = isEditing
    ? supplierOffer
      ? 'Edit Prompt Payment Request'
      : 'Request Prompt Payment'
    : 'Prompt Payment Requested';

  return (
    <Modal
      visible={!!invoice}
      title={modalTitle}
      onCancel={handleHide}
      footer={
        <Footer
          editing={isEditing}
          loading={submitting}
          primaryAction={isEditing ? onSubmit : handleHide}
          secondaryAction={onCancel}
        />
      }
    >
      {invoice && (
        <>
          <InvoiceDetails invoices={[invoice]} invoiceType="INVOICE" />
          <AmountStrip
            css={`
              margin-top: ${spacing.gutter.lg};
            `}
            label="Amount Due (inc. GST)"
            value={formatCurrency(invoice.discountedTotal || 0)}
          />
          <Divider />
        </>
      )}

      <StyledForm colon={false}>
        <Form.Item
          label="Prompt Payment Discount"
          validateStatus={cashbackError ? 'error' : undefined}
        >
          {isEditing ? (
            <Input
              disabled={submitting}
              placeholder="Enter Amount"
              prefix="$"
              value={cashback || undefined}
              onChange={onChangeCashback}
              onBlur={onBlurCashback}
            />
          ) : (
            formatCurrency(parseFloat(cashback || '0'))
          )}
        </Form.Item>
        <ErrorMessage fee>{cashbackError}</ErrorMessage>

        <PaymentDueEntry
          placeholder="dd/mm/yyyy"
          label="Discount Expiry Date"
          date={expiryDate}
          isEditing={isEditing}
          onChange={onChangeExpiryDate}
          disabled={submitting}
          disabledDate={(date) =>
            date.isBefore(today, 'day') || date.isSameOrAfter(dueDate, 'day')
          }
          error={expiryDateError}
        />
        <ErrorMessage>{expiryDateError}</ErrorMessage>
      </StyledForm>
      <Divider />
      <SuccessFee successFee={successFee} />
      <ServiceFee expiryDate={expiryDate} serviceFee={serviceFee} />
      <Note />
    </Modal>
  );
};
