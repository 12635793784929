import React from 'react';
import styled from 'styled-components/macro';
import { Checkbox, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const Container = styled(Space)`
  width: 100%;
`;

const CheckboxContainer = styled.div`
  background: #fff;
  padding: 10px;
  width: 100%;
`;

type Props = {
  label: string;
  isChecked: boolean;
  handleCheck: (flag: boolean) => void;
  className?: string;
};

export const FilterItem: React.FC<Props> = (props) => {
  const { children, label, isChecked, handleCheck, className } = props;
  const handleClickCheckBox = (e: CheckboxChangeEvent) => {
    handleCheck(e.target.checked);
  };
  return (
    <Container direction="vertical" className={className}>
      <CheckboxContainer>
        <Checkbox checked={isChecked} onChange={handleClickCheckBox}>
          {label}
        </Checkbox>
      </CheckboxContainer>
      {isChecked && children}
    </Container>
  );
};
