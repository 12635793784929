import React from 'react';
import colors from 'colors';
import InvoiceDetails from './InvoiceDetails';
import Amount from 'components/Amount';
import CreditNoteDetails from './CreditNoteDetails';
import { Moment } from 'moment';

const ConformationScreen = ({
  isAddingCreditNote,
  invoiceType,
  amount,
  dueDate,
  issueDate,
  invoiceNumber,
  companyName,
}: {
  isAddingCreditNote: boolean;
  invoiceType: 'BILL' | 'INVOICE';
  amount: string;
  dueDate: Moment;
  issueDate: Moment;
  invoiceNumber: string;
  companyName: string;
}) => (
  <>
    {isAddingCreditNote ? (
      <CreditNoteDetails
        amount={amount}
        companyName={companyName}
        number={invoiceNumber}
        issueDate={issueDate}
      />
    ) : (
      <>
        <InvoiceDetails
          invoiceType={invoiceType}
          dueDate={dueDate}
          issueDate={invoiceType === 'INVOICE' ? undefined : issueDate}
          companyName={companyName}
          invoiceNumber={invoiceNumber}
        />
        <Amount
          amount={amount}
          label="Amount Due (inc. GST)"
          containerStyle={styles.discountedAmountContainer}
        />
      </>
    )}
  </>
);

export default ConformationScreen;

const styles = {
  discountedAmountContainer: {
    backgroundColor: colors.greys100,
    height: 40,
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
};
