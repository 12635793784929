import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';

// import { colors } from 'themes';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const TextContainer = styled.div`
  /* background-color: rgba(160, 160, 160, 0.2); */
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  position: fixed;
  top: 2%;
  left: 50%;
  z-index: 500;
  ${
    '' /* padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px; */
  }
  /* border-radius: 50px; */
  border-radius: 0 0 2px 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #222;
  padding: 6px 10px;
`;

const Text = styled.span`
  ${'' /* color: ${colors.greys700}; */}
  text-align: center;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold;
`;

const LoadingIndicator = ({
  loading,
  loadingTransactions,
  loadingTopups,
  loadingCompanies,
}) =>
  loading || loadingTransactions || loadingTopups || loadingCompanies ? (
    <Container>
      <TextContainer>
        <Text>Loading...</Text>
      </TextContainer>
    </Container>
  ) : null;
const mapStateToProps = ({ invoices, transactions, admin }) => ({
  loading: invoices.loadingInvoices,
  loadingTransactions: transactions.loadingTransactions,
  loadingTopups: admin.topups.loading,
  loadingCompanies: admin.companies.loadingCompanies,
});

export default connect(mapStateToProps)(LoadingIndicator);
