// Filter operators begin
export type AmountOperator = 'eq' | 'between' | 'lt' | 'gt';
export type OrderNumberOperator = 'eq' | 'contains';
export type InvoiceNumberOperator = 'eq' | 'contains';
export type IssueDateOperator = 'eq' | 'between' | 'gt' | 'ge' | 'lt' | 'le';
// Filter operators end

export type ProcessingStatus =
  | 'MATCHED'
  | 'VARIANCE'
  | 'RETRY'
  | 'NO_MATCH'
  | 'DELETED'
  | 'READY_TO_MATCH'
  | 'BLOCKED'
  | 'PENDING_APPROVAL';

// Type of record from database
type RipeCompanyInvoice = {
  annotationId: string;
  companyId: string;
  createdAt: number;
  documentId: string;
  dueDate: string;
  lineItems: GlCodeAllocation | null;
  invoiceAmount: null | string;
  invoiceNumber: null | string;
  dateIssue: null | string;
  lockStatus: null | 'UNLOCKED' | 'LOCKED';
  lockedAt: null | number;
  lockedBy: null | string;
  matchStatus: string;
  matchedBy: 'ORDER_NUMBER' | 'CUSTOMER_ORDER_NUMBER' | null;
  note: string;
  userNotes: null | string;
  orderNumber: null | string;
  posAmount: string;
  processingStatus: ProcessingStatus;
  supplierName: null | string;
  counterPartySupplierName: null | string;
  storeCode: null | string;
  updatedAt: number;
  xeroBillId: null | string;
};

// Single record in API response
export type RipeCompanyInvoiceResource = {
  ripeCompanyInvoice: RipeCompanyInvoice;
  _links: { self: { href: string } };
};

// Type of API response to GET all records
export type RipeCompanyInvoiceAPIResponse = {
  pageNumber: number;
  pageSize: number;
  ripeCompanyInvoiceResources: RipeCompanyInvoiceResource[];
  invoiceStatistics: InvoiceStatistics;
  totalElements: number;
  totalPages: number;
  _links: { next: { href: string } };
};
export type RipeCompanyInvoiceAPIResolve = {
  docs: RipeCompanyInvoice[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  invoiceStatistics: InvoiceStatistics;
  _links?: {
    next?: { href: string };
    prev?: { href: string };
  };
};

export type InvoiceStatistics = {
  orderNotFoundGpos?: InvoiceStatistic;
  orderNotFoundOcr?: InvoiceStatistic;
  retry?: InvoiceStatistic;
  statusMismatch?: InvoiceStatistic;
  variance?: InvoiceStatistic;
  amountOver?: InvoiceStatistic;
  amountUnder?: InvoiceStatistic;
};

export type RipeDocumentInvoiceContent =
  | {
      name: 'GL_CODE_ALLOCATION';
      value: GlCodeAllocation;
      updatedAt: number;
    }
  | {
      name: 'STORE_CODE';
      value: string;
      updatedAt: number;
    }
  | {
      name: 'USER_NOTES';
      value: string;
      updatedAt: number;
    };

export type RipeCompanyVariance = RipeCompanyInvoice & { posAmount: string };

export enum RipeType {
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PROCESSING = 'PROCESSING',
  VARIANCE = 'VARIANCE',
  BLOCKED = 'BLOCKED',
  UNMATCHED = 'UNMATCHED',
  AUTHORISED = 'AUTHORISED',
}

export type RipePageType = Exclude<RipeType, RipeType.AUTHORISED>;

export type SortBy =
  | 'updatedAt'
  | 'supplierName'
  | 'orderNumber'
  | 'invoiceNumber'
  | 'invoiceAmount'
  | 'dateIssue'
  | 'posAmount'
  | null;
export type SortDirection = 'asc' | 'desc' | null;

export type GlCodeAllocation = GlCodeAllocationItem[];
export type GlCodeAllocationItem = {
  glCode: string;
  name: string;
  amount: number;
  costCenter: string | null;
};

export type InvoiceStatistic = {
  statisticsType: 'MATCH_STATUS' | 'PROCESSING_STATUS';
  aggregatedBy: MatchStatus;
  totalAmountDue: number;
  totalPosAmount: number;
  totalAuthorisedAmount: number;
  totalCount: number;
};

type MatchStatus =
  | 'ORDER_NOT_FOUND_OCR'
  | 'ORDER_NOT_FOUND_GPOS'
  | 'STATUS_MISMATCH'
  | 'AMOUNT_UNDER,AMOUNT_OVER'
  | 'INVOICE_DETAILS_MISSING'
  | 'CONTACT_NOT_FOUND'
  | 'EXPIRED'
  | 'POTENTIAL_DUPLICATE'
  | 'ALREADY_CLOSED'
  | 'BLOCKED';

export type AuthorisedInvoice = {
  invoiceAmount: number;
  invoiceNumber: string;
  issueDate: string;
  orderNumber: string;
  supplierName: string;
  userNotes: string;
};

export default RipeCompanyInvoice;
