import React from 'react';
import { Skeleton, Space } from 'antd';
import { EntityType } from './types';
import { MergeStats } from 'types/CounterParty';
import { InvalidCountMessage } from './InvalidCountMessage';
import { ValidCountMessage } from './ValidCountMessage';

type Props = {
  fromEntityName: string;
  loadingMergeStatistics: boolean;
  mergeStatistics: MergeStats | null;
  toEntity: EntityType;
};

export const MergeConfirmation: React.FC<Props> = ({
  fromEntityName,
  loadingMergeStatistics,
  mergeStatistics,
  toEntity,
}) => {
  return (
    <Space direction="vertical" size={'large'}>
      {loadingMergeStatistics ? (
        <Skeleton />
      ) : (
        <>
          <ValidCountMessage
            fromEntityName={fromEntityName}
            toEntityName={toEntity.entityName}
            validBillCount={mergeStatistics?.validBillCount || 0}
            validCreditNoteCount={mergeStatistics?.validCreditNoteCount || 0}
            validInvoiceCount={mergeStatistics?.validInvoiceCount || 0}
          />

          <InvalidCountMessage
            invalidBillCount={mergeStatistics?.invalidBillCount}
            invalidCreditNoteCount={mergeStatistics?.invalidCreditNoteCount}
            invalidInvoiceCount={mergeStatistics?.invalidInvoiceCount}
            invalidItems={mergeStatistics?.invalidItems}
          />
        </>
      )}
    </Space>
  );
};
