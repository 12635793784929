import React, { FC, useRef, useState, ChangeEvent } from 'react';
import { Button, Input, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

import api from 'api';
import { processError } from 'utils';

import colors from 'colors';
import CreditNote from 'types/CreditNote';
const { Text } = Typography;

const Container = styled.div`
  margin-top: 1.1rem;
`;
const InputWrapper = styled.div<{ $isEditable: boolean | undefined }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .ant-input-affix-wrapper-disabled .ant-input {
    ${({ $isEditable }) =>
      !$isEditable && `color: ${colors.greys700} !important`}
  }

  .ant-input-affix-wrapper > input.ant-input {
    /* Chrome, Safari, Edge, Opera */
    :focus ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    :hover ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :focus ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    :hover ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
`;
const Label = styled(Text)`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.greys600};
`;
const Amount = styled(Input)`
  color: ${colors.greys700} !important;
  cursor: auto !important;
  padding: 5px 8px 5px 16px;
`;
const SuffixButton = styled(Button)`
  padding: 0;
  min-width: 0;
  height: 24px;
`;

const ErrorText = styled(Text)`
  font-size: 14px;
  line-height: 20px;
`;

type Props = {
  creditNoteId: string;
  modificationTime: number;
  setCreditNote?: (value: CreditNote) => void;
  refreshTableData?: () => void;
  isEditable?: boolean;
  totalAmount: number;
};

const CreditAmount: FC<Props> = ({
  totalAmount,
  creditNoteId,
  modificationTime,
  setCreditNote,
  refreshTableData,
  isEditable,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState('');
  const [amount, setAmount] = useState(parseFloat(`${totalAmount}`).toFixed(2));
  const [loading, setLoading] = useState(false);

  // ref to blur the input after the action
  const inputRef = useRef<Input>(null);

  const onSave = () => {
    setLoading(true);
    api.creditNotes
      .patch({
        id: creditNoteId,
        value: parseFloat(amount),
        modificationTime,
        path: '/total',
        operation: 'replace',
      })
      .then((result) => {
        refreshTableData && refreshTableData();
        setCreditNote && setCreditNote(result);
        inputRef.current?.blur();
        setLoading(false);
        setIsEditMode(false);
      })
      .catch((err) => {
        const { message } = processError(err);
        setError(message);
        setLoading(false);
      });
  };

  const onCancel = () => {
    if (!loading) {
      setAmount(parseFloat(`${totalAmount}`).toFixed(2));
      setIsEditMode(false);
      setError('');
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    if (!e.target.value || Number(e.target.value) <= 0) {
      // show error if user inputs special charcters other than '_' and '-'
      setError('Amount should be greater than 0');
    }

    setAmount(e.target.value);
  };

  const onBlurFormat = () => {
    setAmount(parseFloat(`${amount}`).toFixed(2));
  };

  return (
    <Container>
      <>
        <Label>TOTAL CREDIT AMOUNT</Label>
        <InputWrapper $isEditable={isEditable}>
          <Amount
            ref={inputRef}
            value={amount}
            type="number"
            prefix="$"
            suffix={
              isEditable ? (
                loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 18 }} spin />
                    }
                  />
                ) : (
                  <SuffixButton
                    onClick={() => {
                      isEditMode ? onSave() : setIsEditMode(true);
                    }}
                    disabled={!!error}
                    type="link"
                    loading={loading}
                    data-testid="credit-amount-edit-save-button"
                  >
                    {isEditMode ? 'Save' : 'Edit'}
                  </SuffixButton>
                )
              ) : null
            }
            disabled={!isEditMode || !isEditable}
            onChange={onChange}
            onBlur={onBlurFormat}
            onPressEnter={onSave}
            // onWheel={(e) => e.currentTarget.blur()}
            onKeyDown={(e) => {
              if (['ArrowUp', 'ArrowDown'].includes(e.code)) {
                e.preventDefault();
                return;
              }
            }}
            data-testid="credit-amount-input"
          />
          {isEditable && isEditMode && (
            <Button type="ghost" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </InputWrapper>
        {!!error && (
          <ErrorText type="danger" data-testid="credit-amount-error">
            {error}
          </ErrorText>
        )}
      </>
    </Container>
  );
};

export default CreditAmount;
