import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, FormInstance, Space } from 'antd';

import { StatementFormValues } from './types';

const MAX_FILE_SIZE = 10 * 1000 * 1000; // 10 MB in bytes

const ImageContainer = styled.div`
  background-color: transparent;
`;

type BankStatementProps = {
  form: FormInstance<StatementFormValues>;
  onChange: (file: File) => void;
  onSubmit: (values: any) => void;
};

export const BankStatement: React.FC<BankStatementProps> = ({
  form,
  onChange,
  onSubmit,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      return;
    }
    const { size, type } = file;
    if (
      type !== 'image/jpeg' &&
      type !== 'image/png' &&
      type !== 'application/pdf'
    ) {
      form.setFields([
        {
          name: 'file',
          errors: [
            'Unsupported file format. Please select a JPG, PNG or PDF file.',
          ],
        },
      ]);
      setPreviewUrl('');
      return;
    }
    if (size > MAX_FILE_SIZE) {
      form.setFields([
        {
          name: 'file',
          errors: ['Please choose a file which is less than 10 MB in size'],
        },
      ]);
      setPreviewUrl('');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === 'string') {
        onChange(file);
        setPreviewUrl(reader.result);
        form.setFields([
          { name: 'file', errors: [], value: event.target.value },
        ]);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <Space direction="vertical">
      {previewUrl ? (
        <ImageContainer>
          <img alt="bank statement" src={previewUrl} width="150" />
        </ImageContainer>
      ) : null}
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="file">
          <input onChange={handleChange} type="file" />
        </Form.Item>
      </Form>
    </Space>
  );
};
