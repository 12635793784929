import React, { useCallback, useEffect, useState, createContext } from 'react';
import AlternatePageLayout from 'components/AlternatePageLayout';
import EditInfo from './EditInfo';
import Actions from './Actions';
import Loading from './Loading';
import EditedNotice from './EditedNotice';
import { InvoiceImage } from './InvoiceImage';
import api from 'api';
import processError from 'utils/processError';
import shouldLockDocument from 'utils/shouldLockDocument';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, notification, Row, Spin } from 'antd';
import { match, RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import styled from 'styled-components';

import RipeCompanyInvoice, {
  AuthorisedInvoice,
  RipeType,
} from 'types/RipeCompanyInvoice';
import Invoice from 'types/Invoice';
import featureFlags from 'config/featureFlags';
import colors from 'colors';
import spacing from 'styles/layout/spacing';
import moment from 'moment';

const SpinnerContainer = styled(Col)`
  display: grid;
  justify-content: center;
  align-items: center;
`;

const ReviewContainer = styled(Row)`
  .ant-btn:not(.ant-btn-primary, .ant-btn-ghost) {
    background-color: ${colors.greys100};
    border: none;
    box-shadow: none;
    :hover {
      background-color: ${colors.greys200};
      color: unset;
    }
  }
`;

type MatchParams = {
  id: string;
};

type Props = {
  match: match<MatchParams>;
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  type: RipeType;
};

declare global {
  interface Window {
    pendingApproval: any;
  }
}

export type InvoiceContextProps = {
  isResized: boolean;
  editingInfo: boolean;
  setEditingInfo: (edit: boolean) => void;
  setHasEdited: (param: boolean) => void;
};

export const InvoiceContext = createContext({} as InvoiceContextProps);

type StateProps = {
  username: string;
};

const InvoiceReview: React.FC<Props & StateProps> = ({
  match,
  location,
  history,
  type,
  username,
}) => {
  const { id } = match.params;
  const previousParams = location.state?.previousParams;

  const [document, setDocument] = useState<RipeCompanyInvoice | null>(null);

  const [pageNotFound, setPageNotFound] = useState(false);
  const [urls, setUrls] = useState<string[] | null>([]);
  const [pagesLength, setPagesLength] = useState(0);

  const [loading, setLoading] = useState(true);
  const [confirming, setConfirming] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [sendingForReview, setSendingForReview] = useState(false);
  const [editingConfirmedBill, setEditingConfirmedBill] = useState(false);

  const [totalElements, setTotalElements] = useState(0);
  const [currentItemNumber, setCurrentItemNumber] = useState(0);

  const [hasEdited, setHasEdited] = useState(false);
  const [isResized, setIsResized] = useState(false);
  const [billNumber, setBillNumber] = useState<string>('');

  const [editingInfo, setEditingInfo] = useState(false);

  // To determine if we should show the supplier name
  // This is for the pending approval queue
  // Supplier name needs to be shown only if it matches the name of a counter-party
  const [shouldShowSupplierName, setShouldShowSupplierName] = useState(false);

  const [authorisedInvoice, setAuthorisedInvoice] =
    useState<AuthorisedInvoice | null>(null);

  const isRipeLiteEnabled = useSelector(
    (state: any) => state.settings?.isRipeLiteEnabled
  );

  const sendToPendingApprovalEnabled =
    isRipeLiteEnabled &&
    featureFlags.sendToPendingApprovalEnabled &&
    (document?.matchStatus === 'ORDER_NOT_FOUND_OCR' ||
      document?.matchStatus === 'ORDER_NOT_FOUND_GPOS' ||
      document?.matchStatus === 'AMOUNT_OVER' ||
      document?.matchStatus === 'AMOUNT_UNDER');

  const { shouldGetZippedRipeImage } = featureFlags;

  const contextValue = {
    isResized,
    editingInfo,
    setEditingInfo,
    setHasEdited,
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsResized(!isResized);
    };
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  });

  const user = useSelector((store: any) => store.auth.user);
  const companyId = user?.companyId;

  useEffect(() => {
    if (!document?.supplierName && !document?.counterPartySupplierName) {
      return;
    }

    if (type === RipeType.PENDING_APPROVAL) {
      const supplierName = document?.counterPartySupplierName
        ? document?.counterPartySupplierName
        : document?.supplierName;

      if (!supplierName) {
        return;
      }

      api.company
        .getCounterParties({
          counterPartyName: supplierName,
          companyId,
        })
        .then((response) => {
          const hasMatchingCounterParty = response.counterParties.some(
            (counterParty) =>
              counterParty.counterParty.counterParty.companyName ===
              supplierName
          );

          // Need to show available supplier name if a supplier counter party
          // with the extracted supplier name exists
          setShouldShowSupplierName(hasMatchingCounterParty);
        });
    } else {
      // Need to show available supplier name if document is NOT pending approval
      setShouldShowSupplierName(!!document?.supplierName);
    }
  }, [companyId, document, type]);

  const moveToNextBill = useCallback(() => {
    api.ripe.unlockDocument({ documentId: id });
    let urlBase = '/bills';
    if (type === RipeType.PENDING_REVIEW) {
      urlBase = '/bills/pending-review';
    } else if (type === RipeType.PENDING_APPROVAL) {
      urlBase = '/bills/pending-approval';
    } else if (type === RipeType.PROCESSING) {
      urlBase = '/bills/processing';
    } else if (type === RipeType.VARIANCE) {
      urlBase = '/bills/variances';
    } else if (type === RipeType.BLOCKED) {
      urlBase = '/bills/blocked';
    } else if (type === RipeType.UNMATCHED) {
      urlBase = '/bills/unmatched';
    }
    if (!location.state) {
      history.push(urlBase, { previousParams });
      return;
    }
    const { documentIds, nextPageHref, prevPageHref } = location.state;
    if (!documentIds) {
      history.push(urlBase, { previousParams });
      return;
    }
    const currentIndex = documentIds.indexOf(id);
    if (currentIndex < documentIds.length - 1) {
      setLoading(true);
      const { pageNumber, pageSize, totalElements } = location.state;
      history.push(`${urlBase}/${documentIds[currentIndex + 1]}`, {
        documentIds,
        nextPageHref,
        prevPageHref,
        pageNumber,
        pageSize,
        totalElements,
        direction: 'forward',
        previousParams,
      });
    } else if (nextPageHref) {
      const paramsString = nextPageHref.split('?')[1];
      setLoading(true);
      api.ripe
        .getDocuments({ paramsString })
        .then(({ docs, _links, pageNumber, pageSize, totalElements }) => {
          const documentIds = docs.map((doc) => doc.documentId);
          let nextPageHref = '';
          let prevPageHref = '';
          if (_links && _links.next && _links.next.href) {
            nextPageHref = _links.next.href;
          }
          if (_links && _links.prev && _links.prev.href) {
            prevPageHref = _links.prev.href;
          }
          history.push(`${urlBase}/${documentIds[0]}`, {
            documentIds,
            nextPageHref,
            prevPageHref,
            pageNumber: pageNumber + 1,
            pageSize,
            totalElements,
            direction: 'forward',
            previousParams,
          });
        })
        .catch((error) => {
          setLoading(false);
          const { message } = processError(error);
          notification.error({ message });
        });
    } else {
      history.push(urlBase, { previousParams });
    }
  }, [history, id, location.state, previousParams, type]);

  const moveToPreviousBill = useCallback(() => {
    api.ripe.unlockDocument({ documentId: id });
    let urlBase = '/bills';
    if (type === RipeType.PENDING_REVIEW) {
      urlBase = '/bills/pending-review';
    } else if (type === RipeType.PENDING_APPROVAL) {
      urlBase = '/bills/pending-approval';
    } else if (type === RipeType.PROCESSING) {
      urlBase = '/bills/processing';
    } else if (type === RipeType.VARIANCE) {
      urlBase = '/bills/variances';
    } else if (type === RipeType.BLOCKED) {
      urlBase = '/bills/blocked';
    } else if (type === RipeType.UNMATCHED) {
      urlBase = '/bills/unmatched';
    }
    if (!location.state) {
      history.push(urlBase, { previousParams });
      return;
    }
    const { documentIds, nextPageHref, prevPageHref } = location.state;
    if (!documentIds) {
      history.push(urlBase, { previousParams });
      return;
    }
    const currentIndex = documentIds.indexOf(id);
    if (currentIndex > 0) {
      setLoading(true);
      const { pageNumber, pageSize, totalElements } = location.state;
      history.push(`${urlBase}/${documentIds[currentIndex - 1]}`, {
        documentIds,
        nextPageHref,
        prevPageHref,
        pageNumber,
        pageSize,
        totalElements,
        direction: 'backward',
        previousParams,
      });
    } else if (prevPageHref) {
      const paramsString = prevPageHref.split('?')[1];
      setLoading(true);
      api.ripe
        .getDocuments({ paramsString })
        .then(({ docs, _links, pageNumber, pageSize, totalElements }) => {
          const documentIds = docs.map((doc) => doc.documentId);
          let nextPageHref = '';
          let prevPageHref = '';
          if (_links && _links.next && _links.next.href) {
            nextPageHref = _links.next.href;
          }
          if (_links && _links.prev && _links.prev.href) {
            prevPageHref = _links.prev.href;
          }
          history.push(`${urlBase}/${documentIds[documentIds.length - 1]}`, {
            documentIds,
            nextPageHref,
            prevPageHref,
            pageNumber: pageNumber + 1,
            pageSize,
            totalElements,
            direction: 'backward',
            previousParams,
          });
        })
        .catch((error) => {
          setLoading(false);
          const { message } = processError(error);
          notification.error({ message });
        });
    } else {
      history.push(urlBase, { previousParams });
    }
  }, [history, id, location.state, previousParams, type]);

  const getRipeData = useCallback(async () => {
    // Load the document
    api.ripe
      .getDocument(id)
      .then(async (document: RipeCompanyInvoice) => {
        // Check if the document is currently locked
        const { lockStatus, lockedBy } = document;
        if (lockStatus === 'LOCKED' && lockedBy !== username) {
          // Document is locked by another user
          let message = `This document is locked for editing${
            lockedBy && ` by ${lockedBy}`
          }`;
          const direction = location.state?.direction;
          if (direction && direction === 'forward') {
            message += ` Moving to the next one.`;
            notification.info({ message });
            setTimeout(() => {
              moveToNextBill();
            }, 3000);
          } else if (direction && direction === 'backward') {
            message += ` Moving to the next one.`;
            notification.info({ message });
            setTimeout(() => {
              moveToPreviousBill();
            }, 3000);
          } else {
            message += ` Going back to list of bills.`;
            notification.info({ message });
            setTimeout(() => {
              moveToNextBill();
            }, 3000);
          }
        } else {
          // Document is either unlocked, or locked by self
          if (lockStatus !== 'LOCKED') {
            // Document is unlocked. Lock it.
            await api.ripe.lockDocument({ documentId: id }).then((document) => {
              setDocument(document);
            });
          } else {
            // Document is already locked by self. Safe to proceed.
            setDocument(document);
          }
        }
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [id, moveToNextBill, moveToPreviousBill, location.state, username]);

  const getBillData = useCallback(() => {
    // Get data from regular invoice endpoint
    api.invoices
      .getSingle(id)
      .then(async (invoice: Invoice) => {
        let userNotes = '-';
        if (invoice.ripeBillDocumentId) {
          try {
            const document = await api.ripe.getDocument(
              invoice.ripeBillDocumentId
            );
            userNotes = document.userNotes || '-';
          } catch (e) {}
        }

        const authorisedInvoice: AuthorisedInvoice = {
          invoiceAmount: invoice.total,
          invoiceNumber: invoice.billNumber,
          issueDate: moment(invoice.issueDate).format('DD MMMM YYYY'),
          orderNumber: invoice.billOrderNumber || '-',
          supplierName: invoice.issuedByCompanyName,
          userNotes,
        };

        setAuthorisedInvoice(authorisedInvoice);

        setBillNumber(invoice.billNumber);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (type === RipeType.AUTHORISED) {
      // Coming from normal bills table
      getBillData();
    } else {
      // Coming from one of the RIPE tables
      getRipeData();
    }

    //api.ripe.getInvoice(id).then((url: string) => setUrl(url));
    let idToGetImage = id;
    if (type === RipeType.AUTHORISED) {
      const { rid } = queryString.parse(location.search);
      if (rid && typeof rid === 'string') {
        idToGetImage = rid;
      }
    }

    setPageNotFound(false);

    api.ripe
      .getInvoicePages(idToGetImage)
      .then((pages: string[]) => {
        setPagesLength(pages ? pages.length : 0);
        const imageUrls: any[] = [];

        if (pages && pages.length > 0) {
          const apiFunction = shouldGetZippedRipeImage
            ? api.ripe.getZippedInvoiceImageByContentUrl
            : api.ripe.getInvoiceImageByContentUrl;

          Promise.allSettled(
            pages.map(async (page, index) => {
              const imageUrl = shouldGetZippedRipeImage
                ? await apiFunction(page)
                : 'data:image/png;base64,' + (await apiFunction(page));
              imageUrls.push({
                index: index,
                data: imageUrl,
              });
            })
          ).then(() => {
            setUrls(
              imageUrls
                ?.sort((a, b) => a.index - b.index)
                ?.map((pageUrl) => pageUrl.data)
            );
          });
        }
      })
      .catch((error) => {
        const { status } = processError(error);
        if (status === 404) {
          setPageNotFound(true);
        }
      });
  }, [
    id,
    getBillData,
    getRipeData,
    location.search,
    shouldGetZippedRipeImage,
    type,
  ]);

  useEffect(() => {
    if (location.state) {
      const { pageNumber, pageSize, totalElements, documentIds } =
        location.state;
      setTotalElements(totalElements);
      const currentItemIndex = documentIds.indexOf(id);
      const currentItemNumber =
        (pageNumber - 1) * pageSize + currentItemIndex + 1;
      setCurrentItemNumber(currentItemNumber);
    }
  }, [location, id]);

  type HandleDocumentActionParams = {
    action: 'CONFIRM' | 'DELETE';
    successSnackbarVariant: 'success' | 'info';
    successMessage: 'Document confirmed' | 'Document deleted';
    setLoading: typeof setConfirming | typeof setDeleting;
  };

  type ReviewDocumentPayload = {
    state: 'CONFIRM' | 'DELETE';
    documentId: RipeCompanyInvoice['documentId'];
  };

  const handleDocumentAction = ({
    action,
    successSnackbarVariant,
    successMessage,
    setLoading,
  }: HandleDocumentActionParams) => {
    setLoading(true);
    if (document) {
      const payload: ReviewDocumentPayload = {
        state: action,
        documentId: document?.documentId,
      };
      api.ripe
        .reviewDocument(payload)
        .then(() => {
          setLoading(false);
          notification[successSnackbarVariant]({ message: successMessage });
          moveToNextBill();
        })
        .catch((error) => {
          const { status, message } = processError(error);
          const documentNeedsLocking = shouldLockDocument({
            status,
            errorMessage: message,
          });
          if (documentNeedsLocking) {
            api.ripe
              .lockDocument({ documentId: id })
              .then(() => {
                api.ripe
                  .reviewDocument(payload)
                  .then(() => {
                    notification[successSnackbarVariant]({
                      message: successMessage,
                    });
                    moveToNextBill();
                  })
                  .catch((error) => {
                    const { message } = processError(error);
                    notification.error({ message });
                  })
                  .finally(() => setLoading(false));
              })
              .catch((error) => {
                setLoading(false);
                const { message } = processError(error);
                notification.error({ message });
              });
          } else {
            setLoading(false);
            notification.error({ message });
          }
        });
    }
  };

  const handleConfirm = () => {
    handleDocumentAction({
      action: 'CONFIRM',
      successSnackbarVariant: 'success',
      successMessage: 'Document confirmed',
      setLoading: setConfirming,
    });
  };

  const handleDelete = () => {
    handleDocumentAction({
      action: 'DELETE',
      successSnackbarVariant: 'info',
      successMessage: 'Document deleted',
      setLoading: setDeleting,
    });
  };

  const handleSendToPendingApproval = () => {
    if (!document) {
      return;
    }
    const updatedDocument: RipeCompanyInvoice = {
      ...document,
      processingStatus: 'PENDING_APPROVAL',
    };
    setSendingForReview(true);
    api.ripe
      .patchDocumentProcessingStatus(updatedDocument)
      .then(() => {
        const message =
          'Document sent for pending approval. Moving to next document';
        notification.success({ message });
        moveToNextBill();
      })
      .catch((error) => {
        const { status, message } = processError(error);
        const documentNeedsLocking = shouldLockDocument({
          status,
          errorMessage: message,
        });
        if (documentNeedsLocking) {
          lockDocument(updatedDocument);
        } else {
          notification.error({ message });
        }
      })
      .finally(() => setSendingForReview(false));
  };

  const handleSendForReview = () => {
    if (!document) {
      return;
    }
    if (type !== RipeType.VARIANCE) {
      const message = 'Cannot send this document for review';
      notification.error({ message });
    }
    const updatedDocument: RipeCompanyInvoice = {
      ...document,
      processingStatus: 'RETRY',
    };
    setSendingForReview(true);
    api.ripe
      .putDocument(updatedDocument)
      .then(() => {
        const message = 'Document sent for review. Moving to next document';
        notification.success({ message });
        moveToNextBill();
      })
      .catch((error) => {
        const { status, message } = processError(error);
        const documentNeedsLocking = shouldLockDocument({
          status,
          errorMessage: message,
        });
        if (documentNeedsLocking) {
          lockDocument(updatedDocument);
        } else {
          notification.error({ message });
        }
      })
      .finally(() => setSendingForReview(false));
  };

  const lockDocument = (document: RipeCompanyInvoice) => {
    api.ripe
      .lockDocument({ documentId: id })
      .then(() => {
        api.ripe
          .putDocument(document)
          .then(() => {
            const message = 'Document sent for review. Moving to next document';
            notification.success({ message });
            moveToNextBill();
          })
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          });
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      });
  };

  let breadcrumbs: {
    label: string;
    href?: string;
    onClick?: () => void;
  }[] = [
    {
      label: 'Home',
      href: '/',
      onClick: () => {
        api.ripe.unlockDocument({ documentId: id });
        history.push('/');
      },
    },
    {
      label: 'Bills',
      href: '/bills',
      onClick: () => {
        api.ripe.unlockDocument({ documentId: id });
        history.push('/bills');
      },
    },
  ];

  if (type === RipeType.PENDING_REVIEW) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Pending review',
        href: '/bills/pending-review',
        onClick: () => {
          api.ripe.unlockDocument({ documentId: id });
          history.push('/bills/pending-review', { previousParams });
        },
      },
      {
        label: 'Review bill',
      },
    ];
  } else if (type === RipeType.PENDING_APPROVAL) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Pending Approval',
        href: '/bills/pending-approval',
        onClick: () => {
          api.ripe.unlockDocument({ documentId: id });
          history.push('/bills/pending-approval', { previousParams });
        },
      },
      {
        label: 'View bill',
      },
    ];
  } else if (type === RipeType.PROCESSING) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Processing',
        href: '/bills/processing',
        onClick: () => {
          api.ripe.unlockDocument({ documentId: id });
          history.push('/bills/processing', { previousParams });
        },
      },
      {
        label: 'View bill',
      },
    ];
  } else if (type === RipeType.VARIANCE) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Bills with variance',
        href: '/bills/variances',
        onClick: () => {
          api.ripe.unlockDocument({ documentId: id });
          history.push('/bills/variances', { previousParams });
        },
      },
      {
        label: 'Review bill',
      },
    ];
  } else if (type === RipeType.BLOCKED) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Blocked',
        href: '/bills/blocked',
        onClick: () => {
          api.ripe.unlockDocument({ documentId: id });
          history.push('/bills/blocked', { previousParams });
        },
      },
      {
        label: 'Blocked bill',
      },
    ];
  } else if (type === RipeType.UNMATCHED) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Unmatched bills',
        href: '/bills/unmatched',
        onClick: () => {
          api.ripe.unlockDocument({ documentId: id });
          history.push('/bills/unmatched', { previousParams });
        },
      },
      {
        label: 'View bill',
      },
    ];
  } else if (type === RipeType.AUTHORISED) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: billNumber,
      },
    ];
  }

  let title = 'Relay';
  switch (type) {
    case RipeType.PENDING_REVIEW:
      title += ' | Review pending bill';
      break;
    case RipeType.PENDING_APPROVAL:
      title += ' | Approval pending bill';
      break;
    case RipeType.PROCESSING:
      title += ' | View bill in processing state';
      break;
    case RipeType.VARIANCE:
      title += ' | Review bill having variance';
      break;
    case RipeType.BLOCKED:
      title += ' | View blocked bill';
      break;
    case RipeType.UNMATCHED:
      title += ' | View unmatched bill';
      break;
  }

  return loading ? (
    <AlternatePageLayout loading={loading}>
      <Loading />
    </AlternatePageLayout>
  ) : (
    <AlternatePageLayout
      breadcrumbs={breadcrumbs}
      onClose={() => {
        api.ripe.unlockDocument({ documentId: id });
        if (type === RipeType.PENDING_REVIEW) {
          history.push('/bills/pending-review', { previousParams });
        } else if (type === RipeType.PENDING_APPROVAL) {
          history.push('/bills/pending-approval', { previousParams });
        } else if (type === RipeType.PROCESSING) {
          history.push('/bills/processing', { previousParams });
        } else if (type === RipeType.VARIANCE) {
          history.push('/bills/variances', { previousParams });
        } else if (type === RipeType.BLOCKED) {
          history.push('/bills/blocked', { previousParams });
        } else if (type === RipeType.UNMATCHED) {
          history.push('/bills/unmatched', { previousParams });
        } else if (type === RipeType.AUTHORISED) {
          history.go(-1);
        }
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <InvoiceContext.Provider value={contextValue}>
        <ReviewContainer gutter={spacing.gutterNumbers.lg}>
          <Col span={8}>
            <EditInfo
              onUpdate={() => {
                setHasEdited(true);
              }}
              posAmount={document ? document.posAmount : ''}
              matchStatus={document ? document.matchStatus : ''}
              documentId={match.params.id}
              document={document}
              setDocument={setDocument}
              type={type}
              loading={loading}
              editingConfirmedBill={editingConfirmedBill}
              setEditingConfirmedBill={setEditingConfirmedBill}
              history={history}
              shouldShowSupplierName={shouldShowSupplierName}
              authorisedInvoice={authorisedInvoice}
            >
              {type === RipeType.UNMATCHED && hasEdited && <EditedNotice />}
              {type !== RipeType.AUTHORISED && (
                <Actions
                  moveToNextBill={moveToNextBill}
                  moveToPreviousBill={moveToPreviousBill}
                  handleConfirm={handleConfirm}
                  handleDelete={handleDelete}
                  handleSendToPendingApproval={handleSendToPendingApproval}
                  handleSendForReview={handleSendForReview}
                  confirming={confirming}
                  deleting={deleting}
                  sendingForReview={sendingForReview}
                  editingConfirmedBill={editingConfirmedBill}
                  editingInfo={editingInfo}
                  enableSendToPendingApproval={sendToPendingApprovalEnabled}
                  enableEditingConfirmedBill={() => {
                    setEditingConfirmedBill(true);
                  }}
                  disableEditingConfirmedBill={() => {
                    setEditingConfirmedBill(false);
                  }}
                  documentId={match.params.id}
                  invoiceAmount={document?.invoiceAmount || null}
                  posAmount={document?.posAmount || ''}
                  type={type}
                  showNavigation={!!location.state}
                  currentItemNumber={currentItemNumber}
                  totalElements={totalElements}
                />
              )}
            </EditInfo>
          </Col>
          {pagesLength === urls?.length ? (
            <Col span={16}>
              <InvoiceImage
                pageNotFound={pageNotFound}
                sourceURLs={urls}
                id={match.params.id}
              />
            </Col>
          ) : (
            <SpinnerContainer span={16}>
              <Spin tip="Loading..." size="large" />
            </SpinnerContainer>
          )}
        </ReviewContainer>
      </InvoiceContext.Provider>
    </AlternatePageLayout>
  );
};

const mapStateToProps = ({ auth }: any): StateProps => ({
  username: auth.user.username,
});

export default connect(mapStateToProps)(InvoiceReview);
