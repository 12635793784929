const SET_TOKEN = 'relay/signup/SET_TOKEN';
const SET_COMPANY_ID = 'relay/signup/SET_COMPANY_ID';
const SET_COMPANY = 'relay/signup/SET_COMPANY';
const SET_XERO_RELAY_ACCOUNTS = 'relay/signup/SET_XERO_RELAY_ACCOUNTS';
const RESET = 'relay/signup/RESET';
const SET_MYOB_ACCOUNT = 'relay/signup/SET_MYOB_ACCOUNT';
const SET_ACCOUNT_RIGHT_COMPANY_FILES =
  'relay/signup/SET_ACCOUNT_RIGHT_COMPANY_FILES';
const LOGOUT = 'LOGOUT';

const initialState = {
  token: null,
  companyId: null,
  company: null,
  myobAccount: '',
  xeroRelayAccounts: [],
  accountRightCompanyFiles: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      return { ...state, token: payload };
    case SET_COMPANY_ID:
      return { ...state, companyId: payload };
    case SET_COMPANY:
      return { ...state, company: payload };
    case SET_XERO_RELAY_ACCOUNTS:
      return { ...state, xeroRelayAccounts: payload };
    case SET_MYOB_ACCOUNT:
      return { ...state, myobAccount: payload };
    case SET_ACCOUNT_RIGHT_COMPANY_FILES: {
      const accountRightCompanyFiles = Object.keys(payload).map(key => {
        return {
          label: payload[key],
          value: key
        };
      });
      return { ...state, accountRightCompanyFiles };
    }
    case RESET:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const setToken = token => ({ type: SET_TOKEN, payload: token });

export const setCompanyId = companyId => ({
  type: SET_COMPANY_ID,
  payload: companyId
});

export const setCompany = company => ({
  type: SET_COMPANY,
  payload: company
});

export const setXeroRelayAccounts = accounts => ({
  type: SET_XERO_RELAY_ACCOUNTS,
  payload: accounts
});

export const setMyobAccount = account => ({
  type: SET_MYOB_ACCOUNT,
  payload: account
});

export const setAccountRightCompanyFiles = files => ({
  type: SET_ACCOUNT_RIGHT_COMPANY_FILES,
  payload: files
});

export const reset = () => ({ type: RESET });
