import axios from 'axios';

const login = (user) => {
  return new Promise(function (resolve, reject) {
    axios
      .post('/login', user)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const revokeToken = (token) => {
  return new Promise(function (resolve, reject) {
    axios
      .post('/login/revoke', { token })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const signup = (payload, token) => {
  return new Promise(function (resolve, reject) {
    axios
      .post('/signup', payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getUser = (token) => {
  return new Promise(function (resolve, reject) {
    axios
      .get('/user', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(({ data }) => resolve(data.user))
      .catch((error) => reject(error));
  });
};

const putUser = (user) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`/user/${user.id}`, user)
      .then(({ data }) => {
        resolve(data.user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const resetPassword = (username) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/user/forgot-password', username)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const resetPasswordAfterToken = ({ token, passwordData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`user/reset-password?token=${token}`, passwordData)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const refreshToken = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        '/login/refresh_token',
        { token: token },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(({ data }) => resolve(data.accessToken))
      .catch((error) => reject(error));
  });
};

export default {
  login,
  revokeToken,
  signup,
  getUser,
  putUser,
  resetPassword,
  resetPasswordAfterToken,
  refreshToken,
};
