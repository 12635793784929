import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AutoSuggest from 'react-autosuggest';
import { MIN_SEARCH_LENGTH } from 'values';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';

import './autosuggest.css';
import api from 'api';
import { colors } from 'themes';

import { SuggestedCompany } from 'types/Company';
import debounce from 'lodash.debounce';

const { Text } = Typography;

const Container = styled.div`
  position: relative;
  right: 0;
`;

const Error = styled.span`
  color: ${colors.error};
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 10px;
`;
const Empty = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loading = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  countryCode?: 'AU' | 'NZ';
  onSelectCompany: (company: SuggestedCompany) => void;
  error: string;
  selectedCompanyName?: string;
  placeholder: string;
  fromCreateInvoice?: boolean;
  disabled?: boolean;
  onInput?: (value: string) => void;
  suffix?: any;
  style?: any;
  hidePrefix?: boolean;
  isInternalSearchOnlyEnabled?: boolean;
};

const renderInputComponent = ({
  fromCreateInvoice,
  hidePrefix,
  ...inputProps
}: any) => {
  if (fromCreateInvoice) {
    return (
      <Input
        size="large"
        {...inputProps}
        prefix={hidePrefix ? null : <SearchOutlined />}
      />
    );
  } else {
    return <Input size="large" {...inputProps} />;
  }
};

const getSuggestionValue = (suggestion: any) => {
  return suggestion.companyName;
};

const CompanyName: React.FC<Props> = ({
  countryCode,
  onSelectCompany,
  error,
  selectedCompanyName,
  placeholder,
  fromCreateInvoice,
  disabled,
  onInput,
  suffix,
  style,
  hidePrefix,
  isInternalSearchOnlyEnabled,
}) => {
  const [companyName, setCompanyName] = useState<string>(
    selectedCompanyName || ''
  );
  const [highlighted, setHighlighted] = useState<SuggestedCompany | null>(null);
  const [searchResults, setSearchResults] = useState<SuggestedCompany[]>([]);
  const [loading, setLoading] = useState(false);
  const [emptyList, setEmptyList] = useState(false);

  const companyId = useSelector((state: any) => state.auth?.user?.companyId);

  useEffect(() => {
    if (selectedCompanyName === null) {
      setCompanyName('');
      return;
    }
  }, [selectedCompanyName]);

  //handle reder suggestions
  const renderSuggestion = (suggestion: any) => {
    if (suggestion.suggestionStatus === 'Loading') {
      return <Loading />;
    }
    if (suggestion.suggestionStatus === 'Empty') {
      return <Empty>{`No matches for ${companyName}`}</Empty>;
    } else {
      return <span>{suggestion.companyName}</span>;
    }
  };

  // What happens when the input value changes.
  // We need to set the value prop.
  const onChange = (event: any, { newValue }: { newValue: string }) => {
    setCompanyName(newValue);
    !!onInput && newValue.length > 2 && onInput(newValue);
  };

  const onBlur = () => {
    if (highlighted) {
      onSelectCompany(highlighted);
    } //&& (companyName.length> 2)
    !!onInput && !selectedCompanyName && !highlighted && onInput('value');
  };

  const { useCallback } = React;

  const getSuggestionResults = ({ value }: { value: string }) => {
    setLoading(true);
    debounceLoadData({ value });
  };

  const doInternalSearch = ({ searchTerm }: { searchTerm: string }) => {
    return new Promise((resolve, reject) => {
      api.company
        .getCounterParties({ companyId, counterPartyName: searchTerm })
        .then((counterPartiesResponse) => {
          const companies = counterPartiesResponse.counterParties
            .slice(0, 5)
            .map((counterPartyItem) => ({
              companyName:
                counterPartyItem.counterParty.counterParty.companyName,
            }));
          resolve(companies);
        });
    });
  };

  const doCompanyOfficeSearch = ({
    searchTerm,
    countryCode,
  }: {
    searchTerm: string;
    countryCode?: 'AU' | 'NZ';
  }) => {
    return new Promise((resolve, reject) => {
      api.company
        .searchCompanies({
          searchTerm,
          countryCode: countryCode === 'AU' ? 'AU' : undefined,
        })
        .then((results: any) => {
          // Trim the results to first 5 elements, and then create an array of company names.
          const companies = results.slice(0, 5).map((r: any) => {
            if (countryCode && countryCode === 'AU') {
              return {
                companyName: r.Name,
                businessNumber: r.CompanyID,
                registrationNumber: r.CompanyID,
              };
            } else {
              return {
                nzbn: r.nzbn,
                businessNumber: r.nzbn,
                companyName: r.entityName,
                registrationNumber: r.sourceRegisterUniqueId,
                industryCode: r.classifications.length
                  ? r.classifications
                      .map((c: any) => c.classificationCode)
                      .join(',')
                  : '',
                industryCodeDescription: r.classifications.length
                  ? r.classifications
                      .map((c: any) => c.classificationDescription)
                      .join(',')
                  : '',
              };
            }
          });
          resolve(companies);
        });
    });
  };

  // Let's call the search API to show suggestions.
  const getResults = ({ value }: { value: string }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    setEmptyList(false);
    setSearchResults([]);
    const operation = isInternalSearchOnlyEnabled
      ? doInternalSearch
      : doCompanyOfficeSearch;
    operation({ searchTerm: value, countryCode }).then((companies: any) => {
      if (inputLength >= MIN_SEARCH_LENGTH && companies.length === 0) {
        setEmptyList(true);
      } else {
        setSearchResults(companies);
      }
      setLoading(false);
    });
  };

  const debounceLoadData = useCallback(debounce(getResults, 1000), []);

  const clearSuggestions = () => {
    setSearchResults([]);
  };

  // User has selected an entry from suggestions.
  // Let's now filter the list of invoices/bills.
  const onSuggestionSelected = (
    _: any,
    { suggestion }: { suggestion: SuggestedCompany }
  ) => {
    !emptyList &&
      !loading &&
      suggestion.suggestionStatus !== 'Loading' &&
      suggestion.suggestionStatus !== 'Empty' &&
      onSelectCompany(suggestion);
  };

  const onSuggestionHighlighted = ({
    suggestion,
  }: {
    suggestion: SuggestedCompany;
  }) => {
    !emptyList && !loading && setHighlighted(suggestion);
  };

  // We need to show suggestions only if user has typed atleast 3 characters (excluding space characters)
  // and if the input is not disabled.
  const shouldRenderSuggestions = (value: string) =>
    value.trim().length >= MIN_SEARCH_LENGTH && !disabled;

  const inputProps = {
    error,
    fromCreateInvoice,
    onBlur,
    disabled,
    onChange,
    suffix,
    style,
    placeholder: placeholder ? placeholder : 'Search the companies register...',
    value: companyName,
    hidePrefix,
  };

  return (
    <Container>
      <AutoSuggest
        suggestions={
          loading
            ? [
                {
                  suggestionStatus: 'Loading',
                  companyName: '',
                  nzbn: '',
                  businessNumber: '',
                },
              ]
            : emptyList
            ? [
                {
                  suggestionStatus: 'Empty',
                  companyName: '',
                  nzbn: '',
                  businessNumber: '',
                },
              ]
            : searchResults
        }
        onSuggestionsFetchRequested={getSuggestionResults}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionHighlighted={onSuggestionHighlighted}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default CompanyName;
