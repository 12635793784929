import React from 'react';
import styled from 'styled-components/macro';

import spacing from 'styles/layout/spacing';
import colors from 'colors';
import { FileImageOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  pageNotFound: boolean;
  sourceURLs: string[];
  id: string;
};

const Container = styled.div`
  height: fit-content;
  min-height: 100%;
  max-height: calc(100vh - 40px - 2 * ${spacing.gutter.lg});
  border-radius: 4px;
  background-color: ${colors.white};
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: height 300ms;
`;

const StyledFileImageOutlined = styled(FileImageOutlined)`
  font-size: 50px;
  color: ${colors.greys200};
  margin: calc(50% - 50px) auto;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  &:not(:last-child) {
    border-bottom: ${spacing.gutter.lg} solid ${colors.greys100};
  }
`;

const NOT_FOUND_MESSAGE =
  'This document has been archived. To retrieve it, please contact Relay support.';

export const InvoiceImage: React.FC<Props> = ({
  pageNotFound,
  sourceURLs,
  id,
}) => {
  return (
    <Container id={id}>
      {pageNotFound ? (
        <Text
          css={`
            margin-top: 25%;
          `}
          type="secondary"
        >
          {NOT_FOUND_MESSAGE}
        </Text>
      ) : sourceURLs && sourceURLs.length > 0 ? (
        sourceURLs.map((src: string, index: number) => (
          <Image src={src} key={index} id={`image-${index}`} />
        ))
      ) : (
        <StyledFileImageOutlined />
      )}
    </Container>
  );
};
