import React from 'react';
import { Table, Button, notification } from 'antd';

import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import api from 'api';
import { colors } from 'themes';
import formatter from 'utils/currencyFormatter';
import {
  getTopups,
  updatePageSize,
  updatePageNumber,
} from 'store/admin/topups';
import processError from 'utils/processError';

const Status = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${colors.greys700};
`;
const Company = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${colors.greys700};
`;
const DateTime = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${colors.greys700};
`;
const Amount = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.greys900};
`;

const TableComponent = (props) => {
  const {
    topups,
    getTopups,
    status,
    page,
    size,
    totalNumberOfTopups,
    updatePageSize,
    updatePageNumber,
    loading,
  } = props;

  const confirmTopUp = (topupDetails) => {
    api.admin.topups
      .put(topupDetails)
      .then(() => {
        getTopups({ status, page: page - 1, size });
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({
          message: 'Error',
          description: message,
        });
      });
  };
  const cancelTopUp = (topupDetails) => {
    api.admin.topups
      .put(topupDetails)
      .then(() => {
        getTopups({ status, page: page - 1, size });
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({
          message: 'Error',
          description: message,
        });
      });
  };

  const columns = [
    {
      title: 'Date',
      key: 'Date',
      render: (record) => {
        const { topup } = record;
        return (
          <DateTime>
            {moment(
              topup.topupStatus === 'COMPLETED' ||
                topup.topupStatus === 'CANCELLED'
                ? topup.modificationTime
                : topup.creationTime
            )
              .format('DD-MM-YYYY')
              .valueOf()}
          </DateTime>
        );
      },
    },
    {
      title: 'Time',
      key: 'Time',
      render: (record) => {
        const { topup } = record;
        return (
          <DateTime>
            {moment(
              topup.topupStatus === 'COMPLETED' ||
                topup.topupStatus === 'CANCELLED'
                ? topup.modificationTime
                : topup.creationTime
            )
              .format('HH:mm:ss')
              .valueOf()}
          </DateTime>
        );
      },
    },
    {
      title: 'Status',
      key: 'Status',
      render: (record) => {
        const { topup } = record;
        return (
          <Status>
            {topup.topupStatus === 'PENDING'
              ? 'Pending'
              : topup.topupStatus === 'UNKNOWN'
              ? 'Unknown'
              : topup.topupStatus === 'CANCELLED'
              ? 'Cancelled'
              : topup.topupStatus === 'EXPIRED'
              ? 'Expired'
              : 'Completed'}
          </Status>
        );
      },
    },
    {
      title: 'Company',
      key: 'Company',
      render: (record, index) => {
        const { companyName } = record._links;
        const company = companyName.href.split('companyName=')[1];
        return <Company>{company}</Company>;
      },
    },
    {
      title: 'Amount',
      key: 'Amount',
      render: (record) => {
        const { topup } = record;
        return <Amount>{formatter.format(topup.topupAmount)}</Amount>;
      },
    },
    {
      title: status !== 'COMPLETED' || status !== 'CANCELLED' ? 'Action' : '',
      key: 'Action',
      render: (record) => {
        const { topup } = record;
        return (
          topup.topupStatus !== 'COMPLETED' &&
          topup.topupStatus !== 'EXPIRED' &&
          topup.topupStatus !== 'CANCELLED' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                small
                transparent
                onClick={() =>
                  confirmTopUp({
                    ...topup,
                    topupStatus: 'COMPLETED',
                  })
                }
              >
                CONFIRM
              </Button>
              <Button
                small
                transparent
                onClick={() =>
                  cancelTopUp({
                    ...topup,
                    topupStatus: 'CANCELLED',
                  })
                }
              >
                CANCEL
              </Button>
            </div>
          )
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={topups}
      loading={loading}
      pagination={{
        current: page,
        pageSize: size,
        total: totalNumberOfTopups,
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        onShowSizeChange: (current, size) => {
          updatePageSize(size);
          updatePageNumber(1);
          getTopups({ status, page: 0, size });
        },
        onChange: (page, pageSize) => {
          getTopups({ status, page: page - 1, size: pageSize });
          updatePageNumber(page);
          updatePageSize(pageSize);
        },
      }}
    />
  );
};

const mapStateToProps = ({ admin }) => ({
  page: admin.topups.page,
  size: admin.topups.size,
  topups: admin.topups.topups,
  loading: admin.topups.loading,
  totalNumberOfTopups: admin.topups.totalNumberOfTopups,
});

export default connect(mapStateToProps, {
  getTopups,
  updatePageSize,
  updatePageNumber,
})(TableComponent);
