import React from 'react';
import { Button } from 'antd';

type Props = {
  disabled?: boolean,
  label: string,
  loading?: boolean,
  onClick: () => void
}

const PrimaryButton: React.FC<Props> = (props) => {
  const { label, onClick, disabled, loading } = props;
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      type="primary"
      size='large'
      style={styles.button}
    >
      {label}
    </Button>
  );
};

const styles = {
  button: {
    width: 120
  },
};

export default PrimaryButton;
