import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import spacing from 'styles/layout/spacing';

const ButtonContainer = styled.div`
  margin-top: ${spacing.gutter.sm};
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  onClick: () => void;
};

export const AddRowButton: React.FC<Props> = ({ onClick }) => {
  return (
    <ButtonContainer>
      <Button
        icon={<PlusOutlined />}
        onClick={onClick}
        type="ghost"
        size="small"
      >
        Add Row
      </Button>
    </ButtonContainer>
  );
};
