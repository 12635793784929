import React from 'react';
import styled from 'styled-components/macro';
import { Col, Input, Row, Select, Space, Typography } from 'antd';
import { processAmount } from 'utils';
import { AmountOperator } from 'types/RipeCompanyInvoice';
import { FilterItem } from 'ds';

const { Text } = Typography;

const { Option } = Select;

const InputContainer = styled.div`
  padding: 7px 11px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

type Props = {
  amount: string | null;
  handleChangeAmount: (value: string) => void;
  handleChangeHigherAmount: (value: string) => void;
  handleChangeAmountOperator: (operator: AmountOperator) => void;
  setAmountChecked: (flag: boolean) => void;
  higherAmount: string | null;
  isAmountChecked: boolean;
  amountOperator: AmountOperator;
};

const Amount: React.FC<Props> = (props) => {
  const {
    amount,
    amountOperator,
    higherAmount,
    isAmountChecked,
    handleChangeAmount,
    handleChangeHigherAmount,
    handleChangeAmountOperator,
    setAmountChecked,
  } = props;

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const processedAmount = processAmount({
      value,
      defaultValue: amount,
    });
    handleChangeAmount(processedAmount || '');
  };

  const handleHigherAmountInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const processedAmount = processAmount({
      value,
      defaultValue: higherAmount,
    });
    handleChangeHigherAmount(processedAmount || '');
  };

  const handleAmountInputBlur = () => {
    handleChangeAmount(amount ? parseFloat(amount).toFixed(2) : '');
  };

  const handleHigherAmountInputBlur = () => {
    handleChangeHigherAmount(
      higherAmount ? parseFloat(higherAmount).toFixed(2) : ''
    );
  };

  return (
    <FilterItem
      label="Amount Due"
      isChecked={isAmountChecked}
      handleCheck={setAmountChecked}
    >
      {isAmountChecked && (
        <InputContainer>
          <Row gutter={12}>
            <Col span={15}>
              <FlexContainer>
                <Select
                  defaultValue="eq"
                  css={`
                    width: 100%;
                  `}
                  size="small"
                  value={amountOperator}
                  onChange={handleChangeAmountOperator}
                >
                  <Option value="eq">is equal to</Option>
                  <Option value="between">is between</Option>
                  <Option value="gt">is greater than</Option>
                  <Option value="lt">is less than</Option>
                </Select>
                {amountOperator === 'between' && (
                  <Text
                    css={`
                      position: relative;
                      top: 13px;
                    `}
                  >
                    and
                  </Text>
                )}
              </FlexContainer>
            </Col>
            <Col span={9}>
              <Space direction="vertical">
                <Input
                  onChange={handleAmountInputChange}
                  size="small"
                  value={amount || ''}
                  onBlur={handleAmountInputBlur}
                />
                {amountOperator === 'between' && (
                  <Input
                    onChange={handleHigherAmountInputChange}
                    size="small"
                    value={higherAmount ? higherAmount : ''}
                    onBlur={handleHigherAmountInputBlur}
                  />
                )}
              </Space>
            </Col>
          </Row>
        </InputContainer>
      )}
    </FilterItem>
  );
};

export default Amount;
