import { Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import styled from 'styled-components';

const WarningText = styled(Text)`
  font-size: 12px;
`;
interface CurrencyInputProps {
  isDisabled?: boolean;
  hideError?: boolean;
  min?: number;
  max?: number;
  size: 'large' | 'default' | 'small';
  autoFocus?: boolean;
  className?: string;
}

const CurrencyInput: React.FC<
  CurrencyInputProps & Omit<NumberFormatProps, 'size'>
> = ({
  className,
  value,
  isDisabled,
  hideError,
  min,
  max,
  allowNegative,
  size,
  onValueChange,
  onKeyPress,
  autoFocus,
  warning,
  placeholder,
}) => {
  const [inputError, setInputError] = useState('');
  const formattedSize: { height: string } = { height: '' };
  if (size === 'large') {
    formattedSize.height = '40px';
  } else if (size === 'default') {
    formattedSize.height = '32px';
  } else if (size === 'small') {
    formattedSize.height = '24px';
  }
  return (
    <>
      <NumberFormat
        className={className}
        customInput={Input}
        value={value}
        prefix="$"
        placeholder={placeholder}
        style={size && formattedSize}
        allowNegative={allowNegative}
        thousandSeparator={true}
        decimalScale={2}
        onValueChange={onValueChange}
        disabled={isDisabled}
        onKeyPress={(evt) => {
          if (evt.key === 'Enter' && (value === '0' || !value)) {
            if (min && 0 < min) {
              if (hideError) {
                onKeyPress && onKeyPress(evt);
              } else {
                setInputError('Input must be greater than ' + min);
              }
            }
          } else {
            if (inputError) setInputError('');
            onKeyPress && onKeyPress(evt);
          }
        }}
        autoFocus={autoFocus}
        isAllowed={({ floatValue }) => {
          if (floatValue) {
            if (min && max) {
              return min <= floatValue && floatValue < max;
            } else if (min) {
              return min <= floatValue;
            } else if (max) {
              return floatValue < max;
            }
            return true;
          }
          return true;
        }}
      />
      {warning && <WarningText type="secondary">{warning}</WarningText>}
      {!hideError && inputError && (
        <div role="alert" className="ant-form-item-explain-error">
          {inputError}
        </div>
      )}
    </>
  );
};

export default CurrencyInput;
