import { notification } from 'antd';
import api from 'api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPreferences,
  State as EntityPreferencesState,
} from 'store/entityPreferences';
import Company from 'types/Company';
import CounterParty from 'types/CounterParty';
import { processError } from 'utils';
import { isPeriodLocked } from './isPeriodLocked';

type UsePeriodLockParams = {
  counterParty: CounterParty;
  entityId: Company['id'];
  setCounterParty: (counterParty: CounterParty) => void;
  selectedMonth: number;
};

export const usePeriodLock = ({
  counterParty,
  entityId,
  setCounterParty,
  selectedMonth,
}: UsePeriodLockParams) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const issueDateMonth = moment(selectedMonth)
    .subtract(1, 'month')
    .endOf('month');

  useEffect(() => {
    dispatch(getPreferences({ entityId }));
  }, [entityId, dispatch]);

  const entityPreferences = useSelector(
    (state: { entityPreferences: EntityPreferencesState }) =>
      state.entityPreferences
  );

  const isGlobalPeriodLocked = isPeriodLocked({
    periodLockDate: entityPreferences.preferences?.periodLockDate || null,
    issueDateMonth,
  });

  const isSupplierPeriodLocked = isPeriodLocked({
    periodLockDate: counterParty?.periodLockDate || null,
    issueDateMonth,
  });

  const isLocked =
    isGlobalPeriodLocked || isSupplierPeriodLocked ? true : false;

  const handleClickPeriodLock = () => {
    if (isLocked) {
      notification.info({
        message: 'Period is already locked',
      });
      return;
    }
    if (counterParty) {
      setLoading(true);
      const payload = {
        id: counterParty.id,
        modificationTime: counterParty.modificationTime,
        value: issueDateMonth.valueOf(),
        path: '/periodLockDate' as const,
        operation: 'replace' as const,
      };
      api.company
        .patchCounterParty({
          entityId,
          counterPartyId: counterParty.id,
          payload,
        })
        .then(({ counterParty }) => {
          setCounterParty(counterParty);
          const monthName = issueDateMonth.format('MMMM');
          const message = `Locked ${monthName} invoices`;
          notification.success({ message });
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setLoading(false));
    } else {
      notification.warning({ message: 'Counterparty data is missing' });
    }
  };

  const isNextMonth = moment(selectedMonth).isAfter(moment(), 'month');

  const tooltip = isNextMonth
    ? 'Active period can not be locked'
    : isLocked
    ? 'Period Locked'
    : 'Period Unlocked';

  return {
    isLoading: isLoading || entityPreferences.isLoading,
    isLocked,
    handleClickPeriodLock,
    tooltip,
  };
};
