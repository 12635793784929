import axios from 'axios';
import { GetAccountCodesApiResponse } from 'types/AccountCode';
import { GetAgedPayablesApiResponse } from 'types/AgedPayables';
import { TrackingCategory } from 'types/TrackingCategory';

const url = '/xero';

const triggerInvoiceLoad = (token: string): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${url}/invoices/load`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getXeroRelayAccount = ({
  token,
  companyId,
}: {
  token: string;
  companyId: string;
}): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const url = `/company/${companyId}/xerorelayaccounts`;
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(url, options)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postXeroRelayAccount = ({
  token,
  companyId,
  account,
}: {
  token: string;
  companyId: string;
  account: any;
}): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const url = `/company/${companyId}/xerorelayaccount`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .post(url, account, options)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error);
      });
  });
};

const triggerAccountsLoad = (token: string): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${url}/accounts/load`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const validateOffer = ({
  token,
  invoiceId,
}: {
  token?: string;
  invoiceId: string;
}): Promise<'success'> => {
  return new Promise(function (resolve, reject) {
    const url = `xero/invoice/${invoiceId}/validate`;
    const options = {
      headers: {
        Authorization: token
          ? 'Bearer ' + token
          : axios.defaults.headers.common['Authorization'],
      },
    };
    axios
      .get(url, options)
      .then(() => {
        resolve('success');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAccounts = ({
  companyId,
  size,
  isVisibleInRelay,
}: {
  companyId: string;
  size: number;
  isVisibleInRelay: boolean;
}) => {
  return new Promise<GetAccountCodesApiResponse>(function (resolve, reject) {
    if (!companyId) {
      reject('Required parameters missing');
    }
    const params = { size, isVisibleInRelay };
    axios
      .get(`/ripe/entities/${companyId}/xero/accounts`, { params })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err));
  });
};

const getTrackingCategories = ({
  entityId,
}: {
  entityId: string;
}): Promise<TrackingCategory[]> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/ripe/entities/${entityId}/xero/tracking-categories`)
      .then(({ data }: { data: TrackingCategory[] }) => resolve(data))
      .catch((error) => reject(error));
  });

const getAgedPayables = ({
  companyId,
  page,
  size,
  sort,
  contactId,
  reportDate,
  relaySupplierCompanyId,
}: {
  companyId: string;
  page?: number;
  size?: number;
  sort?: string;
  contactId?: string;
  reportDate?: number;
  relaySupplierCompanyId?: string;
}) => {
  return new Promise<GetAgedPayablesApiResponse>(function (resolve, reject) {
    if (!companyId) {
      reject('Required parameters missing');
    }
    const params = {
      page,
      size,
      sort,
      contactId,
      reportDate,
      relaySupplierCompanyId,
    };
    axios
      .get(`/ripe/entities/${companyId}/xero/reports/aged-payables`, { params })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err));
  });
};

export default {
  triggerInvoiceLoad,
  getXeroRelayAccount,
  postXeroRelayAccount,
  triggerAccountsLoad,
  validateOffer,
  getAccounts,
  getTrackingCategories,
  getAgedPayables,
};
