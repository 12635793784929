/// <reference path='./index.d.ts'/>

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFontLoader from 'webfontloader';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
// eslint-disable-next-line
import * as types from 'styled-components/cssprop';

import { store, persistor } from 'config';

// Uncomment the following lines when API mocking needs to be enabled during development

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

require('config/api');

WebFontLoader.load({
  google: {
    families: ['Nunito Sans'],
  },
});

setUseWhatChange(process.env.NODE_ENV === 'development');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
