import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import SupplierOffer from 'types/SupplierOffer';
import { usePayInvoice } from '../hooks';
import { TableRow } from '../types';
import { Modal } from 'antd';
import { AmountStrip, InvoiceInfo, ModalHeader } from 'components';

const PayInvoice: React.FC<{
  invoice: TableRow;
  hideModal: () => void;
  supplierOffer: SupplierOffer;
  amountDue?: number;
  onSuccessPayInvoice: () => void;
}> = ({
  invoice,
  hideModal,
  supplierOffer,
  amountDue,
  onSuccessPayInvoice,
}) => {
  const { isPending, payInvoice } = usePayInvoice();

  const handleOk = () => {
    payInvoice({
      supplierOffer,
      hideModal,
      onSuccessPayInvoice,
      amountDue,
      selectedInvoiceId: invoice.key,
    });
  };

  return (
    <Modal
      closable={false}
      title={<ModalHeader onClose={hideModal} title="Pay Invoice" />}
      visible={true}
      onCancel={hideModal}
      onOk={handleOk}
      okText="Pay Now"
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ type: 'link' }}
    >
      <InvoiceInfo
        amountDue={invoice.amountDue}
        companyName={invoice.supplier}
        dueDate={invoice.dueDate}
        invoiceNumber={invoice.invoiceNumber}
        invoiceType="BILL"
      />

      <AmountStrip
        label="Cashback Available"
        value={invoice.cashback}
        css={`
          position: relative;
          left: -2%;
          width: 104%;
        `}
        greyScale
      />
    </Modal>
  );
};

export default PayInvoice;
