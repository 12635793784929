/**
 * The navigate function returned from this hook can be used to build the browser url with supplied filters,
 * and then navigate to the correponding URL
 */

import { useHistory, useLocation } from 'react-router-dom';

const useNavigate = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  /**
   * Build a browser URL with filter query params, and navigate to the URL
   * @param {Object} param
   * @param {URLSearchParams} param.params - the query params to be set.
   * @param {boolean} param.reset - indicates that the query params that currently exist in the URL should be cleared.
   */
  const navigate = ({
    params,
    reset,
  }: {
    params: URLSearchParams;
    reset?: boolean;
  }) => {
    let url = '';
    if (reset) {
      const tab = queryParams.get('tab');
      if (tab) {
        /**
         * Even if reset flag is set to true,
         * we shouldn't clear 'tab'
         * This is to make sure that the correct tab (Suppliers or Bills) is shown on Bills page
         */
        params.set('tab', tab);
      }
      url = `${location.pathname}?${params}`;
    } else {
      const combinedParams = new URLSearchParams({
        ...Object.fromEntries(queryParams),
        ...Object.fromEntries(params),
      });
      url = `${location.pathname}?${combinedParams}`;
    }

    history.push(url);
  };

  return navigate;
};

export default useNavigate;
