import React, { useEffect, useState } from 'react';
import api from 'api';
import { Button, Form, Modal, notification } from 'antd';
import { AccountCodeFormItem, ModalHeader } from 'components';
import { processError } from 'utils';
import { CounterParty } from 'types/CounterParty';
import { GlAccount } from 'types/AccountCode';

export const EditDefaultGlCode: React.FC<{
  counterParty: CounterParty;
  currentCode?: string;
  entityId: string;
  availableCodes: GlAccount[];
  handleClose: () => void;
  isOpen: boolean;
  onSuccess: (updatedCounterParty: CounterParty) => void;
}> = ({
  counterParty,
  currentCode,
  entityId,
  availableCodes,
  handleClose,
  isOpen,
  onSuccess,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [code, setCode] = useState<GlAccount | undefined>();
  const [form] = Form.useForm<{ code: string }>();

  useEffect(() => {
    setCode(
      availableCodes.find((account) => account.accountCode === currentCode)
    );
  }, [currentCode, availableCodes]);

  const handleSubmit = () => {
    const selectedCode = form.getFieldValue('code');
    const selectedAccount = availableCodes.find(
      (account) => account.accountCode === selectedCode
    );
    if (!selectedAccount) {
      notification.error({ message: 'Invalid GL Code, please try again' });
    }
    setSubmitting(true);
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: selectedAccount,
      path: '/glAccount' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((data) => {
        const message = 'Success';
        const description = `Default GL Code updated for ${counterParty.counterParty.companyName}`;
        notification.success({ message, description });
        onSuccess(data.counterParty);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setSubmitting(false));
  };

  const handleDelete = () => {
    setDeleting(true);
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: null,
      path: '/glAccount' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((data) => {
        const message = 'Success';
        const description = `Default GL Code removed for ${counterParty.counterParty.companyName}`;
        notification.success({ message, description });
        onSuccess(data.counterParty);
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Modal
      closable={false}
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{ loading: isSubmitting }}
      visible={isOpen}
      title={<ModalHeader title="Edit Default GL Code" onClose={handleClose} />}
      footer={[
        currentCode && (
          <Button
            className="tertiary-button"
            type="ghost"
            key="delete"
            onClick={handleDelete}
            loading={isDeleting}
            disabled={isSubmitting}
          >
            Remove GL Code
          </Button>
        ),
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          disabled={isDeleting}
          onClick={handleSubmit}
        >
          Confirm
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Select Default GL Code">
          <AccountCodeFormItem
            defaultValue={code}
            disabled={availableCodes.length === 0}
            placeholder={
              availableCodes.length === 0 ? 'No available codes' : undefined
            }
            name="code"
            availableAccountCodes={availableCodes}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
