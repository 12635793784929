import PasswordValidationResults from 'types/PasswordValidationResults';

const validatePassword = (password: string): PasswordValidationResults => {
  const result = {
    hasLowerCase: password.search(/[a-z]/) !== -1,
    hasUpperCase: password.search(/[A-Z]/) !== -1,
    hasSpecialChar: password.search(/[!@#$%^&*]/) !== -1,
    hasNumber: password.search(/\d/) !== -1,
    hasLength: password.length >= 8,
  };
  return result;
};

export default validatePassword;
