import React from 'react';
import { Button, Table, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import {
  GlCodeAllocation,
  GlCodeAllocationItem,
} from 'types/RipeCompanyInvoice';
import { RelayTable } from 'components';
import { formatCurrency } from 'utils';
import styled from 'styled-components';
import colors from 'colors';

const { Text } = Typography;

interface Props {
  allocation: GlCodeAllocation;
  handleDelete?: (allocation: GlCodeAllocationItem) => void;
  editable?: boolean;
  isLoading: boolean;
}

const StyledRelayTable = styled(RelayTable)<{ $editable?: boolean }>`
  min-height: auto;
  .ant-table-row {
    &:nth-child(2) {
      &:hover .ant-table-cell {
        background: ${(props) => (props.$editable ? colors.greys100 : 'none')};
      }
    }
    &:hover .ant-table-cell {
      background: ${(props) => !props.$editable && 'none'};
    }
    .ant-table-cell {
      border: ${(props) => !props.$editable && 'none !important'};
      background: ${(props) => !props.$editable && 'none'};
      padding-top: ${(props) => !props.$editable && '6px'};
      padding-bottom: ${(props) => !props.$editable && '6px'};
      &:hover {
        background: ${(props) => !props.$editable && 'none'};
      }
    }
  }
  .ant-table-summary {
    .ant-table-cell {
      border-top: ${(props) =>
        !props.$editable && '1px solid ' + colors.greys200};
      border-bottom: none;
      padding-top: ${(props) => !props.$editable && '6px'};
      padding-bottom: ${(props) => !props.$editable && '6px'};
    }
  }
`;

export const GLCodeAllocationTable: React.FC<Props> = ({
  allocation,
  handleDelete,
  editable,
  isLoading,
}) => {
  const columns = [
    {
      title: 'GL CODE',
      dataIndex: 'glCode',
      key: 'glCode',
      width: '20%',
    },
    {
      title: 'COST CENTER',
      dataIndex: 'costCenter',
      key: 'glCode',
      render: (costCenter: string) => <Text>{costCenter || '-'}</Text>,
      width: '20%',
    },
    {
      title: 'AMOUNT',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: number) => formatCurrency(amount),
      align: 'right' as const,
      width: '30%',
    },
    {
      title: 'ACTION',
      key: 'ACTION',
      render: (allocation: GlCodeAllocationItem) => (
        <Button
          onClick={() => handleDelete && handleDelete(allocation)}
          type="ghost"
          size="small"
          icon={<DeleteOutlined />}
        />
      ),
      align: 'center' as const,
      width: '20%',
    },
  ];

  if (editable) {
    columns.splice(1, 0, {
      title: 'NAME',
      key: 'name',
      dataIndex: 'name',
      width: '30%',
    });
  } else {
    columns.pop();
  }

  return (
    <StyledRelayTable
      $editable={editable}
      columns={columns}
      dataSource={allocation}
      pagination={false}
      loading={isLoading}
      scroll={{ x: 100 }}
      rowKey={(item) => item.glCode}
      summary={() => {
        if (allocation?.length > 0) {
          const totalAmount = allocation.reduce((a, b) => {
            return a + b.amount;
          }, 0);
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={editable ? 3 : undefined}
                >
                  <b>Total</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell
                  align="right"
                  index={2}
                  colSpan={editable ? 2 : undefined}
                >
                  <b>{formatCurrency(totalAmount)}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
};
