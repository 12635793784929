import React, { useContext } from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Checkbox, Empty } from 'antd';
import { FilterItem } from 'ds';
import spacing from 'styles/layout/spacing';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { PaymentTermsContext } from '../../../PaymentTermsContext';
import { getPaymentTermString, sortPaymentTerms } from 'utils';

export const PaymentTerms: React.FC<{
  value: string | null;
  handleChange: (value: string) => void;
  handleCheck: (flag: boolean) => void;
  isChecked: boolean;
}> = ({ value, handleChange, handleCheck, isChecked }) => {
  const paymentTerms = useContext(PaymentTermsContext);
  const sortedPaymentTerms = sortPaymentTerms(paymentTerms);

  const options = sortedPaymentTerms.map((paymentTerm) => {
    const paymentTermString = getPaymentTermString({
      term: paymentTerm,
      separator: '-',
      showDefaultMarker: true,
    });
    return { label: paymentTermString, value: paymentTerm.id };
  });

  const selectedPaymentTermIds = value?.split(',');

  const onChange = (checkedOptions: CheckboxValueType[]) => {
    const paymentTermIds = checkedOptions.join(',');
    handleChange(paymentTermIds);
  };

  return (
    <FilterItem
      label="Payment terms"
      isChecked={isChecked}
      handleCheck={handleCheck}
      css={`
        .ant-checkbox-group {
          padding: ${spacing.gutter.md} ${spacing.gutter.lg};
          display: flex;
          flex-direction: column;
          gap: ${spacing.gutter.sm};
        }
        .ant-checkbox-group-item {
          display: flex;
        }
      `}
    >
      {isChecked &&
        (options.length ? (
          <Checkbox.Group
            options={options}
            onChange={onChange}
            value={selectedPaymentTermIds}
          />
        ) : (
          <Empty
            description="No payment terms available"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ))}
    </FilterItem>
  );
};
