import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

import {
  AddAccountModal,
  AccountInfo,
  Actions,
  DeleteAccount,
  Head,
  SetDefaultAccount,
} from './components';
import { useWithdrawals } from './useWithdrawals';

import spacing from 'styles/layout/spacing';

const Container = styled.div`
  max-width: 550px;
  > * {
    margin-bottom: ${spacing.gutter.md};
  }
`;

export const Withdrawals: React.FC = () => {
  const {
    defaultWithdrawalAccount,
    isLoading,
    primaryAction,
    secondaryAction,

    isDeleteAccountModalVisible,
    isDeleting,
    deleteAccount,
    hideDeleteAccountModal,

    addAccountModalProps,
    setDefaultAccountProps,
  } = useWithdrawals();
  return (
    <Container>
      <Head />
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <AccountInfo account={defaultWithdrawalAccount} variant="FULL" />
          <Actions
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
          />
          <AddAccountModal {...addAccountModalProps} />
          <DeleteAccount
            account={defaultWithdrawalAccount}
            isDeleting={isDeleting}
            isVisible={isDeleteAccountModalVisible}
            onDelete={deleteAccount}
            onHide={hideDeleteAccountModal}
          />
          <SetDefaultAccount {...setDefaultAccountProps} />
        </>
      )}
    </Container>
  );
};
