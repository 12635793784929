import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes';

const Container = styled.div`
  padding: 12px 30px;
  margin-top: 14px;
  margin-bottom: 40px;
  border: 1px solid ${props => (props.isSelected ? '#286EFF' : colors.greys200)};
`;

const Text = styled.span`
  color: ${colors.greys600};
  font-size: 12px;
`;

const Name = styled(Text)`
  margin-left: 11px;
`;

const NewAccount = ({ account, option, isSelected }) => {
  return (
    <Container isSelected={isSelected}>
      <Text>{`${option}${account.code}`}</Text>
      <Name>{account.accountName}</Name>
    </Container>
  );
};

export default NewAccount;
