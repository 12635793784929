import React from 'react';
import styled from 'styled-components/macro';
import { Col, Row, Select } from 'antd';
import { FilterItem } from 'ds';

const InputContainer = styled.div`
  padding: 7px 11px;
`;

const { Option } = Select;

const BalancePayable: React.FC<{
  matchesXero: string | null;
  isChecked: boolean;
  handleChangeMatchesXero: (value: string) => void;
  handleCheck: (flag: boolean) => void;
}> = ({ matchesXero, isChecked, handleChangeMatchesXero, handleCheck }) => {
  const handleInputChange = (value: string) => {
    handleChangeMatchesXero(value);
  };

  return (
    <FilterItem
      isChecked={isChecked}
      label="Balance Payable"
      handleCheck={handleCheck}
    >
      {isChecked && (
        <InputContainer>
          <Row gutter={12} justify="center" align="middle">
            <Col span={12}>Xero Balance</Col>
            <Col span={12}>
              <Select
                style={{ width: '100%' }}
                defaultValue={
                  matchesXero === 'true' || matchesXero === 'false'
                    ? matchesXero
                    : undefined
                }
                onChange={handleInputChange}
              >
                <Option value="true">Matches</Option>
                <Option value="false">Does not match</Option>
              </Select>
            </Col>
          </Row>
        </InputContainer>
      )}
    </FilterItem>
  );
};

export default BalancePayable;
