import axios from 'axios';
import User, { UserState } from 'types/User';

type UserStateAPIResponse = {
  id: string;
  state: UserState;
  creationTime: number;
  modificationTime: number;
  lastModifiedBy: string;
};

const post = (payload: User): Promise<User> => {
  return new Promise<User>(function (resolve, reject) {
    axios
      .post('/user', payload)
      .then(({ data }) => resolve(data.user))
      .catch((error) => reject(error));
  });
};

const get = (token?: string): Promise<User> => {
  return new Promise<User>(function (resolve, reject) {
    axios
      .get('/user', {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data.user))
      .catch((error) => reject(error));
  });
};

const getState = ({
  token,
  userId,
}: {
  token?: string;
  userId: string;
}): Promise<UserStateAPIResponse> => {
  return new Promise<UserStateAPIResponse>(function (resolve, reject) {
    axios
      .get(`/user/${userId}/state`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getHistory = (userId: string): Promise<User[]> => {
  return new Promise<User[]>(function (resolve, reject) {
    axios
      .get(`/user/${userId}/history`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const put = ({
  isLoggedInAsAdmin,
  token,
  user,
}: {
  isLoggedInAsAdmin?: boolean | undefined | null;
  token: string;
  user: User;
}): Promise<User> => {
  return new Promise<User>(function (resolve, reject) {
    let url = `/user/${user.id}`;
    if (isLoggedInAsAdmin) {
      url = `/admin${url}`;
    }
    axios
      .put(url, user, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => {
        resolve(data.user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

type PatchPayload = {
  operation: 'replace';
  path: '/password' | '/phone_number';
  value: string;
};

const patch = ({
  payload,
  userId,
  token,
}: {
  payload: PatchPayload;
  userId: string;
  token?: string;
}): Promise<boolean> =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/user/${userId}`, payload, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });

const code = {
  send: ({
    token,
    userId,
  }: {
    token?: string;
    userId: string;
  }): Promise<void> => {
    return new Promise<void>(function (resolve, reject) {
      axios
        .get(`/user/${userId}/code/send`, {
          headers: {
            Authorization: token
              ? 'Bearer ' + token
              : axios.defaults.headers.common['Authorization'],
          },
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verify: ({
    token,
    userId,
    code,
  }: {
    token: string;
    userId: string;
    code: string;
  }): Promise<User> => {
    return new Promise<User>(function (resolve, reject) {
      axios
        .post(
          `/user/${userId}/code/verify`,
          { code },
          {
            headers: {
              Authorization: token
                ? 'Bearer ' + token
                : axios.defaults.headers.common['Authorization'],
            },
          }
        )
        .then(({ data }) => {
          resolve(data.user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  post,
  get,
  getState,
  getHistory,
  put,
  patch,
  code,
};
