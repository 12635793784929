import React, { useState } from 'react';
import styled from 'styled-components';
import api from 'api';
import processError from 'utils/processError';
import ModalHeader from 'components/ModalHeader';
import { setRefreshData } from 'store/ui';
import { connect } from 'react-redux';
import { Modal, Button, notification } from 'antd';
import { setBackgroundCheckPurchaserOffer } from 'store/ui';
import { colors } from 'themes';

const ConfirmationText = styled.span`
  font-size: 14px;
  color: ${colors.greys600};
`;

type Props = {
  invoiceType: 'INVOICE' | 'BILL';
  selectedInvoiceIDs: Array<string>;
  visibleDeleteModal: boolean;
  toggleDeleteInvoiceModel: () => void;
  setBackgroundCheckPurchaserOffer: (params: {
    shouldCheck: boolean;
    direction: null | 'AVAILABLE' | 'OFFERED';
  }) => void;
  setRefreshData: (flag: boolean) => void;
};

const DeleteInvoices: React.FC<Props> = ({
  invoiceType,
  selectedInvoiceIDs,
  visibleDeleteModal,
  toggleDeleteInvoiceModel,
  setBackgroundCheckPurchaserOffer,
  setRefreshData,
}) => {
  const [loading, setLoading] = useState(false);

  const hideWindow = () => {
    toggleDeleteInvoiceModel();
  };

  const totalInvoicesCount = selectedInvoiceIDs.length;

  const confirmDelete = () => {
    setLoading(true);
    if (totalInvoicesCount === 1) {
      api.invoices
        .remove(selectedInvoiceIDs[0])
        .then(() => {
          const invoiceTypeInMessage =
            invoiceType === 'INVOICE' ? 'Invoice' : 'Bill';
          const message = `${invoiceTypeInMessage} deleted`;
          notification.info({ message });
          if (invoiceType === 'BILL') {
            setBackgroundCheckPurchaserOffer({
              shouldCheck: true,
              direction: 'OFFERED',
            });
          } else {
            setBackgroundCheckPurchaserOffer({
              shouldCheck: true,
              direction: 'AVAILABLE',
            });
          }
          setRefreshData(true);
          hideWindow();
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setLoading(false));
    } else {
      const payload = {
        invoiceIds: selectedInvoiceIDs,
        type: invoiceType,
      };
      api.invoices
        .bulkDelete(payload)
        .then((response) => {
          if (response && response.results) {
            const deletedInvoicesCount = response.results.filter(
              (item) => item.status === 'SUCCESS'
            ).length;
            if (deletedInvoicesCount === 0) {
              notification.error({ message: 'Could not perform deletion' });
              return;
            }
            let message = '';
            const invoiceTypeInMessage =
              invoiceType === 'INVOICE' ? 'invoice' : 'bill';
            if (deletedInvoicesCount === totalInvoicesCount) {
              message = `Deleted ${totalInvoicesCount} ${invoiceTypeInMessage}${
                totalInvoicesCount === 1 ? '' : 's'
              }`;
            } else {
              message = `Deleted ${deletedInvoicesCount} out of ${totalInvoicesCount} ${invoiceTypeInMessage}${
                totalInvoicesCount === 1 ? '' : 's'
              }`;
            }
            notification.info({ message });
            if (invoiceType === 'BILL') {
              setBackgroundCheckPurchaserOffer({
                shouldCheck: true,
                direction: 'OFFERED',
              });
            } else {
              setBackgroundCheckPurchaserOffer({
                shouldCheck: true,
                direction: 'AVAILABLE',
              });
            }
            setRefreshData(true);
            hideWindow();
          } else {
            setRefreshData(true);
            hideWindow();
          }
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => setLoading(false));
    }
  };

  const actions = [
    <Button key="back" type="ghost" onClick={hideWindow}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={loading}
      onClick={confirmDelete}
    >
      Delete
    </Button>,
  ];

  const oneItem = totalInvoicesCount === 1 ? true : false;
  const title = `Confirm Delete`;
  return (
    <Modal
      visible={visibleDeleteModal}
      onCancel={hideWindow}
      footer={actions}
      title={<ModalHeader title={title} onClose={hideWindow} />}
      closable={false}
    >
      <ConfirmationText>
        {`Are you sure you want to delete ${totalInvoicesCount} ${
          invoiceType === 'INVOICE' ? 'invoice' : 'bill'
        }${oneItem ? '' : 's'}?`}
      </ConfirmationText>
    </Modal>
  );
};

export default connect(null, {
  setBackgroundCheckPurchaserOffer,
  setRefreshData,
})(DeleteInvoices);
