import React, { FC } from 'react';
import { Input, Form } from 'antd';
import styled from 'styled-components';

import { colors } from 'themes';

const Container = styled.div`
  .ant-input-affix-wrapper > input.ant-input {
    text-align: right !important;
    margin: 0;
    /* Chrome, Safari, Edge, Opera */
    :focus ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    :hover ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :focus ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    :hover ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
  .ant-input-prefix {
    color: ${colors.greys700};
    font-weight: bold;
  }
`;

const InputWrapper = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Amount = styled(Input)`
  max-width: 100px;
  text-align: right !important;
  padding: 5px 8px !important;
  border-color: ${colors.greys200};
`;
type Props = {
  value: number | string;
  onChange: (value: number | string) => void;
  onBlurFormat: () => void;
  error: boolean;
};

const CreditInput: FC<Props> = ({ value, onChange, onBlurFormat, error }) => {
  return (
    <Container>
      <InputWrapper validateStatus={error ? 'error' : ''}>
        <Amount
          value={value}
          prefix="$"
          type="number"
          inputMode="numeric"
          min="0.00"
          defaultValue={parseFloat('0').toFixed(2)}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlurFormat}
          onWheel={(e) => e.currentTarget.blur()}
          onKeyDown={(e) => {
            if (['ArrowUp', 'ArrowDown'].includes(e.code)) {
              e.preventDefault();
              return;
            }
          }}
          data-testid="allocate-credit-input"
        />
      </InputWrapper>
    </Container>
  );
};

export default CreditInput;
