import axios from 'axios';

const getPendingTransactions = ({
  page,
  size,
  companyId,
}: {
  page: number;
  size: number;
  companyId?: string;
}) => {
  return new Promise(function (resolve, reject) {
    let url = '/transaction';
    const params = {
      page,
      size,
      companyId,
      status: 'PENDING',
    };
    axios
      .get(url, { params })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getTransactions = ({
  page,
  size,
  companyId,
}: {
  page: number;
  size: number;
  companyId?: string;
}) => {
  return new Promise(function (resolve, reject) {
    let url = `/transaction`;
    const params = {
      page,
      size,
      companyId,
      status: 'COMPLETED',
    };
    axios
      .get(url, { params })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const exportTransactions = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get('/transaction/export')
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getTransactions,
  getPendingTransactions,
  exportTransactions,
};
