import React, { useState } from 'react';
import Button from 'components/Button';
import api from 'api';
import processError from 'utils/processError';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { hideAddBankAccountWindow, getBankAccounts } from 'store/auth';
import { showWithdrawModal } from 'store/ui';

const MAX_FILE_SIZE = 10 * 1000 * 1000; // 10 MB in bytes

const BankStatement = (props) => {
  const [selectedFile, setSelectedFile] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const [fileSelectionError, setFileSelectionError] = useState('');

  const {
    hideAddBankAccountWindow,
    verifyAccountDetails,
    user,
    getBankAccounts,
    showWithdrawModal,
  } = props;

  const handleSubmit = () => {
    this.preventDefault();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const { size, type } = file;
    if (
      type !== 'image/jpeg' &&
      type !== 'image/png' &&
      type !== 'application/pdf'
    ) {
      setFileSelectionError(
        'Unsupported file format. Please select a JPG, PNG or PDF file.'
      );
      setImagePreviewUrl('');
      setSelectedFile('');
      return;
    }
    if (size > MAX_FILE_SIZE) {
      setFileSelectionError(
        'Please choose a file which is less than 10 MB in size'
      );
      setImagePreviewUrl('');
      setSelectedFile('');
      return;
    }
    setFileSelectionError('');
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const toVerifyBankAccount = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    const { account, company } = user;

    const bankAccounts =
      account &&
      account.filter((a) =>
        company.company.connectedToXero ||
        company.company.connectedToMyobAccountRight
          ? (a.loadedBy === 'RELAY' ||
              a.loadedBy === 'XERO' ||
              a.loadedBy === 'MYOB_ACCOUNTRIGHT') &&
            a.bankAccountStatus !== 'DELETED'
          : a.loadedBy === 'RELAY' && a.bankAccountStatus !== 'DELETED'
      );
    const defaultAccount =
      bankAccounts && bankAccounts.filter((a) => a.defaultAccount)[0];

    const accountDetails = verifyAccountDetails
      ? verifyAccountDetails
      : defaultAccount;

    api.company
      .verifyBankAccount({
        formData,
        companyId: user.companyId,
        bankId: accountDetails && accountDetails.id,
      })
      .then(() => {
        const { companyId } = user;
        getBankAccounts(companyId);
        hideAddBankAccountWindow();
        setLoading(false);
        setFileSelectionError('');
        showWithdrawModal();
      })
      .catch((err) => {
        const { debugMessage, message } = processError(err);
        if (debugMessage.includes('size exceeded')) {
          setFileSelectionError(
            'Please choose a file which is less than 10 MB in size'
          );
        } else {
          setFileSelectionError(message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      {imagePreviewUrl && (
        <ImageContainer>
          <img width="150" alt="bank statement" src={imagePreviewUrl} />
        </ImageContainer>
      )}

      <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
        <input
          type="file"
          onChange={(e) => handleImageChange(e)}
          accept="image/jpeg,image/png,application/pdf"
        />
      </form>

      {fileSelectionError ? (
        <p style={{ color: 'red', marginTop: 10 }}>{fileSelectionError}</p>
      ) : null}
      <div style={styles.row}>
        <Button
          transparent
          onClick={() => {
            hideAddBankAccountWindow();
          }}
          label={'Cancel'}
          style={{ marginRight: 5 }}
        />

        <Button
          raised
          disabled={!selectedFile || loading || fileSelectionError}
          loading={loading}
          onClick={() => {
            toVerifyBankAccount();
          }}
          label="Submit"
        />
      </div>
    </>
  );
};

const ImageContainer = styled.div`
  background-color: transparent;
`;

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 30,
    marginBottom: 10,
  },
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  verifyAccountDetails: auth.verifyAccountDetails,
});

const mapDispatchToProps = {
  hideAddBankAccountWindow,
  getBankAccounts,
  showWithdrawModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankStatement);
