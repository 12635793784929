import axios from 'axios';
import Invoice, {
  BulkDeleteAPIResponse,
  InvoicesAPIResponse,
} from 'types/Invoice';

const URL = '/invoice';

const pay = (invoice: Invoice) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`${URL}/${invoice.id}`, invoice)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const put = (invoice: Invoice) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`${URL}/${invoice.id}`, invoice)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

type PatchPathMap = {
  '/include_in_purchaser_offer': boolean;
  '/contact_email': string;
};

type PatchPathType = keyof PatchPathMap;

type PatchItem = {
  [K in PatchPathType]: {
    id: string;
    path: K;
    operation: 'replace';
    value: PatchPathMap[K];
  };
}[PatchPathType];

const patch = (
  payload: PatchItem[]
): Promise<
  {
    id: string;
    updated: boolean;
    message: null | string;
  }[]
> => {
  return new Promise(function (resolve, reject) {
    axios
      .patch(`${URL}`, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const remove = (invoiceId: string): Promise<void> => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`${URL}/${invoiceId}`)
      .then(({ data }) => resolve())
      .catch((error) => reject(error));
  });
};

const bulkDelete = ({
  invoiceIds,
  type,
}: {
  invoiceIds: Array<string>;
  type: 'INVOICE' | 'BILL';
}): Promise<BulkDeleteAPIResponse> => {
  return new Promise((resolve, reject) => {
    if (!invoiceIds) {
      reject('List of invoice ids needed');
      return;
    }
    if (!type) {
      reject('Invoice type needed');
      return;
    }
    if (type !== 'INVOICE' && type !== 'BILL') {
      reject('Invalid invoice type');
      return;
    }
    axios
      .delete(`${URL}/batch`, {
        data: { invoiceIds, type },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });
};

const filter = ({
  abortController,
  token,
  url,
}: {
  abortController?: AbortController;
  token?: string;
  url: string;
}): Promise<InvoicesAPIResponse> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/invoice${url}`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
        signal: abortController?.signal,
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getSingle = (id: string, token?: string): Promise<Invoice> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/invoice/${id}`, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }) => resolve(data.invoice))
      .catch((error) => reject(error));
  });
};

const getNotifiedInvoices = ({
  invoiceType,
  token,
}: {
  invoiceType: 'BILL' | 'INVOICE';
  token: string;
}): Promise<InvoicesAPIResponse> => {
  return new Promise(function (resolve, reject) {
    const status =
      invoiceType === 'BILL' ? 'CASHBACK_AVAILABLE' : 'PAYMENT_READY';
    const PAGE_SIZE = 1000;
    const url = `/invoice/notified?type=${invoiceType}&token=${token}&status=${status}&size=${PAGE_SIZE}`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const post = (invoice: Invoice): Promise<Invoice> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}`, invoice)
      .then(({ data }) => resolve(data.invoice))
      .catch((error) => reject(error));
  });
};

type PostBillPayload = Pick<
  Invoice,
  | 'billNumber'
  | 'dueDate'
  | 'issuedFor'
  | 'issuedByCompanyName'
  | 'issueDate'
  | 'total'
> & {
  billGLCode?: Invoice['billGLCode'];
  createBillInXero?: boolean;
  billOrderNumber?: string;
};

const postBill = (payload: PostBillPayload): Promise<Invoice> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}`, payload)
      .then(({ data }) => resolve(data.invoice))
      .catch((error) => reject(error));
  });
};

type PostInvoicePayload = Pick<
  Invoice,
  'dueDate' | 'invoiceNumber' | 'issuedBy' | 'issuedForCompanyName' | 'total'
>;

const postInvoice = (payload: PostInvoicePayload): Promise<Invoice> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}`, payload)
      .then(({ data }) => resolve(data.invoice))
      .catch((error) => reject(error));
  });
};

const importInvoice = (invoice: any) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}/import`, invoice)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getImportProgress = (importId: string) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${URL}/import/${importId}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

export default {
  getSingle,
  getNotifiedInvoices,
  pay,
  put,
  patch,
  filter,
  post,
  postBill,
  postInvoice,
  remove,
  bulkDelete,
  importInvoice,
  getImportProgress,
};
