import React from 'react';
import { colors } from 'themes';
import CurrencyAmount from 'components/CurrencyAmount';
const Amount = (props) => {
  const {
    amount,
    label,
    containerStyle,
    discountPercentage,
    visibleDiscount,
    discountedAmount,
    tableStatus,
    discountError,
    invoiceAmount,
  } = props;
  const percentage = discountPercentage ? discountPercentage : 0;
  const crr = props.crr ? props.crr : 0;
  return (
    <div
      style={
        discountedAmount
          ? tableStatus
            ? { ...containerStyle }
            : { ...containerStyle, marginTop: 30 }
          : containerStyle
      }
    >
      <div style={styles.content}>
        <p style={styles.label} data-testid="amount-label">
          {label}
        </p>
        <CurrencyAmount
          style={styles.amount}
          amount={discountError ? invoiceAmount : amount}
        />
      </div>
      {visibleDiscount && (
        <div
          style={{ ...styles.container, ...styles.percentageContainer }}
          data-testid="amount-discount"
        >
          <span>{`${percentage.toFixed(2)}%`} Discount</span>
          <span>{`${crr.toFixed(2)}%`} APR</span>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {},
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  amount: {
    width: 100,
    height: 20,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 'normal',
    textAlign: 'right',
    color: colors.primary,
  },
  label: {
    height: 20,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 'normal',
    color: colors.primary,
  },
  percentageContainer: {
    marginLeft: 20,
  },
};

export default Amount;
