import React, { useEffect, useState } from 'react';
import api from 'api';
import { Button, Form, Modal, notification } from 'antd';
import { ModalHeader } from 'components';
import { processError } from 'utils';
import { CounterParty } from 'types/CounterParty';
import InputComponent from 'components/Input';
import styled from 'styled-components';
import colors from 'colors';
import NumberFormat from 'react-number-format';

const StyledInput = styled(InputComponent)`
  ::placeholder {
    color: ${colors.greys300};
  }
`;
const Error = styled.p`
  color: ${colors.error};
`;
export const EditExternalBankAccount: React.FC<{
  counterParty: CounterParty;
  currentExternalBankAccount?: string;
  entityId: string;
  isCountryAU: boolean;
  handleClose: () => void;
  isOpen: boolean;
  onSuccess: (updatedCounterParty: CounterParty) => void;
}> = ({
  counterParty,
  currentExternalBankAccount,
  entityId,
  isCountryAU,
  handleClose,
  isOpen,
  onSuccess,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [externalBankAccount, setExternalBankAccount] = useState('');
  const [externalBankAccountError, setExternalBankAccountError] = useState('');
  const [form] = Form.useForm<{ externalBankAccount: string }>();

  const accountNumberFormat = isCountryAU
    ? '##-####-#######-##'
    : '##-####-#######-###';

  useEffect(() => {
    setExternalBankAccount(currentExternalBankAccount ?? '');
  }, [currentExternalBankAccount]);

  const handleSubmit = () => {
    setSubmitting(true);
    if (externalBankAccount.length < 16) {
      setExternalBankAccountError('Bank account is invalid length');
      setSubmitting(false);
      return;
    }
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: externalBankAccount,
      path: '/externalBankAccountNumber' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((data) => {
        const message = 'Success';
        const description = `External Bank Account updated for ${counterParty.counterParty.companyName}`;
        notification.success({ message, description });
        onSuccess(data.counterParty);
      })
      .catch((error) => {
        const { message } = processError(error);
        setExternalBankAccountError(message);
        notification.error({ message });
      })
      .finally(() => setSubmitting(false));
  };

  const handleDelete = () => {
    setDeleting(true);
    const payload = {
      id: counterParty.id,
      modificationTime: counterParty.modificationTime,
      value: null,
      path: '/externalBankAccountNumber' as const,
      operation: 'replace' as const,
    };
    api.admin.counterParties
      .patchCounterParty({ entityId, payload })
      .then((data) => {
        const message = 'Success';
        const description = `External Bank Account deleted for ${counterParty.counterParty.companyName}`;
        notification.success({ message, description });
        onSuccess(data.counterParty);
      })
      .catch((error) => {
        const { message } = processError(error);
        setExternalBankAccountError(message);
        notification.error({ message });
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Modal
      closable={false}
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{ loading: isSubmitting }}
      visible={isOpen}
      title={
        <ModalHeader title="Edit External Bank Account" onClose={handleClose} />
      }
      footer={[
        currentExternalBankAccount && (
          <Button
            className="tertiary-button"
            type="ghost"
            key="delete"
            onClick={handleDelete}
            loading={isDeleting}
            disabled={isSubmitting}
          >
            Delete Bank Account
          </Button>
        ),
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          disabled={isDeleting}
          onClick={handleSubmit}
        >
          Confirm
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" wrapperCol={{ span: 12 }}>
        <Form.Item
          validateStatus={externalBankAccountError && 'error'}
          label="Select External Bank Account"
        >
          <NumberFormat
            customInput={StyledInput}
            isNumericString
            format={accountNumberFormat}
            placeholder={accountNumberFormat}
            value={externalBankAccount}
            onValueChange={({ value }) => {
              value && setExternalBankAccountError('');
              setExternalBankAccount(value);
            }}
            shouldReturnEvent
          />
          {externalBankAccountError && (
            <Error>{externalBankAccountError}</Error>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
