import React from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { History } from 'history';
import { Typography } from 'antd';

import { setGetVerifiedModalVisibility } from 'store/ui';
import { Modal } from 'ds';

const { Text } = Typography;

type Props = {
  history: History;
  setGetVerifiedModalVisibility: (flag: boolean) => any;
};

const VerificationNoticeModal: React.FC<Props> = ({
  history,
  setGetVerifiedModalVisibility,
}) => {
  const handleClick = (): void => {
    setGetVerifiedModalVisibility(false);
    history.push('/verify?step=beneficial_owners&list=true');
  };
  return (
    <Modal
      okText="Next"
      onOk={handleClick}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={() => {
        setGetVerifiedModalVisibility(false);
      }}
      title="Let's get verified"
      visible={true}
    >
      <Text
        css={`
          font-size: 14px;
        `}
      >
        We need to verify your account before you can continue.
      </Text>
    </Modal>
  );
};

export default connect(null, { setGetVerifiedModalVisibility })(
  VerificationNoticeModal
);
