import colors from 'colors';

const styles = {
  icon: {
    marginLeft: 10,
  },
  iconSuccess: {
    color: colors.success,
  },
  iconWarning: {
    color: colors.warning,
  },
  heading: {
    fontWeight: 700,
    marginBottom: '50px',
  },
  link: {
    position: 'relative',
    top: 2,
  } as React.CSSProperties,
  skeleton: {
    height: 48,
    marginBottom: 20,
  },
};

export default styles;
