import React, { useEffect, useState } from 'react';
import { Button, Form, notification, Switch } from 'antd';
import { updatePreferences, State } from 'store/entityPreferences';
import { useDispatch, useSelector } from 'react-redux';

const Customers: React.FC = () => {
  const dispatch = useDispatch();
  const entityId: string = useSelector(
    (store: any) => store.auth.user.companyId
  );
  const entityPreferences: State = useSelector(
    (store: any) => store.entityPreferences
  );

  const { error, isLoading, isUpdating, preferences } = entityPreferences;

  const isAcceptApprovedInvoicesFromPurchasersEnabled =
    preferences?.isAcceptApprovedInvoicesFromPurchasersEnabled;

  const [isEnabled, setEnabled] = useState(
    isAcceptApprovedInvoicesFromPurchasersEnabled
  );

  useEffect(() => {
    if (error) {
      notification.error({ message: error });
    }
  }, [error]);

  useEffect(() => {
    if (preferences) {
      setEnabled(isAcceptApprovedInvoicesFromPurchasersEnabled);
    }
  }, [preferences, isAcceptApprovedInvoicesFromPurchasersEnabled]);

  const handleChange = (checked: boolean) => {
    setEnabled(checked);
  };

  const updateAcceptApprovedInvoicesFromPurchasers = ({
    isEnabled,
  }: {
    isEnabled: boolean;
  }) => {
    if (!preferences) {
      notification.error({ message: 'Missing data' });
      return;
    }
    const payload = {
      ...preferences,
      isAcceptApprovedInvoicesFromPurchasersEnabled: isEnabled,
    };
    dispatch(updatePreferences({ entityId, payload }));
  };

  const enableAcceptInvoices = () =>
    updateAcceptApprovedInvoicesFromPurchasers({ isEnabled: true });

  const disableAcceptInvoices = () =>
    updateAcceptApprovedInvoicesFromPurchasers({ isEnabled: false });

  const handleSave = () => {
    if (isAcceptApprovedInvoicesFromPurchasersEnabled) {
      disableAcceptInvoices();
    } else {
      enableAcceptInvoices();
    }
  };

  return (
    <>
      <Form.Item
        label="Accept invoices from customers on Relay"
        valuePropName="checked"
      >
        <Switch
          checked={isEnabled}
          disabled={isLoading || isUpdating}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item>
        <Button
          disabled={isAcceptApprovedInvoicesFromPurchasersEnabled === isEnabled}
          loading={isUpdating}
          onClick={handleSave}
          type="primary"
        >
          Save
        </Button>
      </Form.Item>
    </>
  );
};

export default Customers;
