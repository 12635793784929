import api from 'api';
import BankAccount from 'types/BankAccount';
import { processError } from 'utils';
import { Dispatch } from 'redux';
import { Address } from 'types/Address';

const ADD_BANK_ACCOUNT = 'relay/bank-accounts/ADD_BANK_ACCOUNT';
const SET_BANK_ACCOUNTS = 'relay/bank-accounts/SET_BANK_ACCOUNTS';
const SET_ERROR = 'relay/bank-accounts/SET_ERROR';
const SET_LOADING = 'relay/bank-accounts/SET_LOADING';
const LOGOUT = 'LOGOUT';

type LogoutAction = {
  type: 'LOGOUT';
};

type AddBankAccountAction = {
  type: typeof ADD_BANK_ACCOUNT;
  payload: BankAccount;
};

type SetBankAccountsAction = {
  type: typeof SET_BANK_ACCOUNTS;
  payload: BankAccount[];
};

type SetErrorAction = {
  type: typeof SET_ERROR;
  payload: string;
};

type SetLoadingAction = {
  type: typeof SET_LOADING;
  payload: boolean;
};

const initialState: {
  bankAccounts: BankAccount[];
  error: string;
  isLoading: boolean;
} = { bankAccounts: [], error: '', isLoading: false };

const reducer = (
  state: typeof initialState = initialState,
  action:
    | AddBankAccountAction
    | SetBankAccountsAction
    | SetErrorAction
    | SetLoadingAction
    | LogoutAction
) => {
  switch (action.type) {
    case ADD_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload],
      };
    case SET_BANK_ACCOUNTS:
      return { ...state, bankAccounts: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

const setError = (error: string) => ({ type: SET_ERROR, payload: error });

const clearError = () => ({ type: SET_ERROR, payload: '' });

const startLoading = () => ({ type: SET_LOADING, payload: true });

const finishLoading = () => ({ type: SET_LOADING, payload: false });

export const addBankAccount = (account: BankAccount) => ({
  type: ADD_BANK_ACCOUNT,
  payload: account,
});

export const getBankAccounts = (
  companyId: string,
  countryCode: Address['countryCode']
) => {
  return (dispatch: Dispatch) => {
    dispatch(clearError());
    dispatch(startLoading());
    api.company
      .getBankAccounts({ companyId })
      .then((bankAccounts) =>
        dispatch({
          type: SET_BANK_ACCOUNTS,
          payload: bankAccounts.filter((account) => {
            if (countryCode === 'AU') {
              return (
                (account.bankAccountNumber !== null && account.bsb !== null) ||
                account.payId !== null
              );
            } else {
              return account.bankAccountNumber !== null;
            }
          }),
        })
      )
      .catch((error) => {
        const { message } = processError(error);
        dispatch(setError(message));
      })
      .finally(() => dispatch(finishLoading()));
  };
};

export default reducer;
