import axios from 'axios';
import {
  AddPaymentTermApiPayload,
  GetPaymentTermsApiResponse,
  PaymentTerm,
} from 'types/PaymentTerm';

const addPaymentTerm = ({
  entityId,
  payload,
}: {
  entityId: string;
  payload: AddPaymentTermApiPayload;
}): Promise<PaymentTerm> =>
  new Promise((resolve, reject) => {
    if (!entityId || !payload) {
      reject('Mandatory params missing');
    }
    const url = `/company/${entityId}/payment-terms`;
    axios
      .post(url, payload)
      .then(({ data }: { data: PaymentTerm }) => resolve(data))
      .catch((error) => resolve(error));
  });

const deletePaymentTerm = ({
  entityId,
  paymentTermId,
}: {
  entityId: string;
  paymentTermId: string;
}): Promise<true> =>
  new Promise((resolve, reject) => {
    if (!entityId || !paymentTermId) {
      reject('Mandatory params missing');
    }
    const url = `/company/${entityId}/payment-terms/${paymentTermId}`;
    axios
      .delete(url)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });

const getPaymentTerms = ({
  entityId,
}: {
  entityId: string;
}): Promise<GetPaymentTermsApiResponse> =>
  new Promise((resolve, reject) => {
    if (!entityId) {
      reject('Mandatory params missing');
    }
    const url = `/company/${entityId}/payment-terms`;
    axios
      .get(url)
      .then(({ data }: { data: GetPaymentTermsApiResponse }) => resolve(data))
      .catch((error) => resolve(error));
  });

const updatePaymentTerm = ({
  entityId,
  payload,
}: {
  entityId: string;
  payload: PaymentTerm;
}): Promise<PaymentTerm> =>
  new Promise((resolve, reject) => {
    if (!entityId || !payload) {
      reject('Mandatory params missing');
    }
    const paymentTermId = payload.id;
    const url = `/company/${entityId}/payment-terms/${paymentTermId}`;
    axios
      .put(url, payload)
      .then(({ data }: { data: PaymentTerm }) => resolve(data))
      .catch((error) => reject(error));
  });

export default {
  addPaymentTerm,
  deletePaymentTerm,
  getPaymentTerms,
  updatePaymentTerm,
};
