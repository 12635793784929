import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CheckCircleFilledGreen } from 'assets/icons';

import Button from 'components/Button';
import AuthCard from 'components/AuthCard';
import PageTracker from 'components/PageTracker';

import { myobLogo } from 'assets/images';
import BetaBadge from 'components/BetaBadge';

import { setMyobAccount } from 'store/signup';

const ConnectButton = styled(Button)`
  margin-bottom: 20px;
`;

const Icon = styled.img`
  width: 150px;
  height: 150px;
`;

const CheckIcon = styled.img`
  position: absolute;
  left: 6px;
  top: 6px;
  width: 20px;
  height: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.length === 1 ? 'center' : 'space-between'};
  margin-bottom: 10px;
`;
const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 180px;
  border-radius: 8px;
  transition: all 300ms;
  :hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  }
  :active {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  }
  box-shadow: ${(props) =>
    props.isSelected && '0px 2px 30px rgba(0, 0, 0, 0.15)'};
`;
const Scope = styled.span`
  margin-top: -50px;
`;

const MyobAccount = ({ invitedUser, setMyobAccount }) => {
  let accounts = [];
  if (window.env.FEATURE_FLAGS && window.env.FEATURE_FLAGS.MYOB_CONNECT) {
    if (window.env.FEATURE_FLAGS && window.env.FEATURE_FLAGS.MYOB_ESSENTIALS) {
      accounts.push({
        id: 'myob-essentials',
        logo: myobLogo,
        label: 'Myob',
        isSelected: false,
        scope: 'Essentials',
      });
    }
    if (
      window.env.FEATURE_FLAGS &&
      window.env.FEATURE_FLAGS.MYOB_ACCOUNT_RIGHT
    ) {
      accounts.push({
        id: 'myob-account-right',
        logo: myobLogo,
        label: 'Myob',
        isSelected: false,
        scope: 'Account Right',
      });
    }
  }
  const [loading, setLoading] = useState(false);
  const [businessAccounts, setBusinessAccounts] = useState(accounts);

  const selectedAccount = businessAccounts.filter(
    (account) => account.isSelected === true
  )[0];

  const handleClick = () => {
    setLoading(true);
    setMyobAccount(
      selectedAccount.id === 'myob-essentials' ? 'Essentials' : 'Account Right'
    );
    const MYOB_CLIENT_ID = window.env.MYOB_CLIENT_ID;
    const MYOB_REDIRECT_URI = window.env.MYOB_REDIRECT_URI;
    const MYOB_SCOPE =
      selectedAccount.id === 'myob-essentials'
        ? window.env.MYOB_ESSENTIALS_SCOPE
        : window.env.MYOB_ACCOUNT_RIGHT_SCOPE;
    const myobAuthurl = `https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_CLIENT_ID}&redirect_uri=${MYOB_REDIRECT_URI}&response_type=code&scope=${MYOB_SCOPE}`;
    window.location.assign(myobAuthurl);
  };

  const onSelectAccount = (account) => {
    const selected = businessAccounts.map((item) => {
      if (item.id === account.id) {
        return { ...item, isSelected: true };
      }
      return { ...item, isSelected: false };
    });
    setBusinessAccounts(selected);
  };

  const message = 'Connect your MYOB account';
  const subMessage = 'Which account would you like to connect?';

  return (
    <AuthCard message={message} subMessage={subMessage}>
      <PageTracker />
      <Row length={businessAccounts.length}>
        {businessAccounts.map((account) => (
          <Column
            onClick={() => onSelectAccount(account)}
            isSelected={account.isSelected}
            key={account.id}
          >
            {account.isSelected && (
              <CheckIcon src={CheckCircleFilledGreen} alt="" />
            )}
            {account.scope === 'Essentials' && <BetaBadge />}
            <Icon src={account.logo} />
            <Scope>{account.scope}</Scope>
          </Column>
        ))}
      </Row>
      <ConnectButton
        label="Continue"
        full
        raised
        primary
        onClick={handleClick}
        disabled={!selectedAccount}
        loading={loading}
        inviteSignup={
          invitedUser && invitedUser.status === 'INVITED' ? true : false
        }
      />
    </AuthCard>
  );
};

const mapStateToProps = ({ signup }) => ({
  token: signup.token,
  company: signup.company,
});

export default connect(mapStateToProps, {
  setMyobAccount,
})(withRouter(MyobAccount));
