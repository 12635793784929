import moment from 'moment';

type Params = {
  total: number;
  crr: number;
  dueDate: number;
};

const getCashRewardForPurchaserOffer = ({
  total,
  crr,
  dueDate,
}: Params): number => {
  const daysPaidEarlier = moment(dueDate).diff(moment(), 'days') + 1;
  const serviceFee = (crr * total * daysPaidEarlier) / (100 * 365);
  return serviceFee;
};

export default getCashRewardForPurchaserOffer;
