import api from 'api';
import { SummaryBlock } from 'components';
import React, { useEffect, useState } from 'react';
import { formatCurrency } from 'utils';

type Props = {
  supplierName: string;
};
type Params = Parameters<typeof api.ripe.getStatistics>[0];

export const PendingInvoicesSummary: React.FC<Props> = (props) => {
  const { supplierName } = props;

  const [totalCount, setTotalCount] = useState('0');
  const [totalAmount, setTotalAmount] = useState('$0.00');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params: Params = {
      processingStatuses: 'RETRY,VARIANCE',
      supplierName: encodeURIComponent(supplierName),
    };
    setLoading(true);
    api.ripe
      .getStatistics(params)
      .then((statistics) => {
        const { retry, variance } = statistics;

        const totalAmount =
          (retry?.totalAmountDue || 0) + (variance?.totalAmountDue || 0);

        const totalCount =
          (retry?.totalCount || 0) + (variance?.totalCount || 0);

        setTotalAmount(formatCurrency(totalAmount));
        setTotalCount(String(totalCount));
      })
      .finally(() => setLoading(false));
  }, [supplierName]);

  return (
    <SummaryBlock
      title="PENDING INVOICES"
      highlightedRowLabel="Number of Invoices"
      highlightedRowValue={totalCount}
      label="Total Amount"
      value={totalAmount}
      loading={loading}
    />
  );
};
