import React from 'react';
import styled from 'styled-components';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import spacing from 'styles/layout/spacing';
import colors from 'colors';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  column-gap: ${spacing.gutter.md};
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Count = styled.span``;

type Props = {
  moveToNextBill: () => void;
  moveToPreviousBill: () => void;
  currentItemNumber: number;
  totalElements: number;
  handleDelete: () => void;
  deleting: boolean;
  showDelete: boolean;
};

const Navigation: React.FC<Props> = ({
  moveToNextBill,
  moveToPreviousBill,
  currentItemNumber,
  totalElements,
  handleDelete,
  deleting,
  showDelete,
}) => (
  <Container>
    <Row>
      <Button
        icon={<CaretLeftOutlined />}
        onClick={moveToPreviousBill}
        type="default"
      />
      <Count>{`${currentItemNumber} / ${totalElements}`}</Count>
      <Button
        icon={<CaretRightOutlined />}
        onClick={moveToNextBill}
        type="default"
      />
    </Row>
    {showDelete && (
      <Popconfirm
        title="Are you sure you want to delete this bill?"
        onConfirm={handleDelete}
        okText="Yes"
        cancelText="No"
      >
        <Button
          icon={<DeleteOutlined style={{ color: colors.error }} />}
          loading={deleting}
        />
      </Popconfirm>
    )}
  </Container>
);

export default Navigation;
