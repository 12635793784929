import axios from 'axios';
import {
  StatementsApiResponse,
  StatementDetailsApiResponse,
} from 'types/Statement';

const getAll = (
  query: string,
  page: number,
  size: number
): Promise<StatementsApiResponse> =>
  new Promise<StatementsApiResponse>((resolve, reject) => {
    const now = new Date();
    const previousMonth = now.getMonth();
    let url = '/statement';
    if (query) {
      url += `?${query}`;
      url += `page=${page - 1 || 0}&size=${size || 25}`;
      if (!query.includes('month')) {
        url += `&month=${previousMonth}`;
      }
    } else {
      url += `?month=${previousMonth}`;
      url += `&page=${page - 1 || 0}&size=${size || 25}`;
    }

    axios
      .get(url)
      .then(({ data }: { data: StatementsApiResponse }) => resolve(data))
      .catch((error) => reject(error));
  });

const get = (
  id: string,
  startAt: number,
  endAt: number
): Promise<StatementDetailsApiResponse> =>
  new Promise<StatementDetailsApiResponse>((resolve, reject) => {
    axios
      .get(
        `/statement/${id}/details?startAt=${startAt || 0}&endAt=${endAt || 25}`
      )
      .then(({ data }: { data: StatementDetailsApiResponse }) => resolve(data))
      .catch((error) => reject(error));
  });

export default { getAll, get };
