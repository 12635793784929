import React from 'react';
import { connect } from 'react-redux';

import TopupModal from 'components/TopupModal';
import AddInvoice from 'components/AddInvoice';
import ImportFile from 'components/ImportFile';
import AddBankAccount from 'components/AddBankAccount';
import GetVerifiedModal from 'components/UserVerification/GetVerifiedModal';
import InviteWindow from 'components/InviteWindow';

import { WithdrawModal } from 'components';

const Modals = ({
  trigger,
  isVisibleNewInvoiceWindow,
  isVisibleImportFileWindow,
  isVisibleAddBankAccount,
  isGetVerifiedModalVisible,
  history,
}) => (
  <>
    {trigger && <TopupModal />}
    {isVisibleAddBankAccount && <AddBankAccount />}
    <InviteWindow />
    <WithdrawModal />
    {isVisibleNewInvoiceWindow && <AddInvoice />}
    {isVisibleImportFileWindow && <ImportFile />}
    {isGetVerifiedModalVisible && <GetVerifiedModal history={history} />}
  </>
);

const mapStateToProps = ({ auth, ui, invoices }) => ({
  trigger: auth.trigger,
  isVisibleNewInvoiceWindow: invoices.isVisibleNewInvoiceWindow,
  isVisibleImportFileWindow: invoices.isVisibleImportFileWindow,
  isVisibleAddBankAccount: auth.isVisibleAddBankAccount,
  isGetVerifiedModalVisible: ui.isGetVerifiedModalVisible,
});

export default connect(mapStateToProps)(Modals);
