import featureFlags from 'config/featureFlags';

const HomePage = ({ history }) => {
  if (featureFlags.redirectToMarketingWebsite) {
    window.location.assign('https://www.relay.ai');
  } else {
    history.push('/login');
  }
  return null;
};

export default HomePage;
