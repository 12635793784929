import React, { useState, useEffect, useCallback } from 'react';
import api from 'api';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button, notification, Typography } from 'antd';
import { PaymentTerm } from 'types/PaymentTerm';
import { AddUpdateTermModal } from './AddUpdateTermModal';
import { PaymentTermsTable } from './PaymentTermsTable';
import { processError } from 'utils';
import spacing from 'styles/layout/spacing';

const { Title } = Typography;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: ${spacing.gutter.sm};
`;

const Container = styled.div`
  max-width: 850px;
`;

export const PaymentTerms: React.FC = () => {
  const entityId = useParams<{ id: string }>().id;

  const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState<PaymentTerm | undefined>(
    undefined
  );
  const [isModalOpen, setModalOpen] = useState(false);

  const getPaymentTerms = useCallback(() => {
    setLoading(true);
    api.paymentTerms
      .getPaymentTerms({ entityId })
      .then((data) => setPaymentTerms(data))
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [entityId]);

  useEffect(() => {
    getPaymentTerms();
  }, [getPaymentTerms]);

  const handleEdit = (term: PaymentTerm) => {
    setModalOpen(true);
    setSelectedTerm(term);
  };

  const onDeleteSuccess = (paymentTermId: PaymentTerm['id']) => {
    const filteredPaymentTerms = paymentTerms.filter(
      (item) => item.id !== paymentTermId
    );
    setPaymentTerms(filteredPaymentTerms);
    setSelectedTerm(undefined);
    setModalOpen(false);
    notification.success({ message: 'Payment term deleted' });
  };

  const handleClickAdd = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedTerm(undefined);
  };

  const onSuccess = (paymentTerm: PaymentTerm) => {
    const isExistingPaymentTerm = paymentTerms.some(
      (term) => term.id === paymentTerm.id
    );
    if (isExistingPaymentTerm) {
      notification.success({ message: 'Payment term updated' });
      setSelectedTerm(undefined);
      getPaymentTerms();
    } else {
      setPaymentTerms([...paymentTerms, paymentTerm]);
      notification.success({ message: 'Payment term added' });
    }
    setModalOpen(false);
  };

  return (
    <Container>
      <Title level={5}>Payment Terms</Title>

      <PaymentTermsTable
        paymentTerms={paymentTerms}
        handleClickEdit={handleEdit}
        isLoading={isLoading}
      />

      <ButtonContainer>
        <Button type="primary" onClick={handleClickAdd}>
          Add
        </Button>
      </ButtonContainer>
      {isModalOpen && (
        <AddUpdateTermModal
          entityId={entityId}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          paymentTerm={selectedTerm}
          onDeleteSuccess={onDeleteSuccess}
          onSuccess={onSuccess}
          existingPaymentTerms={paymentTerms}
        />
      )}
    </Container>
  );
};
