import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table, Tabs } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import api from 'api';
import BankAccount from 'types/BankAccount';
import PageTracker from 'components/PageTracker';
import { setToolbarTitle } from 'store/ui';
import accountNumberFormatter from 'utils/accountNumberFormatter';
import { useNavigate } from 'hooks';

type BankAccountStateItem = {
  companyId: string;
  companyName: string;
  bankAccountNumber: string;
  bsb: BankAccount['bsb'];
  payId: BankAccount['payId'];
  status: string;
};

const tabStatusMapping: { [tab: string]: BankAccount['bankAccountStatus'] } = {
  pending: 'VERIFICATION_PENDING',
  approved: 'VERIFIED',
  cancelled: 'VERIFICATION_FAILED',
};

const tabs = ['All', 'Pending', 'Approved', 'Cancelled'];

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_TAB = 'all';

const BankAccounts: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const tabParam = queryParams.get('tab') || DEFAULT_TAB;
  const pageNumberParam = queryParams.has('page')
    ? Number(queryParams.get('page'))
    : DEFAULT_PAGE_NUMBER;
  const pageSizeParam = queryParams.has('size')
    ? Number(queryParams.get('size'))
    : DEFAULT_PAGE_SIZE;

  const [bankAccounts, setBankAccounts] = useState<BankAccountStateItem[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log({ tabParam, pageNumberParam, pageSizeParam });
  // }, [tabParam, pageNumberParam, pageSizeParam]);

  useEffect(() => {
    setLoading(true);
    const status = tabStatusMapping[tabParam];
    api.admin.bankAccounts
      .get({
        page: pageNumberParam - 1,
        size: pageSizeParam,
        status,
      })
      .then((data) => {
        setTotal(data.totalElements);
        const bankAccounts = data.bankAccounts.map(
          ({ bankAccount, _links }) => {
            const { company, companyName: companyNameLink } = _links;

            const companyUrl = new URL(company.href);
            const companyId = companyUrl.pathname.split('/').pop() || '';

            const companyNameUrl = new URL(companyNameLink.href);
            const companyName =
              new URLSearchParams(companyNameUrl.search).get('companyName') ||
              '';

            const status =
              bankAccount.bankAccountStatus === 'UNVERIFIED'
                ? 'Unverified'
                : bankAccount.bankAccountStatus === 'VERIFICATION_PENDING'
                ? 'Verification pending'
                : bankAccount.bankAccountStatus === 'VERIFIED'
                ? 'Verified'
                : bankAccount.bankAccountStatus === 'VERIFICATION_FAILED'
                ? 'Verification failed'
                : 'Completed';

            const account = {
              companyId,
              companyName,
              bankAccountNumber: bankAccount.bankAccountNumber || '',
              bsb: bankAccount.bsb,
              payId: bankAccount.payId,
              status,
            };

            return account;
          }
        );
        setBankAccounts(bankAccounts);
      })
      .finally(() => setLoading(false));
  }, [pageNumberParam, pageSizeParam, tabParam]);

  useEffect(() => {
    dispatch(setToolbarTitle('Bank Accounts'));
  }, [dispatch]);

  const columns = [
    {
      title: 'Company',
      key: 'Company',
      dataIndex: 'companyName',
    },
    {
      title: 'BSB',
      key: 'bsb',
      dataIndex: 'bsb',
      render: (bsb: string) => bsb || '-',
    },
    {
      title: 'Account Number',
      key: 'AccountNumber',
      dataIndex: 'bankAccountNumber',
      render: (accountNumber: string) => accountNumberFormatter(accountNumber),
    },
    {
      title: 'PayID',
      key: 'payid',
      dataIndex: 'payId',
      render: (payId: string) => payId || '-',
    },
    {
      title: 'Status',
      key: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      key: 'Action',
      dataIndex: 'companyId',
      render: (companyId: string) => {
        const path = `/companies/${companyId}/bank-accounts`;
        return <Link to={path}>View</Link>;
      },
    },
  ];

  const onTabChange = (tab: string) => {
    queryParams.set('tab', tab);
    queryParams.set('page', String(DEFAULT_PAGE_NUMBER));
    navigate({ params: queryParams });
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) => {
    queryParams.set('page', String(pageNumber));
    queryParams.set('size', String(pageSize));
    navigate({ params: queryParams });
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <Helmet>
        <title>Bank Accounts | Relay</title>
      </Helmet>
      <PageTracker />
      <Tabs activeKey={tabParam} onChange={onTabChange}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab} key={tab.toLowerCase()} />
        ))}
      </Tabs>
      <Table
        columns={columns}
        dataSource={bankAccounts}
        loading={isLoading}
        pagination={{
          current: pageNumberParam,
          pageSize: pageSizeParam,
          total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50', '100'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          // onShowSizeChange: (current, size) => {
          //   updatePageSize(size);
          //   updatePageNumber(1);
          //   getVerificationBankAccounts({ status, page: 0, size });
          // },
          // onChange: (pageNumber, pageSize) => {
          // getVerificationBankAccounts({
          //   status,
          //   page: page - 1,
          //   size: pageSize,
          // });
          // updatePageNumber(page);
          // updatePageSize(pageSize);
          // },
          onChange: onPaginationChange,
        }}
      />
    </div>
  );
};

export default BankAccounts;
