import React from 'react';
import styled from 'styled-components';
import { RelayTable } from 'components';
import { CheckOutlined } from '@ant-design/icons';
import { PaymentTerm } from 'types/PaymentTerm';
import { toTitleCase } from 'utils';
import TableButton from 'components/TableButton';

const StyledRelayTable = styled(RelayTable)`
  min-height: auto;
`;

export const PaymentTermsTable: React.FC<{
  paymentTerms: PaymentTerm[];
  handleClickEdit: (PaymentTerm: PaymentTerm) => void;
  isLoading: boolean;
}> = ({ paymentTerms, handleClickEdit, isLoading }) => {
  const columns = [
    {
      title: 'FREQUENCY',
      dataIndex: 'frequency',
      render: (frequency: string) => toTitleCase(frequency),
      key: 'frequency',
    },
    {
      title: 'DAY',
      render: (record: PaymentTerm) =>
        record.frequency === 'MONTHLY'
          ? record.date
          : toTitleCase(record.dayOfWeek || ''),
      key: 'day',
    },
    {
      title: 'DEFAULT',
      key: 'defaultTerm',
      render: (term: PaymentTerm) => term.defaultTerm && <CheckOutlined />,
      align: 'left' as const,
    },
    {
      title: 'ACTION',
      key: 'ACTION',
      render: (term: PaymentTerm) => (
        <TableButton
          className="table-row-button"
          onClick={() => handleClickEdit(term)}
        >
          Edit
        </TableButton>
      ),
      align: 'center' as const,
    },
  ];

  return (
    <StyledRelayTable
      columns={columns}
      dataSource={paymentTerms}
      pagination={false}
      loading={isLoading}
      scroll={{ x: 100 }}
      rowKey={(record) => record.id}
    />
  );
};
