import React from 'react';
import styled from 'styled-components/macro';
import urls from 'urls';
import { connect } from 'react-redux';
import { History } from 'history';
import { logout } from 'store/auth';
import { Divider, Tooltip } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CheckCircleFilledGreen } from 'assets/icons';

const Container = styled.div`
  width: 296px;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const CompanyName = styled.p`
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px;
`;

const Item = styled.p`
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 9px;
  padding-top: 9px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0px;
  cursor: ${(props) => (props.onMouseDown ? 'pointer' : 'initial')};
  background-color: #ffffff;
  :hover {
    background-color: ${(props) => (props.onMouseDown ? '#f6f7f8' : '#ffffff')};
  }
`;

const Icon = styled.img`
  margin-left: 10px;
  width: 20px;
`;

type Props = {
  companyName: string;
  logout: () => void;
  history: History;
  isCompanyVerified: boolean;
} & RouteComponentProps;

const AccountDropDown = (props: Props) => {
  const { companyName, logout, history, isCompanyVerified } = props;

  const goToVerificationFlow = () => {
    !isCompanyVerified && history.push(urls.verify.ownersList);
  };

  const doLogout = () => {
    logout();
    history.push('/login');
  };

  return (
    <Container>
      <CompanyName>{companyName}</CompanyName>
      <Divider
        css={`
          margin: 10px 0;
        `}
      />
      <Item
        onMouseDown={() => {
          history.push('/settings');
        }}
      >
        Settings
      </Item>
      {companyName !== 'Admin' &&
        (isCompanyVerified ? (
          <Item>
            Verified
            <Tooltip title="Verified">
              <Icon src={CheckCircleFilledGreen} alt="" style={styles.icon} />
            </Tooltip>
          </Item>
        ) : (
          <Item onMouseDown={goToVerificationFlow}>Get verified</Item>
        ))}
      <Item onMouseDown={doLogout}> Logout </Item>
    </Container>
  );
};

const styles = {
  icon: { position: 'relative', bottom: 3.5 } as React.CSSProperties,
};

export default connect(null, { logout })(withRouter(AccountDropDown));
