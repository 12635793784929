import React from 'react';
import NumberFormat from 'react-number-format';
import { Form, FormInstance, Input } from 'antd';

import {
  AccountFormValues,
  AustraliaOptions as AustraliaOptionsType,
} from './types';
import { AustraliaOptions } from './AustraliaOptions';

type AccountFormProps = {
  accountNumberFormat: string;
  form: FormInstance<AccountFormValues>;
  australiaOption: AustraliaOptionsType;
  setAustraliaOption: (option: AustraliaOptionsType) => void;
  isCountryAU: boolean;
  onSubmit: (values: AccountFormValues) => void;
};

export const AccountForm: React.FC<AccountFormProps> = ({
  accountNumberFormat,
  form,
  australiaOption,
  setAustraliaOption,
  isCountryAU,
  onSubmit,
}) => {
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      {isCountryAU && (
        <Form.Item>
          <AustraliaOptions
            selected={australiaOption}
            onChange={setAustraliaOption}
          />
        </Form.Item>
      )}

      <Form.Item
        label="Account name"
        name="bankAccountName"
        rules={[{ required: true, message: 'Account name is required' }]}
      >
        <Input />
      </Form.Item>

      {isCountryAU && australiaOption === AustraliaOptionsType.PAYID && (
        <Form.Item
          label="PayID"
          name="payid"
          rules={[
            {
              required:
                isCountryAU && australiaOption === AustraliaOptionsType.PAYID,
              message: 'PayID is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      {isCountryAU && australiaOption === AustraliaOptionsType.ACCOUNT_NUMBER && (
        <Form.Item
          label="BSB"
          name="bsb"
          rules={[
            {
              required:
                isCountryAU &&
                australiaOption === AustraliaOptionsType.ACCOUNT_NUMBER,
              message: 'BSB is required',
            },
            {
              len: 6,
              message: 'BSB should be 6 digits',
              transform: (bsb) => {
                if (bsb) {
                  const trimmedBsb = bsb.replace(/\D/g, '');
                  return trimmedBsb;
                }
              },
            },
          ]}
        >
          <NumberFormat
            customInput={Input}
            format={'###-###'}
            placeholder={'###-###'}
          />
        </Form.Item>
      )}

      {(!isCountryAU ||
        australiaOption === AustraliaOptionsType.ACCOUNT_NUMBER) && (
        <Form.Item
          label="Account number"
          name="bankAccountNumber"
          rules={[
            {
              required:
                !isCountryAU ||
                australiaOption === AustraliaOptionsType.ACCOUNT_NUMBER,
              message: 'Account number is required',
            },
            {
              min: isCountryAU ? 6 : 15,
              message: `Account number should have minimum ${
                isCountryAU ? 6 : 15
              } digits`,
              transform: (accountNumber) => {
                if (accountNumber) {
                  const trimmedAccountNumber = accountNumber.replace(/\D/g, '');
                  return trimmedAccountNumber;
                }
              },
            },
          ]}
        >
          <NumberFormat
            customInput={Input}
            format={accountNumberFormat}
            placeholder={accountNumberFormat}
          />
        </Form.Item>
      )}
    </Form>
  );
};
