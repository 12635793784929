import React, { FC } from 'react';
import colors from 'colors';
import styled from 'styled-components';
import Search from './Search';
import { formatCurrency } from 'utils';
import { Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Credit as CreditType, AllocationType } from '../AllocateCreditNote';

const DataTable = styled(Table)`
  margin-top: 8px;

  .ant-table-thead > tr > th {
    font-size: 10px;
    font-weight: 700 !important;
    background-color: ${colors.greys100};
    border-bottom: 8px solid #fff;
    color: ${colors.greys700};
    padding-top: 6px !important;
    padding-bottom: 6px !important;

    &:before {
      width: 0px !important;
    }
    :first-child {
      padding: 14.5px 9.5px;
      width: 9px;
    }
    :last-child {
      padding-right: 40px;
    }
  }
  colgroup {
    display: none;
  }
  .ant-table-body {
    min-height: 220px;
  }
  .ant-table-measure-row {
    display: none;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    color: ${colors.greys900};
    border: 1px solid transparent;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    line-height: 20px;
    :first-child {
      padding-left: 9.5px !important;
      padding-right: 9px !important;
      text-align: center;
      width: 14px;
    }
    :last-child {
      padding-right: 40px !important;
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    border-top: 1px dashed ${colors.blues.dark};
    border-bottom: 1px dashed ${colors.blues.dark};
    :first-child {
      border-left: 1px dashed ${colors.blues.dark};
    }
    :last-child {
      border-right: 1px dashed ${colors.blues.dark};
    }
  }
`;
const Remove = styled(CloseOutlined)`
  font-size: 10px;
  color: ${colors.greys600};
  :hover {
    color: ${colors.warms.red};
    cursor: pointer;
  }
`;

type Props = {
  creditList: CreditType[];
  fromTime: number;
  setNewCreditList: (list: CreditType[]) => void;
  supplierName: string;
  toTime: number;
  prevAllocations?: AllocationType[] | null;
};

const EditSelection: FC<Props> = ({
  creditList,
  fromTime,
  setNewCreditList,
  supplierName,
  toTime,
  prevAllocations,
}) => {
  const removeInvoice = (invoiceId: string) => {
    const updatedList = creditList.filter(
      (credit) => credit.invoiceId !== invoiceId
    );

    setNewCreditList(updatedList);
  };
  const columns = [
    {
      title: '',
      key: 'invoiceNumber',
      render: (record: CreditType) => {
        return <Remove onClick={() => removeInvoice(record.invoiceId)} />;
      },
    },
    {
      title: 'INVOICE #',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'AMOUNT DUE',
      dataIndex: 'amountDue',
      key: 'amountDue',
      align: 'right' as 'right',
      render: (amount: number) => formatCurrency(amount),
    },
  ];

  return (
    <>
      <Search
        editCreditsList={creditList}
        fromTime={fromTime}
        setNewCreditList={setNewCreditList}
        supplierName={supplierName}
        toTime={toTime}
        prevAllocations={prevAllocations}
      />
      <DataTable
        dataSource={creditList}
        columns={columns}
        pagination={false}
        scroll={{ y: 220 }}
      />
    </>
  );
};

export default EditSelection;
