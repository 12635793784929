import Invoice from 'types/Invoice';
import PurchaserOffer from 'types/PurchaserOffer';
import moment from 'moment';
import { RELAY_FEE_PERCENTAGE } from 'config';

type Params = {
  invoice: Invoice;
  purchaserOffer: PurchaserOffer;
};

const getServiceFeeForPurchaserOffer = ({
  invoice,
  purchaserOffer,
}: Params): number => {
  const { crr } = purchaserOffer;
  const { discountedTotal } = invoice;
  const daysPaidEarlier = moment(invoice.dueDate).diff(moment(), 'days') + 1;
  const serviceFee =
    (crr * discountedTotal * daysPaidEarlier) /
    (100 * 365 * ((100 - RELAY_FEE_PERCENTAGE) / 100));
  return serviceFee;
};

export default getServiceFeeForPurchaserOffer;
