import React from 'react';
import styled from 'styled-components';
import { Close } from 'assets/icons';
import { ModalProps, Typography } from 'antd';
import { Subtitle } from './Subtitle';

const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 56px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  :hover {
    img {
      filter: opacity(1);
    }
  }
  cursor: pointer;
`;

const CloseImg = styled.img`
  width: 12px;
  height: 12px;
  filter: opacity(0.7);
  transition: filter 300ms;
`;

const Title = styled(Text)`
  font-size: 16px;
`;

type Props = {
  title: string;
  subtitle?: string | string[];
  onClose?: ModalProps['onCancel'];
  hideClose?: boolean;
};

const ModalHeader: React.FC<Props> = ({
  title,
  subtitle,
  onClose,
  hideClose,
}) => (
  <Container>
    <Title strong>{title}</Title>
    {!!subtitle && <Subtitle subtitle={subtitle} />}
    {!hideClose && (
      <CloseContainer onClick={onClose}>
        <CloseImg src={Close} alt="close" />
      </CloseContainer>
    )}
  </Container>
);

export default ModalHeader;
