import React, { useState, useEffect } from 'react';
import PageLoading from 'components/PageLoading';
import queryString from 'query-string';
import styled from 'styled-components';
import { Alert, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import PhoneNumberEntry from './PhoneNumberEntry';
import SetName from './SetName';
import VerifyCode from './VerifyCode';
import UserStateCheck from 'components/UserStateCheck';

import api from 'api';
import processError from 'utils/processError';
import { bg1, city1, relayNewLogo } from 'assets/images';
import spacing from 'styles/layout/spacing';
import breakpoints from 'styles/layout/breakpoints';

const Container = styled.div`
  background-image: url(${bg1});
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  width: 100%;
  max-width: ${breakpoints.mobile};
  margin: ${spacing.gutter.lg} auto;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: ${spacing.gutter.lg};
`;

const Photo = styled.div`
  display: none;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    width: 100%;
    background-image: url(${city1});
    background-size: cover;
  }
`;

const StyledAlert = styled(Alert)`
  width: calc(100% - ${spacing.gutter.xl});
  position: absolute;
  top: 0;
`;

export const StyledButton = styled(Button)`
  margin-top: ${spacing.gutter.lg};
  width: 180px;
  align-self: center;
`;

const ActivateAccount = ({ history, location }) => {
  const { set_name, set_phone_number, token, verify_code } = queryString.parse(
    location.search
  );

  const [alert, setAlert] = useState(null);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.user
      .get(token)
      .then((user) => {
        setUser(user);
        api.company
          .get({ companyId: user.companyId, token })
          .then((response) => {
            setCompany(response.company);
            setLoading(false);
          })
          .catch((error) => {
            const { status, message } = processError(error);
            if (status === 401) {
              history.push('/login', { autoLoggedOut: true });
            } else {
              setLoading(false);
              setAlert({
                message: 'Error',
                description: message,
                type: 'error',
              });
            }
          });
      })
      .catch((error) => {
        const { status, message } = processError(error);
        if (status === 401) {
          history.push('/login', { autoLoggedOut: true });
        } else {
          setLoading(false);
          setAlert({
            message: 'Error',
            description: message,
            type: 'error',
          });
        }
      });
  }, [history, token, verify_code]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <UserStateCheck isLoggedIn={false} token={token} />
      <Helmet>
        <title>
          {set_phone_number
            ? 'Add your phone number | Account activation'
            : verify_code
            ? 'Verify your phone number | Account activation'
            : 'Activate your account'}{' '}
          | Relay
        </title>
      </Helmet>
      <Content>
        {!!alert && (
          <StyledAlert
            description={alert.description}
            message={alert.message}
            type={alert.type}
            showIcon={alert.showIcon === undefined ? true : alert.showIcon}
          />
        )}
        <Logo alertShown={!!alert} src={relayNewLogo} />
        {set_name && (
          <SetName
            history={history}
            setAlert={setAlert}
            token={token}
            user={user}
          />
        )}
        {set_phone_number && (
          <PhoneNumberEntry
            history={history}
            location={location}
            setAlert={setAlert}
            user={user}
            company={company}
          />
        )}
        {verify_code && (
          <VerifyCode
            history={history}
            location={location}
            setAlert={setAlert}
            user={user}
          />
        )}
      </Content>
      <Photo />
    </Container>
  );
};

export default withRouter(ActivateAccount);
