import React, { useEffect, useState } from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import api from 'api';
import Invoice from 'types/Invoice';
import { formatCurrency, formatDateForDisplay, processError } from 'utils';
import { AmountStrip, InvoiceInfo, ModalHeader } from 'components';
import { Divider, Modal, notification, Skeleton } from 'antd';

type Props = {
  invoice?: Invoice | null;
  invoiceId?: Invoice['id'] | null;
  invoiceType: 'BILL' | 'INVOICE';
  hideModal: () => void;
};

export const PaidInvoice: React.FC<Props> = (props) => {
  const { invoiceId, invoiceType, hideModal } = props;

  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!invoiceId || props.invoice) {
      return;
    }
    setLoading(true);
    api.invoices
      .getSingle(invoiceId)
      .then((response) => setInvoice(response))
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => setLoading(false));
  }, [invoiceId, props.invoice]);

  const modalTitle =
    invoiceType === 'BILL' ? 'Payment Successful' : 'Payment Received';

  const invoiceData = props.invoice || invoice;

  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={hideModal}
      onOk={hideModal}
      closable={false}
      title={<ModalHeader onClose={hideModal} title={modalTitle} />}
      visible={true}
    >
      <Skeleton active loading={isLoading}>
        {!!invoiceData && (
          <>
            <InvoiceInfo
              amountDue={formatCurrency(invoiceData.discountedTotal)}
              companyName={
                invoiceType === 'BILL'
                  ? invoiceData.issuedByCompanyName
                  : invoiceData.issuedForCompanyName
              }
              dueDate={formatDateForDisplay(invoiceData.dueDate)}
              invoiceNumber={
                invoiceType === 'BILL'
                  ? invoiceData.billNumber
                  : invoiceData.invoiceNumber
              }
              invoiceType={invoiceType}
            />
            <AmountStrip
              css={`
                position: relative;
                left: -2%;
                width: 104%;
                margin-bottom: 40px;
              `}
              greyScale
              label={
                invoiceType === 'BILL' ? 'Cashback Received' : 'Service Fee'
              }
              value={
                invoiceType === 'BILL'
                  ? formatCurrency(Number(invoiceData.cashReward) || 0)
                  : formatCurrency(Number(invoiceData.serviceFee) || 0)
              }
            />
            <Divider />
            {!!invoiceData.paidDate && !!invoiceData.totalPaid && (
              <AmountStrip
                css={`
                  position: relative;
                  left: -2%;
                  width: 104%;
                `}
                label={`Amount Paid on ${formatDateForDisplay(
                  invoiceData.paidDate
                )}`}
                value={formatCurrency(invoiceData.totalPaid)}
              />
            )}
          </>
        )}
      </Skeleton>
    </Modal>
  );
};
