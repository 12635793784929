import React, { useCallback, useEffect, useState } from 'react';
import api from 'api';
import styled from 'styled-components/macro';
import TableButton from 'components/TableButton';
import Company from 'types/Company';
import PayablesSummary from 'types/PayablesSummary';
import { formatCurrency, processError } from 'utils';
import { notification, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Email, SupplierSearch, SortingColumnHeader } from './components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setDataToRefresh, setRefreshData } from 'store/ui';
// import { showInviteWindow } from 'store/invoices';
import {
  CompanyStatusDot,
  InvoicePagination,
  InvoiceStatusDot,
  RelayTable,
  TableActions,
  TableHeaderDot,
  BulkIncludeExcludeButton,
  TableCellWithStatusDot,
} from 'components';
import { MailOutlined } from '@ant-design/icons';
import { useInvoiceFilters, useTableSelection } from 'hooks';
import buildUrl from '../buildUrl';
import { months } from 'values';

const { Text } = Typography;

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 25;

const month = months[new Date().getMonth()];

const StyledTable = styled(RelayTable)`
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-tbody > tr:hover > td {
    background-color: #fafafa;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 0px 10px;
  }
  .custom-spacing {
    padding-right: 3.5% !important;
  }
`;

const SortedHeaderDiv = styled.div`
  display: flex;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
`;

const CashBackColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const renderCompanyNameColumn = ({
  companyName,
  companyStatus,
  companyVerificationStatus,
  isPurchaserOffersDisabled,
}: {
  companyName: string;
  companyStatus: Company['status'];
  companyVerificationStatus: Company['verificationStatus'];
  isPurchaserOffersDisabled: boolean;
}) => {
  return (
    <TableCellWithStatusDot>
      <CompanyStatusDot
        companyStatus={companyStatus}
        companyVerificationStatus={companyVerificationStatus}
        isPurchaserOffersDisabled={isPurchaserOffersDisabled}
      />
      <Text>{companyName}</Text>
    </TableCellWithStatusDot>
  );
};

const renderBalanceColumn = ({
  balance,
  status,
}: {
  balance: string;
  status: string;
}) => (
  <TableCellWithStatusDot>
    <InvoiceStatusDot includeInPurchaserOffer={status === 'PAYMENT_OFFERED'} />
    {balance}
  </TableCellWithStatusDot>
);

const renderCashbackColumn = ({
  cashback,
  cashbackPercentage,
  status,
}: {
  cashback: number | null;
  cashbackPercentage: string | null;
  status: Summary['status'];
}) => {
  if (
    !cashback ||
    status === 'NO_OFFER' ||
    status === 'NOT_ELIGIBLE_NEGATIVE_BALANCE'
  ) {
    return '-';
  }
  return (
    <CashBackColumn>
      <Text strong>{formatCurrency(cashback)}</Text>
      <Text
        css={`
          font-size: 10px;
        `}
      >
        {cashbackPercentage}
      </Text>
    </CashBackColumn>
  );
};

type Summary = {
  invoiceIds: string[];
  key: string;
  supplierName: string;
  supplierId: string;
  month: string;
  status: PayablesSummary['status'];
  statusLabel: string;
  companyStatus: Company['status'];
  companyVerificationStatus: Company['verificationStatus'];
  balance: string;
  cashback: number | null;
  cashbackPercentage: string;
  email: string;
  unallocatedCredit: string;
  action: () => {};
  actionLabel: string;
};

type Props = {
  summaryStatus?: string;
  isActive: boolean;
};

const SuppliersTab: React.FC<Props> = ({ summaryStatus, isActive }) => {
  const settings = useSelector((state: any) => state.settings);
  const isSupplierFilterEnabled =
    settings?.isRipeXeroAgedPayablesFilterEnabled && settings?.isRipeEnabled;

  const dispatch = useDispatch();
  const history = useHistory();
  const { search: searchLocation, pathname } = useLocation();

  const locationParams = new URLSearchParams(searchLocation);
  const sortParam = locationParams.get('sort');
  const statusParam = locationParams.get('status');
  const supplierParam = locationParams.get('supplier');
  const pageParam = Number(locationParams.get('page')) || DEFAULT_PAGE;
  const size = Number(locationParams.get('size')) || DEFAULT_SIZE;

  const sortParams = sortParam ? sortParam.split(',') : null;
  const sortByParam =
    sortParams && sortParams.length > 0 ? sortParams[0] : null;
  const sortOrderParam =
    sortParams && sortParams.length > 1 ? sortParams[1] : null;

  const matchesXero = locationParams.get('isMatchedPayablesValue') ?? undefined;

  const { clearRowSelection, rowSelection } = useTableSelection();
  const [supplierName, setSupplierName] = useState<string | undefined>(
    supplierParam ? supplierParam : undefined
  );
  const [page, setPage] = useState(pageParam);

  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [loading, setLoading] = useState(false);
  const [displaySupplierNameSorting, setDisplaySupplierNameSorting] = useState(
    sortByParam === 'supplierName' ? true : false
  );

  const [displayBalancePayableSorting, setDisplayBalancePayableSorting] =
    useState(sortByParam === 'totalAmountPayable' ? true : false);
  const [displayUnallocatedCNSorting, setDisplayUnallocatedCNSorting] =
    useState(sortByParam === 'unallocatedCreditNoteBalance' ? true : false);

  const filters = useInvoiceFilters(true);

  const isLoading = useSelector((store: any) => store.invoices.loadingInvoices);
  const payableSummaries = useSelector(
    (store: any) =>
      store.invoices.payableSummaries.map(
        (summary: { payablesSummary: PayablesSummary }) =>
          summary.payablesSummary
      ),
    shallowEqual
  );

  const handleSortChange = (newSort: string) => {
    const sortBy = !newSort ? null : newSort.split(',')[0];
    setDisplaySupplierNameSorting(sortBy === 'supplierName');
    setDisplayBalancePayableSorting(sortBy === 'totalAmountPayable');
    setDisplayUnallocatedCNSorting(sortBy === 'unallocatedCreditNoteBalance');
    const url = buildUrl({
      base: pathname,
      status: statusParam,
      sort: newSort,
      page,
      size,
      matchesXero,
      tab: 'suppliers',
      supplier: supplierName,
    });

    history.push(url);
  };

  const showBulkActions = rowSelection.selectedRowKeys.length > 0;
  const selectedRows = summaries.filter((summary) =>
    rowSelection.selectedRowKeys.includes(summary.key)
  );
  const selectedInvoiceIds: string[] = [];
  selectedRows.map((row) => selectedInvoiceIds.push(...row.invoiceIds));

  const shouldShowBulkIncludeButton = selectedRows.some(
    (row) => row.actionLabel === 'Include'
  );

  const shouldShowBulkExcludeButton = selectedRows.some(
    (row) => row.actionLabel === 'Exclude'
  );

  const includeExclude = useCallback(
    ({
      action,
      invoiceIds,
    }: {
      action: 'INCLUDE' | 'EXCLUDE';
      invoiceIds: string[];
    }) => {
      const payload = invoiceIds.map((id) => ({
        id,
        path: '/include_in_purchaser_offer' as const,
        operation: 'replace' as const,
        value: action === 'INCLUDE' ? true : false,
      }));
      setLoading(true);
      api.invoices
        .patch(payload)
        .then(() => {
          dispatch(setRefreshData(true));
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({ message });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    const rows = payableSummaries.map((summary: PayablesSummary) => {
      const {
        companyOwedStatus,
        companyOwedVerificationStatus,
        invoiceIds,
        isPurchaserOffersEnabled,
        supplierName,
        supplierId,
        status,
        totalAmountPayable,
        companyOwedEmail,
        unallocatedCreditNoteBalance,
      } = summary;

      let { totalCashReward } = summary;
      if (status === 'PAYMENT_OFFERED' && !isPurchaserOffersEnabled) {
        totalCashReward = 0;
      }

      const cashbackPercentage =
        totalCashReward && totalAmountPayable
          ? ((totalCashReward * 100) / totalAmountPayable).toFixed(2)
          : '0';

      let statusLabel = '-';
      if (status === 'PAYMENT_OFFERED' && isPurchaserOffersEnabled) {
        if (companyOwedStatus === 'INVITED') {
          statusLabel = 'Invited';
        } else {
          statusLabel = 'Payment Offered';
        }
      } else if (status === 'CASHBACK_AVAILABLE') {
        statusLabel = 'Cashback Available';
      } else if (!isPurchaserOffersEnabled) {
        statusLabel = 'Not Eligible';
      }

      let actionLabel = '-';
      let action = () => {};
      if (status === 'PAYMENT_OFFERED' && isPurchaserOffersEnabled) {
        actionLabel = 'Exclude';
        action = () => includeExclude({ action: 'EXCLUDE', invoiceIds });
      } else if (isPurchaserOffersEnabled) {
        actionLabel = 'Include';
        action = () => includeExclude({ action: 'INCLUDE', invoiceIds });
      }

      return {
        invoiceIds,
        key: supplierId,
        supplierName,
        supplierId,
        month,
        status,
        statusLabel,
        companyStatus: companyOwedStatus,
        companyVerificationStatus: companyOwedVerificationStatus,
        balance: formatCurrency(totalAmountPayable),
        cashback: totalCashReward,
        cashbackPercentage: `${cashbackPercentage}%`,
        email: companyOwedEmail,
        unallocatedCredit: unallocatedCreditNoteBalance
          ? formatCurrency(unallocatedCreditNoteBalance)
          : '-',
        action,
        actionLabel,
      };
    });
    setSummaries(rows);
  }, [dispatch, includeExclude, payableSummaries]);

  useEffect(() => {
    if (isActive) dispatch(setRefreshData(true));
  }, [isActive, dispatch]);

  useEffect(() => {
    dispatch(setDataToRefresh('PAYABLES_SUMMARY'));
  }, [dispatch]);

  // No need of invite action now
  // May be needed in the future
  // const invite = (invoiceId: string) => {
  //   api.invoices
  //     .getSingle(invoiceId)
  //     .then((invoice) => {
  //       dispatch(showInviteWindow({ type: 'BILL', data: invoice }));
  //     })
  //     .catch((error) => {
  //        const { message } = processError(error);
  //        notification.error({ message: 'Cannot invite', description: message });
  //     });
  // };

  const columns = [
    {
      key: 'supplierName',
      render: ({
        companyStatus,
        companyVerificationStatus,
        statusLabel,
        supplierName,
      }: Summary) =>
        renderCompanyNameColumn({
          companyStatus,
          companyVerificationStatus,
          companyName: supplierName,
          isPurchaserOffersDisabled: statusLabel === 'Not Eligible',
        }),
      title: () => (
        <SortedHeaderDiv>
          <TableCellWithStatusDot>
            <TableHeaderDot />
            <SortingColumnHeader
              sortingLabel="SUPPLIER"
              displaySortingIcon={displaySupplierNameSorting}
              sort="supplierName"
              sortOrderParam={sortOrderParam}
              handleSortChange={handleSortChange}
            />
          </TableCellWithStatusDot>
        </SortedHeaderDiv>
      ),
      width: '23%',
    },
    {
      title: 'PERIOD',
      dataIndex: 'month',
      key: 'month',
      align: 'center' as const,
      width: '11%',
    },
    {
      title: () => (
        <>
          <SortedHeaderDiv>
            <TableCellWithStatusDot>
              <TableHeaderDot />
              <SortingColumnHeader
                sortingLabel="BALANCE PAYABLE"
                displaySortingIcon={displayBalancePayableSorting}
                sort="totalAmountPayable"
                sortOrderParam={sortOrderParam}
                handleSortChange={handleSortChange}
              />
            </TableCellWithStatusDot>
          </SortedHeaderDiv>
        </>
      ),
      key: 'balance',
      render: ({ balance, status }: Summary) =>
        renderBalanceColumn({ balance, status }),
      width: '17%',
    },
    {
      title: () => (
        <>
          <SortedHeaderDiv>
            <SortingColumnHeader
              sortingLabel="UNALLOCATED CREDIT"
              displaySortingIcon={displayUnallocatedCNSorting}
              sort="unallocatedCreditNoteBalance"
              sortOrderParam={sortOrderParam}
              handleSortChange={handleSortChange}
            />
          </SortedHeaderDiv>
        </>
      ),
      key: 'unallocted_credit',
      dataIndex: 'unallocatedCredit',
      align: 'left' as const,
      width: '16%',
    },
    {
      title: 'STATUS',
      dataIndex: 'statusLabel',
      key: 'status',
      align: 'center' as const,
      width: '14%',
      render: (status: string) => (
        <Text data-testid="supplier-status">{status}</Text>
      ),
    },
    {
      title: 'CASHBACK',
      key: 'cashback',
      render: ({ cashback, cashbackPercentage, status }: Summary) =>
        renderCashbackColumn({ cashback, cashbackPercentage, status }),
      align: 'right' as const,
      className: 'custom-spacing',
      width: '13%',
    },
    {
      title: (
        <MailOutlined
          style={{
            color: '#FFF',
            marginRight: 0,
            fontSize: 10,
            fontWeight: 'bold',
          }}
        />
      ),
      render: ({ status, email, companyStatus }: Summary) => (
        <Email email={email} status={status} companyStatus={companyStatus} />
      ),
      key: 'email',
      align: 'center' as const,
      width: '3%',
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (summary: Summary) => (
        <TableButton
          style={{ margin: '1em' }}
          onClick={(e) => {
            e.stopPropagation();
            summary.action();
          }}
        >
          {summary.actionLabel}
        </TableButton>
      ),
      align: 'center' as const,
      width: '4.5%',
    },
  ];

  const navigateToDetails = ({ supplierId }: { supplierId: string }) => {
    const url = `/suppliers/${supplierId}`;
    history.push(url);
  };

  const onSuccessBulkAction = () => {
    clearRowSelection();
  };

  const clearSupplierName = () => {
    const url = buildUrl({
      base: pathname,
      status: statusParam,
      sort: sortParam,
      page: DEFAULT_PAGE,
      size,
      tab: 'suppliers',
      supplier: undefined,
      matchesXero,
    });
    history.push(url);
    setSupplierName(undefined);
    setPage(DEFAULT_PAGE);
  };
  const handleSupplierNameChange = (value: string) => {
    const url = buildUrl({
      base: pathname,
      status: statusParam,
      sort: sortParam,
      page: DEFAULT_PAGE,
      size,
      tab: 'suppliers',
      supplier: value,
      matchesXero,
    });
    history.push(url);
    setSupplierName(value);
    setPage(DEFAULT_PAGE);
  };

  return (
    <>
      <TableActions
        bulkActions={
          showBulkActions
            ? [
                shouldShowBulkIncludeButton && (
                  <BulkIncludeExcludeButton
                    action="include"
                    invoiceIds={selectedInvoiceIds}
                    key="include"
                    onSuccess={onSuccessBulkAction}
                    isSupplierBills
                  />
                ),
                shouldShowBulkExcludeButton && (
                  <BulkIncludeExcludeButton
                    action="exclude"
                    invoiceIds={selectedInvoiceIds}
                    key="exclude"
                    onSuccess={onSuccessBulkAction}
                    isSupplierBills
                  />
                ),
              ]
            : undefined
        }
        search={
          <SupplierSearch
            status={summaryStatus}
            setSupplierName={handleSupplierNameChange}
            clearSupplierName={clearSupplierName}
            supplierName={supplierName ? supplierName : ''}
            isMatchedPayablesValue={matchesXero}
          />
        }
        hideSearch
        hideFilter={!isSupplierFilterEnabled}
        // move this flag check into filter component when more are supplier filters are added
        filters={filters}
        type="BILL"
      />
      <StyledTable
        dataSource={summaries}
        columns={columns}
        pagination={false}
        loading={loading || isLoading}
        onRow={(record) => ({
          onClick: () => {
            navigateToDetails({
              supplierId: record.supplierId,
            });
          },
        })}
        rowSelection={rowSelection}
      />
      <InvoicePagination type="SUPPLIER" />
    </>
  );
};

export default SuppliersTab;
