const SET_SHOW_PAY_NOW = 'relay/ui/SET_SHOW_PAY_NOW';
const LOGOUT = 'LOGOUT';

type State = {
  showPayNow: boolean;
};

const initialState: State = {
  showPayNow: false,
};

type Actions = ReturnType<typeof setShowPayNow> | { type: 'LOGOUT' };

const offerLanding = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case LOGOUT:
      return initialState;

    case SET_SHOW_PAY_NOW:
      return { ...state, showPayNow: action.payload };
    default:
      return state;
  }
};

export const setShowPayNow = (flag: boolean) =>
  ({
    type: SET_SHOW_PAY_NOW,
    payload: flag,
  } as const);

export default offerLanding;
