import { Modal } from 'ds';
import React from 'react';
import BankAccount from 'types/BankAccount';

import { AccountForm } from './AccountForm';
import { AccountReview } from './AccountReview';
import { BankStatement } from './BankStatement';
import { VerificationCode } from './VerificationCode';
import { Steps } from './types';
import { useAddAccount } from './useAddAccount';

type AddAccountModalProps = {
  defaultWithdrawalAccount?: BankAccount;
  isVisible: boolean;
  onHide: () => void;
  onStatementUploadSuccess: () => void;
  addNewBankAccount: (newBankAccount: BankAccount) => void;
};

export const AddAccountModal: React.FC<AddAccountModalProps> = ({
  defaultWithdrawalAccount,
  isVisible,
  onHide,
  onStatementUploadSuccess,
  addNewBankAccount,
}) => {
  const {
    accountFormProps,
    accountReviewProps,
    bankStatementProps,
    verificationCodeProps,
    modalTitle,
    modalSubTitle,
    primaryAction,
    secondaryAction,
    okButtonProps,
    cancelButtonProps,
    step,
  } = useAddAccount({
    addNewBankAccount,
    defaultWithdrawalAccount,
    onHide,
    onStatementUploadSuccess,
  });

  return (
    <Modal
      onCancel={secondaryAction.action}
      onOk={primaryAction.action}
      okText={primaryAction.label}
      cancelText={secondaryAction.label}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      subtitle={modalSubTitle}
      title={modalTitle}
      visible={isVisible}
    >
      {step === Steps.DETAILS_ENTRY && <AccountForm {...accountFormProps} />}
      {step === Steps.DETAILS_REVIEW && (
        <AccountReview {...accountReviewProps} />
      )}
      {step === Steps.BANK_STATEMENT && (
        <BankStatement
          form={bankStatementProps.form}
          onChange={bankStatementProps.onChange}
          onSubmit={bankStatementProps.onSubmit}
        />
      )}
      {step === Steps.CODE_ENTRY && (
        <VerificationCode
          disabled={verificationCodeProps.disabled}
          error={verificationCodeProps.error}
          phoneNumberFragment={verificationCodeProps.phoneNumberFragment}
          onChange={verificationCodeProps.onChange}
          onSend={verificationCodeProps.onSend}
          value={verificationCodeProps.value}
        />
      )}
    </Modal>
  );
};
