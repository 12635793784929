import React from 'react';
import { Space, Typography } from 'antd';
// eslint-disable-next-line
import styled from 'styled-components';

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 14px;
`;

type Props = {
  subtitle: string | string[];
};

export const Subtitle: React.FC<Props> = (props) => {
  const { subtitle } = props;
  return Array.isArray(subtitle) ? (
    <Space direction="vertical" size={1}>
      {subtitle.map((text) => (
        <StyledText key={text}>{text}</StyledText>
      ))}
    </Space>
  ) : (
    <StyledText>{subtitle}</StyledText>
  );
};
