import React, { useState } from 'react';
import { Input, Typography } from 'antd';
import PageTracker from 'components/PageTracker';
import { InputContainer } from 'components';
import styled from 'styled-components';
import { TitleSection } from 'components';
import api from 'api';
import splitName from 'utils/splitName';
import processError from 'utils/processError';
import validatePassword from 'utils/validatePassword';
import User from 'types/User';
import PasswordValidationResults from 'types/PasswordValidationResults';
import { RouteComponentProps } from 'react-router';
import { colors } from 'themes';
import { StyledButton } from './ActivateAccount';

const { Text } = Typography;

const NAME_MISSING_ERROR = 'Please enter your full legal name';
const NAME_INVALID_ERROR = 'Please enter your full legal name in valid format';
const PASSWORD_MISSING_ERROR =
  'Please enter a password to secure your Relay account';
const PASSWORD_INVALID_ERROR = 'Please enter a password in valid format';

const PasswordHelpText = styled.p`
  background-color: #fffdd1;
  border-radius: 4px;
  color: ${colors.text};
  margin-top: 12px;
  padding: 10px;
`;

type Props = {
  history: RouteComponentProps['history'];
  setAlert: ({
    message,
    description,
    type,
  }: {
    message: string;
    description: string;
    type: 'error';
  }) => void;
  token: string;
  user: User;
};

const SetName: React.FC<Props> = ({ history, setAlert, token, user }) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  // Errors and helper texts
  const [nameError, setNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordValidationErrors, setPasswordValidationErrors] =
    useState<PasswordValidationResults | null>(null);
  const [isHelpTextVisible, setHelpTextVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    let hasErrors = false;
    const { firstName, lastName, middleName } = splitName(name);
    // Let's validate the input fields
    if (!firstName || !lastName) {
      // User has not entered full name
      hasErrors = true;
      setNameError(NAME_MISSING_ERROR);
    }
    if (!password) {
      // User has not entered password
      hasErrors = true;
      setPasswordError(PASSWORD_MISSING_ERROR);
    } else {
      const {
        hasLowerCase,
        hasUpperCase,
        hasSpecialChar,
        hasNumber,
        hasLength,
      } = passwordValidationErrors || {};
      const isValidPassword =
        hasLowerCase &&
        hasUpperCase &&
        hasSpecialChar &&
        hasNumber &&
        hasLength;
      if (!isValidPassword) {
        // Password does not meet the criteria
        hasErrors = true;
      }
    }
    if (hasErrors) {
      // There are errors that user need to resolve
      // Let's not do anything further
      return;
    }
    setLoading(true);
    const updatedUser = {
      ...user,
      firstName,
      lastName,
      middleName,
    };
    api.user
      .put({ user: updatedUser, token })
      .then(() => {
        const patchPayload = {
          operation: 'replace' as const,
          path: '/password' as const,
          value: password,
        };
        api.user
          .patch({ payload: patchPayload, userId: user.id, token })
          .then(() => {
            api.auth.revokeToken(token);
            api.auth
              .login({ username: user.username, password })
              .then((data) => {
                const token = data.accessToken;
                history.push(`/activate?set_phone_number=true&token=${token}`);
              })
              .catch((error) => {
                // Error while attempting to login
                const { message } = processError(error);
                setAlert({
                  message: 'Error',
                  description: message,
                  type: 'error',
                });
                setLoading(false);
              });
          })
          .catch((error) => {
            // Error while attempting to update password
            const { errors, message } = processError(error);
            if (errors) {
              // The API has returned some validation errors. Let's check them.
              errors.forEach((error: string) => {
                const keyword = error.split(',')[0];
                if (keyword === 'password') {
                  setPasswordError(PASSWORD_INVALID_ERROR);
                }
              });
            } else {
              setAlert({
                message: 'Error',
                description: message,
                type: 'error',
              });
            }
            setLoading(false);
          });
      })
      .catch((error) => {
        // Error while attempting to update user with name
        const { errors, message } = processError(error);
        if (errors) {
          // The API has returned some validation errors. Let's check them.
          errors.forEach((error: string) => {
            const keyword = error.split(',')[0];
            if (keyword === 'firstName' || keyword === 'lastName') {
              setNameError(NAME_INVALID_ERROR);
            }
          });
        } else {
          setAlert({
            message: 'Error',
            description: message,
            type: 'error',
          });
        }
        setLoading(false);
      });
  };

  const onNameEntry = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    // No need to show error while user is entering name
    setNameError('');
  };

  const onPasswordEntry = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValidationErrors(validatePassword(e.target.value));
    setPassword(e.target.value);
    // No need to show error while user is entering password
    setPasswordError('');
  };

  const onPasswordClick = () => {
    !isHelpTextVisible && setHelpTextVisibility(true);
    passwordError && setPasswordError('');
  };

  return (
    <>
      <PageTracker />
      <TitleSection title="Create Account" />
      <InputContainer label="Full name" error={nameError}>
        <Input
          placeholder="Enter your full legal name..."
          value={name}
          onChange={onNameEntry}
          size="large"
        />
      </InputContainer>

      <InputContainer label="Password" error={passwordError}>
        <Input.Password
          type="password"
          placeholder="Enter a password..."
          value={password}
          onChange={onPasswordEntry}
          onClick={onPasswordClick}
          onFocus={onPasswordClick}
          size="large"
        />
        {isHelpTextVisible && (
          <PasswordHelpText>
            Password must be{' '}
            <Text
              delete={
                passwordValidationErrors
                  ? passwordValidationErrors.hasLength
                  : undefined
              }
            >
              at least 8 characters
            </Text>{' '}
            and include{' '}
            <Text delete={passwordValidationErrors?.hasNumber}>
              at least one number
            </Text>
            ,{' '}
            <Text delete={passwordValidationErrors?.hasLowerCase}>
              one lowercase letter
            </Text>
            ,{' '}
            <Text delete={passwordValidationErrors?.hasUpperCase}>
              one uppercase letter
            </Text>{' '}
            and{' '}
            <Text delete={passwordValidationErrors?.hasSpecialChar}>
              one special character
            </Text>
            .
          </PasswordHelpText>
        )}
      </InputContainer>
      <StyledButton
        loading={loading}
        onClick={onSubmit}
        type="primary"
        size="large"
        data-testid="submit-button"
      >
        Set Password
      </StyledButton>
    </>
  );
};

export default SetName;
