import currencyFormatter from 'utils/currencyFormatter';

type GetVarianceParams = {
  posAmount?: null | string;
  invoiceAmount?: null | string;
};

export const getInvoiceVariance = ({
  posAmount,
  invoiceAmount,
}: GetVarianceParams): string => {
  if (invoiceAmount) {
    const variance = parseFloat(invoiceAmount) - parseFloat(posAmount || '0');
    const prefix = variance > 0 ? '+' : '';
    return `${prefix}${currencyFormatter.format(variance)}`;
  } else {
    return '-';
  }
};
