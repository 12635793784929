import { PaymentTerm } from 'types/PaymentTerm';

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const sortMonthlyTerms = (paymentTerms: PaymentTerm[]) => {
  if (paymentTerms.length === 0) {
    return [];
  }
  return paymentTerms.sort((a, b) => {
    if (a.date && b.date) {
      return a.date - b.date;
    }
    return 0;
  });
};

const sortWeeklyTerms = (paymentTerms: PaymentTerm[]) => {
  if (paymentTerms.length === 0) {
    return [];
  }
  return paymentTerms.sort((a, b) => {
    if (a.dayOfWeek && b.dayOfWeek) {
      const day1 = a.dayOfWeek.toLowerCase();
      const day2 = b.dayOfWeek.toLowerCase();
      return weekdays.indexOf(day1) - weekdays.indexOf(day2);
    }
    return 0;
  });
};

export const sortPaymentTerms = (paymentTerms: PaymentTerm[]) => {
  const monthlyTerms = paymentTerms.filter(
    (term) => term.frequency === 'MONTHLY'
  );
  const weeklyTerms = paymentTerms.filter(
    (term) => term.frequency === 'WEEKLY'
  );
  const sortedMonthlyTerms = sortMonthlyTerms(monthlyTerms);
  const sortedWeeklyTerms = sortWeeklyTerms(weeklyTerms);

  return [...sortedMonthlyTerms, ...sortedWeeklyTerms];
};
