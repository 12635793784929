import React, { useEffect, useState } from 'react';
import UserVerification from 'pages/Verification/UserVerification';
import styled from 'styled-components';
import { Address } from 'types/Address';
import { Breadcrumb, Col, Row, Empty, notification } from 'antd';
import { History } from 'history';
import { relayNewLogo } from 'assets/images';
import { Close } from 'assets/icons';

import api from 'api';
import joinName from 'utils/joinName';
import processError from 'utils/processError';

import User from 'types/User';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 30px;
`;

const CloseButton = styled.img`
  cursor: pointer;
`;

const Container = styled.div`
  padding: 20px 10px;
  @media only screen and (min-width: 768px) {
    padding: 20px 100px;
  }
`;

const BreadcrumbContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Doc = styled.img`
  box-shadow: 0 4px 10px rgba(200, 200, 200, 0.5);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
`;

const Details = styled.div`
  box-shadow: 0 4px 10px rgba(200, 200, 200, 0.5);
  border-radius: 5px;
  padding: 10px;
`;

type Props = {
  history: History;
  location: {
    state: {
      countryCode: Address['countryCode'];
      companyId: string;
      companyName: string;
      owner: User;
    };
  };
  token: string;
};

const VerifyOwner: React.FC<Props> = ({ history, location, token }) => {
  const [documentLink, setDocumentLink] = useState<string | null>(null);

  const { companyId, companyName, owner, countryCode } = location.state;
  const { firstName, middleName, lastName } = owner;
  const name = joinName({ firstName, middleName, lastName });

  const goBack = (): void => {
    history.go(-1);
  };

  const handleOnDone = (): void => {
    history.push(`/companies/${companyId}`, {
      company: { id: companyId, companyName },
      userId: owner.id,
    });
  };

  useEffect(() => {
    if (owner && owner.verificationDocName) {
      api.identity
        .getVerificationDocLink(owner.id)
        .then((documentLink) => {
          setDocumentLink(documentLink);
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({
            message: 'error',
            description: message,
          });
        });
    }
  }, [owner]);

  if (!companyId) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Logo src={relayNewLogo} alt="relay logo" />
        <CloseButton src={Close} alt="go back" onClick={goBack} />
      </Header>

      <BreadcrumbContainer>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/companies">Companies</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a
              href={`/companies/${companyId}`}
              onClick={() => {
                history.go(-1);
              }}
            >
              {companyName}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbContainer>

      <Row gutter={32} wrap>
        <Col span={12}>
          {documentLink ? (
            <Doc src={documentLink} alt="document uploaded by user" />
          ) : (
            <Empty description="No document uploaded" />
          )}
        </Col>
        <Col span={12}>
          <Details>
            <UserVerification
              countryCode={countryCode}
              owner={owner}
              token={token}
              isLoggedInAsAdmin={true}
              onCancel={goBack}
              onDone={handleOnDone}
            />
          </Details>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyOwner;
