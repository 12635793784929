import React from 'react';
import colors from 'colors';
import styled from 'styled-components/macro';
import { formatCurrency } from 'utils';
import { Col, Row, Typography, Divider } from 'antd';
import { AmountStrip } from 'components';
import moment, { Moment } from 'moment';

const { Text } = Typography;

const Header = styled(Text)`
  font-size: 12px;
  color: ${colors.greys600};
`;

const Details = styled(Text)`
  font-size: 14px;
  color: ${colors.greys900};
`;

const StyledDivider = styled(Divider)`
  margin-top: 4px;
  margin-bottom: 10px;
`;

const CreditNoteDetails: React.FC<{
  amount: string;
  companyName: string;
  number: string;
  issueDate: Moment;
}> = ({ amount, companyName, issueDate, number }) => {
  return (
    <>
      <Row>
        <Col flex={1}>
          <Header>CREDIT NOTE #</Header>
          <StyledDivider />
          <Details>{number}</Details>
        </Col>
        <Col
          flex={1}
          css={`
            text-align: right;
          `}
        >
          <Header>ISSUE DATE</Header>
          <StyledDivider />
          <Details>{moment(issueDate).format('DD MMM YYYY').valueOf()}</Details>
        </Col>
        <Col
          flex={2}
          css={`
            text-align: right;
          `}
        >
          <Header>SUPPLIER</Header>
          <StyledDivider />
          <Details>{companyName}</Details>
        </Col>
      </Row>

      <Divider
        css={`
          margin-top: 14px;
          margin-bottom: 24px;
        `}
      />

      <AmountStrip
        label="Credit Amount"
        value={formatCurrency(Number(amount))}
        css={`
          position: relative;
          left: -2%;
          width: 104%;
        `}
      />

      <Divider
        css={`
          margin-top: 24px;
          margin-bottom: 12px;
        `}
      />
    </>
  );
};

export default CreditNoteDetails;
