import { useState, useEffect, useRef } from 'react';
import Coordinate from 'types/RipeDraw';

type PointLineProp = {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  d: number;
};

const pointInTheLine = ({ x0, y0, x1, y1, d }: PointLineProp) => {
  const slope = (y1 - y0) / (x1 - x0);
  const angle = Math.atan(slope);
  const x2 = x1 + d * Math.cos(angle);
  const y2 = y1 + d * Math.sin(angle);
  return { x2, y2 };
};

export function draw(ctx: any, coords: Coordinate) {
  let { startX, startY, endX, endY } = coords;
  startX = startX + 110; //To start only after the close icon
  startY = startY + 14; //To start from vertical center of that element
  endX = endX - 10; //To end with small margin with container
  endY = endY + 10; // to end at the middle of the element
  let slope = (endY - startY) / (endX - startX);

  let tangX = startX,
    tangY = startY;

  //Updating y coordinate based on the slope
  if (slope < 0) {
    tangY = startY - 30;
  } else {
    tangY = startY + 30;
  }
  slope = Math.abs(slope); //taking the absolute value because slope can be negative

  //Findking the x coordinate of the tangent to draw the arc
  if (slope > 0 && slope < 0.35) {
    tangX = startX + 200;
  } else if (slope >= 0.35 && slope < 0.5) {
    tangX = startX + 150;
  } else if (slope >= 0.5 && slope < 0.62) {
    tangX = startX + 120;
  } else if (slope >= 0.62) {
    tangX = startX + 100;
  }
  const radius = 4; //Radius of the circle that shown in the beginning and end of line
  const { x2, y2 } = pointInTheLine({
    //Find the point in the line to draw a circle at the
    x0: startX + 100, //end with (x2,y2) as center
    y0: startY + 30,
    x1: endX,
    y1: endY,
    d: radius, //radius of arc
  });
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#2e59ff';

  ctx.arc(startX, startY, radius, 0, 2 * Math.PI);
  // Create a starting point
  ctx.moveTo(startX + radius, startY); // Line starts after the circle, so push x coordinate r distance
  ctx.lineTo(startX + 40, startY); // Create a horizontal line
  if (slope >= 0.1) {
    ctx.arcTo(startX + 70, startY, tangX, tangY, 20); // Create an arc only if there is slope between points
  }
  ctx.lineTo(endX, endY); // Continue with vertical line
  ctx.stroke(); //Draw
  ctx.beginPath();
  ctx.arc(x2, y2, radius, 0, 2 * Math.PI);
  ctx.stroke(); //Draw
}

export function useCanvas() {
  const canvasRef = useRef(null);
  const [coordinates, setCoordinates] = useState<Coordinate | undefined>();

  useEffect(() => {
    const canvasObj: any = canvasRef.current;

    if (canvasObj) {
      const ctx = canvasObj.getContext('2d');
      // clear the canvas area before rendering the coordinates held in state
      ctx.clearRect(0, 0, canvasObj.width, canvasObj.height);

      // draw all lines
      coordinates && draw(ctx, coordinates);
    }
  }, [coordinates]);

  return [setCoordinates, canvasRef] as const;
}
