import axios from 'axios';

const verify = async token => {
  try {
    await axios.post('/login/captcha/verify', { token });
    return true;
  } catch {
    return false;
  }
};

export default { verify };
