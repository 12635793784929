import axios from 'axios';

const notify = (payload: {
  companyId: string;
  invoiceIds: string[];
  type: 'INVOICE' | 'BILL';
  note?: string;
}): Promise<boolean> =>
  new Promise((resolve, reject) => {
    axios
      .post('/notify', payload)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });

export default { notify };
