import React from 'react';
import styled from 'styled-components/macro';
import { CSVLink } from 'react-csv';
import { Typography } from 'antd';
import { MergeStats } from 'types/CounterParty';
import { getTextWithCount } from 'utils';

const { Text } = Typography;

const ViewDetails = styled(Text)`
  :hover {
    cursor: pointer;
  }
`;

export const InvalidCountMessage: React.FC<{
  invalidBillCount?: number;
  invalidCreditNoteCount?: number;
  invalidInvoiceCount?: number;
  invalidItems?: MergeStats['invalidItems'];
}> = ({
  invalidBillCount,
  invalidCreditNoteCount,
  invalidInvoiceCount,
  invalidItems,
}) => {
  if (invalidBillCount || invalidCreditNoteCount) {
    return (
      <>
        <Text>
          <Text strong>{getTextWithCount('bill', invalidBillCount)}</Text>,{' '}
          <Text strong>{getTextWithCount('invoice', invalidInvoiceCount)}</Text>{' '}
          and{' '}
          <Text strong>
            {getTextWithCount('credit note', invalidCreditNoteCount)}
          </Text>{' '}
          are invalid and will be deleted on merge{' '}
          {invalidItems && (
            <CSVLink
              css={`
                display: block;
              `}
              data={invalidItems}
              filename="invalid_bills_and_credit_notes.csv"
            >
              <ViewDetails underline>view details</ViewDetails>
            </CSVLink>
          )}
        </Text>
      </>
    );
  }
  return null;
};
