import React, { FC, useRef, useState, ChangeEvent } from 'react';
import { Button, Input, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

import api from 'api';
import { processError } from 'utils';

import colors from 'colors';
import CreditNote from 'types/CreditNote';
const { Text } = Typography;

const Container = styled.div`
  margin-top: 1.1rem;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const Label = styled(Text)`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.greys600};
`;
const Name = styled(Input)`
  color: ${colors.greys700} !important;
  cursor: auto !important;
  padding: 5px 8px 5px 16px;
`;
const ReferenceLink = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.blues.dark};
  cursor: pointer;
  margin-left: 16px;
`;

const SuffixButton = styled(Button)`
  padding: 0;
  min-width: 0;
  height: 24px;
`;

const ErrorText = styled(Text)`
  font-size: 14px;
  line-height: 20px;
`;

type Props = {
  supplierReference: string | null;
  creditNoteId: string;
  modificationTime: number;
  setCreditNote?: (value: CreditNote) => void;
  refreshTableData?: () => void;
  isEditable?: boolean;
};

const SupplierReference: FC<Props> = ({
  supplierReference,
  creditNoteId,
  modificationTime,
  setCreditNote,
  refreshTableData,
  isEditable,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState('');
  const [reference, setReference] = useState(supplierReference || '');
  const [loading, setLoading] = useState(false);
  const onClickReferenceLink = () => {
    setIsEditMode(true);
  };

  // ref to blur the input after the action
  const inputRef = useRef<Input>(null);

  const onSave = () => {
    setLoading(true);
    api.creditNotes
      .patch({
        id: creditNoteId,
        value: reference,
        modificationTime,
        path: '/supplier_reference',
        operation: 'replace',
      })
      .then((result) => {
        refreshTableData && refreshTableData();
        setCreditNote && setCreditNote(result);
        inputRef.current?.blur();
        setLoading(false);
        setIsEditMode(false);
      })
      .catch((err) => {
        const { message } = processError(err);
        setError(message);
        setLoading(false);
      });
  };

  const validReference = (value: string): boolean => {
    // valid supplier reference value have alphabets, digits or special charcters '_' and '-'
    return /^[a-zA-Z0-9-_]+$|^$|^\s+$/.test(value);
  };

  const onCancel = () => {
    if (!loading) {
      setReference(supplierReference || '');
      setIsEditMode(false);
      setError('');
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    if (e.target.value.length > 20) {
      // to restrict the input length to 20 characters which is the allowed max length
      return;
    }
    if (e.target.value && !validReference(e.target.value)) {
      // show error if user inputs special charcters other than '_' and '-'
      setError('Allowed special charaters are "-" and "_"');
    }

    setReference(e.target.value);
  };
  if (!isEditable && !supplierReference) {
    return null;
  } else {
    return (
      <Container>
        {!supplierReference && !isEditMode ? (
          <ReferenceLink onClick={onClickReferenceLink}>
            Add Supplier Reference
          </ReferenceLink>
        ) : (
          <>
            <Label>SUPPLIER REFERENCE</Label>
            <InputWrapper>
              <Name
                ref={inputRef}
                value={reference}
                suffix={
                  isEditable ? (
                    loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 18 }} spin />
                        }
                      />
                    ) : (
                      <SuffixButton
                        onClick={() => {
                          isEditMode ? onSave() : setIsEditMode(true);
                        }}
                        disabled={!!error}
                        type="link"
                        loading={loading}
                        data-testid="edit-save-button"
                      >
                        {isEditMode ? 'Save' : 'Edit'}
                      </SuffixButton>
                    )
                  ) : null
                }
                disabled={!isEditMode || !isEditable}
                onChange={onChange}
                onPressEnter={onSave}
                data-testid="supplier-reference-input"
              />
              {isEditable && isEditMode && (
                <Button type="ghost" onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </InputWrapper>
            {!!error && (
              <ErrorText type="danger" data-testid="supplier-reference-error">
                {error}
              </ErrorText>
            )}
          </>
        )}
      </Container>
    );
  }
};

export default SupplierReference;
