import React, { FC, useState, useEffect, useCallback } from 'react';
import api from 'api';
import colors from 'colors';
import styled from 'styled-components/macro';
import { formatCurrency } from 'utils';
import debounce from 'lodash.debounce';
import { Spin, Typography, AutoComplete, Input } from 'antd';
import { SearchOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Credit as CreditType, AllocationType } from '../AllocateCreditNote';
import { SelectValue } from 'antd/lib/select';

const DEBOUNCE_DELAY = 500; // in milliseconds

const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 34px;
  margin-left: 10px;
  .hover-add-button {
    color: transparent;
  }
  :hover .hover-add-button {
    color: ${colors.blues.dark};
  }
`;
const AddButton = styled(PlusCircleFilled)`
  color: transparent;
  margin-right: 10px;
`;
const ListItem = styled(Text)`
  color: ${colors.greys900};
  font-size: 14px;
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SearchIcon = styled(SearchOutlined)`
  font-size: 15px;
  color: ${colors.greys700};
`;
const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

const Done = styled(Text)`
  color: ${colors.blues.dark};
  font-size: 16px;
`;

type Props = {
  editCreditsList: CreditType[];
  fromTime: number;
  setNewCreditList: (list: CreditType[]) => void;
  supplierName: string;
  toTime: number;
  prevAllocations?: AllocationType[] | null;
};

const Search: FC<Props> = ({
  editCreditsList,
  fromTime,
  setNewCreditList,
  supplierName,
  toTime,
  prevAllocations,
}) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [optionsData, setOptionsData] = useState<CreditType[]>([]);
  const [fetching, setFetching] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getSearchResults = useCallback((url: string) => {
    setFetching(true);
    api.invoices
      .filter({ url })
      .then((results) => {
        let creditList: CreditType[] = [];
        results.invoices.forEach((element) => {
          if (element.invoice.total && element.invoice.discountedTotal) {
            creditList.push({
              invoiceId: element.invoice.id,
              amountDue: element.invoice.discountedTotal,
              allocatedAmount: '0.00',
              invoiceNumber: element.invoice.billNumber,
            });
          }
        });

        setOptionsData(creditList);
      })
      .finally(() => setFetching(false));
  }, []);

  useEffect(() => {
    let url = `?type=BILL&issuedByCompanyName=${encodeURIComponent(
      supplierName
    )}&status=ALL_ELIGIBLE&sort=discountedTotal,desc&dueDate=${fromTime}-${toTime}:range`;
    getSearchResults(url);
  }, [fromTime, supplierName, toTime, getSearchResults]);

  const onSelect = (invoiceId: SelectValue) => {
    const selected = optionsData.find((item) => item.invoiceId === invoiceId);
    if (selected) {
      const updatedList = [...editCreditsList, { ...selected }];
      setNewCreditList(updatedList);
    }
  };

  const onFocus = () => {
    setIsOpen(true);
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
    setIsOpen(false);
  };

  const renderOptions = () => {
    const filteredData = optionsData.filter(
      (option) =>
        !editCreditsList.find((item) => item.invoiceId === option.invoiceId)
    );
    return filteredData.map((credit) => {
      return {
        value: credit.invoiceId,
        label: (
          <Container>
            <ItemContainer>
              <AddButton className="hover-add-button" />
              <ListItem>{credit.invoiceNumber}</ListItem>
            </ItemContainer>

            <ListItem>{formatCurrency(credit.amountDue)}</ListItem>
          </Container>
        ),
      };
    });
  };
  const debounceLoadData = useCallback(
    debounce((searchValue: string) => {
      let url = `?type=BILL&issuedByCompanyName=${encodeURIComponent(
        supplierName
      )}&status=ALL_ELIGIBLE&sort=discountedTotal,desc&dueDate=${fromTime}-${toTime}:range`;
      if (searchValue) {
        url = `${url}&invoiceNumber=${encodeURIComponent(searchValue)}:like`;
      }
      getSearchResults(url);
    }, DEBOUNCE_DELAY),
    []
  );

  const onSearch = (value: string) => {
    setIsOpen(true);
    setIsFocus(true);
    setSearchValue(value);
    debounceLoadData(value);
  };
  const onDone = () => {
    setIsFocus(false);
    setIsOpen(false);
  };
  const onChange = () => {
    setIsOpen(true);
  };
  return (
    <StyledAutoComplete
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={renderOptions()}
      onSearch={onSearch}
      onSelect={onSelect}
      value={searchValue || ''}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      listHeight={212}
      open={isOpen}
    >
      <Input
        size="middle"
        placeholder="Add invoices"
        suffix={
          isFocus ? (
            <Done onClick={onDone}>Done</Done>
          ) : (
            <SearchIcon onClick={() => onSearch('')} />
          )
        }
        css={`
          height: 38px;
          border-radius: 2px;
        `}
      />
    </StyledAutoComplete>
  );
};

export default Search;
