import React from 'react';
import styled from 'styled-components';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

const SortingLabel = styled.span`
  display: flex;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 10px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const SortingDiv = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

type Props = {
  sortingLabel?: string;
  displaySortingIcon?: boolean;
  sort?: string;
  sortOrderParam?: string | null;
  handleSortChange: Function;
};

const SortingColumnHeader: React.FC<Props> = ({
  sortingLabel,
  displaySortingIcon,
  sort,
  sortOrderParam,
  handleSortChange,
}) => {
  const SortIcon =
    !sortOrderParam || sortOrderParam === 'asc'
      ? CaretUpFilled
      : CaretDownFilled;

  const handleSortClick = () => {
    const direction = sortOrderParam === 'asc' ? 'desc' : 'asc';
    const newSort = !sort ? null : `${sort},${direction}`;
    handleSortChange(newSort);
  };

  return (
    <SortingDiv>
      <SortingLabel onClick={() => handleSortClick()}>
        {sortingLabel}
      </SortingLabel>
      {displaySortingIcon ? (
        <SortIcon
          style={{
            marginLeft: 5,
            position: 'relative',
            top: 3,
          }}
          onClick={() => handleSortClick()}
        />
      ) : null}
    </SortingDiv>
  );
};

export default SortingColumnHeader;
