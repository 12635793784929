import BankAccount from 'types/BankAccount';

export type AccountFormValues = {
  bankAccountName: BankAccount['bankAccountName'];
  bankAccountNumber: BankAccount['bankAccountNumber'];
  bsb: BankAccount['bsb'];
  payid: BankAccount['payId'];
};

export type StatementFormValues = {
  file: File;
};

export enum AustraliaOptions {
  PAYID = 'PayID',
  ACCOUNT_NUMBER = 'Account number',
}

export enum Steps {
  DETAILS_ENTRY = 'DETAILS_ENTRY',
  DETAILS_REVIEW = 'DETAILS_REVIEW',
  BANK_STATEMENT = 'BANK_STATEMENT',
  CODE_ENTRY = 'CODE_ENTRY',
}
