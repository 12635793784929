import React from 'react';
import styled from 'styled-components';
import colors from 'colors';
import PasswordValidationResults from 'types/PasswordValidationResults';
import { Typography } from 'antd';

const { Text } = Typography;

const Container = styled.p`
  background-color: #fffdd1;
  border-radius: 4px;
  color: ${colors.text};
  margin-top: 12px;
  padding: 10px;
`;

type Props = {
  validationResults: PasswordValidationResults;
};

const PasswordHelpText: React.FC<Props> = ({ validationResults }) => (
  <Container>
    Password must be{' '}
    <Text delete={validationResults.hasLength}>at least 8 characters</Text> and
    include{' '}
    <Text delete={validationResults.hasNumber}>at least one number</Text>,{' '}
    <Text delete={validationResults.hasLowerCase}>one lowercase letter</Text>,{' '}
    <Text delete={validationResults.hasUpperCase}>one uppercase letter</Text>{' '}
    and{' '}
    <Text delete={validationResults.hasSpecialChar}>one special character</Text>
    .
  </Container>
);

export default PasswordHelpText;
