import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Select, Checkbox, FormInstance } from 'antd';
import { DayOfWeek, PaymentFrequency, PaymentTerm } from 'types/PaymentTerm';

const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 165px;
`;

const dates = Array(31)
  .fill(0)
  .map((_, index) => index + 1);

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const daysOfWeekOptions = daysOfWeek.map((day) => ({
  label: day,
  value: day.toUpperCase(),
}));

const frequencyOptions = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Weekly', value: 'WEEKLY' },
];

type Props = {
  form: FormInstance<{
    frequency: PaymentFrequency;
    date?: number;
    day?: DayOfWeek;
  }>;
  handleSubmit: () => void;
  paymentTerm?: PaymentTerm;
};

export const AddUpdateTermForm: React.FC<Props> = ({
  form,
  handleSubmit,
  paymentTerm,
}) => {
  const [frequency, setFrequency] = useState<PaymentFrequency>(
    paymentTerm?.frequency || 'MONTHLY'
  );

  const handleChangeFrequency = (value: any) => setFrequency(value);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 14, offset: 0 }}
      labelAlign="left"
      initialValues={{
        ...paymentTerm,
        defaultTerm: paymentTerm?.defaultTerm || false,
        frequency,
      }}
    >
      <Form.Item
        label="Frequency"
        name="frequency"
        rules={[{ required: true }]}
      >
        <StyledSelect onChange={handleChangeFrequency}>
          {frequencyOptions.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </StyledSelect>
      </Form.Item>
      {frequency === 'MONTHLY' ? (
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: 'Payment date is required' }]}
        >
          <StyledSelect>
            {dates.map((date) => (
              <Option key={date} value={date}>
                {date}
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ) : (
        <Form.Item
          label="Day"
          name="dayOfWeek"
          rules={[{ required: true, message: 'Payment day is required' }]}
        >
          <StyledSelect>
            {daysOfWeekOptions.map((option) => (
              <Option key={option.label} value={option.value}>
                {option.label}
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>
      )}

      {/**
       * Checkbox for setting as default needs to be shown
       * only when editing existing record
       */}
      {paymentTerm && (
        <Form.Item
          label="Set as default"
          name="defaultTerm"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      )}
    </Form>
  );
};
