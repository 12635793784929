import React, { FC, useState, useEffect } from 'react';
import api from 'api';
import styled from 'styled-components/macro';
import colors from 'colors';
import SupplierInfo from 'components/AllocateCreditNote/SupplierInfo';
import AmountStrip from 'components/AmountStrip';
import { CreditAmount, SupplierReference } from 'components';
import InvoiceList from './InvoiceList';
import CreditNote, { AllocationType } from 'types/CreditNote';
import { formatCurrency, processError } from 'utils';
import { notification } from 'antd';

const Line = styled.div`
  margin-top: 8px;
  border: 1px solid ${colors.greys200};
`;

// type definitions
export type InvoiceAllocation = AllocationType & {
  amountDue: number;
  invoiceNumber: string;
};

type Props = {
  creditNote: CreditNote;
  invoiceType: 'BILL' | 'INVOICE';
  scrollHeight?: number;
};

const CreditNoteDetails: FC<Props> = ({
  creditNote,
  invoiceType,
  scrollHeight,
}) => {
  const [invoices, setInvoices] = useState<
    {
      invoiceNumber: string;
      creditApplied: number;
      amountDue: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (creditNote) {
      const invoiceIds = creditNote.invoiceAllocations?.map(
        (allocation) => allocation.invoiceId
      );
      const allocations: {
        invoiceNumber: string;
        creditApplied: number;
        amountDue: number;
        key: string;
      }[] = [];
      if (invoiceIds && invoiceIds.length) {
        const urlParams = `?type=${invoiceType}&sort=discountedTotal,desc&ids=${invoiceIds.toString()}`;
        api.invoices
          .filter({ url: urlParams })
          .then((result) => {
            result.invoices.forEach((invoiceItem) => {
              const allocatedAmount = creditNote.invoiceAllocations
                ?.filter((item) => item.invoiceId === invoiceItem.invoice.id)
                ?.reduce(
                  (total, item) =>
                    total + parseFloat(`${item.allocatedAmount}`),
                  0.0
                );
              allocatedAmount &&
                allocations.push({
                  amountDue: invoiceItem.invoice.discountedTotal,
                  creditApplied: Number(allocatedAmount.toFixed(2)),
                  invoiceNumber: invoiceItem.invoice.billNumber,
                  key: invoiceItem.invoice.billNumber,
                });
            });
            setInvoices(allocations);
          })
          .catch((err) => {
            const { message } = processError(err);
            notification.error({ message });
          })
          .finally(() => setLoading(false));
      }
    }
  }, [creditNote, invoiceType]);

  return (
    <>
      <SupplierInfo name={creditNote?.issuedByCompanyName || 'Supplier'} />

      <SupplierReference
        supplierReference={creditNote.supplierReference}
        creditNoteId={creditNote.id}
        modificationTime={creditNote.modificationTime}
        isEditable={creditNote.status === 'UNALLOCATED'}
      />
      <CreditAmount
        totalAmount={creditNote.total}
        creditNoteId={creditNote.id}
        modificationTime={creditNote.modificationTime}
        isEditable={creditNote.status === 'UNALLOCATED'}
      />

      <AmountStrip
        label="Credit Remaining"
        value={formatCurrency(creditNote.unallocatedTotal)}
        compact={true}
        background={colors.blues.lighter}
        css={`
          margin-top: 24px;
        `}
      />

      <InvoiceList
        invoices={invoices}
        loading={loading}
        scrollHeight={scrollHeight}
      />
      <Line />
      <AmountStrip
        label="Credit Applied"
        value={formatCurrency(creditNote.allocatedTotal)}
        background="#F6F2FF"
        css={`
          margin-top: 24px;
        `}
      />
    </>
  );
};

export default CreditNoteDetails;
