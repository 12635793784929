import React from 'react';
import styled from 'styled-components';

import colors from 'colors';

const Container = styled.div`
  position: absolute;
  right: 6px;
  display: flex;
  align-items: center;
  padding: 8px 11px;
  border: 1px solid ${colors.greys300};
  border-radius: 4px;
  height: 36px;
  width: 150px;
`;

const Dollar = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: ${colors.greys700};
`;

const Field = styled.input`
  border: none;
  font-size: 14px;
  margin-left: 6px;
  max-width: 110px;
`;

const Input: React.FC<{
  updateCustomInput: (value: string) => void;
  value?: number;
}> = ({ updateCustomInput, value }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCustomInput(e.target.value);
  };

  return (
    <Container>
      <Dollar>$</Dollar>
      <Field
        placeholder="Enter Amount"
        onChange={handleChange}
        value={value || ''}
      />
    </Container>
  );
};

export default Input;
