import React, { useCallback, useEffect, useState } from 'react';
import InputItem from 'components/InputItem';
import CsvImport from 'components/CsvImport';
import { RadioButtons } from 'ds';
import ConfirmationScreen from './ConfirmationScreen';
import moment from 'moment';
import styled from 'styled-components/macro';
import { hideImportFileWindow } from 'store/invoices';
import {
  getPurchaserOffersAvailable,
  getPurchaserOffersOffered,
} from 'store/offers';
import { setRefreshData } from 'store/ui';
import { connect } from 'react-redux';
import { Checkbox, Modal, notification, Typography } from 'antd';
import { ModalHeader } from 'components';

import api from 'api';
import {
  getTimestamp,
  getTodayTimestamp,
  processError,
  removeSpaces,
  validateEmail,
} from 'utils';
import CsvDownload from 'components/CsvDownload';

const { Text } = Typography;

const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const fileFormats = [
  { label: 'Xero', value: 'XERO' },
  { label: 'Relay', value: 'RELAY' },
];

const MAX_TRIES_TO_GET_PROGRESS = 3;
const PROGRESS_INTERVAL = 1000; // delay in trying to get progress in milliseconds

const dateFormat = /^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/;

const validateDate = (date) =>
  date && typeof date === 'string' && date.trim().match(dateFormat);

const ImportFile = (props) => {
  const {
    isVisibleImportFileWindow,
    hideImportFileWindow,
    user,
    type,
    getPurchaserOffersAvailable,
    getPurchaserOffersOffered,
    isRipeEnabled,
    isCreateBillInXeroEnabled,
    setRefreshData,
  } = props;

  const [fileFormat, setFileformat] = useState('XERO');

  const shouldShowImportToXero =
    type === 'BILL' &&
    fileFormat === 'RELAY' &&
    isRipeEnabled &&
    isCreateBillInXeroEnabled;

  // createBillInXero will be made mandatory
  const [createBillInXero, setCreateBillInXero] = useState(
    shouldShowImportToXero
  );
  const [fileData, setFileData] = useState(null);
  const [dateValidationError, setDateValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [invoices, setInvoices] = useState(null);
  const [totalInvoices, setTotal] = useState(0);
  const [validInvoices, setValid] = useState(0);
  const [failedInvoicesCount, setFailed] = useState(0);
  const [isFinish, setFinish] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [warningInvoices, setWarningInvoices] = useState([]);
  const [importedInvoices, setImportedInvoices] = useState(null);
  const [warningInvoicesForDownload, setWarningInvoicesForDownload] = useState(
    []
  );
  const [failedInvoicesForDownload, setFailedInvoicesForDownload] = useState(
    []
  );

  const [incorrectTemplateError, setIncorrectTemplateError] = useState(false);
  const [orderNumberError, setOrderNumberError] = useState('');
  // to show import file progress
  const [progressView, setProgressView] = useState(false);
  const [importProgress, setImportProgress] = useState('0.00');
  const [progressInterval, setProgressInterval] = useState(null);

  useEffect(() => {
    setCreateBillInXero(shouldShowImportToXero);
  }, [shouldShowImportToXero]);

  const validate = useCallback(
    (item) => {
      const keys = Object.keys(item);
      if (fileFormat === 'RELAY') {
        const {
          amount_due__inc__gst_,
          issue_date,
          due_date,
          invoice_number,
          order_number,
          contact_email,
        } = item;
        const contactname =
          type === 'INVOICE' ? item.customer_name : item.supplier_name;

        const amountDue = keys.some((ele) => ele === 'amount_due__inc__gst_');
        const dueDate = keys.some((ele) => ele === 'due_date');
        const invoiceNumber = keys.some((ele) => ele === 'invoice_number');
        const contactName =
          type === 'INVOICE'
            ? keys.some((ele) => ele === 'customer_name')
            : keys.some((ele) => ele === 'supplier_name');
        if (!amountDue || !dueDate || !invoiceNumber || !contactName) {
          setIncorrectTemplateError(true);
        }
        if (createBillInXero && !order_number) {
          setOrderNumberError(
            'Order number is mandatory in the file if you need to import your bills to Xero'
          );
        }
        const formatDueDate = moment(due_date, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
        const formattedIssueDate = issue_date
          ? moment(issue_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : getTodayTimestamp();
        const trimmedContactEmail =
          typeof contact_email === 'string'
            ? contact_email.trim()
            : contact_email;
        const isValidEmail = validateEmail(trimmedContactEmail);
        const trimmedAmount =
          typeof amount_due__inc__gst_ === 'string'
            ? removeSpaces(amount_due__inc__gst_)
            : amount_due__inc__gst_;

        if (
          due_date &&
          formatDueDate !== 'Invalid date' &&
          validateDate(due_date) &&
          (issue_date
            ? Boolean(
                formattedIssueDate !== 'Invalid date' &&
                  validateDate(issue_date) &&
                  getTimestamp(formattedIssueDate) <= getTodayTimestamp() // check if future date
              )
            : true) &&
          trimmedAmount &&
          Number(trimmedAmount) > 0 &&
          contactname &&
          invoice_number
        ) {
          return {
            formatDueDate:
              typeof formatDueDate === 'string'
                ? formatDueDate.trim()
                : formatDueDate,
            formattedIssueDate:
              typeof formattedIssueDate === 'string'
                ? formattedIssueDate.trim()
                : formattedIssueDate,
            total: trimmedAmount,
            contactname:
              typeof contactname === 'string'
                ? contactname.trim()
                : contactname,
            invoicenumber:
              typeof invoice_number === 'string'
                ? invoice_number.trim()
                : invoice_number,
            billOrderNumber:
              typeof order_number === 'string'
                ? order_number.trim()
                : order_number,
            contactEmail: isValidEmail ? trimmedContactEmail : null,
          };
        } else {
          return false;
        }
      } else {
        const { duedate, total, contactname, invoicenumber, emailaddress } =
          item;
        const formatDueDate = moment(duedate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
        const trimmedEmailAddress =
          typeof emailaddress === 'string' ? emailaddress.trim() : emailaddress;
        const isValidEmail = validateEmail(trimmedEmailAddress);

        const totalAmount = keys.some((ele) => ele === 'total');
        const dueDate = keys.some((ele) => ele === 'duedate');
        const invoiceNumber = keys.some((ele) => ele === 'invoicenumber');
        const contactName = keys.some((ele) => ele === 'contactname');
        if (!totalAmount || !dueDate || !invoiceNumber || !contactName) {
          setIncorrectTemplateError(true);
        }

        const trimmedAmount =
          typeof total === 'string' ? removeSpaces(total) : total;

        const dateValidate =
          duedate &&
          typeof duedate === 'string' &&
          duedate.trim().match(dateFormat);

        if (
          duedate &&
          formatDueDate !== 'Invalid date' &&
          dateValidate &&
          trimmedAmount &&
          Number(trimmedAmount) > 0 &&
          contactname &&
          invoicenumber
        ) {
          return {
            formatDueDate:
              typeof formatDueDate === 'string'
                ? formatDueDate.trim()
                : formatDueDate,
            total: trimmedAmount,
            contactname:
              typeof contactname === 'string'
                ? contactname.trim()
                : contactname,
            invoicenumber:
              typeof invoicenumber === 'string'
                ? invoicenumber.trim()
                : invoicenumber,
            contactEmail: isValidEmail ? trimmedEmailAddress : null,
          };
        } else {
          return false;
        }
      }
    },
    [createBillInXero, fileFormat, type]
  );

  const processFileData = useCallback(
    (fileData) => {
      const payloadData = [];
      const imp_invoices = {};
      const uniqueData = fileData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => {
            if (fileFormat === 'RELAY') {
              const trimmedAmount =
                typeof t.amount_due__inc__gst_ === 'string'
                  ? removeSpaces(t.amount_due__inc__gst_)
                  : t.amount_due__inc__gst_;
              const trimmedInvoicenumber =
                typeof t.invoice_number === 'string'
                  ? t.invoice_number.trim()
                  : t.invoice_number;
              const trimmedItemAmount =
                typeof item.amount_due__inc__gst_ === 'string'
                  ? removeSpaces(item.amount_due__inc__gst_)
                  : item.amount_due__inc__gst_;
              const trimmedItemInvoicenumber =
                typeof item.invoice_number === 'string'
                  ? item.invoice_number.trim()
                  : item.invoice_number;
              return (
                trimmedInvoicenumber === trimmedItemInvoicenumber &&
                trimmedAmount === trimmedItemAmount
              );
            } else {
              const trimmedAmount =
                typeof t.total === 'string' ? removeSpaces(t.total) : t.total;
              const trimmedInvoicenumber =
                typeof t.invoicenumber === 'string'
                  ? t.invoicenumber.trim()
                  : t.invoicenumber;
              const trimmedItemAmount =
                typeof item.total === 'string'
                  ? removeSpaces(item.total)
                  : item.total;
              const trimmedItemInvoicenumber =
                typeof item.invoicenumber === 'string'
                  ? item.invoicenumber.trim()
                  : item.invoicenumber;
              return (
                trimmedInvoicenumber === trimmedItemInvoicenumber &&
                trimmedAmount === trimmedItemAmount
              );
            }
          })
      );
      setTotal(uniqueData.length);
      const issued = user.company.company.id;
      if (type === 'INVOICE') {
        uniqueData.forEach((item) => {
          const { type } = item;
          if (
            fileFormat === 'RELAY' ||
            (type && type.toLowerCase() === 'sales invoice')
          ) {
            const validatedData = validate(item);
            if (validatedData) {
              const {
                formatDueDate,
                total,
                contactname,
                invoicenumber,
                contactEmail,
              } = validatedData;
              const payload = {
                dueDate: getTimestamp(formatDueDate),
                total: String(total),
                issuedBy: issued,
                issuedForCompanyName: contactname,
                invoiceNumber: invoicenumber,
                contactEmail,
              };
              imp_invoices[invoicenumber] = payload;
              payloadData.push(payload);
              setIncorrectTemplateError(false);
            }
          }
        });
      } else {
        uniqueData.forEach((item) => {
          const { type } = item;
          if (
            fileFormat === 'RELAY' ||
            (type && type.toLowerCase() === 'bill')
          ) {
            const validatedData = validate(item);
            if (validatedData) {
              const {
                formatDueDate,
                formattedIssueDate,
                total,
                contactname,
                invoicenumber,
                contactEmail,
                billOrderNumber,
              } = validatedData;
              const payload = {
                dueDate: getTimestamp(formatDueDate),
                issueDate:
                  fileFormat === 'RELAY'
                    ? formattedIssueDate
                      ? getTimestamp(formattedIssueDate)
                      : getTodayTimestamp()
                    : undefined,
                total: String(total),
                issuedFor: issued,
                issuedByCompanyName: contactname,
                billNumber: invoicenumber,
                contactEmail,
                createBillInXero,
                billOrderNumber:
                  createBillInXero && billOrderNumber
                    ? billOrderNumber
                    : undefined,
              };
              imp_invoices[invoicenumber] = payload;
              payloadData.push(payload);
              setIncorrectTemplateError(false);
            }
          }
        });
      }
      setImportedInvoices(imp_invoices);
      setInvoices(payloadData);
      setValid(payloadData.length);
    },
    [createBillInXero, fileFormat, type, user.company.company.id, validate]
  );

  useEffect(() => {
    if (fileData) {
      processFileData(fileData);
    }
  }, [fileData, processFileData]);

  const onSubmit = (duplicatedInvoices) => {
    setLoading(true);
    setDateValidationError('');
    setIncorrectTemplateError(false);
    api.invoices
      .importInvoice({
        type,
        invoices:
          duplicatedInvoices && Array.isArray(duplicatedInvoices)
            ? duplicatedInvoices
            : invoices,
        ignoreWarnings: duplicatedInvoices ? true : false,
      })
      .then((res) => {
        setLoading(false);
        setProgressView(true);
        let triesToGetProgress = 1;
        // api to get import progress and data
        const progressInterval = setInterval(() => {
          api.invoices
            .getImportProgress(res)
            .then(async (response) => {
              const { success, total, failed, warningInvoices, progress } =
                response;
              if (parseInt(progress) === 100) {
                clearInterval(progressInterval);
                await setImportProgress(progress);
                setTimeout(() => {
                  setProgressView(false);
                  setFinish(duplicatedInvoices ? false : true);
                  setValid(success);
                  setTotal(total);
                  setFailed(failed);
                  setWarningInvoices(warningInvoices);
                  duplicatedInvoices && setInvoices(null);

                  const duplicateInvoices = [];
                  warningInvoices.length &&
                    warningInvoices.forEach((item) => {
                      const invoice = importedInvoices[Object.keys(item)];
                      duplicateInvoices.push(invoice);
                    });
                  setWarningInvoicesForDownload(duplicateInvoices);

                  //for failed invoices
                  const failedInvoicesData = [];
                  response.failedInvoices.length &&
                    response.failedInvoices.forEach((item) => {
                      const invoice = importedInvoices[Object.keys(item)];
                      const values = Object.values(item)[0];
                      failedInvoicesData.push({ ...invoice, reason: values });
                    });
                  setFailedInvoicesForDownload(failedInvoicesData);
                  setRefreshData(true);

                  duplicatedInvoices && hideImportFileWindow();
                  setImportProgress('0.00');
                  if (type === 'INVOICE') {
                    getPurchaserOffersAvailable();
                  } else {
                    getPurchaserOffersOffered();
                  }
                }, 2000);
              } else {
                setImportProgress(progress);
              }
            })
            .catch((err) => {
              const { status, message } = processError(err);
              if (
                status === 404 &&
                triesToGetProgress < MAX_TRIES_TO_GET_PROGRESS
              ) {
                triesToGetProgress++;
              } else {
                notification.error({
                  message:
                    status === 404 ? 'Error in importing from file' : message,
                });
                setProgressView(false);
                triesToGetProgress = 0;
                clearInterval(progressInterval);
                setProgressInterval(null);
              }
            });
        }, PROGRESS_INTERVAL);
        setProgressInterval(progressInterval);
      })
      .catch((err) => {
        const { message } = processError(err);
        notification.error({ message });
        setLoading(false);
      });
  };

  const cancel = () => {
    setIsEditing(true);
    hideImportFileWindow();
    setInvoices(null);
  };

  const createInvoice = () => setIsEditing(false);

  const onFinish = () => {
    onSubmit(warningInvoicesForDownload);
  };

  const handleFileLoaded = (data) => {
    setFileData(data);
  };

  const handleFileFormatchange = (e) => {
    const format = e.target.value;
    if (format === 'XERO') {
      setOrderNumberError('');
    }
    setFileformat(format);
  };

  const handleClickImportToXero = (e) => {
    const checked = e.target.checked;
    if (!checked) {
      setOrderNumberError();
    }
    setCreateBillInXero(checked);
  };

  return (
    <Modal
      visible={isVisibleImportFileWindow}
      closable={false}
      onCancel={cancel}
      cancelButtonProps={{
        type: 'ghost',
        style: {
          display:
            progressView ||
            (!isFinish && validInvoices !== 0) ||
            (isFinish && warningInvoices.length)
              ? 'initial'
              : 'none',
        },
      }}
      cancelText={
        !isFinish && validInvoices !== 0
          ? 'Cancel'
          : isFinish && warningInvoices.length
          ? 'No'
          : ''
      }
      onOk={
        loading === false && isEditing
          ? createInvoice
          : !isFinish
          ? validInvoices === 0
            ? cancel
            : () => onSubmit()
          : isFinish && warningInvoices.length
          ? onFinish
          : cancel
      }
      okButtonProps={{
        disabled:
          !invoices ||
          dateValidationError ||
          incorrectTemplateError ||
          (createBillInXero && orderNumberError) ||
          (!isEditing &&
            !isFinish &&
            !isChecked &&
            fileFormat === 'RELAY' &&
            validInvoices !== 0),
        loading,
        style: { display: progressView ? 'none' : 'initial' },
      }}
      okText={
        isEditing
          ? 'Continue'
          : isFinish && warningInvoices.length
          ? 'Yes'
          : isFinish && warningInvoices.length === 0
          ? 'Finish'
          : validInvoices === 0
          ? 'Close'
          : 'Confirm'
      }
      title={
        <ModalHeader
          onClose={() => {
            hideImportFileWindow();
            clearInterval(progressInterval);
          }}
          title={
            isEditing
              ? `Import ${type === 'BILL' ? 'Bills' : 'Invoices'}`
              : isFinish
              ? 'Import Results'
              : progressView
              ? 'Import in progress'
              : 'Confirm Import'
          }
        />
      }
    >
      {isEditing ? (
        <>
          <InputItem label="Select file format">
            <RadioButtons
              options={fileFormats}
              onChange={handleFileFormatchange}
              value={fileFormat}
            />
          </InputItem>
          {fileFormat === 'RELAY' && (
            <DownloadContainer>
              <CsvDownload
                label={`Download ${
                  type === 'INVOICE' ? 'invoice ' : 'bill '
                }import
                template`}
                filename={
                  type === 'BILL'
                    ? 'import_approved_bills'
                    : 'import_approved_invoices'
                }
              />
            </DownloadContainer>
          )}
          <CsvImport
            label="Select CSV file"
            error={
              incorrectTemplateError &&
              `Incorrect template. Please check you are using the correct template to import ${
                type === 'INVOICE' ? 'invoice' : 'bill'
              }s.`
            }
            fileLoadedHandler={handleFileLoaded}
          />
          {shouldShowImportToXero && (
            <Checkbox
              checked={createBillInXero}
              onChange={handleClickImportToXero}
              disabled={createBillInXero} // always disabled
            >
              Import to Xero
            </Checkbox>
          )}
          {!!orderNumberError && (
            <Text
              css={`
                display: block;
                margin-top: 10px;
              `}
              type="danger"
            >
              {orderNumberError}
            </Text>
          )}
        </>
      ) : (
        <ConfirmationScreen
          isFinish={isFinish}
          totalInvoices={totalInvoices}
          invoiceType={type}
          validInvoices={validInvoices}
          warningInvoices={warningInvoices}
          isChecked={isChecked}
          setChecked={setChecked}
          fileFormat={fileFormat}
          warningInvoicesForDownload={warningInvoicesForDownload}
          progressView={progressView}
          importProgress={importProgress}
          failedInvoicesCount={failedInvoicesCount}
          failedInvoicesForDownload={failedInvoicesForDownload}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = ({ auth, invoices, settings, ui }) => ({
  isVisibleImportFileWindow: invoices.isVisibleImportFileWindow,
  user: auth.user,
  type: invoices.type,
  isRipeEnabled: settings.isRipeEnabled,
  isCreateBillInXeroEnabled: settings.isCreateBillInXeroEnabled,
});

const mapDispatchToProps = {
  hideImportFileWindow,
  getPurchaserOffersAvailable,
  getPurchaserOffersOffered,
  setRefreshData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportFile);
