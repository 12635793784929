import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from 'themes';

const StyledContainer = styled.div`
  ${'' /* position: absolute; */}
  width: 258px;
  height: 24px;
  ${'' /* right: 56px; */}
  background: #ffffff;
  border: 0.5px solid;
  border-color: ${(props) => (props.isInFocus ? '#2F80ED' : colors.greys500)};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const Container = ({ children }) => {
  const [isInFocus, updateFocus] = useState(false);
  const changeFocus = () => {
    updateFocus(!isInFocus);
  };
  return (
    <StyledContainer
      onFocus={changeFocus}
      onBlur={changeFocus}
      isInFocus={isInFocus}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
