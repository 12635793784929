import React, { useState } from 'react';
import styled from 'styled-components';
import api from 'api';
import { useDispatch } from 'react-redux';
import { Modal, Button, notification } from 'antd';
import { setRefreshData } from 'store/ui';
import { colors } from 'themes';
import {
  validateEmail,
  processError,
  isInvitedByLoggedUserCompany,
} from 'utils';
import Invoice from 'types/Invoice';

const Content = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: ${colors.greys700};
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Email = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.greys700};
  margin-right: 12px;
  margin-top: 10px;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  color: #333333;
`;
type Props = {
  visibleInviteModal: boolean;
  selectedInvoices: Invoice[];
  companyId: string;
  toggleInviteInvoiceModel: () => void;
};

const InviteInvoice: React.FC<Props> = ({
  visibleInviteModal,
  selectedInvoices,
  companyId,
  toggleInviteInvoiceModel,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const hideWindow = () => {
    toggleInviteInvoiceModel();
  };

  const validInvoices = selectedInvoices.filter((invoice) => {
    const isInvitedByLoggedInUser = isInvitedByLoggedUserCompany(
      invoice,
      companyId
    );

    const companyStatus = invoice.owingCompany.status;

    return (
      (companyStatus === 'INACTIVE' ||
        (companyStatus === 'INVITED' && !isInvitedByLoggedInUser)) &&
      (invoice.billStatus === 'PAYMENT_OFFERED' ||
        invoice.invoiceStatus === 'CASHBACK_OFFERED')
    );
  });

  const groupedInvoices: { [key: string]: Invoice[] } = {};
  // groupedInvoices is going to be an object with companyIds as key and arrays of invoices as value
  validInvoices.forEach((invoice) => {
    if (groupedInvoices[invoice.issuedFor]) {
      groupedInvoices[invoice.issuedFor].push(invoice);
    } else {
      groupedInvoices[invoice.issuedFor] = [invoice];
    }
  });

  const groupedInvoicesWithContact: {
    [key: string]: {
      invoices: Invoice[];
      email: string;
    };
  } = {};
  // groupedInvoicesWithContact is going to be an object with companyIds as keys
  // The value of each companyId will be another object of the structure {email: string, invoices: array[invoice]}
  // groupedInvoicesWithContact will hold companies that have contact information
  let totalCompanies = 0;
  let companiesWithContact = 0;
  for (const [companyId, invoices] of Object.entries(groupedInvoices)) {
    totalCompanies++;
    const invoiceWithContact = invoices.find((invoice) =>
      validateEmail(invoice.contactEmail ?? undefined)
    );
    if (invoiceWithContact) {
      companiesWithContact++;
      let eligibleInvoices = invoices;
      groupedInvoicesWithContact[companyId] = {
        invoices: eligibleInvoices,
        email: invoiceWithContact.contactEmail!,
      };
    }
  }

  const onConfirm = () => {
    setLoading(true);
    const axiosRequests = [];
    //let invoiceIds = [];
    for (const [companyId, obj] of Object.entries(groupedInvoicesWithContact)) {
      const payload = {
        email: obj.email,
        companyId,
        note: '',
        type: 'INVOICE' as const,
      };
      const request = api.invite.sendInvite(payload);
      axiosRequests.push(request);
    }
    Promise.all(axiosRequests)
      .then(() => {
        dispatch(setRefreshData(true));
        hideWindow();
      })
      .catch((error) => {
        const { message } = processError(error);
        notification.error({ message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actions = [
    <Button key="back" type="ghost" onClick={hideWindow}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      disabled={!validInvoices.length}
      loading={loading}
      onClick={onConfirm}
    >
      Confirm
    </Button>,
  ];
  const emptyAction = [
    <Button key="back" type="primary" onClick={hideWindow}>
      OK
    </Button>,
  ];

  return (
    <Modal
      visible={visibleInviteModal}
      onCancel={hideWindow}
      footer={validInvoices.length ? actions : emptyAction}
      title={<Title>Invite your customers</Title>}
    >
      {companiesWithContact > 0 ? (
        <Column>
          <Email>
            {`We found email contact details for ${companiesWithContact} out of 
            ${totalCompanies} customer${`${
              totalCompanies > 1 ? 's' : ''
            }`} selected.`}
          </Email>
          <Content style={{ marginTop: 20 }}>
            {`Click confirm to invite ${
              companiesWithContact === 1 ? 'this' : 'these'
            } ${`customer${companiesWithContact === 1 ? '' : 's'}`}
             to accept your early payment request`}
          </Content>
        </Column>
      ) : (
        <Column>
          <Content>The selected customers could not be invited</Content>
          <Content>
            This can happen if there are no active offers for this customer,
            they have already been invited or we could not find contact details.
          </Content>
          <Content>Please invite these customers individually.</Content>
        </Column>
      )}
    </Modal>
  );
};

export default InviteInvoice;
