import React, { Component } from 'react';

import { colors } from 'themes';
import { formatCurrency } from 'utils';
import AmountInput from 'components/AmountInput';

class DiscountEntry extends Component {
  onChange = (e) => {
    const discount = e.target.value;
    if (isNaN(discount)) {
      if (discount === '.') {
        this.props.onChange({ discount: '0.' });
      } else {
        this.props.onChange({ discount: 0 });
      }
    } else {
      this.props.onChange({ discount });
    }
  };

  onBlur = () => {
    let { discount } = this.props;
    discount = discount ? parseFloat(discount).toFixed(2) : null;
    this.props.onChange({ discount });
  };

  render() {
    const { label, discount, isEditing, discountError } = this.props;
    return (
      <div style={styles.container}>
        <p style={{ marginTop: 'auto', marginBottom: 'auto' }}> {label} </p>
        {isEditing ? (
          <div style={{ marginRight: -10 }}>
            <AmountInput
              testId="amount-input"
              value={discount}
              onChange={this.onChange}
              onBlur={this.onBlur}
              multiline={true}
              discountEntry={true}
              error={discountError ? true : false}
            />
          </div>
        ) : (
          <p style={styles.discount} data-testid="discount-entry-discount">
            {formatCurrency(discount)}
          </p>
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5,
  },
  textInput: {
    padding: 9,
    width: 130,
    height: 36,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 'normal',
    textAlign: 'right',
    color: colors.greys600,
    borderWidth: 1,
    marginTop: -5,
    border: '1px solid #BDC3CB',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  amountType: {
    fontSize: 16,
    color: colors.greys700,
    fontWeight: 'bold',
  },
  discount: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 'normal',
    textAlign: 'right',
    color: colors.greys900,
  },
};

export default DiscountEntry;
