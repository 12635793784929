import { MIN_SUPPLIER_OFFER_FEE, SUCCESS_FEE_PERCENTAGE } from 'config';
import SupplierOffer from 'types/SupplierOffer';

export const calculateCashback = (
  totalServiceFee: SupplierOffer['autobid']
) => {
  const calculatedServiceFee = totalServiceFee - MIN_SUPPLIER_OFFER_FEE;
  const cashback = calculatedServiceFee / (1 + SUCCESS_FEE_PERCENTAGE / 100);
  const roundedCashback = cashback.toFixed(2);
  return roundedCashback;
};
