import React from 'react';
import PayablesSummary from 'types/PayablesSummary';

import { Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const Email: React.FC<{
  email: string;
  status: PayablesSummary['status'];
  companyStatus: PayablesSummary['companyOwedStatus'];
}> = ({ email, status, companyStatus }) => {
  if (email && status === 'PAYMENT_OFFERED' && companyStatus === 'INACTIVE') {
    return (
      <Tooltip title={email}>
        <MailOutlined />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default Email;
