import React from 'react';
import InputItem from 'components/InputItem';
import { Input, Tooltip } from 'antd';
import {
  CheckCircleFilledGreen,
  CheckCircleFilledGrey,
  CircleFilledGrey,
  CheckCircleFilledOrange,
} from 'assets/icons';
import { VerificationStatus } from 'types/User';

type Props = {
  designation: string;
  editing: boolean;
  setEditing: (flag: boolean) => void;
  error: string;
  name: string;
  onKeyPress: (e: { key: string }) => void;
  onSubmitName: () => void;
  onNameEntry: (value: string) => void;
  isPrimaryUser: boolean;
  isLoggedInAsAdmin?: boolean;
  verificationStatus: VerificationStatus;
};

const NameEntry: React.FC<Props> = (props) => {
  const {
    designation,
    editing,
    setEditing,
    error,
    name,
    onKeyPress,
    onSubmitName,
    onNameEntry,
    isPrimaryUser,
    isLoggedInAsAdmin,
    verificationStatus,
  } = props;

  const onDone: () => void = () => {
    onSubmitName();
  };

  let iconSrc = '';
  let tooltip = '';
  switch (verificationStatus) {
    case 'NOT_VERIFIED':
      iconSrc = CircleFilledGrey;
      tooltip = 'Unverified';
      break;
    case 'VERIFIED':
      iconSrc = CheckCircleFilledGreen;
      tooltip = 'Verified';
      break;
    case 'VERIFICATION_FAILED':
      iconSrc = CheckCircleFilledGrey;
      tooltip = 'Incomplete';
      break;
    case 'VERIFICATION_PENDING':
      iconSrc = CheckCircleFilledOrange;
      tooltip = 'Pending';
      break;
    default:
      iconSrc = CheckCircleFilledGrey;
      break;
  }

  return (
    <InputItem
      label="Full legal name"
      sublabel="Enter your full legal name as it appears on your drivers license"
      error={error}
    >
      <Input
        value={name}
        prefix={
          !isPrimaryUser && (
            <Tooltip title={tooltip}>
              <img src={iconSrc} style={styles.icon} alt="" />
            </Tooltip>
          )
        }
        suffix={
          isPrimaryUser || isLoggedInAsAdmin ? (
            <span
              style={{ ...styles.suffix, ...styles.suffixWithAction }}
              onClick={() => {
                editing ? onDone() : setEditing(true);
              }}
            >
              {editing ? 'Done' : 'Edit'}
            </span>
          ) : (
            <span style={styles.suffix}>{designation}</span>
          )
        }
        disabled={!editing}
        onChange={(e) => {
          onNameEntry(e.target.value);
        }}
        onKeyPress={onKeyPress}
        size="large"
      />
    </InputItem>
  );
};

const styles = {
  suffix: { color: '#286EFF', fontSize: 14 },
  suffixWithAction: { cursor: 'pointer' },
  icon: { width: 20, height: 20, marginRight: 5, marginTop: -4 },
};

export default NameEntry;
