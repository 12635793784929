import axios from 'axios';
import queryString from 'query-string';

import { store } from './store';
store.subscribe(listener);

function listener() {
  let token = '';
  const { user } = store.getState().auth;
  if (user) {
    token = user.token;
  }
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.defaults.baseURL = window.env.API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

//Remove auto encoding by axios
axios.defaults.paramsSerializer = (params) => {
  const stringifiedParams = queryString.stringify(params, { encode: false });
  return stringifiedParams;
};
