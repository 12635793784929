import { useState } from 'react';

const Home = ({ history }) => {
  useState(() => {
    history.push('/invoices');
  }, [history]);

  return null;
};

export default Home;
