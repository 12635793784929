import React, { useEffect, useState } from 'react';
import Upload from 'pages/Verification/UserVerification/Upload';
import UserDetailsDisplay from 'pages/Verification/UserVerification/UserDetailsDisplay';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Button,
  Col,
  Divider,
  Empty,
  Modal,
  Row,
  Tooltip,
  Typography,
  notification,
  Skeleton,
} from 'antd';
import { LoadingIndicator, UserName } from 'components/UserVerification';
import PageTracker from 'components/PageTracker';
import {
  CheckCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import api from 'api';
import getHistoryItemsToRender from './getHistoryItemsToRender';
import joinName from 'utils/joinName';
import styles from './styles';
import processError from 'utils/processError';

import User from 'types/User';
import Company from 'types/Company';
import { useHistory, useLocation } from 'react-router';

const { Text } = Typography;

const HeadingContainer = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const RelayUserContainer = styled.div`
  margin-bottom: 20px;
  min-width: 300px;
`;

const UserDataRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const HistoryContainer = styled.div`
  width: 288px;
`;

const Document = styled.img`
  width: 420px;
`;

type Props = {
  company: Company;
};

const KYC: React.FC<Props> = ({ company }) => {
  const token = useSelector((state: any) => state.user?.token);

  const history = useHistory();
  const location = useLocation();

  const [isLoadingUsers, setLoadingUsers] = useState(true);
  const [selectedUser, selectUser] = useState<User | null>(null);
  const [relayUser, setRelayUser] = useState<User | null>(null);
  const [beneficialOwners, setBeneficialOwners] = useState<User[]>([]);
  const [isDetailsModalVisible, setDetailsModalVisibility] = useState(false);
  const [isUploadModalVisible, setUploadModalVisibility] = useState(false);
  const [isLoadingHistory, setLoadingHistory] = useState(false);
  const [documentLink, setDocumentLink] = useState<string | null>(null);
  const [ownerHistory, setOwnerHistory] = useState<User[]>([]);
  const [selectedHistoryState, selectHistoryState] = useState<User | null>(
    null
  );

  const userId = location.state && location.state.userId;

  const { id, companyName } = company;

  useEffect(() => {
    if (id) {
      api.company
        .getUsers(id)
        .then(async (response) => {
          const users = response.userResource.map((u) => u.user);
          const relayUser = users.find((o: User) =>
            o.roles.includes('ROLE_USER')
          );
          let otherUsers = users;
          if (relayUser) {
            otherUsers = users.filter((o: User) => o.id !== relayUser.id);
          }
          setRelayUser(relayUser || null);
          setBeneficialOwners(otherUsers);
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({
            message: 'Error fetching users',
            description: message,
          });
        })
        .finally(() => {
          setLoadingUsers(false);
        });
    }
  }, [id]);

  useEffect(() => {
    // When the selected owner gets updated, triggering fetching history
    // This can happen when either admin selects another owner or when admin
    // updates the details of any owner.
    if (selectedUser) {
      setLoadingHistory(true);
      api.user
        .getHistory(selectedUser.id)
        .then((history) => {
          const itemsToRender = getHistoryItemsToRender(
            history.sort((a, b) => a.creationTime - b.creationTime)
          );
          setOwnerHistory(itemsToRender);
        })
        .finally(() => {
          setLoadingHistory(false);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userId && relayUser) {
      if (relayUser.id === userId) {
        selectUser(relayUser);
      } else {
        const userToSelect = beneficialOwners.find((o) => o.id === userId);
        if (userToSelect) {
          selectUser(userToSelect);
        }
      }
    }
  }, [userId, relayUser, beneficialOwners]);

  useEffect(() => {
    // To get the verification doc link
    if (
      selectedHistoryState &&
      selectedUser &&
      selectedUser.verificationDocName
    ) {
      api.identity
        .getVerificationDocLink(selectedUser.id)
        .then((documentLink) => {
          setDocumentLink(documentLink);
        })
        .catch((error) => {
          const { message } = processError(error);
          notification.error({
            message: 'Error',
            description: message,
          });
        });
    } else {
      setDocumentLink(null);
    }
  }, [selectedHistoryState, selectedUser]);

  const renderOwnerName = (
    owner: User,
    selectedUserId: string | null
  ): React.ReactNode => {
    const { id, firstName, middleName, lastName, roles, verificationStatus } =
      owner;
    const name = joinName({ firstName, middleName, lastName });
    const onClick = () => {
      selectUser(owner);
      selectHistoryState(null);
    };
    const isSelected = selectedUserId && owner.id === selectedUserId;
    return (
      <UserName
        clickable={true}
        isSelected={!!isSelected}
        key={id}
        name={name}
        onClick={onClick}
        roles={roles}
        verificationStatus={verificationStatus}
      />
    );
  };

  const renderHistory = (): React.ReactNode => {
    const owners: User[] = [];
    if (relayUser) {
      owners.push(relayUser);
    }
    beneficialOwners.forEach((o) => {
      owners.push(o);
    });
    return ownerHistory.length ? (
      ownerHistory.map((item) => {
        const { verificationStatus, lastModifiedBy } = item;
        const isVerifiedByCompany = owners.some(
          (owner) => owner.username === lastModifiedBy
        );

        let label = '';
        switch (verificationStatus) {
          case 'NOT_VERIFIED':
            label = 'Created';
            break;
          case 'VERIFICATION_FAILED':
            label = 'Verification failed';
            break;
          case 'VERIFICATION_PENDING':
            label = 'Document uploaded';
            break;
          case 'VERIFIED':
            label = isVerifiedByCompany ? 'Verified' : 'Manually verified';
            break;
          default:
            label = verificationStatus;
        }
        const onClick = (): void => {
          selectHistoryState(item);
          setDetailsModalVisibility(true);
        };
        const isSelected =
          selectedHistoryState && selectedHistoryState.id === item.id;
        return (
          <UserName
            clickable={true}
            isSelected={!!isSelected}
            name={label}
            onClick={onClick}
            roles={[]}
            verificationStatus={verificationStatus}
            key={item.id}
          />
        );
      })
    ) : (
      <Text type="secondary">No history</Text>
    );
  };

  const goToOwnerVerificationPage = (): void => {
    history.push(`/companies/${id}/verify-owner`, {
      companyName,
      companyId: id,
      owner: selectedUser,
      countryCode: company.address?.country,
    });
  };

  const updateOwner = (owner: User) => {
    if (isUploadModalVisible) {
      setUploadModalVisibility(false);
    }
    selectUser(null);
    if (relayUser && owner.id === relayUser.id) {
      setRelayUser(owner);
    } else {
      const updatedBeneficialOwners = beneficialOwners.map((o) =>
        o.id === owner.id ? owner : o
      );
      setBeneficialOwners(updatedBeneficialOwners);
    }
    selectUser(owner);
  };

  const shouldShowUploadButton = ownerHistory.every(
    (history) => !history.verificationDocName
  );

  if (isLoadingUsers) {
    return <Skeleton />;
  }

  return (
    <>
      <Helmet>
        <title>KYC | {companyName} | Relay</title>
      </Helmet>
      <PageTracker />

      <Row gutter={32}>
        <Col>
          {isLoadingUsers ? (
            <LoadingIndicator />
          ) : (
            <>
              <HeadingContainer>
                <Text>Relay user</Text>
              </HeadingContainer>
              {relayUser ? (
                <RelayUserContainer>
                  {renderOwnerName(relayUser, selectedUser && selectedUser.id)}
                  <UserDataRow>
                    <MailOutlined style={{ marginRight: 10 }} />
                    <a
                      href={`mailto:${relayUser.username}`}
                      style={styles.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {relayUser.username}
                    </a>
                  </UserDataRow>

                  <UserDataRow>
                    <PhoneOutlined style={{ marginRight: 10 }} />
                    {relayUser.phoneNumber ? (
                      <>
                        <a
                          href={`tel:${relayUser.phoneNumber}`}
                          style={styles.link}
                          rel="noopener noreferrer"
                        >
                          {relayUser.phoneNumber}
                        </a>
                        <Tooltip
                          title={
                            relayUser.phoneNumberVerified
                              ? 'Verified'
                              : 'Unverified'
                          }
                        >
                          {relayUser.phoneNumberVerified ? (
                            <CheckCircleOutlined
                              style={{
                                ...styles.icon,
                                ...styles.iconSuccess,
                              }}
                            />
                          ) : (
                            <ExclamationCircleOutlined
                              style={{
                                ...styles.icon,
                                ...styles.iconWarning,
                              }}
                            />
                          )}
                        </Tooltip>
                      </>
                    ) : (
                      'Phone number not provided'
                    )}
                  </UserDataRow>
                </RelayUserContainer>
              ) : (
                <Text type="secondary">Relay user not found</Text>
              )}

              <Divider />

              <HeadingContainer>
                <Text>Beneficial owners</Text>
              </HeadingContainer>
              {beneficialOwners.length ? (
                beneficialOwners.map((user: User) =>
                  renderOwnerName(user, selectedUser && selectedUser.id)
                )
              ) : (
                <Text type="secondary">Beneficial owners not found</Text>
              )}
            </>
          )}
        </Col>
        <Col>
          {!!selectedUser && (
            <HistoryContainer>
              <HeadingContainer>
                <Text>Verification history</Text>
                <CloseOutlined
                  onClick={() => {
                    selectUser(null);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </HeadingContainer>
              {isLoadingHistory ? (
                <LoadingIndicator />
              ) : (
                <>
                  {renderHistory()}
                  {shouldShowUploadButton && (
                    <Button
                      block
                      onClick={() => {
                        setUploadModalVisibility(true);
                      }}
                      style={{ marginBottom: 10 }}
                    >
                      Upload document
                    </Button>
                  )}
                  {selectedUser.verificationStatus !== 'VERIFIED' && (
                    <Button
                      block
                      onClick={goToOwnerVerificationPage}
                      type="primary"
                    >
                      Verify
                    </Button>
                  )}
                </>
              )}
            </HistoryContainer>
          )}
        </Col>
      </Row>

      {!!selectedUser && !!selectedHistoryState && (
        <Modal
          footer={null}
          onCancel={() => {
            selectHistoryState(null);
            setDetailsModalVisibility(false);
          }}
          title={`${joinName({
            firstName: selectedUser.firstName,
            middleName: selectedUser.middleName,
            lastName: selectedUser.lastName,
          })} - ${
            selectedHistoryState.verificationStatus === 'VERIFICATION_PENDING'
              ? 'Document'
              : 'Details'
          }`}
          visible={isDetailsModalVisible}
        >
          {selectedHistoryState.verificationStatus ===
          'VERIFICATION_PENDING' ? (
            documentLink ? (
              <Document src={documentLink} alt="Uploaded document" />
            ) : (
              <Empty description="Could not load document" />
            )
          ) : (
            <UserDetailsDisplay
              countryCode={company.address?.country || null}
              user={selectedHistoryState}
              token={token}
              isLoggedInAsAdmin={true}
            />
          )}
          <Text type="secondary">
            Updated by <Text>{selectedHistoryState.lastModifiedBy}</Text> on{' '}
            <Text>
              {moment(selectedHistoryState.creationTime).format(
                'D MMM YYYY, HH:mm A'
              )}
            </Text>
          </Text>
        </Modal>
      )}

      {selectedUser && (
        <Modal
          footer={null}
          onCancel={() => {
            setUploadModalVisibility(false);
          }}
          title={`Upload document for ${selectedUser.firstName}`}
          visible={isUploadModalVisible}
        >
          <Upload
            owner={selectedUser}
            history={history}
            token={token}
            isLoggedInAsAdmin={true}
            updateOwner={updateOwner}
          />
        </Modal>
      )}
    </>
  );
};

export default KYC;
