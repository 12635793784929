import { getTodayTimestamp } from 'utils';
import Invoice from 'types/Invoice';
import SupplierOffer from 'types/SupplierOffer';
import PurchaserOffer from 'types/PurchaserOffer';
import findInvoiceStatus from 'pages/Invoices/utils/findInvoiceStatus';

type Props = {
  invoice: Invoice;
  supplierOffersOffered: SupplierOffer[];
  purchaserOffersOffered: { purchaserOffer: PurchaserOffer }[];
  setSelectedInvoice: () => void;
  showPaidInvoice: () => void;
  showSupplierOfferModal: () => void;
};

const InvoiceDataFormatter = ({
  invoice,
  supplierOffersOffered,
  purchaserOffersOffered,
  setSelectedInvoice,
  showPaidInvoice,
  showSupplierOfferModal,
}: Props) => {
  const today = getTodayTimestamp();

  const supplierOffer = supplierOffersOffered.find(
    (offer: SupplierOffer) =>
      offer.offerStatus !== 'EXPIRED' &&
      offer.invoiceIds[0] === invoice.id &&
      offer.expirationTime >= today
  );

  const purchaserOffer = purchaserOffersOffered.find(
    ({ purchaserOffer }: { purchaserOffer: PurchaserOffer }) =>
      (purchaserOffer.offerStatus === 'ACTIVE' ||
        purchaserOffer.offerStatus === 'VIRTUAL') &&
      purchaserOffer.createdByCompany === invoice.issuedFor &&
      invoice.dueDate >= today
  );
  const metaData = findInvoiceStatus({
    invoice,
    purchaserOffer: purchaserOffer?.purchaserOffer,
    supplierOffer,
    setSelectedInvoice,
    showPaidInvoice,
    showSupplierOfferModal,
  });

  return {
    ...invoice,
    ...metaData,
  };
};

export default InvoiceDataFormatter;
