import React, { useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';

import { Modal } from 'ds';
import Ruler from 'components/Ruler';

import FundsInfo from './FundsInfo';
import InvoiceInfo from './InvoiceInfo';
import Inputs from './Inputs';
import Footer from './Footer';

import {
  hideEarlyPaymentOffer,
  getPurchaserOffersOffered,
  updatePurchaserOfferOffered,
} from 'store/offers';
import { showTopUpWindow, refreshToken } from 'store/auth';
import { setRefreshData } from 'store/ui';
import { colors } from 'themes';
import processError from 'utils/processError';

import api from 'api';

const EarlyPaymentOffer = (props) => {
  const [crr, setCrr] = useState(null);
  const [cashpoolAmount, setCashpoolAmount] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error) => {
    notification.error({
      message:
        error ??
        'Something went wrong. Please try again or contact Relay if the issue persists.',
    });
  };

  const confirmCashpool = () => {
    props.refreshToken(props.user.token);
    if (!crr || !cashpoolAmount) {
      setEmptyMessage(true);
    } else {
      setEmptyMessage(false);
      if (cashpoolAmount < 1) {
        setErrorMessage('Cashpool amount cannot be less than $1');
      } else {
        setErrorMessage('');
        setLoading(true);
        const fundsAvailable = parseFloat(
          props.user.company.company.fundsAvailable
        );
        if (cashpoolAmount > fundsAvailable) {
          props.showTopUpWindow({
            trigger: 'PURCHASER_OFFER',
            triggerAmount: cashpoolAmount,
            crr: crr,
          });
          props.hideEarlyPaymentOffer();
          setCashpoolAmount(null);
          setCrr(null);
          setLoading(false);
        } else {
          const { bills } = props;
          let invoiceIds = [];
          bills.length &&
            bills.forEach((bill) => {
              if (bill.invoice.includeInPurchaserOffer) {
                invoiceIds.push(bill.invoice.id);
              }
            });

          // const expiresIn = moment().add(1, 'Y').format('YYYY-MM-DD');
          // const expirationTime = getTimestamp(expiresIn);
          const params = {
            createdByCompany: props.user.company.company.id,
            cashpool: String(cashpoolAmount),
            crr: String(crr),
            //expirationTime,
            invoiceIds,
          };
          api.purchaserOffers
            .post(params)
            .then((res) => {
              props.updatePurchaserOfferOffered(res);
              props.hideEarlyPaymentOffer();
              setLoading(false);
              setCashpoolAmount(null);
              setCrr(null);
              if (props.dataToRefresh !== 'INVOICE') {
                props.setRefreshData(true);
              }
            })
            .catch((err) => {
              const { message, errors } = processError(err);
              if (errors) {
                errors.map((message) => handleError(message));
              } else {
                handleError(message);
              }
              setLoading(false);
            });
        }
        // post earlypayment offer
      }
    }
  };

  const handleCancel = () => {
    setCashpoolAmount(null);
    setCrr(null);
    setErrorMessage('');
    props.hideEarlyPaymentOffer();
  };

  const { isVisibleEarlyPaymentOffer, fundsAvailable, count, invoicesTotal } =
    props;

  const totalCount = count ? count : 0;
  const totalAmountDue = invoicesTotal ? invoicesTotal : 0;

  return (
    <Modal
      okText="Confirm"
      okButtonProps={{ loading }}
      onOk={confirmCashpool}
      cancelText="Delete"
      onCancel={handleCancel}
      title="Create Prompt Payment Offer"
      visible={isVisibleEarlyPaymentOffer}
    >
      <FundsInfo balance={fundsAvailable} />
      <InvoiceInfo count={totalCount} amount={totalAmountDue} />
      <Ruler />
      <Inputs
        cashpoolAmount={cashpoolAmount}
        crr={crr}
        updateCashpoolAmount={setCashpoolAmount}
        updateCrr={setCrr}
      />
      {emptyMessage ? (
        <p style={styles.emptyMessage}>Please fill both fields</p>
      ) : null}
      {errorMessage ? <p style={styles.emptyMessage}>{errorMessage}</p> : null}
      <Ruler />
      <Footer />
    </Modal>
  );
};

const styles = {
  emptyMessage: {
    color: colors.error,
    fontSize: 12,
    textAlign: 'right',
    marginRight: 12,
    marginTop: -10,
  },
};

const mapStateToProps = ({ offers, invoices, auth, analytics, ui }) => ({
  isVisibleEarlyPaymentOffer: offers.isVisibleEarlyPaymentOffer,
  bills: invoices.bills,
  fundsAvailable:
    auth.user?.company?.company && auth.user.company.company.fundsAvailable,
  user: auth.user,
  count: analytics.unpaidInvoicesCount,
  invoicesTotal: analytics.invoicesTotal,
  dataToRefresh: ui.dataToRefresh,
});

const mapDispatchToProps = {
  hideEarlyPaymentOffer,
  showTopUpWindow,
  getPurchaserOffersOffered,
  updatePurchaserOfferOffered,
  refreshToken,
  setRefreshData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EarlyPaymentOffer);
