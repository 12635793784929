import React from 'react';
import Company from 'types/Company';
import CounterParty from 'types/CounterParty';
import { PeriodLockButton } from './PeriodLockButton';
import { usePeriodLock } from './usePeriodLock';

type PeriodLockProps = {
  counterParty: CounterParty;
  entityId: Company['id'];
  isDisabled: boolean;
  setCounterParty: (counterParty: CounterParty) => void;
  selectedMonth: number;
};

export const PeriodLock: React.FC<PeriodLockProps> = ({
  counterParty,
  entityId,
  isDisabled,
  setCounterParty,
  selectedMonth,
}) => {
  const { isLoading, isLocked, handleClickPeriodLock, tooltip } = usePeriodLock(
    {
      counterParty,
      entityId,
      setCounterParty,
      selectedMonth,
    }
  );

  return (
    <PeriodLockButton
      isDisabled={isDisabled}
      isLoading={isLoading}
      isLocked={isLocked}
      onClick={handleClickPeriodLock}
      tooltip={tooltip}
    />
  );
};
