import { PaymentTerm } from 'types/PaymentTerm';
import { toTitleCase } from 'utils';

const getOrdinal = (d: number) => {
  let ordinal = 'th';
  if (d === 1 || d === 31) {
    ordinal = 'st';
  } else if (d === 2 || d === 22) {
    ordinal = 'nd';
  } else if (d === 3 || d === 23) {
    ordinal = 'rd';
  }
  return ordinal;
};

const appendOrdinal = (d: number) => {
  const ordinal = getOrdinal(d);
  return `${d}${ordinal}`;
};

type Params = {
  term?: PaymentTerm | null;
  separator?: '-' | ',';
  showDefaultMarker?: boolean;
};

export const getPaymentTermString = (params: {
  term?: PaymentTerm | null;
  separator?: '-' | ',';
  showDefaultMarker?: boolean;
}): string => {
  const { term, separator = ',', showDefaultMarker } = params;
  if (!term) {
    return '';
  }
  const { frequency, date, dayOfWeek, defaultTerm } = term;
  const separatorToUse = separator === '-' ? ' -' : separator;
  if (frequency === 'WEEKLY' && dayOfWeek) {
    return `Weekly${separatorToUse} ${toTitleCase(dayOfWeek)}${
      showDefaultMarker && defaultTerm ? '*' : ''
    }`;
  } else if (date) {
    const withOrdinal = appendOrdinal(date);
    return `Monthly${separatorToUse} ${withOrdinal}${
      showDefaultMarker && defaultTerm ? '*' : ''
    }`;
  }
  return '';
};
