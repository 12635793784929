import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import spacing from 'styles/layout/spacing';

const { Title, Text } = Typography;

const TitleContainer = styled.div`
  margin-bottom: ${spacing.gutter.lg};
`;

export const Head: React.FC = () => {
  return (
    <TitleContainer>
      <Title level={5}>Bank Account</Title>
      <Text>
        Withdrawals from your Relay account will be sent to this bank account
      </Text>
    </TitleContainer>
  );
};
