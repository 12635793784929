import axios from 'axios';
import PurchaserOffer, {
  PurchaserOfferInvoiceResponse,
  PurchaserOfferResource,
  PurchaserOfferPOST,
  ExecuteOfferStatusAPIResponse,
} from 'types/PurchaserOffer';

const URL = '/purchaseroffer';

const get = (): Promise<PurchaserOfferResource> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${URL}?page=0&size=1000`)
      .then(({ data }: { data: PurchaserOfferInvoiceResponse }) =>
        resolve(data.purchaserOfferResource)
      )
      .catch((error) => reject(error));
  });
};

const getSingle = (id: string): Promise<PurchaserOfferResource> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${URL}/${id}`)
      .then(({ data }: { data: PurchaserOfferResource }) => resolve(data))
      .catch((error) => reject(error));
  });
};

type GetAvailableParams = {
  companyId?: string;
  notified?: boolean;
  token?: string;
};
const getAvailable = ({
  companyId,
  notified,
  token,
}: GetAvailableParams): Promise<PurchaserOfferResource> => {
  return new Promise(function (resolve, reject) {
    const urlFragment = notified ? 'notified' : 'available';
    let url = `${URL}/${urlFragment}?page=0&size=1000`;
    if (companyId) {
      url = `${url}&companyId=${companyId}`;
    }
    if (notified && token) {
      url = `${url}&token=${token}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: token
            ? 'Bearer ' + token
            : axios.defaults.headers.common['Authorization'],
        },
      })
      .then(({ data }: { data: PurchaserOfferInvoiceResponse }) =>
        resolve(data.purchaserOfferResource)
      )
      .catch((error) => reject(error));
  });
};

const post = (params: PurchaserOfferPOST): Promise<PurchaserOfferResource> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(URL, params)
      .then(({ data }: { data: PurchaserOfferResource }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const edit = ({
  params,
  uriParam,
}: {
  params: PurchaserOffer;
  uriParam: string;
}): Promise<PurchaserOfferResource> => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`${URL}/${uriParam}?page=0&size=1000`, params)
      .then(({ data }: { data: PurchaserOfferResource }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const reset = ({
  params,
  uriParam,
}: {
  params: PurchaserOffer;
  uriParam: string;
}): Promise<PurchaserOfferResource> => {
  return new Promise(function (resolve, reject) {
    axios
      .put(`${URL}/${uriParam}`, params)
      .then(({ data }: { data: PurchaserOfferResource }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const execute = ({
  payload,
  offerId,
}: {
  payload: { invoiceIds: Array<string> };
  offerId: string;
}): Promise<string> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${URL}/${offerId}/execute`, payload)
      .then(({ data }: { data: string }) => resolve(data))
      .catch((error) => reject(error));
  });
};

const executionStatus = ({
  offerId,
  executionId,
}: {
  offerId: string;
  executionId: string;
}): Promise<ExecuteOfferStatusAPIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${URL}/${offerId}/execute/${executionId}/status`)
      .then(({ data }: { data: ExecuteOfferStatusAPIResponse }) =>
        resolve(data)
      )
      .catch((error) => reject(error));
  });
};

// const allocatedOffers = uriParam => {
//   return new Promise(function(resolve, reject) {
//     axios
//       .get(`${URL}/${uriParam}/allocated?page=0&size=1000`, {})
//       .then(({ data }) => resolve(data))
//       .catch(error => reject(error));
//   });
// };

export default {
  get,
  getSingle,
  getAvailable,
  post,
  edit,
  execute,
  executionStatus,
  reset,
  //allocatedOffers
};
