import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Modal, notification, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { hideWithdrawModal, State as UiState } from 'store/ui';
import { getBankAccounts, getCompany } from 'store/auth';
import { bootstrapTransactions } from 'store/transactions';

import WithdrawFunds from './WithdrawFunds';
import { WithdrawalConfirmation } from './WithdrawalConfirmation/WithdrawalConfirmation';
import { WithdrawalPlaced } from './WithdrawalPlaced';
import { DestinationAccountInfo } from './DestinationAccountInfo';
import { ModalHeader } from 'components';

import api from 'api';
import spacing from 'styles/layout/spacing';
import BankAccount from 'types/BankAccount';
import Transaction from 'types/Transaction';
import { DestinationAccountState } from './types';
import Company from 'types/Company';
import { processError } from 'utils';

const { Text } = Typography;

const Spinner = styled(Spin)`
  position: relative;
  left: 48%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const Error = styled(Text)`
  font-size: 14;
  text-align: 'right';
  margin-right: 12;
  margin-top: 10;
`;

const MIN_AMOUNT = 1;

const INSUFFICIENT_BALANCE_ERROR =
  'The amount entered exceeds the available balance. Please enter a lower amount.';
const INVALID_AMOUNT_ERROR = `Withdrawal amount must be equal to or higher than $${MIN_AMOUNT}`;

enum ModalStep {
  ENTRY,
  DETAILS_CONFIRMATION,
  WITHDRAWAL_PLACED,
}

type Props = {
  isAdmin?: boolean;
  companyId?: Company['id'];
  fundsAvailable?: Company['fundsAvailable'];
  visible?: boolean;
  sourceAccount?: BankAccount | null;
  destinationAccount?: BankAccount | null;
  onHide?: () => void;
  onSuccess?: () => void;
};

export const WithdrawModal: React.FC<Props> = (props) => {
  const { isAdmin } = props;

  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.auth.user);
  const { isWithdrawModalVisible } = useSelector(
    (state: { ui: UiState }) => state.ui
  );

  const transactionsState = useSelector((state: any) => state.transactions);
  const transactions = transactionsState.transactions.map(
    (t: { transaction: Transaction }) => t.transaction
  );
  const { loadingTransactions, pageSize } = transactionsState;

  const { company } = user.company;
  const companyId = isAdmin ? props.companyId : user.companyId;

  const { account } = user;
  const toAccount = isAdmin
    ? props.destinationAccount
    : account
    ? account.find(
        (acc: BankAccount) =>
          acc.defaultAccount && acc.bankAccountStatus !== 'DELETED'
      )
    : null;
  const fromBankAccount = isAdmin
    ? props.sourceAccount
    : account && account.find((acc: BankAccount) => acc.loadedBy === 'SYSTEM');

  const [withDrawAmount, setWithDrawAmount] = useState('0');
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState(ModalStep.ENTRY);

  const parsedAmount = parseFloat(withDrawAmount);

  const fundsAvailable = isAdmin
    ? props?.fundsAvailable
    : company?.fundsAvailable;

  const countryCode = company?.address?.countryCode;

  useEffect(() => {
    if (!isAdmin && isWithdrawModalVisible) {
      dispatch(getCompany(companyId));
      dispatch(getBankAccounts(companyId, countryCode));
    }
  }, [companyId, countryCode, dispatch, isAdmin, isWithdrawModalVisible]);

  useEffect(() => {
    if (!isAdmin && isWithdrawModalVisible) {
      dispatch(bootstrapTransactions({ size: pageSize }));
    }
  }, [dispatch, pageSize, isAdmin, isWithdrawModalVisible]);

  const onChangeAmount = ({ value }: { value: string }) => {
    setWithDrawAmount(value);
    setError(null);
  };

  // on confirm withdraw
  const onConfirmWithdraw = () => {
    if (!error && toAccount) {
      setSubmitting(true);
      if (withDrawAmount > fundsAvailable) {
        setError(INSUFFICIENT_BALANCE_ERROR);
        setSubmitting(false);
      } else {
        const payload = {
          bankAccountId: toAccount.id,
          withdrawalAmount: parsedAmount,
        };

        const apiFunction = isAdmin
          ? api.admin.withdrawBalance
          : api.company.withdrawBalance;

        apiFunction({ payload, entityId: companyId })
          .then(() => {
            setStep(ModalStep.WITHDRAWAL_PLACED);
            if (isAdmin) {
              props.onSuccess?.();
            } else {
              dispatch(bootstrapTransactions({ size: pageSize }));
              dispatch(getCompany(companyId));
            }
          })
          .catch((error) => {
            const { message } = processError(error);
            notification.error({ message });
          })
          .finally(() => setSubmitting(false));
      }
    }
  };

  // submit withdraw and display withdraw confirmation
  const submitWithdrawal = () => {
    if (!withDrawAmount || parsedAmount < MIN_AMOUNT) {
      setError(INVALID_AMOUNT_ERROR);
      return null;
    }
    if (toAccount) {
      if (withDrawAmount > fundsAvailable) {
        setError(INSUFFICIENT_BALANCE_ERROR);
      } else {
        setError(null);
        setStep(ModalStep.DETAILS_CONFIRMATION);
      }
    }
  };

  const hasPendingWithdrawal = transactions.some(
    (transaction: Transaction) =>
      transaction.transactionClass === 'WITHDRAWAL' &&
      transaction.status === 'PENDING'
  );

  const reset = () => {
    setStep(ModalStep.ENTRY);
    setWithDrawAmount('0');
    setSubmitting(false);
    setError(null);
  };

  const handleHide = () => {
    reset();
    if (isAdmin && props.onHide) {
      props.onHide();
    } else {
      dispatch(hideWithdrawModal());
    }
  };

  let destinationAccountState =
    toAccount && step !== ModalStep.WITHDRAWAL_PLACED && hasPendingWithdrawal
      ? DestinationAccountState['PENDING_WITHDRAWAL']
      : toAccount?.bankAccountStatus === 'VERIFICATION_PENDING'
      ? DestinationAccountState['VERIFICATION_PENDING']
      : toAccount?.bankAccountStatus === 'UNVERIFIED'
      ? DestinationAccountState['UNVERIFIED']
      : null;

  if (!toAccount) {
    destinationAccountState = DestinationAccountState['NO_ACCOUNT'];
  }

  const titleMap = {
    [ModalStep.ENTRY]: 'Submit',
    [ModalStep.DETAILS_CONFIRMATION]: 'Confirm',
    [ModalStep.WITHDRAWAL_PLACED]: 'OK',
  };

  const okButtonLabel = hasPendingWithdrawal ? 'Close' : titleMap[step];

  return (
    <Modal
      closable={false}
      css={`
        .ant-modal-content {
          margin: auto;
          width: calc(100% - 2 * ${spacing.gutter.sm});
          max-width: 450px;
        }
      `}
      visible={isAdmin ? props.visible : isWithdrawModalVisible}
      okText={okButtonLabel}
      onOk={
        step === ModalStep.WITHDRAWAL_PLACED || hasPendingWithdrawal
          ? handleHide
          : step === ModalStep.DETAILS_CONFIRMATION
          ? onConfirmWithdraw
          : submitWithdrawal
      }
      okButtonProps={{
        loading: isSubmitting,
        style: { display: toAccount ? 'initial' : 'none' },
      }}
      cancelButtonProps={{
        disabled: isSubmitting,
        style: {
          display:
            toAccount &&
            step !== ModalStep.WITHDRAWAL_PLACED &&
            !hasPendingWithdrawal
              ? 'initial'
              : 'none',
        },
      }}
      onCancel={handleHide}
      title={
        <ModalHeader
          onClose={handleHide}
          title={
            step === ModalStep.WITHDRAWAL_PLACED
              ? toAccount.bankAccountStatus === 'VERIFICATION_PENDING'
                ? 'Withdrawal Pending'
                : 'Withdrawal Successful'
              : step === ModalStep.DETAILS_CONFIRMATION
              ? 'Withdrawal Details'
              : 'Withdraw Funds'
          }
        />
      }
    >
      {loadingTransactions ? (
        <Spinner />
      ) : (
        <>
          {step === ModalStep.WITHDRAWAL_PLACED ? (
            <WithdrawalPlaced
              isBankAccountVerified={toAccount.bankAccountStatus === 'VERIFIED'}
              isAdmin={isAdmin}
              amount={parsedAmount}
            />
          ) : step === ModalStep.DETAILS_CONFIRMATION ? (
            <WithdrawalConfirmation
              countryCode={countryCode}
              selectedToAccount={toAccount}
              withDrawalAmount={parsedAmount}
            />
          ) : (
            <WithdrawFunds
              countryCode={countryCode}
              fundsAvailable={fundsAvailable}
              toAccount={toAccount}
              fromBankAccount={fromBankAccount}
              withDrawAmount={withDrawAmount}
              onChangeAmount={onChangeAmount}
              hasPendingWithdrawal={hasPendingWithdrawal}
            />
          )}

          {!!error && <Error type="danger">{error}</Error>}

          {step === ModalStep.ENTRY && (
            <DestinationAccountInfo state={destinationAccountState} />
          )}
        </>
      )}
    </Modal>
  );
};
