import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { showInviteWindow } from 'store/invoices';
import { setRefreshData } from 'store/ui';

import api from 'api';
import {
  processError,
  validateEmail,
  isInvitedByLoggedUserCompany,
} from 'utils';
import Invoice from 'types/Invoice';
import { InvoiceMetaData } from './InvoiceMetaData';
import TableButton from 'components/TableButton';

const RowButton: React.FC<{
  record: Invoice & InvoiceMetaData;
  type: 'INVOICE' | 'BILL';
  loggedUserCompanyId: string;
}> = ({ record, type, loggedUserCompanyId }) => {
  const { contactEmail, owingCompany, status } = record;
  const dispatch = useDispatch();
  const [isInviteInProgress, setInviteProgress] = useState(false);
  const isValidEmail = validateEmail(contactEmail || undefined);
  let label = record.action.label;
  let onClick = record.action.do;

  if (
    status.label === 'Payment Requested' &&
    (owingCompany.status === 'INACTIVE' ||
      (owingCompany.status === 'INVITED' &&
        !isInvitedByLoggedUserCompany(record, loggedUserCompanyId)))
  ) {
    label = 'Invite' as const;
    onClick = () => {
      contactEmail && isValidEmail
        ? onInvite({ type, data: record as Invoice })
        : dispatch(showInviteWindow({ type, data: record as Invoice }));
    };
  }

  const onInvite = ({
    type,
    data,
  }: {
    type: 'INVOICE' | 'BILL';
    data: Invoice;
  }) => {
    setInviteProgress(true);
    const payload = {
      email: data.contactEmail || '',
      companyId: data.owingCompany.id,
      note: '',
      type,
    };
    api.invite
      .sendInvite(payload)
      .then(() => {
        dispatch(setRefreshData(true));
      })
      .catch((err) => {
        const { status, message, debugMessage } = processError(err);
        if (status !== 403 || debugMessage !== 'company not verified') {
          notification.error({ message });
        }
      })
      .finally(() => {
        setInviteProgress(false);
      });
  };
  return (
    <TableButton
      onClick={onClick}
      loading={isInviteInProgress}
      className="table-row-button"
    >
      {isInviteInProgress ? 'Sending' : label}
    </TableButton>
  );
};

export default RowButton;
