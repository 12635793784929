import React from 'react';
import Company from 'types/Company';
import { CompanyStatusDot, TableCellWithStatusDot } from 'components';
import { Typography } from 'antd';

type Props = {
  isPurchaserOffersDisabled: boolean;
  name: string;
  companyStatus: Company['status'];
  companyVerificationStatus: Company['verificationStatus'];
};

const CompanyName: React.FC<Props> = ({
  isPurchaserOffersDisabled,
  name,
  companyStatus,
  companyVerificationStatus,
}) => (
  <TableCellWithStatusDot>
    <CompanyStatusDot
      companyStatus={companyStatus}
      companyVerificationStatus={companyVerificationStatus}
      isPurchaserOffersDisabled={isPurchaserOffersDisabled}
    />
    <Typography.Text>{name}</Typography.Text>
  </TableCellWithStatusDot>
);

export default CompanyName;
