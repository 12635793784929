import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)<{ $isSubMenu: boolean }>`
  color: #f6f7f8;
  font-size: ${(props) => (props.$isSubMenu ? '13px' : '16px')};
`;

const NavLink: React.FC<{ label: string; to: string; isSubMenu: boolean }> = ({
  label,
  to,
  isSubMenu,
}) => (
  <StyledLink to={to} $isSubMenu={isSubMenu}>
    {label}
  </StyledLink>
);

export default NavLink;
