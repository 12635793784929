import React, { useState } from 'react';
import styled from 'styled-components';
import useFilters from './useFilters';
import FilterDialog from './FilterDialog';
import Amount from './Amount';
import OrderNumber from './OrderNumber';
import InvoiceNumber from './InvoiceNumber';
import IssueDate from './IssueDate';
import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';

const { Text } = Typography;

const Container = styled(Space)`
  cursor: pointer;
  user-select: none;
`;

const Arrow = styled(CaretDownOutlined)<{ $open: boolean }>`
  position: relative;
  top: 0.5px;
  transition: 400ms transform;
  transform: ${(props) => props.$open && 'rotate(180deg);'};
`;

const Filter: React.FC = () => {
  const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);

  const hideFilterDialog = () => setFilterDialogOpen(false);

  const onChangeDropdownVisibility = (isVisible: boolean) =>
    setFilterDialogOpen(isVisible);

  const {
    activeFilterCount,
    amountFilters,
    orderNumberFilters,
    invoiceNumberFilters,
    issueDateFilters,
    handleDone,
    handleReset,
  } = useFilters({
    hideFilterDialog,
  });

  const amountFilter = <Amount {...amountFilters} />;

  const orderNumberFilter = <OrderNumber {...orderNumberFilters} />;

  const invoiceNumberFilter = <InvoiceNumber {...invoiceNumberFilters} />;

  const issueDateFilter = <IssueDate {...issueDateFilters} />;

  let label = 'Filter';
  if (activeFilterCount > 0) {
    label += ` (${activeFilterCount})`;
  }

  return (
    <Dropdown
      overlay={() => (
        <FilterDialog
          amountFilter={amountFilter}
          orderNumberFilter={orderNumberFilter}
          invoiceNumberFilter={invoiceNumberFilter}
          issueDateFilter={issueDateFilter}
          handleDone={handleDone}
          handleReset={handleReset}
        />
      )}
      trigger={['click']}
      onVisibleChange={onChangeDropdownVisibility}
      placement="bottomRight"
      visible={isFilterDialogOpen}
    >
      <Container align="center" onClick={(e: any) => e.preventDefault()}>
        <Text>{label}</Text>
        <Arrow $open={isFilterDialogOpen} />
      </Container>
    </Dropdown>
  );
};

export default Filter;
