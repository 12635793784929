import { Button, Modal } from 'antd';
import Text from 'antd/lib/typography/Text';
import ModalHeader from 'components/ModalHeader';
import TableButton from 'components/TableButton';
import SimpleTable from 'ds/atoms/SimpleTable';
import React from 'react';
import spacing from 'styles/layout/spacing';
import Invoice from 'types/Invoice';
import { formatCurrency } from 'utils';
import DataRow from '../GetPaidModal/DataRow';

type InvoiceSummary = {
  invoice: Invoice;
  id: string;
  discountedTotal: number;
};

type Props = {
  invoicesByCompany: {
    [companyName: string]: InvoiceSummary;
  };
  setSelectedInvoice: (invoice: Invoice | null) => void;
  onClose: () => void;
};

const PaymentsAvailableModal: React.FC<Props> = ({
  invoicesByCompany,
  setSelectedInvoice,
  onClose,
}) => {
  const invoices = Object.values(invoicesByCompany);
  const columns = [
    {
      title: 'CUSTOMER',
      key: 'customer',
      render: (summary: InvoiceSummary) => (
        <Text
          ellipsis={{ tooltip: true }}
          data-testid={`customer-${summary.id}`}
        >
          {summary.invoice.owingCompany.companyName}
        </Text>
      ),
    },
    {
      title: 'MAX AVAILABLE',
      key: 'max-available',
      align: 'right' as 'right',
      render: (summary: InvoiceSummary) => (
        <Text ellipsis={{ tooltip: true }} data-testid={`amount-${summary.id}`}>
          {formatCurrency(summary.discountedTotal)}
        </Text>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right' as 'right',
      render: (summary: InvoiceSummary) => (
        <TableButton
          type="primary"
          onClick={() => setSelectedInvoice(summary.invoice)}
          data-testid={`button-${summary.id}`}
        >
          Get Paid
        </TableButton>
      ),
    },
  ];

  return (
    <Modal
      title={<ModalHeader title="Payments Available" hideClose />}
      visible
      centered
      closable={false}
      footer={[
        <Button
          onClick={onClose}
          key="dismiss-button"
          data-testid="dismiss-button"
        >
          Dismiss
        </Button>,
      ]}
    >
      <SimpleTable dataSource={invoices} columns={columns} pagination={false} />
      <div style={{ marginTop: spacing.gutter.sm }}>
        <DataRow
          color="blue"
          label="Amount Due (inc. GST)"
          value={invoices.reduce((a, b) => a + b.discountedTotal, 0)}
        />
      </div>
    </Modal>
  );
};

export default PaymentsAvailableModal;
