import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { refreshToken } from 'store/auth';

import { colors } from 'themes';
import { LoadingOutlined } from '@ant-design/icons';
import spacing from 'styles/layout/spacing';

const Container = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  margin-left: ${(props) => props.action && '10px'};
  margin-right: ${(props) => props.action && '10px'};
  height: ${(props) => (props.small ? '24px' : '32px')};
  border-radius: 2px;
  border: ${(props) => (props.action ? `0.5px solid ${colors.greys500}` : 0)};
  cursor: ${(props) => (props.disabled || props.$loading ? 'auto' : 'pointer')};
  background-color: ${(props) =>
    props.transparent
      ? 'transparent'
      : props.action
      ? '#FFFFFF'
      : colors.greys500};
  color: ${(props) => (props.transparent ? colors.greys900 : '#FFF')};
  :hover {
    background-color: ${(props) =>
      props.transparent || props.action ? colors.greys200 : colors.greys600};
    color: ${(props) =>
      props.transparent || props.action ? colors.greys800 : '#FFF'};
  }
  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  width: ${(props) => (props.full ? '100%' : 'initial')};
  :active {
    background-color: ${(props) =>
      props.transparent || props.action ? colors.greys400 : colors.greys700};
    color: #fff;
  }
`;

const Label = styled.span`
  font-size: ${(props) =>
    props.small ? (props.action ? '10px' : '12px') : '16px'};
  font-weight: ${(props) => props.action && 'bold'};
  letter-spacing: ${(props) => props.action && '0.05em'};
  color: ${(props) => props.action && colors.greys700};
`;

const LoadingIndicator = styled(LoadingOutlined)`
  margin-right: ${spacing.gutter.xs};
`;

const Button = ({
  label,
  onClick,
  small,
  transparent,
  className,
  full,
  action,
  disabled,
  loading,
  style,
  refreshToken,
  user,
  callRefreshToken,
}) => {
  const onButtonClick = () => {
    // only relay users token refresh
    if (user && user.token && label !== 'Log In' && callRefreshToken) {
      refreshToken(user.token);
    }
    !!onClick && onClick();
  };
  return (
    <Container
      onClick={!disabled && !loading && onButtonClick}
      small={small}
      full={full}
      style={style}
      transparent={transparent}
      className={className}
      action={action}
      $loading={loading}
      disabled={disabled}
    >
      {loading && <LoadingIndicator />}
      <Label small={small} action={action}>
        {label}
      </Label>
    </Container>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {
  refreshToken,
})(Button);
